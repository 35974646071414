<template>
  <div class="form-label-group input-group mb-3">
    <input
      :placeholder="label"
      disabled
      type="number"
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      :value="value"
      :aria-describedby="`help-${id}`"
    >
    <label :for="id" class="col-form-label">{{ label }}</label>
    <div class="input-group-append">
      <span class="input-group-text">원</span>
    </div>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputTotalDisable',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '거래금액 (1000원 이상 등록 가능)',
    },
  },
};
</script>

import 'bootstrap/scss/bootstrap.scss';
import '@/assets/style.scss';

import '@/filters/filters';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

store.dispatch('userInfomation/requestUser');
store.dispatch('keywords/fetchKeywords');

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

import _ from 'underscore';
import {
  INPUT_FORM__USER_NICKNAME,
  POST_FORM__USER_NICKNAME,
  DONE_FORM__USER_NICKNAME,
  FAIL_FORM__USER_NICKNAME,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    nickname: '',
  },
  error: {},
  status: 'INIT',
};

const getters = {
  enabledForm: state => _.every(state.form),
  loadingForm: state => state.status === 'POST',
};

const mutations = {
  [INPUT_FORM__USER_NICKNAME] (state, { key, value }) {
    state.form[key] = value.trim();
  },
  [POST_FORM__USER_NICKNAME] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__USER_NICKNAME] (state) {
    state.form.nickname = '';
    state.status = 'DONE';

    router.push({ name: 'user_infomation' });
  },
  [FAIL_FORM__USER_NICKNAME] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  submitForm({ state, commit }) {
    commit(POST_FORM__USER_NICKNAME);

    const { nickname } = state.form;
    api.post('/users/nickname', { nickname }).then(
      response => setTimeout(() => commit(DONE_FORM__USER_NICKNAME, response.data), 1000),
      error => commit(FAIL_FORM__USER_NICKNAME, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

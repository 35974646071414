<template>
  <div>
    <NegoLinks group="group4"/>
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-12 col-lg-6">
          <p class="text-left text-lg-center">
            회원간매매를 위해 비상장주식 매물을 등록합니다.
          </p>
          <NegoLinkButtons />
          <NegoButton
            v-if="user.kassetAccountStatus === 'ISSUE'"
            label="확인매물 등록 신청"
            @click-button="routeSearch"
          />
          <div
            v-if="user.kassetAccountStatus === 'READY'"
            class="alert alert-warning"
          >현재 비대면 계좌 개설이 진행중입니다.</div>
          <NegoButton
            v-if="user.kassetAccountStatus === 'NONE'"
            label="비대면 계좌 개설"
            @click-button="openAccount"
          />
        </div>
      </div>
      <template v-if="user.kassetAccountStatus === 'ISSUE'">
        <div v-if="loadingList">
          loading...
        </div>
        <div class="px-3" v-else>
          <SellAssignsCard
            v-for="(item, index) in list"
            :key="index"
            :item="item"
            @update-inbound="updateInbound"
          />
        </div>
      </template>
    </div>

    <SellAssignsNotice/>
    <SellAssignsVideo/>
    <AppModal v-if="showModal" title="알림">
      <template>
        <p class="mb-0">현재 이용하실 수 없습니다.</p>
      </template>
      <template v-slot:footer>
        <button class="btn btn-outline-dark btn-sm" @click="closeModal">닫기</button>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import NegoButton from '@/components/nego/NegoButton.vue';
import NegoLinkButtons from '@/components/nego/NegoLinkButtons.vue';
import SellAssignsCard from '@/components/sellAssigns/SellAssignsCard.vue';
import SellAssignsNotice from '@/components/sellAssigns/SellAssignsNotice.vue';
import SellAssignsVideo from '@/components/sellAssigns/SellAssignsVideo.vue';
import { NegoStockWeb, NegoStockApi, FintechCommPath, FintechAccountPath } from '@/utils/constants';
import AppModal from '@/components/app/AppModal.vue';

export default {
  name: 'SellAssignsView',
  components: {
    NegoLinks,
    NegoButton,
    NegoLinkButtons,
    SellAssignsCard,
    SellAssignsNotice,
    SellAssignsVideo,
    AppModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('sellAssigns', [
      'list',
      'error',
      'status',
    ]),
    ...mapGetters('sellAssigns', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('sellAssigns', [
      'reqeustList',
    ]),
    updateInbound(sellAssignKey) {
      const { corp } = this.user;
      const PATH = [
        `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
        `?redirect_path=${NegoStockWeb}/sell-assigns`,
        '&path=/inbound/update',
        `&sellAssignKey=${sellAssignKey}`,
        `&corp=${!!corp}`,
      ].join('');
      window.location = PATH;
    },
    openAccount() {
      if (this.user.preUser) {
        this.$router.push({ name: 'user_mobile' });
      } else {
        window.location = `${NegoStockApi}/sessions/1/${FintechAccountPath}/auth?path=/applied`;
      }
    },
    routeSearch() {
      this.$router.push({ name: 'sell_assigns_search' });
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {
    if (this.user && !this.user.preUser && this.user.kassetAccountStatus === 'ISSUE') {
      this.reqeustList();
    }
  },
};
</script>

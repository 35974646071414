<template>
  <div>
    <FormHead
      :title="form.dealName"
      text="조합가입신청"
    />
    <form @submit.prevent="checkForm">
      <InputPrice
        label="참여금액"
        id="reserveAmount"
        :value="form['reserveAmount']"
        :error="error['reserveAmount']"
        @input-form="(value) => inputForm({ key: 'reserveAmount', value })"
      />
      <h6 class="mt-5">유의사항</h6>
      <ol class="pl-4">
        <li>조합원 수 제한(49인)으로 인해 <mark class="bg-warning">최소참여금액이 {{ form['retailMinAmount'] | currency }} 원</mark> 입니다.</li>
        <li>본 신기술투자조합은 전문투자자로 한정하여 가입 신청을 받으며, 이에 따라 금융소비자보호법 제17조, 제18조 및 제19조의 적합성 원칙 적정성 원칙, 설명의무가 적용되지 않습니다.</li>
        <li>상품설명서, 조합규약 등을 충분히 검토하신 후에 가입신청을 진행해 주시기 바랍니다.</li>
        <li>가입신청 및 참여금액 입금이 조합참여를 100% 보장하지는 않습니다.</li>
      </ol>
      <InputCheckbox
        id="agreeForm"
        :value="form['agreeForm']"
        :error="error['agreeForm']"
        @input-form="(value) => inputForm({ key: 'agreeForm', value })"
        label="유의사항 확인함"
      />
      <SubmitButton
        label="조합가입 신청"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
      <div v-if="error['dealKey']"
        class="alert alert-warning"
      >{{ error['dealKey'] }}</div>
    </form>
    <div class="border p-2 mt-5">
      <h6 class="font-weight-bold text-center py-3">조합가입 신청 후 절차 안내</h6>
      <ol class="pl-4">
        <li>신청 접수가 이루어지면 참여금액을 입금하실 가상계좌를 안내해 드립니다.</li>
        <li>안내 받으신 가상계좌로 참여금액 입금해주시면 "조합규약 등에의 전자서명" 진행에 대해 안내해 드립니다.</li>
        <li>"조합규약 등에의 전자서명"을 완료하시면 조합가입이 완료됩니다.</li>
        <li>"조합규약 등에의 전자서명"을 완료하신 경우라도 모집액 초과 등의 이유로 참여가 취소될 수 있습니다.</li>
        <li>조합가입이 미완료 또는 취소될 경우 입금하신 참여금액은 고객님이 등록하신 계좌로 반환됩니다.</li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import {
  INIT_FORM__LP_FUNDING_SUBSCRIPT,
  INPUT_FORM__LP_FUNDING_SUBSCRIPT,
} from '@/store/mutation-types';

export default {
  name: 'LPFundingSubscript',
  components: {
    FormHead,
    InputPrice,
    SubmitButton,
    InputCheckbox,
  },
  computed: {
    ...mapState('lpFundingSubscript', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('lpFundingSubscript', [
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('lpFundingSubscript', {
      'initForm': INIT_FORM__LP_FUNDING_SUBSCRIPT,
      'inputForm': INPUT_FORM__LP_FUNDING_SUBSCRIPT,
    }),
    ...mapActions('lpFundingSubscript', [
      'checkForm',
      'submitForm',
    ]),
  },
  mounted() {
    const { dealName, dealKey, retailMinAmount } = this.$route.query;
    this.inputForm({ key: 'dealName', value: dealName });
    this.inputForm({ key: 'dealKey', value: dealKey });
    this.inputForm({ key: 'retailMinAmount', value: parseInt(retailMinAmount) });
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

<template>
  <div class="form-label mb-3">
    <textarea
      :rows="rows"
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      :value="value"
      :placeholder="label"
      @input="$emit('input-form', $event.target.value)"
      :disabled="disabled"
    ></textarea>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputTextArea',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: '10',
    },
  },
};
</script>

<template>
  <div class="form-label-group mb-3">
    <input
      :disabled="disabled"
      :autocomplete="autocomplete"
      type="email"
      class="form-control"
      :placeholder="placeholder"
      :id="id"
      :class="{ 'is-invalid': error }"
      :value="value"
      @input="$emit('input-form', $event.target.value)"
    >
    <label :for="id">{{ placeholder }}</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputEmail',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'username',
    },
    placeholder: {
      type: String,
      default: '이메일',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

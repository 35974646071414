<template>
  <div class="container p-0">
    <div class="alert alert-success" v-if="isOld">
      <strong>
        인터넷익스플로러(Internet Explorer)는 사용이 제한적입니다.<br>
        엣지(Edge), 사파리(Safari), 크롬(Chrome), 파이어폭스(Firefox), 오페라(Opera) 등 최신 브라우저를 사용해주세요.<br>
      </strong>
      <a class="btn btn-primary mt-3" href="https://www.google.co.kr/chrome/" target="_blank">
        크롬 다운로드 사이트
      </a>
    </div>
  </div>
</template>

<script>
import { checkOldBrowser } from '@/utils/checkPcBrowser';

export default {
  name: 'AppBrowser',
  data() {
    return {
      isOld: checkOldBrowser(),
    };
  },
};
</script>

<style scoped>
.alert {
  margin-top: 5rem !important;
  margin-bottom: 1.5rem !important;
}
</style>

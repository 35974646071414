import _ from 'underscore';
import {
  REQUEST__OTC_REPORTS,
  RECEIVE__OTC_REPORTS,
  FAILURE__OTC_REPORTS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  status: 'INITIAL',
  list: [],
  error: {},
  params: {},
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__OTC_REPORTS] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__OTC_REPORTS] (state, data) {
    const { contents, ...params } = data;
    state.status = 'RECEIVE';
    state.list = _.map(contents, el => {
      const companyCodes = [];
      if (el.companyName) {
        const names = el.companyName.split('||');
        _.forEach(names, (str) => {
          const [code, name] = str.split(':');
          companyCodes.push({
            companyName: `#${name}`,
            companyCode: code,
          })
        });
      }
      return {
        ...el,
        companyCodes,
      };
    });
    state.params = { ...params };
  },
  [FAILURE__OTC_REPORTS] (state, error) {
    state.status = 'FAILURE';
    state.error = { ...error };
  },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST__OTC_REPORTS);

    api.get('/public/cover/reports', { params: { scale: 50, page: 1 }}).then(
      response => commit(RECEIVE__OTC_REPORTS, response.data),
      error => commit(FAILURE__OTC_REPORTS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="">
    <form @submit.prevent="checkForm">
      <FormHead
        title="기업 등록 요청"
        text="비상장기업"
      />
      <SelectOptions
        id="purpose"
        label="요청사유 선택"
        :options="REGIST_PURPOSE_TYPE"
        :value="form['purpose']"
        :error="error['purpose']"
        @input-form="(value) => inputForm({ key: 'purpose', value })"
      />
      <InputText
        label="회사명"
        id="companyName"
        :value="form['companyName']"
        :error="error['companyName']"
        @input-form="(value) => inputForm({ key: 'companyName', value })"
      />
      <InputText
        label="사업자등록번호"
        id="bizNo"
        :value="form['bizNo']"
        :error="error['bizNo']"
        @input-form="(value) => inputForm({ key: 'bizNo', value })"
      />
      <InputTextArea
        id="message"
        label="담당자에게 전할 내용 (선택)"
        :value="form['message']"
        :error="error['message']"
        @input-form="value => inputForm({ key: 'message', value })"
      />
      <InputFile
        id="file"
        label="파일을 첨부해주세요"
        @input-form="value => inputForm({ key: 'file', value })"
        :error="error['file']"
      />
      <SubmitButton
        label="요청하기"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <div class="text-right my-4">
      <router-link
      class="btn btn-light text-white px-4 btn-sm"
      :to="{ name: '__OTC_HOT__' }"
      >이전으로</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import SelectOptions from '@/components/form/SelectOptions.vue';
import InputText from '@/components/form/InputText.vue';
import InputTextArea from '@/components/form/InputTextArea.vue';
import InputFile from '@/components/form/InputFile.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import {
  INIT_FORM__COMPANY_REGISTER,
  INPUT_FORM__COMPANY_REGISTER,
} from '@/store/mutation-types';
import { REGIST_PURPOSE_TYPE } from '@/filters/filtersCompany';

export default {
  name: 'CompanyRegister',
  components: {
    FormHead,
    SelectOptions,
    InputText,
    InputTextArea,
    InputFile,
    SubmitButton,
  },
  data() {
    return {
      REGIST_PURPOSE_TYPE,
    };
  },
  computed: {
    ...mapState('companyRegister', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('companyRegister', [
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('companyRegister', {
      'initForm': INIT_FORM__COMPANY_REGISTER,
      'inputForm': INPUT_FORM__COMPANY_REGISTER,
    }),
    ...mapActions('companyRegister', [
      'checkForm',
      'submitForm',
    ]),
  },
  mounted() {
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

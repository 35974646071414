<template>
  <div>
    <LoadingSpinner v-if="loadingList" />
    <div v-else-if="list.length === 0" class="alert alert-light">
      등록된 딜이 없습니다.
    </div>
    <div class="row row-cols-1 row-cols-md-2 no-gutters mx-n2" v-else>
      <template v-for="(deal, index) in list">
        <div class="px-2" :key="index">
          <DealCard
            :deal="deal"
            :currentVideo="currentVideo"
            @select-item="selectVideo"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/form/LoadingSpinner.vue';
import DealCard from './DealCard.vue';

export default {
  name: 'CardListDeals',
  components: {
    LoadingSpinner,
    DealCard,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    loadingList: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentVideo: '',
    };
  },
  methods: {
    selectVideo(videoId) {
      this.currentVideo = videoId;
    },
  },
};
</script>

<template>
  <div class="form-label-group input-group mb-3">
    <input
      :type="inputType"
      class="form-control"
      :id="id"
      :class="{
        'is-valid': status === FormStatus.DONE,
        'is-invalid': status === FormStatus.FAIL,
      }"
      :aria-describedby="`button-${id}`"
      :placeholder="inputLabel"
      :value="value"
      @input="$emit('input-form', $event.target.value)"
    >
    <label :for="id">{{ inputLabel }}</label>
    <div class="input-group-append">
      <button
        type="button"
        class="btn btn-primary"
        :id="`button-${id}`"
        :disabled="!enabled"
        @click="$emit('post-form')"
      >
        <span
          v-if="status === FormStatus.POST"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        >
        </span>
        {{ buttonLabel }}
      </button>
    </div>
    <small
      v-if="status === FormStatus.DONE || status === FormStatus.FAIL"
      :class="{
        'valid-feedback': status === FormStatus.DONE,
        'invalid-feedback': status === FormStatus.FAIL,
      }"
    >{{ feedback }}</small>
  </div>
</template>

<script>
import { FormStatus } from '@/utils/formStatus';

export default {
  name: 'InputGroupButton',
  data() {
    return {
      FormStatus,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    status: {
      type: String, // 'INIT' | 'POST' | 'DONE' | 'FAIL'
      default: null,
    },
    feedback: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'number',
    },
  },
};
</script>

<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="d-flex mb-3 pb-3 border-bottom">
        <img src="./img/profile.png" alt="" class="">
        <div class="ml-3">
          <h5 class="card-title mb-1" v-if="isRecent">닉네임: {{ content.creatorNickname }}</h5>
          <h5 class="card-title mb-1" v-else>공개ID: {{ content.creatorPublicId }}</h5>
          <p class="card-text">{{ content.stockName }}({{ content.stockCode }}) {{ content.type === 'BUY' ? '매수' : '매도' }}요청</p>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <p>{{ content.registTime | date_short }}</p>
        <button class="btn btn-primary" @click="onClick">{{ isRecent ? '차단' : '해제' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntentsBlocksCard',
  props: {
    content: {
      type: Object,
      required: true,
    },
    cardType: {
      type: String,
      required: true,
    },
  },
  computed: {
    isRecent() {
      return this.cardType === 'recent';
    },
  },
  methods: {
    onClick() {
      this.$emit('select-card', { ...this.content });
    },
  },
};
</script>

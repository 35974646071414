<template>
  <div id="app" class="app">
    <AppBrowser />
    <AppLoading v-if="!isAuthenticated && loadingData"/>
    <template v-else>
      <AppNavbar v-if="!cooperation" />
      <AngelNavbar v-else-if="showAngel" />
      <TudalNavbar v-else-if="showTudal" />
      <AppNavbarNotice v-if="!cooperation" />
      <router-view></router-view>
      <AppFooter v-if="!cooperation" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppNavbar from './views/app/AppNavbar.vue';
import AppNavbarNotice from './views/app/AppNavbarNotice.vue';
import AppFooter from '@/components/app/AppFooter.vue';
import AppLoading from '@/components/app/AppLoading.vue';
import AngelNavbar from './views/angelLeague/AngelNavbar.vue';
import TudalNavbar from './views/tudal/TudalNavbar.vue';
import AppBrowser from '@/components/app/AppBrowser.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppNavbarNotice,
    AppFooter,
    AppLoading,
    AngelNavbar,
    TudalNavbar,
    AppBrowser,
  },
  computed: {
    ...mapGetters('userInfomation', [
      'loadingData',
      'isAuthenticated',
    ]),
    showAngel() {
      const { meta } = this.$route;
      return meta && meta.angel;
    },
    showTudal() {
      const { meta } = this.$route;
      return meta && meta.tudal;
    },
    cooperation() {
      const { meta } = this.$route;
      return meta && (meta.angel || meta.tudal);
    },
  },
};
</script>

<style scoped>
.app {
  margin-top: 3.5rem;
}
</style>

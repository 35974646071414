<template>
  <div>
    <p>조합의 비상장주식 연간 양도내역 및 개별 조합원별료 안분된 양도명세파일을 다운로드 받으실 수 있습니다.</p>
    <div class="border-top mt-5">
      <template v-if="list.length">
        <div class="py-3 border-bottom" v-for="(el, index) in list" :key="index">
          <div class="d-flex justify-content-between">
            <h6>{{ display(el.info2) }}</h6>
            <small>{{ el.dateIdx | date_str }}</small>
          </div>
          <div class="bg-snow p-2">
            <div>
              <a
                class="btn btn-link btn-sm"
                target="_blank"
                download
                :href="`${FintechDocuApi}/uid/${el.docuKey}`"
              ><IconFiletypePDF class="icon-sm"/></a>
              양도증빙
            </div>
            <div>
              <a
                class="btn btn-link btn-sm"
                target="_blank"
                download
                :href="`${FintechDocuApi}/file/${el.info1}`"
              ><IconFiletypeXLS class="icon-sm"/></a>
              홈텍스신고용
            </div>
          </div>
        </div>
      </template>
      <div class="py-3 border-bottom" v-else>
        <div class="bg-warning p-2">발행된 문서가 없습니다.</div>
      </div>
    </div>
  </div>
</template>

<script>
// import IconDownload from '@/components/icons/IconDownload.vue';
import IconFiletypePDF from '@/components/icons/IconFiletypePDF.vue';
import IconFiletypeXLS from '@/components/icons/IconFiletypeXLS.vue';
import { FintechDocuApi } from '@/utils/constants';
const GainsMap = {
  A: '상반기',
  B: '하반기',
  C: '국외',
};

export default {
  name: 'DocsGains',
  components: {
    // IconDownload,
    IconFiletypePDF,
    IconFiletypeXLS,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      FintechDocuApi,
    };
  },
  methods: {
    display(info) {
      const year = info.slice(0, 4);
      const type = info.slice(4);
      return `${year}년 ${GainsMap[type]}`;
    },
  },
};
</script>

import {
  INPUT_KEYWORD__NEGO_STOCKS,
  RESET_KEYWORD__NEGO_STOCKS,
  REQUEST_LIST__NEGO_STOCKS,
  RECEIVE_LIST__NEGO_STOCKS,
  FAILURE_LIST__NEGO_STOCKS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  stocks: [],
  keyword: '',
  status: 'INIT',
};

const getters = {
  loadingStatus: state => state.status === 'REQUEST',
  filteredStocks: state => {
    const { keyword, stocks } = state;
    if (!keyword || keyword.length < 1 || stocks.length === 0) {
      return [];
    }

    return stocks.filter(
      (stock) => (stock.name.includes(keyword)
        || stock.name.includes(keyword.toUpperCase())
        || stock.code.includes(keyword)
      )
    );
  },
};

const mutations = {
  [INPUT_KEYWORD__NEGO_STOCKS] (state, keyword) {
    state.keyword = keyword;
  },
  [RESET_KEYWORD__NEGO_STOCKS] (state) {
    state.keyword = '';
  },
  [REQUEST_LIST__NEGO_STOCKS] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__NEGO_STOCKS] (state, data) {
    state.stocks = [...data];
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__NEGO_STOCKS] (state) {
    state.status = 'FAILURE';
  },
};

const actions = {
  fetchStocks({ state, commit }) {
    const { stocks } = state;
    if (stocks.length) return;

    commit(REQUEST_LIST__NEGO_STOCKS);

    api.get('/public/stocks/M50').then(
      response => commit(RECEIVE_LIST__NEGO_STOCKS, response.data),
      error => commit(FAILURE_LIST__NEGO_STOCKS, { error }),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="d-flex justify-content-between">
    <div class="flex-grow-1 flex-shrink-1 text-truncate mr-1">
      <h6 class="text-truncate mb-0" :title="stockName">{{ stockName }}</h6>
      <p class="mb-0 stock-code">{{ stockCode }}</p>
    </div>
    <div class="flex-shrink-0 text-center">
      <h6 class="mb-0">{{ isSeller ? '매도' : '매수' }}</h6>
      <p class="mb-0 stock-code">{{ isSender ? 'OUT' : 'IN' }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntentsCardHeader',
  props: {
    isSeller: {
      type: Boolean,
      required: true,
    },
    isSender: {
      type: Boolean,
      required: true,
    },
    stockName: {
      type: String,
      required: true,
    },
    stockCode: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.stock-code {
  font-size: 14px;
  line-height: 1.2;
}
</style>

import _ from 'underscore';
import {
  SELECT__INVESTOR_SCORING,
  CHECK__INVESTOR_SCORING,
  SCORE__INVESTOR_SCORING,
  ANSWER__INVESTOR_SCORING,
  INIT__INVESTOR_SCORING,
  INPUT__INVESTOR_SCORING,
  POST__INVESTOR_SCORING,
  DONE__INVESTOR_SCORING,
  FAIL__INVESTOR_SCORING,
} from '@/store/mutation-types';
import INDI from './investorScoringIndi.json';
// import TYPE from '@/store/json/investorScoringType.json';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    mobileForSign: '',
  },
  questions: _.map(INDI, q => ({
    ...q,
  })),
  error: {},
  status: 'INIT',
  score: 0,
  answer: '',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
  // currentType: (state) => {
  //   const { score } = state;
  //   const [type] = _.filter(TYPE, t => {
  //     const [min, max] = t.score;
  //     return score >= min && score <= max;
  //   });
  //   return type;
  // },
  showMobileForSign: state => !!state.error.kakao,
};

const mutations = {
  [SELECT__INVESTOR_SCORING] (state, { qIndex, aIndex }) {
    const { questions } = state;
    const currentQuestion = _.find(questions, (q) => q.qIndex === qIndex);
    const { multiple, selected, score, options } = currentQuestion;
    const index = _.indexOf(selected, aIndex);
    const { aScore } = _.find(options, (o) => o.aIndex === aIndex);
    let newSelected;
    let newScore;

    if (multiple) {
      if (index !== -1) {
        newSelected = [
          ...selected.slice(0, index),
          ...selected.slice(index + 1)
        ];

        const scoreArry = [];
        _.each(options, o => {
          if (_.contains(newSelected, o.aIndex)) {
            scoreArry.push(o.aScore);
          }
        });
        newScore = scoreArry.length ? _.max(scoreArry) : 0;
      } else {
        const insertIdx = _.sortedIndex(selected, aIndex);
        newSelected = [
          ...selected.slice(0, insertIdx),
          aIndex,
          ...selected.slice(insertIdx)
        ];
        newScore = score > aScore ? score : aScore ;
      }
    } else {
      if (index === 0) {
        newSelected = [];
        newScore = 0;
      } else {
        newSelected = [ aIndex ];
        newScore = aScore;
      }
    }

    currentQuestion.selected = newSelected;
    currentQuestion.score = newScore;
  },
  [CHECK__INVESTOR_SCORING] (state) {
    const { questions } = state;
    const invalid = {};
    _.each(questions, q => {
      if (!q.score) {
        invalid[q.qIndex] = '질문을 확인해 주세요.';
      }
    });
    state.error = { ...invalid };
  },
  [SCORE__INVESTOR_SCORING] (state) {
    const { questions } = state;
    const newScore = questions.reduce((acc, que) => {
      return acc + que.score;
    }, 0);
    state.score = newScore * 2;
    state.status = 'SCORE';
  },
  [ANSWER__INVESTOR_SCORING] (state) {
    const { questions } = state;
    const answer = _.map(questions, ({ qIndex, selected }) => ({ [qIndex]: selected }));
    state.answer = JSON.stringify(answer);

    router.push({
      name: 'INVESTOR_RESULT',
    });
  },
  [INIT__INVESTOR_SCORING] (state) {
    const questions = INDI;
    state.questions = _.map(questions, q => {
      return {
        ...q,
        selected: [],
        score: 0,
      };
    });
    state.error = {};
    state.status = 'INIT';
    state.score = 0;
    state.answer = '';
  },
  [INPUT__INVESTOR_SCORING] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST__INVESTOR_SCORING] (state) {
    state.status = 'POST';
  },
  [DONE__INVESTOR_SCORING] (state) {
    state.status = 'DONE';
  },
  [FAIL__INVESTOR_SCORING] (state, error) {
    state.status = 'FAIL';
    state.error = { ...error };
  },
};

const actions = {
  checkForm({ commit, getters }) {
    commit(CHECK__INVESTOR_SCORING);
    if (getters.invalidForm) {
      return;
    }

    commit(SCORE__INVESTOR_SCORING);
    commit(ANSWER__INVESTOR_SCORING);
  },
  submitForm({ state, commit }) {
    commit(POST__INVESTOR_SCORING);

    const { score, answer } = state;
    const { mobileForSign } = state.form;
    const data = { score, answer };
    if (mobileForSign) {
      data.mobileForSign = mobileForSign;
    }

    api.post('/users/investor/scoring', data).then(
      () => commit(DONE__INVESTOR_SCORING),
      error => commit(FAIL__INVESTOR_SCORING, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

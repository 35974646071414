<template>
  <div class="form-group">
    <button
      class="btn btn-secondary btn-block"
      type="submit"
      :disabled="loadingStatus || !enabledForm"
    >
      <template v-if="!loadingStatus">
        {{ label }}
      </template>
      <template v-else>
        <span class="spinner-border spinner-border-sm m-1" role="status" aria-hidden="true"></span> Loading...
      </template>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    loadingStatus: {
      type: Boolean,
      default: false,
    },
    enabledForm: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="container my-5">
    <h1 class="pt-5">404 페이지를 찾을 수 없습니다.</h1>
    <p>웹 사이트 이용에 대한 문의 사항은 아래 번호로 문의 해주세요.</p>
    <ul>
      <li>운영일자: 주말, 공휴일 제외</li>
      <li>운영시간: 09:00 ~ 16:00</li>
      <li>전화번호: 02-560-****</li>
    </ul>
    <button class="btn btn-primary">홈으로 이동</button>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

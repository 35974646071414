<template>
  <div class="form-group mb-3">
    <div class="custom-control custom-checkbox pl-0">
      <IconCheckCircleFill
        class="icon-sm mr-1"
        :class="[value ? 'text-secondary' : 'text-light']"
        @click="toggleValue"
      />
      <input
        type="checkbox"
        class="custom-control-input d-none"
        :class="{ 'is-invalid': error }"
        :id="id"
        :checked="value"
        @change="$emit('input-form', $event.target.checked)"
      >
      <label class="" :for="id">{{ label }} <small class="text-muted">({{ requireLabel }})</small></label>
      <router-link class="float-right" :to="{ name: to }">[보기]</router-link>
      <div class="invalid-feedback">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import IconCheckCircleFill from '@/components/icons/IconCheckCircleFill.vue';

export default {
  name: 'InputCheckbox',
  components: {
    IconCheckCircleFill,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
    },
    error: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: 'terms',
    },
    requireLabel: {
      type: String,
      default: '필수'
    },
  },
  methods: {
    toggleValue() {
      this.$emit('input-form', !this.value);
    },
  },
};
</script>

<template>
  <div>
    <LoadingSpinner v-if="loading" />
    <div v-else-if="list.length === 0" class="alert alert-light">
      등록된 리포트가 없습니다.
    </div>
    <div class="row row-cols-1 row-cols-md-2 no-gutters mx-n2" v-else>
      <div class="col mb-3 px-2" v-for="item in list" :key="item.companyNewsUid">
        <div class="card h-100" >
          <div class="card-body p-2">
            <div class="d-flex mb-2">
              <small
                class="bg-primary py-1 px-2 mr-2 text-white flex-shrink-0 align-self-center"
                disabled
              >{{ item.type | reports_type }}</small>
              <div class="d-flex flex-wrap">
                <template v-for="(el, index) in item.companyCodes" >
                  <router-link
                    class="btn-link mr-2"
                    :key="index"
                    :to="{
                      name: '__COMPANY_VIEW__',
                      params: { companyCode: el.companyCode },
                    }"
                  >
                    <small class="card-text text-primary text-truncate py-1">{{ el.companyName }}</small>
                  </router-link>
                </template>
              </div>
            </div>
            <div class="d-flex flex-column report-content">
              <h6 class="card-title mb-0">
                <a
                  class="text-reset"
                  :href="`${NegoStockApi}/public/cover/reports/files/${item.analReportFileKey}`"
                  target="_blank"
                  download
                >{{ item.title }}</a>
              </h6>
              <small class="mt-auto mb-0">{{ item.publishDate | date_short_1 }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/form/LoadingSpinner.vue';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'CardListReports',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      NegoStockApi,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>
.report-content {
  height: 4rem;
}
.card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<template>
  <AppModal title="예약구매" v-if="corporation">
    <div class="">
      <h5 class="text-center mb-4">[법인고객 예약구매 안내]</h5>
      <p class="text-danger font-weight-bold">현재, 네고스탁 서비스는 개인회원만 이용 가능합니다. 예약구매를 희망하시는 법인 고객의 경우에는 아래 “매수의향제출” 버튼을 눌러 의향을 제출해주시면 담당자가 이후 절차를 안내해 드립니다.</p>
      <p class="">담당자에 전화 문의 시, <mark class="bg-warning font-weight-bold">담당자는 주식매수 위한 절차 등에 관한 안내만 가능</mark>하며 회사와 관련한 장래전망 등에 대한 문의에는 일체 답변을 드리지 않습니다.</p>
      
      <router-link
        class="btn btn-outline-primary btn-block my-4"
        :to="{
          name: 'PUBLIC_INTENT',
          params: { dealType: 'helpstock' },
          query: { dealKey: corporation.dealKey },
        }"
      >매수의향제출</router-link>

      <p class="mb-0">담당자: {{ corporation.dealCharger }} ({{ corporation.dealChargeDept }})</p>
      <p class="mb-0 ">연락처: {{ corporation.dealChargerContact | tel }}</p>
    </div>
    <template v-slot:footer>
      <button
        class="btn btn-outline-dark btn-sm"
        @click="() => $emit('hide-corporation')"
      >닫기</button>
    </template>
  </AppModal>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue';

export default {
  name: 'DealCorporationModal',
  components: {
    AppModal,
  },
  props: {
    corporation: {
      type: Object,
      default: null,
    },
  },
};
</script>

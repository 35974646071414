<template>
  <div>
    <FormHead
      title="비밀번호변경"
      text="소문자(a~z), 숫자(0~9) 포함 8자리 이상 입력해주세요."
    />
    <form @submit.prevent="submitForm">
      <InputEmail
        autocomplete="username"
        id="username"
        :value="user['username']"
        hidden
      />
      <InputPassword
        autocomplete="current-password"
        placeholder="현재비밀번호"
        id="currentPasswd"
        :value="form['currentPasswd']"
        :error="error['currentPasswd']"
        @input-form="(value) => inputForm({ key: 'currentPasswd', value })"
      />
      <InputPassword
        autocomplete="new-password"
        placeholder="새 비밀번호"
        id="newPasswd"
        :value="form['newPasswd']"
        :error="error['newPasswd']"
        @input-form="(value) => inputForm({ key: 'newPasswd', value })"
      />
      <InputPassword
        autocomplete="new-password"
        placeholder="비밀번호 확인"
        id="confirmPasswd"
        :value="form['confirmPasswd']"
        :error="error['confirmPasswd']"
        @input-form="(value) => inputForm({ key: 'confirmPasswd', value })"
      />
      <SubmitButton
        label="수정"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__USER_PASSWORD } from '@/store/mutation-types';
import FormHead from '@/components/auth/form/FormHead';
import InputEmail from '@/components/auth/form/InputEmail.vue';
import InputPassword from '@/components/auth/form/InputPassword.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';

export default {
  name: 'PasswordView',
  components: {
    FormHead,
    InputEmail,
    InputPassword,
    SubmitButton,
  },
  computed: {
    ...mapState('userPassword', [
      'form',
      'error',
    ]),
    ...mapGetters('userPassword', [
      'enabledForm',
      'loadingForm',
    ]),
    ...mapState('userInfomation', [
      'user',
    ]),
  },
  methods: {
    ...mapMutations('userPassword', {
      'inputForm': INPUT_FORM__USER_PASSWORD,
    }),
    ...mapActions('userPassword', [
      'submitForm',
    ]),
  },
};
</script>

<template>
  <div class="lp-join">
    <NegoLinks group="group2"/>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>가입신청 진행 중이신 신기술투자조합 내역입니다. <br class="d-md-none">조합참여액 입금 후 전자서명을 완료해 주세요.</p>
        </div>
        <div class="col-12" v-if="!list.length">
          <div class="alert alert-primary text-center">가입진행 중인 조합 건이 없습니다.</div>
        </div>
        <div class="col-lg-6 col-xl-4" v-for="(deal, index) in list" :key="index">
          <LPJoinCard
            :deal="deal"
            @sign-item="signItem(deal)"
          />
        </div>
      </div>
      <LPNotice/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import LPJoinCard from '@/components/limitedPartner/LPJoinCard.vue';
import LPNotice from '@/components/limitedPartner/LPNotice.vue';

export default {
  name: 'LPJoin',
  components: {
    NegoLinks,
    LPJoinCard,
    LPNotice,
  },
  computed: {
    ...mapState('lpJoin', [
      'list',
    ]),
    ...mapGetters('lpJoin', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('lpJoin', [
      'requestList',
    ]),
    signItem({ subscriptionKey }) {
      if (subscriptionKey) {
        this.$router.push({
          name: '__AGREE_FILE__',
          params: { subscriptionKey },
          query: { proInvestor: true },
        });
      }
    },
  },
  mounted() {
    this.requestList();
  },
};
</script>

<style lang="scss" scoped>
.lp-join {
  min-height: 95vh;
}
</style>

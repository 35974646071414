<template>
  <div class="form-label-group mb-3">
    <select
      class="form-control"
      :id="id"
      :value="value"
      @input="$emit('input-form', parseInt($event.target.value, 10))">
      <option disabled value="">선택하세요.</option>
      <option :value="expirations[0].date">{{ expirations[0].label }}</option>
      <option :value="expirations[1].date">{{ expirations[1].label }}</option>
      <option :value="expirations[2].date">{{ expirations[2].label }}</option>
    </select>
    <label :for="id" class="col-form-label">{{ label }}</label>
  </div>
</template>

<script>
function getExpirations() {
  const now = Date.now();
  const hour = 1000 * 60 * 60;
  return [
    { label: '12시간', date: now + (hour * 12) },
    { label: '24시간', date: now + (hour * 24) },
    { label: '48시간', date: now + (hour * 48) },
  ];
}

export default {
  name: 'SelectExpiration',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '유효기간',
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      expirations: getExpirations(),
    };
  },
  mounted() {
    this.$emit('input-form', this.expirations[2].date);
  },
};
</script>

<template>
  <div class="row mt-3">
    <template v-for="({ label, transType, toStatus }, idx) in buttons">
      <div
        :key="idx"
        :class="{
          'col': buttons.length === 1,
          'col-8': buttons.length === 2 && idx === 0,
          'col-4': buttons.length === 2 && idx === 1,
        }"
      >
        <button
          :key="idx"
          class="btn btn-outline-light btn-sm btn-block"
          @click="$emit('click-button', { transType, toStatus })"
        >{{ label }}</button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'IntentsCardButtons',
  props: {
    buttons: {
      type: Array,
      default: null,
    },
  },
};
</script>

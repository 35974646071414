import _ from 'underscore';
import {
  CHECK_FORM__SIGN_UP,
  INPUT_FORM__SIGN_UP,
  POST_FORM__SIGN_UP,
  DONE_FORM__SIGN_UP,
  FAIL_FORM__SIGN_UP,
  CLOSE_MODAL__SIGN_UP,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';
import {
  validEmail,
  validPassword,
} from '@/utils/expressions';

const INIT_FORM = {
  userEmail: '',
  userPasswd: '',
  confirmPasswd: '',
  agreement: false,
  privacy: false,
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
  showModal: false,
};

const getters = {
  invalidForm: state => _.keys(state.error).length > 0,
  loadingForm: state => state.status === 'POST',
};

const mutations = {
  [CHECK_FORM__SIGN_UP] (state) {
    const { userEmail, userPasswd, confirmPasswd, agreement, privacy } = state.form;
    const error = {};
  
    if (!userEmail) {
      error.userEmail = '이메일을 입력해 주세요.';
    } else if (!validEmail(userEmail)) {
      error.userEmail = '이메일 형식을 확인해 주세요.';
    }
    if (!userPasswd) {
      error.userPasswd = '비밀번호를 입력해 주세요.';
    } else if (!validPassword(userPasswd)) {
      error.userPasswd = '비밀번호 형식을 확인해 주세요.';
    }
    if (!confirmPasswd) {
      error.confirmPasswd = '비밀번호를 입력해 주세요.';
    } else if (!validPassword(confirmPasswd)) {
      error.confirmPasswd = '비밀번호 형식을 확인해 주세요.';
    }
    if (userPasswd !== confirmPasswd) {
      error.userPasswd = '비밀번호가 일치하지 않습니다.';
      error.confirmPasswd = '비밀번호가 일치하지 않습니다.';
    }
    if (!agreement) {
      error.agreement = '이용약관에 대한 동의가 필요합니다.';
    }
    if (!privacy) {
      error.privacy = '개인정보이용방침에 대한 동의가 필요합니다.';
    }

    state.error = { ...error };
  },
  [INPUT_FORM__SIGN_UP] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__SIGN_UP] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__SIGN_UP] (state, { username, userKey }) {
    if (username && userKey) {
      state.form = { ...INIT_FORM };
    }
    state.showModal = true;
    state.status = 'DONE';
  },
  [FAIL_FORM__SIGN_UP] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
  [CLOSE_MODAL__SIGN_UP] (state) {
    state.showModal = false;

    router.replace({ name: 'sign_in' });
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__SIGN_UP);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__SIGN_UP);

    const { userEmail, userPasswd, confirmPasswd } = state.form;
    api.post('/auth/signup', { userEmail, userPasswd, confirmPasswd }).then(
      response => commit(DONE_FORM__SIGN_UP, response.data),
      error => commit(FAIL_FORM__SIGN_UP, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="price">
    <div class="d-flex justify-content-between">
      <h6 class="mb-1">가격</h6>
      <h6 class="mb-1">{{ price | currency }}<small> 원</small></h6>
    </div>
    <div class="d-flex justify-content-between">
      <h6 class="mb-1">수량</h6>
      <h6 class="mb-1">{{ quantity | currency }}<small> 주</small></h6>
    </div>
    <div class="d-flex justify-content-between border-top pt-2">
      <h6 class="mb-1">대금</h6>
      <h6 class="mb-1">{{ price * quantity | currency }} <small>원</small></h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntentsCardTotalPrice',
  props: {
    price: {
      type: Number,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  @include nego-font(1.125rem, 300, 1.25, 0.2px, $primary);
}
</style>

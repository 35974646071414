<template>
  <div class="container py-5">
    <CompanyHeader
      :data="company"
      :loading="loadingStatus"
    />
    <div class="mb-5">
      <p class="h5 mb-4">리포트</p>
      <CardListReports
        :list="reports"
        :loading="loadingStatus"
      />
    </div>
    <div class="mb-5">
      <p class="h5 mb-4">관련영상</p>
      <CardListVideos
        :list="videos"
        :loading="loadingStatus"
      />
    </div>
    <div class="mb-5">
      <p class="h5 mb-4">관련기사</p>
      <CardListNews
        :list="news"
        :enableDelete="false"
        :loading="loadingStatus"
      />
    </div>
    <div class="mb-5">
      <div class="alert alert-light">
        회사와 관련한 좋은 소개 유튜브 영상, 언론기사가 있다면 주소(URL)를 알려주세요.
        <div class="d-flex justify-content-end mt-2">
          <button
            class="btn btn-outline-primary btn-sm rounded-0"
            @click="checkLink('videos')"
          >유튜브 영상</button>
          <button
            class="btn btn-outline-primary btn-sm rounded-0 ml-2"
            @click="checkLink('news')"
          >언론기사</button>
        </div>
      </div>
      <CompanySuggest v-if="showSuggest"/>
    </div>
    <div class="mb-5">
      <div class="alert alert-light">
        회사담당자(대리인) 또는 주주이신가요? 정보수정, 자금조달, 매매(중개)를 요청하실 수 있습니다.
        <div class="d-flex justify-content-end mt-2">
          <button
            class="btn btn-outline-primary btn-sm rounded-0 ml-2"
            @click="checkLink2"
          >요청하기</button>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <ul class="nav nav-tabs mb-4">
        <li class="nav-item" v-for="(el, index) in links" :key="index">
          <a
            href=""
            class="nav-link h5 mb-0"
            :class="{ 'active': el.name === currentTab }"
            @click.prevent="clickTab(el.name)"
          >{{ el.label }}</a>
        </li>
      </ul>
      <CompanyTableInfos
        v-show="currentTab === 'company'"
        :data="company"
        :loading="loadingStatus"
      />
      <CompanyTablePostings
        v-show="currentTab === 'postings'"
        :list="postings"
        :loading="loadingStatus"
      />
    </div>
    <div class="pt-5">
      <CompanyNotice/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import CompanyHeader from '@/components/company/CompanyHeader.vue';
import CardListReports from '@/components/company/CardListReports.vue';
import CardListVideos from '@/components/company/CardListVideos.vue';
import CardListNews from '@/components/company/CardListNews.vue';
import CompanyTableInfos from '@/components/company/CompanyTableInfos.vue';
import CompanyTablePostings from '@/components/company/CompanyTablePostings.vue';
import CompanyNotice from '@/components/company/CompanyNotice.vue';
import CompanySuggest from './CompanySuggest.vue';
import { SET__COMPANY_SUGGEST } from '@/store/mutation-types';

export default {
  name: 'Company',
  components: {
    CompanyHeader,
    CardListReports,
    CardListVideos,
    CardListNews,
    CompanyTableInfos,
    CompanyTablePostings,
    CompanyNotice,
    CompanySuggest,
  },
  data() {
    return {
      currentTab: 'company',
      links: [
        { name: 'company',  label: '기업정보' },
        { name: 'postings', label: '공시자료' },
      ],
    };
  },
  computed: {
    ...mapGetters('userInfomation', [
      'isAuthenticated',
      'isPreUser',
    ]),
    ...mapState('companyBrief', [
      'company',
      'reports',
      'videos',
      'news',
      'postings',
    ]),
    ...mapGetters('companyBrief', [
      'loadingStatus',
    ]),
    ...mapGetters('companySuggest', [
      'showSuggest',
    ]),
  },
  methods: {
    ...mapMutations('companySuggest', {
      setSuggest: SET__COMPANY_SUGGEST,
    }),
    ...mapActions('companyBrief', [
      'requestData',
    ]),
    clickTab(value) {
      this.currentTab = value;
    },
    checkLink(modeType) {
      const { companyCode } = this.company;

      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'sign_in',
          query: { redirect_path: `/company/view/${companyCode}` },
        });
        return;
      }

      if (this.isPreUser) {
        this.$router.push({
          name: 'user_mobile',
        });
        return;
      }

      this.setSuggest({
        companyCode,
        modeType,
      });
    },
    checkLink2() {
      const { companyCode, name: companyName } = this.company;

      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'sign_in',
          query: {
            redirect_path: '/company/update',
            companyCode,
            companyName,
          },
        });
        return;
      }

      if (this.isPreUser) {
        this.$router.push({
          name: 'user_mobile',
        });
        return;
      }

      this.$router.push({
        name: '__COMPANY_UPDATE__',
        query: {
          companyCode,
          companyName,
        },
      });
    },
  },
  mounted() {
    const { companyCode } = this.$route.params;
    if (companyCode) {
      this.requestData({ companyCode });
    }
  },
};
</script>

<style>
.border-t {
  border-top: 6px solid #e9ecef;
}
.border-b {
  border-bottom: 6px solid #e9ecef;
}
</style>

<template>
  <div class="bg-primary text-white p-3 mt-4">
    <div class="d-flex justify-content-between">
      <h5>출자원금(잔액)</h5>
      <p>{{ countInvests }}개 조합 | {{ totalInvestAmount | won3}} 백만원</p>
    </div>
    <div class="spinner-border" role="status" v-if="loadingList">
      <span class="sr-only">Loading...</span>
    </div>
    <div
      v-else-if="list.length === 0"
      class="alert alert-light text-center"
    >투자한 신기술조합 건이 없습니다.</div>
    <template v-else>
      <button
        v-for="(item, index) in list"
        :key="index"
        class="btn btn-gray btn-block text-dark p-2 mb-1"
        @click="() => clickDeals(item)"
      >
        <div class="d-flex justify-content-between">
          <span class="flex-grow-1 flex-shrink-1 text-left text-truncate">{{ item.dealName }}</span>
          <span
            class="flex-shrink-0 text-right p-0"
          >{{ item.investAmount | won3 }} <small>백만원</small> <IconArrowRightShort /></span>
        </div>
      </button>
    </template>
    <router-link
      class="btn btn-secondary rounded-0 btn-block mt-4"
      :to="{ name: '__INVEST_PLACEMENTS_DOCUMENTS__' }"
    >세금 신고자료(출자확인서) 다운로드</router-link>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IconArrowRightShort from '@/components/icons/IconArrowRightShort.vue';

export default {
  name: 'InvestPlacementsList',
  components: {
    IconArrowRightShort,
  },
  computed: {
    ...mapState('investPlacements', [
      'list',
    ]),
    ...mapGetters('investPlacements', [
      'loadingList',
      'countInvests',
      'totalInvestAmount',
    ]),
  },
  methods: {
    ...mapActions('investPlacements', [
      'requestList',
    ]),
    clickDeals({ dealKey, dealName }) {
      this.$router.push({
        name: '__DEALS_LIBRARY__',
        query: { dealKey, dealName },
      });
    },
  },
  mounted() {
    this.requestList();
  },
};
</script>
<style>

</style>

<template>
  <div>
    <TermsHeader title="네고스탁 비상장증권 중개에 관한 약관"/>
    <TermsContents :contents="contents"/>
  </div>
</template>

<script>
import TermsHeader from '@/components/terms/TermsHeader.vue';
import TermsContents from '@/components/terms/TermsContents.vue';
import contents from './agreementB';

export default {
  name: 'TermsTradingView',
  components: {
    TermsHeader,
    TermsContents,
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

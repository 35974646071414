<template>
  <ul class="nav border-bottom mb-4">
    <li
      class="nav-item"
      v-for="(link, index) in links"
      :key="index"
    >
      <a
        class="nav-link d-inline-block font-weight-bold"
        :class="{'active': current === link.name}"
        @click="$emit('select-link', link.name)"
      >{{ link.label }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavLinks',
  props: {
    current: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>
.nav-link {
  color: black;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}
.nav-link.active {
  border-bottom: 4px solid black;
  margin-bottom: -4px;
}
</style>

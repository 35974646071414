import {
  GET_KEYWORD__NEGO_KEYWORDS,
  ADD_KEYWORD__NEGO_KEYWORDS,
  DELETE_ALL__NEGO_KEYWORDS,
} from '@/store/mutation-types';

const NEGOSTOCK_KEYWORDS = 'NEGOSTOCK_KEYWORDS';
const KEYWORD_LIST_MAX = 10;

const state = {
  list: [],
};

const getters = {
};

const mutations = {
  [GET_KEYWORD__NEGO_KEYWORDS] (state) {
    const list = JSON.parse(localStorage.getItem(NEGOSTOCK_KEYWORDS));
    if (list) {
      state.list = [...list];
    }
  },
  [ADD_KEYWORD__NEGO_KEYWORDS] (state, { stock }) {
    const { list } = state;
    const index = list.findIndex(el => el.name === stock.name && el.code === stock.code);
    if (index > -1) {
      state.list = [
        stock,
        ...list.slice(0, index),
        ...list.slice(index + 1),
      ];
    }
    else {
      if (list.length < KEYWORD_LIST_MAX) {
        state.list = [
          stock,
          ...list,
        ];
      } else {
        state.list = [
          stock,
          ...list.slice(0, list.length - 1),
        ];
      }
    }
    localStorage.setItem(NEGOSTOCK_KEYWORDS, JSON.stringify(state.list));
  },
  [DELETE_ALL__NEGO_KEYWORDS] (state) {
    localStorage.removeItem(NEGOSTOCK_KEYWORDS);
    state.list = [];
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import _ from 'underscore';
import {
  CHECK_FORM__COMPANY_REGISTER,
  INIT_FORM__COMPANY_REGISTER,
  INPUT_FORM__COMPANY_REGISTER,
  POST_FORM__COMPANY_REGISTER,
  DONE_FORM__COMPANY_REGISTER,
  FAIL_FORM__COMPANY_REGISTER,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  purpose: '',
  companyName: '',
  bizNo: '',
  message: '',
  file: '',
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [CHECK_FORM__COMPANY_REGISTER] (state) {
    const { purpose, companyName, bizNo } = state.form;
    const error = {};

    if (!purpose) {
      error.purpose = '요청사유를 선택해 주세요.';
    }
    if (!companyName) {
      error.companyName = '회사명을 입력해 주세요.';
    }
    if (!bizNo) {
      error.bizNo = '사업자등록번호를 입력해 주세요.';
    }

    state.error = { ...error };
  },
  [INIT_FORM__COMPANY_REGISTER] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__COMPANY_REGISTER] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__COMPANY_REGISTER] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__COMPANY_REGISTER] (state) {
    state.status = 'DONE';

    router.replace({ name: '__OTC_HOT__' });
  },
  [FAIL_FORM__COMPANY_REGISTER] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
     commit(CHECK_FORM__COMPANY_REGISTER);

     if (!getters.invalidForm) {
       dispatch('submitForm');
     }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__COMPANY_REGISTER);

    const { purpose, companyName, bizNo, message, file } = state.form;
    const formData = new FormData();
    formData.append('purpose', purpose);
    formData.append('companyName', companyName);
    formData.append('bizNo', bizNo);
    if (message) {
      formData.append('message', message);
    }
    if (file) {
      formData.append('file', file);
    }

    api.post('/company/ask/regist', formData).then(
      reponse => commit(DONE_FORM__COMPANY_REGISTER, reponse.data),
      error => commit(FAIL_FORM__COMPANY_REGISTER, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <NegoLinks group="group4"/>
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-12 col-lg-6">
          <p class="text-left text-lg-center">
            회원간매매를 위해 비상장주식 매물을 등록합니다.
          </p>
          <NegoLinkButtons />
          <NegoButton
            label="비상장주식 종목을 추가하세요"
            @click-button="routeSearch"
          />
        </div>
      </div>
      <div v-if="loadingList">
        loading...
      </div>
      <template v-else>
        <div class="row justify-content-center" v-if="list.length === 0">
          <div class="col-12">
            <div class="alert alert-primary text-center" role="alert">등록하신 종목이 없습니다.</div>
          </div>
        </div>
        <template v-else>
          <div class="px-3 mb-5">
            <MyStocksCard
              v-for="(mystock, idx) in list"
              :key="idx"
              :mystock="mystock"
              @route-update="routeUpdate"
              @route-trans="routeTrans"
            />
          </div>
          <NegoPagination
            v-if="params"
            :params="params"
            @select-page="routePage"
          />
        </template>
      </template>
    </div>
    <MyStocksNotice/>
    <MyStocksModal
      v-if="showModal"
      :textModal="textModal"
      @close-modal="showModal = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import NegoButton from '@/components/nego/NegoButton.vue';
import NegoPagination from '@/components/nego/NegoPagination.vue';
import NegoLinkButtons from '@/components/nego/NegoLinkButtons.vue';
import MyStocksCard from '@/components/mystocks/MyStocksCard.vue';
import MyStocksModal from '@/components/mystocks/MyStocksModal.vue';
import MyStocksNotice from '@/components/mystocks/MyStocksNotice.vue';

export default {
  name: 'MyStocksView',
  components: {
    NegoLinks,
    NegoButton,
    NegoPagination,
    NegoLinkButtons,
    MyStocksCard,
    MyStocksModal,
    MyStocksNotice,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('myStocks', [
      'list',
      'params',
      'error',
    ]),
    ...mapGetters('myStocks', [
      'loadingList',
    ]),
    ...mapGetters('myStocksExpires', [
      'enableBuyEdit',
      'enableSellEdit',
      'enableEdit',
    ]),
  },
  data() {
    return {
      textModal: '',
      showModal: false,
    };
  },
  methods: {
    ...mapActions('myStocks', [
      'requestMyStocks',
    ]),
    ...mapActions('myStocksExpires', [
      'getExpires',
    ]),
    request() {
      const page = Number.parseInt(this.$route.query.page) || 1;
      this.requestMyStocks({ page });
      this.getExpires();
    },
    routePage(page) {
      this.$router.push({ name: 'mystocks', query: { page }});
      this.request();
    },
    routeSearch() {
      if (!this.enableBuyEdit && !this.enableSellEdit) {
        this.textModal = '추가';
        this.showModal = true;
        return;
      }
      this.$router.push({ name: 'mystocks_search' });
    },
    routeUpdate(mystock) {
      const now = Date.now();
      const { expiration, intentCount } = mystock;
      if ((expiration < now || intentCount >= 3) && !this.enableEdit(mystock.type)) {
        this.textModal = '수정';
        this.showModal = true;
        return;
      }
      this.$router.push({
        name: 'mystocks_update',
        params: { myStockKey: mystock.myStockKey },
        query: {
          stockInfoUid: mystock.stockInfoUid,
          stockName: mystock.stockName,
          type: mystock.type,
          quantity: mystock.quantity,
          price: mystock.limitPrice,
          negoLimit: mystock.limitRate.toString(),
        },
      });
    },
    routeTrans(mystock) {
      this.$router.push({
        name: 'mystocks_trans',
        params: { myStockKey: mystock.myStockKey },
        query: {
          transType: 'delete',
          stockInfoUid: mystock.stockInfoUid,
          stockName: mystock.stockName,
        },
      });
    },
  },
  mounted() {
    if (this.user && !this.user.preUser) {
      this.request();
    }
  },
};
</script>

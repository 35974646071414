<template>
  <div class="form-group">
    <button
      class="btn btn-secondary btn-block"
      type="submit"
      :disabled="isLoading || !enabledForm"
    >
      <span
        v-if="isLoading"
        class="spinner-border spinner-border-sm m-1"
        role="status"
        aria-hidden="true"
      ></span>{{ label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    enabledForm: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <FormHead
      title="비밀번호 찾기"
      text="등록된 내 회원정보로 찾으실 수 있습니다."
    />
    <form>
      <InputGroupButton
        inputType="text"
        id="email"
        inputLabel="이메일"
        buttonLabel="확인"
        :value="email['value']"
        :status="email['status']"
        :feedback="email['feedback']"
        :enabled="enabledEmail && !sendEmail"
        @input-form="(value) => inputForm({ key: 'email', value })"
        @post-form="checkEmail"
      />
      <template v-if="hasMobile">
        <InputGroupButton
          id="number"
          inputLabel="휴대전화 ('-' 없이 입력)"
          buttonLabel="요청"
          :value="number['value']"
          :status="number['status']"
          :feedback="number['feedback']"
          :enabled="enabledNumber"
          @input-form="(value) => inputForm({ key: 'number', value })"
          @post-form="checkNumber"
        />
        <InputGroupButton
          id="code"
          inputLabel="인증번호 (6자리 숫자)"
          buttonLabel="확인"
          :value="code['value']"
          :status="code['status']"
          :feedback="code['feedback']"
          :enabled="enabledCode"
          @input-form="(value) => inputForm({ key: 'code', value })"
          @post-form="checkCode"
        />
      </template>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/auth/form/FormHead';
import InputGroupButton from '@/components/user/form/InputGroupButton.vue';
import { INPUT_FORM__FIND_PASSWORD, INIT_FORM__FIND_PASSWORD } from '@/store/mutation-types';

export default {
  name: 'FindPasswordView',
  components: {
    FormHead,
    InputGroupButton,
  },
  computed: {
    ...mapState('authFindPassword', [
      'email',
      'number',
      'code',
      'hasMobile',
      'sendEmail',
    ]),
    ...mapGetters('authFindPassword', [
      'enabledEmail',
      'enabledNumber',
      'enabledCode',
    ]),
  },
  methods: {
    ...mapMutations('authFindPassword', {
      'inputForm': INPUT_FORM__FIND_PASSWORD,
      'initForm': INIT_FORM__FIND_PASSWORD,
    }),
    ...mapActions('authFindPassword', [
      'checkEmail',
      'checkNumber',
      'checkCode',
    ]),
  },
  destroyed() {
    this.initForm();
  },
};
</script>

<template>
  <div class="">
    <div class="text-center border-bottom my-4 py-2 h5">
      비상장주식 매수약정
    </div>
    <div class="alert alert-warning" v-if="!data.payIn">
      매수대금 입금이 확인되지 않았습니다. 매수대금을 가상계좌로 입금하신 후, 전자서명을 진행해주세요.
    </div>

    <div class="mt-5">
      <InputCheckboxNo
        id="form_A"
        label="아래의 매수조건을 확인함"
        :value="form.A"
        :error="valid.A"
        @input-form="(value) => inputForm({ key: 'A', value })"
      />
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>매수고객</td>
            <td>
              <p class="mb-0">성명: {{ data.nameInfo }}</p>
              <p class="mb-0">생년월일: <samp>{{ data.birthInfo }}</samp></p>
              <p class="mb-0">휴대전화: <samp>{{ data.mobileForSign | phone }}</samp></p>
            </td>
          </tr>
          <tr>
            <td>종목</td>
            <td>{{ data.stockName }} (<samp>{{ data.stockInfoUid }}</samp>)</td>
          </tr>
          <tr>
            <td>수량 및 단가</td>
            <td>{{ data.offerQuantity | currency }} 주 | {{ data.offerPrice | currency }} 원</td>
          </tr>
          <tr>
            <td>매매금액</td>
            <td>{{ data.offerQuantity * data.offerPrice | currency }} 원</td>
          </tr>
          <tr>
            <td>주식결제일</td>
            <td>
              <samp v-if="data.stockInfoUid.length === 6">{{ data.shareTransDate | date_short }}</samp>
              <span v-else>명의개서</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-5">
      <InputCheckbox
        id="form_B"
        label="상품설명서 관련하여 다음을 확인함"
        :value="form.B"
        :error="valid.B"
        @input-form="(value) => inputForm({ key: 'B', value })"
        linkFile="/files/product_guide.pdf"
        linkName="비상장주식상품설명서.pdf"
      />
      <div class="border p-3">
        <template v-if="data.suggested">
          <ul class="pl-3">
            <li>본인은 아래의 직원으로부터 비상장주식 투자의 특성, 원금손실 가능성 등에 대해 설명을 듣고 이해하였음.</li>
            <li>판매직원이 설명한 내용이 상품설명서와 동일함.</li>
          </ul>
          <p class="mb-0">판매직원</p>
          <p class="mb-0">{{ data.salesPersonDuty }} {{ data.salesPersonName }} (<samp>{{ data.salesPersonContact | tel }}</samp>)</p>
        </template>
        <template v-else>
          <ul class="pl-3 mb-0">
            <li>본인은 상품설명서에 기술된 비상장주식 투자의 특성, 원금손실 가능성 등에 대해 이해하고 있음.</li>
            <li>본 매수약정은 판매직원의 투자권유 없이 본인의 판단으로 결정하였음.</li>
          </ul>
        </template>
      </div>
    </div>

    <div class="mt-5" v-if="isLowerExpert(data.score)">
      <InputCheckbox
        id="form_C"
        label="투자성향에 부적합한 상품임을 확인함"
        :value="form.C"
        :error="valid.C"
        @input-form="(value) => inputForm({ key: 'C', value })"
        linkFile="/files/unproper_confirm.pdf"
        linkName="투자성향부적합거래확인.pdf"
      />
      <div class="border p-3 mb-3">
        <p>본인은 비상장주식 투자가 본인의 투자성향에 적합하지 않음을 이해하고 있으며, 유의사항에 대해 이해하였음</p>
        <ul class="pl-3">
          <li>고객투자성향: <mark>{{ userScoreLabel(data.score) }}</mark></li>
          <li>금융투자상품명: 비상장주식</li>
          <li>금융투자상품의 위험등급: 초고위험</li>
        </ul>
        <table class="table table-sm table-bordered mb-0">
          <thead class="table-light">
            <tr>
              <th>투자성향</th>
              <th>금융투자상품 위험등급</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>공격투자형</td>
              <td>초고위험이하 상품</td>
            </tr>
            <tr>
              <td>적극투자형</td>
              <td>고위험</td>
            </tr>
            <tr>
              <td>위험중립형</td>
              <td>중위험</td>
            </tr>
            <tr>
              <td>안정투자형</td>
              <td>저위험</td>
            </tr>
            <tr>
              <td>안정형</td>
              <td>초저위험</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <template v-if="loadingForm">
      <div class="spinner-border my-3" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </template>
    <template v-else>
      <div v-if="error['subscriptionKey'] || error['kakao'] || error['mobileForSign']" class="my-4">
         <div class="alert alert-warning">
            {{ error.subscriptionKey || error.kakao }}
        </div>
      </div>
      <div v-if="data.status === 'SIGN_EXPIRE' || error['kakao'] || error['mobileForSign']" class="my-4">
        <InputNumber
          label="매수고객 명의 휴대전화번호"
          id="mobileForSign"
          :value="form['mobileForSign']"
          :error="valid['mobileForSign']"
          @input-form="(value) => inputForm({ key: 'mobileForSign', value })"
        />
      </div>
    </template>

    <div class="my-4">
      <div
        v-if="showedAlert"
        class="alert alert-warning"
      >{{ MESSAGES[data.status] }}</div>
      <button
        class="btn btn-kakao btn-lg btn-block"
        @click="checkForm"
        :disabled="!data.payIn || !enabledForm"
      >
        <IconChatFill class="btn-kakao-symbol mr-1 mb-1" />
        <span class="btn-kakao-label"> 카카오페이 전자서명</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import InputCheckboxNo from '@/components/intents/form/InputCheckbox.vue';
import InputCheckbox from '@/components/form/InputCheckbox.vue';
import InputNumber from '@/components/form/InputNumber.vue';
import IconChatFill from '@/components/icons/IconChatFill.vue';
import { INPUT_FORM__BUY_SIGN } from '@/store/mutation-types';
import { userScoreLabel, isLowerExpert } from '@/utils/checkUser';

const MESSAGES = {
  SIGN_ASK: '카카오톡 카카오페이 인증요청을 확인해주세요.',
  SIGN_FIN: '전자서명을 완료하셨습니다.',
  SIGN_EXPIRE: '카카오페이 인증 유효기간이 경과되었습니다.',
  BROKEN: '매수 신청이 취소되었습니다.',
};

export default {
  name: 'BuySignView',
  components: {
    InputCheckboxNo,
    InputCheckbox,
    InputNumber,
    IconChatFill,
  },
  data() {
    return {
      MESSAGES,
      userScoreLabel,
      isLowerExpert,
    };
  },
  computed: {
    ...mapState('buySign', [
      'data',
      'form',
      'valid',
      'error',
    ]),
    ...mapGetters('buySign', [
      'showedAlert',
      'enabledForm',
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('buySign', {
      'inputForm': INPUT_FORM__BUY_SIGN,
    }),
    ...mapActions('buySign', [
      'requestData',
      'postForm',
      'checkForm',
    ]),
  },
  mounted() {
    const { helperStockTxKey } = this.$route.params;
    this.requestData({ helperStockTxKey });
  },
};
</script>

<style lang="scss" scoped>
.btn-kakao {
  background-color: #FEE500;
}
.btn-kakao-symbol,
.btn-kakao-label {
  color: #000000;
}
.btn-kakao-label {
  opacity: 0.85;
}
</style>

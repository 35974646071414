<template>
  <div class="alert alert-light px-2" v-if="files.length">
    <small>[투자참고자료]</small>
    <small v-if="!files[0].sellAssignFileKey" class="text-danger ml-2">※ 전문투자자만 다운로드 가능</small>
    <ol class="mb-0 pl-4 font-md">
      <li v-for="(file, index) in files" :key="index" class="text-break">
        <a
          v-if="file.sellAssignFileKey"
          :href="`${NegoStockApi}/prostocks/files/${file.sellAssignFileKey}`"
          target="_blank"
          class=" p-0"
          download
        ><span>{{ file.name }}</span><IconDownload class="icon-xs ml-2" /></a>
        <span v-else>{{ file.name }}</span>
      </li>
    </ol>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload.vue';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'CardSellAssignFiles',
  components: {
    IconDownload,
  },
  data() {
    return {
      NegoStockApi,
    };
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
};
</script>


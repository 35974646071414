<template>
  <div class="form-label-group mb-3">
    <select
      :disabled="disabled"
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      :value="value"
      @input="$emit('input-form', $event.target.value)">
      <option disabled value="">선택하세요.</option>
      <template v-for="({label, value}, index) in options">
        <option :key="index" :value="value">{{ label }}</option>
      </template>
    </select>
    <label :for="id" class="col-form-label">{{ label }}</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'SelectOptions',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

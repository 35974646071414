import {
  REQUEST_DATA__PAYMENT_DOCS,
  RECEIVE_DATA__PAYMENT_DOCS,
  FAILURE_DATA__PAYMENT_DOCS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  data: {
    nameInfo: '',
    birthInfo: '',
    mobileForSign: '',
    stockName: '',
    stockInfoUid: '',
    offerQuantity: 0,
    offerPrice: 0,
    contractDocuCode: '',
  },
  dataError: {},
  dataStatus: 'INITIAL',
};

const getters = {
  loadingData: state => state.dataStatus === 'REQUEST',
};

const mutations = {
  [REQUEST_DATA__PAYMENT_DOCS] (state) {
    state.dataStatus = 'REQUEST';
  },
  [RECEIVE_DATA__PAYMENT_DOCS] (state, data) {
    state.data = { ...data };
    state.dataStatus = 'RECEIVE';
  },
  [FAILURE_DATA__PAYMENT_DOCS] (state, error) {
    state.dataError = { ...error };
    state.dataStatus = 'FAILURE';
  },
};

const actions = {
  requestData({ commit }, { helperStockTxKey }) {
    commit(REQUEST_DATA__PAYMENT_DOCS);

    api.get(`/public/helperstock/${helperStockTxKey}`).then(
      reponse => commit(RECEIVE_DATA__PAYMENT_DOCS, reponse.data),
      error => commit(FAILURE_DATA__PAYMENT_DOCS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

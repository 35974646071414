import _ from 'underscore';
import {
  CHECK_FORM__MYSTOCKS_UPDATE,
  INIT_FORM__MYSTOCKS_UPDATE,
  INPUT_FORM__MYSTOCKS_UPDATE,
  POST_FORM__MYSTOCKS_UPDATE,
  DONE_FORM__MYSTOCKS_UPDATE,
  FAIL_FORM__MYSTOCKS_UPDATE,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  myStockKey: '',
  stockInfoUid: '',
  stockName: '',
  type: 'BUY',
  quantity: 0,
  price: 0,
  totalPrice: 0,
  negoLimit: '0',
  export38: false,
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [CHECK_FORM__MYSTOCKS_UPDATE] (state) {
    const { quantity, price, totalPrice } = state.form;
    const error = {};

    if (!quantity) {
      error.quantity = '수량을 입력해 주세요.';
    }
    if (!price) {
      error.price = '가격을 입력해 주세요.';
    }
    if (totalPrice < 1000) {
      error.totalPrice = '거래금액은 1천원 이상 가능합니다.';
    }

    state.error = { ...error };
  },
  [INIT_FORM__MYSTOCKS_UPDATE] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__MYSTOCKS_UPDATE] (state, { key, value }) {
    state.form[key] = value;

    if (key === 'quantity' || key === 'price') {
      state.form['totalPrice'] = state.form['quantity'] * state.form['price'];
    }
    if (key === 'type') {
      state.form['export38'] = false;
    }
  },
  [POST_FORM__MYSTOCKS_UPDATE] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__MYSTOCKS_UPDATE] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'DONE';

    router.replace({ name: 'mystocks' });
  },
  [FAIL_FORM__MYSTOCKS_UPDATE] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__MYSTOCKS_UPDATE);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
 },
  submitForm({ state, commit }) {
    commit(POST_FORM__MYSTOCKS_UPDATE);

    const { myStockKey, quantity, price, negoLimit, export38 } = state.form;
    api.post(`/mystocks/${myStockKey}`, { quantity, price, negoLimit, export38 }).then(
      response => commit(DONE_FORM__MYSTOCKS_UPDATE, response.data),
      error => commit(FAIL_FORM__MYSTOCKS_UPDATE, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import {
  REQUEST_LIST__MYSTOCKS,
  RECEIVE_LIST__MYSTOCKS,
  FAILURE_LIST__MYSTOCKS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  params: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST_LIST__MYSTOCKS] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__MYSTOCKS] (state, { contents, ...params }) {
    state.list = [...contents];
    state.params = { ...params };
    state.error = {};
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__MYSTOCKS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestMyStocks({ commit }, { page = 1, scale = 6 }) {
    commit(REQUEST_LIST__MYSTOCKS);

    api.get('/mystocks', { params: { page, scale }}).then(
      response => commit(RECEIVE_LIST__MYSTOCKS, response.data),
      error => commit(FAILURE_LIST__MYSTOCKS, error),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

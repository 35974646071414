<template>
  <div>
    <FormHead
      title="회원가입 약관"
      text="이용약관 &amp; 개인정보이용방침"
    />
    <div class="my-4">
      <h6>이용약관</h6>
      <article class="terms bg-light p-2">
        <TermsContents :contents="agreementA"/>
      </article>
    </div>
    <div class="my-4">
      <h6>개인정보이용방침</h6>
      <article class="terms bg-light p-2">
        <TermsContents :contents="privacy"/>
      </article>
    </div>
    <div class="text-right">
      <button
        class="btn btn-primary"
        @click="routeBack"
      >뒤로</button>
    </div>
  </div>
</template>

<script>
import FormHead from '@/components/auth/form/FormHead';
import TermsContents from '@/components/terms/TermsContents.vue';
import agreementA from '@/views/terms/agreementA';
import privacy from '@/views/terms/contentsPrivacy';

export default {
  name: 'TermsView',
  components: {
    FormHead,
    TermsContents,
  },
  data() {
    return {
      agreementA,
      privacy,
    };
  },
  methods: {
    routeBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.terms {
  height: 10rem;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

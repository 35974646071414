<template>
  <div>
    <NegoLinks group="group2"/>
    <div class="container">
      <p class="text-center d-none d-lg-block">
        코리아에셋투자증권이 매매를 안전하게 처리해 드립니다.
      </p>
      <div class="row" v-if="loadingList && list.length === 0">
        <div class="col-md-6 col-xl-4 mb-4" v-for="(empth, idx) in 6" :key="idx">
          <NegoEmptyCard/>
        </div>
      </div>
      <template>
        <div class="row" v-if="list.length === 0">
          <div class="col-12">
            <div class="alert alert-primary text-center" role="alert">매매진행 중인 건이 없습니다.</div>
          </div>
        </div>
        <template v-else>
          <div class="row justify-content-center" v-if="list && list.length">
            <div class="col-md-6 col-xl-4 mb-4" v-for="(transaction, idx) in list" :key="idx">
              <TransactionsProgressCardHelperStock
                v-if="transaction.origin === 'INTENT_HELP'"
                :transaction="transaction"
              />
              <TransactionsProgressCard
                v-else
                :transaction="transaction"
              />
            </div>
          </div>
          <NegoPagination
            v-if="params"
            :params="params"
            @select-page="routePage"
          />
        </template>
      </template>

      <div class="mt-5">
        <ul class="nav nav-tabs justify-content-end mb-0">
          <li class="nav-item" v-for="(el, index) in links" :key="index">
            <a
              href=""
              class="nav-link h5 mb-0"
              :class="{ 'active': el.name === currentTab }"
              @click.prevent="currentTab = el.name"
            >{{ el.label }}</a>
          </li>
        </ul>
      </div>
    </div>
    <ProgressNotice2 v-show="currentTab === 'notice2'" />
    <ProgressNotice v-show="currentTab === 'notice'" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import NegoEmptyCard from '@/components/nego/NegoEmptyCard.vue';
import NegoPagination from '@/components/nego/NegoPagination.vue';
import TransactionsProgressCard from '@/components/transactions/TransactionsProgressCard.vue';
import TransactionsProgressCardHelperStock from '@/components/transactions/TransactionsProgressCardHelperStock.vue';
import ProgressNotice from '@/components/transactions/ProgressNotice.vue';
import ProgressNotice2 from '@/components/transactions/ProgressNotice2.vue';

export default {
  name: 'TransactionsProgressView',
  components: {
    NegoLinks,
    NegoEmptyCard,
    NegoPagination,
    TransactionsProgressCard,
    TransactionsProgressCardHelperStock,
    ProgressNotice,
    ProgressNotice2,
  },
  data() {
    return {
      currentTab: 'notice2',
      links: [
        { name: 'notice2', label: '바로매수 | 예약구매' },
        { name: 'notice', label: '회원간매매' },
      ],
    };
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('transactionsProgress', {
      list: state => state.transactions.list,
      params: state => state.transactions.params,
      error: state => state.transactions.error,
    }),
    ...mapGetters('transactionsProgress', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('transactionsProgress', [
      'requestList',
    ]),
    request() {
      const page = Number.parseInt(this.$route.query.page) || 1;
      this.requestList({ page });
    },
    routePage(page) {
      this.$router.push({ name: 'transactions_progress', query: { page } });
      this.stopCycle();
      this.startCycle();
    },
    startCycle() {
      this.request();
      this._intervId = setInterval(this.request, 30000);
    },
    stopCycle() {
      if (this._intervId) {
        clearInterval(this._intervId);
      }
    },
  },
  mounted() {
    if (this.user && !this.user.preUser) {
      this.startCycle();
    }
  },
  destroyed() {
    this.stopCycle();
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  font-size: 1rem;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
</style>

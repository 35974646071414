<template>
  <div class="deals-library">
    <InvestLinks />
    <DealsCharges />
    <div class="d-flex justify-content-end mb-3">
      <router-link
        class="btn btn-primary btn-sm rounded-0 ml-2"
        :to="{
          name: '__DEALS_QNA__',
          query: $route.query,
        }"
      >담당자에게 문의하기</router-link>
    </div>
    <template v-if="!isProInvestor">
      <div class="alert bg-warning">
        상품설명서 등의 자료는 전문투자자만 열람가능 합니다. 회원정보 페이지에서 전문투자자 등록이 가능합니다.
        <br>
        <p class="mb-0 text-right">
          <router-link
          class="btn btn-link btn-sm pr-0 text-dark"
          :to="{
            name: 'user_infomation',
            hash: '#infomation-regist',
          }"
        >회원정보페이지로 가기 &gt;</router-link>
        </p>
      </div>
      <HelpIntentNotice />
    </template>
    <div class="alert bg-warning" v-else-if="$route.query.beforeReserveTime">
      투자안내서(IM), 조합규약(안) 등의 자료는 사전예약 접수일에 공개됩니다.
    </div>
    <template v-else>
      <DealsContents />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InvestLinks from '@/components/limitedPartner/InvestLinks';
import DealsCharges from '@/views/dealsLibrary/DealsCharges';
import DealsContents from '@/views/dealsLibrary/DealsContents';
import HelpIntentNotice from '@/components/helpIntent/HelpIntentNotice.vue';

export default {
  name: 'FundingLibrary',
  components: {
    InvestLinks,
    DealsCharges,
    DealsContents,
    HelpIntentNotice,
  },
  computed: {
    ...mapGetters('userInfomation', [
      'isProInvestor',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.deals-library {
  min-height: 100vh;
}
</style>

<template>
  <div class="market-board">
    <NegoLinks group="group1"/>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="row">
            <div class="col">
              <MarketsSearch label="검색어를 입력해주세요." ref="searchForm"/>
            </div>
            <button
              class="btn rounded-0 mr-3 mb-3"
              :class="[isTotalList ? 'btn-secondary' : 'btn-outline-secondary' ]"
              @click="setTotalList"
              :disabled="isTotalList"
            >전체</button>
          </div>
          <SelectType
            id="type"
            :leftLabel="leftLabel"
            leftValue="SELL"
            :rightLabel="rightLabel"
            rightValue="BUY"
            :value="stock.type"
            @input-form="(value) => inputData({ key: 'type', value })"
          />
          <div class="d-flex justify-content-end d-block d-md-none">
            <router-link class="btn btn-link" :to="{name: 'home_service'}">
              회원간 매매중개서비스 이용안내 &gt;
            </router-link>
          </div>
          <div v-if="loadingList">
            <span class="spinner-border spinner-border-sm text-primary ml-3" role="status"></span>
          </div>
          <div class="my-5">
            <MarketsNotice
              v-if="!isTotalList && !currentList.length"
              :stock="stock"
              :hasContents="false"
            />
            <template v-if="currentList.length">
              <MarketsCard
                v-for="(userstock, idx) in currentList"
                :key="idx"
                :userstock="userstock"
                @request-nego="() => requestNego(userstock)"
                @order-sellassigns="() => orderSellAssigns(userstock)"
                @intent-sellassigns="() => intentSellAssigns(userstock)"
              />
              <div class="border-top-bold">
                <MarketsNotice
                  v-if="!isTotalList"
                  :stock="stock"
                  :hasContents="true"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import SelectType from '@/components/intents/form/SelectType.vue';
import MarketsSearch from '@/components/markets/MarketsSearch.vue';
import MarketsCard from '@/components/markets/MarketsCard.vue';
import MarketsNotice from '@/components/markets/MarketsNotice.vue';
import { INPUT_STOCK__MARKETS_BOARD } from '@/store/mutation-types';

export default {
  name: 'MarketsBoardView',
  components: {
    NegoLinks,
    SelectType,
    MarketsSearch,
    MarketsCard,
    MarketsNotice,
  },
  computed: {
    ...mapState('marketsBoard', [
      'stock',
      'error',
    ]),
    ...mapGetters('marketsBoard', [
      'isTotalList',
      'currentList',
      'countSell',
      'countBuy',
      'loadingList'
    ]),
    ...mapState('userInfomation', [
      'user',
    ]),
    leftLabel() {
      return `팝니다 (${this.countSell})`;
    },
    rightLabel() {
      return `삽니다 (${this.countBuy})`;
    },
  },
  methods: {
    ...mapMutations('marketsBoard', {
      'inputData': INPUT_STOCK__MARKETS_BOARD,
    }),
    ...mapActions('marketsBoard', [
      'requestListPublic',
      'requestList',
    ]),
    requestNego(userstock) {
      const { stockInfoUid, title: stockName, price, quantity, publicId: contact, myStockKey } = userstock;
      const type = this.stock.type === 'SELL' ? 'BUY' : 'SELL';

      this.$router.push({
        name: 'intents_maker',
        query: { stockInfoUid, stockName, type, price, quantity, contact, referSite: 'my', myStockKey },
      });
    },
    orderSellAssigns(userstock) {
      const { stockInfoUid, title: stockName, price, quantity, minimumOrderQuantity, sellAssignKey } = userstock;

      this.$router.push({
        name: 'sell_assigns_order_user',
        query: { stockInfoUid, stockName, price, quantity, minimumOrderQuantity, sellAssignKey },
      });
    },
    intentSellAssigns(userstock) {
      const { stockInfoUid, title: stockName, price, quantity, minimumOrderQuantity, sellAssignKey } = userstock;

      this.$router.push({
        name: 'sell_assigns_intent',
        query: { stockInfoUid, stockName, price, quantity, minimumOrderQuantity, sellAssignKey },
      });
    },
    setTotalList() {
      this.inputData({ key: 'stockInfoUid', value: 'all' });
      this.inputData({ key: 'stockName', value: '' });
      this.requestList();
      this.$router.replace({ name: 'markets_board' });
      this.$refs.searchForm.setQuery();
    },
  },
  mounted() {
    const { stockInfoUid, stockName } = this.$route.query;
    if (stockInfoUid && stockName) {
      this.inputData({ key: 'stockInfoUid', value: stockInfoUid });
      this.inputData({ key: 'stockName', value: stockName });
    } else {
      this.inputData({ key: 'stockInfoUid', value: 'all' });
    }
    if (this.user.publicId) {
      this.requestList();
    } else {
      this.requestListPublic();
    }
  },
};
</script>

<style lang="scss" scoped>
.market-board {
  min-height: 95vh;
}
.title {
  @include nego-title;
}
.border-top-bold {
  border-top: 5px solid #dee2e6 !important;
}
</style>

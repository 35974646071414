import api from '@/api/axios';
import {
  REQUEST__DEALS_CHARGES,
  RECEIVE__DEALS_CHARGES,
  FAILURE__DEALS_CHARGES,
  REMOVE__DEALS_CHARGES,
} from '@/store/mutation-types';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  optionsEmail: state => state.list.map(c => ({
    value: c.email,
    label: `${c.name} / ${c.dept} / ${c.email}`,
  })),
};

const mutations = {
  [REQUEST__DEALS_CHARGES] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__DEALS_CHARGES] (state, data) {
    state.list = data;
    state.status = 'RECEIVE';
  },
  [FAILURE__DEALS_CHARGES] (state, error) {
    state.error = error;
    state.status = 'FAILURE';
  },
  [REMOVE__DEALS_CHARGES] (state, dealChargeKey) {
    state.list = state.list.filter(charge => {
      return charge.dealChargeKey !== dealChargeKey;
    });
  },
};

const actions = {
  requestList({ commit }, { dealKey }) {
    commit(REQUEST__DEALS_CHARGES);

    api.get(`/public/deals/charges/${dealKey}`).then(
      response => commit(RECEIVE__DEALS_CHARGES, response.data),
      error => commit(FAILURE__DEALS_CHARGES, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

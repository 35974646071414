<template>
  <div>
    <FormHead
      title="회원가입"
      text="카카오계정 또는 이메일로 간단하게 가입할 수 있습니다."
    />
    <KakaoButton />
    <p class="text-center my-4">or</p>
    <router-link class="btn btn-primary btn-block btn-lg mb-3" :to="{name: 'sign_up'}">
      <h5 class="mb-0"><IconEnvelopeFill icon="envelope"/> 이메일로 가입하기</h5>
    </router-link>
  </div>
</template>

<script>
import FormHead from '@/components/auth/form/FormHead';
import KakaoButton from '@/components/auth/form/KakaoButton';
import IconEnvelopeFill from '@/components/icons/IconEnvelopeFill.vue';

export default {
  name: 'AuthView',
  components: {
    FormHead,
    KakaoButton,
    IconEnvelopeFill,
  },
};
</script>

import {
  SET__COMPANY_SUGGEST,
  INPUT__COMPANY_SUGGEST,
  POST__COMPANY_SUGGEST,
  DONE__COMPANY_SUGGEST,
  FAIL__COMPANY_SUGGEST,
  INIT__COMPANY_SUGGEST,
} from '@/store/mutation-types';
import api from '@/api/axios';

const INIT_FORM = {
  companyCode: '',
  companyName: '',
  link: '',
  modeType: '',
};

const state = {
  form: {
    ...INIT_FORM,
  },
  error: '',
  status: 'INIT',
};

const getters = {
  showSuggest: state => state.form.companyCode && state.form.modeType,
  postForm: state => state.status === 'POST',
  doneForm: state => state.status === 'DONE',
  failForm: state => state.status === 'FAIL',
};

const mutations = {
  [SET__COMPANY_SUGGEST] (state, { companyCode, modeType }) {
    state.form = {
      ...INIT_FORM,
      companyCode,
      modeType,
    };
  },
  [INPUT__COMPANY_SUGGEST] (state, link) {
    state.form.link = link;
  },
  [POST__COMPANY_SUGGEST] (state) {
    state.status = 'POST';
  },
  [DONE__COMPANY_SUGGEST] (state) {
    state.status = 'DONE';
    state.form.link = '';
  },
  [FAIL__COMPANY_SUGGEST] (state, error) {
    const { newsLink, videoLink } = error;
    state.error = newsLink || videoLink;
    state.status = 'FAIL';
  },
  [INIT__COMPANY_SUGGEST] (state) {
    state.status = 'INIT';
    state.form = { ...INIT_FORM };
  },
};

const actions = {
  submitForm({ commit, state }) {
    commit(POST__COMPANY_SUGGEST);

    const { companyCode, modeType, link } = state.form;
    const data = { companyCode };
    if (modeType === 'videos') {
      data['videoLink'] = link;
    } else if (modeType === 'news') {
      data['newsLink'] = link;
    }

    api.post('/companies/suggest', data).then(
      response => commit(DONE__COMPANY_SUGGEST, response.data),
      error => commit(FAIL__COMPANY_SUGGEST, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <ContentsList
      :contents="list"
      :isStaff="false"
      @select-item="selectItem"
    />
    <DealsContentsComments
      v-if="currentDealContentsKey"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ContentsList from '@/components/deals/ContentsList';
import DealsContentsComments from './DealsContentsComments';
import { SELECT__DEALS_CONTENTS } from '@/store/mutation-types';

export default {
  name: 'DealsContents',
  components: {
    ContentsList,
    DealsContentsComments,
  },
  computed: {
    ...mapState('dealsContents', [
      'list',
      'currentDealContentsKey',
    ]),
  },
  methods: {
    ...mapMutations('dealsContents', {
      selectItem: SELECT__DEALS_CONTENTS,
    }),
    ...mapActions('dealsContents', [
      'requestList',
    ]),
  },
  mounted() {
    const { dealKey } = this.$route.query;
    this.requestList({ dealKey });
  },
};
</script>

<template>
  <div class="pb-3">
    <template v-if="status === 'KAKAO'">
      <FormHead
        title="이메일찾기 결과"
        text="해당 회원정보는 카카오계정으로 가입되어 있습니다."
      />
      <h6 class="text-center border-top border-bottom py-5 mb-3">
        카카오계정으로 로그인 해주세요
      </h6>
      <KakaoButton />
    </template>

    <template v-if="status === 'EMAIL'">
      <FormHead
        title="이메일찾기 결과"
        text="해당 휴대전화번호로 가입된 이메일입니다."
      />
      <h6 class="text-center border-top border-bottom py-5 mb-3">
        {{ $route.query.email }}
      </h6>
      <div class="row">
        <div class="col-6">
          <router-link
            class="btn btn-primary btn-block"
            :to="{ name: 'sign_in' }"
          >로그인 하기</router-link>
        </div>
        <div class="col-6">
          <router-link
            class="btn btn-outline-primary btn-block"
            :to="{ name: 'find_password' }"
          >비밀번호 찾기</router-link>
        </div>
      </div>
    </template>

    <template v-if="status === 'PASSWORD'">
      <FormHead
        title="비밀번호찾기 결과"
        text=""
      />
      <h6 class="text-center border-top border-bottom py-5 mb-3">
        비밀번호를 초기화 하였습니다. 로그인 해주세요.
      </h6>
      <div class="row">
        <div class="col">
          <router-link
            class="btn btn-primary btn-block"
            :to="{name: 'sign_in'}"
          >로그인 하기</router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import FormHead from '@/components/auth/form/FormHead';
import KakaoButton from '@/components/auth/form/KakaoButton';

export default {
  name: 'FindResultView',
  components: {
    FormHead,
    KakaoButton,
  },
  data() {
    return {
      status: '',
    };
  },
  mounted() {
    // 'KAKAO' | 'EMAIL' | 'PASSWORD'
    const { status } = this.$route.params;
    if (status) {
      this.status = status;
    }
  },
};
</script>

<template>
  <div class="gradient">
    <div class="container pt-5">
      <h3 class="text-center text-secondary mb-5">
        <strong>중개서비스 이용절차</strong>
      </h3>
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 mb-lg-0">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              title="지연거래 안내 영상"
              src="https://www.youtube-nocookie.com/embed/wDGod2-GpdQ?rel=0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-4 mt-lg-0">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              title="네고스탁 안내 영상"
              src="https://www.youtube-nocookie.com/embed/hOL9nG_LSn8?rel=0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeVideo',
};
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient($gray, #ffffff);
}
</style>

<template>
  <div>
    <LoadingSpinner v-if="loading" />
    <div v-else-if="list.length === 0" class="alert alert-light">
      등록된 뉴스가 없습니다.
    </div>
    <div class="row" v-else>
      <div class="col-12 mb-3" v-for="item in list" :key="item.companyNewsUid">
        <div class="card rounded-0">
          <div class="row no-gutters card-image">
            <div class="col-3 border-right h-100">
              <ImageAndIcons
                :src="item.image"
                icon="newspaper"
              />
            </div>
            <div class="col-9">
              <div class="card-body d-flex flex-column p-2 h-100">
                <h6 class="card-title">{{ item.title }}</h6>
                <div class="d-none d-md-block">
                  <p class="card-text mb-0">{{ item.snippet }}</p>
                </div>
                <div class="mt-auto d-flex justify-content-end">
                  <router-link
                    v-if="item.companyName"
                    class="btn btn-link btn-sm text-truncate p-0 mr-auto"
                    :to="{
                      name: '__COMPANY_VIEW__',
                      params: { companyCode: item.companyCode }
                    }"
                  >#{{ item.companyName }}</router-link>
                  <a class="btn btn-link btn-sm flex-shrink-0 p-0" :href="item.url" target="_blank">
                    기사보기
                  </a>
                  <span v-if="enableDelete">
                    <Dropdowns
                      btnClass="btn-outline-primary btn-sm ml-3"
                      :id="item.companyNewsUid"
                      @remove-item="$emit('remove-item', item.companyNewsUid)"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/form/LoadingSpinner.vue';
import ImageAndIcons from '@/components/icons/ImageAndIcons';
import Dropdowns from '@/components/deals/Dropdowns.vue';

export default {
  name: 'CardListNews',
  components: {
    LoadingSpinner,
    ImageAndIcons,
    Dropdowns,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title,
.card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card-title {
  -webkit-line-clamp: 2;
}
.card-text {
  -webkit-line-clamp: 3;
}

.btn-link {
  line-height: 1;
}
.card-image {
  height: 5.5rem;
  @media (min-width: 768px) {
    height: 10rem;
  }
}
</style>

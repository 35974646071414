import {
  SET_REDIRECT__NEGO_REDIRECT,
  REMOVE_REDIRECT__NEGO_REDIRECT,
} from '@/store/mutation-types';

const NEGOSTOCK_REDIRECT = 'NEGOSTOCK_REDIRECT';

const state = {
  query: JSON.parse(localStorage.getItem(NEGOSTOCK_REDIRECT)),
};

const getters = {
};

const mutations = {
  // query: { iam: xxxx }

  // query: { redirect_path: 'redirect', path: '/users/modify/update'}
  // query: { redirect_path: 'intents' }
  // query: { redirect_path: 'transactions_progress' }
  // query: { redirect_path: 'intents_maker', stockInfoUid: '2625' code: '004550', name: '대우송도개발' }
  [SET_REDIRECT__NEGO_REDIRECT] (state, query) {
    state.query = query;
    localStorage.setItem(NEGOSTOCK_REDIRECT, JSON.stringify(query));
  },
  [REMOVE_REDIRECT__NEGO_REDIRECT] (state) {
    state.query = null;
    localStorage.removeItem(NEGOSTOCK_REDIRECT);
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="container my-5">
    <div class="border p-3">
      <h6 class="title">바로매수 결제 과정</h6>
      <ul class="text pl-3">
        <li>바로 매수 신청 후, 1시간 이내 지정한 가상계좌로 매수대금을 입금하시지 않을 경우, 신청 취소됩니다.</li>
        <li>입금 확인 후 30분 이내에 미리 등록해 두신 계좌로 매수한 주식을 입고 받으실 수 있습니다.</li>
        <li>본 매수 신청이 실제 주식 매수를 100% 보장하지는 않으며, 매수가 이루어지지 않을 경우, 입금 후 최대 2시간 이내에 대금을 반환해 드립니다.</li>
      </ul>
      <h6 class="title">예약구매 결제 과정</h6>
      <ul class="text pl-3">
        <li>구매수량, 구매단가를 확인하신 후 매수대금을 지정된 가상계좌로 입금해주십시오. </li>
        <li>주식결제일에(오후 5시 이전) 구매예약 신청 후 매수대금을 입금해주신 분들에게 주식을 이체해 드립니다. </li>
        <li>예약구매 신청 시 당사의 담당자로부터 예약구매와 관련한 연락을 받으실 수도 있습니다.</li>
      </ul>
      <h6 class="">유의사항</h6>
      <ul class="text pl-3">
        <li>예약구매 신청 및 당사의 거래가능 여부 통보, 고객님의 매매대금입금이 실제 주식 매수를 100% 보장하지는 않습니다.</li>
        <li>예약구매 신청 총수량이 당사의 매수예정 수량을 초과할 경우 먼저 입금하신 고객님께 우선하여 주식을 보내드립니다. 주식을 보내드리지 못할 경우, 입금하신 대금은 즉시 반환해 드립니다.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressNotice2',
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, -1.3px);
}
</style>

<template>
  <div class="mb-5">
    <div class="mx-n2">
      <NegoLinks group="group4"/>
    </div>
    <div class="">
      <p>신기술투자조합 투자내역을 확인하시려면, <br>먼저 로그인이 필요합니다.</p>
      <KakaoButton
        iam="invest"
      />
    </div>
  </div>
</template>

<script>
import NegoLinks from '@/components/nego/NegoLinks.vue';
import KakaoButton from '@/components/auth/form/KakaoButton';

export default {
  name: 'InvestSignin',
  components: {
    NegoLinks,
    KakaoButton,
  },
};
</script>

import api from '@/api/axios';
import {
  REQUEST__NEWS_LETTER,
  RECEIVE__NEWS_LETTER,
  FAILURE__NEWS_LETTER,
} from '@/store/mutation-types';

const state = {
  form: {
    marketingAdKey: '',
    title: '',
    contentsHtml: '',
    registTime: '',
    files: [],
  },
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingData: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__NEWS_LETTER] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__NEWS_LETTER] (state, data) {
    state.status = 'RECEIVE';
    state.form = data;
  },
  [FAILURE__NEWS_LETTER] (state, error) {
    state.error = error;
    state.status = 'FAILURE';
  },
};

const actions = {
  requestData({ commit }, { marketingAdKey }) {
    commit(REQUEST__NEWS_LETTER);

    api.get(`/public/newsletter/${marketingAdKey}`).then(
      response => commit(RECEIVE__NEWS_LETTER, response.data),
      error => commit(FAILURE__NEWS_LETTER, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <AppModalSimple
    title="거래연동"
    content="연동 시 매도거래는 위탁계좌 입고 후에 매도하는 것으로 처리됩니다. 그러나 중개수수료는 변동 없이 0.2%의 수수료가 적용됩니다.<br/> 두 거래를 연동하시겠습니까?"
  >
    <div class="d-flex w-100">
      <button class="btn btn-outline-dark flex-grow-1 mr-3" @click="onLink">예</button>
      <button class="btn btn-outline-dark flex-grow-1" @click="onBack">아니요</button>
    </div>
  </AppModalSimple>
</template>

<script>
import AppModalSimple from '@/components/app/AppModalSimple.vue';
import { NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'TransactionsLinksConfirmView',
  components: {
    AppModalSimple,
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    onLink() {
      const { transactionKey, targetKey } = this.$route.params;
      const path = [
        `${NegoStockApi}/sessions/0/${FintechCommPath}/auth?path=/transactions/links`,
        `&sellingKey=${transactionKey}`,
        `&buyingKey=${targetKey}`,
      ].join('');
      window.location = path;
    },
  },
};
</script>

const PlacementPortfolio = {
  INVEST: 'INVEST',
  CONVERS_IN: 'CONVERS_IN',
  OTHER_IN: 'OTHER_IN',
  DIVIDEND: 'DIVIDEND',
  DISPOSAL: 'DISPOSAL',
  CONVERSE_OUT: 'CONVERSE_OUT',
  DISTRIBUTION: 'DISTRIBUTION',
  IMPAIRMENT: 'IMPAIRMENT',
  OTHER_OUT: 'OTHER_OUT',
};
const PLACEMENT_PORTFOLIO__TYPE = [
  { value: PlacementPortfolio.INVEST,       sign:  1, label: '투자(+)' },
  { value: PlacementPortfolio.CONVERS_IN,   sign:  1, label: '전환(+)' },
  { value: PlacementPortfolio.OTHER_IN,     sign:  1, label: '기타취득(+)' },
  { value: PlacementPortfolio.DIVIDEND,     sign:  1, label: '이자및배당(+)' },
  { value: PlacementPortfolio.DISPOSAL,     sign: -1, label: '매각(-)' },
  { value: PlacementPortfolio.CONVERSE_OUT, sign: -1, label: '전환감소(-)' },
  { value: PlacementPortfolio.DISTRIBUTION, sign: -1, label: '분배(-)' },
  { value: PlacementPortfolio.IMPAIRMENT,   sign: -1, label: '감액(-)' },
  { value: PlacementPortfolio.OTHER_OUT,    sign: -1, label: '기타감소(-)' },
];

/**
 * AM
 * 운용중 - 회계관리 - 신규등록(보유현금)
 */
const PlacementCash = {
  ASSET_DVIDEND: 'ASSET_DVIDEND',
  OTHER_IN: 'OTHER_IN',
  MANAGEMENT_FEE: 'MANAGEMENT_FEE',
  EXPENSE: 'EXPENSE',
  PERFORMANCE_FEE: 'PERFORMANCE_FEE',
  OTHER_OUT: 'OTHER_OUT',
  CAPITAL_CALL: 'CAPITAL_CALL',
  CAPITAL_RETURN: 'CAPITAL_RETURN',
  ASSET_DISPOSAL: 'ASSET_DISPOSAL',
  ASSET_ACQUISITION: 'ASSET_ACQUISITION',
  DISTRIBUTION: 'DISTRIBUTION',
  DISTRIBUTION_IN: 'DISTRIBUTION_IN',
};
const PLACEMENT_CASH__TYPE = [
  { value: PlacementCash.ASSET_DVIDEND,     sign:  1, label: '투자자산배당&이자(+)' },
  { value: PlacementCash.OTHER_IN,          sign:  1, label: '기타수익등(+)' },
  { value: PlacementCash.MANAGEMENT_FEE,    sign: -1, label: '관리보수(-)' },
  { value: PlacementCash.EXPENSE,           sign: -1, label: '비용지출(-)' },
  { value: PlacementCash.PERFORMANCE_FEE,   sign: -1, label: '성과보수(-)' },
  { value: PlacementCash.OTHER_OUT,         sign: -1, label: '기타(-)' },
  { value: PlacementCash.CAPITAL_CALL,      sign:  1, label: '출자납입(+)' },
  { value: PlacementCash.CAPITAL_RETURN,    sign: -1, label: '출자반환(-)' },
  { value: PlacementCash.ASSET_DISPOSAL,    sign:  1, label: '투자자산처분(+)' },
  { value: PlacementCash.ASSET_ACQUISITION, sign: -1, label: '투자자산취득(-)' },
  { value: PlacementCash.DISTRIBUTION,      sign: -1, label: '투자자현금분배(-)' },
  { value: PlacementCash.DISTRIBUTION_IN,   sign:  1, label: '투자자분배취소(+)' },
];

/**
 * AM
 * 운용중 - 회계관리 - 증권추가
 */
const StockIn = {
  INVEST: 'INVEST',
  CONVERS_IN: 'CONVERS_IN',
  OTHER_IN: 'OTHER_IN',
};
const STOCK_IN__TYPE = [
  { value: StockIn.INVEST,     label: '투자' },
  { value: StockIn.CONVERS_IN, label: '전환' },
  { value: StockIn.OTHER_IN,   label: '기타' },
];

export {
  PLACEMENT_PORTFOLIO__TYPE,
  PLACEMENT_CASH__TYPE,
  STOCK_IN__TYPE,
};

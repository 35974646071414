<template>
  <div class="">
    <NegoLinks group="group4"/>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <form>
            <LabelOnly label="공개 ID"/>
            <p class="mb-4">{{ user['publicId'] }}</p>
            <LabelOnly label="계정정보"/>
            <button class="btn btn-outline-dark btn-block btn-lg my-3" v-if="user.socialUser" disabled>
              <h5 class="mb-0"><IconChatFill/> 카카오 로그인 사용중</h5>
            </button>
            <InputLink
              v-if="!user.socialUser"
              :label="user['username']"
              :path="{name: 'reset_email'}"
            />
            <InputLink
              v-if="!user.socialUser"
              label="비밀번호"
              :path="{name: 'user_password'}"
            />
            <InputReadonlyButton
              label="닉네임"
              id="nickname"
              :value="user['nickname']"
              pathName="user_nickname"
            />
            <InputReadonlyButton
              v-if="user.mobile"
              label="휴대전화"
              id="userMobile"
              :value="user.mobile['number']"
              pathName="user_mobile"
            />
            <InputLink
              label="회원탈퇴"
              buttonLabel="신청"
              :path="{name: 'revoke'}"
            />
            <!-- <InfomationAccount
              :user="user"
            /> -->
            <LabelOnly label="네고요청 수신설정"/>
            <InputLink
              label="회원 차단"
              :path="{name: 'intents_recent'}"
            />
            <InputCheckbox
              label="등록한 종목만 네고요청 수령"
              id="configuredRecv"
              :value="user['configuredRecvSellIntent']"
              @check-form="postConfigure"
            />
            <InputLink
              label="광고성 투자정보 수신"
              :value="user['allowMarketing'] | user_allow_marketing"
              :path="{
                name: 'user_marketing',
                query: { allowMarketing: user['allowMarketing'] }
              }"
            />
          </form>
          <InfomationRegist
            id="infomation-regist"
            :user="user"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import InputReadonlyButton from '@/components/user/form/InputReadonlyButton.vue';
import InputLink from '@/components/user/form/InputLink.vue';
import InputCheckbox from '@/components/user/form/InputCheckbox.vue';
import LabelOnly from '@/components/user/form/LabelOnly.vue';
import InfomationRegist from '@/components/user/InfomationRegist.vue';
// import InfomationAccount from '@/components/user/InfomationAccount.vue';
import IconChatFill from '@/components/icons/IconChatFill.vue';

export default {
  name: 'InfomationView',
  components: {
    NegoLinks,
    InputReadonlyButton,
    InputLink,
    InputCheckbox,
    LabelOnly,
    InfomationRegist,
    // InfomationAccount,
    IconChatFill,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapGetters('userInfomation', [
      'loadingData',
    ]),
  },
  methods: {
    ...mapActions('userInfomation', [
      'requestUser',
      'postConfigure',
    ]),
  },
  mounted() {
    this.requestUser();
  },
};
</script>

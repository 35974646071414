const STATUS = {
  // 매수자
  BUYER__ACCEPT__LINKED: '매수 후 매도 예정인 건입니다.',
  BUYER__ACCEPT: '매수대금을 아래 가상계좌로<br>결제이행 10분전까지 입금해주세요.',
  BUYER__SHARE_IN__LINKED: '매수 후 매도 예정인 건입니다.',
  BUYER__SHARE_IN: '매수대금을 아래 가상계좌로<br>결제이행 10분전까지 입금해주세요.',
  BUYER__PAY_IN: '매도자의 주식입고를 기다리는 중',
  BUYER__COMPLETE_IN: '주식입고 및 대금입금이 완료되어<br>매매당사자 실명정보 확인 중',
  BUYER__SHARE_OUT: '회원님 계좌로 주식이 이체됨',
  // 매도자
  SELLER__ACCEPT__LINKING: '매수 후 매도 예정인 건입니다.',
  SELLER__ACCEPT__ACTOR_CHANGED: '매도주식을 고객님의 당사위탁계좌로<br>결제이행 시간까지 입고(이체)해 주세요.',
  SELLER__ACCEPT: '매도주식을 아래 증권계좌로<br>결제이행 시간까지 입고(이체)해 주세요.',
  SELLER__SHARE_IN: '매수자의 대금입금을 기다리는 중',
  SELLER__PAY_IN__LINKING: '매수 후 매도 예정인 건입니다.',
  SELLER__PAY_IN__ACTOR_CHANGED: '매도주식을 고객님의 당사위탁계좌로<br>결제이행 시간까지 입고(이체)해 주세요.',
  SELLER__PAY_IN: '매도주식을 아래 증권계좌로<br>결제이행 시간까지 입고(이체)해 주세요.',
  SELLER__COMPLETE_IN: '주식입고 및 대금입금이 완료되어<br>매매당사자 실명정보 확인 중',
  SELLER__SHARE_OUT: '회원님 계좌로 매각 대금이 곧 입금 될 예정',
};

const PAY_STATUS = {
  ACCEPT: '입금대기',
  PAY_IN: '입금완료',
  PAY_WAIT: '입금대기',
  PAY_REQUEST: '입금완료',
  PAY_OUT: '지급대기',
};

const SHARE_STATUS = {
  ACCEPT: '입고대기',
  WAITING_IN: '입고대기',
  SHARE_IN: '입고완료',
  WAITING_OUT: '입고완료',
  SHARE_OUT: '이체완료',
};

export {
  STATUS,
  PAY_STATUS,
  SHARE_STATUS,
};

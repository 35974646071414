import _ from 'underscore';
import {
  INPUT_FORM__MYSTOCKS_TRANS,
  DEL_FORM__MYSTOCKS_TRANS,
  DONE_FORM__MYSTOCKS_TRANS,
  FAIL_FORM__MYSTOCKS_TRANS,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    stockInfoUid: '',
    stockName: '',
    myStockKey: '',
  },
  error: {},
  status: 'INIT',
};

const getters = {
  enabledForm: state => _.every(state.form),
  loadingForm: state => state.status === 'DEL',
};

const mutations = {
  [INPUT_FORM__MYSTOCKS_TRANS] (state, { key, value }) {
    state.form[key] = value;
  },
  [DEL_FORM__MYSTOCKS_TRANS] (state) {
    state.error = {};
    state.status = 'DEL';
  },
  [DONE_FORM__MYSTOCKS_TRANS] (state) {
    state.status = 'DONE';

    router.replace({ name: 'mystocks' });
  },
  [FAIL_FORM__MYSTOCKS_TRANS] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  deleteForm({ state, commit }) {
    commit(DEL_FORM__MYSTOCKS_TRANS);

    const { myStockKey } = state.form;
    api.delete(`/mystocks/${myStockKey}`).then(
      response => commit(DONE_FORM__MYSTOCKS_TRANS, response.data),
      error => commit(FAIL_FORM__MYSTOCKS_TRANS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

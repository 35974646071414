<template>
  <div class="d-flex justify-content-between mb-3">
    <p class="col-form-label">{{ label }}<span v-if="value">: {{ value }}</span></p>
    <router-link class="btn btn-light" :to="path">{{ buttonLabel }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'InputLink',
  props: {
    label: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: '수정',
    },
    path: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
  },
};
</script>

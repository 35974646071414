<template>
  <div class="home-first">
    <HomeMain
      :list="deals"
    />
    <HomeLinks />
    <div class="line"></div>
    <HomeHots
      :list="companyHotShuffled"
      :loadingList="loadingList"
    />
    <div class="line"></div>
    <HomeReports
      :list="companyReported"
      :loadingList="loadingList"
    />
    <div class="line"></div>
    <div class="container mb-4">
      <div class="d-flex justify-content-between align-items-center my-4">
        <h6 class="mb-0">비상장기업 소식</h6>
        <ul class="nav justify-content-lg-center border-bottom">
          <li class="nav-item">
            <a
              class="nav-link d-inline-block font-weight-bold"
              :class="{'active': currentLIST === 'NEWS'}"
              @click="() => selectList('NEWS')"
            >뉴스</a>
            <a
              class="nav-link d-inline-block font-weight-bold"
              :class="{'active': currentLIST === 'VIDEOS'}"
              @click="() => selectList('VIDEOS')"
            >영상</a>
          </li>
        </ul>
      </div>
      <CardListNews
        v-if="currentLIST === 'NEWS'"
        :list="news"
        :enableDelete="false"
        :loading="loadingList"
      />
      <CardListVideos
        v-if="currentLIST === 'VIDEOS'"
        :list="videos"
        :loading="loadingList"
      />
    </div>
    <NegoLinksHome />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import HomeMain from '@/components/homeFirst/HomeMain.vue';
import HomeHots from '@/components/homeFirst/HomeHots.vue';
import HomeReports from '@/components/homeFirst/HomeReports.vue';
import HomeLinks from '@/components/homeFirst/HomeLinks.vue';
import CardListNews from '@/components/company/CardListNews.vue';
import CardListVideos from '@/components/company/CardListVideos.vue';
import NegoLinksHome from '@/components/nego/NegoLinksHome.vue';
import { SELECT_LIST__HOME_FIRST, SHUFFLE_HOT__HOME_FIRST } from '@/store/mutation-types';


export default {
  name: 'HomeFirst',
  components: {
    HomeMain,
    HomeHots,
    HomeReports,
    HomeLinks,
    CardListNews,
    CardListVideos,
    NegoLinksHome,
  },
  computed: {
    ...mapState('homeFirst', [
      'deals',
      'companyHotShuffled',
      'companyReported',
      'news',
      'videos',
      'currentLIST',
    ]),
    ...mapGetters('homeFirst', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapMutations('homeFirst', {
      selectList: SELECT_LIST__HOME_FIRST,
      shuffleHot: SHUFFLE_HOT__HOME_FIRST,
    }),
    ...mapActions('homeFirst', [
      'requestList',
    ]),
  },
  mounted() {
    this.requestList();
  },

};
</script>

<style lang="scss" scoped>
.line {
  border-top: 6px solid $snow;
}
.nav-link {
  color: black;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}
.nav-link.active {
  border-bottom: 4px solid black;
  margin-bottom: -4px;
}
</style>

import Vue from 'vue';
import Router from 'vue-router';

import AppMetaContainer from '@/views/AppMetaContainer.vue';
import HomeFirst from '@/views/homeFirst/HomeFirst.vue';
import HomeService from '@/views/homeFirst/HomeService.vue';
import AuthContainer from '@/views/AuthContainer.vue';
import AuthView from '@/views/auth/AuthView.vue';
import TermsView from '@/views/auth/TermsView.vue';
import SignUpView from '@/views/auth/SignUpView.vue';
import SignInView from '@/views/auth/SignInView.vue';
import SignOutView from '@/views/auth/SignOutView.vue';
import FindEmailView from '@/views/auth/FindEmailView.vue';
import FindResultView from '@/views/auth/FindResultView.vue';
import FindPasswordView from '@/views/auth/FindPasswordView.vue';
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue';
import ResetEmailView from '@/views/auth/ResetEmailView.vue';
import RevokeView from '@/views/auth/RevokeView.vue';
import AuthKakaoView from '@/views/auth/AuthKakaoView.vue';
import AgentView from '@/views/AgentView.vue';
import UserContainer from '@/views/UserContainer.vue';
import InfomationView from '@/views/user/InfomationView.vue';
import NicknameView from '@/views/user/NicknameView.vue';
import PasswordView from '@/views/user/PasswordView.vue';
import MobileView from '@/views/user/MobileView.vue';
import RedirectView from '@/views/RedirectView.vue';
import KakaoView from '@/views/user/KakaoView.vue';
import MarketingView from '@/views/user/MarketingView.vue';
import NegoSampleView from '@/views/nego/NegoSampleView.vue';
import NegoSearchView from '@/views/nego/NegoSearchView.vue';
import IntentsView from '@/views/intents/IntentsView.vue';
import IntentsMessages from '@/views/intentsChat/IntentsMessages.vue';
import IntentsContainer from '@/views/IntentsContainer.vue';
import IntentsMakerView from '@/views/intents/IntentsMakerView.vue';
import IntentsTransView from '@/views/intents/IntentsTransView.vue';
import IntentsBlocksContainer from '@/views/IntentsBlocksContainer.vue';
import IntentsRecentView from '@/views/intentsBlocks/IntentsRecentView.vue';
import IntentsRecentBlockView from '@/views/intentsBlocks/IntentsRecentBlockView.vue';
import IntentsBlocksView from '@/views/intentsBlocks/IntentsBlocksView.vue';
import IntentsBlocksDeleteView from '@/views/intentsBlocks/IntentsBlocksDeleteView.vue';
import MyStocksView from '@/views/mystocks/MyStocksView.vue';
import MyStocksContainer from '@/views/MyStocksContainer.vue';
import MyStocksMakerView from '@/views/mystocks/MyStocksMakerView.vue';
import MyStocksUpdateView from '@/views/mystocks/MyStocksUpdateView.vue';
import MyStocksTransView from '@/views/mystocks/MyStocksTransView.vue';
import TransactionsContainer from '@/views/TransactionsContainer.vue';
import TransactionsProgressView from '@/views/transactions/TransactionsProgressView.vue';
import TransactionsListView from '@/views/transactions/TransactionsListView.vue';
import TransactionsDeleteView from '@/views/transactions/TransactionsDeleteView.vue';
// import TransactionsFinishedView from '@/views/transactions/TransactionsFinishedView.vue';
// import TransactionsCanceledView from '@/views/transactions/TransactionsCanceledView.vue';
// import TransactionsCanceledDeleteView from '@/views/transactions/TransactionsCanceledDeleteView.vue';
import TransactionsLinksView from '@/views/transactions/TransactionsLinksView.vue';
import TransactionsLinksConfirmView from '@/views/transactions/TransactionsLinksConfirmView.vue';
import TransactionsShareInView from '@/views/transactions/TransactionsShareInView.vue';
import TransactionsPayInView from '@/views/transactions/TransactionsPayInView.vue';
import MarketsContainer from '@/views/MarketsContainer.vue';
import MarketsBoardView from '@/views/markets/MarketsBoardView.vue';
import SellAssignsContainer from '@/views/SellAssignsContainer.vue';
import SellAssignsView from '@/views/sellAssigns/SellAssignsView.vue';
import SellAssignsCheckView from '@/views/sellAssigns/SellAssignsCheckView.vue';
import SellAssignsOrderView from '@/views/sellAssigns/SellAssignsOrderView.vue';
import SellAssignsOrderEventView from '@/views/sellAssigns/SellAssignsOrderEventView.vue';
import SellAssignsOrderUserView from '@/views/sellAssigns/SellAssignsOrderUserView.vue';
import SellAssignsIntentView from '@/views/sellAssigns/SellAssignsIntentView.vue';
import InvestSignin from '@/views/investPlacements/InvestSignin.vue';
// import InvestKakao from '@/views/investPlacements/InvestKakao.vue';
import InvestCheck from '@/views/investPlacements/InvestCheck.vue';
import InvestTerms from '@/views/investPlacements/InvestTerms.vue';
import InvestNice from '@/views/investPlacements/InvestNice.vue';
import InvestPlacements from '@/views/investPlacements/InvestPlacements.vue';
import InvestPlacementsHistory from '@/views/investPlacements/InvestPlacementsHistory.vue';
import InvestPlacementsDocuments from '@/views/investPlacements/InvestPlacementsDocuments.vue';
import PlacementsAccounts from '@/views/placementsAccounts/PlacementsAccounts.vue';
// import PlacementsPortfoliosHistory from '@/views/placementsAccounts/PlacementsPortfoliosHistory.vue';
// import PlacementsCashHistory from '@/views/placementsAccounts/PlacementsCashHistory.vue';
import PlacementsAdvise from '@/views/placementsAdvise/PlacementsAdvise.vue';
import PlacementsLibrary from '@/views/placementsAdvise/PlacementsLibrary.vue';
import ConsultList from '@/views/consult/ConsultList.vue';
import ConsultCreate from '@/views/consult/ConsultCreate.vue';
import ConsultDetail from '@/views/consult/ConsultDetail.vue';
import DealsLibrary from '@/views/dealsLibrary/DealsLibrary.vue';
import DealsQna from '@/views/dealsLibrary/DealsQna.vue';
import HelpIntent from '@/views/helpIntent/HelpIntent.vue';
import HelpIntentMaker from '@/views/helpIntent/HelpIntentMaker.vue';
import OTCReports from '@/views/otc/OTCReports.vue';
import OTCHot from '@/views/otc/OTCHot.vue';
import Company from '@/views/company/Company.vue';
import CompanyRegister from '@/views/company/CompanyRegister.vue';
import CompanyUpdate from '@/views/company/CompanyUpdate.vue';
import LPFunding from '@/views/limitedPartner/LPFunding.vue';
import LPFundingReserve from '@/views/limitedPartner/LPFundingReserve.vue';
import LPFundingSubscript from '@/views/limitedPartner/LPFundingSubscript.vue';
import FundingLibrary from '@/views/limitedPartner/FundingLibrary.vue';
import LPJoin from '@/views/limitedPartner/LPJoin.vue';
import GuidesContainer from '@/views/GuidesContainer.vue';
import GuidesFaq from '@/components/guides/GuidesFaq.vue';
import TermsContainer from '@/views/TermsContainer.vue';
import TermsAgreementView from '@/views/terms/TermsAgreementView.vue';
import TermsPrivacyView from '@/views/terms/TermsPrivacyView.vue';
import TermsTradingView from '@/views/terms/TermsTradingView.vue';
import AngelLeagueContainer from '@/views/AngelLeagueContainer.vue';
// import AngelSignInView from '@/views/angelLeague/AngelSignInView.vue';
// import AngelKakaoView from '@/views/angelLeague/AngelKakaoView.vue';
// import AngelMobileView from '@/views/angelLeague/AngelMobileView.vue';
// import AngelTermsView from '@/views/angelLeague/AngelTermsView.vue';
// import AngelRedirectView from '@/views/angelLeague/AngelRedirectView.vue';
// import AngelItemAdd from '@/views/angelLeague/AngelItemAdd.vue';
// import AngelItemMod from '@/views/angelLeague/AngelItemMod.vue';
// import AngelBuyOffer from '@/views/angelLeague/AngelBuyOffer.vue';
// import AngelAgreement from '@/views/angelLeague/AngelAgreement.vue';
import AngelTransactionsProgress from '@/views/angelLeague/AngelTransactionsProgress.vue';
import AngelTransactionsFinished from '@/views/angelLeague/AngelTransactionsFinished.vue';
// import TudalContainer from '@/views/TudalContainer.vue';
// import TudalSignIn from '@/views/tudal/TudalSignIn.vue';
// import TudalKakao from '@/views/tudal/TudalKakao.vue';
// import TudalMobile from '@/views/tudal/TudalMobile.vue';
// import TudalTerms from '@/views/tudal/TudalTerms.vue';
// import TudalRedirect from '@/views/tudal/TudalRedirect.vue';
// import TudalOffer from '@/views/tudal/TudalOffer.vue';
// import TudalConfirm from '@/views/tudal/TudalConfirm.vue';
import InvestMeeting from '@/views/investMeeting/InvestMeeting.vue';
import AgreeFileView from '@/views/public/AgreeFileView.vue';
import BuySignView from '@/views/public/BuySignView.vue';
import PaymentDocsView from '@/views/public/PaymentDocsView.vue';
import ConfirmFileView from '@/views/public/ConfirmFileView.vue';
// import InvestorStatus from '@/views/investor/InvestorStatus.vue';
import InvestorSuggesting from '@/views/investor/InvestorSuggesting.vue';
import InvestorScoring from '@/views/investor/InvestorScoring.vue';
import InvestorResult from '@/views/investor/InvestorResult.vue';
import PublicIntent from '@/views/public/PublicIntent.vue';
import PublicIntentSuccess from '@/views/public/PublicIntentSuccess.vue';
import MarketingReject from '@/views/marketing/MarketingReject.vue';
import Newsletter from '@/views/marketing/Newsletter.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import store from '@/store';

const IntentsChatView = () => import(/* webpackChunkName: "chat" */ '@/views/intentsChat/IntentsChatView.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeFirst,
    },
    {
      path: '/service',
      name: 'home_service',
      component: HomeService,
    },
    {
      path: '/auth',
      component: AuthContainer,
      children: [
        {
          path: '',
          name: 'auth',
          component: AuthView,
        },
        {
          path: 'terms',
          name: 'terms',
          component: TermsView,
        },
        {
          path: 'sign-up',
          name: 'sign_up',
          component: SignUpView,
        },
        {
          path: 'sign-in',
          name: 'sign_in',
          component: SignInView,
        },
        {
          path: 'sign-out',
          name: 'sign_out',
          component: SignOutView,
        },
        {
          path: 'find-email',
          name: 'find_email',
          component: FindEmailView,
        },
        {
          path: 'find-result/:status',
          name: 'find_result',
          component: FindResultView,
        },
        {
          path: 'find-password',
          name: 'find_password',
          component: FindPasswordView,
        },
        {
          path: 'reset-password/:code',
          name: 'reset_password',
          component: ResetPasswordView,
        },
        {
          path: 'reset-email/',
          name: 'reset_email',
          component: ResetEmailView,
          meta: { requiresAuth: true },
        },
        {
          path: 'revoke',
          name: 'revoke',
          component: RevokeView,
          meta: { requiresAuth: true },
        },
        {
          path: 'kakao',
          name: 'auth_kakao',
          component: AuthKakaoView,
        },
      ],
    },
    {
      path: '/agent/:target',
      name: 'agent',
      component: AgentView,
    },
    {
      path: '/user',
      component: UserContainer,
      children: [
        {
          path: 'nickname',
          name: 'user_nickname',
          component: NicknameView,
          meta: { requiresAuth: true },
        },
        {
          path: 'password',
          name: 'user_password',
          component: PasswordView,
          meta: { requiresAuth: true },
        },
        {
          path: 'mobile',
          name: 'user_mobile',
          component: MobileView,
          meta: { requiresAuth: true },
        },
        {
          path: 'kakao',
          name: 'user_kakao',
          component: KakaoView,
          meta: { requiresAuth: true },
        },
        {
          path: 'kakao/angel',
          redirect: { name: '__ANGEL_KAKAO__' },
        },
        {
          path: 'kakao/tudal',
          redirect: { name: '__TUDAL_KAKAO__' },
        },
        {
          path: 'kakao/invest',
          redirect: { name: '__INVEST_PLACEMENTS__' },
        },
        {
          path: 'marketing',
          name: 'user_marketing',
          component: MarketingView,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/user/infomation',
      name: 'user_infomation',
      component: InfomationView,
      meta: { requiresAuth: true },
    },
    {
      // ?path=/___/___/___
      path: '/redirect',
      name: 'redirect',
      component: RedirectView,
      meta: { requiresAuth: true },
    },
    {
      path: '/nego/:intentKey',
      name: 'nego_sample',
      component: NegoSampleView,
    },
    {
      // ?page=___
      path: '/intents',
      name: 'intents',
      component: IntentsView,
      meta: { requiresAuth: true },
    },
    {
      path: '/intents',
      component: IntentsContainer,
      children: [
        {
          path: 'search',
          name: 'intents_search',
          component: NegoSearchView,
          meta: { requiresAuth: true },
        },
        {
          // ?stockInfoUid=___&stockName=___
          path: 'maker',
          name: 'intents_maker',
          component: IntentsMakerView,
          // meta: { requiresAuth: true },
        },
        {
          // ?transType=___&toStatus=___
          path: ':intentKey/trans',
          name: 'intents_trans',
          component: IntentsTransView,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/chat/:intentKey',
      name: 'intents_chat',
      component: IntentsChatView,
      meta: { requiresAuth: true },
    },
    {
      path: '/messages/:intentKey',
      name: 'intents_messages',
      component: IntentsMessages,
      meta: { requiresAuth: true },
    },
    {
      path: '/intents',
      component: IntentsBlocksContainer,
      children: [
        {
          // ?page=___
          path: 'recent',
          name: 'intents_recent',
          component: IntentsRecentView,
          meta: { requiresAuth: true },
          children: [
            {
              path: ':intentKey/block',
              name: 'intent_recent_block',
              component: IntentsRecentBlockView,
            },
          ],
        },
        {
          // ?page=___
          path: 'blocks',
          name: 'intents_blocks',
          component: IntentsBlocksView,
          meta: { requiresAuth: true },
          children: [
            {
              path: ':intentBlockKey/delete',
              name: 'intents_blocks_delete',
              component: IntentsBlocksDeleteView,
            },
          ],
        },
      ],
    },
    {
      // ?page=___
      path: '/mystocks',
      name: 'mystocks',
      component: MyStocksView,
      meta: { requiresAuth: true },
    },
    {
      path: '/mystocks',
      component: MyStocksContainer,
      children: [
        {
          path: 'search',
          name: 'mystocks_search',
          component: NegoSearchView,
          meta: { requiresAuth: true },
        },
        {
          // ?stockInfoUid=___&stockName=___
          path: 'maker',
          name: 'mystocks_maker',
          component: MyStocksMakerView,
          // meta: { requiresAuth: true },
        },
        {
          // ?stockInfoUid=___&stockName=___&type=___&quantity=___&price=___
          path: ':myStockKey/update',
          name: 'mystocks_update',
          component: MyStocksUpdateView,
          meta: { requiresAuth: true },
        },
        {
          // ?transType=___&stockInfoUid=___&stockName=___
          path: ':myStockKey/trans',
          name: 'mystocks_trans',
          component: MyStocksTransView,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/transactions/progress',
      name: 'transactions_progress',
      component: TransactionsProgressView,
      meta: { requiresAuth: true },
    },
    {
      path: '/transactions',
      component: TransactionsContainer,
      children: [
        {
          path: ':transactionKey/links',
          name: 'transactions_links',
          component: TransactionsLinksView,
          meta: { requiresAuth: true },
          children: [
            {
              path: ':targetKey',
              name: 'transactions_links_confirm',
              component: TransactionsLinksConfirmView,
            },
          ],
        },
        {
          path: ':transactionKey/share-in',
          name: 'transactions_share_in',
          component: TransactionsShareInView,
          meta: { requiresAuth: true },
        },
        {
          path: ':transactionKey/pay-in',
          name: 'transactions_pay_in',
          component: TransactionsPayInView,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/transactions-list/',
      name: 'transactions_list',
      component: TransactionsListView,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'delete/:transactionKey',
          name: 'transactions_delete',
          component: TransactionsDeleteView,
        },
      ],
    },
    // {
    //   path: '/transactions/finished',
    //   name: 'transactions_finished',
    //   component: TransactionsFinishedView,
    //   meta: { requiresAuth: true },
    // },
    // {
    //   path: '/transactions/canceled',
    //   name: 'transactions_canceled',
    //   component: TransactionsCanceledView,
    //   meta: { requiresAuth: true },
    //   children: [
    //     {
    //       path: ':transactionKey',
    //       name: 'transactions_canceled_delete',
    //       component: TransactionsCanceledDeleteView,
    //     },
    //   ],
    // },
    {
      path: '/markets/board',
      name: 'markets_board',
      component: MarketsBoardView,
    },
    {
      path: '/markets',
      component: MarketsContainer,
      children: [
        {
          path: 'search',
          name: 'markets_search',
          component: NegoSearchView,
        },
      ],
    },
    {
      path: '/sell-assigns',
      name: 'sell_assigns',
      component: SellAssignsView,
      meta: { requiresAuth: true },
    },
    {
      path: '/sell-assigns',
      component: SellAssignsContainer,
      children: [
        {
          path: 'search',
          name: 'sell_assigns_search',
          component: NegoSearchView,
          meta: { requiresAuth: true },
        },
        {
          path: 'check',
          name: 'sell_assigns_check',
          component: SellAssignsCheckView,
          meta: { requiresAuth: true },
        },
        {
          path: 'order',
          name: 'sell_assigns_order',
          component: SellAssignsOrderView,
          meta: { requiresAuth: true },
        },
        {
          path: 'order-event',
          name: 'sell_assigns_order_event',
          component: SellAssignsOrderEventView,
          meta: { requiresAuth: true },
        },
        {
          path: 'order-user',
          name: 'sell_assigns_order_user',
          component: SellAssignsOrderUserView,
        },
        {
          path: 'intent',
          name: 'sell_assigns_intent',
          component: SellAssignsIntentView,
        },
      ],
    },
    {
      path: '/invest/placements',
      name: '__INVEST_PLACEMENTS__',
      component: InvestPlacements,
      meta: {
        requiresAuth: true,
        authName: '__INVEST_SIGNIN__',
        noPreUser: true,
        preName: '__INVEST_CHECK__'
      },
    },
    {
      path: '/invest',
      component: AppMetaContainer,
      children: [
        {
          path: 'signin',
          name: '__INVEST_SIGNIN__',
          component: InvestSignin,
        },
        {
          path: 'check',
          name: '__INVEST_CHECK__',
          component: InvestCheck,
          meta: {
            requiresAuth: true,
            authName: '__INVEST_SIGNIN__',
          },
        },
        {
          path: 'terms',
          name: '__INVEST_TERMS__',
          component: InvestTerms,
          // meta: { tudal: true },
        },
        {
          path: 'nice',
          name: '__INVEST_NICE__',
          component: InvestNice,
          meta: {
            requiresAuth: true,
            authName: '__INVEST_SIGNIN__',
          },
        },
        {
          path: 'placements/history/',
          name: '__INVEST_PLACEMENTS_HISTORY__',
          component: InvestPlacementsHistory,
          meta: { requiresAuth: true },
        },
        {
          path: 'placements/documents',
          name: '__INVEST_PLACEMENTS_DOCUMENTS__',
          component: InvestPlacementsDocuments,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/placements',
      component: AppMetaContainer,
      children: [
        {
          path: 'accounts',
          name: '__PLACEMENTS_ACCOUNTS__',
          component: PlacementsAccounts,
          meta: { col: 'col-12' },
        },
        // {
        //   path: 'portfolios-history',
        //   name: '__PLACEMENTS_PORTFOLIOS_HISTORY__',
        //   component: PlacementsPortfoliosHistory,
        //   meta: { col: 'col-12', back: true },
        // },
        // {
        //   path: 'cash-history',
        //   name: '__PLACEMENTS_CASH_HISTORY__',
        //   component: PlacementsCashHistory,
        //   meta: { col: 'col-12', back: true },
        // },
        {
          path: 'library',
          name: '__PLACEMENTS_LIBRARY__',
          component: PlacementsLibrary,
        },
      ],
    },
    {
      path: '/placements/advise',
      name: '__PLACEMENTS_ADVISE__',
      component: PlacementsAdvise,
    },
    {
      path: '/consult/list',
      name: '__CONSULT_LIST__',
      component: ConsultList,
    },
    {
      path: '/consult',
      component: AppMetaContainer,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'create',
          name: '__CONSULT_CREATE__',
          component: ConsultCreate,
        },
        {
          path: 'detail/:consultKey',
          name: '__CONSULT_DETAIL__',
          component: ConsultDetail,
        },
      ],
    },
    {
      path: '/deals',
      component: AppMetaContainer,
      children: [
        {
          path: 'library',
          name: '__DEALS_LIBRARY__',
          component: DealsLibrary,
          meta: { requiresAuth: true },
        },
        {
          path: 'qna',
          name: '__DEALS_QNA__',
          component: DealsQna,
          meta: { requiresAuth: true, col: 'col-12 col-md-8', back: true, bg: 'light' },
        },
      ],
    },
    {
      path: '/help-intent',
      name: '__HELP_INTENT__',
      component: HelpIntent,
    },
    {
      path: '/help-intent',
      component: IntentsContainer,
      children: [
        {
          path: 'maker',
          name: '__HELP_INTENT_MAKER__',
          component: HelpIntentMaker,
          meta: { requiresAuth: true, col: 'col-12 col-md-8', back: true, bg: 'light' },
        },
      ],
    },
    {
      path: '/otc/reports',
      name: '__OTC_REPORTS__',
      component: OTCReports,
    },
    {
      path: '/otc/hot',
      name: '__OTC_HOT__',
      component: OTCHot,
    },
    {
      path: '/company',
      component: AppMetaContainer,
      children: [
        {
          path: 'register',
          name: '__COMPANY_REGISTER__',
          component: CompanyRegister,
          meta: { requiresAuth: true, col: 'col-12 col-md-6', bg: 'light' },
        },
        {
          path: 'update',
          name: '__COMPANY_UPDATE__',
          component: CompanyUpdate,
          meta: { requiresAuth: true, col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/company/view/:companyCode',
      name: '__COMPANY_VIEW__',
      component: Company,
    },
    {
      path: '/lp/funding',
      name: '__LP_FUNDING__',
      component: LPFunding,
    },
    {
      path: '/lp/funding',
      component: AppMetaContainer,
      children: [
        {
          path: 'reserve',
          name: '__LP_FUNDING_RESERVE__',
          component: LPFundingReserve,
          meta: { requiresAuth: true, col: 'col-12 col-md-6', back: true, bg: 'light' },
        },
        {
          path: 'subscript',
          name: '__LP_FUNDING_SUBSCRIPT__',
          component: LPFundingSubscript,
          meta: { requiresAuth: true, col: 'col-12 col-md-6', back: true, bg: 'light' },
        },
        {
          path: 'library',
          name: '__FUNDING_LIBRARY__',
          component: FundingLibrary,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/lp/join',
      name: '__LP_JOIN__',
      component: LPJoin,
      meta: { requiresAuth: true },
    },
    {
      path: '/guides',
      component: GuidesContainer,
      children: [
        {
          path: 'faq',
          name: 'guides_faq',
          component: GuidesFaq,
        },
      ],
    },
    {
      path: '/terms',
      component: TermsContainer,
      children: [
        {
          path: 'agreement',
          name: 'terms_agreement',
          component: TermsAgreementView,
        },
        {
          path: 'privacy',
          name: 'terms_privacy',
          component: TermsPrivacyView,
        },
        {
          path: 'trading',
          name: 'terms_trading',
          component: TermsTradingView,
        },
      ],
    },
    {
      path: '/angelleague',
      component: AngelLeagueContainer,
      children: [
        // {
        //   path: 'signin',
        //   name: '__ANGEL_SIGN_IN__',
        //   component: AngelSignInView,
        // },
        // {
        //   path: 'user/kakao',
        //   name: '__ANGEL_KAKAO__',
        //   component: AngelKakaoView,
        //   meta: {
        //     angel: true,
        //     requiresAuth: true,
        //     authName: '__ANGEL_SIGN_IN__',
        //     noPreUser: true,
        //     preName: '__ANGEL_MOBILE__'
        //   },
        // },
        // {
        //   path: 'mobile',
        //   name: '__ANGEL_MOBILE__',
        //   component: AngelMobileView,
        //   meta: { requiresAuth: true, angel: true },
        // },
        // {
        //   path: 'terms',
        //   name: '__ANGEL_TERMS__',
        //   component: AngelTermsView,
        // },
        // {
        //   path: 'redirect',
        //   name: '__ANGEL_REDIRECT__',
        //   component: AngelRedirectView,
        //   meta: { requiresAuth: true, angel: true },
        // },
        // {
        //   path: 'item-add',
        //   name: '__ANGEL_ITEM_ADD__',
        //   component: AngelItemAdd,
        //   meta: { requiresAuth: true, angel: true },
        // },
        // {
        //   path: 'item-mod',
        //   name: '__ANGEL_ITEM_MOD__',
        //   component: AngelItemMod,
        //   meta: { requiresAuth: true, angel: true },
        // },
        // {
        //   path: 'buy-offer',
        //   name: '__ANGEL_BUY_OFFER__',
        //   component: AngelBuyOffer,
        //   meta: { requiresAuth: true, angel: true },
        // },
        // {
        //   path: 'agreement',
        //   name: '__ANGEL_AGREEMENT__',
        //   component: AngelAgreement,
        //   meta: { requiresAuth: true, angel: true },
        // },
        {
          path: 'transactions-progress',
          name: '__ANGEL_TRANSACTIONS_PROGRESS__',
          component: AngelTransactionsProgress,
          meta: { requiresAuth: true, angel: false },
        },
        // {
        //   path: 'transactions-progress/pay-in/:transactionKey',
        //   name: '__ANGEL_TRANSACTIONS_PROGRESS_PAY_IN__',
        //   component: AngelTransactionsProgressPayIn,
        //   meta: { requiresAuth: true, angel: false },
        // },
        {
          path: 'transactions-finished',
          name: '__ANGEL_TRANSACTIONS_FINISHED__',
          component: AngelTransactionsFinished,
          meta: { requiresAuth: true, angel: false },
        }
      ],
    },
    // {
    //   path: '/tudal',
    //   component: TudalContainer,
    //   children: [
    //     {
    //       path: 'signin',
    //       name: '__TUDAL_SIGN_IN__',
    //       component: TudalSignIn,
    //       meta: { tudal: true },
    //     },
    //     {
    //       path: 'user/kakao',
    //       name: '__TUDAL_KAKAO__',
    //       component: TudalKakao,
    //       meta: {
    //         tudal: true,
    //         requiresAuth: true,
    //         authName: '__TUDAL_SIGN_IN__',
    //         noPreUser: true,
    //         preName: '__TUDAL_MOBILE__'
    //       },
    //     },
    //     {
    //       path: 'mobile',
    //       name: '__TUDAL_MOBILE__',
    //       component: TudalMobile,
    //       meta: { requiresAuth: true, tudal: true },
    //     },
    //     {
    //       path: 'terms',
    //       name: '__TUDAL_TERMS__',
    //       component: TudalTerms,
    //       meta: { tudal: true },
    //     },
    //     {
    //       path: 'redirect',
    //       name: '__TUDAL_REDIRECT__',
    //       component: TudalRedirect,
    //       meta: { requiresAuth: true, tudal: true },
    //     },
    //     {
    //       path: 'offer',
    //       name: '__TUDAL_OFFER__',
    //       component: TudalOffer,
    //       meta: { requiresAuth: true, tudal: true },
    //     },
    //     {
    //       path: 'confirm',
    //       name: '__TUDAL_CONFIRM__',
    //       component: TudalConfirm,
    //       meta: { requiresAuth: true, tudal: true },
    //     }
    //   ],
    // },
    {
      path: '/invest-meeting',
      component: AppMetaContainer,
      children: [
        {
          path: ':investorMeetingKey',
          name: '__INVEST_MEETING__',
          component: InvestMeeting,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/agreefile',
      component: AppMetaContainer,
      children: [
        {
          path: ':subscriptionKey',
          name: '__AGREE_FILE__',
          component: AgreeFileView,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/buysign',
      component: AppMetaContainer,
      children: [
        {
          path: ':helperStockTxKey',
          name: '__BUY_SIGN__',
          component: BuySignView,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/payment_docs',
      component: AppMetaContainer,
      children: [
        {
          path: ':helperStockTxKey',
          name: '__PAYMENT_DOCS__',
          component: PaymentDocsView,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/confirmfile',
      component: AppMetaContainer,
      children: [
        {
          path: ':subscriptionKey',
          name: '__CONFIRM_FILE__',
          component: ConfirmFileView,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/investor',
      component: AppMetaContainer,
      children: [
        // {
        //   path: 'status',
        //   name: 'INVESTOR_STATUS',
        //   component: InvestorStatus,
        //   meta: { col: 'col-12 col-md-6', bg: 'light' },
        // },
        {
          path: 'suggesting',
          name: 'INVESTOR_SUGGESTING',
          component: InvestorSuggesting,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
        {
          path: 'scoring',
          name: 'INVESTOR_SCORING',
          component: InvestorScoring,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
        {
          path: 'result',
          name: 'INVESTOR_RESULT',
          component: InvestorResult,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/public',
      component: AppMetaContainer,
      children: [
        {
          path: 'intent/:dealType',
          name: 'PUBLIC_INTENT',
          component: PublicIntent,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
        {
          path: 'intent-success',
          name: 'PUBLIC_INTENT_SUCCESS',
          component: PublicIntentSuccess,
          meta: { col: 'col-12 col-md-6', bg: 'light' },
        },
      ],
    },
    {
      path: '/marketing',
      component: AppMetaContainer,
      children: [
        {
          path: 'reject/:userKey',
          name: 'MARKETING_REJECT',
          component: MarketingReject,
        },
      ],
    },
    {
      path: '/newsletter',
      component: AppMetaContainer,
      children: [
        {
          path: ':marketingAdKey',
          name: '__NEWS_LETTER__',
          component: Newsletter,
        },
      ],
    },
    {
      path: '/400',
      component: AppMetaContainer,
      children: [
        {
          path: '',
          name: 'error_400',
          component: ErrorPage,
          meta: { col: 'col-12 col-md-6', angel: true },
        },
      ],
    },
    {
      path: '*',
      name: 'not_found',
      component: NotFoundPage,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 500);
      })
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const { user, status } = store.state.userInfomation;
  const { userKey, preUser } = user;

  // 인증 실패, 로그아웃
  if (status === 'FAILURE' || status === 'INITIAL') {
    if (!userKey) {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        next({
          name: to.meta.authName || 'sign_in',
          query: { 'redirect_path': to.path, ...to.query },
        });
      } else {
        next();
      }
    }
  // 인증 성공
  } else if (status === 'RECEIVE') {
    if (preUser && to.matched.some(record => record.meta.noPreUser)) {
      next({
        name: to.meta.preName,
      });
    } else {
      next();
    }
  // 인증 전 REQUEST
  } else {
    next();
  }
});

export default router;

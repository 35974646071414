import {
  INITIAL_LIST__INTENTS,
  REQUEST_LIST__INTENTS,
  RECEIVE_LIST__INTENTS,
  FAILURE_LIST__INTENTS,
} from '@/store/mutation-types';
import api from '@/api/axios';

/**
 *  {
 *    "contents": [{
 *      "type": "BUY", // 유형
 *      "receiverContact": "01026106969", // 상대방연락처 | 공개ID
 *      "hopeQuantity": 90, // 희망 수량
 *      "hopePrice": 85000, // 희망 단가
 *      "offerQuantity": 0, // 최종제안 수량
 *      "offerPrice": 0, // 최종제안 단가
 *      "hasCreatorMsg": false, // 생성유저-신규메시지 존재유무
 *      "hasReceiverMsg": false, // 수신유저-신규메시지 존재유무
 *      "status": "S01", // 의향서 상태
 *      "deletedCreator": false, // 거래생성자 삭제여부
 *      "deletedReceiver": false, // 거래수신자 삭제여부
 *      "hopeTime": 1551769722000, // 생성시간
 *      "expiration": 1551769773351, // 희망 | 제종제안 유효시간
 *      "creatorPublicId": "01473001529", // 생성유저 공개ID
 *      "stockInfoUid": 2662, // 종목Uid
 *      "stockCode": "037390", // 종목코드
 *      "stockName": "삼성전자서비스", // 종목명
 *      "sender": true, // 현재사용자 송신자?
 *      "receiver": false, // 현재사용자 수신자?
 *      "seller": false, // 현재사용자 매도?
 *      "buyer": true, // 현재사용자 매수?
 *      "intentKey": "8814069c...", // 의향서 key
 *      "creatorKey": "212d6356..." // 생성 사용자 key
 *    }],
 *    "page": 1, // 요청페이지
 *    "scale": 6, // 페이지당 아이템수
 *    "total": 1, // 전체 아이템수
 *    "totalPages": 1, // 전체 페이지수
 *    "pageScale": 5, // 노출 페이지수
 *    "startPage": 1, // 시작페이지번호
 *    "endPage": 1, // 마지막페이지번호
 *    "first": true, // 첫페이지?
 *    "last": true, // 마지막페이지?
 *    "p": {},
 *  };
 */

const state = {
  list: [],
  params: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST' && state.list.length === 0,
};

const mutations = {
  [INITIAL_LIST__INTENTS] (state) {
    state.list = [];
    state.error = {};
    state.status = 'INITIAL';
  },
  [REQUEST_LIST__INTENTS] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__INTENTS] (state, { contents, ...params }) {
    state.list = [...contents];
    state.params = { ...params };
    state.error = {};
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__INTENTS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, { page = 1, scale = 6 }) {
    commit(REQUEST_LIST__INTENTS);

    api.get('/intents', { params: { page, scale }}).then(
      response => commit(RECEIVE_LIST__INTENTS, response.data),
      error => commit(FAILURE_LIST__INTENTS, error),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vue from 'vue';
import Vuex from 'vuex';

import homeFirst from './modules/homeFirst/homeFirst';
import authSignUp from './modules/auth/authSignUp';
import authSignIn from './modules/auth/authSignIn';
import authFindEmail from './modules/auth/authFindEmail';
import authFindPassword from './modules/auth/authFindPassword';
import authResetPassword from './modules/auth/authResetPassword';
import authResetEmail from './modules/auth/authResetEmail';
import authRevoke from './modules/auth/authRevoke';
import userInfomation from './modules/user/userInfomation';
import userNickname from './modules/user/userNickname';
import userPassword from './modules/user/userPassword';
import userMobile from './modules/user/userMobile';
import userMarketing from './modules/user/userMarketing';
import negoRedirect from './modules/nego/negoRedirect';
import negoStocks from './modules/nego/negoStocks';
import negoKeywords from './modules/nego/negoKeywords';
import intents from './modules/intents/intents';
import intentsMessages from './modules/intentsChat/intentsMessages';
import intentsMaker from './modules/intents/intentsMaker';
import intentsTrans from './modules/intents/intentsTrans';
import intentsRecent from './modules/intents/intentsRecent';
import intentsBlocks from './modules/intents/intentsBlocks';
import myStocks from './modules/myStocks/myStocks';
import myStocksExpires from './modules/myStocks/myStocksExpires';
import myStocksMaker from './modules/myStocks/myStocksMaker';
import myStocksUpdate from './modules/myStocks/myStocksUpdate';
import myStocksTrans from './modules/myStocks/myStocksTrans';
import transactionsProgress from './modules/transactions/transactionsProgress';
import transactionsFinished from './modules/transactions/transactionsFinished';
import transactionsCanceled from './modules/transactions/transactionsCanceled';
import transactionsLinks from './modules/transactions/transactionsLinks';
import negoSample from './modules/nego/negoSample';
import marketsBoard from './modules/markets/marketsBoard';
import sellAssigns from './modules/sellAssigns/sellAssigns';
import sellAssignsCheck from './modules/sellAssigns/sellAssignsCheck';
import sellAssignsOrder from './modules/sellAssigns/sellAssignsOrder';
import sellAssignsOrderEvent from './modules/sellAssigns/sellAssignsOrderEvent';
import sellAssignsIntent from './modules/sellAssigns/sellAssignsIntent';
import investCheck from './modules/investPlacements/investCheck';
import investPlacements from './modules/investPlacements/investPlacements';
import investPlacementsContents from './modules/investPlacements/investPlacementsContents';
import investPlacementsHistory from './modules/investPlacements/investPlacementsHistory';
import investPlacementsMedia from './modules/investPlacements/investPlacementsMedia';
import investPlacementsDocuments from './modules/investPlacements/investPlacementsDocuments';
import investMeeting from './modules/investMeeting/investMeeting';
import placementsPortfolios from './modules/placements/placementsPortfolios';
import placementsPortfoliosHistory from './modules/placements/placementsPortfoliosHistory';
import placementsCash from './modules/placements/placementsCash';
import placementsCashHistory from './modules/placements/placementsCashHistory';
import placementsAdvise from './modules/placements/placementsAdvise';
import consultList from './modules/consult/consultList';
import consultCreate from './modules/consult/consultCreate';
import consultDetail from './modules/consult/consultDetail';
import dealsContents from './modules/dealsLibrary/dealsContents';
import dealsContentsPublic from './modules/dealsLibrary/dealsContentsPublic';
import dealsContentsComments from './modules/dealsLibrary/dealsContentsComments';
import dealsCharges from './modules/dealsLibrary/dealsCharges';
import dealsQna from './modules/dealsLibrary/dealsQna';
import helpIntent from './modules/helpIntent/helpIntent';
import helpIntentMaker from './modules/helpIntent/helpIntentMaker';
import otcReports from './modules/otc/otcReports';
import otcHot from './modules/otc/otcHot';
import keywords from './modules/otc/keywords';
import lpFunding from './modules/limitedPartner/lpFunding';
import lpFundingReserve from './modules/limitedPartner/lpFundingReserve';
import lpFundingSubscript from './modules/limitedPartner/lpFundingSubscript';
import lpJoin from './modules/limitedPartner/lpJoin';
import companyBrief from './modules/company/companyBrief';
import companySuggest from './modules/company/companySuggest';
import companyRegister from './modules/company/companyRegister';
import companyUpdate from './modules/company/companyUpdate';
// import angelKakao from './modules/angelleague/angelKakao';
// import angelItemAdd from './modules/angelleague/angelItemAdd';
// import angelItemMod from './modules/angelleague/angelItemMod';
// import angelBuyOffer from './modules/angelleague/angelBuyOffer';
import angelTransactionsProgress from './modules/angelleague/angelTransactionsProgress';
import angelTransactionsFinished from './modules/angelleague/angelTransactionsFinished';
// import tudalKakao from './modules/tudal/tudalKakao';
// import tudalOffer from './modules/tudal/tudalOffer';
// import tudalConfirm from './modules/tudal/tudalConfirm';
import agreeFile from './modules/public/agreeFile';
import buySign from './modules/public/buySign';
import paymentDocs from './modules/public/paymentDocs';
import investorSuggesting from './modules/investor/investorSuggesting';
import investorScoring from './modules/investor/investorScoring';
import publicIntent from './modules/public/publicIntent';
import marketingReject from './modules/marketing/marketingReject';
import newsletter from './modules/marketing/newsletter';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    homeFirst,
    authSignUp,
    authSignIn,
    authFindEmail,
    authFindPassword,
    authResetPassword,
    authResetEmail,
    authRevoke,
    userInfomation,
    userNickname,
    userPassword,
    userMobile,
    userMarketing,
    negoRedirect,
    negoStocks,
    negoKeywords,
    intents,
    intentsMessages,
    intentsMaker,
    intentsTrans,
    intentsRecent,
    intentsBlocks,
    myStocks,
    myStocksExpires,
    myStocksMaker,
    myStocksUpdate,
    myStocksTrans,
    transactionsProgress,
    transactionsFinished,
    transactionsCanceled,
    transactionsLinks,
    negoSample,
    marketsBoard,
    sellAssigns,
    sellAssignsCheck,
    sellAssignsOrder,
    sellAssignsOrderEvent,
    sellAssignsIntent,
    investCheck,
    investPlacements,
    investPlacementsContents,
    investPlacementsHistory,
    investPlacementsMedia,
    investPlacementsDocuments,
    placementsPortfolios,
    placementsPortfoliosHistory,
    placementsCash,
    placementsCashHistory,
    placementsAdvise,
    consultList,
    consultCreate,
    consultDetail,
    dealsContents,
    dealsContentsPublic,
    dealsContentsComments,
    dealsCharges,
    dealsQna,
    helpIntent,
    helpIntentMaker,
    otcReports,
    otcHot,
    keywords,
    lpFunding,
    lpFundingReserve,
    lpFundingSubscript,
    lpJoin,
    companyBrief,
    companySuggest,
    companyRegister,
    companyUpdate,
    // angelKakao,
    // angelItemAdd,
    // angelItemMod,
    // angelBuyOffer,
    angelTransactionsProgress,
    angelTransactionsFinished,
    // tudalKakao,
    // tudalOffer,
    // tudalConfirm,
    investMeeting,
    agreeFile,
    buySign,
    paymentDocs,
    // investorStatus,
    investorSuggesting,
    investorScoring,
    publicIntent,
    marketingReject,
    newsletter,
  },
});

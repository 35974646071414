import _ from 'underscore';
import {
  REQUEST_DATA__AGREE_FILE,
  RECEIVE_DATA__AGREE_FILE,
  FAILURE_DATA__AGREE_FILE,
  INPUT_FORM__AGREE_FILE,
  CHECK_FORM__AGREE_FILE,
  POST_FORM__AGREE_FILE,
  DONE_FORM__AGREE_FILE,
  FAIL_FORM__AGREE_FILE,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    D: false,
    E: false,
    F: false,
    mobileForSign: '',
    tempUser: true,
  },
  data: {
    subscriptionKey: '',
    dealName: '',
    nameInfo: '',
    birthInfo: '',
    mobileForSign: '',
    commitAmount: 0,
    status: '',
    suggested: true,
    shareTransDate: 0,
    score: 0,
    salesPersonDuty: 0,
    salesPersonName: 0,
    salesPersonContact: 0,
    payIn: false,
    additionalClosing: false,
  },
  files: [],
  valid: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
  showedAlert: state => state.data.status === 'SIGN_ASK'
    || state.data.status === 'SIGN_FIN'
    || state.data.status === 'SIGN_EXPIRE'
    || state.data.status === 'FILE_EXPIRE'
    || state.data.status === 'BROKEN',
  enabledForm: state => state.data.status === 'SIGN_NOTI'
    || state.data.status === 'SIGN_EXPIRE'
    || state.data.status === 'FILE_EXPIRE',
  loadingForm: state => state.status === 'REQUEST' || state.status === 'POST',
  invalidForm: state => _.keys(state.valid).length > 0,
};

const mutations = {
  [INPUT_FORM__AGREE_FILE] (state, { key, value }) {
    state.form[key] = value;
  },
  [CHECK_FORM__AGREE_FILE] (state) {
    const { status, offline } = state.data;
    const { D, E, F, mobileForSign: mobile, tempUser } = state.form;
    const { kakao, mobileForSign } = state.error;
    const valid = {};

    if (tempUser) {
      if (!D) {
        valid.D = '동의를 확인해주세요.';
      }
    }
    if (!E) {
      valid.E = '동의를 확인해주세요.';
    }
    if (!F) {
      valid.F = '동의를 확인해주세요.';
    }
    if (offline && (status === 'SIGN_EXPIRE' || kakao || mobileForSign)) {
      if (!mobile) {
        valid.mobileForSign = '전화번호를 확인해주세요.';
      }
      if (mobile.startsWith('010') && mobile.length !== 11) {
        valid.mobileForSign = '전화번호를 확인해주세요..';
      } else if (!mobile.startsWith('010') && mobile.length !== 10) {
        valid.mobileForSign = '전화번호를 확인해주세요...';
      }
    }

    state.valid = { ...valid };
  },
  [REQUEST_DATA__AGREE_FILE] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_DATA__AGREE_FILE] (state, { files, ...data }) {
    state.data = { ...data };
    state.files = [ ...files ];
    state.status = 'RECEIVE';
  },
  [FAILURE_DATA__AGREE_FILE] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
  [POST_FORM__AGREE_FILE] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__AGREE_FILE] (state) {
    const { tempUser } = state.form;
    state.status = 'DONE';

    if (tempUser) {
      location.href = 'kakaotalk://inappbrowser/close';
    } else {
      router.replace({
        name: '__LP_JOIN__',
      });
    }
  },
  [FAIL_FORM__AGREE_FILE] (state, error) {
    state.status = 'FAIL';
    state.error = { ...error };
  },
};

const actions = {
  requestData({ commit }, { subscriptionKey }) {
    commit(REQUEST_DATA__AGREE_FILE);

    api.get(`/public/subscript/${subscriptionKey}`).then(
      reponse => commit(RECEIVE_DATA__AGREE_FILE, reponse.data),
      error => commit(FAILURE_DATA__AGREE_FILE, error.response.data),
    );
  },
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__AGREE_FILE);

    if (!getters.invalidForm) {
      dispatch('postForm');
    }
  },
  postForm({ commit, state }) {
    commit(POST_FORM__AGREE_FILE);

    const { status, offline, subscriptionKey } = state.data;
    const { mobileForSign: mobile } = state.form;
    const { kakao, mobileForSign } = state.error;

    const data = { subscriptionKey };
    if (offline && (status === 'SIGN_EXPIRE' || kakao || mobileForSign)) {
      _.extend(data, { mobileForSign: mobile });
    }

    api.post('/public/subscript/signatures', data).then(
      reponse => commit(DONE_FORM__AGREE_FILE, reponse.data),
      error => setTimeout(() => {
        commit(FAIL_FORM__AGREE_FILE, error.response.data);
      }, 1000)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <p v-if="loadingData">검색 중...</p>
    <div v-if="data.sellAssignKey">
      <FormHead
        :title="data.stockName"
        :text="data.stockInfoUid"
      />
      <p class="text-center">해당종목에 대해 확인매물이 이미 존재합니다.</p>
      <button
        class="btn btn-primary btn-block"
        @click="updateSellAssign"
      >확인매물 수정하기</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'SellAssignsCheckView',
  components: {
    FormHead,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('sellAssignsCheck', [
      'data',
      'status',
    ]),
    ...mapGetters('sellAssignsCheck', [
      'loadingData',
    ]),
  },
  methods: {
    ...mapActions('sellAssignsCheck', [
      'reqeustData',
    ]),
    updateSellAssign() {
      const { sellAssignKey } = this.data;
      const { corp } = this.user;
      const Path = [
        `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
        '?path=/inbound/update',
        `&redirect_path=${NegoStockWeb}/sell-assigns`,
        `&sellAssignKey=${sellAssignKey}`,
        `&corp=${!!corp}`,
      ].join('');
      window.location = Path;
    },
  },
  mounted() {
    const { stockInfoUid, stockName } = this.$route.query;
    this.reqeustData({ stockInfoUid, stockName });
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'sell_assigns_search') {
      next();
    } else {
      next({ name: 'sell_assigns' });
    }
  },
};
</script>

<template>
  <div>
    <p>종합소득세 소득공제 서류 및 이자배당원천징수영수증을 다운로드 받으실 수 있습니다.</p>
    <div class="border-top mt-5">
      <template v-if="list.length">
        <div class="py-3 border-bottom" v-for="(el, index) in list" :key="index">
          <div class="d-flex justify-content-between">
            <h6>{{ el.fundName }}</h6>
            <small>{{ el.dateIdx | date_str }}</small>
          </div>
          <div class="bg-snow p-2">
            <a
              class="btn btn-link btn-sm"
              target="_blank"
              download
              :href="`${FintechDocuApi}/code/${el.docuCode}`"
            ><IconDownload class="icon-sm"/></a>
            {{ el.filename }}
          </div>
        </div>
      </template>
      <div class="py-3 border-bottom" v-else>
        <div class="bg-warning p-2">발행된 문서가 없습니다.</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload.vue';
import { FintechDocuApi } from '@/utils/constants';

export default {
  name: 'DocsLetters',
  components: {
    IconDownload,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      FintechDocuApi,
    };
  },
};
</script>


<template>
  <AppModal :title="title">
    <InputButton
      :labelInput="label"
      labelButton="제출"
      :value="form['link']"
      @input-form="inputForm"
      @submit-form="submitForm"
    />

    <p v-if="doneForm">좋은 뉴스를 알려주셔서 감사합니다.<br/>담당자가 뉴스 확인 한 후 게시될 예정입니다.</p>
    <p v-if="failForm">{{ error }}</p>
    <template v-slot:footer>
      <button
        class="btn btn-light btn-sm px-3"
        @click="closeForm"
      >닫기</button>
    </template>
  </AppModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import AppModal from '@/components/app/AppModal.vue';
import InputButton from '@/components/form/InputButton.vue'
import {
  INPUT__COMPANY_SUGGEST,
  INIT__COMPANY_SUGGEST,
} from '@/store/mutation-types';

const ModeMap = {
  videos: '영상',
  news: '기사',
};

export default {
  name: 'CompanySuggest',
  components: {
    AppModal,
    InputButton,
  },
  computed: {
    ...mapState('companySuggest', [
      'form',
      'error',
    ]),
    ...mapGetters('companySuggest', [
      'doneForm',
      'failForm',
    ]),
    mode() {
      return ModeMap[this.form.modeType]
    },
    title() {
      return `좋은 ${this.mode} 알리기`;
    },
    label() {
      return this.form.modeType === 'videos' ? '유튜브 동영상 링크' : '언론기사 링크(URL)';
    }
  },
  methods: {
    ...mapMutations('companySuggest', {
      inputForm: INPUT__COMPANY_SUGGEST,
      closeForm: INIT__COMPANY_SUGGEST,
    }),
    ...mapActions('companySuggest', [
      'submitForm',
    ]),
  },
};
</script>

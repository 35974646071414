import {
  REQUEST_DATA__SELL_ASSIGNS_CHECK,
  RECEIVE_DATA__SELL_ASSIGNS_CHECK,
  FAILURE_DATA__SELL_ASSIGNS_CHECK,
} from '@/store/mutation-types';
import api from '@/api/axios';
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';

const state = {
  data: {},
  error: {},
  status: 'INIT',
};

const getters = {
  loadingData: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST_DATA__SELL_ASSIGNS_CHECK] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_DATA__SELL_ASSIGNS_CHECK] (state, { data, stockInfoUid, stockName }) {
    state.data = { ...data };
    state.status = 'RECEIVE';

    if (!data) {
      const Path = [
        `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
        '?path=/inbound/create',
        `&redirect_path=${NegoStockWeb}/sell-assigns`,
        `&stockInfoUid=${stockInfoUid}`,
        `&stockName=${encodeURIComponent(stockName)}`,
      ].join('');
      window.location = Path;
    }
  },
  [FAILURE_DATA__SELL_ASSIGNS_CHECK] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  reqeustData({ commit }, { stockInfoUid, stockName }) {
    commit(REQUEST_DATA__SELL_ASSIGNS_CHECK);

    api.get(`/sellassigns/${stockInfoUid}`).then(
      reponse => commit(RECEIVE_DATA__SELL_ASSIGNS_CHECK, { data: reponse.data, stockInfoUid, stockName }),
      error => commit(FAILURE_DATA__SELL_ASSIGNS_CHECK, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <TermsHeader title="개인정보취급방침"/>
    <TermsContents :contents="contents"/>
  </div>
</template>

<script>
import TermsHeader from '@/components/terms/TermsHeader.vue';
import TermsContents from '@/components/terms/TermsContents.vue';
import contents from './contentsPrivacy';

export default {
  name: 'TermsPrivacyView',
  components: {
    TermsHeader,
    TermsContents,
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<template>
  <div>
    <FormHead
      title="닉네임변경"
      text="의향서에서 상대방에게 표시됩니다."
    />
    <form @submit.prevent="submitForm">
      <InputText
        placeholder="새 닉네임"
        id="nickname"
        :value="form['nickname']"
        :error="error['nickname']"
        @input-form="(value) => inputForm({ key: 'nickname', value })"
      />
      <SubmitButton
        label="수정"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__USER_NICKNAME } from '@/store/mutation-types';
import FormHead from '@/components/auth/form/FormHead';
import InputText from '@/components/auth/form/InputText.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';

export default {
  name: 'NicknameView',
  components: {
    FormHead,
    InputText,
    SubmitButton,
  },
  computed: {
    ...mapState('userNickname', [
      'form',
      'error',
    ]),
    ...mapGetters('userNickname', [
      'loadingForm',
      'enabledForm',
    ]),
  },
  methods: {
    ...mapMutations('userNickname', {
      'inputForm': INPUT_FORM__USER_NICKNAME,
    }),
    ...mapActions('userNickname', [
      'submitForm',
    ]),
  },
};
</script>

<template>
  <div class="pt-2 pb-2 py-lg-4 mb-4">
    <h5 class="mt-2">세금 신고자료 다운로드</h5>
    <ul class="nav justify-content-lg-center border-bottom">
      <li class="nav-item" v-for="({ name, label }, idx) in links" :key="idx">
        <a
          class="nav-link d-inline-block font-weight-bold"
          :class="{'active': mode === name}"
          @click.prevent="$emit('select-mode', name)"
          href="#"
        >{{ label }}</a>
      </li>
      <li class="nav-item ml-auto">
        <router-link class="nav-link" :to="{ name: '__INVEST_PLACEMENTS__' }"
        ><IconHouseDoor class="icon-sm"/></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import IconHouseDoor from '@/components/icons/IconHouseDoor';

export default {
  name: 'InvestLinks',
  components: {
    IconHouseDoor,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  color: black;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}
.nav .nav-item:first-child .nav-link {
  padding-left: 0;
}
.nav-link.active {
  border-bottom: 4px solid black;
  margin-bottom: -4px;
}
</style>

<template>
  <div class="pt-2 pb-2 py-lg-4 mb-4">
    <h5 class="mt-2">{{ $route.query.dealName }}</h5>

    <ul class="nav justify-content-lg-center border-bottom">
      <li class="nav-item" v-for="({ name, label }, idx) in links" :key="idx">
        <router-link
          class="nav-link d-inline-block font-weight-bold"
          :class="{'active': $route.name === name}"
          :to="{
            name,
            query: $route.query,
          }"
        >{{ label }}</router-link>
      </li>
      <li class="nav-item ml-auto">
        <router-link class="nav-link" :to="{ name: '__LP_FUNDING__' }"
        ><IconHouseDoor class="icon-sm"/></router-link>
      </li>
    </ul>
    

  </div>
</template>

<script>
import IconHouseDoor from '@/components/icons/IconHouseDoor';

export default {
  name: 'InvestLinks',
  components: {
    IconHouseDoor,
  },
  data() {
    return {
      links: [
        { name: '__FUNDING_LIBRARY__', label: '자료실' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  color: black;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}
.nav .nav-item:first-child .nav-link {
  padding-left: 0;
}
.nav-link.active {
  border-bottom: 4px solid black;
  margin-bottom: -4px;
}
</style>

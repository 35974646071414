<template>
  <div class="form-label-group mb-3">
    <select
      class="form-control"
      :id="id"
      :value="value"
      @input="$emit('input-form', $event.target.value)">
      <option disabled value="">선택하세요.</option>
      <option value="0">허용하지 않음</option>
      <option value="0.03">3% 이내</option>
      <option value="0.05">5% 이내</option>
      <option value="0.1">10% 이내</option>
      <option value="0.2">20% 이내</option>
    </select>
    <label :for="id" class="col-form-label">가격협의</label>
  </div>
</template>

<script>
export default {
  name: 'SelectNegoLimit',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="container my-5">
    <div class="border p-3">
      <h6 class="title">네고요청 받기 위한 일반게시 종목 등록</h6>
      <ul class="text mb-5 pl-3">
        <li>종목(희망가격 및 수량)을 등록하시면 게시판에 게시되어 다른 회원님으로부터 네고 요청을 받으실 수 있습니다. </li>
        <li>3일 동안 게시판에 게시되며 1개당 네고요청은 최대 3번 받을 수 있습니다. </li>
        <li>회원 1인당 등록할 수 있는 종목 개수는 매수, 매수 각 3개씩입니다. </li>
      </ul>
      <h6 class="title">알고 있는 상대방과 거래</h6>
      <ul class="text mb-3 pl-3">
        <li>일반게시로 종목을 등록할 필요없이 아래 “지인과 거래하기”을 클릭하여 거래상대방에게 바로 네고요청을 보내실 수 있습니다.
          <button
            class="btn btn-outline-primary btn-sm"
            @click="$refs.negoModal.openModal()"
          >지인과 거래하기</button>
        </li>
        <li>“지인과 거래하기”는 알고 계신 거래상대방과 이미 논의하신 거래 건에 대해 당사에 중개요청하기 위한 용도로 사용하셔야 합니다.</li>
        <li>무작위 발송 등으로 수신자에 의해 “수신거부” 처리될 경우 서비스약관 제11조에 따라 서비스이용이 제한될 수 있습니다.</li>
      </ul>
    </div>
    <NegoModal ref="negoModal"/>
  </div>
</template>

<script>
import NegoModal from '@/components/nego/NegoModal.vue';

export default {
  name: 'MyStocksNotice',
  components: {
    NegoModal,
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, .3px);
}
</style>

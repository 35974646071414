const USER__ALLOW_MARKETING = [
  { value: true,  label: '동의함' },
  { value: false, label: '동의하지 않음' },
];

const USER__ALLOW_MARKETING_GROUP = [
  { value: 'allowSmsMarketing',   label: '문자' },
  { value: 'allowEmailMarketing', label: '이메일' },
  { value: 'allowPhoneMarketing', label: '전화' },
  { value: 'allowDmMarketing',    label: 'DM' },
];

const USER__ACCEPT_RECOMMEND = [
  { value: true,  label: '희망' },
  { value: false, label: '희망하지 않음' },
];

const USER__ACCEPT_PROVIDE = [
  { value: true,  label: '제공' },
  { value: false, label: '미제공' },
];

const USER__ACCEPT_PROVIDE_SCORE = [
  {
    "score": [1, 20],
    "label": "안정형",
    "sub": "투자가능상품등급: 초저위험",
    "desc": "예금 또는 적금 수준의 수익률을 기대하며, 투자원금에 손실이 발행하는 것을 원하지 않음"
  },
  {
    "score": [21, 40],
    "label": "안정추구형",
    "sub": "투자가능상품등급: 저위험",
    "desc": "투자원금의 손실위험을 최소화하고, 이자소득이나 배당소득 수준의 안정적인 투자를 목표로 함. 다만, 수익을 위해 단기적인 손실을 수용할 수 있으면, 예·적금보다 높은 수익을 위해 자산 중 일부를 변동성 높은 상품에 투자할 의향이 있음"
  },
  {
    "score": [41, 60],
    "label": "위험중립형",
    "sub": "투자가능상품등급: 중위험",
    "desc": "투자에는 그에 상응하는 투자위험이 있음을 충분히 인지하고 있으며, 예·적금보다 높은 수익을 기대할 수 있다면 일정 수준의 손실위험을 감수할 수 있음"
  },
  {
    "score": [61, 80],
    "label": "적극투자형",
    "sub": "투자가능상품등급: 고위험",
    "desc": "투자원금의 보전보다는 위험을 감내하더라도 높은 수준의 투자수익 실현을 추구함, 투자자금의 상당부분을 주식, 주식형펀드 또는 파생상품 등의 위험자산에 투자할 의향이 있음"
  },
  {
    "score": [81, 100],
    "label": "공격투자형",
    "sub": "투자가능상품등급: 초고위험",
    "desc": "시장평균 수익률을 훨씬 넘어서는 높은 수준의 투자수익을 추구하며, 이를 위해 자산가치의 변동에 따른 손실 위험을 적극 수용. 투자자금 대부분을 주식, 주식형펀드 또는 파생상품 등의 위험자산에 투자할 의향이 있음"
  }
];

export {
  USER__ALLOW_MARKETING,
  USER__ALLOW_MARKETING_GROUP,
  USER__ACCEPT_RECOMMEND,
  USER__ACCEPT_PROVIDE,
  USER__ACCEPT_PROVIDE_SCORE,
};

<template>
  <div>
    <!-- <p class="">
      취소된 비상장주식 매매내역입니다.
    </p> -->
    <div class="row" v-if="loadingList">
      <div class="col-md-6 col-xl-4 mb-4" v-for="(empth, idx) in 6" :key="idx">
        <NegoEmptyCard />
      </div>
    </div>
    <template v-else>
      <div class="row" v-if="!list.length">
        <div class="col-12">
          <div
            class="alert alert-primary text-center"
            role="alert"
          >매매취소 건이 없습니다.</div>
        </div>
      </div>
      <template v-else>
        <div class="row justify-content-center">
          <div class="col-md-6 col-xl-4 mb-4" v-for="(transaction, idx) in list" :key="idx">
            <TransactionsCanceledCard :transaction="transaction" />
          </div>
        </div>
        <NegoPagination
          v-if="params"
          :params="params"
          @select-page="routePage"
        />
      </template>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoEmptyCard from '@/components/nego/NegoEmptyCard.vue';
import NegoPagination from '@/components/nego/NegoPagination.vue';
import TransactionsCanceledCard from '@/components/transactions/TransactionsCanceledCard.vue';

export default {
  name: 'TransactionsCanceledView',
  components: {
    NegoEmptyCard,
    NegoPagination,
    TransactionsCanceledCard,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('transactionsCanceled', [
      'list',
      'params',
      'error',
    ]),
    ...mapGetters('transactionsCanceled', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('transactionsCanceled', [
      'requestList',
    ]),
    request() {
      const page = Number.parseInt(this.$route.query.page) || 1;
      this.requestList({ page });
    },
    routePage(page) {
      this.$router.push({
        name: 'transactions_list',
        query: { mode: 'canceled', page }
      });
      this.request();
    },
  },
  mounted() {
    if (this.user && !this.user.preUser) {
      this.request();
    }
  },
};
</script>

export default [
  {
    header: '네고스탁 서비스 이용약관',
  },
  {
    text: `코리아에셋투자증권(이하 “당사”라고 합니다)은 귀하의 개인정보보호를 매우 중요시하며,
      "개인정보 보호법“, “정보통신망이용 촉진 및 정보보호등에 관한 법률”또는 관련법령ㆍ규정을 준수하고 있습니다.
      당사는 개인정보보호취급ㆍ처리방침을 통하여 귀하께서 제공하시는 개인정보가 어떤 용도와 방식으로 이용되고 있으며
      개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
      귀하는 본 홈페이지,개인(신용)정보의 수집ㆍ이용ㆍ제공 동의서, 유무선 전화, E-mail 등을 통하여 당사에 제공하시는
      개인정보는 귀하가 요청하셨거나 관심을 가질 상품과 서비스의 제공, 당사의 고객니즈 이해, 고객 피드백
      또는 고용관련기회제공, 관련 법률 또는 규정이 요구하는 목적 등을 포함하여 본 개인정보취급ㆍ처리방침에 기재된
      목적을 위하여 이용됩니다. 귀하께서 당사에 제공하시는 정보는 귀하를 위한 당사의 서비스와 관련 상품을 개선시키는데
      귀중한 자산이 될 것입니다. 당사는 고객 개인정보의 수집목적이 달성될 때까지 고객의 개인정보를 보유합니다.
      고객 개인정보는 수집목적 달성 후 관련 법령ㆍ규정, 회계기준 또는 당사의 이익보호에 따라 보유하여야 할 경우가
      아닌 경우 파기됩니다. 일반적으로, 관할에 따라 적용되는 관련법령에 따라 보유기간은 최소한으로 합니다.`,
  },
  {
    title: '제1조 (개인정보보호방침의 순서)',
    list: [
      '처리하는 개인정보의 항목',
      '개인정보의 수립목적 및 이용목적',
      '목적 외 사용 및 제3자에 대한 제공 및 공유',
      '개인정보 처리에 대한 동의 및 방법',
      '개인정보의 수집',
      '개인정보의 열람/정정',
      '동의철회',
      '개인정보의 보유 및 이용기간',
      '개인정보의 파기절차 및 방법',
      '법정대리인의 권리',
      '개인정보 자동 수집장치의 설치·운영 및 그 거부에 관한 사항',
      '개인정보 안정성 확보조치',
      '개인정보침해 관련 상담 및 신고',
      '개인정보 처리방침의 변경에 따른 공지의무',
      '개인정보관리책임자',
    ],
  },
  {
    title: '제2조 (처리하는 개인정보의 항목)',
    list: [
      '당사는 회원가입 시 서비스 이용을 위해 필요한 최소한의 개인정보만을 수집합니다.',
      '관련 법령 또는 규정이 허용하는 범위 내에서, 귀하가 본 홈페이지에 접속하거나 본 홈페이지를 통해 당사의 서비스를 이용하는 때에 당사는 귀하의 ID, 비밀번호, 성명, 주민등록번호, 주소, 연락처, 전화번호와 "쿠키"(아래 “개인정보 자동 수집장치의 설치·운영 및 그 거부에 관한 사항” 참조)를 포함한 E-mail 주소 등 귀하의 개인정보를 수집할 수 있습니다. 그러나, 직업, 취미, 관심분야와 같은 선택정보는 입력하지 않더라도 서비스 이용에는 제한을 두지 않습니다.',
      [
        '금융거래 고객 및 관계자의 개인정보',
        [
          '필수정보: 성명, 주소, 거래내역/수탁·운용보고서의 수령처로 지정한 연락처(E-mail 등), 국적(외국인), 계좌번호, 비밀번호, 거래내역, 자산현황',
          '선택정보: 직업, 직장명, 결혼여부, 거래동기, 취미, 연소득 등',
          '고유식별정보: 주민등록번호, 여권번호(외국인)',
        ],
        '네고스타 웹사이트 회원가입 고객의 개인정보',
        [
          '필수정보: 휴대폰번호, 이메일, 닉네임',
        ]
      ],
    ],
    helps: [
      '※ 귀하는 위와 같은 개인정보의 수집·이용에 거부할 권리가 있으나, 필수정보 또는 고유식별정보의 수집·이용에 거부할 경우 계좌개설 불가 등 금융거래 서비스의 이용에 제약이 있을 수 있습니다.',
      '※ 당사는 위와 같은 선택정보의 수집·이용에 동의하지 않았다는 이유로 귀하에게 서비스 제공을 거부할 수 없습니다.',
    ],
  },
  {
    title: '제3조 (개인정보의 수립목적 및 이용목적)',
    text: '관련 법령 또는 규정이 허용하는 범위내에서 당사는 본 개인정보 처리방침 전문에 기재된 목적을 위하여 개인정보를 수집·이용합니다. 수집되는 개인정보 항목에 따른 구체적인 수집목적 및 이용목적은 다음과 같습니다.',
    list: [
      '성명, 아이디, 비밀번호, 주민등록번호 : 회원가입 및 이용ID발급, 회원제 서비스 이용에 따른 본인 확인 절차에 이용, 예탁유가증권의 매매 등 계약의 체결 확인',
      '이메일주소, 전화번호 : 고지사항 전달, 불만처리 등을 위한 원활한 의사소통, 투자정보와 새로운 상품, 서비스 및 신상품이나 이벤트 정보 등의 안내',  
      '주소 : 매매내역서, 잔고확인서 등 당사 이용현황에 대한 확인서류 제공, 비상장주식매매계약서 체결, 이벤트 진행시 배송지의 확보',
      '사이트 이용 현황 : 당사 사이트 접속빈도 등에 대한 측정을 통해 이용 통계를 수집하고, 이를 서비스 정책(서비스 개편 및 확대)에 반영',
      '기타 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료'
    ],
    helps: ['개인정보 제공대상자별 제공목적과 제공정보'],
    table: {
      head: ['위탁기관', '주소', '정보제공목적', '제공정보'],
      body: [
        ['코리아에셋투자증권', 'www.kasset.co.kr', '온라인소액투자중개업', '성명, 실명확인번호, 휴대폰번호, 이메일주소'],
        ['한국예탁결제원', 'www.ksd.or.kr', '청약배정', '성명, 실명확인번호, 증권계좌번호'],
        ['한국증권금융', 'www.ksfc.co.kr', '청약증거금관리', '고객명, 휴대폰번호, 은행계좌번호'],
        ['㈜스윗트래커', 'www.bizmsg.kr', '문자메시지 및 카카오 알림톡 전송 대행', '핸드폰 번호'],
        ['세틀뱅크㈜', 'www.settlebank.co.kr', '펌뱅킹 서비스 데이터 중계', '계좌번호, 계좌명, 거래일시, 거래금액'],
      ],
    },
  },
  {
    title: '제4조 (목적 외 사용 및 제3자에 대한 제공 및 공유)',
    list: [
      '당사는 귀하의 동의가 있거나 관련 법령의 규정에 의한 경우, 당사가 귀하에게 제공하는 서비스 또는 상품의 공급을 위하여 필요한 경우를 제외하고는 본 개인정보 처리방침에서 고지한 범위를 넘어 귀하의 개인정보를 이용하거나 타인 또는 타기업·기관에 제공하지 않습니다.',
      '귀하의 개인정보를 제공하거나 공유하는 경우에는 사전에 귀하에게 제공받거나 공유받는 자가 누구이며 주된 사업이 무엇인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 개인정보를 제공하거나 공유하는 목적이 무엇인지 등에 대해 개별적으로 전자우편 또는 서면을 통해 고지한 후 이에 대한 동의를 구합니다.',
      '귀하께서는 당사가 귀하의 개인정보를 이용하거나 제3자에게 제공·공유한 내역을 요구할 권리가 있으며, 필요시에 당사의 개인정보관리책임자에게 전화, 팩스, 이메일 등을 통하여 자료를 요구할 수 있습니다. 다만, 다음의 경우에는 관련 법령의 규정에 의하여 귀하의 동의없이 개인정보를 제공할 수도 있으니 이점 양지해 주시기 바랍니다.',
      [
        '통계작성·학술연구 또는 시장조사를 위하여 필요한 경우로써 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우', 
        '금융실명거래및비밀보장에 관한 법률, 신용정보의이용및보호에관한법률, 전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법률에 특별한 규정이 있는 경우',  
        '서비스 제공에 따른 요금정산을 위하여 필요한 경우'
      ],
      '제3자에게 제공된 귀하의 개인정보는 해당 이용목적이 달성될 때까지(서비스 만기, 고객의 동의 철회, 계약의 해제·해지 등) 보유 및 처리됩니다. 해당 이용목적이 달성된 경우에는 지체없이 파기됩니다. 다만 상법, 자본시장 및 금융투자업에 관한 법률, 관련세법 등의 관련법령 또는 규정상의 의무이행 및 기타 감독기관의 요청·명령에 따른 제출할 의무이행에 필요할 것으로 판단되는 정보에 대해서는 개인정보를 파기하지 아니하고 제출 등에 활용할 수 있습니다.',
    ],
  },
  {
    title: '제5조 (개인정보 처리에 대한 동의 및 방법)',
    text: '당사는 귀하의 개인정보 수집 및 이용약관에 대한 동의를 받고 있습니다. 귀하의 개인정보 수집과 관련하여 당사의 개인정보보호방침 또는 이용약관의 내용에 대해 “동의” 버튼 또는 “취소”버튼을 클릭할 수 있는 절차를 마련하고 있으며, 귀하가 “동의”버튼을 클릭하면 당사의 개인정보 수집 및 이용약관에 대해 동의한 것으로 봅니다.',
  },
  {
    title: '제6조 (개인정보의 수집)',
    text: '당사는 회원가입시 서비스 이용을 위해 필요한 최소한의 개인정보만을 수집합니다. 귀하가 당사의 서비스를 이용하기 위해서는 회원가입시(회원ID, 비밀번호, 성명, 주민등록번호, 주소, 전화번호, E-mail 주소)를 필수적으로 입력하셔야 합니다. 다만, 일부 서비스의 경우에는 회원가입 시 더 적은 필수정보를 요구할 수 도 있습니다. 그러나 직업, 취미, 지역, 관심분야와 같은 선택항목은 이를 입력하지 않더라도 서비스이용에는 제한이 없습니다.',
  },
  {
    title: '제7조 (개인정보의 열람/정정)',
    text: '귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 [개인정보변경]메뉴를 통하여 직접 열람 또는 정정 가능합니다.',
  },
  {
    title: '제8조 (동의철회(회원탈퇴))',
    list: [
      '회원가입 등을 통해 개인정보의 수집, 이용에 대해 귀하께서 동의하신 내용을 귀하께서는 언제든지철회하실 수 있습니다.',
      '거래고객은 유선 또는 영업점을 방문하시어 사이버트레이딩 이용 해지절차를 통해 가능합니다.',
      '회사에 계좌가 없는 준고객의 경우, 회원탈퇴 절차를 통해 가능합니다.',
    ],
  },
  {
    title: '제9조 (개인정보의 보유 및 이용기간)',
    text: '당사는 수집한 개인정보의 수집목적 또는 제공받은 목적이 달성된 경우라도 상법, 증권거래법, 관련세법 등의 관련법류상의 의무이행 및 기타 감독기관의 요청·명령에 따른 제출할 의무이행에 필요할 것으로 판단되는 정보에 대해서는 개인정보를 파기하지 아니하고 제출 등에 활용할 수 있습니다. 다만, 위 경우에 해당되지 않을 것으로 판단되는 경우 귀하의 개인정보를 지체없이 파기합니다.',
    list: [
      '회원가입정보의 경우 : 회원가입을 탈퇴하거나 회원에서 제명된 때',
      '설문조사, 이벤트 등의 목적을 위하여 수집한 경우 : 당해 설문조사, 이벤트 등이 종료한 때',
    ],
  },
  {
    title: '제10조 (개인정보의 파기절차 및 방법)',
    list: [
      '귀하는 회원가입시 개인정보의 수집·이용 및 제공에 대해 동의한 내용은 언제든지 철회하실 수 있습니다. 당사는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기하되, 귀하가 회원가입 등을 위해 입력하신 정보는 내부 방침 및 기타 관련법령에 따라 일정기간 저장 후 파기될 수 있습니다. 이때 일정기간 저장된 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다',
      '파기방법은 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적방법을 사용하여 삭제합니다.',
    ],
  },
  {
    title: '제11조 (법정대리인의 권리)',
    text: `만14세 미만의 아동으로부터 개인정보를 수집하거나 제3자에게 아동의 개인정보를 제공하고자 하는 경우에는
      그 법정대리인의 동의를 구해야 합니다. 이 경우 당사는 그 아동에게 법정대리인의 동의를 얻기 위하여 필요한
      법정대리인의 성명 등의 정보를 요구할 수 있습니다. 법정대리인은 상기 아동의 개인정보에 대한 동의를 철회할
      수 있으며, 당해 아동이 제공한 개인정보에 대한 열람 또는 오류의 정정을 요구할 수 있습니다. 당사는 법정대리인의
      동의없이 아동에 관한 정보를 제3자에게 제공하거나 공유하지 않으며, 아동으로부터 수집한 개인정보에 대하여
      법정대리인이 오류의 정정을 요구하는 경우 그 오류를 정정할 때 까지 해당 개인정보의 이용 및 제공을 금지합니다.`,
  },
  {
    title: '제12조 (개인정보 자동 수집장치의 설치·운영 및 그 거부에 관한 사항)',
    list: [
      '당사는 현재 귀하의 정보를 수시로 저장하고 찾아내는 "쿠키(cookie)"를 운용합니다.',
      '쿠키란 당사의 홈페이지를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트파일로서 귀하의 컴퓨터 하드디스크에 저장되며, 서버에 의해서만 검색되고 보여집니다. 당사는 다음과 같은 목적을 위해 쿠키를 사용합니다.',
      [
        '고객별 접속 빈도나 방문시간 등을 분석하고 고객의 취향과 관심상품을 파악하여 타겟(target) 마케팅 및 서비스 개편 등의 척도로 활용합니다.',
        '매매한 주식, 금융상품 등에 대한 정보와 관심있게 둘러본 항목들에 대한 자취를 추적하여 다음 번 거래시 개인의 맞춤서비스를 제공하는 데 이용합니다.',
        '당사가 진행하는 각종 이벤트에서 귀하의 참여정도 및 방문회수를 파악하여 차별적인 응모기회를 부여하고 개인의 관심분야에 따라 차별화된 정보를 제공하기 위한 자료로 이용됩니다.',
      ],
      '귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.',
      '만일 이러한 수집행위를 원하지 않으신다면 다음의 방법으로 쿠키를 차단하실 수 있습니다.',
      [
        '쿠키 설치 허용여부를 지정하는 방법(Internet Explorer 7.0기준)',
        [
          '[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.',
          '[개인정보 탭]을 클릭합니다.',
          '[개인정보보호 수준]을 설정합니다.',
        ],
        '받은 쿠키를 보는 방법(Internet Explorer 7.0기준)',
        [
          '[도구] 메뉴에서 [인터넷 옵션]을 선택합니다.',
          '[일반 탭(기본 탭)]에서 [설정]을 클릭합니다.',
          '[파일 보기]를 선택합니다.',
        ]
      ],
    ],
  },
  {
    title: '제13조 (개인정보 안정성 확보조치)',
    text: '당사는 귀하의 개인정보가 분실·도난·유출·변조 또는 훼손되지 아니하도록 내부 관리계획 수립, 접속기록 보관 등 안전성 확보에 필요한 기술적·관리적 및 물리적 조치를 다음과 같이 하고 있습니다.',
    list: [
      '관리적 조치',
      [
        '개인정보의 안전한 처리를 위한 내부관리계획의 수립',
        '개인정보를 직접 취급하는 임직원 및 수탁자 등에 대한 교육계획 수립 및 실시',
        '내부관리계획에 따른 정기적인 자체 점검의 실시',
      ],
      '기술적 조치',
      [
        '시스템에 대한 접근 권한을 업무 수행에 필요한 최소 범위로 차등 부여 하고 있음',
        '정보통신망을 통한 불법적인 접근 및 침해사고 방지를 위해 침입차단 및 침입탐지시스템을 운영하고 있으며 24시간 365일 보안관제 하고 있음',
        '시스템의 접속 기록의 보관 및 위/변조 방지를 위해 로그관리 시스템 운영 및 정기 백업을 실시하고 있음',
        '개인정보처리시스템 또는 업무용 컴퓨터에 악성 프로그램을 방지/치료 할 수 있는 백신소프트웨어 등의 보안프로그램을 설치/운영하고 있음',
      ],
      '물리적 조치',
      [
        '당사는 정보유출 등을 방지하기 위해 외부인의 출입을 통제하는 출입통제 시스템 및 장비를 상시 운영하고 있습니다. 24시간 CCTV 및 보안업체의 경비시스템이 운영되고 있음',
      ],
    ],
  },
  {
    title: '제14조 (개인정보침해 관련 상담 및 신고)',
    text: '개인정보침해에 대한 신고·상담이 필요하신 경우에는 정보통신부 산하 공공기관인 한국정보보호진흥원(KISA)내 개인정보침해신고센터로 문의하시기 바랍니다.',
    list: [
      '개인정보침해신고센터',
      '전화 : (02)118',
      '홈페이지 : http://privacy.kisa.or.kr',
    ],
  },
];

<template>
  <div class="mb-3">
    <div class="d-none d-md-block">
      <div class="row no-gutters py-1 px-md-3 border-top border-bottom">
        <div class="col-md-7">내용</div>
        <div class="col-md-3 order-first">조합명</div>
        <div class="col-md-2 text-left text-md-right">작성일</div>
      </div>
    </div>
    <div class="d-block d-md-none border-bottom">
    </div>
    <div class="row no-gutters py-2 px-md-3 border-bottom" v-for="(el, index) in contents" :key="index">
      <div class="col-12 col-md-7 order-md-2">
        <button
          class="btn btn-link text-secondary font-weight-bold p-0"
          @click="$emit('click-deals', el)"
        >{{ el.title }}</button>
      </div>
      <div class="col-8 col-md-3 order-md-1">
        <small>{{ el.dealName }}</small>
      </div>
      <div class="col-4 col-md-2 order-md-3 text-right mb-3">
        <small>{{ el.registTime | date_short }}</small>
      </div>
      <div class="col-12 col-md-7 order-md-4 offset-md-3">
        <p class="contents mb-2">{{ el.contents }}</p>
        <div class=" text-right">
          <button
            class="btn btn-link text-secondary p-0"
            @click="$emit('click-deals', el)"
          ><small>상세보기</small></button>
        </div>
      </div>
      <div class="col-12 col-md-7 order-md-5 offset-md-3" v-if="el.type === 'FILE'">
        <a
          class="btn btn-link btn-sm mr-3"
          target="_blank"
          :href="`${NegoStockApi}/public/deals/contents/file/${el.dealContentsKey}`"
          download
        ><IconDownload class="icon-sm"/></a>
      </div>
    </div>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload.vue';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'ContentsList',
  components: {
    IconDownload,
  },
  data() {
    return {
      NegoStockApi,
    };
  },
  props: {
    contents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.contents {
  white-space: pre-line;
}

.contents {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

import _ from 'underscore';
import {
  CHECK_FORM__COMPANY_UPDATE,
  INIT_FORM__COMPANY_UPDATE,
  INPUT_FORM__COMPANY_UPDATE,
  POST_FORM__COMPANY_UPDATE,
  DONE_FORM__COMPANY_UPDATE,
  FAIL_FORM__COMPANY_UPDATE,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';
import { UpdatePurpose } from '@/filters/filtersCompany';

const INIT_FORM = {
  companyName: '',
  companyCode: '',
  purpose: '',
  message: '',
  file: '',
  employee: '',
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [CHECK_FORM__COMPANY_UPDATE] (state) {
    const { purpose, message, file } = state.form;
    const error = {};

    if (!purpose) {
      error.purpose = '요청사유를 선택해 주세요.';
    } else if (purpose === UpdatePurpose.P32 && !file) {
      error.file = '파일을 입력해 주세요.';
    }
    if (!message) {
      error.message = '전달 내용을 입력해 주세요.';
    }

    state.error = { ...error };
  },
  [INIT_FORM__COMPANY_UPDATE] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__COMPANY_UPDATE] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__COMPANY_UPDATE] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__COMPANY_UPDATE] (state) {
    state.status = 'DONE';

    router.replace({ name: '__OTC_HOT__' });
  },
  [FAIL_FORM__COMPANY_UPDATE] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
     commit(CHECK_FORM__COMPANY_UPDATE);

     if (!getters.invalidForm) {
       dispatch('submitForm');
     }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__COMPANY_UPDATE);

    const { companyCode, purpose, message, file, employee } = state.form;
    const formData = new FormData();
    formData.append('companyCode', companyCode);
    formData.append('purpose', purpose);
    formData.append('message', message);
    if (file) {
      formData.append('file', file);
    }
    if (employee) {
      formData.append('employee', employee);
    }

    api.post('/company/ask/update', formData).then(
      reponse => commit(DONE_FORM__COMPANY_UPDATE, reponse.data),
      error => commit(FAIL_FORM__COMPANY_UPDATE, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

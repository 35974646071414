<template>
  <div class="border p-3">
    <template v-if="!hasContents">
      <h6 class="text-secondary text-center mb-4">앗! 아직 "{{typeHeader}}" 게시물이 없네요.</h6>
      <p>{{typeText}}를 원하신다면 일반게시 {{typeText}} 종목으로 등록해주세요.</p>
    </template>
    <template v-else>
      <h6 class="text-secondary text-center mb-4">원하시는 {{typeText}}가격이 없으신가요?</h6>
      <p>원하시는 가격으로 일반게시 {{typeText}} 종목으로 등록해 주세요.</p>
    </template>
    <!-- <p>하루 한번 매수와 매도 1종목 씩 국내 비상장주식 No.1 사이트인 "*****"에 게시물을 무료로 등록하실 수 있습니다.</p> -->
    <ul class="pl-4">
      <li>회원님 개인정보 공개 없이 네고스탁 공개 ID로 게시</li>
      <li>하루 최대 3번 네고 요청을 받을 수 있음</li>
    </ul>
    <router-link
      class="btn btn-outline-primary btn-block py-2"
      :to="{
        name: 'mystocks_maker',
        query: {
          stockInfoUid: stock.stockInfoUid,
          stockName: stock.stockName,
          type: stock.type === 'SELL' ? 'BUY' : 'SELL',
        },
      }"
    >
      <IconPlusCircleFill class="icon-lg mb-1" />
      <p class="mb-0">게시할 종목에 추가하세요</p>
    </router-link>
  </div>
</template>

<script>
import IconPlusCircleFill from '@/components/icons/IconPlusCircleFill.vue';

export default {
  name: 'MarketsNotice',
  components: {
    IconPlusCircleFill,
  },
  props: {
    hasContents: {
      type: Boolean,
      required: true,
    },
    stock: {
      type: Object,
      required: true,
    },
  },
  computed: {
    typeHeader() {
      return this.stock.type === 'SELL' ? '팝니다' : '삽니다';
    },
    typeText() {
      return this.stock.type === 'SELL' ? '매수' : '매도';
    },
  },
};
</script>

<template>
  <div class="">
    <div class="" v-if="list.length">
      <template v-for="(content, idx) in list">
        <IntentsBlocksCard
          :key="idx"
          :content="content"
          cardType="recent"
          @select-card="routingBlock"
        />
      </template>
      <NegoPagination
        v-if="params"
        :params="params"
        @select-page="routingPage"
      />
    </div>
    <div class="" v-else>
      <p>목록이 없습니다.</p>
    </div>
    <!-- IntentsRecentBlockView -->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import IntentsBlocksCard from '@/components/intentsBlocks/IntentsBlocksCard.vue';
import NegoPagination from '@/components/nego/NegoPagination.vue';

export default {
  name: 'IntentsRecentView',
  components: {
    IntentsBlocksCard,
    NegoPagination,
  },
  computed: {
    ...mapState('intentsRecent', [
      'list',
      'params',
      'error',
      'status',
    ]),
  },
  methods: {
    ...mapActions('intentsRecent', [
      'requestList',
    ]),
    routingBlock({ intentKey }) {
      this.$router.push({ name: 'intent_recent_block', params: { intentKey }});
    },
    routingPage(page) {
      this.$router.push({ name: 'intents_recent', query: { page }});
      this.requestPage();
    },
    requestPage() {
      const page = Number.parseInt(this.$route.query.page) || 1;
      this.requestList({ page });
    },
  },
  mounted() {
    this.requestPage();
  },
};
</script>

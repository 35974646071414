<template>
  <table class="table table-sm">
    <thead>
      <tr class="">
        <td class="font-weight-lighter">접수일자</td>
        <td class="font-weight-lighter">공시제목</td>
        <td class="font-weight-lighter">제출인</td>
      </tr>
    </thead>
    <tbody>
      <tr v-if="loading">
        <td colspan="3">Loading...</td>
      </tr>
      <template v-else>
        <template v-if="list.length">
          <tr v-for="(el, index) in list" :key="index" class="">
            <td>{{ el.date }}</td>
            <td><a href="" class="btn-link" @click.prevent="handleClick(el.postingNo)">{{ el.title }}</a></td>
            <td>{{ el.submitter }}</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="3">데이터가 없습니다.</td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
const LINK = 'https://dart.fss.or.kr/dsaf001/main.do?rcpNo=';

export default {
  name: 'CompanyTablePostings',
  props: {
    list: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick(postingNo) {
      window.open(`${LINK}${postingNo}`);
    },
  },
};
</script>

<style scoped>
.btn-sm {
  line-height: 1;
}
</style>

<template>
  <div class="container my-5">
    <div class="border p-3">
      <h6 class="title">확인매물 매도신청이란?</h6>
      <ul class="text pl-3">
        <li>본인의 전용위탁계좌 주식을 입고하여 매도를 진행하는 것을 말합니다.</li>
        <li>수량 및 가격을 비공개로 설정할 수도 있습니다.(자세한 내용은 아래 영상을 참고해 주세요.)</li>
        <li>주식입고 후에 매도여부, 수량 및 가격을 언제든지 수정하실 수 있습니다.</li>
      </ul>
      <img src="./img/sellassign_xs.png" class="img-fluid d-lg-none mb-4" alt="Responsive image">
      <img src="./img/sellassign_lg.png" class="img-fluid d-none d-lg-block mb-4" alt="Responsive image">
      <h6 class="title">전용위탁계좌 개설 시 장점</h6>
      <ul class="text mb-5  pl-3">
        <li>전용계좌 입고를 통한 매도신청 시, 다른 회원에게는 "<u>즉시 매수 가능한 확인매물</u>"로 검색됩니다.</li>
        <li>네고스탁으로 매수 시 주식을 전용계좌로 수령하여 이후 손쉽게 매도하실 수 있습니다.</li>
        <li>종목과 수량이 동일하다면, <u>증거금 없이 당일 매수 후 매도</u> 차익거래가 가능합니다.
          <br>
          <strong class="text-danger">당일 차익거래 이용가이드 다운로드</strong>
          (
            <a href="/files/nego_guide.pdf" download="차익거래이용가이드.pdf" class="text-dark">
              <IconFileEarmarkText class="icon-sm"/> 차익거래이용가이드.pdf
            </a>
          )
        </li>
      </ul>
      <h6 class="title">확인매물 거래 체결 및 결제 절차</h6>
      <ul class="text pl-3">
        <li>바로매수 신청 시 체결</li>
        <li>바로매수 신청 후 30분 이내에 매수대금 입금</li>
        <li>매도자는 매수자 입금 후 30분 이내 전화이체지시</li>
        <li>매수대금 입금 및 전화이체지시 없을 경우, 거래 자동 취소</li>
      </ul>
      <img src="./img/buyprocess_xs.png" class="img-fluid d-lg-none mb-4" alt="Responsive image">
      <img src="./img/buyprocess_lg.png" class="img-fluid d-none d-lg-block mb-4" alt="Responsive image">
    </div>
  </div>
</template>

<script>
import IconFileEarmarkText from '@/components/icons/IconFileEarmarkText.vue';

export default {
  name: 'SellAssignsNotice',
  components: {
    IconFileEarmarkText,
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, .3px);
}
</style>

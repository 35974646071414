<template>
  <div class="card-img-top embed-responsive embed-responsive-16by9 bg-dark">
    <iframe
      id="ytplayer" type="text/html"
      :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
      allowfullscreen
      allow="autoplay;"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'CardListVideosItemPlayer',
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
};
</script>

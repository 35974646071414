<template>
  <div>
    <FormHead
      title="회원탈퇴"
      text="서비스 회원탈퇴를 처리합니다."
    />
    <form @submit.prevent="submitForm">
      <fieldset
        v-if="!user.socialUser"
      >
        <InputEmail
          placeholder="이메일"
          id="userEmail"
          :value="form['userEmail']"
          :error="error['userEmail']"
          @input-form="(value) => inputForm({ key: 'userEmail', value })"
        />
        <InputPassword
          placeholder="현재비밀번호"
          id="currentPasswd"
          :value="form['currentPasswd']"
          :error="error['currentPasswd']"
          @input-form="(value) => inputForm({ key: 'currentPasswd', value })"
        />
      </fieldset>
      <div
        v-if="user.socialUser && error['userEmail']"
        class="alert alert-danger"
        role="alert"
      >{{ error['userEmail'] }}</div>
      <SubmitButton
        label="탈퇴처리"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <div class="text-right my-3">
      <router-link
        class="btn btn-light btn-sm text-white px-4"
        :to="{ name: 'user_infomation' }"
      >닫기</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__AUTH_REVOKE } from '@/store/mutation-types';
import FormHead from '@/components/auth/form/FormHead';
import InputEmail from '@/components/auth/form/InputEmail.vue';
import InputPassword from '@/components/auth/form/InputPassword.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';

export default {
  name: 'PasswordView',
  components: {
    FormHead,
    InputEmail,
    InputPassword,
    SubmitButton,
  },
  computed: {
    ...mapState('authRevoke', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('authRevoke', [
      'loadingForm',
    ]),
    ...mapState('userInfomation', [
      'user',
    ]),
  },
  methods: {
    ...mapMutations('authRevoke', {
      'inputForm': INPUT_FORM__AUTH_REVOKE,
    }),
    ...mapActions('authRevoke', [
      'submitForm',
    ]),
  },
};
</script>

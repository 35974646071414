import {
  SET_STOCK__TRANSACTIONS_LINKS,
  REQUEST_LIST__TRANSACTIONS_LINKS,
  RECEIVE_LIST__TRANSACTIONS_LINKS,
  FAILURE_LIST__TRANSACTIONS_LINKS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  stock: {
    stockInfoUid: '',
    stockName: '',
  },
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [SET_STOCK__TRANSACTIONS_LINKS] (state, { stockInfoUid, stockName }) {
    state.stock = {
      stockInfoUid,
      stockName,
    };
  },
  [REQUEST_LIST__TRANSACTIONS_LINKS] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__TRANSACTIONS_LINKS] (state, data) {
    state.list = [ ...data ];
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__TRANSACTIONS_LINKS] (state, error) {
    state.error = { ...error }
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, transactionKey) {
    commit(REQUEST_LIST__TRANSACTIONS_LINKS);

    api.get(`/tx/links/${transactionKey}`).then(
      response => commit(RECEIVE_LIST__TRANSACTIONS_LINKS, response.data),
      error => commit(FAILURE_LIST__TRANSACTIONS_LINKS, { error }),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="pt-2 pb-2 py-lg-4 mb-4">
    <ul class="nav justify-content-lg-center">
      <li class="nav-item" v-for="({ name, label }, idx) in links" :key="idx">
        <router-link
          class="nav-link d-inline-block font-weight-bold"
          :class="{'active': $route.name === name}"
          :to="{ name }"
        >{{ label }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NegoLinks',
  props: {
    group: {
      type: String,
      required: true,
    },
  },
  computed: {
    links() {
      return this.groups[this.group];
    },
  },
  data() {
    return {
      groups: {
        groupAngel: [
          { name: '__ANGEL_TRANSACTIONS_PROGRESS__', label: '매매진행(조합지분)' },
          { name: '__ANGEL_TRANSACTIONS_FINISHED__', label: '매매완료(조합지분)' },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  color: #989898;
  font-size: 1rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.25rem;

  @media (min-width: 992px) {
    font-size: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5em;
  }
}
.nav-link.active {
  border-bottom: 2px solid $primary;
  color: $primary;
}
</style>

<template>
  <AppModalSimple
    title="네고스탁 취소목록"
    content="삭제 하시겠습니까?"
  >
    <div class="d-flex w-100">
      <button class="btn btn-outline-dark flex-grow-1 mr-3" @click="onClose">취소</button>
      <button class="btn btn-outline-dark flex-grow-1" @click="onDelete">삭제</button>
    </div>
  </AppModalSimple>
</template>

<script>
import { mapActions } from 'vuex';
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'TransactionsDeleteView',
  components: {
    AppModalSimple,
  },
  methods: {
    ...mapActions('transactionsCanceled', [
      'deleteTransaction',
    ]),
    onClose() {
      this.$router.replace({ name: 'transactions_list' });
    },
    onDelete() {
      const { transactionKey } = this.$route.params;
      this.deleteTransaction({ transactionKey });
    },
  },
};
</script>

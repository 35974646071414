<template>
  <div>
    <ContentsList
      :contents="list"
      :isStaff="false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ContentsList from '@/components/deals/ContentsList';

export default {
  name: 'DealsContentsPublic',
  components: {
    ContentsList,
  },
  computed: {
    ...mapState('dealsContentsPublic', [
      'list',
    ]),
  },
  methods: {
    ...mapActions('dealsContentsPublic', [
      'requestList',
    ]),
  },
  mounted() {
    const { dealKey } = this.$route.query;
    this.requestList({ dealKey });
  },
};
</script>

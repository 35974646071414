<template>
  <div class="border p-3 my-5">
    <h6 class="title text-center mb-3">신기술투자조합 출자위험 안내</h6>
    <ul class="text pl-3">
      <li><strong>투자원본에 대한 손실위험</strong> : 신기술투자조합(이하 "조합")은 실적배당형으로 투자원리금 전액이 보장 또는 보호되지 않습니다. 따라서 투자원본의 전부 또는 일부에 대한 손실의 위험이 존재하며 투자금액의 손실 내지 감소의 위험은 전적으로 투자자가 부담하며, 당사나 조합의 공동업무집행조합원 등 어떤 당사자도 투자손실에 대하여 책임을 지지 아니합니다.</li>
      <li><strong>시장위험 및 개별위험</strong> : 조합자산을 주식 및 주식관련장내파생상품 등에 주로 투자함으로써 투자증권의 가격변동, 이자율 변동 등 기타 거시경제지표의 변화에 따른 위험에 노출됩니다. 또한, 예상하지 못한 국내외 정치, 경제상황, 정부의 조치 및 세제의 변경 등도 운용에 영향을 미칠 수 있으며, 조합자산이 투자한 상품의 가치는 투자대상종목 발행회사의 영업환경, 재무상황 및 신용상태의 악화에 따라 급격히 변동될 수 있습니다.</li>
      <li><strong>비상장주식에 대한 투자위험</strong> : 조합이 투자하는 기업은 주로 비상장 기업으로 상장 기업에 준하는 공시의무를 지니지 않으므로 정보 접근성이 상대적으로 떨어지며 상당히 낮은 수준의 투명성을 가진 기업의 지분입니다. 그러므로 투자자는 동 투자대상자산의 가격변동의 사유 또는 이상 변동 등에 대하여 사전 또는 사후적으로 충분한 설명을 듣지 못할 수 있고, 경우에 따라서는 인지하지 못한 추가적인 손실에 노출될 수 있습니다.</li>
      <li><strong>존속기간 연장 위험</strong> : 조합의 존속기간 만료 후 청산의 목적범위 내에서 존속할 경우 만기를 초과해서 투자회수가 지연될 수 있습니다(조합원총회 결의를 통해 만기 연장가능).</li>
      <li><strong>유동성 위험</strong> : 유가증권시장의 규모 등을 감안할 때 조합에서 거래량이 풍부하지 못한 비상장주식 종목에 투자하는 경우 투자대상 종목의 유동성 부족에 따른 환금성에 제약이 발생할 수 있으며, 이는 환매연기나 거래비용 증가 등으로 기회비용 발생과 함께 투자원금 손실이 발생할 수 있습니다.</li>
      <li><strong>환금성(실물인수 위험)</strong> : 조합은 일부 보유자산의 환가(시장 매각) 및 회수 불가사유 발생 또는 증권 거래시장 등의 폐쇄, 정치, 천재지변 등의 불가피한 상황이 발생한 경우 출금이 제한 될 수도 있으며, 조합재산을 현상대로 교부할 수 있습니다.</li>
      <li><strong>기업공개(IPO) 위험</strong> : 투자대상 기업이 계약 기간 동안 상장되지 않을 위험이 존재합니다. 이러한 경우 현금화 하지 못하거나 현금화 하기 위하여 예상치 못한 손실 위험이 존재합니다. 또한 상장이 되더라도 매도 시까지 지속적인 주가하락에 의하여 평가손실을 입거나 수익률이 하락할 리스크가 존재합니다.</li>
      <li><strong>조합지분양도 위험</strong> : 조합 출자지분의 양도는 가능하지만 규약에 따른 절차를 감안하여 조합원이 원하는 시기에 양도가 어려울 수 있으며, 양도 시 출자금액 이하의 금액으로 양도할 수 있는 가능성이 있습니다.</li>
      <li><strong>수수료 부담</strong> : 본 조합에 참여하는 조합원은 본 조합의 조합운영경비와 업무집행조합원에 대한 관리보수 및 성과보수를 부담하게 됩니다. 이로 인해 조합의 투자대상이 비상장주식 등에 직접 투자하는 것과 비교 시 투자 수익율 낮아질 가능성이 있습니다.</li>
    </ul>
    <small class="font-weight-bold text-danger">위 사항들은 신기술투자조합에 유한책임조합원으로 참여하는 것에 수반되는 위험 등 잠재적 투자자가 알아야 할 일반적인 사항을 간략하게 서술한 것으로 조합참여(투자)와 관련하여 발생될 수 있는 모든 위험과 중요 사항을 전부 기술한 것은 아닙니다. 보다 상세한 내용은 각 개별 조합의 상품설명서를 통해 확인하실 수 있습니다.</small>
  </div>
</template>

<script>
export default {
  name: 'LPNotice',
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, -1.3px);
}
</style>

<template>
  <div class="otc-hot">
    <NegoLinks group="group3"/>
    <div class="container">
      <!-- <p class="mb-md-0">고객님께 투자기회를 제공해드리기 위해 준비중인 <br class="d-md-none">주요 비상장기업의 목록입니다.</p> -->
      <p class="mb-md-0">#AI, #전기차.2차전지 등 키워드별로 <br class="d-md-none">어떤 HOT한 비상장기업들이 있는지 알아보세요.</p>
      <div class="row mt-3">
        <div class="col-5 col-lg-2 text-left">
          <DropdownsKeyword
            @toggle-item="handleToggle"
          />
        </div>
        <div class="col-7 col-lg-3 text-right">
          <button
            v-for="(sort, index) in sorting"
            :key="index"
            class="btn btn-sm rounded-0"
            :class="sort.selected ? 'border-bottom' : ''"
            @click="() => sortList(sort.field)"
          >
            <IconSortDownAlt class="icon-sm mr-1" v-if="sort.direct === 'ASC'" />
            <IconSortUp class="icon-sm mr-1" v-if="sort.direct === 'DESC'" />
            {{ sort.label }}
          </button>
        </div>
        <div class="col-12 col-lg-7 order-lg-first">
          <InputSearch
            :keyword="filter"
            @input-search="handleFilter"
          />
        </div>
      </div>
      <!-- <LoadingSpinner v-if="loadingList" class="font-sm my-4" /> -->
      <div v-if="limitedList.length">
        <CardListHot
          :list="limitedList"
          :selectable="isAuthenticated"
          @select-item="selectItem"
          @toggle-item="handleItem"
        />
        <button
          class="btn btn-primary btn-sm btn-block mb-4"
          :disabled="disabledLimition"
          @click="moreList"
        >더 보기</button>
      </div>
      <template v-if="limitedList.length === 0">
        <div class="alert alert-light mt-4">
          등록된 기업이 없습니다. 등록을 원하시는 기업이 있으시다면 요청해주세요. 기업정보가 등록되면 기업정보페이지에서 자금조달 또는 매매를 요청하실 수 있습니다.
          <br/>
          <button
            class="btn btn-primary btn-block rounded-0 mt-3"
            @click="checkUser"
          >기업등록 요청하기</button>
        </div>
        <!-- <div class="alert alert-warning font-sm">
          목록에는 최대 200개 기업까지만 표시됩니다. "키워드 선택" 또는 "검색어 입력"을 통해 찾으시는 기업의 범위를 좁혀주세요.
        </div> -->
      </template>

    </div>
    <template v-if="item && !item.registedNotice">
      <AppModalSimple
        title="투자기회 알림요청"
        :content="`<mark>'${item.companyName}'</mark>에 투자하실 수 있는 기회가 생기는 경우 알림메시지를 보내드립니다. '투자기회 알림요청'을 신청하시겠어요?`"
      >
        <div class="row no-gutters w-100">
          <div class="col pr-1">
            <button class="btn btn-outline-dark btn-block" @click="registNotice">예</button>
          </div>
          <div class="col pl-1">
            <button class="btn btn-outline-dark btn-block" @click="clearItem">아니오</button>
          </div>
        </div>
      </AppModalSimple>
    </template>
    <template v-if="item && item.registedNotice">
      <AppModalSimple
        title="투자기회 알림요청 취소"
        :content="`<mark>'${item.companyName}'</mark>에 투자하실 수 있는 기회가 생기는 경우 받을 수 있는 알림을 취소하시겠습니까?`"
      >
        <div class="row no-gutters w-100">
          <div class="col pr-1">
            <button class="btn btn-outline-dark btn-block" @click="deleteNotice">예</button>
          </div>
          <div class="col pl-1">
            <button class="btn btn-outline-dark btn-block" @click="clearItem">아니오</button>
          </div>
        </div>
      </AppModalSimple>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import InputSearch from '@/components/otc/InputSearch.vue';
import CardListHot from '@/components/otc/CardListHot.vue';
import DropdownsKeyword from './DropdownsKeyword.vue';
import AppModalSimple from '@/components/app/AppModalSimple.vue';
import IconSortUp from '@/components/icons/IconSortUp.vue';
import IconSortDownAlt from '@/components/icons/IconSortDownAlt.vue';
// import LoadingSpinner from '@/components/form/LoadingSpinner.vue';
import {
  FILTER__OTC_HOT,
  SORT__OTC_HOT,
  INITIAL__OTC_HOT,
  SELECT__OTC_HOT,
  CLEAR__OTC_HOT,
  LIMITED__OTC_HOT,
  TOGGLE__KEYWORDS,
  DESELECT__KEYWORDS,
} from '@/store/mutation-types';

export default {
  name: 'OTCHot',
  components: {
    NegoLinks,
    InputSearch,
    CardListHot,
    DropdownsKeyword,
    AppModalSimple,
    IconSortUp,
    IconSortDownAlt,
    // LoadingSpinner,
  },
  computed: {
    ...mapGetters('userInfomation', [
      'isAuthenticated',
      'isPreUser',
    ]),
    ...mapState('otcHot', [
      'item',
      'sorting',
      'filter',
    ]),
    ...mapGetters('otcHot', [
      'loadingList',
      'limitedList',
      'disabledLimition',
    ]),
  },
  methods: {
    ...mapMutations('otcHot', {
      filterList: FILTER__OTC_HOT,
      sortList: SORT__OTC_HOT,
      initList: INITIAL__OTC_HOT,
      selectItem: SELECT__OTC_HOT,
      clearItem: CLEAR__OTC_HOT,
      moreList: LIMITED__OTC_HOT,
    }),
    ...mapActions('otcHot', [
      'requestList',
      'requestListPublic',
      'registNotice',
      'deleteNotice',
    ]),
    ...mapMutations('keywords', {
      toggleItem: TOGGLE__KEYWORDS,
      deselectItem: DESELECT__KEYWORDS,
    }),

    handleFilter(value) {
      this.filterList(value);
      this.deselectItem();
    },
    handleToggle() {
      this.filterList('');
    },
    handleItem() {
      this.toggleItem();
      this.filterList('');
    },
    checkUser() {
      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'sign_in',
          query: { redirect_path: '/company/register' },
        });
        return;
      }

      if (this.isPreUser) {
        this.$router.push({
          name: 'user_mobile',
        });
        return;
      }

      this.$router.push({
        name: '__COMPANY_REGISTER__',
      });
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.requestList();
    } else {
      this.requestListPublic();
    }
  },
};
</script>

<style lang="scss" scoped>
.otc-hot {
  min-height: calc(100vh - 56px);
}
.border-bottom {
  border-bottom: 1px solid #000000 !important;
}
</style>

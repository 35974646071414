<template>
  <div class="mt-4">
    <h6>광고성 투자정보 수신</h6>
    <SelectOptions
      id="allowMarketing"
      label="동의여부"
      :value="form['allowMarketing']"
      :options="USER__ALLOW_MARKETING"
      @input-form="(value) => inputForm({ key: 'allowMarketing', value })"
    />
    <div class="mb-3">
      <button
        class="btn btn-primary btn-block"
        @click="submitForm"
      >저장하기</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import SelectOptions from '@/components/form/SelectOptions.vue';
import { INPUT_FORM__USER_MARKETING } from '@/store/mutation-types';
import {
  USER__ALLOW_MARKETING,
} from '@/filters/filtersUser';

export default {
  name: 'MarketingView',
  components: {
    SelectOptions,
  },
  data() {
    return {
      USER__ALLOW_MARKETING,
    };
  },
  computed: {
    ...mapState('userMarketing', [
      'form',
    ]),
    ...mapGetters('userMarketing', [
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('userMarketing', {
      inputForm: INPUT_FORM__USER_MARKETING,
    }),
    ...mapActions('userMarketing', [
      'submitForm',
    ]),
  },
  mounted() {
    const { allowMarketing } = this.$route.query;
    this.inputForm({ key: 'allowMarketing', value: allowMarketing.toString() });
  },
};
</script>

<style scoped>
.terms {
  height: 16rem;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<template>
  <img
    v-if="isLoadingSuccess"
    class="image rounded-0 h-100 w-100"
    :class="`bi-${icon} image-${object}`"
    :src="src"
    @error="handleError"
  />
  <div v-else class="icon d-flex justify-content-center align-items-center h-100 w-100">
    <IconPerson
      v-if="icon === 'person'"
      :class="iconClass"
    />
    <IconBuilding
      v-if="icon === 'building'"
      :class="iconClass"
    />
    <IconNewspaper
      v-if="icon === 'newspaper'"
      :class="iconClass"
    />
  </div>
</template>

<script>
import IconPerson from '@/components/icons/IconPerson';
import IconBuilding from '@/components/icons/IconBuilding';
import IconNewspaper from '@/components/icons/IconNewspaper';

export default {
  name: 'ImageAndIcons',
  components: {
    IconPerson,
    IconBuilding,
    IconNewspaper,
  },
  props: {
    src: {
      type: String,
      required: false,
    },
    object: {
      type: String,
      default: 'contain',
    },
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: 'icon-xl text-light',
    },
  },
  data() {
    return {
      isLoadingSuccess: true,
    };
  },
  methods: {
    handleError() {
      this.isLoadingSuccess = false;
    },
  },
  beforeMount() {
    if (!this.src) {
      this.handleError();
    }
  },
  watch: {
    src(val) {
      this.isLoadingSuccess = val ? true : false;
    },
  },
};
</script>

<style scoped>
.image,
.icon {
  background-color: #ebebeb;
}
.image-contain {
  object-fit: contain;
}
.image-cover {
  object-fit: cover;
}
</style>

<template>
  <div class="pt-3">
    <form @submit.prevent="checkForm">
      <h5 class="text-center my-4">비상장주식 1:1 문의 작성</h5>
      <InputText
        id="title"
        label="제목"
        :value="form['title']"
        @input-form="value => inputForm({ key: 'title', value })"
        :error="error['title']"
      />
      <InputTextArea
        id="contents"
        label="내용"
        :value="form['contents']"
        @input-form="value => inputForm({ key: 'contents', value })"
        :error="error['contents']"
      />
      <SubmitButton
        label="작성완료"
        :isLoading="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import InputText from '@/components/form/InputText.vue';
import InputTextArea from '@/components/form/InputTextArea.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { INPUT_FORM__CONSULT_CREATE } from '@/store/mutation-types';

export default {
  name: 'ConsultCreate',
  components: {
    InputText,
    InputTextArea,
    SubmitButton,
  },
  computed: {
    ...mapState('consultCreate', [
      'form',
      'error',
    ]),
    ...mapGetters('consultCreate', [
      'enabledForm',
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('consultCreate', {
      'inputForm': INPUT_FORM__CONSULT_CREATE,
    }),
    ...mapActions('consultCreate', [
      'requestKey',
      'checkForm',
      'submitForm',
    ]),
  },
  mounted() {
    this.requestKey();
  },
};
</script>

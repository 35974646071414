<template>
  <div class="rounded bg-warning p-3">
    <p class="mb-0"><span class="font-weight-bold">"주당가격 확인"과 "바로매수 신청"은 개인전문투자자만 가능합니다.</span> 당사 전문투자자 등록신청은 회원정보 페이지에서 가능하며, 휴대전화 촬영을 통해 전문투자자 확인증을 제출해주시면 당사의 전문투자자로 등록하실 수 있습니다.</p>
    <p class="text-right mb-0">
      <router-link
        class="btn btn-secondary btn-sm"
        :to="{
          name: 'user_infomation',
          hash: '#infomation-regist',
        }"
      >회원정보페이지로 가기</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'SellAssignsOrderNoticeOnlyPro',
};
</script>

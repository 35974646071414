<template>
  <div>
    <NegoLinks group="group4"/>
    <div class="container">
      <p class="mb-2">
        완료 또는 취소된 비상장주식 매매내역입니다. <br>(내역서 파일암호: 생년월일 6자리)
      </p>
      <div class="text-right mb-2">
        <SelectButtons
          :value="currentMode"
          :left="{ label: '완료건', value: 'finished' }"
          :right="{ label: '취소건', value: 'canceled' }"
          @input-form="toggleMode"
        />
      </div>
      <template v-if="currentMode === 'finished'">
        <TransactionsFinishedView/>
      </template>
      <template v-if="currentMode === 'canceled'">
        <TransactionsCanceledView/>
      </template>
    </div>
    <TransactionsNotice/>
  </div>
</template>

<script>
import NegoLinks from '@/components/nego/NegoLinks.vue';
import SelectButtons from '@/components/form/SelectButtons.vue';
import TransactionsFinishedView from './TransactionsFinishedView.vue';
import TransactionsCanceledView from './TransactionsCanceledView.vue';
import TransactionsNotice from '@/components/transactions/TransactionsNotice.vue';

export default {
  name: 'TransactionsListView',
  components: {
    NegoLinks,
    SelectButtons,
    TransactionsFinishedView,
    TransactionsCanceledView,
    TransactionsNotice,
  },
  data() {
    return {
      currentMode: 'finished',
    };
  },
  methods: {
    toggleMode(mode) {
      this.currentMode = mode;
    },
  },
  beforeMount() {
    const {mode} = this.$route.query;
    if (mode) {
      this.toggleMode(mode);
    }
  },
};
</script>

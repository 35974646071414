<template>
  <div>
    <div v-if="intent.stockName">
      <SampleJumbotron
        :intent="intent"
        :loadingStatus="loadingData"
      />
      <div class="mt-n5 text-center d-block d-lg-none">
        <a href="#home-cards" class="btn btn-link" aria-label="scroll down page">
          <img class="icon text-secondary" :src="icon" alt="scroll down page"/>
        </a>
      </div>
    </div>
    <div v-else>
      <HomeAuth/>
      <div class="container pt-5">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6">
            <div class="border py-3">
              <h3 class="text-center mb-0">삭제되거나 기간이 만료된<br class="d-md-none"> 네고요청입니다.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeCards id="home-cards"/>
    <HomeVideo id="home-video"/>
    <HomeProcess/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SampleJumbotron from '@/components/negoSample/SampleJumbotron.vue';
import HomeAuth from '@/components/homeService/HomeAuth.vue';
import HomeCards from '@/components/homeService/HomeCards.vue';
import HomeVideo from '@/components/homeService/HomeVideo.vue';
import HomeProcess from '@/components/homeService/HomeProcess.vue';
import icon from './img/arrow-down.svg';

export default {
  name: 'NegoSampleView',
  components: {
    SampleJumbotron,
    HomeAuth,
    HomeCards,
    HomeVideo,
    HomeProcess,
  },
  data() {
    return {
      icon,
    };
  },
  computed: {
    ...mapState('negoSample', [
      'intent',
    ]),
    ...mapGetters('negoSample', [
      'loadingData',
    ]),
  },
  methods: {
    ...mapActions('negoSample', [
      'requestIntent'
    ]),
  },
  mounted() {
    const { intentKey } = this.$route.params;
    if (intentKey) {
      this.requestIntent({ intentKey });
    }
  },
};
</script>

<style scoped>
.icon {
  width: 2rem;
}
</style>


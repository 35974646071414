const RegistPurpose = {
  P11: '11',
  P12: '12',
  P13: '13',
  P14: '14',
  P15: '15',
  P16: '16',
};
const REGIST_PURPOSE_TYPE = [
  { value: RegistPurpose.P11, label: '직접투자에 관심 있음' },
  { value: RegistPurpose.P12, label: '조합을 통한 간접투자에 관심 있음' },
  { value: RegistPurpose.P13, label: '보유 중인 주식 등 매각에 관심 있음' },
  { value: RegistPurpose.P14, label: '회사 임직원이며 자금조달 희망' },
  { value: RegistPurpose.P15, label: 'Co-GP 공동투자자 발굴 희망' },
  { value: RegistPurpose.P16, label: '기타' },
];

const UpdatePurpose = {
  P31: '31',
  P32: '32',
  P33: '33',
  P34: '34',
};
const UPDATE_PURPOSE_TYPE = [
  { value: UpdatePurpose.P31, label: '기업정보 수정' },
  { value: UpdatePurpose.P32, label: '자금조달 희망' },
  { value: UpdatePurpose.P33, label: '주식 등 매도희망' },
  { value: UpdatePurpose.P34, label: 'Co-GP 공동투자자 발굴 희망' },
];

export {
  RegistPurpose,
  REGIST_PURPOSE_TYPE,
  UpdatePurpose,
  UPDATE_PURPOSE_TYPE,
};

<template>
  <table class="table table-bordered table-sm font-weight-light text-nowrap">
    <thead>
      <tr class="bg-gray">
        <td>일자</td>
        <td>구분</td>
        <td class="text-center">증가</td>
        <td class="text-center">감소</td>
        <td class="text-center">잔액</td>
        <td class="text-center">분배수익</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(el, index) in list" :key="index">
        <td>{{ el.issuedDate | date_short_1 }}</td>
        <td class="text-right">{{ el.type | investor_type }}</td>
        <td class="text-right">{{ el.increase | currency }}</td>
        <td class="text-right">{{ el.decrease | currency }}</td>
        <td class="text-right">{{ el.balance | currency }}</td>
        <td class="text-right">{{ el.profitLoss | currency }}</td>
      </tr>
      <tr v-if="!list.length">
        <td colspan="6">데이터가 없습니다.</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'HistoryTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="mb-3">
    <div class="my-4 border-bottom">
      <h5>{{ form.stockName }} <small>({{ form.stockInfoUid }})</small></h5>
    </div>
    <p>일반게시 건을 삭제 하시겠습니까?</p>
    <form @submit.prevent="deleteForm">
      <SubmitButton
        label="삭제"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
    <div
      class="alert alert-danger"
      role="alert"
      v-if="error.myStockKey"
    >{{ error.myStockKey }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__MYSTOCKS_TRANS } from '@/store/mutation-types';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';

export default {
  name: 'MyStocksTransView',
  components: {
    SubmitButton,
  },
  computed: {
    ...mapState('myStocksTrans', [
      'form',
      'error',
    ]),
    ...mapGetters('myStocksTrans', [
      'enabledForm',
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('myStocksTrans', {
      'inputForm': INPUT_FORM__MYSTOCKS_TRANS,
    }),
    ...mapActions('myStocksTrans', [
      'deleteForm',
    ]),
  },
  mounted() {
    const { myStockKey } = this.$route.params;
    const { stockInfoUid, stockName } = this.$route.query;
    this.inputForm({ key: 'myStockKey', value: myStockKey });
    this.inputForm({ key: 'stockInfoUid', value: stockInfoUid });
    this.inputForm({ key: 'stockName', value: stockName });
  },
}
</script>

import {
  REQUEST__INVEST_PLACEMENTS_CONTENTS,
  RECEIVE__INVEST_PLACEMENTS_CONTENTS,
  FAILURE__INVEST_PLACEMENTS_CONTENTS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__INVEST_PLACEMENTS_CONTENTS] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__INVEST_PLACEMENTS_CONTENTS] (state, data) {
    state.list = [...data];
    state.status = 'RECEIVE';
  },
  [FAILURE__INVEST_PLACEMENTS_CONTENTS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST__INVEST_PLACEMENTS_CONTENTS);

    api.get('/invest/placement/contents').then(
      response => commit(RECEIVE__INVEST_PLACEMENTS_CONTENTS, response.data),
      error => commit(FAILURE__INVEST_PLACEMENTS_CONTENTS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

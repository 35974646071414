import _ from 'underscore';
import {
  REQUEST__PLACEMENTS_PORTFOLIS,
  RECEIVE__PLACEMENTS_PORTFOLIS,
  FAILURE__PLACEMENTS_PORTFOLIS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  histories: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
  totalAmount: state => state.list.reduce((acc, el) => acc + el.amount, 0),
};

const mutations = {
  [REQUEST__PLACEMENTS_PORTFOLIS] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__PLACEMENTS_PORTFOLIS] (state, data) {
    const newList = [];
    const newHistories = [];
    _.each(data, ({ histories, ...portfolio }) => {
      newList.push(portfolio);
      newHistories.push({
        histories,
        companyName: portfolio.companyName,
        stockName: portfolio.stockName,
        abnormal: histories[0].stockType !== '보통주',
      });
    });
    state.list = newList;
    state.histories = newHistories;
    state.status = 'RECEIVE';
  },
  [FAILURE__PLACEMENTS_PORTFOLIS] (state) {
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, dealKey) {
    commit(REQUEST__PLACEMENTS_PORTFOLIS);

    api.get(`/invest/placement/portfolios/${dealKey}`).then(
      response => commit(RECEIVE__PLACEMENTS_PORTFOLIS, response.data),
      error => commit(FAILURE__PLACEMENTS_PORTFOLIS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

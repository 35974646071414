<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between bg-primary text-white px-3">
      <div>
        <h6 class="mb-0">{{ transaction.stockName }}</h6>
        <p class="mb-0 stock-code">{{ transaction.stockCode }}</p>
      </div>
      <h6 class="mb-0">{{ transaction.seller ? '매도' : '매수' }}</h6>
    </div>
    <div class="card-body price p-3">
      <h6 class="text-right mb-1">{{ transaction.offerPrice | currency }} <small>원</small></h6>
      <div class="d-flex justify-content-between">
        <p class="mb-1">{{ shareMessage }}</p>
        <h6 class="mb-1">{{ transaction.offerQuantity | currency }} <small>주</small></h6>
      </div>
      <div class="d-flex justify-content-between border-top pt-2">
        <p class="mb-0">{{ payMessage }}</p>
        <h6 class="mb-0">{{ transaction.offerPrice * transaction.offerQuantity | currency }} <small>원</small></h6>
      </div>
      <div class="my-3 text-center">
        <span class="description mb-0" v-html="message"></span>
      </div>
      <div v-if="enableDelete">
        <router-link
          class="btn btn-outline-primary btn-block btn-sm"
          :to="{
            name: 'transactions_delete',
            params: { transactionKey: transaction.transactionKey },
            query: { ...$route.query }
          }"
        >삭제하기</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS, PAY_STATUS, SHARE_STATUS } from './transactionsCanceledCardStatus';

export default {
  name: 'TransactionsCanceledCard',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status() {
      const { buyer, seller, payStatus, shareStatus } = this.transaction;
      if (buyer) {
        return `BUYER__BROKEN__${payStatus}`;
      }
      if (seller){
        return `SELLER__BROKEN__${shareStatus}`;
      }
      return '';
    },
    payMessage() {
      return PAY_STATUS[this.transaction.payStatus];
    },
    shareMessage() {
      return SHARE_STATUS[this.transaction.shareStatus];
    },
    message() {
      return STATUS[this.status];
    },
    enableDelete() {
      const { buyer, seller, payStatus, shareStatus } = this.transaction;
      if (buyer && (payStatus === 'ACCEPT' || payStatus === 'REFUND')) {
        return true;
      }
      if (seller && (shareStatus === 'ACCEPT' || shareStatus === 'WAITING_IN' || shareStatus === 'RETURN_OUT')) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  @include nego-font(1rem, 300, 1.25, 0.2px, $dark);
}
.description {
  @include nego-font(1rem, 400, 1.22, 0.2px, $dark);
}
.stock-code {
  font-size: 14px;
  line-height: 1.2;
}
</style>

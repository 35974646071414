<template>
  <div class="">
    <form @submit.prevent="checkForm">
      <FormHead
        title="정보수정 등 요청"
        :text="form['companyName']"
      />
      <SelectOptions
        id="purpose"
        label="요청 선택"
        :options="UPDATE_PURPOSE_TYPE"
        :value="form['purpose']"
        :error="error['purpose']"
        @input-form="(value) => inputForm({ key: 'purpose', value })"
      />
      <InputTextArea
        id="message"
        label="담당자에게 전할 내용"
        :value="form['message']"
        :error="error['message']"
        @input-form="value => inputForm({ key: 'message', value })"
      />
      <InputFile
        id="file"
        label="회사소개서 등 파일 첨부"
        @input-form="value => inputForm({ key: 'file', value })"
        :error="error['file']"
      />
      <InputText
        v-if="form['purpose'] !== UpdatePurpose.P31"
        label="본 요청을 전달받을 당사 임직원 성명 (선택입력)"
        id="employee"
        :value="form['employee']"
        :error="error['employee']"
        @input-form="(value) => inputForm({ key: 'employee', value })"
      />
      <p
        class="my-4"
      >담당자 검토 후 회원정보에 등록된 휴대전화로 연락을 드리도록 하겠습니다.</p>
      <SubmitButton
        label="요청하기"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <div class="text-right my-4">
      <router-link
        class="btn btn-light text-white px-4 btn-sm"
        :to="{ name: '__COMPANY_VIEW__', params: { companyCode: form['companyCode'] } }"
      >이전으로</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import SelectOptions from '@/components/form/SelectOptions.vue';
import InputText from '@/components/form/InputText.vue';
import InputTextArea from '@/components/form/InputTextArea.vue';
import InputFile from '@/components/form/InputFile.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import {
  INIT_FORM__COMPANY_UPDATE,
  INPUT_FORM__COMPANY_UPDATE,
} from '@/store/mutation-types';
import { UpdatePurpose, UPDATE_PURPOSE_TYPE } from '@/filters/filtersCompany';

export default {
  name: 'CompanyUpdate',
  components: {
    FormHead,
    SelectOptions,
    InputText,
    InputTextArea,
    InputFile,
    SubmitButton,
  },
  data() {
    return {
      UpdatePurpose,
      UPDATE_PURPOSE_TYPE,
    };
  },
  computed: {
    ...mapState('companyUpdate', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('companyUpdate', [
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('companyUpdate', {
      'initForm': INIT_FORM__COMPANY_UPDATE,
      'inputForm': INPUT_FORM__COMPANY_UPDATE,
    }),
    ...mapActions('companyUpdate', [
      'checkForm',
      'submitForm',
    ]),
  },
  mounted() {
    const { companyCode, companyName } = this.$route.query
    this.inputForm({ key: 'companyCode', value: companyCode });
    this.inputForm({ key: 'companyName', value: companyName });
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

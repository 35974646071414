<template>
  <div class="input-group">
    <input
      id="input-search"
      type="search"
      class="form-control rounded-0 border-0 pl-0"
      aria-describedby="button-search"
      placeholder="기업명 검색"
      minlength="2"
      :value="keyword"
      @input="_debounce($event.target.value.trim())"
    >
    <div class="input-group-append">
      <button class="btn btn-outline-secondary btn-sm border-0" type="button" id="button-search">
        <IconSearch class="icon-xs" />
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import IconSearch from '@/components/icons/IconSearch.vue';

export default {
  name: 'InputSearch',
  components: {
    IconSearch,
  },
  props: {
    keyword: {
      type: String,
      required: true,
    },
  },
  methods: {
    onInputSearch(keyword) {
      this.$emit('input-search', keyword);
    },
  },
  mounted() {
    this._debounce = _.debounce(this.onInputSearch, 300);
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  border-bottom: 1px solid #dee2e6;
}

.input-group:focus-within {
  border-bottom-color: $secondary;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>

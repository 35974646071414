<template>
  <div class="placements-advise">
    <NegoLinks group="group3"/>
    <div class="container">
      <p>추후 "투자기회"에서 만나실 수 있는 <br class="d-md-none">당사에 딜진행 검토를 요청한 기업들을 소개합니다.</p>
      <div class="mt-4">
        <CardListDeals
          :list="list"
          :loadingList="loadingList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import CardListDeals from '@/components/placementsAdvise/CardListDeals.vue';

export default {
  name: 'PlacementsAdvise',
  components: {
    NegoLinks,
    CardListDeals,
  },
  computed: {
    ...mapState('placementsAdvise', [
      'list',
    ]),
    ...mapGetters('placementsAdvise', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('placementsAdvise', [
      'requestList',
    ]),
  },
  mounted() {
    this.requestList();
  },
};
</script>

<style lang="scss" scoped>
.placements-advise {
  min-height: 95vh;
}
</style>

<template>
  <div>
    <div class="container my-5">
      <h4 class="text-center text-primary mb-5"><strong>회원간 매매거래 규칙</strong></h4>
      <div class="row">
        <div class="col-12 text-center">
          <p class="text mb-4"><strong>하나.</strong> 네고요청을 "받은회원"이 먼저 "거래요청"하고, "보낸회원"이 "승락"하면 거래가 체결됩니다.</p>
          <p class="text mb-4"><strong>둘.</strong> 합의한 결제이행 시간까지 당사가 지정하는 은행 및 증권 계좌로 매수대금과 주식을 이체합니다.</p>
          <p class="text mb-4"><strong>셋.</strong> 일방이라도 결제이행 시간 이내에 결제를 이행하지 않으면 체결된 거래는 자동으로 취소됩니다.</p>
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div class="container py-5 mb-5">
        <h4 class="header text-center text-primary font-weight-bold mb-3">결제이행 지정시간</h4>
        <h6 class="text-center mb-4">"거래요청"하는 시간에 따라 선택 가능한<br class="d-md-none">결제이행 시간은 다음과 같습니다.</h6>
        <div class="d-md-none">
          <table class="table table-bordered text-center">
            <caption>*오늘과 내일은 영업일 기준입니다.</caption>
            <thead class="thead-light">
              <tr>
                <th class="">14시 이전</th>
                <th>14시 이후</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>오늘 또는<br> 내일(10시, 15시)</td>
                <td>내일(10시, 15시)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row d-none d-md-flex">
          <div class="col-12 col-lg-4 text-center mb-3">
            <p class="title mb-3">13시 이전</p>
            <ul class="list-unstyled text">
              <li class="text mb-0">1시간 이내</li>
              <li class="text mb-0">오늘 오후 3시까지</li>
              <li class="text mb-0">익영업일 10시까지</li>
              <li class="text mb-0">익영업일 15시까지</li>
            </ul>
          </div>
          <div class="col-12 col-lg-4 text-center mb-3">
            <p class="title mb-3">14시 이전</p>
            <ul class="list-unstyled text">
              <li class="text mb-0">오늘 오후 3시까지</li>
              <li class="text mb-0">익영업일 10시까지</li>
              <li class="text mb-0">익영업일 15시까지</li>
            </ul>
          </div>
          <div class="col-12 col-lg-4 text-center mb-3">
            <p class="title mb-3">14시 이후</p>
            <ul class="list-unstyled text">
              <li class="text mb-0">익영업일 10시까지</li>
              <li class="text mb-0">익영업일 15시까지</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeRules',
};
</script>

<style lang="scss" scoped>
.header {
  @include nego-font2(300, 1.4, -1.1px, $secondary);
}
.title {
  @include nego-font(1.125rem, 700, 1, -1.3px, $primary);
}
.text {
  @include nego-font(1.125rem, 300, 1.33, -1.3px);
}
</style>

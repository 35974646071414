<template>
  <div class="invest-placements">
    <NegoLinks group="group4"/>
    <div class="container my-3">
      <template v-if="user.clientKey">
        <p>당사가 단독/공동으로 운용 중인<br class="d-md-none" /> 신기술금융투자조합에 투자하신 내역입니다.</p>
        <InvestPlacementsList />
        <div class="mt-5">
          <div class="d-flex justify-content-end">
            <NavLinks
              :current="current"
              :links="links"
              @select-link="selectCurrent"
            />
          </div>
          <InvestPlacementsContents
            v-show="current === 'contents'"
          />
          <CardListNews
            v-show="current === 'news'"
            :list="news"
            :enableDelete="false"
            :loading="loadingList"
          />
          <CardListVideos
            v-show="current === 'videos'"
            :list="videos"
            :loading="loadingList"
          />
        </div>
      </template>
      <InvestNoClient v-else />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import NavLinks from '@/components/form/NavLinks.vue';
import InvestPlacementsList from './InvestPlacementsList.vue';
import InvestPlacementsContents from './InvestPlacementsContents.vue';
import CardListNews from '@/components/company/CardListNews.vue';
import CardListVideos from '@/components/company/CardListVideos.vue';
import InvestNoClient from './InvestNoClient.vue';
import {
  SELECT__INVEST_PLACEMENTS_MEDIA,
} from '@/store/mutation-types';

export default {
  name: 'InvestPlacements',
  components: {
    NegoLinks,
    NavLinks,
    InvestPlacementsList,
    InvestPlacementsContents,
    CardListNews,
    CardListVideos,
    InvestNoClient,
  },
  data() {
    return {
      links: [
        { name: 'contents', label: '공지' },
        { name: 'news', label: '뉴스' },
        { name: 'videos', label: '영상' },
      ],
    };
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('investPlacementsMedia', [
      'current',
      'news',
      'videos',
    ]),
    ...mapGetters('investPlacementsMedia', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapMutations('investPlacementsMedia', {
      selectCurrent: SELECT__INVEST_PLACEMENTS_MEDIA,
    }),
    ...mapActions('investPlacementsMedia', [
      'requestList',
    ]),
  },
  mounted() {
    this.requestList();
  },
};
</script>

<style lang="scss" scoped>
.invest-placements {
  min-height: 95vh;
}
</style>


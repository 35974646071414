import {
  REQUEST__PLACEMENTS_ADVISE,
  RECEIVE__PLACEMENTS_ADVISE,
  FAILURE__PLACEMENTS_ADVISE,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
  totalAmount: state => state.list.length ? state.list[0].balanceAmount : 0,
};

const mutations = {
  [REQUEST__PLACEMENTS_ADVISE] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__PLACEMENTS_ADVISE] (state, data) {
    state.list = [...data];
    state.status = 'RECEIVE';
  },
  [FAILURE__PLACEMENTS_ADVISE] (state) {
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST__PLACEMENTS_ADVISE);

    api.get('/public/placements/advise').then(
      response => commit(RECEIVE__PLACEMENTS_ADVISE, response.data),
      error => commit(FAILURE__PLACEMENTS_ADVISE, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="border">
    <div class="d-flex justify-content-between  bg-primary py-2 px-3">
      <h6 class="text-white mb-0">{{ userstock.title }}</h6>
      <small v-if="isQuit" class="text-white bg-danger py-0 px-1">예약구매종료</small>
    </div>
    <div class="row no-gutters p-3">
      <div class="col-3">
        <ImageOrIcons
          :src="userstock.companyLogo"
          icon="building"
        />
      </div>
      <div class="col-9 pl-3">
        <div class="d-flex flex-row justify-content-between">
          <small class="text-light">가격</small>
          <template v-if="!userstock.price">
            <h6 v-if="!userstock.retailNonProBuyable">전문투자자에만 공개</h6>
            <h6 v-else>투자적합고객에만 공개</h6>
          </template>
          <h6 v-else>{{ userstock.price | currency }} <small>원</small></h6>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small class="text-light">최소구매수량</small>
          <h6>{{ userstock.minimumOrderQuantity | currency }} <small>주</small></h6>
        </div>
        <div class="row no-gutters">
          <div class="col pr-2">
            <button
              class="btn btn-outline-primary btn-sm w-100"
              @click="() => $emit('open-company')"
            >기업정보</button>
          </div>
          <div class="col pl-2">
            <button
              v-if="!isQuit"
              class="btn btn-outline-primary btn-sm w-100"
              @click="() => $emit('make-help-intent')"
            >구매예약</button>
            <template v-else>
              <button
                v-if="userstock.openNoticeKey"
                class="btn btn-primary btn-sm w-100"
                @click="() => $emit('delete-notice')"
              >해제신청</button>
              <button
                v-else
                class="btn btn-outline-primary btn-sm w-100"
                @click="() => $emit('regist-notice')"
              >알림신청</button>
            </template>
          </div>
        </div>
      </div>
      <hr class="my-3 w-100" />
      <div class="alert alert-light w-100 mb-0">
        <ul class="mb-0 pl-2 font-md">
          <li>
            <div class="d-flex justify-content-between">
              <span>구매예약 신청기간</span>
              <span>{{ userstock.retailIntentStartDate | date_short_0 }} ~ {{ userstock.retailIntentDl | date_short_0 }}</span>
            </div>
          </li>
          <li>
            <div class="d-flex justify-content-between">
              <span>주식 교부일</span>
              <span>{{ userstock.retailBatchOutDate | date_short_0 }}</span>
            </div>
          </li>
        </ul>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-link btn-sm px-0"
            @click="() => $emit('show-charger')"
          >담당자 정보</button>
          <span class="my-1 mx-2">|</span>
          <button
            class="btn btn-link btn-sm px-0"
            @click="() => $emit('show-corporation')"
          >법인고객 예약구매 안내</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageOrIcons from '@/components/icons/ImageOrIcons';

export default {
  name: 'CardHelpStock',
  components: {
    ImageOrIcons,
  },
  props: {
    userstock: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isQuit() {
      const today = new Date(this.userstock.retailIntentDl);
      today.setHours(15);
      return this.userstock.zero || today.valueOf() < Date.now();
    },
  },
};
</script>

<template>
  <div class="invest-placments-history">
    <InvestLinks />

    <div class="d-flex justify-content-between font-weight-bold mb-3">
      <span>출자잔액</span>
      <span>{{ info.investAmount | currency }} 원</span>
    </div>
    <HistoryTableMy :info="info"/>

    <div class="alert alert-light">
      <template v-if="cert.docuCode">
        <a
          class="btn btn-link btn-sm"
          target="_blank"
          download
          :href="`${FintechDocuApi}/code/${cert.docuCode}`"
        ><IconDownload class="icon-sm"/></a>
        {{ cert.filename }}
      </template>
      <template v-else>
        <p class="mb-0">현재 다운로드 가능한 출자증서가 없습니다.</p>
      </template>
    </div>
    <div class="alert alert-warning">
      <h6>출자증서 안내</h6>
      <p class="font-md mb-0">다운로드 받을 수 있는 출자증서가 없거나 출자증서가 현재 조합원님의 출자잔액과 다를 경우, 자료실 "담당자에게 문의하기"를 통해 출자증서 (재)발행을 요청해 주시기 바랍니다.</p>
    </div>

    <div class="font-weight-bold mt-5 mb-3">
      <span>출자잔액 변동내역</span>
    </div>
    <div class="table-responsive">
      <HistoryTable :list="list"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InvestLinks from '@/components/investPlacements/InvestLinks';
import HistoryTableMy from '@/components/investPlacements/HistoryTableMy';
import HistoryTable from '@/components/investPlacements/HistoryTable';
import IconDownload from '@/components/icons/IconDownload.vue';
import { FintechDocuApi } from '@/utils/constants';

export default {
  name: 'InvestPlacementsHistory',
  components: {
    InvestLinks,
    HistoryTableMy,
    HistoryTable,
    IconDownload,
  },
  data() {
    return {
      FintechDocuApi,
    };
  },
  computed: {
    ...mapState('investPlacementsHistory', [
      'list',
      'cert',
      'info',
    ]),
    ...mapGetters('investPlacementsHistory', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('investPlacementsHistory', [
      'requestList',
    ]),
  },
  mounted() {
    const { dealKey } = this.$route.query;
    this.requestList({ dealKey });
  },
};
</script>

<style lang="scss" scoped>
.invest-placments-history {
  min-height: 100vh;
}
</style>

<template>
  <div>
    <FormHead
      class="border-bottom"
      title="투자 의향 제출"
      :text="`투자대상: ${form['dealName']}`"
    />
    <div class="alert alert-primary mb-3">
      <h6>투자의향 제출 유의사항</h6>
      <ol class="pl-3 mb-0">
        <li>투자대상에 대한 당사의 별도 투자권유를 받지 않고 투자하시기를 원하실 경우에만 투자의향을 제출해 주시기 바랍니다.</li>
        <li>투자의향 제출은 법적구속력이 없이 단순히 투자의사를 표현하는 것이며, 당사로 제출하신 의향에 따라 투자를 하실 수 있음을 보장해 드리지 않습니다.</li>
        <li>투자의향 제출 시, 계약 진행 관련 단순안내를 위해 등록해 주신 연락처로 연락드릴수 있습니다.</li>
      </ol>
    </div>
    <form class="mt-3" @submit.prevent="checkForm">
      <InputCheckbox
        id="agreeForm"
        label="유의사항 확인함"
        :value="form['agreeForm']"
        @input-form="(value) => inputForm({ key: 'agreeForm', value })"
        :error="error['agreeForm']"
      />
      <template v-if="form['dealType'] === 'helpstock'">
        <InputNumberToString
          id="hopeQuantity"
          label="수량 (주)"
          append="주"
          :value="form['hopeQuantity']"
          @input-form="(value) => inputForm({ key: 'hopeQuantity', value })"
          :error="error['hopeQuantity']"
        />
        <InputNumberToString
          id="hopePrice"
          label="가격 (원)"
          append="원"
          :value="form['hopePrice']"
          @input-form="(value) => inputForm({ key: 'hopePrice', value })"
          :error="error['hopePrice']"
        />
        <InputNumberToString
          disabled
          id="totalPrice"
          label="매매금액 (원)"
          append="원"
          :value="form['hopePrice'] * form['hopeQuantity']"
        />
      </template>
      <template v-else>
        <InputNumberToString
          id="hopePrice"
          label="투자의향금액 (최대 10,000)"
          append="백만원"
          :value="form['hopePrice'] / 1000000"
          @input-form="(value) => inputForm({ key: 'hopePrice', value: value * 1000000 })"
          :error="error['hopePrice']"
          :max="10000"
        />
      </template>
      <InputText
        id="name"
        label="이름"
        :value="form['name']"
        @input-form="(value) => inputForm({ key: 'name', value })"
        :error="error['name']"
      />
      <InputText
        id="mobile"
        label="연락처(휴대전화번호)"
        :value="form['mobile']"
        @input-form="(value) => inputForm({ key: 'mobile', value })"
        :error="error['mobile']"
      />
      <InputText
        id="email"
        label="연락처(이메일)"
        :value="form['email']"
        @input-form="(value) => inputForm({ key: 'email', value })"
        :error="error['email']"
      />

      <SubmitButton
        label="등록"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputNumberToString from '@/components/form/InputNumberToString.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import InputText from '@/components/form/InputText.vue';
import { INPUT_FORM__PUBLIC_INTENT } from '@/store/mutation-types';

export default {
  name: 'PublicIntent',
  components: {
    FormHead,
    InputNumberToString,
    InputCheckbox,
    SubmitButton,
    InputText,
  },
  computed: {
    ...mapState('publicIntent', [
      'form',
      'error',
    ]),
    ...mapGetters('publicIntent', [
      'enabledForm',
      'loadingForm',
      'isDealTypePlacement',
    ]),
  },
  methods: {
    ...mapMutations('publicIntent', {
      'inputForm': INPUT_FORM__PUBLIC_INTENT,
    }),
    ...mapActions('publicIntent', [
      'requestData',
      'checkForm',
      'submitForm',
    ]),
  },
  mounted() {
    const { dealType } = this.$route.params;
    const { dealKey } = this.$route.query;
    this.inputForm({ key: 'dealType', value: dealType });
    this.inputForm({ key: 'dealKey', value: dealKey });
    this.requestData();
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border mb-4"},[_c('div',{staticClass:"d-flex justify-content-between bg-primary text-white py-2 px-3"},[_vm._v(_vm._s(_vm.deal.dealName)+" "),(_vm.deal.taxCredit)?_c('small',{staticClass:"bg-danger p-1"},[_vm._v("소득공제")]):_vm._e()]),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-3"},[_c('ImageOrIcons',{attrs:{"src":_vm.deal.companyLogo,"icon":"building"}})],1),_c('div',{staticClass:"col-9 pl-3"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.deal.companyName))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm._f("stock_type")(_vm.deal.stockTypeCode)))]),_c('p',{staticClass:"mb-2"},[_vm._v("설정규모 "+_vm._s(_vm._f("won3")(_vm.deal.expectedAmount))+" 백만원")]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col pr-2"},[_c('router-link',{staticClass:"btn btn-outline-primary btn-sm w-100",attrs:{"to":{
                name: '__COMPANY_VIEW__',
                params: { companyCode: _vm.deal.companyCode },
              }}},[_vm._v("기업정보")])],1),_c('div',{staticClass:"col pl-2"},[_c('router-link',{staticClass:"btn btn-outline-primary btn-sm w-100",attrs:{"to":{
                name: '__FUNDING_LIBRARY__',
                query: { dealKey: _vm.deal.dealKey, dealName: _vm.deal.dealName, beforeReserveTime: _vm.deal.beforeReserveTime }
              }}},[_vm._v("조합자료실")])],1)])])]),_c('hr'),(_vm.deal.status === _vm.Status.ACCEPT)?[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0"},[_vm._v("조합 참여액 (입금하실 금액)")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.deal.offerPrice))+" 원")])])]),_c('div',{staticClass:"bg-gray border rounded p-2 mb-3"},[_c('p',[_vm._v(" 입금안내 "),(_vm.modeTest)?_c('router-link',{staticClass:"btn btn-outline-primary btn-sm ml-3",attrs:{"to":{
              name: 'transactions_pay_in',
              params: { transactionKey: _vm.deal.transactionKey },
            }}},[_vm._v("입금통보")]):_vm._e()],1),_c('ul',{staticClass:"pl-4 mb-0"},[_c('li',[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._v(" 입금계좌: "),_c('span',[_vm._v("농협 "+_vm._s(_vm.deal.virtualAccount)),_c('br'),_vm._v("("+_vm._s(_vm.deal.virtualAccountOwner)+")")])])]),_c('li',[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._v(" 입금시간: "),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.deal.expiration))+" 까지")])])])])]),_c('button',{staticClass:"btn btn-block btn-light",attrs:{"disabled":""}},[_vm._v("조합가입 전자서명(입금 후 진행 가능)")])]:(_vm.deal.status === _vm.Status.PAY_IN)?[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0"},[_vm._v("조합 참여액 (입금완료)")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.deal.offerPrice))+" 원")])])]),_c('div',{staticClass:"bg-gray border rounded p-2 mb-3"},[_c('p',[_vm._v("전자서명(카카오페이) 안내")]),_c('ul',{staticClass:"pl-4 mb-0"},[_vm._m(0),_c('li',[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._v(" 서명 제한시간: "),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.deal.expiration))+" 까지")])])])])]),_c('button',{staticClass:"btn btn-block btn-secondary",on:{"click":function($event){return _vm.$emit('sign-item')}}},[_vm._v(" 조합가입 전자서명 "),(
          _vm.deal.subscriptionStatus === _vm.SubscriptionStatus.SIGN_ASK
          || _vm.deal.subscriptionStatus === _vm.SubscriptionStatus.SIGN_EXPIRE)?_c('span',[_vm._v("(진행중)")]):_vm._e()])]:(_vm.deal.status === _vm.Status.SHARE_OUT || _vm.deal.status === _vm.Status.COMPLETE_OUT)?[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0"},[_vm._v("조합 참여액 (서명완료)")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.deal.offerPrice))+" 원")])])]),_vm._m(1),_c('button',{staticClass:"btn btn-block btn-light",attrs:{"disabled":""}},[_vm._v(_vm._s((_vm.deal.subscriptionStatus === _vm.SubscriptionStatus.SIGN_NOTI || _vm.deal.subscriptionStatus === _vm.SubscriptionStatus.SIGN_ASK || _vm.deal.subscriptionStatus === _vm.SubscriptionStatus.SIGN_EXPIRE) ? '재서명중(별도안내)' : '가입 절차 완료')+" ")])]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{},[_vm._v(" 제한시간까지 전자서명을 완료하시지 않을 경우, 조합가입신청이 취소됩니다(입금액은 반환). ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gray border rounded p-2 mb-3"},[_c('p',[_vm._v("전자서명 완료 안내")]),_c('ul',{staticClass:"pl-4 mb-0"},[_c('li',[_c('div',{},[_vm._v(" 조합 참여에 감사 드립니다. ")])]),_c('li',[_c('div',{},[_vm._v(" 조합설립 절차가 완료되면, MY페이지의 \"투자조합 현황\"에서 조합 운용내역을 확인하실 수 있습니다. ")])])])])
}]

export { render, staticRenderFns }
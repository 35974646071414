import {
  UNCHECK_LOCK__USER_MOBILE,
  INPUT_FORM__USER_MOBILE,
  CHECK_FORM__USER_MOBILE,
  INIT_FORM__USER_MOBILE,
  POST_MOBILE__USER_MOBILE,
  DONE_MOBILE__USER_MOBILE,
  FAIL_MOBILE__USER_MOBILE,
  POST_CODE__USER_MOBILE,
  DONE_CODE__USER_MOBILE,
  FAIL_CODE__USER_MOBILE,
} from '@/store/mutation-types';
import api from '@/api/axios';
import { FormStatus } from '@/utils/formStatus';
import router from '@/router';

const INIT_FORM = {
  value: '',
  status: FormStatus.INIT,
  feed: '',
};

const state = {
  mobile: { ...INIT_FORM },
  code: { ...INIT_FORM },
  form: {
    checkAll: false,
    agreement: false,
    privacy: false,
    marketing: false,
  },
  angel: false,
};

const getters = {
  enabledMobile: (state) => {
    return state.mobile.value.length === 11;
  },
  enabledCode: (state, getters) => {
    return getters.enabledMobile && state.mobile.status === FormStatus.DONE;
  },
  enabledForm: (state, getters) => {
    const { agreement, privacy } = state.form;
    return getters.enabledCode && agreement && privacy;
  },
  loadingForm: (state) => {
    return state.code.status === FormStatus.POST;
  },
};

const mutations = {
  [UNCHECK_LOCK__USER_MOBILE] (state, { angel = false }) {
    state.angel = angel;
  },
  [INPUT_FORM__USER_MOBILE] (state, { key, value }) {
    state[key].value = value;
  },
  [CHECK_FORM__USER_MOBILE] (state, { key, value }) {
    state.form[key] = value;

    if (key === 'checkAll') {
      state.form = {
        checkAll: value,
        agreement: value,
        privacy: value,
        marketing: value,
      };
    }
  },
  [INIT_FORM__USER_MOBILE] (state) {
    state.mobile = { ...INIT_FORM };
    state.code = { ...INIT_FORM };
    state.form = {
      checkAll: false,
      agreement: false,
      privacy: false,
      marketing: false,
    },
    state.angel = false;
  },
  [POST_MOBILE__USER_MOBILE] (state) {
    state.mobile.status = FormStatus.POST;
  },
  [DONE_MOBILE__USER_MOBILE] (state, exist) {
    const existMessage = '이미 등록된 번호입니다. (네고스탁은 휴대전화번호 1개당 1개의 계정만 인정됩니다. 인증을 진행하시면 기존 회원의 휴대전화번호 인증이 취소됩니다.) ';
    const message = '인증번호를 발송하였습니다.';
    state.mobile.feed = exist ? existMessage + message : message;
    state.mobile.status = FormStatus.DONE;
  },
  [FAIL_MOBILE__USER_MOBILE] (state, error) {
    state.mobile.feed = error.mobile;
    state.mobile.status = FormStatus.FAIL;
  },
  [POST_CODE__USER_MOBILE] (state) {
    state.code.status = FormStatus.POST;
  },
  [DONE_CODE__USER_MOBILE] (state) {
    state.code.feed = '인증번호를 확인하였습니다.';
    state.code.status = FormStatus.DONE;

    const { name } = router.history.current;
    if (name === 'user_mobile') {
      router.push({ name: 'user_infomation' });
    } else if (name === '__ANGEL_MOBILE__') {
      router.push({ name: '__ANGEL_KAKAO__' });
    } else if (name === '__TUDAL_MOBILE__') {
      router.push({ name: '__TUDAL_KAKAO__' });
    }
  },
  [FAIL_CODE__USER_MOBILE] (state, error) {
    state.code.feed = error.code;
    state.code.status = FormStatus.FAIL;
  },
};

const actions = {
  checkMobile({ state, commit, dispatch }) {
    commit(POST_MOBILE__USER_MOBILE);

    const mobile = state.mobile.value;
    api.post('/mobile/check', { mobile }).then(
      response => dispatch('postMobile', response.data.exist),
      error => commit(FAIL_MOBILE__USER_MOBILE, error.response.data)
    );
  },
  postMobile({ state, commit }, exist) {
    const mobile = state.mobile.value;
    const angel = state.angel;
    api.post('/mobile/code', { mobile, angel }).then(
      () => commit(DONE_MOBILE__USER_MOBILE, exist),
      error => commit(FAIL_MOBILE__USER_MOBILE, error.response.data)
    );
  },
  postCode({ state, commit }) {
    commit(POST_CODE__USER_MOBILE);

    const mobile = state.mobile.value;
    const code = state.code.value
    const allowMarketing = state.form.marketing;
    api.post('/mobile/verify', { mobile, code, allowMarketing }).then(
      () => {
        commit(DONE_CODE__USER_MOBILE);
        commit(INIT_FORM__USER_MOBILE);
      },
      error => commit(FAIL_CODE__USER_MOBILE, error.response.data)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="form-label-group input-group mb-3">
    <input
      inputmode="numeric"
      type="text"
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      :value="displayValue"
      :disabled="disabled"
      @focus="() => focus_mode = true"
      @blur="() => focus_mode = false"
      @change="handleChange"
    >
    <label :for="id" class="col-form-label">{{ label }}</label>
    <div class="input-group-append">
      <span class="input-group-text">{{ append }}</span>
    </div>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputNumberToString',
  data() {
    return {
      focus_mode: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    append: {
      type: String,
      default: '원',
    },
    max: {
      type: Number,
      required: false,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    displayValue() {
      if (this.focus_mode) {
        return this.value;
      }
      return this.value.toLocaleString('kr-KO');
    },
  },
  methods: {
    handleChange(e) {
      let newValue = parseInt(e.target.value);
      if (this.max !== null || this.max !== undefined) {
        newValue = newValue > this.max ? this.max : newValue;
      }
      if (this.min !== null || this.min !== undefined) {
        newValue = newValue < this.min ? this.min : newValue;
      }
      this.$emit('input-form', newValue);
    },
  },
};
</script>

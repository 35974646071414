<template>
  <div class="position-fixed">
    <IntentsChatRoom
      :roomInfo="roomInfo"
      :room="room"
      :loadingStatus="loadingData"
      :disableChat="true"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IntentsChatRoom from '@/components/intentsChat/IntentsChatRoom.vue';

export default {
  name: 'IntentsMessages',
  components: {
    IntentsChatRoom,
  },
  computed: {
    ...mapState('intentsMessages', [
      'roomInfo',
      'room',
    ]),
    ...mapGetters('intentsMessages', [
      'loadingData',
    ]),
  },
  methods: {
    ...mapActions('intentsMessages', [
      'requestRoom',
    ]),
  },
  mounted() {
    const { intentKey } = this.$route.params;
    this.requestRoom({ intentKey });
  },
};
</script>

<style scoped>
.position-fixed {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1090;
}
</style>


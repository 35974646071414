<template>
  <div>
    <TermsHeader title="네고스탁 서비스 이용약관"/>
    <TermsContents :contents="contents"/>
  </div>
</template>

<script>
import TermsHeader from '@/components/terms/TermsHeader.vue';
import TermsContents from '@/components/terms/TermsContents.vue';
import contents from './agreementA';

export default {
  name: 'TermsAgreementView',
  components: {
    TermsHeader,
    TermsContents,
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<template>
  <div class="row py-3 links-card">
    <div class="col-12 col-8">
      <div class="d-flex flex-column flex-lg-row justify-content-lg-between">
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-left">
          <small class="text-light">거래상대방</small>
          <h5 class="text-right text-lg-left">
            {{ transaction.sellerNickname }} <br class="d-lg-none">
            <small class="text-monospace">{{ transaction.sellerPublicId }}</small>
          </h5>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-left">
          <small class="text-light">매수가격</small>
          <h5 class="">{{ transaction.offerPrice | currency }}</h5>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-left">
          <small class="text-light">수량</small>
          <h5 class="">{{ transaction.offerQuantity | currency }}</h5>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-left">
          <small class="text-light">유효시간</small>
          <h5 class="">{{ transaction.expiration | date }}</h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-4">
      <button
        class="btn btn-primary btn-block" @click="$emit('routing-confirm', transaction.transactionKey)"
      >연동하기 (매수할 주식으로 매도)</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransactionsLinksCard',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.links-card {
  border-top: 2px solid $primary;

  &:last-child {
    border-bottom: 2px solid $primary;
  }
}
</style>

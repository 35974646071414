<template>
  <div class="card rounded-0" >
    <div
      :id="`heading-${item.index}`"
      class="card-header py-2 px-0"
      :class="toggle ? 'bg-gray' : 'bg-white'"
    >
      <h3 class="mb-0">
        <div
          class="btn btn-block text-left"
          type="button"
          data-toggle="collapse"
          :data-target="`#collapse-${item.index}`"
          aria-expanded="true"
          :aria-controls="`collapse-${item.index}`"
          @click="handleToggle"
        >
          <p>{{ item.title }}</p>
          <div class="d-flex justify-content-between">
            <p class="font-sm mb-0">
              <span class="text-primary">{{ item.hasAnswer ? '답변완료' : '답변준비중' }}</span>
              <span class="mx-2">|</span>
              <span class="text-dark">{{ item.writer }}</span>
              <span class="mx-2">|</span>
              <span class="text-dark">{{ item.registTime | date_4 }}</span>
            </p>
            <template v-if="item.hasAnswer">
              <IconChevronUp class="mr-2" v-if="toggle" />
              <IconChevronDown class="mr-2" v-else />
            </template>
          </div>
        </div>
      </h3>
    </div>
    <div
      v-if="item.hasAnswer"
      :id="`collapse-${item.index}`"
      class="collapse"
      :class="{ 'show': toggle }"
      :aria-labelledby="`heading-${item.index}`"
    >
      <div class="card-body border-top p-3" :class="toggle ? 'bg-gray' : 'bg-white'">
        <div class="d-flex">
          <div class="vr"></div>
          <div class="d-flex flex-column">
            <p><span class="badge badge-pill badge-primary mr-2">리서치센터</span>{{ item.answer.title }}</p>
            <div class="d-flex flex-column font-sm">
              <span
                class="text-dark mb-3"
              >상세 내용은 비공개<IconLock class="text-secondary ml-2" /></span>
              <span class="text-dark">{{ item.answer.registTime | date_4 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import IconChevronUp from '@/components/icons/IconChevronUp.vue';
import IconLock from '@/components/icons/IconLock.vue';

export default {
  name: 'ConsultListItem',
  components: {
    IconChevronDown,
    IconChevronUp,
    IconLock,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggle: false,
    };
  },
  methods: {
    handleToggle() {
      if (this.item.hasAnswer) {
        if (this.item.answer.contents) {
          this.$emit('select-item');
        } else {
          this.toggle = !this.toggle;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vr {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  background-color: $primary;
  margin-right: .5rem;
}
</style>

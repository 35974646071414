const SENDER_MAPPING = {
  'S01': {
    buttons: [
      { label: '네고요청 취소', transType: 'cancel_sender', toStatus: 'S07' },
    ],
    message: '미확인',
  },
  'S02': {
    buttons: [
      { label: '네고요청 취소', transType: 'cancel_sender', toStatus: 'S07' },
    ],
    message: '협의 가능',
  },
  'S03': {
    buttons: [
      { label: '네고요청 취소', transType: 'cancel_sender', toStatus: 'S08' },
    ],
    message: '협의 가능',
  },
  'S04': {
    buttons: [
      { label: '거래수락(중개요청)', transType: 'approval', toStatus: 'S13' },
      { label: '거절', transType: 'refuse_sender', toStatus: 'S11' },
    ],
    message: '협의 종료(거래수락 대기)',
  },
  'S05': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '미확인 취소됨',
  },
  'S06': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '미확인 취소됨',
  },
  'S07': {
    buttons: null,
    message: null,
  },
  'S08': {
    buttons: null,
    message: null,
  },
  'S09': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '거래요청 거절됨',
  },
  'S10': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '거래요청 거절됨',
  },
  'S11': {
    buttons: null,
    message: null,
  },
  'S12': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '최종제안 취소함',
  },
  'S13': {
    buttons: null,
    message: null,
  },
};

const RECEIVER_MAPPING = {
  'S01': {
    buttons: null,
    message: null,
  },
  'S02': {
    buttons: [
      { label: '거래요청(중개요청)', transType: 'offers', toStatus: 'S04' },
      { label: '거절', transType: 'refuse_receiver', toStatus: 'S09' },
    ],
    message: '협의 가능',
  },
  'S03': {
    buttons: [
      { label: '거래요청(중개요청)', transType: 'offers', toStatus: 'S04' },
      { label: '거절', transType: 'refuse_receiver', toStatus: 'S10' },
    ],
    message: '협의 가능',
  },
  'S04': {
    buttons: [
      { label: '거래요청(중개요청) 취소', transType: 'cancel_receiver', toStatus: 'S12' },
    ],
    message: '협의 종료(거래요청)',
  },
  'S05': {
    buttons: null,
    message: null,
  },
  'S06': {
    buttons: null,
    message: null,
  },
  'S07': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '요청회원이 취소함',
  },
  'S08': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '요청회원이 취소함',
  },
  'S09': {
    buttons: null,
    message: null,
  },
  'S10': {
    buttons: null,
    message: null,
  },
  'S11': {
    buttons: [
      { label: '삭제', transType: 'delete', toStatus: 'del' },
    ],
    message: '거래요청 거절됨',
  },
  'S12': {
    buttons: null,
    message: null,
  },
  'S13': {
    buttons: null,
    message: null,
  },
};

const HELPER_MAPPING = {
  'S04': {
    buttons: [
      { label: '예약구매신청 취소', transType: 'refuse_sender', toStatus: 'S11' },
    ],
    message: '예약구매 담당자 확인 중(거래요청)',
  },
};

export {
  SENDER_MAPPING,
  RECEIVER_MAPPING,
  HELPER_MAPPING,
};

<template>
  <div>
    <h5 class="my-4 text-center">조합원 총회 의결권행사</h5>

    <table class="table table-sm table-bordered mb-4">
      <tbody>
        <tr>
          <td>조합명</td>
          <td>{{ data.dealName }}</td>
        </tr>
        <tr>
          <td>조합원</td>
          <td>
            성명: {{ data.investorName }}<br>
            생년월일: <samp>{{ data.investorRegistration }}</samp>
          </td>
        </tr>
        <tr>
          <td>납입출자좌수</td>
          <td>{{ data.shareNumber | currency }}좌 (1좌당 {{ data.perShareAmount | currency }}원)</td>
        </tr>
        <tr>
          <td>휴대전화번호</td>
          <td><samp>{{ data.investorMobile | tel }}</samp></td>
        </tr>
      </tbody>
    </table>

    <h6>1. 총회 의안설명서</h6>
    <div class="alert alert-info mb-4 d-flex justify-content-between">
      <FileDownload
        v-if="data.fileName && data.investorMeetingFileKey"
        :fileName="data.fileName"
        fileKind=""
        :fileKey="data.investorMeetingFileKey"
        :fileSize="data.fileSize"
        :filePath="`${NegoStockApi}/meeting/file`"
      />
    </div>
    <h6>2. 전자의결권 행사</h6>
    <div class="p-3 bg-snow mb-3">
      <p>아래 전자서명 버튼을 누르시면 "카카오페이 전자서명" 카카오톡 메시지가 수신됩니다.</p>
      <p class="mb-0">창을 닫으신 후 해당 메시지에서 전자서명을 진행해주세요.</p>
    </div>

    <fieldset
      v-for="(vote, index) in votingData"
      :key="index"
      :disabled="data.voted"
    >
      <legend class="h6">의안 {{ index + 1 }}.</legend>
      <SelectType
        id="voting"
        label="의안 갯수"
        :value="vote"
        @input-form="value => selectForm({ index, value })"
      />
    </fieldset>

    <div
      v-if="showedAlert"
      class="alert alert-warning"
    ><p class="mb-0" v-html="MESSAGES[data.signStatus]"></p></div>
    <template v-if="loadingForm">
      <div class="spinner-border my-3" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </template>
    <template v-else>
      <div v-if="isFormError" class="my-4">
        <div class="alert alert-warning">
            {{ error.subscriptionKey || error.placementInvestorKey || error.votingData || error.mobileForSign || error.kakao }}
        </div>
      </div>
      <div v-if="error['kakao'] || data.signStatus === 'SIGN_EXPIRE'" class="my-4">
        <InputNumber
          label="조합원 본인명의 휴대전화번호"
          id="mobileForSign"
          :value="form['mobileForSign']"
          :error="invalid['mobileForSign']"
          @input-form="(value) => inputForm({ key: 'mobileForSign', value })"
        />
      </div>
    </template>

    <button
      class="btn btn-kakao btn-lg btn-block mb-3"
      @click="checkForm"
      :disabled="loadingForm
        || !enabledForm
        || data.signStatus === 'SIGN_ASK'
        || data.signStatus === 'SIGN_FIN'
        || data.signStatus === 'BROKEN'
      "
    >
      <IconChatFill class="btn-kakao-symbol mr-1 mb-1" />
      <span class="btn-kakao-label"> 카카오페이 전자서명</span>
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FileDownload from '@/components/form/FileDownload.vue';
import SelectType from '@/components/form/SelectType.vue';
import InputNumber from '@/components/form/InputNumber.vue';
import IconChatFill from '@/components/icons/IconChatFill.vue';
import { NegoStockApi } from '@/utils/constants';
import {
  INPUT_FORM__INVEST_MEETING,
  SELECT_FORM__INVEST_MEETING,
} from '@/store/mutation-types';

const MESSAGES = {
  SIGN_ASK: '카카오톡 카카오페이 인증요청을 확인해주세요.',
  SIGN_FIN: '전자서명을 완료하셨습니다.',
  SIGN_EXPIRE: '카카오페이 인증 유효기간이 경과되었습니다. 아래 "카카오페이 전자서명" 버튼을 누르시면 다시 진행할 수 있습니다.<br> 조합원 본인명의의 다른 휴대전화번호로 서명요청을 받으시려면 아래에 전화번호를 입력하신 후 진행해 주십시오.',
  BROKEN: '관리자에게 문의해주세요.',
};

export default {
  name: 'InvestMeeting',
  components: {
    FileDownload,
    SelectType,
    InputNumber,
    IconChatFill,
  },
  data() {
    return {
      MESSAGES,
      NegoStockApi,
    };
  },
  computed: {
    ...mapState('investMeeting', [
      'data',
      'form',
      'votingData',
      'invalid',
      'error',
    ]),
    ...mapGetters('investMeeting', [
      'showedAlert',
      'enabledForm',
      'loadingForm',
    ]),
    isFormError() {
      const { error } = this;
      return (
        error['investorMeetingKey']
        || error['placementInvestorKey']
        || error['votingData']
        || error['mobileForSign']
        || error['kakao']
      );
    },
  },
  methods: {
    ...mapMutations('investMeeting', {
      'inputForm': INPUT_FORM__INVEST_MEETING,
      'selectForm': SELECT_FORM__INVEST_MEETING,
    }),
    ...mapActions('investMeeting', [
      'requestData',
      'checkForm',
      'postForm',
    ]),
  },
  mounted() {
    const { investorMeetingKey } = this.$route.params;
    if (investorMeetingKey) {
      this.requestData({ investorMeetingKey });
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-kakao {
  background-color: #FEE500;
}
.btn-kakao-symbol,
.btn-kakao-label {
  color: #000000;
}
.btn-kakao-label {
  opacity: 0.85;
}
</style>

<template>
  <div class="btn-group dropup">
    <button
      class="btn btn-link btn-sm rounded-0 dropdown-toggle"
      type="button"
      :id="`dropdownMenuButton-${id}`"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleMenu"
      @blur="closeMenu"
    >
      <IconTrash class="icon-sm"/> 삭제
    </button>
    <div
      class="dropdown-menu p-3 text-muted dropdown-menu-right"
      :class="{ 'show': dropped}"
      :aria-labelledby="`dropdownMenuButton-${id}`"
    >
      <p>정말 삭제 하시겠습니까?<br/> 삭제 후 복구는 불가능합니다.</p>
      <div class="dropdown-divider"></div>
      <div>
        <button
          type="button"
          class="btn btn-primary btn-sm mr-2"
          @click="$emit('remove-item')"
        >OK</button>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm ml-2"
          @click="toggleMenu"
        >Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from '@/components/icons/IconTrash.vue';

export default {
  name: 'Dropdowns',
  components: {
    IconTrash,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      dropped: false,
    };
  },
  methods: {
    toggleMenu() {
      this.dropped = !this.dropped;
    },
    closeMenu() {
      setTimeout(() => {
        this.dropped = false;
      }, 200);
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  min-width: 15rem;
}
</style>

<template>
  <footer class="bd-footer bg-snow">
    <div class="container py-5">
      <div>
        <h5 class="font-weight-bold text-secondary">네고스탁 <span class="font-lg font-weight-light text-dark">비상장기업 투자 플랫폼</span></h5>
      </div>
      <div class="font-sm my-3 pl-0">
        <router-link class="text-reset py-3" :to="{name:'terms_agreement'}">이용약관</router-link>
        <span class="mx-2">|</span>
        <router-link class="text-reset py-3" :to="{name:'terms_privacy'}">개인정보 취급방침</router-link>
        <span class="mx-2">|</span>
        <router-link class="text-reset py-3" :to="{name:'terms_trading'}">비상장주식매매중개약관</router-link>
        <span class="mx-2 d-none d-md-inline">|</span><br class="d-md-none">
        <router-link class="text-reset py-3" :to="{name:'guides_faq'}">자주묻는질문</router-link>
      </div>
      <div class="font-sm mb-5">
        <p class="mb-0">코리아에셋투자증권(주)</p>
        <p class="mb-0">서울특별시 영등포구 여의나루로 57, 12층 (신송센터빌딩)</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

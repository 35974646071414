<template>
  <div class="row py-3 mx-0 userstocks-block">
    <div class="col-12">
      <h6 :class="[userstock.red ? 'text-danger' : '']">
        {{ userstock.title }}&nbsp;
        <small class="text-primary">{{ userstock.datetime | date_middle_2 }}</small>&nbsp;
        <small v-if="userstock.availableNego && userstock.limitRate !== 0" class="text">(가격협의가능)</small>
      </h6>
    </div>
    <div class="col-6 col-sm-3 d-flex flex-row flex-sm-column justify-content-between text-sm-right">
      <small class="text-light">가격</small>
      <h6 v-if="!userstock.secret">{{ userstock.price | currency }} <small>원</small></h6>
      <h6 v-if="userstock.secret">비공개</h6>
    </div>
    <div class="col-6 col-sm-3 d-flex flex-row flex-sm-column justify-content-between text-sm-right">
      <small class="text-light">수량</small>
      <h6 v-if="!userstock.secret">{{ userstock.quantity | currency }} <small>주</small></h6>
      <h6 v-if="userstock.secret">비공개</h6>
    </div>
    <div class="col-6 col-sm-3 d-flex flex-row flex-sm-column justify-content-between text-sm-right">
      <template>
        <small class="text-light">공개ID</small>
        <small class="mb-2">{{ userstock.publicId }}</small>
      </template>
    </div>
    <div class="col-6 col-sm-3">
      <template v-if="!userstock.secret">
        <button
          v-if="!userstock.sellAssign"
          class="btn btn-outline-primary btn-block py-0 py-sm-2"
          @click="$emit('request-nego')"
        >네고요청</button>
        <button
          v-if="userstock.sellAssign"
          class="btn btn-outline-primary btn-block py-0 py-sm-2"
          @click="$emit('order-sellassigns')"
        >바로매수</button>
      </template>
      <button v-if="userstock.secret"
        class="btn btn-outline-primary btn-block py-0 py-sm-2"
        @click="$emit('intent-sellassigns')"
      >의향제출</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketsCard',
  props: {
    userstock: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.userstocks-block {
  border-top: 2px solid $primary;

  &:last-child {
    border-bottom: 2px solid $primary;
  }
}
.icon {
  width: 1rem;
  color: #645765;
}
</style>

<template>
  <div class="container my-4">
    <div class="d-flex justify-content-between align-items-center my-4 position-relative">
      <h6 class="mb-0">리포트 <small class="ml-2 text-danger font-italic">new</small></h6>
      <router-link
        class="stretched-link"
        :to="{ name: '__OTC_REPORTS__' }"
      ><IconChevronRight /></router-link>
    </div>
    <LoadingSpinner v-if="loadingList" />
    <div class="row row-cols-4 no-gutters mx-n2">
      <div
        class="col mb-3 px-2"
        v-for="(el, index) in list"
        :key="index"
      >
        <router-link
          :to="{
            name: '__COMPANY_VIEW__',
            params: { companyCode: el.companyCode },
          }"
        >
          <ImageOrTitle
            :src="el.logo"
            object="contain"
            :title="el.name"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import IconChevronRight from '@/components/icons/IconChevronRight.vue';
import LoadingSpinner from '@/components/form/LoadingSpinner.vue';
import ImageOrTitle from '@/components/icons/ImageOrTitle';

export default {
  name: 'HomeReports',
  components: {
    IconChevronRight,
    LoadingSpinner,
    ImageOrTitle,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    loadingList: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.col {
  height: 5rem;
}
</style>

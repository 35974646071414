<template>
  <div class="placements-accounts">
    <InvestLinks />
    <div class="mb-4">
      <div class="d-flex justify-content-between font-weight-bold">
        <span>
          현재 보유현황
          <LoadingSpinner class="icon-sm ml-3" v-if="loadingList" />
        </span>
        <span>{{ totalAmount | currency }} 원</span>
      </div>
      <div class="table-responsive mt-3">
        <PortfoliosTable
          :editable="false"
          :list="list"
        />
        
      </div>
    </div>
    <div class="">
      <div class="d-flex justify-content-between">
        <span class="mb-3 font-weight-bold">
          증권별 변동내역
          <LoadingSpinner class="icon-sm ml-3" v-if="loadingList" />
        </span>
      </div>
      <div class="table-responsive" v-if="histories.length">
        <PortfoliosHistoryTable
          :list="histories"
        />
      </div>
      <div
        v-else-if="histories.length === 0"
        class="alert alert-light"
      >데이터가 없습니다.</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InvestLinks from '@/components/investPlacements/InvestLinks';
import LoadingSpinner from '@/components/form/LoadingSpinner.vue';
import PortfoliosTable from '@/components/placementsAccounts/PortfoliosTable.vue';
import PortfoliosHistoryTable from '@/components/placementsAccounts/PortfoliosHistoryTable.vue';

export default {
  name: 'PlacementsAccounts',
  components: {
    InvestLinks,
    LoadingSpinner,
    PortfoliosTable,
    PortfoliosHistoryTable,
  },
  computed: {
    ...mapState('placementsPortfolios', [
      'list',
      'histories',
    ]),
    ...mapGetters('placementsPortfolios', [
      'loadingList',
      'totalAmount',
    ]),
  },
  methods: {
    ...mapActions('placementsPortfolios', [
      'requestList',
    ]),
  },
  mounted() {
    const { dealKey } = this.$route.query;
    if (!dealKey) {
      this.$router.push({ name: '__PLACEMENTS_FUNDS__' });
    } else {
      this.requestList(dealKey);
    }
  },
};
</script>

<style lang="scss" scoped>
.placements-accounts {
  min-height: 100vh;
}
</style>

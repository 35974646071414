<template>
  <div class="text-left collapse-box py-3">
    <button
      class="btn btn-link text-decoration-none text-left p-0"
      type="button"
      data-toggle="collapse"
      :aria-target="`collapse-${index}`"
      :aria-expanded="expanded"
      :aria-controls="`collapse-${index}`"
      @click="toggleCollapse"
    >
      <span class="text-light">Q <span class="text-secondary">{{ index }}</span>. </span>
      <span :class="[expanded ? 'text-secondary' : 'text-dark']">{{ question }}</span>
    </button>
    <div class="collapse" :class="{ 'show': expanded }" :id="`collapse-${index}`">
      <div class="alert alert-primary m-1">
        <p v-if="answer" class="mb-0">{{ answer }}</p>
        <ul v-if="answers" class="ml-n3">
          <li v-for="(answer, index) in answers" :key="index">{{ answer }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuidesFaqCollapse',
  props: {
    index: {
      type: Number,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      default: undefined,
    },
    answers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleCollapse() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-box {
  border-bottom: 1px dashed $light;
}
</style>

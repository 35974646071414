<template>
  <div>
    <div class="modal fade show d-block">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header text-dark">
            <h5 class="modal-title text-center w-100">{{ title }}</h5>
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-dismiss="modal"
              @click="$emit('close-modal')"
            >Close</button>
          </div>
          <div class="modal-body text-dark">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: 'DealModalLarge',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  created() {
    document.getElementsByTagName('body')[0].classList.add('modal-open');
  },
  destroyed() {
    document.getElementsByTagName('body')[0].classList.remove('modal-open');
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @media (min-width: 992px) {
    padding-right: 1rem;
  }
}
</style>


<template>
  <div class="container py-4 py-md-5">
    <h4 class="header text-center font-weight-bold mb-0">
      회원간 비상장주식 매매 <br class="d-block d-md-none" /> 중개서비스 이용안내
    </h4>
    <div class="row row-cols-1 row-cols-lg-5 d-none d-md-flex">
      <div class="col text-center my-3" v-for="({ title, texts, img }, idx) in cards" :key="idx">
        <img :src="img" :alt="title" class="icon">
        <p class="title mb-1">{{ title }}</p>
        <p class="text mb-0" v-for="(text, idx) in texts" :key="idx">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import img00 from './img/info00.svg';
import img01 from './img/info01.svg';
import img02 from './img/info02.svg';
import img03 from './img/info03.svg';
import img04 from './img/info04.svg';

const cards = [
  {
    title: '저렴한 수수료',
    texts: ['일반 중개 대비 1/10의 수수료','매수수수료 0%,','매도수수료 0.2%'],
    img: img04,
  },
  {
    title: '익명으로 협의',
    texts: ['거래상대방 탐색,','채팅 협의를','네고스탁 공개 ID로 진행'],
    img: img00,
  },
  {
    title: '당사 계좌 불필요',
    texts: ['모든 증권회사의 계좌로','서비스 이용가능','(당사 계좌개설 불필요)'],
    img: img01,
  },
  {
    title: '에스크로 기능',
    texts: ['내 개인정보와 매매대금은','거래 종결 시까지','안전하게 보관'],
    img: img02,
  },
  {
    title: '편리한 계약서류',
    texts: ['PC, 모바일에서','매도(매수)내역서','다운로드 가능'],
    img: img03,
  },
];

export default {
  name: 'HomeCards',
  data() {
    return {
      cards,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 6rem;
  height: 6rem;
}
.header {
  @include nego-font(1.25rem, 400, 1.5, -1.7px);

  @media (min-width: 768px) {
    @include nego-font(1.5rem, 400, 1.5, -1.7px);
  }
}
.title {
  @include nego-font(1.125rem, 700, 1, -1.3px, $primary);
}
.text {
  @include nego-font(1.125rem, 300, 1.33, -1.3px);
}
</style>

<template>
  <div class="home-main">
    <div class="container h-100">
      <div class="d-flex flex-column justify-content-center h-100">
        <h3 class="title mb-0 mt-3">유망한 비상장기업 투자기회를</h3>
        <h2 class="title font-weight-bold mb-3">코리아에셋증권에서 찾으세요.</h2>
        <HomeMainCarousel
          v-if="list.length"
          class="main-carousel"
          :list="list"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HomeMainCarousel from '@/components/homeFirst/HomeMainCarousel.vue';

export default {
  name: 'HomeMain',
  components: {
    HomeMainCarousel,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.home-main {
  height: 33vh;
  background-color: #d5dde7;
}
</style>

<template>
  <div class="bg-snow p-2 p-md-3 mb-3" v-if="files.length">
    <ul class="list-unstyled mb-0">
      <li v-for="(file, index) in files" :key="index" class="text-break">
        <a
          v-if="mode === 'DOWN'"
          :href="`${NegoStockApi}/public/deals/contents/file/${file.dealContentsFileKey}`"
          target="_blank"
          class="btn btn-link align-baseline"
          download
        ><IconDownload class="icon-xs" /></a>
        <button
          v-if="mode === 'FORM'"
          class="btn btn-link align-baseline"
          @click.prevent="$emit('delete-file', file.dealContentsFileKey)"
        ><IconXSquare class="icon-xs" /></button>
        <span>{{ file.name }}</span>
        <small class="ml-3">{{ file.size | file_size }}</small>
      </li>
    </ul>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload.vue';
import IconXSquare from '@/components/icons/IconXSquare.vue';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'ContentsListFiles',
  components: {
    IconDownload,
    IconXSquare,
  },
  data() {
    return {
      NegoStockApi,
    };
  },
  props: {
    mode: {
      type: String,
      default: 'DOWN', // DOWN | FORM
    },
    files: {
      type: Array,
      required: true,
    },
  },
};
</script>


<template>
  <AppModal
    v-if="showModal"
    title="네고스탁 바로매수 안내"
  >
    <slot></slot>
    <template v-slot:footer>
      <button class="btn btn-outline-dark btn-sm" @click="closeModal">닫기</button>
    </template>
  </AppModal>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue';

export default {
  name: 'SellAssignsOrderNoticeModal',
  components: {
    AppModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

import _ from 'underscore';
import {
  SET_FORM__HELP_INTENT_MAKER,
  CHECK_FORM__HELP_INTENT_MAKER,
  INIT_FORM__HELP_INTENT_MAKER,
  INPUT_FORM__HELP_INTENT_MAKER,
  POST_FORM__HELP_INTENT_MAKER,
  DONE_FORM__HELP_INTENT_MAKER,
  FAIL_FORM__HELP_INTENT_MAKER,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  stockInfoUid: '',
  stockName: '',
  dealKey: '',
  price: 0,
  quantity: 0,
  minimumOrderQuantity: 0,
  retailBatchOutDate: 0,
  retailNonProBuyable: false,
  agreeForm: false,
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [SET_FORM__HELP_INTENT_MAKER] (state, data) {
    state.form.stockInfoUid = data.stockInfoUid;
    state.form.stockName = data.title;
    state.form.dealKey = data.dealKey;
    state.form.price = data.price;
    state.form.quantity = 0;
    state.form.minimumOrderQuantity = data.minimumOrderQuantity;
    state.form.retailBatchOutDate = data.retailBatchOutDate;
    state.form.retailNonProBuyable = data.retailNonProBuyable;
  },
  [CHECK_FORM__HELP_INTENT_MAKER] (state) {
    const { quantity, minimumOrderQuantity, agreeForm } = state.form;
    const error = {};

    if (!quantity) {
      error.quantity = '수량을 입력해 주세요.';
    }
    if (quantity < minimumOrderQuantity) {
      error.quantity = '매도자가 설정한 최소 거래수량보다 작습니다.';
    }
    if (!agreeForm) {
      error.agreeForm = '유의사항을 확인해야합니다.';
    }

    state.error = { ...error };
  },
  [INIT_FORM__HELP_INTENT_MAKER] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__HELP_INTENT_MAKER] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__HELP_INTENT_MAKER] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__HELP_INTENT_MAKER] (state) {
    state.status = 'DONE';

    router.replace({ name: '__HELP_INTENT__' });
  },
  [FAIL_FORM__HELP_INTENT_MAKER] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
     commit(CHECK_FORM__HELP_INTENT_MAKER);

     if (!getters.invalidForm) {
       dispatch('submitForm');
     }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__HELP_INTENT_MAKER);

    const { dealKey, price, quantity } = state.form;
    api.post('/helpintents', { dealKey, price, quantity }).then(
      reponse => commit(DONE_FORM__HELP_INTENT_MAKER, reponse.data),
      error => commit(FAIL_FORM__HELP_INTENT_MAKER, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

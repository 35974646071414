<template>
  <div class="my-4">
    <h4 class="title">{{ title }}</h4>
    <p class="text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'FormHead',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-title;
}
.text {
  @include nego-text;
}
</style>

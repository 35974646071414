<template>
  <div class="container my-5">
    <div class="border p-3">
      <h6 class="title">네고요청 하는 방법</h6>
      <ul class="text pl-3">
        <li>매물검색을 통해 검색된 리스트에서 선택하여 네고요청 발송할 수 있습니다. </li>
        <li>지인거래는 이미 알고 있는 거래상대방과 협의된 거래를 네고스탁을 통해 투명하고 안전하게 거래할 목적으로 네고요청을 발송하는 것입니다.</li>
      </ul>
      <h6 class="title">네고요청 이후 거래 체결 과정</h6>
      <ul class="text pl-3">
        <li>네고요청을 받은 회원이 거래조건, 결제시간을 정하여 거래 요청합니다.<br>(거래요청 시, 네고 받은 거래조건 수정 가능)</li>
        <li>거래요청을 받은 회원 거래요청 수락하면 거래가 체결됩니다.<br>(거래요청에 대해서는 거래조건 수정, 추가 협의 불가)</li>
      </ul>
      <picture class="d-lg-none mb-4">
        <source srcset="./img/intents_xs.webp" type="image/webp">
        <img src="./img/intents_xs.png" class="img-fluid" alt="매매진행 과정 안내 이미지: 모바일">
      </picture>
      <picture class="d-none d-lg-block mb-4">
        <source srcset="./img/intents_lg.webp" type="image/webp">
        <img src="./img/intents_lg.png" class="img-fluid" alt="매매진행 과정 안내 이미지: 웹">
      </picture>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntentsNotice',
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, -1.3px);
}
</style>

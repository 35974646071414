import _ from 'underscore';
import {
  REQUEST_DATA__BUY_SIGN,
  RECEIVE_DATA__BUY_SIGN,
  FAILURE_DATA__BUY_SIGN,
  INPUT_FORM__BUY_SIGN,
  CHECK_FORM__BUY_SIGN,
  POST_FORM__BUY_SIGN,
  DONE_FORM__BUY_SIGN,
  FAIL_FORM__BUY_SIGN,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  form: {
    A: false,
    B: false,
    C: false,
    mobileForSign: '',
  },
  data: {
    helperStockTxKey: '',
    stockName: '',
    stockInfoUid: '',
    nameInfo: '',
    birthInfo: '',
    offerQuantity: 0,
    offerPrice: 0,
    status: '', // COMMIT | SIGN_NOTI | SIGN_ASK | SIGN_FIN | SIGN_EXPIRE | BROKEN
    salesPersonName: '',
    salesPersonDuty: '',
    salesPersonContact: '',
    suggested: false,
    score: undefined,
  },
  valid: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
  showedAlert: state => state.data.status === 'SIGN_ASK'
    || state.data.status === 'SIGN_FIN'
    || state.data.status === 'SIGN_EXPIRE'
    || state.data.status === 'BROKEN',
  enabledForm: state => state.data.status === 'COMMIT'
    || state.data.status === 'SIGN_NOTI'
    || state.data.status === 'SIGN_EXPIRE',
  loadingForm: state => state.status === 'REQUEST' || state.status === 'POST',
  invalidForm: state => _.keys(state.valid).length > 0,
};

const mutations = {
  [INPUT_FORM__BUY_SIGN] (state, { key, value }) {
    state.form[key] = value;
  },
  [CHECK_FORM__BUY_SIGN] (state) {
    const { status, score } = state.data;
    const { A, B, C, mobileForSign: mobile, } = state.form;
    const { kakao, mobileForSign } = state.error;
    const valid = {};

    if (!A) {
      valid.A = '동의를 확인해주세요.';
    }
    if (!B) {
      valid.B = '동의를 확인해주세요.';
    }
    if (score > 0 && score < 81) {
      if (!C) {
        valid.C = '동의를 확인해주세요.';
      }
    }
    if (status === 'SIGN_EXPIRE' || kakao || mobileForSign) {
      if (!mobile) {
        valid.mobileForSign = '전화번호를 확인해주세요.';
      }
      if (mobile.startsWith('010') && mobile.length !== 11) {
        valid.mobileForSign = '전화번호를 확인해주세요..';
      } else if (!mobile.startsWith('010') && mobile.length !== 10) {
        valid.mobileForSign = '전화번호를 확인해주세요...';
      }
    }

    state.valid = { ...valid };
  },
  [REQUEST_DATA__BUY_SIGN] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_DATA__BUY_SIGN] (state, data) {
    state.data = { ...data };
    state.status = 'RECEIVE';
  },
  [FAILURE_DATA__BUY_SIGN] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
  [POST_FORM__BUY_SIGN] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__BUY_SIGN] (state) {
    state.status = 'DONE';
    location.href = 'kakaotalk://inappbrowser/close';
  },
  [FAIL_FORM__BUY_SIGN] (state, error) {
    state.status = 'FAIL';
    state.error = { ...error };
  },
};

const actions = {
  requestData({ commit }, { helperStockTxKey }) {
    commit(REQUEST_DATA__BUY_SIGN);

    api.get(`/public/helperstock/${helperStockTxKey}`).then(
      reponse => commit(RECEIVE_DATA__BUY_SIGN, reponse.data),
      error => commit(FAILURE_DATA__BUY_SIGN, error.response.data),
    );
  },
  postForm({ commit, state }) {
    commit(POST_FORM__BUY_SIGN);

    const { helperStockTxKey, status } = state.data;
    const { mobileForSign: mobile } = state.form;
    const { kakao, mobileForSign } = state.error;

    const data = { helperStockTxKey };
    if (status === 'SIGN_EXPIRE' || kakao || mobileForSign) {
      _.extend(data, { mobileForSign: mobile });
    }

    api.post('/public/helperstock/signatures', data).then(
      reponse => commit(DONE_FORM__BUY_SIGN, reponse.data),
      error => setTimeout(() => {
        commit(FAIL_FORM__BUY_SIGN, error.response.data);
      }, 1000)
    );
  },
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__BUY_SIGN);

    if (!getters.invalidForm) {
      dispatch('postForm');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="app-loading d-flex justify-content-center align-items-center">
    <div>
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoading',
};
</script>

<style scoped>
.app-loading {
  height: calc(100vh - 56px);
}
</style>

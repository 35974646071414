<template>
  <div class="auth-kakao">
    <div class="mt-4 d-flex justify-content-center">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span class="ml-3">처리 중 입니다.</span>
    </div>
    <div>
      <p class="pt-4 text-center">이전 페이지로 이동할 경우, 재 로그인이 필요합니다. <br>로그인 정보 유지를 위해 홈으로 이동해 주세요.</p>
      <button
        class="btn btn-secondary btn-block"
        @click="linkHome"
      >홈으로 이동</button>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { NegoStockApi, NegoStockWeb } from '@/utils/constants';

export default {
  name: 'AuthKakaoView',
  methods: {
    linkHome() {
      window.location = NegoStockWeb;
    },
  },
  mounted() {
    const { query } = this.$route;
    window.location = `${NegoStockApi}/login/oauth2/code/kakao?${qs.stringify({ ...query })}`;
  },
};
</script>

<style scoped>
.auth-kakao {
  height: 95vh;
}
</style>

import api from '@/api/axios';
import {
  FILTER__CONSULT_LIST,
  REQUEST__CONSULT_LIST,
  RECEIVE__CONSULT_LIST,
  FAILURE__CONSULT_LIST,
} from '@/store/mutation-types';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
  filter: 'NONE', // 'NONE' || 'MINE'
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
  filteredList: state => state.list.filter(item => !(state.filter === 'MINE' && !item.contents)),
};

const mutations = {
  [FILTER__CONSULT_LIST] (state, filter) {
    state.filter = filter;
  },
  [REQUEST__CONSULT_LIST] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__CONSULT_LIST] (state, data) {
    state.status = 'RECEIVE';
    const list = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.depth === 0) {
        const nextItem = data[i+1];
        if (nextItem && nextItem.depth === 1 && item.groupKey === nextItem.groupKey) {
          item.answer = {
            ...nextItem,
            refKey: item.consultKey,
          };
          item.hasAnswer = true;
        }
        list.push(item);
      }
    }
    state.list = list;
  },
  [FAILURE__CONSULT_LIST] (state, error) {
    state.error = error;
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST__CONSULT_LIST);

    api.get('/consult').then(
      response => commit(RECEIVE__CONSULT_LIST, response.data),
      error => commit(FAILURE__CONSULT_LIST, error.response.data),
    );
  },
  requestListPublic({ commit }) {
    commit(REQUEST__CONSULT_LIST);

    api.get('/public/consult').then(
      response => commit(RECEIVE__CONSULT_LIST, response.data),
      error => commit(FAILURE__CONSULT_LIST, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

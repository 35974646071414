import _ from 'underscore';
import {
  CHECK_FORM__INTENTS_MAKER,
  INIT_FORM__INTENTS_MAKER,
  INPUT_FORM__INTENTS_MAKER,
  POST_FORM__INTENTS_MAKER,
  DONE_FORM__INTENTS_MAKER,
  FAIL_FORM__INTENTS_MAKER,
  // REQUEST_REFERS__INTENTS_MAKER,
  // RECEIVE_REFERS__INTENTS_MAKER,
  // FAILURE_REFERS__INTENTS_MAKER,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  stockName: '',
  stockInfoUid: '',
  type: 'BUY', // { 매수: BUY, 매도: SELL }
  quantity: 0, // 수량
  price: 0, // 가격
  listingPrice: 0, // 싸이트 게시 가격
  expiration: 0, // { 12시간, 24시간, 48시간 }
  contact: '', // 상대연락처
  totalPrice: 0, // 가격 * 수량 표시용
  referSite: '', // { 'my': '네고스탁(일반게시)', 'direct': '직접'}
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
  // list: [],
};

const getters = {
  editabled: state => state.form.referSite === 'direct',
  invalidForm: state => _.keys(state.error).length > 0,
  loadingForm: state => state.status === 'POST',
};

const mutations = {
  [CHECK_FORM__INTENTS_MAKER] (state) {
    const { quantity, price, expiration, totalPrice, contact } = state.form;
    const error = {};
  
    if (!quantity) {
      error.quantity = '수량을 입력해 주세요.';
    }
    if (!price) {
      error.price = '가격을 입력해 주세요.';
    }
    if (!expiration) {
      error.expiration = '유효기간을 입력해 주세요.';
    }
    if (totalPrice < 1000) {
      error.totalPrice = '거래금액은 1천원 이상 가능합니다.';
    }
    if (!contact) {
      error.contact = '수신자를 입력해 주세요.';
    }

    state.error = { ...error };
  },
  [INIT_FORM__INTENTS_MAKER] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__INTENTS_MAKER] (state, { key, value }) {
    state.form[key] = value;

    if (key === 'quantity' || key === 'price') {
      state.form['totalPrice'] = state.form['quantity'] * state.form['price'];
    }
  },
  [POST_FORM__INTENTS_MAKER] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__INTENTS_MAKER] (state, { intentKey }) {
    if (intentKey) {
      state.form = { ...INIT_FORM };
      state.error = {};
      state.status = 'DONE';

      router.replace({ name: 'intents' });
    }
  },
  [FAIL_FORM__INTENTS_MAKER] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
  // [REQUEST_REFERS__INTENTS_MAKER] (state) {
  //   state.status = 'REQUEST;
  // },
  // [RECEIVE_REFERS__INTENTS_MAKER] (state, data) {
  //   state.list = [...data];
  //   state.status = 'RECEIVE';
  // },
  // [FAILURE_REFERS__INTENTS_MAKER] (state) {
  //   state.status = 'FAILURE';
  // },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__INTENTS_MAKER);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__INTENTS_MAKER);

    const {
      stockInfoUid, type, quantity, price, listingPrice, expiration, contact, referSite,
    } = state.form;
    api.post('/intents', { stockInfoUid, type, quantity, price, listingPrice, expiration, contact, referSite }).then(
      response => commit(DONE_FORM__INTENTS_MAKER, response.data),
      error => commit(FAIL_FORM__INTENTS_MAKER, error.response.data),
    );
  },
  // requestRefers({ state, commit }) {
  //   const { list } = state;
  //   if (list.length) return;

  //   commit(REQUEST_REFERS__INTENTS_MAKER);
  //   api.get('/public/refers').then(
  //     response => commit(RECEIVE_REFERS__INTENTS_MAKER, response.data),
  //     error => commit(FAILURE_REFERS__INTENTS_MAKER, { error }),
  //   );
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <h4 class="mt-4 text-center">투자성향 분석을 위한 투자자정보 확인</h4>
    <div class="border p-3 my-3">
      <ul class="pl-3 mb-0">
        <li>본 페이지는 「금융소비자 보호에 관한 법률」에 따라 고객의 투자자정보를 파악하여, 그에 적합한 투자권유를 해드리기 위한 기초 자료로 활용됩니다. 고객의 상황에 부합하거나 가장 가까운 항목을 정확히 선택하여 주시기 바랍니다. </li>
        <li>본 페이지는 「금융소비자 보호에 관한 법률」 제18조 및 「금융소비자 보호에 관한 법률 시행령」제12조제1항에 따라 고객이 적정성 원칙 대상상품을 거래하고자 하는 경우에는 반드시 작성하여야 합니다.</li>
      </ul>
    </div>
    <div
      v-for="(question, idx) in questions"
      :key="idx"
      class="mb-4"
    >
      <p>{{ question.qIndex }}. {{ question.text }}</p>
      <SelectButtonGroup
        :question="question"
        @input-form="({ qIndex, aIndex }) => selectForm({ qIndex, aIndex })"
      />
      <p
          v-if="error[question.qIndex]"
          class=" text-danger"
        >{{ error[question.qIndex] }}</p>
      <hr class="my-4">
    </div>
    <form @submit.prevent="checkForm">
      <div>
        <SubmitButton
          label="제출하기"
          :isLoading="false"
          :enabledForm="true"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SelectButtonGroup from '@/components/form/SelectButtonGroup.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import {
  SELECT__INVESTOR_SCORING,
} from '@/store/mutation-types';

export default {
  name: 'InvestorScoring',
  components: {
    SelectButtonGroup,
    SubmitButton,
  },
  computed: {
    ...mapState('investorScoring', [
      'questions',
      'error',
    ]),
  },
  methods: {
    ...mapMutations('investorScoring', {
      selectForm: SELECT__INVESTOR_SCORING,
    }),
    ...mapActions('investorScoring', [
      'checkForm',
    ]),
  },
};
</script>

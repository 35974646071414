<template>
  <div class="deals-library">
    <InvestLinks />
    <DealsCharges />
    <div class="d-flex justify-content-end mb-3">
      <router-link
        class="btn btn-primary btn-sm rounded-0 ml-2"
        :to="{
          name: '__DEALS_QNA__',
          query: $route.query,
        }"
      >담당자에게 문의하기</router-link>
    </div>
    <DealsContentsPublic />
  </div>
</template>

<script>
import InvestLinks from '@/components/placementsAdvise/InvestLinks';
import DealsCharges from '@/views/dealsLibrary/DealsCharges';
import DealsContentsPublic from '@/views/placementsAdvise/DealsContentsPublic';

export default {
  name: 'PlacementsLibrary',
  components: {
    InvestLinks,
    DealsCharges,
    DealsContentsPublic,
  },
};
</script>

<style lang="scss" scoped>
.deals-library {
  min-height: 100vh;
}
</style>

<template>
  <div class="">
    <FormHead
      title="회원가입 동의"
      text="약관에 동의하셔야 합니다."
    />
    <form @submit.prevent="submitForm">
      <InputCheckboxSquare
        label="전체동의"
        id="checkAll"
        :value="form['checkAll']"
        @input-form="(value) => inputForm({ key: 'checkAll', value })"
      />
      <InputCheckbox
        label="이용약관"
        id="agreement"
        to="__INVEST_TERMS__"
        :value="form['agreement']"
        @input-form="(value) => inputForm({ key: 'agreement', value })"
      />
      <InputCheckbox
        label="개인정보이용방침"
        id="privacy"
        to="__INVEST_TERMS__"
        :value="form['privacy']"
        @input-form="(value) => inputForm({ key: 'privacy', value })"
      />
      <InputCheckbox
        label="투자정보마케팅 수신동의"
        requireLabel="선택"
        id="marketing"
        to="__INVEST_TERMS__"
        :value="form['marketing']"
        @input-form="(value) => inputForm({ key: 'marketing', value })"
      />
      <SubmitButton
        label="완료"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import FormHead from '@/components/auth/form/FormHead';
import InputCheckboxSquare from '@/components/auth/form/InputCheckboxSquare.vue';
import InputCheckbox from '@/components/auth/form/InputCheckbox.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';
import { INPUT_FORM__INVEST_CHECK } from '@/store/mutation-types';

export default {
  name: 'InvestCheck',
  components: {
    FormHead,
    InputCheckboxSquare,
    InputCheckbox,
    SubmitButton,
  },
  computed: {
    ...mapState('investCheck', [
      'form',
    ]),
    ...mapGetters('investCheck', [
      'loadingForm',
      'enabledForm',
    ]),
  },
  methods: {
    ...mapMutations('investCheck', {
      'inputForm': INPUT_FORM__INVEST_CHECK,
    }),
    ...mapActions('investCheck', [
      'submitForm',
    ]),
  },
};
</script>

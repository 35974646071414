<template>
  <form @submit.prevent="submitForm">
    <FormHead
      title="로그인 이메일 변경"
      text="로그인 시 사용할 이메일을 변경할 수 있습니다."
    />
    <InputPassword
      label="현재비밀번호"
      id="currentPasswd"
      :value="form['currentPasswd']"
      :error="error['currentPasswd']"
      @input-form="(value) => inputForm({ key: 'currentPasswd', value })"
    />
    <InputEmail
      placeholder="새 이메일"
      id="userEmail"
      :value="form['userEmail']"
      :error="error['userEmail']"
      @input-form="(value) => inputForm({ key: 'userEmail', value })"
    />
    <SubmitButton
      label="수정"
      :loadingStatus="loadingForm"
      :enabledForm="enabledForm"
    />
  </form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__RESET_EMAIL } from '@/store/mutation-types';
import FormHead from '@/components/auth/form/FormHead';
import InputPassword from '@/components/auth/form/InputPassword.vue';
import InputEmail from '@/components/auth/form/InputEmail.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';

export default {
  name: 'ResetEmailView',
  components: {
    FormHead,
    InputPassword,
    InputEmail,
    SubmitButton,
  },
  computed: {
    ...mapState('authResetEmail', [
      'form',
      'error',
    ]),
    ...mapGetters('authResetEmail', [
      'enabledForm',
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('authResetEmail', {
      'inputForm': INPUT_FORM__RESET_EMAIL,
    }),
    ...mapActions('authResetEmail', [
      'submitForm',
    ]),
  },
  mounted() {
  },
};
</script>

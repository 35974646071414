<template>
  <div class="my-3">
    <div class="alert alert-primary mb-3">
      <h5>투자 의향 제출 성공</h5>
      <p class="mb-0">성공하였습니다!</p>
    </div>
    <router-link
      class="btn btn-secondary w-100"
      :to="{ name: 'home' }"
    >홈 이동하기</router-link>
  </div>
</template>

<script>
export default {
  name: 'PublicIntentSuccess',
};
</script>

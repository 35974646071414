<template>
  <nav aria-label="navigation">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{disabled: params.startPage === 1}">
        <button class="page-link border-0" @click.prevent="nevigate(params.startPage - 1, $event)">
          <span aria-hidden="true">&#x0226A; 이전</span>
        </button>
      </li>
      <template v-for="(page, idx) in pageRange">
        <li
          class="page-item"
          :class="{active: page === params.page}"
          :key="idx"
        >
          <button
            class="page-link border-0"
            @click.prevent="nevigate(page, $event)"
          >{{ page }}</button>
        </li>
      </template>
      <li class="page-item" :class="{disabled: params.endPage === params.totalPages}">
        <button class="page-link border-0" @click.prevent="nevigate(params.endPage + 1, $event)">
          <span aria-hidden="true">다음 &#x0226B;</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import _ from 'underscore';

export default {
  name: 'NegoPagination',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pageRange() {
      if (this.params.startPage && this.params.endPage) {
        return _.range(this.params.startPage, this.params.endPage + 1);
      }
      return 0;
    },
  },
  methods: {
    nevigate(page, event) {
      event.target.blur();
      if (page === this.params.page) {
        return;
      }
      this.$emit('select-page', page);
    }
  }
};
</script>

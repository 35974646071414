<template>
  <div class="border mb-4">
    <div class="d-flex align-items-center bg-primary text-white py-2 px-3">
      <span class="flex-grow-1 text-truncate" :title="deal.dealName">{{ deal.dealName }}</span>
      <small v-if="deal.taxCredit" class="flex-shrink-0 bg-danger p-1">소득공제</small>
    </div>
    <div class="p-3">
      <div class="row no-gutters">
        <div class="col-3">
          <ImageOrIcons
            :src="deal.companyLogo"
            icon="building"
          />
        </div>
        <div class="col-9 pl-3">
          <h5 class="mb-0">{{ deal.companyName }}</h5>
          <p class="mb-0">{{ deal.stockTypeCode | stock_type }}</p>
          <p class="mb-2">설정규모 {{ deal.expectedAmount | won3 }} 백만원</p>
          <div class="row no-gutters">
            <div class="col pr-2">
              <router-link
                class="btn btn-outline-primary btn-sm w-100"
                :to="{
                  name: '__COMPANY_VIEW__',
                  params: { companyCode: deal.companyCode },
                }"
              >기업정보</router-link>
            </div>
            <div class="col pl-2">
              <router-link
                class="btn btn-outline-primary btn-sm w-100"
                :to="{
                  name: '__FUNDING_LIBRARY__',
                  query: { dealKey: deal.dealKey, dealName: deal.dealName, beforeReserveTime: deal.beforeReserveTime }
                }"
              >조합자료실</router-link>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="mb-3">
        <div class="d-flex justify-content-between" v-if="deal.beforeReserveTime">
          <p class="mb-0">오픈알림 신청 현황</p><span>{{ deal.stat0 }} 명</span>
        </div>
        <div class="d-flex justify-content-between" v-if="!deal.beforeReserveTime">
          <p class="mb-0">사전예약 현황<span v-if="deal.reserveTime">(진행중)</span></p><span>{{ deal.stat1 }} % 달성</span>
        </div>
        <div class="d-flex justify-content-between" v-if="!deal.beforeReserveTime && !deal.reserveTime">
          <p class="mb-0">조합가입완료 현황</p><span>{{ deal.stat2 }} % 달성</span>
        </div>
      </div>
      <div class="bg-gray border rounded p-2 mb-3">
        <p>모집일정 (사전예고 없이 변경될 수 있음)</p>
        <ul class="pl-4 mb-0">
          <li>
            <div class="d-flex justify-content-between">
              사전예약접수
              <span>{{ deal.reserveOpenDate | date_str_1 }} ~ {{ deal.reserveCloseDate | date_str_1 }}</span>
            </div>
          </li>
          <li>
            <div class="d-flex justify-content-between">
              사전예약자 청약
              <span>{{ deal.subscriptionPreOpenDate | date_str_1 }} 10시</span>
            </div>
          </li>
          <li>
            <div class="d-flex justify-content-between">
              일반 청약
              <span>{{ deal.subscriptionOpenDate | date_str_1 }} ~ {{ deal.subscriptionCloseDate | date_str_1 }}</span>
            </div>
          </li>
        </ul>
      </div>
      <button
        v-if="deal.beforeReserveTime"
        class="btn btn-block"
        :class="deal.registedOpenNotice ? 'btn-light' : 'btn-secondary'"
        :disabled="deal.registedOpenNotice"
        @click="$emit('regist-item', deal)"
      >오픈알림 {{ deal.registedOpenNotice ? '신청 중' : '신청' }}</button>
      <button
        v-if="deal.reserveTime"
        class="btn btn-block btn-secondary"
        @click="$emit('reserve-item', deal)"
      >투자 사전예약 {{ !deal.reserved ? '신청' : '수정' }}</button>
      <button
        v-else-if="deal.subscriptionReadyTime"
        class="btn btn-block btn-light"
        disabled
      >조합가입 신청({{ deal.subscriptionOpenDate | date_str_1 }} 10시 오픈)</button>
      <button
        v-else-if="deal.subscriptionTime"
        class="btn btn-block btn-secondary"
        :class="deal.joined ? 'btn-light' : 'btn-secondary'"
        :disabled="deal.joined"
        @click="$emit('subscript-item', deal)"
      >조합가입 신청</button>
      <template v-else-if="deal.subscriptionClosed">
        <button
          v-if="deal.retailManagingStatus !== 'DONE'"
          class="btn btn-block btn-light"
          disabled
        >조합가입 신청 접수 종료</button>
        <button
          v-else
          class="btn btn-block btn-success"
          disabled
        >조합원 온라인 모집 종료</button>
      </template>
    </div>
  </div>
</template>

<script>
import ImageOrIcons from '@/components/icons/ImageOrIcons';

export default {
  name: 'LPFundingCard',
  components: {
    ImageOrIcons,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
};
</script>

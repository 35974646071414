<template>
  <div class="container">
    <h6 class="p-4">처리 중...</h6>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FintechCommPath, FintechAccountPath, NegoStockApi } from '@/utils/constants';

export default {
  name: 'RedirectView',
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
  },
  mounted() {
    const { nameStatus, corp } = this.user;
    let { path } = this.$route.query;
    const target = path.split('/')[1];
    // let check = 1; // 0: 체그안함, 1: 스마트폰

    if ((target === 'users') && (nameStatus !== 'NONE' && nameStatus !== 'REQUEST')) {
      this.$router.replace({ name: 'user_infomation' });
      return;
    }

    let middlePath = '';

    // "/users/realname" => /{commtest}/auth
    // "/users/corp"     => /{commtest}/auth
    // "/users/file-id"  => /{commtest}/auth
    if (target === 'users') {
      middlePath = FintechCommPath;

      if (corp && nameStatus !== 'REQUEST') {
        path = '/users/corp';
      }
    }

    // "/applied/"               => /{openaccounttest}/auth
    // "/applied/modify/file-id" => /{openaccounttest}/auth
    else if (target === 'applied') {
      middlePath = FintechAccountPath;
    }

    window.location = `${NegoStockApi}/sessions/1/${middlePath}/auth?path=${path}`;
  },
};
</script>

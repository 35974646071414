<template>
  <div>
    <FormHead
      :title="form.stockName"
      :text="form.stockInfoUid"
    />
    <form @submit.prevent="handleSubmit">
      <SelectType
        id="type"
        :disabled="!editabled"
        :value="form['type']"
        :error="error['type']"
        @input-form="(value) => inputForm({ key: 'type', value })"
      />
      <InputQuantity
        id="quantity"
        :value="form['quantity']"
        :error="error['quantity']"
        @input-form="(value) => inputForm({ key: 'quantity', value })"
      />
      <InputPrice
        id="price"
        :value="form['price']"
        :error="error['price']"
        @input-form="(value) => inputForm({ key: 'price', value })"
      />
      <InputTotalDisable
        id="totalPrice"
        :value="form['totalPrice']"
        :error="error['totalPrice']"
      />
      <SelectExpiration
        id="expiration"
        label="네고요청 유효기간"
        :value="form['expiration']"
        :error="error['expiration']"
        @input-form="(value) => inputForm({ key: 'expiration', value })"
      />
      <InputContact
        id="contact"
        :label="!editabled ? '네고요청 수신자 공개ID' : '거래 상대방 공개ID 또는 휴대전화번호'"
        :disabled="!editabled"
        :value="form['contact']"
        :error="error['contact']"
        @input-form="(value) => inputForm({ key: 'contact', value })"
      />
      <SubmitButton
        :label="!editabled ? '매매협의(네고) 요청' : '매매조건 발송'"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <div
      v-if="error.stockInfoUid || error.result === 'FORBIDDEN'"
      class="alert alert-danger"
      role="alert"
    >{{ error.stockInfoUid || '휴대전화인증이 필요합니다.' }}</div>
    <div
      class="alert alert-primary"
      v-if="error.creatorIntentDailyCount"
    >{{ error.creatorIntentDailyCount }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import SelectType from '@/components/intents/form/SelectType.vue';
import InputQuantity from '@/components/intents/form/InputQuantity.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import InputTotalDisable from '@/components/intents/form/InputTotalDisable.vue';
import SelectExpiration from '@/components/intents/form/SelectExpiration.vue';
import InputContact from '@/components/intents/form/InputContact.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import {
  INIT_FORM__INTENTS_MAKER,
  INPUT_FORM__INTENTS_MAKER,
} from '@/store/mutation-types';

export default {
  name: 'IntentsMakerView',
  components: {
    FormHead,
    SelectType,
    InputQuantity,
    InputPrice,
    InputTotalDisable,
    SelectExpiration,
    InputContact,
    SubmitButton,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('intentsMaker', [
      'form',
      'error',
    ]),
    ...mapGetters('intentsMaker', [
      'editabled',
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('intentsMaker', {
      'initForm': INIT_FORM__INTENTS_MAKER,
      'inputForm': INPUT_FORM__INTENTS_MAKER,
    }),
    ...mapActions('intentsMaker', [
      'checkForm',
      'submitForm',
    ]),
    handleSubmit() {
      if (this.user.userKey) {
        this.checkForm();
      } else {
        const { path, query } = this.$route;
        this.$router.push({
          name: 'sign_in',
          query: {
            ...query,
            redirect_path: path,
          },
        });
      }
    },
  },
  mounted() {
    const { stockInfoUid, stockName, referSite, type, contact, quantity, price } = this.$route.query;
    if (stockInfoUid && stockName) {
      this.inputForm({ key: 'stockInfoUid', value: stockInfoUid });
      this.inputForm({ key: 'stockName', value: stockName });
      this.inputForm({ key: 'referSite', value: referSite || 'direct' });
    } else {
      this.$router.push({ name: 'intents_search' });
      return;
    }
    if (type && contact && quantity && price) {
      this.inputForm({ key: 'type', value: type });
      this.inputForm({ key: 'contact', value: contact });
      this.inputForm({ key: 'quantity', value: parseInt(quantity, 10) });
      this.inputForm({ key: 'price', value: parseInt(price, 10) });
      this.inputForm({ key: 'listingPrice', value: parseInt(price, 10) });
    }
  },
  destroyed() {
    this.initForm();
  },
};
</script>

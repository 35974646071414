<template>
  <div>
    <NegoLinks group="groupAngel"/>
    <p class="text-left text-lg-center">
      코리아에셋투자증권이 <br class="d-lg-none">매매를 안전하게 처리해 드립니다.
    </p>
    <div class="row" v-if="loadingList && list.length === 0">
      <div class="col-md-6 col-xl-4 mb-4" v-for="(empth, idx) in 6" :key="idx">
        <NegoEmptyCard/>
      </div>
    </div>
    <template v-else>
      <div class="row" v-if="list.length === 0">
        <div class="col-12">
          <div class="alert alert-primary text-center" role="alert">매매진행 중인 건이 없습니다.</div>
        </div>
      </div>
      <template v-else>
        <div class="row justify-content-center" v-if="list && list.length">
          <div class="col-md-6 col-xl-4 mb-4" v-for="(transaction, idx) in list" :key="idx">
            <TransactionsProgressCard :transaction="transaction"/>
          </div>
        </div>
        <NegoPagination
          v-if="params"
          :params="params"
          @select-page="routePage"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/angelLeague/NegoLinks.vue';
import NegoEmptyCard from '@/components/nego/NegoEmptyCard.vue';
import NegoPagination from '@/components/nego/NegoPagination.vue';
import TransactionsProgressCard from '@/components/angelLeague/TransactionsProgressCard.vue';

export default {
  name: 'AngelTransactionsProgress',
  components: {
    NegoLinks,
    NegoEmptyCard,
    NegoPagination,
    TransactionsProgressCard,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('angelTransactionsProgress', [
      'list',
      'params',
      'error',
    ]),
    ...mapGetters('angelTransactionsProgress', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('angelTransactionsProgress', [
      'requestList',
    ]),
    routePage(page) {
      this.$router.push({ name: '__ANGEL_TRANSACTIONS_PROGRESS__', query: { page } });
      this.requestList({ page });
    },
  },
  mounted() {
    if (this.user && !this.user.preUser) {
      const page = Number.parseInt(this.$route.query.page) || 1;
      this.requestList({ page });
    }
  },
};
</script>

<template>
  <div class="form-label-group mb-3">
    <input
      type="number"
      class="form-control"
      :id="id"
      :class="{
        'is-valid': status === FormStatus.DONE,
        'is-invalid': status === FormStatus.FAIL,
      }"
      :placeholder="inputLabel"
      :value="value"
      @input="$emit('input-form', $event.target.value)"
      :disabled="!enabled"
    >
    <label :for="id">{{ inputLabel }}</label>
    <small
      v-if="status === FormStatus.DONE || status === FormStatus.FAIL"
      :class="{
        'valid-feedback': status === FormStatus.DONE,
        'invalid-feedback': status === FormStatus.FAIL,
      }"
    >{{ feedback }}</small>
  </div>
</template>

<script>
import { FormStatus } from '@/utils/formStatus';

export default {
  name: 'InputGroup',
  data() {
    return {
      FormStatus,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    status: {
      type: String, // 'INIT' | 'POST' | 'DONE' | 'FAIL'
      default: null,
    },
    feedback: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between bg-secondary text-white px-3">
      <div>
        <h6 class="mb-0">{{ transaction.stockName }}</h6>
        <p class="mb-0 stock-code">{{ transaction.stockCode }}</p>
      </div>
      <h6>매수</h6>
    </div>
    <div class="card-body price p-3">
      <div class="d-flex justify-content-between">
        <h6 class="mb-1">가격</h6>
        <h6 class="mb-1">{{ transaction.offerPrice | currency }} <small>원</small></h6>
      </div>
      <div class="d-flex justify-content-between">
        <div class="h6 mb-1">수량</div>
        <h6 class="mb-1">{{ transaction.offerQuantity | currency }} <small>주</small></h6>
      </div>
      <div class="d-flex justify-content-between border-top pt-2">
        <div class="mb-0 h6">
          대금
          <span class="badge badge-pill badge-primary font-weight-lighter">{{ payMessage }}</span>
          <router-link
            v-if="enableDeposit && modeTest"
            class="btn btn-outline-primary btn-sm ml-3"
            :to="{
              name: 'transactions_pay_in',
              params: { transactionKey: transaction.transactionKey }
            }"
          >입금통보</router-link>
        </div>
        <h6>{{ transaction.offerPrice * transaction.offerQuantity | currency }} <small>원</small></h6>
      </div>

      <ul class="my-3 description pl-3">
        <li
          v-if="transaction.stockCode.length === 6 || status === 'BUYER__ACCEPT'"
          v-html="message"></li>
        <li
          v-if="transaction.sign_status === 'SIGN_FIN'"
        >명의개서 진행에 필요한 서류를 안내에 따라 제출해 주세요.</li>
        <li
          v-else
        >매수약정 전자서명을 진행해 주세요.</li>
      </ul>

      <p
        v-if="transaction.stockCode.length === 6"
        class="expiration mt-1 mb-2 text-right"
      >주식결제일: {{ transaction.expiration | date_short }} 오후 3시 예정</p>
      <p
        v-else
        class="expiration mt-1 mb-2 text-right"
      >주식결제: 명의개서 방식</p>
      <div
        v-if="showBankInfo"
        class="alert alert-primary text-center p-2 mb-3"
      >
        <h6 class="mb-1">{{ transaction.virtualAccount ? '농협은행' : '' }}</h6>
        <h6 class="mb-1">{{ transaction.virtualAccount }}</h6>
        <h6 class="mb-0">(계좌주: {{ transaction.virtualAccountOwner }})</h6>
      </div>

      <router-link
        class="btn btn-kakao btn-block"
        :to="{
          name: '__BUY_SIGN__',
          params: { helperStockTxKey: transaction.helperStockTxKey }
        }"
      >
        <IconChatFill class="btn-kakao-symbol mr-1 mb-1" />
        <span class="btn-kakao-label"> 매수약정 전자서명하기
          <template v-if="transaction.signStatus === 'SIGN_FIN'">(완료)</template>
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { STATUS, PAY_STATUS, SHARE_STATUS } from './transactionsProgressCardHelperStockStatus';
import { AppTarget, NegoStockApi, FintechCommPath } from '@/utils/constants';
import IconChatFill from '@/components/icons/IconChatFill.vue';

export default {
  name: 'TransactionsProgressCardHelperStock',
  components: {
    IconChatFill,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    enableDeposit() {
      const { buyer, payStatus } = this.transaction;
      return buyer && payStatus === 'ACCEPT';
    },
    showBankInfo() {
      const { buyer, status } = this.transaction;
      return buyer && (status === 'ACCEPT' || status === 'SHARE_IN');
    },
    status() {
      const { buyer, status } = this.transaction;
      buyer; // true;
      return `BUYER__${status}`;
    },
    payMessage() {
      return PAY_STATUS[this.transaction.payStatus];
    },
    shareMessage() {
      return SHARE_STATUS[this.transaction.shareStatus];
    },
    message() {
      return STATUS[this.status];
    },
    modeTest() {
      return AppTarget === 'dev' || AppTarget === 'alpha';
    },
  },
  methods: {
    onLink({ sellAssignAvailableKey, offerQuantity, expiration, transactionKey }) {
      const path = [
        `${NegoStockApi}/sessions/0/${FintechCommPath}/auth?path=/transactions/trans`,
        `&sellAssignKey=${sellAssignAvailableKey}`,
        `&offerQuantity=${offerQuantity}`,
        `&expiration=${expiration}`,
        `&transTransactionKey=${transactionKey}`,
      ].join('');
      window.location = path;
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  @include nego-font(1.125rem, 300, 1.25, 0.2px, $dark);
}
.expiration {
  @include nego-font(1rem, 300, 1.25, 0.2px, $dark);
}
.description {
  @include nego-font(1rem, 400, 1.22, 0.2px, $dark);
}
.stock-code {
  font-size: 14px;
  line-height: 1.2;
}

.btn-kakao {
  background-color: #FEE500;
}
.btn-kakao-symbol,
.btn-kakao-label {
  color: #000000;
}
.btn-kakao-label {
  opacity: 0.85;
}
</style>

<template>
  <div class="user-container bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm col-md-8 col-lg-6 col-xl-5 bg-white">
          <router-view></router-view>
          <div
            class="d-flex justify-content-end my-3"
            v-if="!closeButton"
          >
            <router-link
              class="btn btn-light btn-sm text-white px-4"
              :to="{ name: 'user_infomation' }"
            >닫기</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserContainer',
  computed: {
    closeButton() {
      return (this.$route.name === 'user_infomation' || this.$route.name === 'user_mobile');
    },
  },
};
</script>

<style scoped>
.user-container {
  min-height: 100vh;
}
</style>

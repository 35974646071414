import _ from 'underscore';
import {
  CHECK_FORM__LP_FUNDING_SUBSCRIPT,
  INIT_FORM__LP_FUNDING_SUBSCRIPT,
  INPUT_FORM__LP_FUNDING_SUBSCRIPT,
  POST_FORM__LP_FUNDING_SUBSCRIPT,
  DONE_FORM__LP_FUNDING_SUBSCRIPT,
  FAIL_FORM__LP_FUNDING_SUBSCRIPT,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  dealName: '',
  dealKey: '',
  reserveAmount: 0,
  retailMinAmount: 0,
  agreeForm: false,
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [CHECK_FORM__LP_FUNDING_SUBSCRIPT] (state) {
    const { reserveAmount, agreeForm, retailMinAmount } = state.form;
    const error = {};

    if (!reserveAmount) {
      error.reserveAmount = '참여 금액을 입력해 주세요.';
    } else if (reserveAmount < retailMinAmount) {
      error.reserveAmount = '최소참여금액 이상이여야 합니다.';
    }
    if (!agreeForm) {
      error.agreeForm = '유의사항을 확인해야합니다.';
    }

    state.error = { ...error };
  },
  [INIT_FORM__LP_FUNDING_SUBSCRIPT] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__LP_FUNDING_SUBSCRIPT] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__LP_FUNDING_SUBSCRIPT] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__LP_FUNDING_SUBSCRIPT] (state) {
    state.status = 'DONE';

    router.replace({ name: '__LP_FUNDING__' });
  },
  [FAIL_FORM__LP_FUNDING_SUBSCRIPT] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
     commit(CHECK_FORM__LP_FUNDING_SUBSCRIPT);

     if (!getters.invalidForm) {
       dispatch('submitForm');
     }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__LP_FUNDING_SUBSCRIPT);

    const { dealKey, reserveAmount } = state.form;
    api.post('/deals/pro/subscript', { dealKey, reserveAmount }).then(
      reponse => commit(DONE_FORM__LP_FUNDING_SUBSCRIPT, reponse.data),
      error => commit(FAIL_FORM__LP_FUNDING_SUBSCRIPT, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

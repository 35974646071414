<template>
  <div class="otc-report">
    <NegoLinks group="group3"/>
    <div class="container">
      <p>뜨고 있는 개별 비상장기업과 산업에 대한 <br class="d-md-none">차별적인 리포트를 제공해드립니다.</p>
      <div class="mt-4">
        <CardListReports
          :list="list"
          :loading="loadingList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import CardListReports from '@/components/otc/CardListReports.vue';

export default {
  name: 'OTCReports',
  components: {
    NegoLinks,
    CardListReports,
  },
  computed: {
    ...mapState('otcReports', [
      'list',
    ]),
    ...mapGetters('otcReports', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('otcReports', [
      'requestList',
    ]),
  },
  mounted() {
    this.requestList();
  },
};
</script>

<style lang="scss" scoped>
.otc-report {
  min-height: 95vh;
}
</style>

<template>
  <NegoLinksBottom
    :groups="LinkGroups"
  />
</template>

<script>
import NegoLinksBottom from './NegoLinksBottom.vue';
import { LinkGroups } from './negoLinks.js';

export default {
  name: 'NegoLinksHome',
  components: {
    NegoLinksBottom,
  },
  data() {
    return {
      LinkGroups,
    };
  },
};
</script>

<template>
  <div class="border mt-5 p-3">
    <h4>400 Error</h4>
    <p class="mb-0">요청을 실행할 수 없습니다.</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<template>
  <div
    class="spinner-border"
    :class="`text-${color}`"
    role="status"
  ><span class="sr-only">Loading...</span></div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    sm: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'dark',
    },
  },
};
</script>

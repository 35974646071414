import _ from 'underscore';
import {
  FILTER__OTC_HOT,
  SORT__OTC_HOT,
  REQUEST__OTC_HOT,
  RECEIVE__OTC_HOT,
  FAILURE__OTC_HOT,
  INITIAL__OTC_HOT,
  SELECT__OTC_HOT,
  CLEAR__OTC_HOT,
  LIMITED__OTC_HOT,
} from '@/store/mutation-types';
import api from '@/api/axios';
import { replaceCompanyName } from '@/utils/expressions';

const state = {
  cover: 'NONE',
  status: 'INITIAL',
  filter: '',
  list: [],
  error: {},
  item: null,
  sorting: [
    {
      label: '등록일',
      field: 'registTime',
      direct: 'DESC',
      selected: false,
    },
    {
      label: '회사명',
      field: 'companyName',
      direct: 'ASC',
      selected: false,
    },
  ],
  limitedCount: 50,
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
  filteredList: (state) => {
    const { filter, list } = state;
    if (!filter) {
      return list;
    } else {
      return list.filter(i => i.companyName && i.companyName.includes(filter));
    }
  },
  keywordList: (state, getters, rootState, rootGetters) => {
    const { filteredList } = getters;
    const filterUids = rootGetters['keywords/selectedList'];
    if (!filterUids.length) {
      return filteredList;
    } else {
      return filteredList.filter(i => _.some(filterUids, uid => i.keywordUidList && i.keywordUidList.includes(uid)));
    }
  },
  sortedList: (state, getters) => {
    const { keywordList } = getters;
    const { sorting } = state;
    const currentSort = _.find(sorting, el => el.selected);
    if (!currentSort) {
      return keywordList;
    } else {
      const sorted = _.sortBy(keywordList, currentSort.field);
      if (currentSort.direct === 'ASC') {
        return sorted;
      } else {
        return sorted.reverse();
      }
    }
  },
  limitedList: (state, getters) => {
    const { limitedCount } = state;
    const { sortedList } = getters;
    const list = sortedList.slice(0, limitedCount);
    return list;
  },
  disabledLimition: (state, getters) => {
    const { limitedList, sortedList } = getters;
    return limitedList.length === sortedList.length;
  },
};

const mutations = {
  [LIMITED__OTC_HOT] (state) {
    const { limitedCount } = state;
    state.limitedCount = limitedCount + 30;
  },
  [FILTER__OTC_HOT] (state, value) {
    state.filter = value;
  },
  [SORT__OTC_HOT] (state, field) {
    state.sorting = _.map(state.sorting, el => {
      if (el.field === field) {
        return {
          ...el,
          direct: !el.selected ? el.direct : (el.direct === 'DESC' ? 'ASC' : 'DESC'),
          selected: true,
        };
      } else {
        return {
          ...el,
          selected: false,
        };
      }
    });
  },
  [REQUEST__OTC_HOT] (state, cover) {
    state.status = 'REQUEST';
    state.cover = cover;
  },
  [RECEIVE__OTC_HOT] (state, data) {
    state.status = 'RECEIVE';
    state.list = _.map(data, el => {
      const { companyCode, companyName, ceo, logo, registTime, registedNotice, openNoticeKey } = el;
      const keywordList = el.keywords && el.keywords.split(',');
      const keywordUidList = el.keywordUids && el.keywordUids.split(',').map(k => parseInt(k));
      const keyword = [];
      for (const i in keywordList) {
        keyword.push([keywordUidList[i], keywordList[i]]);
      }
      return {
        companyCode,
        companyName: replaceCompanyName(companyName),
        ceo,
        logo,
        registTime,
        registedNotice,
        openNoticeKey,
        keywordList,
        keywordUidList,
        keyword,
      };
    });
  },
  [FAILURE__OTC_HOT] (state, error) {
    state.status = 'FAILURE';
    state.error = { ...error };
  },
  [INITIAL__OTC_HOT] (state) {
    state.status = 'INITIAL';
    state.list = [];
    state.limitedCount = 30;
  },
  [SELECT__OTC_HOT] (state, item) {
    state.item = { ...item };
  },
  [CLEAR__OTC_HOT] (state) {
    state.item = null;
  },
};

const actions = {
  requestListPublic({ commit }) {
    commit(REQUEST__OTC_HOT, 'PUBLIC');

    api.get('/public/cover/hot').then(
      response => commit(RECEIVE__OTC_HOT, response.data),
      error => commit(FAILURE__OTC_HOT, error.response.data),
    );
  },
  requestList({ commit }) {
    commit(REQUEST__OTC_HOT, 'PRIVATE');

    api.get('/cover/hot').then(
      response => commit(RECEIVE__OTC_HOT, response.data),
      error => commit(FAILURE__OTC_HOT, error.response.data),
    );
  },
  registNotice({ dispatch, commit, state }) {
    const { companyCode } = state.item;

    api.post('/cover/notice', { companyCode }).then(
      () => {
        commit(CLEAR__OTC_HOT);
        dispatch('requestList');
      },
      error => commit(FAILURE__OTC_HOT, error.response.data),
    );
  },
  deleteNotice({ dispatch, commit, state }) {
    const { openNoticeKey } = state.item;

    api.delete(`/cover/notice/${openNoticeKey}`).then(
      () => {
        commit(CLEAR__OTC_HOT);
        dispatch('requestList');
      },
      error => commit(FAILURE__OTC_HOT, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="text-white bg-primary my-3 p-3">
    <h5 class="mb-0">이용약관 등</h5>
    <h3 class="font-weight-bold mb-0">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'TermsHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

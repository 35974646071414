const Investor = {
  INVEST: 'INVEST',
  BUY: 'BUY',
  DISTRIBUTION_IN: 'DISTRIBUTION_IN',
  OTHER_IN: 'OTHER_IN',
  INVEST_RETURN: 'INVEST_RETURN',
  SELL: 'SELL',
  DISTRIBUTION: 'DISTRIBUTION',
  OTHER_OUT: 'OTHER_OUT',
};
const INVESTOR__TYPE = [
  { value: Investor.INVEST,          sign:  1, label: '출자납입' },
  { value: Investor.BUY,             sign:  1, label: '양수' },
  { value: Investor.DISTRIBUTION_IN, sign:  1, label: '분배취소' },
  { value: Investor.OTHER_IN,        sign:  1, label: '기타증가' },
  { value: Investor.INVEST_RETURN,   sign: -1, label: '출자납입반환' },
  { value: Investor.SELL,            sign: -1, label: '양도' },
  { value: Investor.DISTRIBUTION,    sign: -1, label: '분배' },
  { value: Investor.OTHER_OUT,       sign: -1, label: '기타감소' },
];

function checkInvestorType(type) {
  const item = INVESTOR__TYPE.find(el => el.value === type);
  return item.sign > 0;
}

export {
  INVESTOR__TYPE,
  checkInvestorType,
};

<template>
  <div class="row no-gutters my-3">
    <div class="col-6 pr-2">
      <router-link
        :to="{ name: 'mystocks' }"
        class="btn btn-block rounded-0"
        :class="$route.name === 'mystocks' ? 'btn-secondary' : 'btn-outline-secondary'"
      >일반매물</router-link>
    </div>
    <div class="col-6 pl-2">
      <router-link
        :to="{ name: 'sell_assigns' }"
        class="btn btn-block rounded-0"
        :class="$route.name === 'sell_assigns' ? 'btn-secondary' : 'btn-outline-secondary'"
      >확인매물</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NegoLinkButtons',
};
</script>

<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between bg-primary text-white px-3">
      <div>
        <h6 class="mb-0">{{ transaction.stockName }}</h6>
        <p class="mb-0 stock-code">{{ transaction.stockCode }}</p>
      </div>
      <h6>{{ transaction.seller ? '매도' : '매수' }}</h6>
    </div>
    <div class="card-body price p-3">
      <div class="d-flex justify-content-between">
        <h6 class="mb-1">가격</h6>
        <h6 class="mb-1">{{ transaction.offerPrice | currency }} <small>원</small></h6>
      </div>
      <div class="d-flex justify-content-between">
        <div class="h6 mb-1">
          수량
          <span class="badge badge-pill badge-primary font-weight-lighter">{{ shareMessage }}</span>
          <router-link
            v-if="enableShareIn && !isLinking"
            class="btn btn-outline-primary btn-sm ml-3 py-0"
            :to="{
              name: 'transactions_share_in',
              params: { transactionKey: transaction.transactionKey }
            }"
          >이체통보</router-link>
        </div>
        <h6 class="mb-1">{{ transaction.offerQuantity | currency }} <small>주</small></h6>
      </div>
      <div class="d-flex justify-content-between border-top pt-2">
        <div class="mb-0 h6">
          대금
          <span class="badge badge-pill badge-primary font-weight-lighter">{{ payMessage }}</span>
          <router-link
            v-if="enableDeposit && !isLinked && modeTest"
            class="btn btn-outline-primary btn-sm ml-3"
            :to="{
              name: 'transactions_pay_in',
              params: { transactionKey: transaction.transactionKey }
            }"
          >입금통보</router-link>
        </div>
        <h6>{{ transaction.offerPrice * transaction.offerQuantity | currency }} <small>원</small></h6>
      </div>
      <ul class="my-3 description pl-3">
        <li v-html="message"></li>
      </ul>
      <p class="expiration mt-1 mb-2 text-right">결제이행: {{ transaction.expiration | date }} 까지</p>
      <div
        v-if="showBankInfo && !isLinked"
        class="alert alert-primary text-center p-2 mb-0"
      >
        <h6 class="mb-1">{{ transaction.virtualAccount ? '농협은행' : '' }}</h6>
        <h6 class="mb-1">{{ transaction.virtualAccount }}</h6>
        <h6 class="mb-0">(계좌주: {{ transaction.virtualAccountOwner }})</h6>
      </div>
      <div
        v-if="showBrokerInfo && !isLinking && !isActorChanged"
        class="alert alert-primary text-center p-2 mb-0"
      >
        <h6 class="mb-1">코리아에셋투자증권</h6>
        <h6 class="mb-1">001-01-707546</h6>
        <h6 class="mb-0">(계좌주: 코리아에셋투자증권)</h6>
      </div>
      <div v-if="enableLink">
        <div class="d-flex justify-content-between mt-3">
          <h6 class="text-secondary font-weight-bold">다른 결제 방법</h6>
          <small>더 알아보기</small>
        </div>
        <div class="row no-gutters">
          <div class="col mr-2">
            <button
              v-if="transaction.sellAssignAvailableKey"
              class="btn btn-outline-secondary btn-block btn-sm"
              @click="onLink(transaction)"
            >계좌 보유 주식으로</button>
          </div>
          <div class="col ml-2">
            <router-link
              class="btn btn-outline-secondary btn-block btn-sm"
              :to="{
                name: 'transactions_links',
                params: { transactionKey: transaction.transactionKey },
                query: { stockInfoUid: transaction.stockInfoUid, stockName: transaction.stockName }
              }"
            >매수 중인 주식으로</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS, PAY_STATUS, SHARE_STATUS } from './transactionsProgressCardStatus';
import { AppTarget, NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'TransactionsProgressCard',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLinked() {
      const { buyer, linked, status } = this.transaction;
      return buyer && linked && (status === 'ACCEPT' || status === 'SHARE_IN');
    },
    isLinking() {
      const { seller, linking, status } = this.transaction;
      return seller && linking && (status === 'ACCEPT' || status === 'PAY_IN');
    },
    isActorChanged() {
      const { seller, linking, status } = this.transaction;
      return seller && linking && (status === 'ACCEPT' || status === 'PAY_IN');
    },
    enableDeposit() {
      const { buyer, payStatus } = this.transaction;
      return buyer && payStatus === 'ACCEPT';
    },
    enableShareIn() {
      const { seller, shareStatus, sellAssignKey } = this.transaction;
      return seller && shareStatus === 'ACCEPT' && !sellAssignKey;
    },
    showBankInfo() {
      const { buyer, status } = this.transaction;
      return buyer && (status === 'ACCEPT' || status === 'SHARE_IN');
    },
    showBrokerInfo() {
      const { seller, status } = this.transaction;
      return seller && (status === 'ACCEPT' || status === 'PAY_IN');
    },
    enableLink() {
      const { linkable, sellAssignKey } = this.transaction;
      return linkable && !sellAssignKey;
    },
    status() {
      const { buyer, status } = this.transaction;
      if (this.isLinked) {
        return `BUYER__${status}__LINKED`;
      }
      if (this.isLinking) {
        return `SELLER__${status}__LINKING`;
      }
      if (this.isActorChanged) {
        return `SELLER__${status}__ACTOR_CHANGED`;
      }
      return `${buyer ? 'BUYER' : 'SELLER' }__${status}`;
    },
    payMessage() {
      return PAY_STATUS[this.transaction.payStatus];
    },
    shareMessage() {
      return SHARE_STATUS[this.transaction.shareStatus];
    },
    message() {
      return STATUS[this.status];
    },
    modeTest() {
      return AppTarget === 'dev' || AppTarget === 'alpha';
    },
  },
  methods: {
    onLink({ sellAssignAvailableKey, offerQuantity, expiration, transactionKey }) {
      const path = [
        `${NegoStockApi}/sessions/0/${FintechCommPath}/auth?path=/transactions/trans`,
        `&sellAssignKey=${sellAssignAvailableKey}`,
        `&offerQuantity=${offerQuantity}`,
        `&expiration=${expiration}`,
        `&transTransactionKey=${transactionKey}`,
      ].join('');
      window.location = path;
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  @include nego-font(1.125rem, 300, 1.25, 0.2px, $dark);
}
.expiration {
  @include nego-font(1rem, 300, 1.25, 0.2px, $dark);
}
.description {
  @include nego-font(1rem, 400, 1.22, 0.2px, $dark);
}
.stock-code {
  font-size: 14px;
  line-height: 1.2;
}
</style>

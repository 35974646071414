<template>
  <div>
    <h4 class="mt-4 text-center">투자권유 희망 여부 등 확인</h4>
    <div class="border p-3 mb-3 bg-gray terms">
      본 내용은 투자자가 투자성 상품을 가입하는 경우 투자 권유 희망 및 투자자정보 제공 의사를 확인하기 위해 마련되었습니다.
      <ul class="pl-3">
        <li>투자자는 아래의 유의사항을 반드시 읽고 필요한 확인 절차를 거쳐주시기 바랍니다.</li>
        <li>동 확인서는 향후 분쟁 또는 소송이 발생하는 경우 귀하의 권리구제에 불리하게 사용될 수 있으므로 신중하게 작성할 필요가 있습니다.</li>
      </ul>
      유의사항
      <ol class="pl-3">
        <li>
          귀하께서 투자권유를 희망하지 않거나 투자자 정보를 제공하지 않는 경우 금융회사는 적합성 원칙* 준수의무를 부담하지 않습니다.
          <br>* 적합성 원칙(금융소비자보호법 제17조): 소비자의 재산상황, 금융상품 취득•처분 경험 등 정보를 파악하고, 소비자에게 부적합한 금융상품의 계약 체결 권유를 금지
        </li>
        <li>
          귀하께서 투자권유를 희망하지 않거나 설명을 요청하지 않는 경우 금융회사는 설명의무*를 부담하지 않습니다.
          <br>* 설명의무(금융소비자보호법 제19조): 금융상품의 중요사항을 소비자가 이해할 수 있도록 설명
        </li>
      </ol>
    </div>

    <h6>투자자 확인사항</h6>
    <SelectOptions
      id="acceptRecommend"
      label="투자권유"
      :value="form['acceptRecommend']"
      :options="USER__ACCEPT_RECOMMEND"
      @input-form="(value) => inputForm({ key: 'acceptRecommend', value: value === 'true' })"
      :error="formError['acceptRecommend']"
    />

    <template v-if="formInit['acceptProvide'] === undefined || formInit['acceptProvide'] === false">
      <SelectOptions
        id="acceptProvide"
        label="투자자정보"
        :value="form['acceptProvide']"
        :options="USER__ACCEPT_PROVIDE"
        @input-form="(value) => inputForm({ key: 'acceptProvide', value: value === 'true' })"
        :error="formError['acceptProvide']"
      />
  
      <div class="alert alert-warning">
        <p>투자권유를 희망하지 않거나 투자자정보를 제공하지 않는 경우에는 고객의 별도 의사가 있기 전까지 회사가 투자권유를 할 수 없습니다.</p>
        <p class="mb-0">투자시 원금 손실이 발생할 수 있으며, 투자 손익에 대한 책임은 모두 고객에게 귀속됩니다.</p>
      </div>
    </template>
    <div class="row mb-3">
      <div class="col">
        <button
          class="btn btn-primary btn-block"
          @click="checkForm"
        >저장하기</button>
      </div>
      <div class="col">
        <router-link
          class="btn btn-primary btn-block"
          :to="{ name: 'user_infomation' }"
        >닫기</router-link>
      </div>
    </div>

    <template v-if="formInit['acceptProvide'] === true">
      <div class="mb-3">
        <h6>
          고객님의 투자성향: {{ userScoreLabel(userScore) }}
          <small v-if="isOverUserTime(userSignFinTime)">(유효기간경과)</small>
          <small v-else>(유효기간: {{ userSignFinTime | date_short_0 }})</small>
        </h6>
        <div class="alert alert-warning">{{ userScoreDesc(userScore) }}</div>
        <router-link
          type="button"
          class="btn btn-primary btn-block"
          :to="{ name: 'INVESTOR_SCORING' }"
        >투자성향 다시 진단하기</router-link>
      </div>
    </template>

    <AppModal v-if="showModalASK" title="전자서명 필요">
      <div>
        <p>제출하신 투자자정보에 대해 카카오페이 알림톡으로 수신한 전자서명 요청을 완료해주세요.</p>
        <router-link
          class="btn btn-primary btn-block"
          :to="{ name: 'user_infomation' }"
        >돌아가기</router-link>
      </div>
    </AppModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import SelectOptions from '@/components/form/SelectOptions.vue';
import AppModal from '@/components/app/AppModal.vue';
import {
  SET__INVESTOR_SUGGESTING,
  INPUT__INVESTOR_SUGGESTING,
} from '@/store/mutation-types';
import {
  USER__ACCEPT_RECOMMEND,
  USER__ACCEPT_PROVIDE,
  USER__ALLOW_MARKETING_GROUP,
} from '@/filters/filtersUser';
import {
  isOverUserTime,
  userScoreLabel,
  userScoreDesc,
} from '@/utils/checkUser';

export default {
  name: 'InvestorSuggesting',
  components: {
    SelectOptions,
    AppModal,
  },
  data() {
    return {
      USER__ACCEPT_RECOMMEND,
      USER__ACCEPT_PROVIDE,
      USER__ALLOW_MARKETING_GROUP,
      isOverUserTime,
      userScoreLabel,
      userScoreDesc,
    };
  },
  computed: {
    ...mapState('investorSuggesting', [
      'formInit',
      'form',
      'formError',
    ]),
    ...mapGetters('investorSuggesting', [
      'loadingForm',
      'showModalASK',
      'userScore',
      'userSignFinTime',
    ]),
  },
  methods: {
    ...mapMutations('investorSuggesting', {
      'setForm': SET__INVESTOR_SUGGESTING,
      'inputForm': INPUT__INVESTOR_SUGGESTING,
    }),
    ...mapActions('investorSuggesting', [
      'checkForm',
      'requstData',
    ]),
  },
  mounted() {
    const { acceptRecommend, acceptProvide } = this.$route.query;

    let recommend;
    let provide;
    if (typeof acceptRecommend === 'undefined') {
      recommend = undefined;
    } else if (typeof acceptRecommend === 'boolean') {
      recommend = acceptRecommend;
    } else if (typeof acceptRecommend === 'string') {
      recommend = acceptRecommend === 'true';
    }
    if (typeof acceptProvide === 'undefined') {
      provide  = undefined;
    } else if (typeof acceptProvide === 'boolean') {
      provide = acceptProvide;
    } else if (typeof acceptProvide === 'string') {
      provide = acceptProvide === 'true';
    }
    this.setForm({ key: 'acceptRecommend', value: recommend });
    this.setForm({ key: 'acceptProvide', value: provide });

    this.requstData();
  },
};
</script>

<style scoped>
.terms {
  height: 16rem;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

import _ from 'underscore';
import {
  INPUT_FORM__FIND_EMAIL,
  INIT_FORM__FIND_EMAIL,
  POST_FORM__FIND_EMAIL,
  DONE_FORM__FIND_EMAIL,
  FAIL_FORM__FIND_EMAIL,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_RESULT = {
  valid: false,
  socialUser: false,
  email: '',
};

const state = {
  form: {
    number: '',
  },
  result: {
    ...INIT_RESULT,
  },
  error: {},
  status: 'INIT',
};


const getters = {
  enabledForm: state => _.every(state.form),
  loadingForm: state => state.status === 'POST',
};

const mutations = {
  [INPUT_FORM__FIND_EMAIL] (state, { key, value }) {
    state.form[key] = value;
  },
  [INIT_FORM__FIND_EMAIL] (state) {
    state.form.number = '';
    state.result = { ...INIT_RESULT };
    state.status = 'INIT';
  },
  [POST_FORM__FIND_EMAIL] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__FIND_EMAIL] (state, data) {
    state.result = { ...data };
    state.status = 'DONE';

    let status;
    let email;
    if (!data.valid) {
      state.error['number'] = '회원정보가 없습니다.';
      return;
    } else {
      if (data.socialUser) {
        status = 'KAKAO';
      } else {
        status = 'EMAIL';
        email = data.email;
      }
    }

    router.push({
      name: 'find_result',
      params: { status },
      query: { email },
    });
  },
  [FAIL_FORM__FIND_EMAIL] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  submitForm({ state, commit }) {
    commit(POST_FORM__FIND_EMAIL);

    const { number } = state.form;
    api.post('/find/bymobile', { number }).then(
      response => commit(DONE_FORM__FIND_EMAIL, response.data),
      error => commit(FAIL_FORM__FIND_EMAIL, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

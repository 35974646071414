<template>
  <div>
    <!--  -->
    <div v-if="!user.clientKey && !user.niceInfo">
      <p>신기술투자조합 투자내역을 확인하시려면,<br> 1회에 한하여 휴대전화 본인인증이 필요합니다.</p>
      <button
        class="btn btn-dark btn-block"
        @click="linkSelfVerify"
      >다음</button>
    </div>

    <!--  -->
    <div v-if="!user.clientKey && user.niceInfo">
      <template v-if="!user.tempClientCount">
        <p>투자한 조합내역을 확인할 수 없습니다.<br> 아래 새로고침을 눌러 다시 시도해보십시오.</p>
        <button
          class="btn btn-dark btn-block"
          @click="connectClient"
        >새로고침</button>
        <div
          v-if="!user.clientKey"
          class="alert alert-warning mt-4"
        >투자한 조합내역을 찾을 수 없습니다. 투자한 조합이 있으시다면 조합 운용 담당자에게 확인해보시기 바랍니다.</div>
      </template>
      <template v-else>
        <p>
          성명, 생년월일, 성별이 모두 동일한 고객이 존재하여
          추가 실명확인이 필요합니다.
          회원정보 페이지에서 실명정보를 등록해 주십시오.
        </p>
        <router-link
          class="btn btn-dark btn-block"
          :to="{name: 'user_infomation'}"
        >회원정보 페이지로 이동</router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'InvestNoClient',
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
  },
  methods: {
    ...mapActions('userInfomation', [
      'connectClient',
    ]),
    linkSelfVerify() {
      window.location = `${NegoStockApi}/sessions/0/${FintechCommPath}/auth?path=/self-verify/myfund`;
    },
  },
  mounted() {
    const { preUser } = this.user;
    if (preUser) {
      this.$router.push({ name:'__INVEST_CHECK__' });
    }
  },
};
</script>

<template>
  <AppModalSimple
    v-if="showModal"
    title="네고스탁"
    content="전용계좌 발급 중에는 거래요청 또는 거래수락을 하실 수 없습니다."
  >
    <div class="d-flex w-100">
      <button class="btn btn-outline-dark flex-grow-1 mr-3" @click="closeModal">확인</button>
    </div>
  </AppModalSimple>
</template>

<script>
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'NegoModalAccount',
  components: {
    AppModalSimple,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

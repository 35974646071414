<template>
  <form @submit.prevent="checkForm">
    <FormHead
      class="text-center"
      title="문의하기"
      :text="`(${form.dealName})`"
    />
    <SelectOptions
      id="to"
      label="담당자 선택"
      :options="optionsEmail"
      :value="form['to']"
      @input-form="(value) => inputForm({ key: 'to', value })"
      :error="error['to']"
    />
    <InputText
      id="subject"
      label="제목"
      :value="form['subject']"
      @input-form="value => inputForm({ key: 'subject', value })"
      :error="error['subject']"
    />
    <InputTextArea
      id="contents"
      label="내용"
      :value="form['contents']"
      @input-form="value => inputForm({ key: 'contents', value })"
      :error="error['contents']"
    />
    <InputFile
      id="file"
      label="파일을 첨부해주세요"
      @input-form="value => inputForm({ key: 'file', value })"
      :error="error['file']"
    />
    <SubmitButton
      label="담당자에게 문의하기"
      :isLoading="loadingForm"
      :enabledForm="enabledForm"
    />
  </form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/form/FormHead.vue';
import InputText from '@/components/form/InputText.vue';
import InputTextArea from '@/components/form/InputTextArea.vue';
import InputFile from '@/components/form/InputFile.vue';
import SelectOptions from '@/components/form/SelectOptions.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { INPUT__DEALS_QNA } from '@/store/mutation-types';

export default {
  name: 'DealsQna',
  components: {
    FormHead,
    InputText,
    InputTextArea,
    InputFile,
    SelectOptions,
    SubmitButton,
  },
  computed: {
    ...mapState('dealsQna', [
      'form',
      'error',
    ]),
    ...mapGetters('dealsQna', [
      'enabledForm',
      'loadingForm',
    ]),
    ...mapGetters('dealsCharges', [
      'optionsEmail',
    ]),
  },
  methods: {
    ...mapMutations('dealsQna', {
      'inputForm': INPUT__DEALS_QNA,
    }),
    ...mapActions('dealsQna', [
      'checkForm',
      'submitForm',
    ]),
  },
  mounted() {
    const { dealKey, dealName } = this.$route.query;
    this.inputForm({ key: 'dealKey', value: dealKey });
    this.inputForm({ key: 'dealName', value: dealName });
  },
};
</script>

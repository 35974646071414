import {
  REQUEST_LIST__INTENTS_RECENT,
  RECEIVE_LIST__INTENTS_RECENT,
  FAILURE_LIST__INTENTS_RECENT,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  list: [],
  params: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
};

const mutations = {
  [REQUEST_LIST__INTENTS_RECENT] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__INTENTS_RECENT] (state, { contents, ...params }) {
    state.list = [...contents];
    state.params = { ...params };
    state.error = {};
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__INTENTS_RECENT] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, { page = 1, scale = 6 }) {
    commit(REQUEST_LIST__INTENTS_RECENT);

    api.get('/intents/recent', { params: { page, scale }}).then(
      response => commit(RECEIVE_LIST__INTENTS_RECENT, response.data),
      error => commit(FAILURE_LIST__INTENTS_RECENT, error),
    );
  },
  blockItem({ dispatch, state, commit }, { intentKey }) {
    commit(REQUEST_LIST__INTENTS_RECENT);

    api.post('/blocks', { intentKey }).then(
      () => dispatch('requestList', { page: state.params.page }),
    ).then(
      () => router.replace({ name: 'intents_recent' }),
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

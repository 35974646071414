import {
  SELECT__INVEST_PLACEMENTS_MEDIA,
  REQUEST__INVEST_PLACEMENTS_MEDIA,
  RECEIVE__INVEST_PLACEMENTS_MEDIA,
  FAILURE__INVEST_PLACEMENTS_MEDIA,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  current: 'contents',
  news: [],
  videos: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [SELECT__INVEST_PLACEMENTS_MEDIA] (state, current) {
    state.current = current;
  },
  [REQUEST__INVEST_PLACEMENTS_MEDIA] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__INVEST_PLACEMENTS_MEDIA] (state, { news, videos }) {
    state.news = [...news];
    state.videos = [...videos];
    state.status = 'RECEIVE';
  },
  [FAILURE__INVEST_PLACEMENTS_MEDIA] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST__INVEST_PLACEMENTS_MEDIA);

    api.get('/invest/placement/media').then(
      response => commit(RECEIVE__INVEST_PLACEMENTS_MEDIA, response.data),
      error => commit(FAILURE__INVEST_PLACEMENTS_MEDIA, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

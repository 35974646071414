<template>
  <div class="border p-3 my-5">
    <p class="title text-center text-dark">개인 전문투자자 안내</p>
    <h6 class="title">개인전문투자자란?</h6>
    <ul class="text pl-3">
      <li>“전문투자자”란, 투자경험과 금융투자상품에 관한 전문성 및 보유자산규모 등에 비추어 투자에 따른 위험 감수능력이 있는 투자자를 의미합니다.</li>
      <li>아래 필수요건과 선택요건 중 1개 요건을 충족하는 개인이면 누구나 전문투자자가 되실 수 있습니다.</li>
    </ul>
    <table class="table table-bordered">
      <tbody>
        <tr class="d-none d-md-table-row text-center">
          <td colspan="2">구분</td>
          <td>조건</td>
        </tr>
        <tr>
          <td class="d-none d-md-table-cell align-middle text-center" colspan="2">필수요건</td>
          <td>
            <p class="d-md-none mb-0">[필수요건]</p>
            <mark class="bg-warning">최근 5년 중 1년 이상 월말 평균잔고 5천만원 이상</mark><br/>
            *잔고인정 금융투자상품 기준 : A등급이하 회사채 및 A2이하 기업어음, 주식, 원금비보장 파생결합증권, 증권형 펀드 및 사모펀드
          </td>
        </tr>
        <tr>
          <td class="d-none d-md-table-cell align-middle" rowspan="3">
            선택요건 <br/>(1~3 중 <br/>1개 선택)
          </td>
          <td class="d-none d-md-table-cell">1. 소득요건</td>
          <td>
            <p class="d-md-none mb-0">[선택요건]</p>
            <span class="d-md-none">1. 소득요건 : </span>
            <mark class="bg-warning">전년도 본인소득 1억원</mark> 또는 부부합산 소득 1.5억원 이상
          </td>
        </tr>
        <tr>
          <td class="d-none d-md-table-cell">2. 전문가요건</td>
          <td>
            <span class="d-md-none">2. 전문가요건 : 회계사, 감정평가사, 변호사 변리사, 세무사, 투자자자산운용사, 금융투자분석사, (국제)재무위험관리사 자격 보유 및 해당분야 1년 이상 종사 경력</span>
            <span class="d-none d-md-inline">
              해당분야 1년 이상 종사 경력 필요<br/>
              (1)회계사, 감정평가사, 변호사 변리사, 세무사<br/>
              (2)투자자자산운용사, 금융투자분석사, (국제)재무위험관리사
            </span>
          </td>
        </tr>
        <tr>
          <td class="d-none d-md-table-cell">3. 자산요건</td>
          <td>
            <span class="d-md-none">3. 자산요건 : </span>
            (부부합산)금융자산 및 부동산의 거주주택을 제외한 순자산 5억원 이상
          </td>
        </tr>
      </tbody>
    </table>
    <h6 class="title">당사 개인전문투자자 등록 방법</h6>
    <ul class="text pl-3">
      <li>
        <p class="mb-0">당사 전문투자자 등록신청은 회원정보 페이지에서 가능하며, 휴대전화 촬영을 통해 전문투자자 확인증을 제출해주시면 당사의 전문투자자로 등록하실 수 있습니다.</p>
        <p class="text-right">
          <router-link
            class="btn btn-secondary btn-sm"
            :to="{
              name: 'user_infomation',
              hash: '#infomation-regist',
            }"
          >회원정보페이지로 가기</router-link>
        </p>
      </li>
      <li>당사는 개인전문투자자 지정요건 심사권한을 보유하고 있지 않습니다. 따라서, 다른 증권사에서 개인전문투자자 지정 심사를 받으신 후 해당 증권사가 발급한 전문투자자 확인증을 제출해주셔야 합니다.</li>
      <li>참고로 금융상품 잔고요건(필수요건)과 소득요건(선택요건)을 충족하고 계시다면, <u>잔고보유 중이신 주요 증권사의 HTS 또는 모바일앱에서 별도의 자료제출 없이 지정심사를 신청하실 수 있습니다.</u></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelpIntentNotice',
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, -1.3px);
}
.table {
  font-size: .85rem;
}
</style>

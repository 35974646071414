import {
  INPUT_FORM__AUTH_REVOKE,
  POST_FORM__AUTH_REVOKE,
  DONE_FORM__AUTH_REVOKE,
  FAIL_FORM__AUTH_REVOKE,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    userEmail: '',
    currentPasswd: '',
  },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.form.status === 'POST',
};

const mutations = {
  [INPUT_FORM__AUTH_REVOKE] (state, { key, value }) {
    state.form[key] = value.trim();
  },
  [POST_FORM__AUTH_REVOKE] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__AUTH_REVOKE] (state) {
    state.form.userEmail = '';
    state.form.currentPasswd = '';
    state.status = 'DONE';

    router.replace({ name: 'home' });
    location.reload();
  },
  [FAIL_FORM__AUTH_REVOKE] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  submitForm({ state, commit }) {
    commit(POST_FORM__AUTH_REVOKE);

    const { userEmail, currentPasswd } = state.form;
    api.post('/users/revoke', { userEmail, currentPasswd }).then(
      response => commit(DONE_FORM__AUTH_REVOKE, response.data),
      error => commit(FAIL_FORM__AUTH_REVOKE, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

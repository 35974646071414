<template>
  <div class="row color-0 mx-lg-0 py-4 mb-4">
    <div class="col-12 col-lg-6">
      <div class="d-flex justify-content-between justify-content-lg-center no-gutters">
        <div class="">
          <img src="./img/event.png" class="img-fluid w-85" />
          <p class="mt-2 mb-0">개인전문투자자 등록 고객님께<br>이벤트 주식 1주를 천원에 드립니다.</p>
        </div>
        <div class="">
          <div class="d-flex flex-column align-items-center justify-content-center color-1 rounded-circle">
            <h3 class="mb-0 color-2 ">1<small>주</small></h3>
            <h3 class="mb-0 color-2 ">천<small>원</small></h3>
          </div>
        </div>
      </div>
      <div class="text-right mt-2 mb-3 mb-lg-0">
        <a
          href="#help-intent-notice"
        ><u><small>개인 전문투자자 안내 보기</small></u></a>
      </div>
    </div>
    <div class="col-12 col-lg-4 offset-lg-1">
      <div class="border border-white rounded">
        <div class="bg-primary- text-white- px-3 py-2">이벤트 중인 주식</div>
        <div class="bg-white- p-3">
          <div class="row no-gutters">
            <div class="col-3">
              <ImageOrIcons
                :src="userstock.companyLogo"
                icon="building"
              />
            </div>
            <div class="col-9 pl-3">
              <h5 class="mb-4 font-weight-bold">{{ userstock.title }}</h5>
              <div class="row no-gutter">
                <div class="col pr-2">
                  <button class="btn btn-outline-primary btn-sm w-100 text-dark"
                    @click="$emit('open-company')"
                  >기업정보</button>
                </div>
                <div class="col pl-2">
                  <button class="btn btn-outline-primary btn-sm w-100 text-dark"
                    @click="$emit('order-event-sellassigns')"
                  >천원구매</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageOrIcons from '@/components/icons/ImageOrIcons';


export default {
  name: 'HelpIntentEvent',
  components: {
    ImageOrIcons,
  },
  props: {
    userstock: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.color-0 {
  background-color: #83e9ee;
}
.color-1 {
  background-color: #484747;
  width: 5rem;
  height: 5rem;
  @media (min-width: 992px) {
    width: 6rem;
    height: 6rem;
  }
}
.color-2 {
  color: #b4ada0;
}
.img-intent {
  height: 4rem;
}
</style>

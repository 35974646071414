<template>
  <div class="out-container bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 bg-white in-container">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AngelLeagueContainer',
};
</script>

<style lang="scss" scoped>
.in-container {
  min-height: 95vh;
}
</style>

import {
  REQUEST__COMPANY_BRIEF,
  RECEIVE__COMPANY_BRIEF,
  FAILURE__COMPANY_BRIEF,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  company: {},
  reports: [],
  videos: [],
  news: [],
  postings: [],
  status: 'INITIAL',
  error: {},
};

const getters = {
  loadingStatus: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__COMPANY_BRIEF] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__COMPANY_BRIEF] (state, data) {
    const { company, reports, videos, news, postings } = data;
    state.status = 'RECEIVE';
    state.company = { ...company };
    state.reports = [ ...reports ];
    state.videos = [ ...videos ];
    state.news = [ ...news ];
    state.postings = [ ...postings ];
  },
  [FAILURE__COMPANY_BRIEF] (state, error) {
    state.status = 'FAILURE';
    state.error = { ...error };
  },
};

const actions = {
  requestData({ commit }, { companyCode }) {
    commit(REQUEST__COMPANY_BRIEF);

    api.get(`/public/companies/brief/${companyCode}`).then(
      response => commit(RECEIVE__COMPANY_BRIEF, response.data),
      error => commit(FAILURE__COMPANY_BRIEF, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="form-row mb-3">
    <p :for="id" class="col-4 col-form-label">{{ label }}</p>
    <div class="input-group col-8">
      <input
        type="text"
        class="form-control"
        readonly
        :id="id"
        :value="value"
        :aria-describedby="`button-${id}`"
      >
      <div class="input-group-append">
        <router-link
          class="btn btn-light"
          :id="`button-${id}`"
          :to="{ name: pathName }"
        >{{ buttonLabel }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputReadonlyButton',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: '수정',
    },
    pathName: {
      type: String,
      required: true,
    },
  },
};
</script>

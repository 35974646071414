<template>
  <div class="form-row mb-3">
    <div class="col">
      <button
        type="button"
        class="btn btn-block rounded-0"
        :disabled="disabled"
        :class="[value === leftValue ? 'btn-secondary' : 'btn-outline-secondary']"
        @click="$emit('input-form', leftValue)"
      >{{ leftLabel }}</button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-block rounded-0"
        :disabled="disabled"
        :class="[value === rightValue ? 'btn-secondary' : 'btn-outline-secondary']"
        @click="$emit('input-form', rightValue)"
      >{{ rightLabel }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectType',
  props: {
    value: {
      type: String,
      required: true,
    },
    leftLabel: {
      type: String,
      default: '찬성',
    },
    leftValue: {
      type: String,
      default: '1',
    },
    rightLabel: {
      type: String,
      default: '반대',
    },
    rightValue: {
      type: String,
      default: '0',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

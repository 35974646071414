<template>
  <div class="mb-4">
    <div class="d-none d-md-block">
      <div class="row no-gutters py-1 px-md-3 border-top border-bottom">
        <div class="col-md-1">#</div>
        <div class="col-md-7">내용</div>
        <div class="col-md-2"></div>
        <div class="col-md-2 text-left text-md-right">작성일</div>
      </div>
    </div>
    <div class="d-block d-md-none border-bottom"></div>
    <div class="row no-gutters py-2 px-md-3 border-bottom" v-for="(el, index) in contents" :key="index">
      <div class="col-md-1 d-none d-md-block ">{{ index + 1 }}</div>
      <div class="col-12 col-md-7"><h6 class="mb-0">{{ el.title }}</h6></div>
      <div class="col-8 col-md-2 order-1 order-md-0 text-right pr-0">
        <template v-if="isStaff">
          <Dropdowns
            :id="index"
            @remove-item="$emit('remove-item', { dealContentsKey: el.dealContentsKey })"
          />
          <button
            class="btn btn-link btn-sm ml-1"
            @click="$emit('update-item', el)"
          ><IconPencil/> 수정</button>
        </template>
      </div>
      <div class="col-12 col-md-2 text-right mb-2"><small>{{ el.registTime | date_short }}</small></div>
      <div class="col-12 col-md-7 offset-md-1"><p class="contents mb-2">{{ el.contents }}</p></div>
      <div class="col-12 col-md-11 offset-md-1">
        <ContentsListFiles
          mode="DOWN"
          :files="el.files"
        />
      </div>
      <div class="col-4 col-md-7 offset-md-1 mb-3" v-if="el.type === 'FEED'">
        <button 
          class="btn btn-outline-primary btn-sm rounded-0"
          @click="$emit('select-item', el.dealContentsKey)"
        >댓글 {{ el.commentCount }}</button>
      </div>
      <div class="col-4 col-md-7 offset-md-1" v-if="el.type === 'FILE'">
        <a
          class="btn btn-link btn-sm mr-3"
          target="_blank"
          :href="`${NegoStockApi}/public/deals/contents/file/${el.dealContentsKey}`"
          download
        ><IconDownload class="icon-sm"/></a>
      </div>
      <div class="col-12 col-md-11 offset-md-1" v-if="el.meeting">
        <button
          v-if="!el.votingStartDate || el.votingStartDate > Date.now()"
          class="btn btn-primary btn-block rounded-0"
          disabled
        >의결권 전자서명 준비중</button>
        <router-link
          v-if="el.signStatus !== 'SIGN_FIN' && el.votingStartDate <= Date.now() && (el.votingEndDate + 86400000) >= Date.now()"
          class="btn btn-secondary btn-block rounded-0"
          :to="{
            name: '__INVEST_MEETING__',
            params: {investorMeetingKey: el.investorMeetingKey },
            query: $route.query,
          }"
        >의결권 행사(전자서명)</router-link>
        <button
          v-if="el.signStatus === 'SIGN_FIN'"
          disabled
          class="btn btn-primary btn-block rounded-0"
        >의결권 행사 완료</button>
        <button
          v-if="el.signStatus !== 'SIGN_FIN' && (el.votingEndDate + 86400000) < Date.now()"
          disabled
          class="btn btn-primary btn-block rounded-0"
        >의결권 행사 기간종료</button>
      </div>

    </div>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload.vue';
import IconPencil from '@/components/icons/IconPencil';
import Dropdowns from '@/components/deals/Dropdowns.vue';
import ContentsListFiles from '@/components/deals/ContentsListFiles.vue';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'ContentsList',
  components: {
    IconDownload,
    IconPencil,
    Dropdowns,
    ContentsListFiles,
  },
  data() {
    return {
      NegoStockApi,
    };
  },
  props: {
    contents: {
      type: Array,
      required: true,
    },
    isStaff: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.contents {
  white-space: pre-line;
}
</style>

<template>
  <div class="container">
    <div class="home-links d-flex justify-content-around align-items-center my-2">
      <router-link
        class="btn btn-link font-weight-bold text-secondary"
        :to="{ name: '__LP_FUNDING__' }"
      >조합원모집</router-link>
      <span class="mx-1">|</span>
      <router-link
        class="btn btn-link font-weight-bold text-secondary"
        :to="{ name: '__HELP_INTENT__' }"
      >Pro스토어</router-link>
      <span class="mx-1">|</span>
      <router-link
        class="btn btn-link font-weight-bold text-secondary"
        :to="{ name: 'markets_board' }"
      >회원간매매</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeLinks',
};
</script>

import {
  REQUEST_LIST__INTENTS_BLOCKS,
  RECEIVE_LIST__INTENTS_BLOCKS,
  FAILURE_LIST__INTENTS_BLOCKS,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  list: [],
  params: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
};

const mutations = {
  [REQUEST_LIST__INTENTS_BLOCKS] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__INTENTS_BLOCKS] (state, { contents, ...params }) {
    state.list = [...contents];
    state.params = { ...params };
    state.error = {};
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__INTENTS_BLOCKS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, { page = 1, scale = 6 }) {
    commit(REQUEST_LIST__INTENTS_BLOCKS);

    api.get('/blocks', { params: { page, scale }}).then(
      response => commit(RECEIVE_LIST__INTENTS_BLOCKS, response.data),
      error => commit(FAILURE_LIST__INTENTS_BLOCKS, error),
    );
  },
  deleteItem({ dispatch, state, commit }, { intentBlockKey }) {
    commit(REQUEST_LIST__INTENTS_BLOCKS);

    api.delete(`/blocks/${intentBlockKey}`).then(
      () => dispatch('requestList', { page: state.params.page }),
    ).then(
      () => router.replace({ name: 'intents_blocks' }),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="border">
    <div class="d-flex justify-content-between bg-primary py-2 px-3">
      <h6 class="text-white mb-0">{{ userstock.title }}</h6>
      <small v-if="userstock.zero" class="text-white bg-danger py-0 px-1">Soldout</small>
    </div>
    <div class="row no-gutters p-3">
      <div class="col-3">
        <ImageOrIcons
          :src="userstock.companyLogo"
          icon="building"
        />
      </div>
      <div class="col-9 pl-3">
        <div class="d-flex flex-row justify-content-between">
          <small class="text-light">가격</small>
          <template v-if="!userstock.price">
            <h6 v-if="!userstock.retailNonProBuyable">전문투자자에만 공개</h6>
            <h6 v-else>투자적합고객에만 공개</h6>
          </template>
          <h6 v-else>{{ userstock.price | currency }} <small>원</small></h6>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <small class="text-light">최소구매수량</small>
          <h6>{{ userstock.minimumOrderQuantity | currency }} <small>주</small></h6>
        </div>
        <div class="row no-gutters">
          <div class="col pr-2">
            <button
              class="btn btn-outline-primary btn-sm w-100"
              @click="$emit('open-company')"
            >기업정보</button>
          </div>
          <div class="col pl-2">
            <button
              v-if="!userstock.zero"
              class="btn btn-outline-primary btn-sm w-100"
              @click="$emit('order-sellassigns')"
            >바로매수</button>
            <template v-else>
              <button
                v-if="userstock.openNoticeKey"
                class="btn btn-primary btn-sm w-100"
                @click="$emit('delete-notice')"
              >해제신청</button>
              <button
                v-else
                @click="$emit('regist-notice')"
                class="btn btn-outline-primary btn-sm w-100"
              >알림신청</button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3">
      <CardSellAssignFiles
        :files="userstock.files || []"
      />
    </div>
  </div>
</template>

<script>
import ImageOrIcons from '@/components/icons/ImageOrIcons';
import CardSellAssignFiles from '@/components/helpIntent/CardSellAssignFiles';

export default {
  name: 'CardSellAssign',
  components: {
    ImageOrIcons,
    CardSellAssignFiles,
  },
  props: {
    userstock: {
      type: Object,
      required: true,
    },
  },
};
</script>

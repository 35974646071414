<template>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <transition-group
      name="carousel-item"
      tag="div"
      class="carousel-inner"
      :enter-class="`carousel-item-${direction === 'left' ? 'right' : 'left'}`"
      :leave-to-class="`carousel-item-${direction}`"
    >
      <template v-for="({ companyName, retailManagingStatus, companyLogo, type }, index) in list">
        <div class="carousel-item active" v-if="index === cardNumber" :key="index">
          <router-link
          
            :to="{
              name: type === 'PLACEMENT' ? '__LP_FUNDING__' : '__HELP_INTENT__'
            }"
            class="text-reset text-decoration-none"
          >
            <div class="row no-gutters link">
              <div class="col-3 image">
                <ImageOrTitle
                  :src="companyLogo"
                  object="contain"
                  :title="companyName"
                />
              </div>
              <div class="col-9 h-10 pl-3 d-flex flex-column justify-content-center">
                <h3 class="mb-1">{{ companyName }}</h3>
                <p class="mb-0 font-weight-bold text-danger">
                  <template v-if="type === 'PLACEMENT'">{{ retailManagingStatus | retail_managing_status }}</template>
                  <template v-if="type === 'HELPSTOCK'">Pro스토어 예약판매중</template>
                  <template v-if="type === 'SELLASSIGN'">Pro스토어 판매중</template>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </transition-group>
    <div class="d-flex justify-content-end justify-content-lg-center">
      <button
        v-for="(el, index) in list"
        :key="index"
        @click="() => moveCard(index)"
        :class="{'active': index === cardNumber}"
        :disabled="index === cardNumber"
        class="btn px-1"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        :data-bs-slide-to="index"
        :aria-label="`Slide ${index}`"
      >
        <IconCircleFill class="icon-xxs" v-if="index === cardNumber" />
        <IconCircle class="icon-xxs" v-else />
      </button>
    </div>
    <!-- <a
      class="carousel-control-prev"
      role="button"
      data-slide="prev"
      @click="handlePrev"
    >
      <IconCaretLeftFill class="text-secondary icon-xl" />
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      role="button"
      data-slide="next"
      @click="handleNext"
    >
      <IconCaretRightFill class="text-secondary icon-xl" />
      <span class="sr-only">Next</span>
    </a> -->
  </div>
</template>

<script>
import IconCircle from '@/components/icons/IconCircle';
import IconCircleFill from '@/components/icons/IconCircleFill';
import ImageOrTitle from '@/components/icons/ImageOrTitle';
import { RETAIL_MANAGING_STATUS } from '@/filters/filtersRetail';

export default {
  name: 'HomeMainCarousel',
  components: {
    IconCircle,
    IconCircleFill,
    ImageOrTitle,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      RETAIL_MANAGING_STATUS,
      cardNumber: 0,
      direction: 'left',
      intervalID: null,
    };
  },
  methods: {
    moveCard(index) {
      if (this.cardNumber < index) {
        this.direction = 'left';
      }
      if (this.cardNumber > index) {
        this.direction = 'right';
      }
      this.cardNumber = index;
      this.endInterval();
      this.startInterval();
    },
    handleNext() {
      this.direction = 'left';
      this.cardNumber += 1;
      if (this.cardNumber === this.list.length) {
        this.cardNumber = 0;
      }
      this.endInterval();
      this.startInterval();
    },
    handlePrev() {
      this.direction = 'right';
      this.cardNumber -= 1;
      if (this.cardNumber < 0) {
        this.cardNumber = this.list.length - 1;
      }
      this.endInterval();
      this.startInterval();
    },
    startInterval() {
      this.intervalID = setInterval(() => {
        this.direction = 'left';
        this.cardNumber = (this.cardNumber + 1) % this.list.length;
      }, 2500);
    },
    endInterval() {
      if (this.intervalID) {
        clearInterval(this.intervalID);
      }
    },
  },
  mounted() {
    if (this.list.length > 1) {
      this.startInterval();
    }
  },
  destroyed() {
    this.endInterval();
  },
};
</script>

<style scoped>
.carousel-control-prev,
.carousel-control-next {
  width: 8%;
}
/* .carousel-item-enter-left {
  transform: translateX(100%);
}
.carousel-item-enter-right {
  transform: translateX(-100%);
}

.carousel-item-leave-to-left {
  transform: translateX(-100%);
}
.carousel-item-leave-to-right {
  transform: translateX(100%);
} */
.text-pre {
  white-space: pre;
}
.image {
  height: 5rem;
}
</style>

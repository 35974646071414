<template>
  <div class="row py-3 sellassigns-card">
    <div class="col-12 col-lg-10 mb-3 mb-lg-0">
      <h6 class="mb-3">{{ item.stockName }} <small class="text-primary">(계좌보유 {{ item.accountCheckQuantity | currency }}주)</small></h6>
      <div class="d-flex flex-column flex-lg-row justify-content-lg-between">
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-left">
          <small class="text-light">매도진행</small>
          <h6 class="mb-0">{{ item.displayable ? '진행중' : '일시중지' }}</h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">확인매물수량</small>
          <h6 class="mb-0">{{ item.currentQuantity | currency }} <small>주</small></h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">관리자확인대기수량</small>
          <h6 class="mb-0">{{ item.inProcessQuantity | currency }} <small>주</small></h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">매도가격</small>
          <h6 class="mb-0">{{ item.sellPrice | currency }} <small>원</small></h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">최소매도수량</small>
          <h6 class="mb-0">{{ item.minimumOrderQuantity | currency }} <small>주</small></h6>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2">
      <div class="d-flex align-items-end h-100">
        <button class="btn btn-outline-primary btn-block btn-sm" @click="$emit('update-inbound', item.sellAssignKey)">수정하기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SellAssignsCard',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sellassigns-card {
  border-top: 2px solid $primary;

  &:last-child {
    border-bottom: 2px solid $primary;
  }
}
</style>

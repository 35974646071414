var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border p-3"},[(!_vm.hasContents)?[_c('h6',{staticClass:"text-secondary text-center mb-4"},[_vm._v("앗! 아직 \""+_vm._s(_vm.typeHeader)+"\" 게시물이 없네요.")]),_c('p',[_vm._v(_vm._s(_vm.typeText)+"를 원하신다면 일반게시 "+_vm._s(_vm.typeText)+" 종목으로 등록해주세요.")])]:[_c('h6',{staticClass:"text-secondary text-center mb-4"},[_vm._v("원하시는 "+_vm._s(_vm.typeText)+"가격이 없으신가요?")]),_c('p',[_vm._v("원하시는 가격으로 일반게시 "+_vm._s(_vm.typeText)+" 종목으로 등록해 주세요.")])],_vm._m(0),_c('router-link',{staticClass:"btn btn-outline-primary btn-block py-2",attrs:{"to":{
      name: 'mystocks_maker',
      query: {
        stockInfoUid: _vm.stock.stockInfoUid,
        stockName: _vm.stock.stockName,
        type: _vm.stock.type === 'SELL' ? 'BUY' : 'SELL',
      },
    }}},[_c('IconPlusCircleFill',{staticClass:"icon-lg mb-1"}),_c('p',{staticClass:"mb-0"},[_vm._v("게시할 종목에 추가하세요")])],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pl-4"},[_c('li',[_vm._v("회원님 개인정보 공개 없이 네고스탁 공개 ID로 게시")]),_c('li',[_vm._v("하루 최대 3번 네고 요청을 받을 수 있음")])])
}]

export { render, staticRenderFns }
<template>
  <div class="form-label-group mb-3">
    <input
      placeholder="결제이행"
      type="text"
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      :disabled="disabled"
      :value="value"
    >
    <label :for="id" class="col-form-label">결제이행</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputExpiration',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

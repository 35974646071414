<template>
  <div class="mb-2">
    <label class="col-form-label text-primary w-100">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'LabelOnly',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.col-form-label {
  @include nego-font(1.125rem, 700, 1.03, -0.5px);
  border-bottom: 5px solid $primary;
}
</style>

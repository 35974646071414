// base_url
export const AppTarget = process.env.VUE_APP_TARGET;
export const AppPublicPath = process.env.VUE_APP_PUBLIC_PATH;

// url
export const NegoStockWeb = process.env.VUE_APP_NEGOSTOCK_WEB;
export const NegoStockApi = process.env.VUE_APP_NEGOSTOCK_API;
export const DealBoardWeb = process.env.VUE_APP_DEALBOARD_WEB;
export const DealBoardApi = process.env.VUE_APP_DEALBOARD_API;
export const FintechCommPath = process.env.VUE_APP_FINTECH_COMM_PATH;
export const FintechAccountPath = process.env.VUE_APP_FINTECH_ACCOUNT_PATH;
export const FintechCommApi = process.env.VUE_APP_FINTECH_COMM_API;
export const FintechDocuApi = process.env.VUE_APP_FINTECH_DOCU_API;
export const FintechDocuWeb = process.env.VUE_APP_FINTECH_DOCU_WEB;

// xsrf
export const xsrfCookieName = process.env.VUE_APP_XSRF_COOKIE_NAME;
export const xsrfHeaderName = process.env.VUE_APP_XSRF_HEADER_NAME;

// firebase
export const FIREBASE ={
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

// kakao
export const kakaoKey = process.env.VUE_APP_KAKAO_KEY;

// angelleague
// export const AngelWeb = process.env.VUE_APP_ANGEL_WEB;

// tudal
// export const TudalWeb = process.env.VUE_APP_TUDAL_WEB;

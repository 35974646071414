<template>
  <div class="row row-cols-1 row-cols-lg-2 my-4">
    <div class="col mb-3" v-for="(el, index) in list" :key="index">
      <div class="row no-gutters border rounded p-2">
        <div class="col-3">
          <img
            class="hot-image rounded-0 image-contain"
            :src="el.logo || PNGbbb"
            @error="el.logo = PNGbbb"
          />
        </div>
        <div class="col-9 pl-2 position-static">
          <div class="d-flex">
            <div class="flex-grow-1 text-truncate">
              <router-link
                class=" text-reset stretched-link"
                :to="{
                  name: '__COMPANY_VIEW__',
                  params: { companyCode: el.companyCode },
                }"
              >
                {{ el.companyName }}
                <p class="font-sm mb-0">대표자: {{ el.ceo }}</p>
              </router-link>
            </div>
            <div class="flex-shrink-0">
              <button
                v-if="selectable"
                class="btn btn-sm stretched-link position-relative"
                @click="$emit('select-item', el)"
                :title="!el.registedNotice ? '알림신청' : '알림해제'"
              >
                <IconBell class="text-light" v-if="!el.registedNotice" />
                <IconBellFill class="text-danger" v-else />
              </button>
              <router-link
                v-else
                title="알림신청"
                class="btn btn-sm stretched-link position-relative"
                :to="{ name: 'sign_in', query: { redirect_path: '/otc/hot' } }"
              ><IconBell class="text-light" /></router-link>
            </div>
          </div>
          <KeywordButtons
            :list="el.keyword"
            @toggle-item="$emit('toggle-item')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBell from '@/components/icons/IconBell';
import IconBellFill from '@/components/icons/IconBellFill';
import KeywordButtons from '@/views/otc/KeywordButtons.vue';
import PNGbbb from './img/bbb.png';

export default {
  name: 'CardListHot',
  components: {
    IconBell,
    IconBellFill,
    KeywordButtons,
  },
  data() {
    return {
      PNGbbb,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.hot-image {
  height: 5rem;
  width: 100%;
  background-color: #ebebeb;
  object-fit: contain;
}
.btn-sm {
  font-size: .75rem;
  line-height: 1.25;
}
</style>

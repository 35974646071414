<template>
  <button
    class="btn btn-kakao btn-block btn-lg"
    @click="authorize"
    :disabled="!isInitialized"
  >
    <h5 class="mb-0">
      <IconChatFill class="btn-kakao-symbol mr-1 mb-1" />
      <span class="btn-kakao-label"> 카카오계정으로 로그인</span>
    </h5>
  </button>
</template>

<script>
import IconChatFill from '@/components/icons/IconChatFill.vue';
import { NegoStockWeb, kakaoKey } from '@/utils/constants';

export default {
  name: 'KakaoButton',
  components: {
    IconChatFill,
  },
  data() {
    return {
      isInitialized: false,
    };
  },
  props: {
    iam: {
      type: String,
      default: '',
    },
  },
  computed: {
    params() {
      return this.iam ? `___iam___${this.iam}` : '' ;
    },
  },
  methods: {
    authorize() {
      const Kakao = window.Kakao;
      Kakao.Auth.authorize({
        "redirectUri": `${NegoStockWeb}/auth/kakao`,
        "state": `jLwuwWGqfLSJpAEZ3evYj8JN4kC11SJDKPbAKT8B9Rs=${this.params}`,
      });
    }
  },
  mounted() {
    const Kakao = window.Kakao;
    if (Kakao) {
      if (!Kakao.isInitialized()) {
        Kakao.init(kakaoKey);
      }
      this.isInitialized = Kakao.isInitialized();
    }
  },
};
</script>
<style lang="scss" scoped>
.btn-kakao {
  background-color: #FEE500;
}
.btn-kakao-symbol,
.btn-kakao-label {
  color: #000000;
}
.btn-kakao-label {
  opacity: 0.85;
}
</style>

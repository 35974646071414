<template>
  <div class="invest-documents">
    <InvestLinks
      :mode="mode"
      :links="[
        { name: 'LETTERS', label: '종합소득세 관련' },
        { name: 'GAINS',   label: '양도소득세 관련' },
      ]"
      @select-mode="selectMode"
    />
    <div>
      <DocsLetters
        v-if="mode === 'LETTERS'"
        :list="sortedList"
      />
      <DocsGains
        v-if="mode === 'GAINS'"
        :list="gains"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import InvestLinks from '@/components/investPlacementsDocuments/InvestLinks';
import DocsLetters from '@/components/investPlacementsDocuments/DocsLetters';
import DocsGains from '@/components/investPlacementsDocuments/DocsGains';

export default {
  name: 'InvestPlacementsDocuments',
  components: {
    InvestLinks,
    DocsLetters,
    DocsGains,
  },
  data() {
    return {
      mode: 'LETTERS',
    };
  },
  computed: {
    ...mapState('investPlacementsDocuments', [
      'list',
      'gains',
    ]),
    ...mapGetters('investPlacementsDocuments', [
      'sortedList',
    ]),
  },
  methods: {
    ...mapActions('investPlacementsDocuments', [
      'requestList',
    ]),
    selectMode(mode) {
      this.mode = mode;
    },
  },
  mounted() {
    this.requestList();
  },
};
</script>

<style lang="scss" scoped>
.invest-documents {
  min-height: 100vh;
}
</style>

import { USER__ACCEPT_PROVIDE_SCORE } from '@/filters/filtersUser.js';

// 2년 경과
export function isOverUserTime(value) {
  const today = new Date();
  const limit = new Date(value);
  limit.setFullYear(limit.getFullYear() + 2);
  return today.valueOf() > limit.valueOf();
}

// 1일 경과
export function isOverUserDate(value) {
  const today = new Date();
  const limit = new Date(value);
  limit.setDate(limit.getDate() + 1);
  return today.valueOf() > limit.valueOf();
}

// 10분 경과
export function isOverUserMinute(value) {
  const today = new Date();
  const limit = new Date(value);
  limit.setMinutes(limit.getMinutes() + 10);
  return today.valueOf() > limit.valueOf();
}

export function userScore(score) {
  const [type] = USER__ACCEPT_PROVIDE_SCORE.filter(el => {
    const [min, max] = el.score;
    return score >= min && score <= max;
  });
  return type;
}

export function userScoreLabel(score) {
  if (!score) {
    return '-';
  }
  return userScore(score)['label'];
}

export function userScoreDesc(score) {
  if (!score) {
    return '-';
  }
  return userScore(score)['desc'];
}

export function isLowerExpert(score) {
  return (score > 0 && score < 81) ?? false;
}

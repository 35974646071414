import _ from 'underscore';
import api from '@/api/axios';
import {
  INITIAL__DEALS_CONTENTS_COMMENTS,
  REQUEST__DEALS_CONTENTS_COMMENTS,
  RECEIVE__DEALS_CONTENTS_COMMENTS,
  FAILURE__DEALS_CONTENTS_COMMENTS,
  INPUT__DEALS_CONTENTS_COMMENTS,
  POST__DEALS_CONTENTS_COMMENTS,
  DONE__DEALS_CONTENTS_COMMENTS,
  FAIL__DEALS_CONTENTS_COMMENTS,
} from '@/store/mutation-types';

const state = {
  dealContentsKey: '',
  list: {
    data: [],
    error: {},
    status: 'INITIAL',
  },
  form: {
    error: {},
    status: 'INITIAL',
  },
};

const getters = {
  countedList: state => _.reduce(state.list.data, (sum, comment) => comment.reply ? sum : sum + 1, 0),
  loadingList: state => state.list.status === 'REQUEST',
  loadingForm: state => state.form.status === 'POST',
};

const mutations = {
  [INITIAL__DEALS_CONTENTS_COMMENTS] (state) {
    state.list.data = [];
    state.list.status = 'INITIAL';
  },
  [REQUEST__DEALS_CONTENTS_COMMENTS] (state, { dealContentsKey }) {
    state.dealContentsKey = dealContentsKey;
    state.list.status = 'REQUEST';
  },
  [RECEIVE__DEALS_CONTENTS_COMMENTS] (state, data) {
    state.list.data = [...data];
    state.list.status = 'RECEIVE';
  },
  [FAILURE__DEALS_CONTENTS_COMMENTS] (state) {
    state.list.status = 'FAILURE';
  },
  [INPUT__DEALS_CONTENTS_COMMENTS] (state, { key, value }) {
    state.form.data[key] = value;
  },
  [POST__DEALS_CONTENTS_COMMENTS] (state) {
    state.form.status = 'POST';
  },
  [DONE__DEALS_CONTENTS_COMMENTS] (state) {
    state.form.status = 'DONE';
  },
  [FAIL__DEALS_CONTENTS_COMMENTS] (state) {
    state.form.status = 'FAIL';
  },
};

const actions = {
  requestList({ commit }, { dealContentsKey }) {
    commit(REQUEST__DEALS_CONTENTS_COMMENTS, { dealContentsKey });

    api.get(`/deals/comments/${dealContentsKey}`).then(
      response => commit(RECEIVE__DEALS_CONTENTS_COMMENTS, response.data),
      error => commit(FAILURE__DEALS_CONTENTS_COMMENTS, error.response.data),
    );
  },
  submitForm({ dispatch, commit, state }, { comment, parentKey }) {
    commit(POST__DEALS_CONTENTS_COMMENTS);

    const { dealContentsKey } = state;
    const form = { dealContentsKey, comment, parentKey };
    api.post('/deals/comments', form).then(
      response => {
        commit(DONE__DEALS_CONTENTS_COMMENTS, response.data);
        dispatch('requestList', { dealContentsKey });
      },
      error => commit(FAIL__DEALS_CONTENTS_COMMENTS, error.response.data),
    );
  },
  removeItem({ dispatch, commit, state }, { dealCommentKey }) {
    const { dealContentsKey } = state;
    api.delete(`/deals/comment/${dealCommentKey}`).then(
      response => {
        commit(DONE__DEALS_CONTENTS_COMMENTS, response.data);
        dispatch('requestList', { dealContentsKey });
      },
      error => commit(FAIL__DEALS_CONTENTS_COMMENTS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

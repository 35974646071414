<template>
  <nav class="navbar navbar-expand-md fixed-top navbar-light bg-white shadow-sm">
    <div class="container-lg">
      <router-link
        class="navbar-brand text-primary font-weight-bold p-0"
        aria-label="Negostock Home"
        :to="{name: 'home'}"
      >
        <img src="./img/logo.svg" alt="" class="logo">
        <img src="./img/brand.svg" alt="" class="brand">
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleModal"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarContent" class="collapse navbar-collapse mt-3 mt-sm-0">
        <ul class="navbar-nav ml-auto mr-md-2">
          <router-link class="nav-item" tag="li" active-class="active" :to="{name: '__OTC_HOT__'}">
            <a class="nav-link text-secondary mx-lg-3">기업정보</a>
          </router-link>
          <router-link class="nav-item" tag="li" active-class="active" :to="{name: '__LP_FUNDING__'}">
            <a class="nav-link text-secondary mx-lg-3">투자기회</a>
          </router-link>
          <router-link class="nav-item" tag="li" active-class="active" :to="{name: '__LP_JOIN__'}">
            <a class="nav-link text-secondary mx-lg-3">투자진행</a>
          </router-link>
          <router-link class="nav-item" tag="li" active-class="active" :to="{name: 'mystocks'}">
            <a class="nav-link text-secondary mx-lg-3">MY</a>
          </router-link>
          <router-link class="nav-item" tag="li" active-class="active" :to="{name: 'user_infomation'}" v-if="isAuthenticated" key="auth-status">
            <a class="nav-link text-secondary mx-lg-3">회원정보</a>
          </router-link>
          <router-link class="nav-item" tag="li" active-class="active" :to="{name: 'sign_in'}" v-else key="auth-no">
            <a class="nav-link text-secondary mx-lg-3">로그인</a>
          </router-link>
        </ul>
        <div class="d-flex mt-2 mt-md-0" v-if="isAuthenticated" key="auth-status">
          <router-link class="btn btn-secondary btn-sm flex-grow-1 ml-md-3 px-3" :to="{name: 'sign_out'}">로그아웃</router-link>
        </div>
        <div class="d-flex mt-2 mt-md-0" v-else key="auth-no">
          <router-link class="btn btn-secondary btn-sm flex-grow-1 ml-md-3 px-3" :to="{name: 'auth'}">회원가입</router-link>
        </div>
      </div>
    </div>
    <AppNavbarModal
      v-if="isOpenedModal"
      @toggle-modal="toggleModal"
    />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import AppNavbarModal from '@/views/app/AppNavbarModal.vue';

export default {
  name: 'AppNavbar',
  components: {
    AppNavbarModal,
  },
  data() {
    return {
      isOpenedModal: false,
    };
  },
  computed: {
    ...mapGetters('userInfomation', [
      'isAuthenticated',
    ]),
  },
  methods: {
    toggleModal() {
      this.isOpenedModal = !this.isOpenedModal;
    },
  },
  watch: {
    '$route'() {
      this.isOpenedModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 2.5rem;
  height: 2.5rem;
}
.brand {
  width: 10rem;
  height: 2rem;
}
</style>

<template>
  <div>
    <div v-for="(content, indexA) in contents" :key="indexA" class="my-4">
      <h6 v-if="content.title" class="font-weight-bold">{{ content.title }}</h6>
      <p v-if="content.text">{{ content.text }}</p>
      <ul v-if="content.list" class="list-decimal- list-unstyled">
        <template v-for="(item, indexB) in content.list">
          <li v-if="typeof item === 'string'" :key="indexB">{{ item }}</li>
          <ul v-if="typeof item === 'object'" :key="indexB" class="list-alpha- list-unstyled">
            <template v-for="(el, indexC) in item">
              <li v-if="typeof el === 'string'" :key="indexC" class="">{{ el }}</li>
              <ul v-if="typeof el === 'object'" :key="indexC" class="list-roman- list-unstyled">
                <li v-for="(s, indexD) in el" :key="indexD" class="">{{ s }}</li>
              </ul>
            </template>
          </ul>
        </template>
      </ul>
      <template v-if="content.helps">
        <p v-for="(help, idx) in content.helps" :key="idx" class="mb-0">{{ help }}</p>
      </template>
      <table v-if="content.table" class="table table-bordered">
        <thead>
          <tr>
            <td v-for="(head, idxH) in content.table.head" :key="idxH">{{ head }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, idxR) in content.table.body" :key="idxR">
            <td v-for="(col, idxC) in row" :key="idxC">{{ col }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsContents',
  props: {
    contents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.list-unstyled {
  padding-left: 1rem;
}
.list-decimal {
  list-style-type: decimal;
}
.list-alpha {
  list-style-type: upper-alpha;
}
.list-hangul {
  list-style-type: hangul;
}
.list-roman {
  list-style-type: lower-roman;
}
</style>


<template>
  <AppModalSimple
    v-if="isClubMember"
    title="딜보드(기관)회원 안내"
    content="로그인 하신 ID는 딜보드(기관)회원입니다. 딜보드페이지로 이동합니다."
  >
    <button
      class="btn btn-primary"
      @click="signOut"
    >딜보드로 이동</button>
  </AppModalSimple>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'AppNavbarNotice',
  components: {
    AppModalSimple,
  },
  computed: {
    ...mapGetters('userInfomation', [
      'isClubMember',
    ]),
  },
  methods: {
    ...mapActions('userInfomation', [
      'signOut',
    ]),
  },
};
</script>

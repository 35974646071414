import _ from 'underscore';
import api from '@/api/axios';
import {
  CHECK__DEALS_QNA,
  INPUT__DEALS_QNA,
  POST__DEALS_QNA,
  DONE__DEALS_QNA,
  FAIL__DEALS_QNA,
} from '@/store/mutation-types';
import router from '@/router';

const INIT_FORM = {
  dealKey: '',
  dealName: '',
  subject: '',
  contents: '',
  file: '',
  to: '',
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  enabledForm: () => true,
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [CHECK__DEALS_QNA] (state) {
    const { subject, contents, to } = state.form;
    const error = {};

    if (!subject) {
      error.subject = '제목을 입력해주세요';
    }
    if (!contents) {
      error.contents = '내용을 입력해주세요.';
    }
    if (!to) {
      error.to = '담당자 이메일을 확인해주세요.';
    }

    state.error = { ...error };
  },
  [INPUT__DEALS_QNA] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST__DEALS_QNA] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE__DEALS_QNA] (state) {
    state.form = { ...INIT_FORM };
    state.status = 'DONE';

    router.replace({
      name: '__DEALS_LIBRARY__',
      query: router.history.current.query,
    });
  },
  [FAIL__DEALS_QNA] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK__DEALS_QNA);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
 },
  submitForm({ commit, state }) {
    commit(POST__DEALS_QNA);

    const { dealKey, to, subject, contents, file } = state.form;
    const formData = new FormData();
    formData.append('dealKey', dealKey);
    formData.append('to', to);
    formData.append('subject', subject);
    formData.append('contents', contents);
    formData.append('file', file);

    api.post('/deals/qna', formData).then(
      response => commit(DONE__DEALS_QNA, response.data),
      error => commit(FAIL__DEALS_QNA, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import {
  REQUEST__INVEST_PLACEMENTS_HISTORY,
  RECEIVE__INVEST_PLACEMENTS_HISTORY,
  FAILURE__INVEST_PLACEMENTS_HISTORY,
} from '@/store/mutation-types';
import api from '@/api/axios';
import _ from 'underscore';
import { checkInvestorType } from '@/filters/filtersInvestor'

const state = {
  info: {},
  cert: {},
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__INVEST_PLACEMENTS_HISTORY] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__INVEST_PLACEMENTS_HISTORY] (state, data) {
    const { histories, cert, ...info } = data;
    const newHistories = [];
    _.reduceRight(histories, (exBalance, history) => {
      const { type, amount } = history;
      const sign = checkInvestorType(type);
      const balance = sign ? (exBalance + amount) : (exBalance - amount);
      newHistories.unshift({
        ...history,
        increase: sign ? amount : '-',
        decrease: sign ? '-' : amount,
        balance,
      });
      return balance;
    }, 0);

    state.list = [ ...newHistories ];
    state.cert = { ...cert };
    state.info = { ...info };
    state.status = 'RECEIVE';
  },
  [FAILURE__INVEST_PLACEMENTS_HISTORY] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, { dealKey }) {
    commit(REQUEST__INVEST_PLACEMENTS_HISTORY);

    api.get(`/invest/placement/hist/${dealKey}`).then(
      response => commit(RECEIVE__INVEST_PLACEMENTS_HISTORY, response.data),
      error => commit(FAILURE__INVEST_PLACEMENTS_HISTORY, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

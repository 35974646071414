<template>
  <button
    :disabled="disabled"
    class="btn btn-outline-primary btn-block py-2"
    @click="$emit('click-button')"
  >
    <div class="d-flex flex-lg-column justify-content-between align-items-center">
      <p class="order-lg-2 mb-0">{{ label }}</p>
      <IconPlusCircleFill class="order-lg-1 mb-lg-2 icon-lg" />
    </div>
  </button>
</template>

<script>
import IconPlusCircleFill from '@/components/icons/IconPlusCircleFill.vue';

export default {
  name: 'NegoButton',
  components: {
    IconPlusCircleFill,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="mb-3">
    <FormHead
      :title="intent.stockName"
      :text="intent.stockCode"
      :intentType="intentType"
    />
    <div>
      <p>{{ content }}</p>
    </div>
    <!--
      최종제안(offers),
      수락(approval),
      거절(refuse_sender, refuse_receiver),
    -->
    <form
      @submit.prevent="checkUser"
      v-if="transType === 'offers' || transType === 'approval' || transType === 'refuse_sender' || transType === 'refuse_receiver'"
    >
      <fieldset :disabled="transType !== 'offers'">
        <InputQuantity
          id="quantity"
          :value="form['quantity']"
          :error="error['quantity']"
          @input-form="(value) => inputForm({ key: 'quantity', value })"
        />
        <InputPrice
          id="price"
          :value="form['price']"
          :error="error['price']"
          @input-form="(value) => inputForm({ key: 'price', value })"
        />
        <InputTotalDisable
          id="totalPrice"
          :value="form['totalPrice']"
          :error="error['totalPrice']"
        />
        <SelectBizdays
          v-if="transType === 'offers'"
          id="expiration"
          :list="bizdays"
          :value="form['expiration']"
          :error="error['expiration']"
          @input-form="(value) => inputForm({ key: 'expiration', value })"
        />
        <InputExpiration
          v-else-if="transType === 'approval' || transType === 'refuse_sender' || transType === 'refuse_receiver'"
          id="expiration"
          :value="form['expiration'] | bizdays"
          :error="error['expiration']"
        />
      </fieldset>
      <SubmitButton
        :label="buttonLabel"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <!--
      취소(cancel_sender, cancel_receiver),
      삭제(delete)
    -->
    <form @submit.prevent="submitForm" v-else>
      <SubmitButton
        :label="buttonLabel"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>

    <template v-if="showStop">
      <AppModalSimple
        title="네고스탁"
        content="전용계좌 발급 중에는 거래요청 또는 거래수락을 하실 수 없습니다."
      >
        <div class="d-flex w-100">
          <button class="btn btn-outline-dark flex-grow-1 mr-3" @click="closeModal">확인</button>
        </div>
      </AppModalSimple>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__INTENTS_TRANS } from '@/store/mutation-types';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputQuantity from '@/components/intents/form/InputQuantity.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import InputTotalDisable from '@/components/intents/form/InputTotalDisable.vue';
import SelectBizdays from '@/components/intents/form/SelectBizdays.vue';
import InputExpiration from '@/components/intents/form/InputExpiration.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import AppModalSimple from '@/components/app/AppModalSimple.vue';
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';

const TRANS_MAP = {
  delete: {
    content: '삭제 하시겠습니까?',
    buttonLabel: '삭제',
  },
  offers: {
    content: '수량, 가격을 확정하여 거래요청을 하실 수 있습니다.',
    buttonLabel: '거래요청(중개요청) 발송',
  },
  cancel_sender: {
    content: '네고요청을 취소 하시겠습니까?',
    buttonLabel: '취소',
  },
  cancel_receiver: {
    content: '거래요청을 취소 하시겠습니까?',
    buttonLabel: '거래요청(중개요청) 취소',
  },
  refuse_receiver: {
    content: '네고요청을 거절 하시겠습니까?',
    buttonLabel: '네고요청 거절',
  },
  refuse_sender: {
    content: '거래요청을 거절 하시겠습니까?',
    buttonLabel: '거래요청 거절',
  },
  approval: {
    content: '거래요청을 수락 하시겠습니까?',
    buttonLabel: '거래수락(중개요청) 발송',
  },
};

export default {
  name: 'IntentsTransView',
  components: {
    FormHead,
    InputQuantity,
    InputPrice,
    InputTotalDisable,
    SelectBizdays,
    InputExpiration,
    SubmitButton,
    AppModalSimple,
  },
  data() {
    return {
      showStop: false,
    };
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('intentsTrans', [
      'intent',
      'form',
      'transType',
      'bizdays',
      'error',
      'loadingStatus',
    ]),
    ...mapGetters('intentsTrans', [
      'enabledForm',
      'loadingForm',
    ]),
    content() {
      return this.transType ? TRANS_MAP[this.transType].content : '';
    },
    buttonLabel() {
      return this.transType ? TRANS_MAP[this.transType].buttonLabel : '';
    },
    intentType() {
      if (this.transType !== 'refuse_receiver' && this.transType !== 'delete') {
        return this.intent.buyer ? '매수' : '매도';
      }
      return '';
    }
  },
  methods: {
    ...mapMutations('intentsTrans', {
      'inputForm': INPUT_FORM__INTENTS_TRANS,
    }),
    ...mapActions('intentsTrans', [
      'setIntent',
      'transForm',
      'deleteForm',
      'checkForm',
      'offersForm',
      'requestBizdays',
    ]),
    checkUser() {
      const { intentKey } = this.$route.params;
      const { transType, toStatus } = this.$route.query;
      const { brokerAccountStatus, bankAccountStatus, kassetAccountStatus } = this.user;

      if (transType === 'offers' || transType === 'approval') {
        if (brokerAccountStatus === 'NONE' && bankAccountStatus === 'NONE') {
          if (kassetAccountStatus === 'NONE') {
            const PATH = [
              `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
              `?path=/users/account`,
              `&redirect_path=${NegoStockWeb}/intents/${intentKey}/trans`,
              `&transType=${transType}`,
              `&toStatus=${toStatus}`,
            ].join('');
            window.location = PATH;
            return;
          } else if (kassetAccountStatus === 'READY') {
            this.showStop = true;
            return;
          }
        }
      }

      this.submitForm();
    },
    closeModal() {
      this.showStop = false;
    },
    submitForm() {
      if (this.transType === 'delete') {
        this.deleteForm();
      } else if (this.transType === 'offers') {
        this.checkForm();
      } else {
        this.transForm();
      }
    },
  },
  mounted() {
    const { intentKey } = this.$route.params;
    const { transType, toStatus } = this.$route.query;

    let status;
    if (toStatus) {
      status = toStatus;
    } else if (!toStatus && transType === 'approval') {
      status = 'S13';
    } else if (!toStatus && transType === 'refuse_sender') {
      status = 'S11';
    } else {
      this.$router.push({ name: 'intents' });
      return;
    }

    this.setIntent({ intentKey, transType, 'toStatus': status });

    if (transType === 'offers') {
      this.requestBizdays();
    }
  },
};
</script>

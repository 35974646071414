<template>
  <div>
    <FormHead
      :title="form.stockName"
      :text="form.stockInfoUid"
    />
    <div class="rounded bg-warning p-3 mb-5" v-if="!isProInvestor">
      <p class="mb-0"><span class="font-weight-bold">"주당가격 확인"과 "바로매수 신청"은 개인전문투자자만 가능합니다.</span> 당사 전문투자자 등록신청은 회원정보 페이지에서 가능하며, 휴대전화 촬영을 통해 전문투자자 확인증을 제출해주시면 당사의 전문투자자로 등록하실 수 있습니다.</p>
      <p class="text-right mb-0">
        <router-link
          class="btn btn-secondary btn-sm"
          :to="{
            name: 'user_infomation',
            hash: '#infomation-regist',
          }"
        >회원정보페이지로 가기</router-link>
      </p>
    </div>
    <form @submit.prevent="handleSubmit">
      <InputPrice
        id="price"
        disabled
        :label="`주당가격(원) ${!isProInvestor ? ':전문투자자에게만 공개' : '' }`"
        :value="form['price']"
        :error="error['price']"
      />
      <InputQuantity
        id="quantity"
        disabled
        :value="form['quantity']"
        :error="error['quantity']"
        :label="`매수수량`"
      />
      <InputTotalDisable
        id="totalPrice"
        :value="form['price'] * form['quantity']"
        label="거래금액(원)"
      />
      <h6>1주 천원 바로매수 이벤트 안내</h6>
      <ol class="pl-4 mb-5">
        <li>본 이벤트는 당사에 전문투자자로 등록해 주신 고객님을 대상으로 <span class="font-weight-bold bg-warning">Pro스토어 바로매수</span>를 경험해 보실 수 있도록 1회에 한하여 1주를 1천원에 구매하실 수 있는 기회를 제공하는 것입니다.</li>
        <li>바로매수 신청 후, 1시간 이내에 안내해 드리는 가상계좌로 매수대금(1천원)을 입금해주세요.</li>
        <li>입금 확인 후 30분 이내에 미리 등록해 두신 계좌로 매수하신 이벤트 주식 1주를 보내드립니다.</li>
      </ol>
      <div class="alert alert-danger" role="alert" v-if="error['orderable']">
        {{ error['orderable'] }}
      </div>
      <SubmitButton
        label="바로매수 신청"
        :loadingStatus="loadingForm"
        :enabledForm="isProInvestor"
      />
    </form>
    <NegoModalAccount ref="negoModalAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputQuantity from '@/components/intents/form/InputQuantity.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import InputTotalDisable from '@/components/intents/form/InputTotalDisable.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import NegoModalAccount from '@/components/nego/NegoModalAccount.vue';
import {
  INIT_FORM__SELL_ASSIGNS_ORDER_EVENT,
  INPUT_FORM__SELL_ASSIGNS_ORDER_EVENT,
} from '@/store/mutation-types';
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'SellAssignsOrderEventView',
  components: {
    FormHead,
    InputQuantity,
    InputPrice,
    InputTotalDisable,
    SubmitButton,
    NegoModalAccount,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('sellAssignsOrderEvent', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('sellAssignsOrderEvent', [
      'loadingForm',
    ]),
    isProInvestor() {
      return this.user.proInvestor;
    },
  },
  methods: {
    ...mapMutations('sellAssignsOrderEvent', {
      'initForm': INIT_FORM__SELL_ASSIGNS_ORDER_EVENT,
      'inputForm': INPUT_FORM__SELL_ASSIGNS_ORDER_EVENT,
    }),
    ...mapActions('sellAssignsOrderEvent', [
      'requestInfo',
      'checkOrder',
      'checkForm',
      'submitForm',
    ]),
    handleSubmit() {
      const {
        // userKey,
        preUser,
        kassetAccountStatus,
        brokerAccountStatus,
        bankAccountStatus,
      } = this.user;

      // 로그인 X
      // if (!userKey) {
      //   const { path, query } = this.$route;
      //   this.$router.push({
      //     name: 'sign_in',
      //     query: {
      //       ...query,
      //       redirect_path: path,
      //     },
      //   });
      //   return;
      // }

      // 휴대전화인증 X
      if (preUser) {
        this.$router.push({
          name: 'user_mobile',
        });
        return;
      }

      // 비대면계좌 처리대기 중
      if (kassetAccountStatus === 'READY') {
        this.$refs.negoModalAccount.openModal();
        return;
      }

      // 증권,은행계좌 미등록
      if (
        kassetAccountStatus === 'NONE'
        && brokerAccountStatus === 'NONE'
        && bankAccountStatus === 'NONE'
      ) {
        const {
          stockInfoUid, stockName,
          price, quantity, minimumOrderQuantity,
          sellAssignKey,
        } = this.$route.query;
        const PATH = [
          `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
          `?path=/users/account`,
          `&redirect_path=${NegoStockWeb}/sell-assigns/order-event`,
          `&stockInfoUid=${stockInfoUid}`,
          `&stockName=${encodeURIComponent(stockName)}`,
          `&price=${price}`,
          `&quantity=${quantity}`,
          `&minimumOrderQuantity=${minimumOrderQuantity}`,
          `&sellAssignKey=${sellAssignKey}`,
        ].join('');
        window.location = PATH;
        return;
      }
      this.checkForm();
    },
  },
  mounted() {
    const { sellAssignKey } = this.$route.query;
    this.requestInfo(sellAssignKey);
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

import {
  REQUEST__PLACEMENTS_CASH_HISTORY,
  RECEIVE__PLACEMENTS_CASH_HISTORY,
  FAILURE__PLACEMENTS_CASH_HISTORY,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__PLACEMENTS_CASH_HISTORY] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__PLACEMENTS_CASH_HISTORY] (state, data) {
    state.list = [...data];
    state.status = 'RECEIVE';
  },
  [FAILURE__PLACEMENTS_CASH_HISTORY] (state) {
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, dealKey) {
    commit(REQUEST__PLACEMENTS_CASH_HISTORY);

    api.get(`/invest/placement/cash/hist/${dealKey}`).then(
      response => commit(RECEIVE__PLACEMENTS_CASH_HISTORY, response.data),
      error => commit(FAILURE__PLACEMENTS_CASH_HISTORY, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

const nego = [
  {
    index: 1,
    question: '"네고요청"이란 무엇인가요?',
    answer: '비상장거래의 가장 큰 장애는 거래상대방을 찾는 것이고,거래상대방을 찾기 위해 주로 비상장주식 정보제공웹사이트 등의 게시판을 이용합니다. "네고요청"은 게시판의 상대방에게 "거래의사가 있으니 협의를 진행해 보자"라고 비공개로 1:1제안이라 볼 수 있습니다.',
  },
  {
    index: 2,
    question: '게시판 등에 공개된 전화번호가 아닌 이미 알고 있는 상대방과 거래를 진행하려면 어떻게 하나요?',
    answer: '네고진행화면에서 "지인과 거래하기" 버튼을 눌러 네고와 거래를 진행하시면 됩니다.',
  },
  {
    index: 3,
    question: '상대방에게 어떻게 네고요청을 전달하나요?',
    answer: '코리아에셋투자증권 명의로 발송되는 SMS로 전달됩니다. 메시지에는 네고요청을 하는 회원 회원정보 중 공개ID만 표시됩니다.',
  },
  {
    index: 4,
    question: '상대방과 언제 협의 가능하나요?',
    answer: '네고요청 1건당 하나의 채팅창이 만들어집니다. 채팅은 네고요청을 받은 상대방이 네고스탁에 접속하여 해당요청을 확인했을 때부터 가능합니다. 협의가 마무리되어 거래요청(중개요청)이 이루어지면 이후에는 확인만 가능하면 추가적인 채팅을 진행할 수 없습니다.',
  },
  {
    index: 5,
    question: '매매중개요청이란 무엇이며 어떤 과정으로 이루어지나요?',
    answer: '1:1채팅을 통해 거래조건 협의가 완료되면, 네고요청을 받을 회원이 협의된 거래조건(가격,수량)을 입력한 후 먼저 거래요청(중개요청)버튼을 눌러 매매중개요청을 합니다. 네고요청을 보낸 회원이 동 조건을 확인하고 거래수락(중개요청)을 누르면 매매중개요청이 완료됩니다. 첫 중개요청(거래요청 또는 거래수락)인 경우, 거래자 본인명의의 증권 및 은행계좌 등록이 등록이 필요합니다.',
  },
  {
    index: 6,
    question: '"일반게시"란 무엇인가요?',
    answer: '다른 회원 나에게 네고요청을 할 수 있도록 일종의 매물을 등록하는 것을 의미합니다.',
  },
  {
    index: 7,
    question: '다른 회원에게서 네고요청을 받을 종목을 지정할 수 있나요?',
    answer: '"나의정보"화면에서 "일반게시로 등록한 종목만 네고요청 수령"을 활성화하시면 등록한 종목에 대해서만 네고요청을 수령하게 됩니다.',
  },
  {
    index: 8,
    question: '관심 없는 네고요청을 계속 보내는 회원을 차단할 수는 없나요?',
    answer: '거래요청을 거절하였음에도 계속해서 네고요청을 보내는 회원이 있을 경우, "회원정보" 화면에서 "회원차단_수정"을 누르셔서 최근 나에게 네고요청을 보낸회원 회원을 선택하여 차단하실 수 있습니다. 차단후에는 동 회원으로부터 발송되는 네고요청이 차단됩니다.',
  },
];

const trade = [
  {
    index: 1,
    question: '네고요청을 통해 매매가 합의되면 이후 매매진행절차는 어떻게 되나요?',
    answers: [
      '첫째 : 거래당사자가 각각 코리아에셋투자증권이 지정하는 계좌로 매매대금과 주식을 이체합니다.',
      '둘째 : 거래당사자가 각각 실명정보 입력 후 신분증 사본을 제출합니다.(실명정보 입력은 최초 거래 시 1회만 입력하면 이후에는 기 입력하신 동일정보가 사용합니다.)',
      '셋째 : 코리아에셋투자증권에서 실명정보를 확인합니다.',
      '넷째 : 매수자가 등록한 증권계좌로 주식을 이체하고, 매도자가 등록한 은행계좌로 매각대금을 송금합니다.',
    ],
  },
  {
    index: 2,
    question: '매매를 중간에 취소할 수 있나요?',
    answer: '거래상대방 일방이 지정된 시간 내에 코리아에셋투자증권으로 주식 또는 매매대금을 이체하지 않을 경우, 관리자에 의해 거래가 취소되며, 매매가 진행 중인 상태에서는 회원님께서 직접 중간에 취소하실 수는 없습니다.',
  },
  {
    index: 3,
    question: '매매가 취소되면 이미 입고된 주식 또는 입금된 매매대금은 어떻게 되나요?',
    answer: '매매대금의 경우, 매매가 취소되면 즉시 회원님의 등록된 계좌로 매매대금이 반환됩니다.',
  },
  {
    index: 4,
    question: '매매내역서를 어떻게 받을 수 있나요?',
    answer: '마이페이지_매매완료 화면에서 완료된 거래 목록을 확인하실 수 있으며, 각 완료된 거래 건별로 매매내역서를 PDF파일로 다운로드 하실 수 있습니다.',
  },
  {
    index: 5,
    question: '거래수수료는 얼마이며 누가 부담하는가요?',
    answer: '거래수수료는 매매가액의 (0.2%)이며 주식의 매도자만 부담합니다. 매도인은 매매가액에서 중개수수료와 증권거래세를 차감한 금액을 입금 받으시게 됩니다.',
  },
  {
    index: 6,
    question: '주식 매도와 관련하여 발생하는 세금은 어떻게 처리되나요?',
    answer: '증권거래세의 경우 당사가 원천징수 후 신고하게 되며, 소득세법에 따른 주식 양도소득세의 경우에는 회원님이 직접 신고, 납부하셔야 합니다.',
  },
];

const sell = [
  {
    index: 1,
    question: '"바로매수"란 무엇인가요?',
    answer: '주식을 매도할 회원이 당사 위탁계좌에 주식을 입고한 뒤, 매도조건을 지정해 주시면 매물검색시 "바로매수" 가능한 종목으로 표시됩니다. 거래 조건이 이미 지정되어 있기 때문에 별로의 네고(협의)는 필요하지 않습니다.',
  },
  {
    index: 2,
    question: '"바로매수" 신청 시 100% 매수 가능한가요?',
    answer: '매수 될 가능성이 매우 높기는 하나 여러 사유로 인해 실제 주식 매수를 100% 보장되지는 않습니다. 매수가 이루어지지 않을 경우, 입금 후 최장 1시간 이내에 입금해주신 매매대금을 반환해 드립니다.',
  },
  {
    index: 3,
    question: '"바로매수"시 매매대금은 언제까지 입금해야 하나요?',
    answer: '30분 이내에 매매대금을 지정한 가상계좌번호로 입금해주셔야 합니다. 30분 이내에 입금하지 않으실 경우 매수신청이 자동으로 취소됩니다.',
  },
  {
    index: 4,
    question: '"바로매수"시 주식은 언제 수령하나요?',
    answer: '입금확인 후 최대한 신속하게 매수한 주식을 이체해 드리며, 이체가 완료되면 SMS로 알려드립니다.',
  },
  {
    index: 5,
    question: '"바로매수"시 수수료가 있나요?',
    answer: '매수자에게는 별도의 수수료를 수취하지 않습니다.',
  },
];


const auth = [
  {
    index: 1,
    question: '회원가입은 어떻게 하나요?',
    answer: '카카오계정으로 로그인하거나 “이메일+비밀번호” 입력을 통해 간단하게 회원가입 가능합니다.',
  },
  {
    index: 2,
    question: '회원가입 후 문자인증이 왜 필요한가요?',
    answer: '네고스탁은 SMS과 카카오 알림톡을 통해 진행상태 등을 알려드립니다. 따라서, 휴대전화번호 1개당 1개 계정만 허용하며 계정에 사용할 휴대전화번호의 문자인증이 필요합니다.',
  },
  {
    index: 3,
    question: '휴대전화번호 문자인증을 이미 받았는데, 문자인증을 다시 하라고 합니다.',
    answer: '해당 전화번호로 다른 계정이 문자인증 받았을 경우에는 휴대전화번호 문자인증을 다시 받으셔야 합니다.',
  },
  {
    index: 4,
    question: '휴대전화번호 문자인증 시, “해당전화번호는 현재 잠겨 있습니다. **월 **일 **시에 잠금상태가 해제됩니다.”라는 메시지가 뜹니다.',
    answer: '문자인증이 되어 계정에 휴대전화번호가 등록된 경우, 등록 후 48시간 동안은 해당 문자인증이 중지되며 해당전화번호는 동 기간 동안 “잠금상태”가 됩니다.',
  },
  {
    index: 5,
    question: '공개ID는 무엇인가요?',
    answer: '공개ID는 계정에 부여된 014로 시작하는 11자리 고유한 숫자입니다.네고요청을 받고자 외부 사이트 등에 정보를 게시할 때,네고스탁 공개 ID를 사용함으로써 개인정보 유출에 대한 염려로부터 자유로워 질 수 있습니다.',
  },
  {
    index: 6,
    question: '계정 정보는 어떻게 변경할 수 있나요?',
    answers: [
      '카카오로그인 사용자의 경우 : 채팅창에 표시되는 닉네임과 휴대전화번호를 변경하실 수 있습니다.',
      '이메일 가입 회원의 경우 : 채팅창에 표시되는 닉네임, 휴대전화번호, 비밀번호를 변경하실 수 있습니다.',
    ],
  },
  {
    index: 7,
    question: '1인당 여러 계정을 사용할 수 있나요?',
    answer: '사용은 가능하나 휴대전화번호 1개당 1개의 계정만 허용됩니다. 또한 복수의 휴대전화번호로 복수의 계정을 사용하더라도 매매거래 시에는 실명확인이 이루어지고 거래내역에 대해서는 실명정보로 통합관리 됩니다.',
  },
  {
    index: 8,
    question: '증권 및 은행계좌정보, 매매계약정보는 언제 입력하게 되나요?',
    answer: '매매계약정보는 매매중개 요청 및 비상장주식중개약관 동의 이후에 SMS 또는 알림톡으로 보내드리는 url을 클릭하여 입력하거나 회원정보 화면에 입력화면으로 이동하여 입력하게 됩니다.',
  },
  {
    index: 9,
    question: '증권 및 은행계좌정보,매매계약정보 변경이 가능한가요?',
    answer: '원칙적으로 한번 등록한 정보는 변경할 수가 없습니다.다만, 정보에 오류가 발견될 경우 고객센터에서 재입력 요청 메시지를 보내드리며, 전용계좌를 개설 시에는 새로 입력되는 정보로 자동 변경됩니다.',
  },
  {
    index: 10,
    question: '탈퇴하고 싶으면 어떻게 해야 하나요?',
    answer: '탈퇴를 희망하실 경우 마이페이지_회원정보 화면 하단의 "탈퇴하기"버튼을 누르시면 됩니다. 다만, 금융거래내역은 관련법률 규정에 따라 5년 동안 별도로 보관됩니다.'
  },
];

export default {
  nego,
  trade,
  sell,
  auth,
};

import _ from 'underscore';
import {
  REQUEST_INFO__SELL_ASSIGNS_ORDER,
  RECEIVE_INFO__SELL_ASSIGNS_ORDER,
  FAILURE_INFO__SELL_ASSIGNS_ORDER,
  CHECK_FORM__SELL_ASSIGNS_ORDER,
  INIT_FORM__SELL_ASSIGNS_ORDER,
  INPUT_FORM__SELL_ASSIGNS_ORDER,
  POST_FORM__SELL_ASSIGNS_ORDER,
  DONE_FORM__SELL_ASSIGNS_ORDER,
  FAIL_FORM__SELL_ASSIGNS_ORDER,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  stockInfoUid: '',
  stockName: '',
  sellAssignKey: '',
  price: 0,
  quantity: 0,
  minimumOrderQuantity: 0,
  nonProBuyable: false,
  agreeForm: false,
  orderable: false,
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [REQUEST_INFO__SELL_ASSIGNS_ORDER] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_INFO__SELL_ASSIGNS_ORDER] (state, data) {
    state.status = 'RECEIVE';
    state.form.stockInfoUid = data.stockInfoUid;
    state.form.stockName = data.stockName;
    state.form.sellAssignKey = data.sellAssignKey;
    state.form.price = data.sellPrice;
    state.form.quantity = 0;
    state.form.minimumOrderQuantity = data.minimumOrderQuantity;
    state.form.nonProBuyable = data.nonProBuyable;
  },
  [FAILURE_INFO__SELL_ASSIGNS_ORDER] (state, error) {
    state.status = 'FAILURE';
    state.error = error;
  },
  [CHECK_FORM__SELL_ASSIGNS_ORDER] (state) {
    const { quantity, minimumOrderQuantity, agreeForm, orderable } = state.form;
    const error = {};
  
    if (!quantity) {
      error.quantity = '수량을 입력해 주세요.';
    }
    if (quantity < minimumOrderQuantity) {
      error.quantity = '매도자가 설정한 최소 거래수량보다 작습니다.';
    }
    // if (quantity > maximumOrderQuantity) {
    //   error.quantity = '매수 가능한 수량을 초과 하였습니다.';
    // }
    if (!agreeForm) {
      error.agreeForm = '유의사항을 확인해야합니다.';
    }
    if (!orderable) {
      error.orderable = '바로매수 이용시간이 아닙니다. (영업일: 오전 7:30 ~ 오후 2:00)';
    }

    state.error = { ...error };
  },
  [INIT_FORM__SELL_ASSIGNS_ORDER] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__SELL_ASSIGNS_ORDER] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__SELL_ASSIGNS_ORDER] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__SELL_ASSIGNS_ORDER] (state) {
    state.status = 'DONE';

    router.replace({ name: 'transactions_progress' });
  },
  [FAIL_FORM__SELL_ASSIGNS_ORDER] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  requestInfo({ dispatch, commit }, sellAssignKey) {
    commit(REQUEST_INFO__SELL_ASSIGNS_ORDER);

    api.get(`/sellassigns/info/${sellAssignKey}`).then(
      response => {
        commit(RECEIVE_INFO__SELL_ASSIGNS_ORDER, response.data);
        dispatch('checkOrder');
      },
      error => commit(FAILURE_INFO__SELL_ASSIGNS_ORDER, error.response.data),
    );
  },
  checkOrder({ commit }) {
    api.get('/sellassigns/order').then(
      response => commit(INPUT_FORM__SELL_ASSIGNS_ORDER, { key: 'orderable', value: response.data }),
      error => commit(FAIL_FORM__SELL_ASSIGNS_ORDER, error.response.data),
    );
  },
  checkForm({ commit, dispatch, getters }) {
     commit(CHECK_FORM__SELL_ASSIGNS_ORDER);

     if (!getters.invalidForm) {
       dispatch('submitForm');
     }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__SELL_ASSIGNS_ORDER);
    const { sellAssignKey, price, quantity } = state.form;
    const data = { sellAssignKey, price, quantity };

    api.post('/sellassigns/order', data).then(
      () => setTimeout(() => commit(DONE_FORM__SELL_ASSIGNS_ORDER), 2000),
      error => commit(FAIL_FORM__SELL_ASSIGNS_ORDER, error.response.data),
    );
  },


};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

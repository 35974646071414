<template>
  <div class="form-label-group mb-3">
    <select
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      :value="value"
      @input="$emit('input-form', parseInt($event.target.value, 10))">
      <option disabled value="0">선택하세요.</option>
      <option v-for="(el, index) in list" :value="el" :key="index">{{ el | bizdays }}</option>
    </select>
    <label :for="id" class="col-form-label">매매일시</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'SelectBizdays',
  props: {
    id: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
  },
};
</script>

import _ from 'underscore';
import {
  REQUEST_DATA__PUBLIC_INTENT,
  RECEIVE_DATA__PUBLIC_INTENT,
  FAILURE_DATA__PUBLIC_INTENT,
  INPUT_FORM__PUBLIC_INTENT,
  CHECK_FORM__PUBLIC_INTENT,
  POST_FORM__PUBLIC_INTENT,
  DONE_FORM__PUBLIC_INTENT,
  FAIL_FORM__PUBLIC_INTENT,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    dealKey: '',
    dealName: '',
    dealType: '', // 'placement' | 'helpstock'
    agreeForm: false,
    hopeQuantity: 1,
    hopePrice: 0,
    name: '',
    mobile: '',
    email: '',
  },
  error: {},
  status: 'INITIAL',
};

const getters = {
  enabledForm: state => _.every(_.omit(state.form, 'agreeForm')),
  invalidForm: state => _.keys(state.error).length > 0,
  loadingForm: state => state.status === 'POST',
  isDealTypePlacement: state => state.form.dealType === 'placement',
};

const mutations = {
  [INPUT_FORM__PUBLIC_INTENT] (state, { key, value }) {
    state.form[key] = value;
  },
  [CHECK_FORM__PUBLIC_INTENT] (state) {
    const {
      dealType, hopeQuantity, hopePrice,
      agreeForm, name, mobile, email,
    } = state.form;
    const invalid = {};

    if (dealType === 'placement') {
      if (!hopePrice) {
        invalid.hopePrice = '투자의향금액을 확인해주세요.';
      }
    }
    if (dealType === 'helpstock') {
      if (!hopeQuantity) {
        invalid.hopeQuantity = '수량을 확인해주세요.';
      }
      if (!hopePrice) {
        invalid.hopePrice = '가격을 확인해주세요.';
      }
    }
    if (!agreeForm) {
      invalid.agreeForm = '유의사항을 확인해주세요.';
    }
    if (!name) {
      invalid.name = '이름을 확인해주세요.';
    }
    if (!mobile) {
      invalid.mobile = '휴대전화번호를 확인해주세요.';
    }
    if (!email) {
      invalid.email = '이메일을 확인해주세요.';
    }

    state.error = { ...invalid };
  },
  [REQUEST_DATA__PUBLIC_INTENT] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_DATA__PUBLIC_INTENT] (state, data) {
    const { name: dealName } = data;
    state.form.dealName = dealName;
    state.status = 'RECEIVE';
  },
  [FAILURE_DATA__PUBLIC_INTENT] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
  [POST_FORM__PUBLIC_INTENT] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__PUBLIC_INTENT] (state) {
    state.status = 'DONE';
    router.replace({
      name: 'PUBLIC_INTENT_SUCCESS',
    });
  },
  [FAIL_FORM__PUBLIC_INTENT] (state, error) {
    state.status = 'FAIL';
    state.error = { ...error };
  },
};

const actions = {
  requestData({ commit, state }) {
    commit(REQUEST_DATA__PUBLIC_INTENT);
    const { dealKey } = state.form;

    api.get(`/public/intent/deal/${dealKey}`).then(
      reponse => commit(RECEIVE_DATA__PUBLIC_INTENT, reponse.data),
      error => commit(FAILURE_DATA__PUBLIC_INTENT, error.response.data),
    );
  },
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__PUBLIC_INTENT);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
  },
  submitForm({ commit, state }) {
    commit(POST_FORM__PUBLIC_INTENT);
    const props = [
      'dealKey',
      'hopeQuantity',
      'hopePrice',
      'name',
      'mobile',
      'email',
    ];
    const data = _.pick(state.form, props);

    api.post('/public/intent/deal', data).then(
      reponse => commit(DONE_FORM__PUBLIC_INTENT, reponse.data),
      error => commit(FAIL_FORM__PUBLIC_INTENT, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="help-intent">
    <NegoLinks group="group1"/>
    <div class="container">
      <p>당사가 보유 또는 취득예정인 투식으로 <br class="d-md-none">전문투자자 또는 투자성향진단결과 적합한 <br class="d-md-none">고객만 당사로부터 구매하실 수 있습니다.</p>
      <span v-if="loadingList" class="spinner-border spinner-border-sm text-primary ml-3" role="status"></span>
      <HelpIntentEvent
        v-if="eventStock"
        :userstock="eventStock"
        @open-company="() => openCompany(eventStock)"
        @order-event-sellassigns="() => orderEventSellAssigns(eventStock)"
      />
      <HelpIntentWarning />
      <div class="row">
        <div class="col-12 mt-3">
          <h6>바로매수 ({{ countSellAssign }})</h6>
        </div>
        <template v-if="markets['sellAssign'].length">
          <div
            class="col-12 col-lg-4 mb-3"
            v-for="(userstock, idx) in markets['sellAssign']"
            :key="`sellAssign-${idx}`"
          >
            <CardSellAssign
              :userstock="userstock"
              @open-company="() => openCompany(userstock)"
              @order-sellassigns="() => orderSellAssigns(userstock)"
              @regist-notice="() => handleRegistNotice(userstock)"
              @delete-notice="() => handleDeleteNotice(userstock)"
            />
          </div>
        </template>
        <div v-else class="col-12">
          <div class="alert alert-primary text-center"
          >바로매수 비상장주식을 준비하고 있습니다.</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-3">
          <h6>예약구매 ({{ countHelpStock }})</h6>
        </div>
        <template v-if="markets['helpStock'].length">
          <div
            class="col-12 col-lg-4 mb-3"
            v-for="(userstock, idx) in markets['helpStock']"
            :key="`sellAssign-${idx}`"
          >
            <CardHelpStock
              :userstock="userstock"
              @open-company="() => openCompany(userstock)"
              @make-help-intent="() => makeHelpIntent(userstock)"
              @regist-notice="() => handleRegistNotice(userstock)"
              @delete-notice="() => handleDeleteNotice(userstock)"
              @show-charger="() => showCharger(userstock)"
              @show-corporation="() => showCorporation(userstock)"
            />
          </div>
          <DealChargerModal
            :charger="charger"
            @hide-charger="() => hideCharger()"
          />
          <DealCorporationModal
            :corporation="corporation"
            @hide-corporation="() => hideCorporation()"
          />
        </template>
        <div v-else class="col-12">
          <div class="alert alert-primary text-center"
          >예약구매 비상장주식을 준비하고 있습니다.</div>
        </div>
      </div>
      <template v-if="item && !item.openNoticeKey">
        <AppModalSimple
          title="투자기회 알림요청"
          :content="`<mark>'${item.title}'</mark>에 투자하실 수 있는 기회가 생기는 경우 알림메시지를 보내드립니다. '투자기회 알림요청'을 신청하시겠어요?`"
        >
          <div class="row no-gutters w-100">
            <div class="col pr-1">
              <button class="btn btn-outline-dark btn-block" @click="registNotice">예</button>
            </div>
            <div class="col pl-1">
              <button class="btn btn-outline-dark btn-block" @click="clearItem">아니오</button>
            </div>
          </div>
        </AppModalSimple>
      </template>
      <template v-if="item && item.openNoticeKey">
        <AppModalSimple
          title="투자기회 알림요청 취소"
          :content="`<mark>'${item.title}'</mark>에 투자하실 수 있는 기회가 생기는 경우 받을 수 있는 알림을 취소하시겠습니까?`"
        >
          <div class="row no-gutters w-100">
            <div class="col pr-1">
              <button class="btn btn-outline-dark btn-block" @click="deleteNotice">예</button>
            </div>
            <div class="col pl-1">
              <button class="btn btn-outline-dark btn-block" @click="clearItem">아니오</button>
            </div>
          </div>
        </AppModalSimple>
      </template>
      <HelpIntentNotice id="help-intent-notice"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import CardSellAssign from '@/components/helpIntent/CardSellAssign.vue';
import CardHelpStock from '@/components/helpIntent/CardHelpStock.vue';
import HelpIntentNotice from '@/components/helpIntent/HelpIntentNotice.vue';
import HelpIntentEvent from '@/components/helpIntent/HelpIntentEvent.vue';
import HelpIntentWarning from '@/components/helpIntent/HelpIntentWarning.vue';
import AppModalSimple from '@/components/app/AppModalSimple.vue';
import DealChargerModal from '@/components/helpIntent/DealChargerModal.vue';
import DealCorporationModal from '@/components/helpIntent/DealCorporationModal.vue';
import {
  SELECT__HELP_INTENT,
  DESELECT__HELP_INTENT,
  SHOW_CHARGER__HELP_INTENT,
  HIDE_CHARGER__HELP_INTENT,
  SHOW_CORPORATION__HELP_INTENT,
  HIDE_CORPORATION__HELP_INTENT,
  SET_FORM__HELP_INTENT_MAKER,
} from '@/store/mutation-types';

export default {
  name: 'HelpIntent',
  components: {
    NegoLinks,
    CardSellAssign,
    CardHelpStock,
    HelpIntentNotice,
    HelpIntentEvent,
    HelpIntentWarning,
    AppModalSimple,
    DealChargerModal,
    DealCorporationModal,
  },
  computed: {
    ...mapState('helpIntent', [
      'markets',
      'error',
      'item',
      'charger',
      'corporation',
    ]),
    ...mapGetters('helpIntent', [
      'eventStock',
      'countSellAssign',
      'countHelpStock',
      'loadingList',
    ]),
    ...mapGetters('userInfomation', [
      'isAuthenticated',
    ]),
  },
  methods: {
    ...mapMutations('helpIntent', {
      selectItem: SELECT__HELP_INTENT,
      clearItem: DESELECT__HELP_INTENT,
      showCharger: SHOW_CHARGER__HELP_INTENT,
      hideCharger: HIDE_CHARGER__HELP_INTENT,
      showCorporation: SHOW_CORPORATION__HELP_INTENT,
      hideCorporation: HIDE_CORPORATION__HELP_INTENT,
    }),
    ...mapActions('helpIntent', [
      'requestListPublic',
      'requestList',
      'registNotice',
      'deleteNotice',
    ]),
    ...mapMutations('helpIntentMaker', {
      setForm: SET_FORM__HELP_INTENT_MAKER,
    }),
    openCompany(userstock) {
      const { companyCode } = userstock;

      this.$router.push({
        name: '__COMPANY_VIEW__',
        params: { companyCode },
      });
    },
    orderSellAssigns({ sellAssignKey }) {
      this.$router.push({
        name: 'sell_assigns_order',
        query: { sellAssignKey },
      });
    },
    orderEventSellAssigns({ sellAssignKey }) {
      this.$router.push({
        name: 'sell_assigns_order_event',
        query: { sellAssignKey },
      });
    },
    makeHelpIntent(userstock) {
      this.setForm(userstock);
      this.$router.push({
        name: '__HELP_INTENT_MAKER__',
        query: { stockInfoUid: userstock.stockInfoUid },
      });
    },
    handleRegistNotice(userstock) {
      if (!this.isAuthenticated) {
        this.linkSignIn();
      } else {
        this.selectItem(userstock);
      }
    },
    handleDeleteNotice(userstock) {
      if (!this.isAuthenticated) {
        this.linkSignIn();
      } else {
        this.selectItem(userstock);
      }
    },
    linkSignIn() {
      this.$router.push({
        name: 'sign_in',
        query: { redirect_path: '/help-intent' },
      });
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.requestList();
    } else {
      this.requestListPublic();
    }
  },
};
</script>

<style lang="scss" scoped>
.help-intent {
  min-height: 95vh;
}
</style>

import qs from 'qs';
import {
  INPUT_FORM__SIGN_IN,
  POST_FORM__SIGN_IN,
  DONE_FORM__SIGN_IN,
  FAIL_FORM__SIGN_IN,
  GET_AUTO__SIGN_IN,
  SET_AUTO__SIGN_IN,
} from '@/store/mutation-types';
import api from '@/api/axios';

const AUTO_SAVE = 'AUTO_SAVE';
const SAVED_EMAIL = 'SAVED_EMAIL';

const state = {
  form: {
    j_username: '',
    j_password: '',
    autoSave: false,
  },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  enabledForm: state => !!state.form.j_username && !!state.form.j_password,
};

const mutations = {
  [GET_AUTO__SIGN_IN] (state) {
    const autoSave = JSON.parse(localStorage.getItem(AUTO_SAVE));
    const savedEmail = localStorage.getItem(SAVED_EMAIL);
    state.form.j_username = autoSave ? savedEmail : '';
    state.form.autoSave = autoSave;
  },
  [SET_AUTO__SIGN_IN] (state) {
    localStorage.setItem(AUTO_SAVE, state.form.autoSave);
    if (state.form.autoSave) {
      localStorage.setItem(SAVED_EMAIL, state.form.j_username);
    } else {
      localStorage.removeItem(SAVED_EMAIL);
    }
  },
  [INPUT_FORM__SIGN_IN] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__SIGN_IN] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__SIGN_IN] (state) {
    state.form.j_username = '';
    state.form.j_password = '';
    state.error = {};
    state.status = 'DONE';
  },
  [FAIL_FORM__SIGN_IN] (state, error) {
    if (!error.auth) {
      state.error = {
        j_username: '이메일을 확인해주세요.',
        j_password: '비밀번호를 확인해주세요.',
        fail: error.fail,
      };
    }
    state.status = 'FAIL';
  },
};

const actions = {
  submitForm({ dispatch, state, commit }) {
    commit(POST_FORM__SIGN_IN);

    const { j_username, j_password } = state.form;
    api.post('/auth/signin', qs.stringify({ j_username, j_password })).then(
      response => {
        commit(SET_AUTO__SIGN_IN);
        commit(DONE_FORM__SIGN_IN, response.data);
        dispatch('userInfomation/requestUser', null, { root: true });
      },
      error => commit(FAIL_FORM__SIGN_IN, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import {
  REQUEST_LIST__LP_JOIN,
  RECEIVE_LIST__LP_JOIN,
  FAILURE_LIST__LP_JOIN,
  // SELECT__LP_JOIN,
  // CLEAR__LP_JOIN,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
  params: {},
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST_LIST__LP_JOIN] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__LP_JOIN] (state, { contents, ...params }) {
    state.list = [...contents];
    state.params = { ...params };
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__LP_JOIN] (state) {
    state.status = 'FAILURE';
  },
  // [SELECT__LP_JOIN] (state, item) {
  //   state.item = { ...item };
  // },
  // [CLEAR__LP_JOIN] (state) {
  //   state.item = null;
  // },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST_LIST__LP_JOIN);

    api.get('/deals/pro/tx').then(
      response => commit(RECEIVE_LIST__LP_JOIN, response.data),
      error => commit(FAILURE_LIST__LP_JOIN, error),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-label-group mb-3"},[_c('input',{staticClass:"form-control",class:{
      'is-valid': _vm.status === _vm.FormStatus.DONE,
      'is-invalid': _vm.status === _vm.FormStatus.FAIL,
    },attrs:{"type":"number","id":_vm.id,"placeholder":_vm.inputLabel,"disabled":!_vm.enabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input-form', $event.target.value)}}}),_c('label',{attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.inputLabel))]),(_vm.status === _vm.FormStatus.DONE || _vm.status === _vm.FormStatus.FAIL)?_c('small',{class:{
      'valid-feedback': _vm.status === _vm.FormStatus.DONE,
      'invalid-feedback': _vm.status === _vm.FormStatus.FAIL,
    }},[_vm._v(_vm._s(_vm.feedback))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
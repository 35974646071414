<template>
  <div class="container my-5">
    <div class="border p-3">
      <h6 class="title">매매진행 결제 과정</h6>
      <ul class="text pl-3">
        <li>거래합의(체결) 직후 통보하는 당사의 계좌로 각각 합의한 결제이행 제한시간 내에 매수대금과 대상주식을 이체합니다.</li>
        <li>매수대금을 먼저 입금하는 것을 권해드리나, 주식을 먼저 이체하는 것도 가능합니다.</li>
        <li>매수대금과 대상주식을 당사가 모두 수령하면 결제완료 안내 문자와 함께 네고스탁을 통한 첫 거래인 회원에게는 실명정보 입력을 요청합니다.</li>
        <li>당사에서 실명정보를 확인한 후 주식과 매도대금을 즉시 지급합니다.</li>
      </ul>
      <picture class="d-lg-none mb-4">
        <source srcset="./img/progress_xs.webp" type="image/webp">
        <img src="./img/progress_xs.png" class="img-fluid" alt="네고진행 과정 안내 이미지: 모바일">
      </picture>
      <picture class="d-none d-lg-block mb-4">
        <source srcset="./img/progress_lg.webp" type="image/webp">
        <img src="./img/progress_lg.png" class="img-fluid" alt="네고진행 과정 안내 이미지: 웹">
      </picture>
      <h6 class="title">일방, 쌍방에 의한 결제 미이행 시</h6>
      <ul class="text pl-3">
        <li>일방이라도 제한시간 이내에 <u>결제를 이행하지 않을 경우 체결된 거래는 자동으로 취소</u>됩니다.</li>
        <li>매도회원이 대상주식 미결제할 경우, 입금된 매수대금은 <u>결제이행 제한시간 후 20분 이내에 반환</u>됩니다.</li>
        <li>매수회원이 매수대금을 미결제할 경우, 입고된 주식은 담당자 확인 후 즉시 반환됩니다.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressNotice',
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, -1.3px);
}
</style>

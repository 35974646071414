import {
  REQUEST_ROOM__INTENTS_MESSAGES,
  RECEIVE_ROOM__INTENTS_MESSAGES,
  FAILURE_ROOM__INTENTS_MESSAGES,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  roomInfo: {
    publicId: '', // 대화상대방구분
    userType: '',
    otherType: '',
    otherName: '', // 상대방표시이름
    hopeQuantity: 0,
    hopePrice: 0,
    expiration: 0,
  },
  room: {
    messages: [], // 메시지 목록
    stock: '', // 종목명(종목코드)
    creator: '', // 생성자 공개ID
    receiver: '', // 수신자 공개ID
  },
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingData: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST_ROOM__INTENTS_MESSAGES] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE_ROOM__INTENTS_MESSAGES] (state, { room, ...roomInfo }) {
    state.roomInfo = {
      publicId: roomInfo.sender ? roomInfo.creatorPublicId : roomInfo.receiverPublicId,
      userType: roomInfo.sender ? 'creator' : 'receiver',
      otherType: !roomInfo.sender ? 'creator' : 'receiver',
      otherName: !roomInfo.sender ? roomInfo.creatorNickname : roomInfo.receiverNickname,
      hopeQuantity: roomInfo.hopeQuantity,
      hopePrice: roomInfo.hopePrice,
      expiration: roomInfo.expiration,
    };
    state.room = { ...room };
    state.error = {};
    state.status = 'RECEIVE';
  },
  [FAILURE_ROOM__INTENTS_MESSAGES] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestRoom({ commit }, { intentKey }) {
    commit(REQUEST_ROOM__INTENTS_MESSAGES);

    api.get(`/messages/${intentKey}`).then(
      response => commit(RECEIVE_ROOM__INTENTS_MESSAGES, response.data),
      error => commit(FAILURE_ROOM__INTENTS_MESSAGES, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="app-meta-container" :class="classBg">
    <div class="container-lg">
      <div class="row justify-content-center">
        <div class="bg-white inner-container" :class="classCol">
          <router-view></router-view>
          <div class="d-flex justify-content-end py-2" v-if="showBack">
            <button
              class="btn btn-light btn-sm text-white px-4"
              @click="routeBack"
            >이전으로</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMetaContainer',
  computed: {
    meta() {
      const { meta } = this.$route;
      return meta;
    },
    classCol() {
      return (this.meta && this.meta.col) ? this.meta.col : 'col-12';
    },
    classBg() {
      return (this.meta && this.meta.bg) ? `bg-${this.meta.bg}` : 'bg-white';
    },
    showBack() {
      return (this.meta && this.meta.back) ? !!this.meta.back : false;
    },
  },
  methods: {
    routeBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-meta-container {
  min-height: 70vh;
}

.inner-container {
  min-height: 95vh;

  @media (min-width: 768px) {
    min-height: unset;
  }
}
</style>


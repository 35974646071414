<template>
  <div>
    <NegoLinks group="group2"/>
    <div class="container">
      <div class="row justify-content-center mb-5">
        <div class="col-12 col-lg-6">
          <p class="text-center d-none d-lg-block">
            게시판 검색 또는 직접 연락처를 입력하여 네고요청을 보내실 수 있습니다.
          </p>
          <NegoButton
            label="지인과 거래하기"
            @click-button="$refs.negoModal.openModal()"
          />
        </div>
      </div>
      <div class="row" v-if="loadingList">
        <div class="col-md-6 col-xl-4 mb-4" v-for="(intent, idx) in 6" :key="idx">
          <NegoEmptyCard/>
        </div>
      </div>
      <template v-else>
        <div class="row" v-if="!list.length">
          <div class="col-12">
            <div
              class="alert alert-primary text-center"
              role="alert"
            >매매협의 진행 중인 건이 없습니다.</div>
          </div>
        </div>
        <template v-else>
          <div class="row justify-content-center">
            <div class="col-md-6 col-xl-4 mb-4" v-for="(intent, idx) in list" :key="idx">
              <IntentsCard
                :intent="intent"
                :user="user"
                @open-chat="openChat"
              />
            </div>
          </div>
          <NegoPagination
            v-if="params"
            :params="params"
            @select-page="routePage"
          />
        </template>
      </template>
    </div>
    <IntentsNotice/>
    <NegoModal ref="negoModal"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import NegoButton from '@/components/nego/NegoButton.vue';
import NegoPagination from '@/components/nego/NegoPagination.vue';
import NegoEmptyCard from '@/components/nego/NegoEmptyCard.vue';
import IntentsCard from '@/components/intents/IntentsCard.vue';
import IntentsNotice from '@/components/intents/IntentsNotice.vue';
import NegoModal from '@/components/nego/NegoModal.vue';
import { INITIAL_LIST__INTENTS } from '@/store/mutation-types';

export default {
  name: 'InventView',
  components: {
    NegoLinks,
    NegoButton,
    NegoPagination,
    NegoEmptyCard,
    IntentsCard,
    IntentsNotice,
    NegoModal,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('intents', [
      'list',
      'params',
      'error',
    ]),
    ...mapGetters('intents', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapMutations('intents', {
      'initialList': INITIAL_LIST__INTENTS,
    }),
    ...mapActions('intents', [
      'requestList',
    ]),
    request() {
      const page = Number.parseInt(this.$route.query.page) || 1;
      this.requestList({ page });
    },
    routePage(page) {
      this.$router.push({ name: 'intents', query: { page }});
      this.stopCycle();
      this.startCycle();
    },
    startCycle() {
      this.request();
      this._intervId = setInterval(this.request, 30000);
    },
    stopCycle() {
      if (this._intervId) {
        clearInterval(this._intervId);
      }
    },
    openChat() {
      setTimeout(this.request, 5000);
    },
  },
  mounted() {
    if (this.user && !this.user.preUser) {
      this.startCycle();
    }
  },
  destroyed() {
    this.stopCycle();
    this.initialList();
  },
};
</script>

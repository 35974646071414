<template>
  <div class="row py-3 transactions-card">
    <div class="col-12 col-lg-10">
      <h6 class="mb-3">{{ transaction.stockName }} <small>{{ transaction.stockCode }}</small></h6>
      <div class="d-flex flex-column flex-lg-row justify-content-lg-between">
        <div class="d-flex flex-row flex-lg-column justify-content-between">
          <small class="text-light">구분</small>
          <h6 class="mb-0">{{ transaction.seller ? '매도' : '매수' }}</h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">거래일자</small>
          <h6 class="mb-0">{{ transaction.expiration | date_short }}</h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">가격</small>
          <h6 class="mb-0">{{ transaction.offerPrice | currency }} <small>원</small></h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">수량</small>
          <h6 class="mb-0">{{ transaction.offerQuantity | currency }} <small>주</small></h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">매매금액</small>
          <h6 class="mb-0">{{ transaction.offerPrice * transaction.offerQuantity | currency }} <small>원</small></h6>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2">
      <div class="row">
        <div class="col-6 col-md-12 offset-6 offset-md-0">
          <a
            v-if="transaction.seller"
            class="btn btn-outline-primary btn-block btn-sm mt-2 mt-lg-0"
            :href="`${FintechCommApi}/public/pdf/report/${transaction.sellerKey}/${transaction.transactionKey}/매도내역서`"
            download="매도내역서.pdf"
          >매도내역서</a>
          <a
            v-else
            class="btn btn-outline-primary btn-block btn-sm mt-2 mt-lg-0"
            :href="`${FintechCommApi}/public/pdf/report/${transaction.buyerKey}/${transaction.transactionKey}/매수내역서`"
            download="매수내역서.pdf"
          >매수내역서</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FintechCommApi } from '@/utils/constants';

export default {
  name: 'TransactionsFinishedCard',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      FintechCommApi,
    };
  },
};
</script>

<style lang="scss" scoped>
.transactions-card {
  border-top: 2px solid $primary;

  &:last-child {
    border-bottom: 2px solid $primary;
  }
}
</style>

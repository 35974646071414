<template>
  <DealModalLarge
    :title="$route.query.dealName"
    @close-modal="closeModal"
  >
    <div>
      <h5>{{ selectedItem.title }}</h5>
      <small>관리자 | {{ selectedItem.registTime | date }}</small>
      <p class="contents pt-3">{{ selectedItem.contents }}</p>
    </div>
    <div class="bg-comment mx-n3 p-3">
      <div class="">
        <h6>댓글 {{ countedList }}</h6>
        <CommentForm
          @submit-form="submitForm"
        />
      </div>
      <div class="mt-3">
        <div v-if="list.data.length === 0">
          [작성된 댓글이 없습니다.]
        </div>
        <div v-else>
          <template v-for="(el, index) in list.data">
            <div
              :key="index"
              class="pt-2 border-top"
              :class="!el.reply ? 'ml-3' : 'ml-4 pl-2'"
            >
              <Dropdowns
                :id="index"
                v-if="el.myComment"
                class="float-right"
                @remove-item="removeItem({ dealCommentKey: el.dealCommentKey })"
              />
              <h6 class="alert-heading">
                {{ el.writer }} <small>{{ el.registTime | date }}</small>
              </h6>
              <p class="comment mb-2">{{ el.comment }}</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </DealModalLarge>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import DealModalLarge from '@/components/deals/DealModalLarge.vue';
import Dropdowns from '@/components/deals/Dropdowns.vue';
import CommentForm from '@/components/deals/CommentForm.vue';
import {
  CLEAR__DEALS_CONTENTS,
  INITIAL__DEALS_CONTENTS_COMMENTS,
  INPUT__DEALS_CONTENTS_COMMENTS,
} from '@/store/mutation-types';

export default {
  name: 'DealsContentsComments',
  components: {
    DealModalLarge,
    Dropdowns,
    CommentForm,
  },
  computed: {
    ...mapGetters('dealsContents', [
      'selectedItem',
    ]),
    ...mapState('dealsContentsComments', [
      'list',
      'form',
    ]),
    ...mapGetters('dealsContentsComments', [
      'countedList',
      'loadingList',
    ]),
  },
  methods: {
    ...mapMutations('dealsContents', {
      'clearItem': CLEAR__DEALS_CONTENTS,
    }),
    ...mapMutations('dealsContentsComments', {
      'clearList': INITIAL__DEALS_CONTENTS_COMMENTS,
      'inputForm': INPUT__DEALS_CONTENTS_COMMENTS,
    }),
    ...mapActions('dealsContentsComments', [
      'requestList',
      'submitForm',
      'removeItem',
    ]),
    closeModal() {
      this.clearItem();
      this.clearList();
    },
  },
  mounted() {
    const { dealContentsKey } = this.selectedItem;
    if (dealContentsKey) {
      this.requestList({ dealContentsKey });
    }
  },
};
</script>

<style scoped>
.bg-comment {
  background: #f4f3f2;
}
.contents,
.comment {
  white-space: pre-line;
}
</style>

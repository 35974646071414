import {
  REQUEST_INTENT__NEGO_SAMPLE,
  RECEIVE_INTENT__NEGO_SAMPLE,
  FAILURE_INTENT__NEGO_SAMPLE,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  intent: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingData: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST_INTENT__NEGO_SAMPLE] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE_INTENT__NEGO_SAMPLE] (state, intent) {
    state.intent = { ...intent };
    state.status = 'RECEIVE';
  },
  [FAILURE_INTENT__NEGO_SAMPLE] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestIntent({ commit }, { intentKey }) {
    commit(REQUEST_INTENT__NEGO_SAMPLE);

    api.get(`/public/previews/${intentKey}`).then(
      response => commit(RECEIVE_INTENT__NEGO_SAMPLE, response.data),
      error => commit(FAILURE_INTENT__NEGO_SAMPLE, error),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

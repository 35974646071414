<template>
  <div class="news-letter pt-3">
    <h5 class="text-center my-4">{{ form['title'] }}</h5>
    <div class="text-right">
      <small class="text-light">일자: {{ form['registTime'] | date }}</small>
    </div>
    <p class="mt-3" v-html="form['contentsHtml']"></p>
    <div>
      <NewsletterFiles
        mode="DOWN"
        :files="form['files']"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NewsletterFiles from '@/components/marketing/NewsletterFiles.vue';

export default {
  name: 'Newsletter',
  components: {
    NewsletterFiles,
  },
  computed: {
    ...mapState('newsletter', [
      'form',
      'error',
    ]),
    ...mapGetters('newsletter', [
      'loadingData',
    ]),
  },
  methods: {
    ...mapActions('newsletter', [
      'requestData',
    ]),
  },
  mounted() {
    const { marketingAdKey } = this.$route.params;
    if (marketingAdKey) {
      this.requestData({ marketingAdKey });
    }
  },
};
</script>

<style lang="scss" scoped>
.news-letter {
  min-height: 95vh;
}
</style>

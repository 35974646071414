import _ from 'underscore';
import {
  REQUEST_DATA__INVEST_MEETING,
  RECEIVE_DATA__INVEST_MEETING,
  FAILURE_DATA__INVEST_MEETING,
  INPUT_FORM__INVEST_MEETING,
  SELECT_FORM__INVEST_MEETING,
  CHECK_FORM__INVEST_MEETING,
  POST_FORM__INVEST_MEETING,
  DONE_FORM__INVEST_MEETING,
  FAIL_FORM__INVEST_MEETING,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  data: {
    investorMeetingKey: '',
    placementInvestorKey: '',
    dealName: '',
    investorName: '',
    investorRegistration: '',
    shareNumber: 0,
    perShareAmount: 0,
    investorMobile: '',
    fileName: '',
    fileSize: 0,
    signStatus: '',
    voted: false,
  },
  form: {
    mobileForSign: '',
  },
  votingData: [],
  invalid: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
  showedAlert: state => state.data.signStatus === 'SIGN_ASK'
    || state.data.signStatus === 'SIGN_FIN'
    || state.data.signStatus === 'SIGN_EXPIRE'
    || state.data.signStatus === 'BROKEN',
  enabledForm: state => _.every(state.votingData, vote => vote),
  loadingForm: state => state.status === 'REQUEST' || state.status === 'POST',
  invalidForm: state => _.keys(state.invalid).length > 0,
};

const mutations = {
  [REQUEST_DATA__INVEST_MEETING] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_DATA__INVEST_MEETING] (state, { votingCount, votingData, ...data }) {
    state.data = data;
    state.votingData = votingData
      ? votingData.split(',')
      : new Array(votingCount).fill('');
    state.status = 'RECEIVE';
  },
  [FAILURE_DATA__INVEST_MEETING] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
  [INPUT_FORM__INVEST_MEETING] (state, { key, value }) {
    state.form[key] = value;
  },
  [SELECT_FORM__INVEST_MEETING] (state, { index, value }) {
    const { votingData } = state;
    state.votingData = [
      ...votingData.slice(0, index),
      value,
      ...votingData.slice(index + 1),
    ];
  },
  [CHECK_FORM__INVEST_MEETING] (state) {
    const { mobileForSign: mobile } = state.form;
    const { kakao, mobileForSign } = state.error;
    const invalid = {};

    if (kakao || mobileForSign) {
      if (!mobile) {
        invalid.mobileForSign = '전화번호를 확인해주세요.';
      }
      if (mobile.startsWith('010') && mobile.length !== 11) {
        invalid.mobileForSign = '전화번호를 확인해주세요..';
      } else if (!mobile.startsWith('010') && mobile.length !== 10) {
        invalid.mobileForSign = '전화번호를 확인해주세요...';
      }
    }

    state.invalid = { ...invalid };
  },
  [POST_FORM__INVEST_MEETING] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__INVEST_MEETING] (state) {
    state.status = 'DONE';

    router.replace({
      name: '__DEALS_LIBRARY__',
      query: router.history.current.query,
    });
  },
  [FAIL_FORM__INVEST_MEETING] (state, error) {
    state.status = 'FAIL';
    state.error = { ...error };
  },
};

const actions = {
  requestData({ commit }, { investorMeetingKey }) {
    commit(REQUEST_DATA__INVEST_MEETING);

    api.get(`/meeting/${investorMeetingKey}`).then(
      reponse => commit(RECEIVE_DATA__INVEST_MEETING, reponse.data),
      error => commit(FAILURE_DATA__INVEST_MEETING, error.response.data),
    );
  },
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__INVEST_MEETING);

    if (!getters.invalidForm) {
      dispatch('postForm');
    }
  },
  postForm({ commit, state }) {
    commit(POST_FORM__INVEST_MEETING);

    const {
      investorMeetingKey,
      placementInvestorKey,
      signStatus,
    } = state.data;
    const { mobileForSign: mobile } = state.form;
    const { kakao, mobileForSign } = state.error;

    const data = {
      investorMeetingKey,
      placementInvestorKey,
      votingData: state.votingData.toString(),
    };
    if (signStatus === 'SIGN_EXPIRE' || kakao || mobileForSign) {
      _.extend(data, { mobileForSign: mobile });
    }

    api.post('/meeting/vote', data).then(
      reponse => commit(DONE_FORM__INVEST_MEETING, reponse.data),
      error => setTimeout(() => {
        commit(FAIL_FORM__INVEST_MEETING, error.response.data);
      }, 1000)
    );
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

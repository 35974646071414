<template>
  <div class="container my-5">
    <div class="border p-3">
      <h6 class="title">매수내역서 및 매도내역서</h6>
      <ul class="text mb-5 pl-3">
        <li>매매가 완료되면, 매수인 경우에는 매수내역서를 매도인 경우에는 매도내역서를 다운로드 받으실 수 있습니다.<br>
          <a href="/files/sell_report.pdf" download="매도내역서 견본.pdf" class="text-dark">
            <IconFileEarmarkText class="icon-md"/> 매도내역서 견본.pdf
          </a> /
          <a href="/files/buy_report.pdf" download="매수내역서 견본.pdf" class="text-dark">
            <IconFileEarmarkText class="icon-md"/> 매수내역서 견본.pdf
          </a>
        </li>
      </ul>
      <h6 class="title">비상장주식거래 세금 신고 안내</h6>
      <ul class="text mb-3 pl-3">
        <li>비상장주식을 양도(매도) 하는 경우 양도한 달이 속하는 반기의 말일에서부서 2개월 이내에 양도소득세를 신고 및 납부하여야 합니다. (신고기한 예시: 1 ~ 6월 중 양도 시 8월 말, 7 ~ 12월 양도 시 다음해 2월 말이 신고기한임)</li>
        <li>본 페이지 제공하는 매도확인서 정보를 이용하여 국세청 홈택스를 통해 직접 신고 하실 수 있습니다.<br>
          <a href="/files/hometax_guide.pdf" download="국세청홈택스 신고방법 안내.pdf" class="text-dark">
            <IconFileEarmarkText class="icon-md"/> 국세청홈택스 신고방법 안내.pdf
          </a>
        </li>
        <li>증권거래세의 경우에는 당사에서 원천징수하여 납부하므로 별도 신고하실 필요가 없습니다.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconFileEarmarkText from '@/components/icons/IconFileEarmarkText.vue';

export default {
  name: 'TransactionsNotice',
  components: {
    IconFileEarmarkText,
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font2(700, 1.6, -1.1px, $secondary);
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}
.text {
  @include nego-font(1rem, 300, 1.6, .3px);
}
</style>

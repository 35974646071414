import _ from 'underscore';
import {
  REQUEST_INTENT__INTENTS_TRNAS,
  RECEIVE_INTENT__INTENTS_TRNAS,
  FAILURE_INTENT__INTENTS_TRNAS,
  CHECK_FORM__INTENTS_TRANS,
  INPUT_FORM__INTENTS_TRANS,
  TRANS_FORM__INTENTS_TRANS,
  DONE_FORM__INTENTS_TRANS,
  FAIL_FORM__INTENTS_TRANS,
  REQUEST_BIZDAYS__INTENTS_TRANS,
  REVEIVE_BIZDAYS__INTENTS_TRANS,
  FAILURE_BIZDAYS__INTENTS_TRANS,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  intent: {
    stockName: '',
    stockCode: '',
    buyer: false,
    seller: false,
  },
  form: {
    intentKey: '',
    quantity: 0,
    price: 0,
    expiration: 0,
    totalPrice: 0,
    toStatus: '',
  },
  transType: '',
  bizdays: [],
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST' || state.status === 'REQUEST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [REQUEST_INTENT__INTENTS_TRNAS] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_INTENT__INTENTS_TRNAS] (state, { intent, transType, toStatus }) {
    state.intent.stockName = intent.stockName;
    state.intent.stockCode = intent.stockCode;
    state.intent.buyer = intent.buyer;
    state.intent.seller = intent.seller;
    state.form.intentKey = intent.intentKey;
    state.form.quantity = intent.hopeQuantity;
    state.form.price = intent.hopePrice;
    state.form.expiration = intent.expiration || '';
    state.form.totalPrice = intent.hopeQuantity * intent.hopePrice;
    state.form.toStatus = toStatus;
    state.transType = transType;
    state.status = 'RECEIVE';
  },
  [FAILURE_INTENT__INTENTS_TRNAS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
  [INPUT_FORM__INTENTS_TRANS] (state, { key, value }) {
    state.form[key] = value;

    if (key === 'quantity' || key === 'price') {
      state.form['totalPrice'] = state.form['quantity'] * state.form['price'];
    }
  },
  [CHECK_FORM__INTENTS_TRANS] (state) {
    const { quantity, price, expiration, totalPrice } = state.form;
    const error = {};
  
    if (!quantity) {
      error.quantity = '수량을 입력해 주세요.';
    }
    if (!price) {
      error.price = '가격을 입력해 주세요.';
    }
    if (!expiration) {
      error.expiration = '유효기간을 입력해 주세요.';
    }
    if (totalPrice < 1000) {
      error.totalPrice = '거래금액은 1천원 이상 가능합니다.';
    }

    state.error = { ...error };
  },
  [TRANS_FORM__INTENTS_TRANS] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__INTENTS_TRANS] (state) {
    state.error = {};
    state.status = 'DONE';

    router.replace({ name: 'intents' });
  },
  [FAIL_FORM__INTENTS_TRANS] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
  [REQUEST_BIZDAYS__INTENTS_TRANS] (state) {
    state.status = 'REQUEST';
  },
  [REVEIVE_BIZDAYS__INTENTS_TRANS] (state, data) {
     state.bizdays = [...data];
     state.form.expiration = 0;
     state.status = 'RECEIVE';
  },
  [FAILURE_BIZDAYS__INTENTS_TRANS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
 },
};

const actions = {
  setIntent({ commit }, { intentKey, transType, toStatus }) {
    commit(REQUEST_INTENT__INTENTS_TRNAS);

    api.get(`/intents/${intentKey}`).then(
      response => commit(RECEIVE_INTENT__INTENTS_TRNAS, { intent: response.data, transType, toStatus }),
      error => commit(FAILURE_INTENT__INTENTS_TRNAS, error.response.data),
    );
  },
  // 취소, 거절, 수락
  transForm({ state, commit }) {
    commit(TRANS_FORM__INTENTS_TRANS);

    const { intentKey, toStatus } = state.form;
    api.post('/intents/trans', { intentKey, toStatus }).then(
      response => commit(DONE_FORM__INTENTS_TRANS, response.data),
      error => commit(FAIL_FORM__INTENTS_TRANS, error.response.data),
    );
  },
  // 삭제
  deleteForm({ state, commit }) {
    commit(TRANS_FORM__INTENTS_TRANS);

    const { intentKey } = state.form;
    api.delete(`/intents/${intentKey}`).then(
      response => commit(DONE_FORM__INTENTS_TRANS, response.data),
      error => commit(FAIL_FORM__INTENTS_TRANS, error.response.data),
    );
  },
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__INTENTS_TRANS);

    if (!getters.invalidForm) {
      dispatch('offersForm');
    }
  },
  // 최종제안
  offersForm({ state, commit }) {
    commit(TRANS_FORM__INTENTS_TRANS);

    const { intentKey, quantity, price, expiration } = state.form;
    api.post('/intents/offers', { intentKey, quantity, price, expiration }).then(
      response => commit(DONE_FORM__INTENTS_TRANS, response.data),
      error => commit(FAIL_FORM__INTENTS_TRANS, error.response.data),
    );
  },
  requestBizdays({ commit }) {
    commit(REQUEST_BIZDAYS__INTENTS_TRANS);

    api.get('/bizdays').then(
      response => commit(REVEIVE_BIZDAYS__INTENTS_TRANS, response.data),
      error => commit(FAILURE_BIZDAYS__INTENTS_TRANS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="card bg-warning">
    <div class="card-body">
      <h5 class="mb-3">유의사항</h5>
      <p>본 페이지의 모든 정보는 참고 및 정보 제공을 위한 것으로, 투자 권유를 목적으로 하지 않습니다. 제공되는 정보는 공개된 자료를 바탕으로 한 것이나 오류가 있을 수 있습니다. 코리아에셋투자증권은 본 페이지를 통해 제공되는 정보의 정확성을 보장하지 않으며, 제공되는 정보를 바탕으로 한 투자 결과에 대하여 책임지지 않습니다.</p>
      <p class="mb-0">따라서, 본 페이지에서 설명하는 회사에 투자할 의사가 있는 사이트 이용자는 자신의 책임 하에 정보를 주의 깊게 검토하고 관련된 위험을 평가하여야 하며 독자적으로 투자와 관련된 의사결정을 하여야 합니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyNotice',
};
</script>

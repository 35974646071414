<template>
    <div class="d-flex justify-content-end">
      <router-link
        class="btn btn-link text-dark font-weight-bold pt-2"
        :to="{name: 'sign_in'}"
      >로그인</router-link>
      <span class="text-dark mx-1 py-2">|</span>
      <router-link
        class="btn btn-link text-dark font-weight-bold pt-2 pr-3"
        :to="{name: 'auth'}"
      >회원가입</router-link>
    </div>
</template>

<script>
export default {
  name: 'HomeAuth',
};
</script>

<style scoped>
.home-auth {
  z-index: 1020;
}
</style>>

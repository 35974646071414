<template>
  <div class="row py-3 mystocks-card">
    <div class="col-12 col-lg-10 mb-2 mb-lg-0">
      <h6 class="mb-3">{{ mystock.stockName }} <small>{{ mystock.stockCode }}</small></h6>
      <div class="d-flex flex-column flex-lg-row justify-content-lg-between">
        <div class="d-flex flex-row flex-lg-column justify-content-between">
          <small class="text-light">구분</small>
          <h6 class="mb-0">{{ mystock.type === 'SELL' ? '매도' : '매수' }}</h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">게시기한</small>
          <h6 class="mb-0">{{ mystock.expiration | date_expiration(mystock.intentCount) }}</h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">가격</small>
          <h6 class="mb-0">{{ mystock.limitPrice | currency }} <small>원</small></h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">수량</small>
          <h6 class="mb-0">{{ mystock.quantity | currency }} <small>주</small></h6>
        </div>
        <div class="d-flex flex-row flex-lg-column justify-content-between text-lg-right">
          <small class="text-light">매매금액</small>
          <h6 class="mb-0">{{ mystock.limitPrice * mystock.quantity | currency }} <small>원</small></h6>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2">
      <div class="d-flex flex-lg-column">
        <button
          class="btn btn-outline-primary btn-block btn-sm mt-2 mr-2 mr-lg-0"
          @click="$emit('route-update', mystock)"
        >수정</button>
        <button
          class="btn btn-outline-primary btn-block btn-sm ml-2 ml-lg-0"
          @click="$emit('route-trans', mystock)"
        >삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyStocksCard',
  props: {
    mystock: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.mystocks-card {
  border-top: 2px solid $primary;

  &:last-child {
    border-bottom: 2px solid $primary;
  }
}
</style>

<template>
  <div class="d-md-none">
    <div class="modal fade show d-block">
      <div class="modal-dialog m-0 h-100">
        <div class="modal-content border-0 rounded-0 h-100">
          <div class="modal-body text-primary p-0">
            <div class="d-flex shadow-sm py-2 px-3">
              <IconPeopleCircle class="user align-self-center mr-2"/>
              <router-link
                v-if="!isAuthenticated"
                class="btn btn-link"
                :to="{name: 'sign_in'}"
              >로그인 해주세요.</router-link>
              <router-link
                v-else
                class="btn btn-link"
                :to="{name: 'user_nickname'}"
              >{{ user.nickname }}</router-link>
              <button type="button" class="close ml-auto" data-dismiss="modal" aria-label="Close" @click="$emit('toggle-modal')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="d-flex justify-content-between border-bottom-bold mb-3">
              <template v-if="!isAuthenticated">
                <span></span>
                <router-link
                  class="btn btn-link pt-2 pr-3"
                  :to="{name: 'auth'}"
                >회원가입</router-link>
              </template>
              <template v-else>
                <router-link
                  class="btn btn-link pt-2 pr-3"
                  :to="{name: 'user_infomation'}"
                >회원정보</router-link>
                <router-link
                  class="btn btn-link pt-2 pr-3"
                  :to="{name: 'sign_out'}"
                >로그아웃</router-link>
              </template>
            </div>
            <div class="mb-3 p-3 border-bottom-bold">
              <router-link :to="{name: '__OTC_HOT__'}" class="h6 text-secondary">
                <img src="./img/progIcon.png" class="icon" />기업정보
              </router-link>
              <div class="ml-4 pl-3 pt-3 text-dark">
                <div>
                  <router-link class="text-reset" :to="{name: '__OTC_HOT__'}">HOT비상장</router-link>
                  <span class="mx-3">|</span>
                  <router-link class="text-reset" :to="{name: '__OTC_REPORTS__'}">비상장리포트</router-link>
                </div>
              </div>
              <hr>
              <router-link :to="{name: '__LP_FUNDING__'}" class="h6 text-secondary mb-3">
                <img src="./img/searchIcon.png" class="icon" />투자기회
              </router-link>
              <div class="ml-4 pl-3 pt-3 text-dark">
                <div>
                  <router-link class="text-reset" :to="{name: '__LP_FUNDING__'}">신기술투자조합 조합원모집</router-link>
                </div>
                <div>
                  <router-link class="text-reset" :to="{name: '__HELP_INTENT__'}">Pro스토어</router-link>
                  <span class="mx-3">|</span>
                  <router-link class="text-reset" :to="{name: 'markets_board'}">회원간매매</router-link>
                </div>
              </div>
              <hr>
              <router-link :to="{name: '__LP_JOIN__'}" class="h6 text-secondary">
                <img src="./img/regIcon.png" class="icon" />투자진행
              </router-link>
              <div class="ml-4 pl-3 pt-3 text-dark">
                <div>
                  <router-link class="text-reset" :to="{name: '__LP_JOIN__'}">신기술투자조합 가입진행</router-link>
                </div>
                <div>
                  <router-link class="text-reset" :to="{name: 'intents'}">협의진행</router-link>
                  <span class="mx-3">|</span>
                  <router-link class="text-reset" :to="{name: 'transactions_progress'}">매매진행</router-link>
                </div>
              </div>
              <hr>
              <router-link :to="{name: '__INVEST_PLACEMENTS__'}" class="h6 text-secondary">
                <img src="./img/regIcon.png" class="icon" />MY
              </router-link>
              <div class="ml-4 pl-3 pt-3 text-dark d-flex flex-column">
                <div>
                  <router-link class="text-reset" :to="{name: '__INVEST_PLACEMENTS__'}">MY조합(투자현황)</router-link>
                </div>
                <div>
                  <router-link class="text-reset" :to="{name: 'mystocks'}">MY매물</router-link>
                  <span class="mx-3">|</span>
                  <router-link class="text-reset" :to="{name: 'transactions_list'}">거래내역</router-link>
                </div>
                <!-- <div>
                  <router-link class="text-reset" :to="{name: '__ANGEL_TRANSACTIONS_FINISHED__'}">엔젤리그조합지분매매</router-link>
                </div> -->
                <div>
                  <router-link class="text-reset" :to="{name: 'user_infomation'}">회원정보</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IconPeopleCircle from '@/components/icons/IconPeopleCircle.vue';

export default {
  name: 'AppNavbarModal',
  components: {
    IconPeopleCircle,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapGetters('userInfomation', [
      'isAuthenticated',
    ]),
  },
  created() {
    document.getElementsByTagName('body')[0].classList.add('modal-open');
  },
  destroyed() {
    document.getElementsByTagName('body')[0].classList.remove('modal-open');
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  max-width: 100%;
  margin-left: 15% !important;
}
.shadow-sm {
  box-shadow: 0 1px rgba(48, 48, 48, 0.075) !important;
}
.border-bottom-bold {
  border-bottom: 10px solid #dee2e6 !important;
}
.user {
  width: 2.5rem;
  height: 2.5rem;
}
.icon {
  width: 2rem;
  margin-right: 0.5rem;
}
</style>


import {
  REQUEST__HELP_INTENT,
  RECEIVE__HELP_INTENT,
  FAILURE__HELP_INTENT,
  SELECT__HELP_INTENT,
  DESELECT__HELP_INTENT,
  SHOW_CHARGER__HELP_INTENT,
  HIDE_CHARGER__HELP_INTENT,
  SHOW_CORPORATION__HELP_INTENT,
  HIDE_CORPORATION__HELP_INTENT,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  modePublic: true,
  markets: {
    sellAssign: [],
    helpStock: [],
    eventStock: [],
  },
  error: {},
  status: 'INITIAL',
  item: null,
  charger: null,
  corporation: null,
};

const getters = {
  eventStock: state => state.markets.eventStock[0],
  countSellAssign: state => state.markets.sellAssign.length,
  countHelpStock: state => state.markets.helpStock.length,
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__HELP_INTENT] (state, modePublic) {
    state.status = 'REQUEST';
    state.modePublic = modePublic;
  },
  [RECEIVE__HELP_INTENT] (state, data) {
    const { sellAssign, helpStock, eventStock } = data;
    state.markets = {
      sellAssign,
      helpStock,
      eventStock,
    };
    state.status = 'RECEIVE';
  },
  [FAILURE__HELP_INTENT] (state) {
    state.status = 'FAILURE';
  },
  [SELECT__HELP_INTENT] (state, item) {
    state.item = { ...item };
  },
  [DESELECT__HELP_INTENT] (state) {
    state.item = null;
  },
  [SHOW_CHARGER__HELP_INTENT] (state, item) {
    state.charger = { ...item };
  },
  [HIDE_CHARGER__HELP_INTENT] (state) {
    state.charger = null;
  },
  [SHOW_CORPORATION__HELP_INTENT] (state, item) {
    state.corporation = { ...item };
  },
  [HIDE_CORPORATION__HELP_INTENT] (state) {
    state.corporation = null;
  },
};

const actions = {
  requestListPublic({ commit }) {
    commit(REQUEST__HELP_INTENT);

    api.get('/public/prostocks').then(
      response => commit(RECEIVE__HELP_INTENT, response.data),
      error => commit(FAILURE__HELP_INTENT, error),
    );
  },
  requestList({ commit }) {
    commit(REQUEST__HELP_INTENT);

    api.get('/prostocks').then(
      response => commit(RECEIVE__HELP_INTENT, response.data),
      error => commit(FAILURE__HELP_INTENT, error),
    );
  },
  registNotice({ dispatch, commit, state }) {
    const { companyCode } = state.item;

    api.post('/cover/notice', { companyCode }).then(
      () => {
        commit(DESELECT__HELP_INTENT);
        dispatch('requestList');
      },
      error => commit(FAILURE__HELP_INTENT, error.response.data),
    );
  },
  deleteNotice({ dispatch, commit, state }) {
    const { openNoticeKey } = state.item;

    api.delete(`/cover/notice/${openNoticeKey}`).then(
      () => {
        commit(DESELECT__HELP_INTENT);
        dispatch('requestList');
      },
      error => commit(FAILURE__HELP_INTENT, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import {
  INPUT_FORM__INVEST_CHECK,
  POST_FORM__INVEST_CHECK,
  DONE_FORM__INVEST_CHECK,
  FAIL_FORM__INVEST_CHECK,
} from '@/store/mutation-types';
import api from '@/api/axios';
import { NegoStockApi, FintechCommPath } from '@/utils/constants';

const INIT_FORM = {
  checkAll: false,
  agreement: false,
  privacy: false,
  marketing: false,
}

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  enabledForm: state => {
    const { agreement, privacy } = state.form;
    return agreement && privacy;
  },
};

const mutations = {
  [INPUT_FORM__INVEST_CHECK] (state, { key, value }) {
    state.form[key] = value;

    if (key === 'checkAll') {
      state.form = {
        checkAll: value,
        agreement: value,
        privacy: value,
        marketing: value,
      };
    }
  },
  [POST_FORM__INVEST_CHECK] (state) {
    state.status = 'POST';
    state.error = {};
  },
  [DONE_FORM__INVEST_CHECK] (state) {
    state.status = 'INIT';
    state.form = { ...INIT_FORM };

    window.location = `${NegoStockApi}/sessions/0/${FintechCommPath}/auth?path=/self-verify/myfund`;
  },
  [FAIL_FORM__INVEST_CHECK] (state, error) {
    state.status = 'FAIL';
    state.error = { ...error };
  },
};

const actions = {
  submitForm({ commit, state }) {
    commit(POST_FORM__INVEST_CHECK);

    const { marketing: allowMarketing } = state.form;
    api.post('/users/allowmarketing', { allowMarketing }).then(
      response => commit(DONE_FORM__INVEST_CHECK, response.data),
      error => commit(FAIL_FORM__INVEST_CHECK, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="w-100">
    <div
      v-if="$route.name === 'auth' || $route.name === 'sign_up' || $route.name === 'request_password'"
      class="d-flex justify-content-between mb-2"
    >
      <span class="text">이미 계정이 있나요?</span>
      <router-link :to="{ name: 'sign_in' }" class="link">로그인</router-link>
    </div>
    <div
      v-if="$route.name === 'sign_in' || $route.name === 'request_password'"
      class="d-flex justify-content-between mb-2"
    >
      <span class="text">계정이 없으신가요?</span>
      <router-link :to="{ name: 'sign_up' }" class="link">회원가입</router-link>
    </div>
    <!-- <div
      v-if="$route.name === 'sign_in'"
      class="d-flex justify-content-between mb-2"
    >비밀번호를 잊었나요? <router-link :to="{ name: 'request_password' }">비번찾기</router-link></div> -->
  </div>
</template>

<script>
export default {
  name: 'AuthLinks',
};
</script>

<style lang="scss" scoped>
.text {
  @include nego-font(0.875rem, 300, 1.71, normal, $dark);
}
.link {
  @include nego-font(0.875rem, 400, 1.71, normal, $secondary);
}
</style>

export default [
  {
    header: '네고스탁 서비스 이용약관',
  },
  {
    title: '제 1 조 (목적)',
    text: '이 약관은 코리아에셋투자증권주식회사(이하 "회사"라 합니다)가 운영하는 네고스탁이 제공하는 인터넷 관련 서비스(”이하 서비스”라고 합니다)의 이용과 관련하여 회사와 이용자 간에 필요한 사항을 규정함을 목적으로 합니다.',
  },
  {
    title: '제 2 조 (용어의 정의)',
    list: [
      '①	이 약관에서 사용하는 용의의 정의는 다음과 같습니다.',
      [
        '1.	“네고스탁”이란 회사가 비상장증권 등의 매매와 관련하여 회원에게 정보 및 회원간 커뮤니케이션 수단 등을 제공하기 위해 운영하는 웹사이트(도메인명 www.negostock.co.kr) 및 모바일앱 등을 의미하며, 아울러 이 서비스를 운영하는 사업자의 의미로도 사용됩니다. ',
        '2.	“회원”이라 함은 회사에 개인정보를 제공하고, 네고스탁 서비스이용 약관에 동의한 자로써 네고스탁이 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.',
        '3.	“게시물”이란 “회원”이 “서비스”를 이용하면서 “서비스”에 게시한 부호·문장·음성·음향·동영상 등의 정보 형태의 글, 사진, 동영상 및 파일과 링크 등을 의미합니다.',
        '4.	“포인트”란 네고스탁이 제공하는 특정서비스 이용에 사용할 수 있도록 회사가 회원에게 부여한 사이버머니를 말합니다. ',
        '5.	“네고요청”이란 비상장증권의 매매의사가 있는 회원이 다른 회원에게 매매관련 협의를 진행하기를 희망한다는 사실을 네고스탁 서비스를 통해 전달하는 것을 의미합니다.',
        '6.	“매매중개요청”은 회사의 “네고스탁 비상장증권 중개에 관한 약관”에 동의하고, 네고스탁의 특정 회원과의 증권의 매매를 중개해 줄 것을 요청하는 것을 말합니다.',
        '7.	“아이디(ID)”는 회원으로 로그인하기 위하여 가입 시 등록한 E-mail 또는 카카오로그인을 통해 카카오로부터 회사가 제공 받은 식별번호를 말합니다.',
        '8.	“비밀번호”는 E-mail 가입 회원의 본인 확인과 비밀 보호를 위해 회원 자신이 설정한 문자, 숫자 또는 양자의 조합을 의미합니다.',
        '9.	회원의 “공개ID”는 회사가 개별회원에게 부여한 014로 시작하는 11자리 숫자를 의미합니다.',
        '10.	“닉네임”이란 가입 시 회사가 “회원”에게 부여하고, 회원이 변경할 수 있는 회원의 “닉네임(이름)”을 의미합니다.',
      ],
      '②	제1항의 용어를 제외한 용어의 정의는 거래 관행 및 관계 법령을 따릅니다.',
    ],
  },
  {
    title: '제 3 조 (약관의 명시와 개정)',
    list: [
      '①	"네고스탁"은 이 약관의 내용과 상호 및 대표자 성명, 주소, 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 게시합니다.',
      '②	이 약관은 그 내용을 회사의 웹사이트에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.',
      '③	약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 “네고스탁”의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이전의 유예기간을 두고 공지합니다. 이 경우 "네고스탁"은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.',
      '④	“회사”가 전항에 따라 공지하면서 “회원”에게 적용일자 전일까지 거부의 의사표시를 하지 않으면 동의의 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 “회원”이 명시적으로 거부의 의사표시를 하지 아니한 경우 “회원”이 개정된 약관에 동의한 것으로 간주합니다.',
      '⑤	회원은 개정된 약관에 동의하지 않으면 적용일자 전일까지 “회사”에 거부의사를 표시하고 “서비스”이용계약을 해지 할 수 있습니다.',
    ],
  },
  {
    title: '제 4 조 (약관 외 운영정책 등)',
    list: [
      '①	“회사는 이 약관 외에 별도의 운영정책을 둘 수 있습니다.',
      '②	이 약관에 명시되지 않은 사항이나 해석에 대해서는 운영정책, 이용안내, 관련 법령에 따릅니다.',
    ],
  },
  {
    title: '제 5 조 (이용계약의 체결)',
    list: [
      '①	서비스 이용계약은, 회원이 되고자 하는 자(이하 "가입신청자")가 회사에서 요구하는 정보를 제공하고, 회사가 지정하는 방법에 따라 이 약관에 대한 동의 의사를 전자적 방식으로 제출한 후, 회사가 다음 각호의 요건을 확인하여 해당 가입신청자에게 이용을 승낙함으로써 체결됩니다.',
      '②	가입신청자는 SMS(문자메시지)를 수신할 수 있는 휴대전화 또는 태블릿 등 전자적 장치를 보유하여야 합니다.',
    ],
  },  
  {
    title: '제 6 조 (이용계약의 거절 등)',
    list: [
      '①	회사는 다음 각 호에 해당하는 경우 이용 신청에 대하여 승낙을 하지 않을 수 있습니다.',
      [
        '1.	회사가 정한 서비스 제공환경이 아니거나 기술상 서비스 제공이 불가능한 경우',
        '2.	가입신청자가 신청 시 제공한 정보에 허의, 누락이나 오류가 있거나, 회사가 요구하는 기준을 충족하지 못하는 경우',
        '3.	14세 미만의 자가 이용신청 하는 경우',
        '4.	기타 이용신청자의 귀책사유로 이용 승낙이 곤란한 경우',
      ], 
      '②	회사는 다음 각 호에 해당하는 경우에는 그 사유가 해소될 때까지 이용 승낙을 보류할 수 있으며, 그 사유를 웹사이트에 공지합니다.',
      [
        '1.	설비의 여유가 없는 경우',
        '2.	기술상 또는 업무 수행상 지장이 있는 경우',
      ],
    ],
  },
  {
    title: '제 7 조 (서비스의 제공 및 변경)',
    list: [
      '①	회사가 제공하는 기본 서비스는 다음과 같습니다.',
      [
        '1.	회원간 커뮤니케이션을 위한 네고요청 전달 및 채팅 서비스',
        '2.	회사에 대한 주식중개 요청 전달서비스',
        '3.	회사에 요청한 주식중개 서비스 진행 과정 안내서비스',
      ], 
      '②	회사는 필요한 경우 관련 법률 및 규정 등이 허용하는 범위 내에서 아래의 서비스를 포함하여 서비스의 내용을 추가 또는 변경할 수 있으며, 추가 또는 변경 내용은 회사의 웹사이트에 공지합니다.',
      [
        '1.	회원들이 등록한 매매관심종목 목록',
        '2.	회원들이 매도를 목적으로 당사 증권계좌 개설 후 입고한 종목에 대한 확인매물 정보 ',
        '3.	비상장 주식 가격정보',
        '4.	관련 기사 및 속보 서비스',
        '5.	각종 비상장 주식 분석 자료',
      ],
      '③	1항 2호에 따른 서비스를 이용하기 위해서는 회사의 “비상장주식중개거래약관”에 대한 별도 동의가 필요합니다.',
      '④	2항에 의해 변경 또는 추가되는 서비스 이용에 대해 별도의 동의를 요구할 수 있습니다.',
    ],
  },
  {
    title: '제 8 조 (서비스의 이용개시, 이용시간 및 요금)',
    list: [
      '①	제 5 조에 따라 회사가 신청인의 이용신청을 승낙함과 동시에 신청인은 회사가 제공하는 서비스의 회원이 되며, 이때부터 회사는 당해 회원에게 당해 약관이 정하는 서비스를 제공합니다. ',
      '②	회사는 본 약관이 정한 서비스 외에 추가적인 서비스를 제공하기 위해 회원에게 별도의 추가적인 약관 동의, 정보 수집 및 이용 동의 등 절차의 이행을 요청할 수 있으며, 이러한 절차가 완료되지 않는 경우 해당 회원이 추가적인 서비스의 전부 또는 일부를 이용하지 못할 수 있습니다. 회원이 추가 서비스를 이용할 경우, 각 서비스 별로 추가되는 이용약관과 본 약관의 내용이 상이한 경우 본 약관보다 추가적으로 적용되는 개별 약관이 우선 적용 됩니다.',
      '③	서비스의 이용은 회사의 업무상 또는 기술상의 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. 다만, 서비스 운영상의 필요성으로 회사가 정한 기간 동안 본 서비스가 일시 중지될 수 있으며, 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 제공하지 못할 수도 있습니다. 이 경우 회사는 이를 회사의 웹사이트에 공지하거나 회원에게 통지하여야 합니다.',
      '④	회사는 다음 각 호에 해당하는 경우, 서비스의 제공을 중지할 수 있습니다.',
      [
        '1.	설비의 보수 등을 위하여 부득이한 경우',
        '2.	전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우',
        '3.	국가비상사태, 천재지변 기타 불가항력적 사유가 있는 경우',
      ],
      '⑤	별도로 표시된 유료 서비스를 제외한 모든 서비스는 회원들에게 무료로 제공됩니다.',
      '⑥	유료 서비스의 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는 별도의 규정에 따릅니다.',
    ],
  },  
  {
    title: '제 9 조 (닉네임 관리)',
    text: '회사는 다음의 각 호에 해당하는 경우, 서비스의 제공을 중지할 수 있습니다.',
    list: [
      '①	"회사"는 "회원"의 "닉네임"이 다음 각호에 해당하는 경우 해당 “닉네임”의 이용을 제한할 수 있습니다.',
      [
        '1.	개인정보 유출 우려가 있는 경우',
        '2.	반사회적 또는 미풍양속에 어긋나는 경우',
        '3.	"회사" 및 "회사"의 운영자로 오인할 우려가 있는 경우',
      ],
      '②	"회원"은 비밀번호가 도용되거나 제3자가 사용하고 있음을 안 경우 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.',
      '③	"회원"이 전항을 준수하지 않아 발생한 불이익에 대하여 "회사"는 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.',
    ],
  },
  {
    title: '제 10 조 (회사의 의무)',
    list: [
      '①	회사는 이 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공하는 데 최선을 다해야 합니다.',
      '②	회사는 회원의 개인정보의 보안에 대하여 기술적 안전 조치를 강구하고 관리에 만전을 기함으로써 회원의 개인정보 보안에 최선을 다해야 합니다.',
      '③	"회사"는 "서비스" 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, "서비스" 내 게시판, 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달할 수 있습니다. ',
      '④	회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약 관련 절차 및 내용 등에 있어 회원에게 편의를 제공하도록 노력합니다.',
    ],
  },
  {
    title: '제 11 조 (회원의 의무)',
    list: [
      '①	회원은 서비스를 이용함에 있어 다음 각 호의 행위를 하여서는 안 됩니다.',
      [
        '1.	본인이 사용 권한이 없는 이메일 및 휴대전화번호를 기재하여 가입하는 행위',
        '2.	수신의사를 밝히지 않은 타인의 휴대전화번호로 네고스탁의 네고요청을 전송하는 행위 ',
        '3.	타인의 서비스 아이디 및 비밀번호를 도용하는 행위',
        '4.	타인의 지적재산권을 침해하는 행위',
        '5.	타인의 명예를 훼손하거나 사생활을 침해하는 행위',
        '6.	다른 회원과의 대화창 또는 “서비스”에 욕설, 비방 또는 음란, 폭력적인 내용의 표현을 하는 행위',
        '7.	정보통신설비의 오작동이나 정보 등의 파괴를 유발하는 컴퓨터 바이러스 등을 유포하는 행위',
        '8.	회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위',
        '9.	회사의 정상적인 경영 또는 업무를 방해하거나 서비스의 운영을 방해하는 행위',
        '10.	회사의 사전 승낙 없이 스크립트(Script), 에이전트(Agent) 등의 자동화된 수단, 또는 기타 부정한 방법을 통하여 “서비스”에 접속하는 행위, “서비스” 이용 신청을 하는 행위, “회사”의 서버에 부하를 야기하는 행위',
        '11.	다른 “회원”의 개인정보 및 계정정보를 수집하는 행위',
        '12.	기타 미풍양속을 해하거나 관련법령 및 이 약관을 포함하여 회사가 정한 이용 조건에 위반하는 행위',
      ],
      '②	회원은 관련 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.',
      '③	회원은 반드시 본인이 직접 서비스를 이용해야 하며, 아이디(ID) 등을 타인에게 양도 또는 대여하는 등의 방법으로 타인으로 하여금 서비스를 이용할 수 있도록 하여서는 안됩니다. 이의 위반으로 인한 책임은 회원에게 있습니다.',
    ],
  },
  {
    title: '제 12 조 (회원의 이용현황 관리)',
    list: [
      '①	회사는 회원의 네고요청 건수, 주식매매중개요청 건수, 실제 매매 건수 및 기타 이용 실적 등을 토대로 회원에게 소정의 등급을 부여할 수 있으며, 각 등급에 따라 일정한 혜택을 부여할 수 있습니다.',
      '②	회사는 다른 회원과 주식매매를 합의한 후 본인의 귀책사유로 그 매매를 종결하지 않는 횟수가 회사가 내부적으로 정한 기준을 초과하는 회원의 경우, “네고요청” 시 이에 대한 내용을 다른 회원 볼 수 있도록 할 수 있습니다.',
      '③	회사는 금융실명거래및보호에관한법률 등 관련 법령이 허용하는 한도 이내에서 회원의 이용 현황과 관련한 사항을 다른 회원이 볼 수 있도록 할 수 있습니다.',
      '④	회사가 회원에게 부여하는 등급 및 혜택에 관한 제반 사항 및 그 변경은 별도의 서비스 화면에 공지할 수 있습니다.',
    ],
  },
  {
    title: '제 13 조 (서비스 이용 제한 등)',
    list: [
      '①	회원이 제11조의 의무를 위반한 경우 회사는 회원의 서비스 이용을 일부 또는 전부 제한할 수 있습니다.',
      '②	회사는 다른 회원과 주식매매를 합의한 후 본인의 귀책사유로 그 매매를 종결하지 않는 횟수가 회사가 내부적으로 정한 기준을 초과하는 회원의 경우 해당 회원의 서비스 이용을 일부 또는 전부를 제한 할 수 있습니다.',
      '③	제1항 및 제2항의 규정에 의하여 회원의 서비스 이용을 제한하는 경우, 제한의 종류 및 기간 등은 회사의 자체 기준에 따릅니다.',
    ],
  },
  {
    title: '제 14 조 (이용 제한 및 제한 해제의 절차)',
    list: [
      '①	회사는 제13조의 규정에 의하여 이용 제한을 하고자 하는 경우 그 사유, 일시 및 기간을 정해 전자우편 또는 전화 등의 방법에 의하여 해당 회원에게 통지하여야 합니다. 다만 회사가 긴급하게 이용을 제한할 필요가 있다고 인정하는 경우에는 그러하지 아니합니다.',
      '②	제1항의 규정에 의하여 이용 제한의 통지를 받거나 긴급하게 이용 제한 조치를 받은 회원 또는 그 대리인은 전자우편 또는 서면의 방법에 의하여 회사에 이의를 제기할 수 있습니다.',
      '③	회사는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용 제한을 일시 연기할 수 있으며, 그 결과를 당해 회원 또는 그 대리인에게 통지합니다.',
      '④	회사는 이용 제한 기간 중 그 사유가 해소되는 등의 합리적인 사유가 있는 경우 이용 제한 조치를 해제합니다.',
    ],
  },
  {
    title: '제 15 조 (계약의 해지)',
    list: [
      '①	회원은 이용계약을 해지하고자 하는 경우, 회사 웹사이트의 "회원 탈퇴 신청" 메뉴에서 탈퇴 신청을 하여야 합니다. 회사는 회원의 탈퇴 신청을 빠른 시간 내에 처리하여야 합니다.',
      '②	회사는, 회원이 제11조의 의무를 위반한 경우 사전 통지를 거쳐 이용계약을 해지하고 회원탈퇴 처리할 수 있습니다. 다만, 그 내용이 중하여 회사의 정상적인 경영이나 업무 활동 또는 서비스 운영에 지장이 있는 경우 사전통지 없이 계약을 해지하고 탈퇴 처리를 할 수 있습니다.',
      '③	회사는, 제2항에 의하여 탈퇴 처리된 회원이 다시 이용 신청을 하는 경우, 그에 대한 승낙을 거절하거나 일정 기간 그 승낙을 보류할 수 있습니다.',
    ],
  },
  {
    title: '제 16 조 (손해배상)',
    list: [
      '①	회사는 회사의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 회원의 손해를 배상하겠습니다. 다만 회사는 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다. ',
      [
        '1.	천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해',
        '2.	회원의 귀책사유로 서비스 이용에 장애가 발생한 경우',
        '3.	서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해',
        '4.	제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해',
        '5.	제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을 방해함으로써 발생하는 손해',
        '6.	제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해',
        '7.	전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해',
        '8.	기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해 ',
      ],
      '②	회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.',
      '③	회사는 회사의 중대한 과실이 없는 한 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.',
      '④	회사는 회원이 서비스와 관련하여 게재한 정보, 자료 등의 신뢰도, 정확성, 적법성 등에 관하여는 책임을 지지 않습니다.',
    ],
  },
  {
    title: '제 17 조 (회원에 대한 공고 및 통지)',
    list: [
      '①	회사는 이 약관에 따라 제공되는 서비스의 운영과 관련한 공지사항을 회사의 홈페이지(www.kasset.co.kr) 또는 네고스탁 서비스 화면에의 게시, 회원이 제공한 이메일, 회원이 제공한 휴대전화번호로의 문자 메시지를 보내는 방법을 통하여 통지할 수 있습니다.',
      '②	전항에 따른 통지는, 관련 법령 또는 이 약관에서 특별히 전자문서가 아닌 서면 형태를 요구하는 경우가 아닌 한, 서면 통지로서의 효력을 갖습니다.',
    ],
  },
  {
    title: '제 18 조 (개인 정보의 보호)',
    list: [
      '①	회사는 개인정보의 보호 및 처리와 관련하여 개인정보보호법, 신용정보의이용및보호에관한법률, 정보통신망이용촉진및정보보호에관한법률 및 각 그 하위 법령에 정하는 사항을 준수하며, 개인정보의 보호를 위하여 노력합니다.',
      '②	회사는 개인 정보의 수집, 이용, 제공, 보호, 위탁 등에 관한 제반 사항의 구체적인 내용을 개인정보처리방침을 통하여 규정하며, 개인정보처리방침은 회사의 홈페이, 네고스탁 서비스 화면 등을 통하여 게시합니다.',
    ],
  },
  {
    title: '제 19 조 (광고성 또는 맞춤형 정보의 제공)',
    list: [
      '①	회사는 본 서비스의 운영과 관련하여 광고성 정보를 제공하는 서비스를 운영할 수 있습니다.',
      '②	회사는 광고성 정보 및 회원 맞춤형 정보를 문자 메시지나 푸쉬 메시지 형식으로 제공할 수 있으며, 이는 광고성 정보에 대한 사전 수신 동의가 있는 경우 등 법령상 허용된 경우에 한하여 제공됩니다.',
    ],
  },
  {
    title: '제 20 조 (게시물 등의 지적재산권)',
    list: [
      '①	회사의 서비스와 관련된 일체의 지식재산권은 회사에게 속합니다.',
      '②	"회사"는 "서비스"와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라 "서비스" 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.',
      '③	회원이 서비스 내에 게시한 “게시물”의 저작권은 저작권법에 의하여 보호를 받으며, 적법한 절차와 방법으로 “회사”에 다음과 같이 사용할 수 있는 영구적인 라이선스를 제공합니다.',
      [
        '1.	서비스의 운영, 향상, 개선, 신규 서비스 개발, 프로모션 등을 위하여 “게시물”을 사용, 편집, 저장, 복제, 수정, 공개, 전송, 공개적인 게시, 배포할 수 있는 권리',
        '2.	“게시물”의 2차적 저작물을 제작 및 배포할 수 있는 권리',
      ],
      '④	전화, 팩스, 전자우편 등을 통해 사전에 “회원”의 동의를 얻은 경우, 회사는 3항 이외의 방법으로 “회원”의 “게시물”을 이용할 수 있습니다.',
      '⑤	“회원”이 이용계약을 해지하거나 제13조에 따라 서비스가 제한되는 경우에도 “서비스”의 운영 향상, 개선, 홍보 등 제 3항에서 규정된 목적 범위 내에서 라이선스는 유지됩니다.',
    ],
  },
  {
    title: '제 21 조 (게시물의 이용 및 관리)',
    list: [
      '①	"회원"의 "게시물"을 무단으로 사용하여 발생하는 손실이나 기타 문제는 전적으로 "회원" 개인의 판단에 따른 책임이며, "회사"는 이에 대하여 책임지지 않습니다.',
      '②	"회원"은 타인의 지적재산권 및 기타 권리를 침해하는 목적으로 "게시물"을 사용할 수 없으며, 타인의 권리를 침해하는 행위로 인하여 발생하는 결과에 대한 모든 책임은 "회원" 본인에게 있습니다.',
      '③	"회원"은 본 서비스에 게시된 "게시물"을 무단으로 상업적이거나 기타 개인적인 이익을 위한 용도로 사용할 수 없으며, 무단 사용으로 인해 "회사"에 피해를 입힌 경우, 법적인 절차에 따라 보상해야 합니다.',
      '④	"회원"의 "게시물"은 타인의 저작권을 침해하거나 허위 사실로 작성되어서는 아니 됩니다. "회원"의 "게시물"이 저작권법 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당 "게시물"의 삭제 등을 요청할 수 있으며, "회사"는 관련 법령에 따라 조치를 취하여야 합니다.',
      '⑤	"회사"는 전항에 따른 권리자의 요청이 없더라도 권리침해가 인정될 만한 사유가 있거나, 허위 사실로 판단되거나, 기타 회사 정책 및 관련 법령에 위반될 경우에는 관련 법령에 따라 해당 "게시물"에 대하여 게시거부나 삭제 등의 조치를 취할 수 있습니다. 다만, “회사”가 모든 "게시물"을 검토할 의무를 지지는 않습니다.',
    ],
  },
  {
    title: '제 22 조 (약관의 해석)',
    list: [
      '①	회사와 이용자 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는, 그 합의사항을 이 약관에 우선하여 적용합니다.',
      '②	회사가 제공하는 서비스에 따라 별도로 부가되는 약관은 본 약관과 통일적으로 해석되어야 하며, 서로 다른 내용이 있는 경우 원칙적으로 본 약관의 내용이 우선합니다. 다만, 개별 서비스 약관과 본 약관 중 해당 개별 서비스의 특성으로 인하여 서로 다른 내용이 있는 경우에는 그 범위에 한하여 해당 개별 서비스 약관의 조항이 우선하는 것으로 합니다.',
      '③	본 약관의 내용 중 관련법령의 강행규정과 배치되는 부분이 있는 경우, 그 부분에 한하여 본 약관의 해당 규정은 무효로 합니다.',
      '④	이 약관에 명시적으로 규정되지 않고, 당사자간에 미리 합의되지 않은 사항의 처리에 관하여는 관련법령 및 상관습에 따릅니다.',
    ],
  },
  {
    title: '제 23 조 (분쟁의 해결)',
    list: [
      '①	회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.',
      '②	제1항의 규정에도 불구하고 회사와 회원 사이에 소송이 제기될 경우, 소송은 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.',
    ],
  },
  {
    title: '제 24 조 (포인트 제도의 운영)',
    list: [
      '①	"네고스탁"은 신규회원 또는 회사의 “중개매매요청”을 통해 매매를 체결한 회원에게 네고스탁 특정서비스 이용에 사용되는 적립금을 부여할 수 있습니다.',
      '②	적립금은 회사가 지정하는 네고스탁의 특정서비스에만 사용할 수 있으며 현금으로 교환할 수 없습니다.',
    ],
  },
  {
    title: '<부칙>',
  },
  {
    title: '제 1 조 (시행일)',
    text: '본 약관은 2020년 5월 18일부터 시행합니다.'
  },
];

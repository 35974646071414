<template>
  <AppModalSimple
    title="일반게시 종목"
    :content="content"
  >
    <div class="">
      <button
        class="btn btn-outline-dark btn-block"
        @click="$emit('close-modal')"
      >확인</button>
    </div>
  </AppModalSimple>
</template>

<script>
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'MyStockModal',
  components: {
    AppModalSimple,
  },
  props: {
    textModal: {
      type: String,
      required: true,
    },
  },
  computed: {
    content() {
      return `일반게시 가능한 종목 개수(매수, 매도 각 3개)를 초과하였습니다. 게시기한이 종료되지 않은 건을 삭제하신 후에 ${this.textModal}하실 수 있습니다.`;
    },
  },
};
</script>

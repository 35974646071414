const Reports = {
  CORP: 'CORP',
  MEMO: 'MEMO',
  MARKET: 'MARKET',
  INDUSTRY: 'INDUSTRY',
  OTHER: 'OTHER',
};
const REPORTS__TYPE = [
  { value: Reports.CORP,     label: '종목' },
  { value: Reports.MEMO,     label: '탐방메모' },
  { value: Reports.MARKET,   label: '시황' },
  { value: Reports.INDUSTRY, label: '산업' },
  { value: Reports.OTHER,    label: '기타' },
];

const Writers = {
  IN: 'IN',
  OUT: 'OUT',
};
const WRITERS__TYPE = [
  { value: Writers.IN,  label: '내부' },
  { value: Writers.OUT, label: '외부' },
];

export {
  Reports,
  REPORTS__TYPE,
  Writers,
  WRITERS__TYPE,
};

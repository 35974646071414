<template>
  <div class="mb-4">
    <div class="d-none d-md-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-3" v-for="(charge, index) in list" :key="index">
          <ChargesCard
            :charge="charge"
            :isStaff="false"
          />
        </div>
      </div>
    </div>
    <div class="d-md-none p-0" >
      <ChargesCarousel
        :list="list"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ChargesCard from '@/components/deals/ChargesCard';
import ChargesCarousel from '@/components/deals/ChargesCarousel';

export default {
  name: 'DealsCharges',
  components: {
    ChargesCard,
    ChargesCarousel,
  },
  computed: {
    ...mapState('dealsCharges', [
      'list',
    ]),
  },
  methods: {
    ...mapActions('dealsCharges', [
      'requestList',
    ]),
  },
  mounted() {
    const { dealKey } = this.$route.query;
    if (dealKey) {
      this.requestList({ dealKey });
    }
  },
};
</script>

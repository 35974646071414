<template>
  <div class="card" :class="[deletable ? 'text-light' : 'text-primary']">
    <div class="card-header px-3" :class="[deletable ? 'bg-white' : 'bg-primary text-white']">
      <IntentsCardHeader
        :isSeller="intent.seller"
        :isSender="intent.sender"
        :stockName="intent.stockName"
        :stockCode="intent.stockCode"
      />
    </div>
    <div class="card-body p-3">
      <IntentsCardTotalPrice
        :class="[deletable ? 'text-light' : 'text-dark']"
        :price="intent.offerPrice || intent.hopePrice"
        :quantity="intent.offerQuantity || intent.hopeQuantity"
      />
      <IntentsCardMessage
        :enabledChat="enabledChat"
        :hasNewMessage="hasNewMessage"
        :message="message"
        :expiration="intent.expiration"
        @click-button="routingChat"
      />
      <IntentsCardButtons
        :buttons="buttons"
        @click-button="routingTrans"
      />
    </div>
    <NegoModalAccount ref="negoModalAccount"/>
  </div>
</template>

<script>
import { SENDER_MAPPING, RECEIVER_MAPPING, HELPER_MAPPING } from './CardMaps';
import IntentsCardHeader from './IntentsCardHeader.vue';
import IntentsCardTotalPrice from './IntentsCardTotalPrice.vue';
import IntentsCardMessage from './IntentsCardMessage.vue';
import IntentsCardButtons from './IntentsCardButtons.vue';
import NegoModalAccount from '@/components/nego/NegoModalAccount.vue';
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'IntentsCard',
  components: {
    IntentsCardHeader,
    IntentsCardTotalPrice,
    IntentsCardMessage,
    IntentsCardButtons,
    NegoModalAccount,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deletable() {
      return this.intent.status !== 'S01'
        && this.intent.status !== 'S02'
        && this.intent.status !== 'S03'
        && this.intent.status !== 'S04';
    },
    enabledChat() {
      return this.intent.status === 'S02'
        || this.intent.status === 'S03'
        || (this.intent.status === 'S04' && this.intent.hasNegoMessages);
    },
    price() {
      return this.intent.offerPrice || this.intent.hopePrice;
    },
    quantity() {
      return this.intent.offerQuantity || this.intent.hopeQuantity;
    },
    hasNewMessage() {
      return (this.intent.sender && this.intent.hasReceiverMsg)
        || (this.intent.receiver && this.intent.hasCreatorMsg);
    },
    mapping() {
      return this.intent.receiver ? RECEIVER_MAPPING
        : !this.intent.helperStock ? SENDER_MAPPING : HELPER_MAPPING;
    },
    message() {
      return this.mapping[this.intent.status].message;
    },
    buttons() {
      return this.mapping[this.intent.status].buttons;
    },
  },
  methods: {
    routingChat() {
      const { intentKey, status, hasNegoMessages } = this.intent;
      let path;
      if (status === 'S02' || status === 'S03') {
        path = `/chat/${intentKey}`;
        this.$emit('open-chat');
      } else if (status === 'S04' && hasNegoMessages) {
        path = `/messages/${intentKey}`;
      }
      window.open(
        path,
        'negostock_chat_popup',
        'menubar=no,location=no,resizable=yes,scrollbars=no,status=no,height=640,width=360'
      );
    },
    routingTrans({ transType, toStatus }) {
      const { intentKey } = this.intent;
      const { brokerAccountStatus, bankAccountStatus, kassetAccountStatus } = this.user;

      if (transType === 'offers' || transType === 'approval') {
        if (brokerAccountStatus === 'NONE' && bankAccountStatus === 'NONE') {
          if (kassetAccountStatus === 'NONE') {
            const PATH = [
              `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
              `?path=/users/account`,
              `&redirect_path=${NegoStockWeb}/intents/${intentKey}/trans`,
              `&transType=${transType}`,
              `&toStatus=${toStatus}`,
            ].join('');
            window.location = PATH;
            return;
          }
          if (kassetAccountStatus === 'READY') {
            this.$refs.negoModalAccount.openModal()
            return;
          }
        }
      }
      this.$router.push({
        name: 'intents_trans',
        params: { intentKey },
        query: { transType, toStatus },
      });
    },
  },
};
</script>

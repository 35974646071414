<template>
  <div>
    <FormHead
      title="이메일 찾기"
      text="등록된 내 휴대전화번호로 찾으실 수 있습니다."
    />
    <form @submit.prevent="submitForm">
      <InputText
        placeholder="휴대전화('-'없이 입력)"
        id="number"
        :value="form['number']"
        :error="error['number']"
        @input-form="(value) => inputForm({ key: 'number', value })"
      />
      <SubmitButton
        label="확인"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/auth/form/FormHead';
import InputText from '@/components/auth/form/InputText.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';
import {
  INPUT_FORM__FIND_EMAIL,
  INIT_FORM__FIND_EMAIL,
} from '@/store/mutation-types';

export default {
  name: 'FindEmailView',
  components: {
    FormHead,
    InputText,
    SubmitButton,
  },
  computed: {
    ...mapState('authFindEmail', [
      'form',
      'error',
      'result',
    ]),
    ...mapGetters('authFindEmail', [
      'enabledForm',
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('authFindEmail', {
      'inputForm': INPUT_FORM__FIND_EMAIL,
      'initForm': INIT_FORM__FIND_EMAIL,
    }),
    ...mapActions('authFindEmail', [
      'submitForm',
    ]),
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

<template>
  <div class="d-flex align-items-center w-100">
    <a
      :href="linkFile"
      target="_blank"
      class="btn btn-link btn-sm"
      download
    ><IconDownload class="icon-xs" /></a>
    <span class="flex-grow-1">{{ fileName }} <small v-if="fileKind">[{{ fileKind }}]</small></span>
    <small class="">{{ fileSize | file_size }}</small>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload.vue';

export default {
  name: 'FileDownload',
  components: {
    IconDownload,
  },
  props: {
    fileName: {
      type: String,
      required: true,
    },
    fileKey: {
      type: String,
      required: true,
    },
    fileSize: {
      type: Number,
      required: true,
    },
    filePath: {
      type: String,
      required: true,
    },
    fileKind: {
      type: String,
      default: '',
    },
  },
  computed: {
    linkFile() {
      return `${this.filePath}/${this.fileKey}`;
    },
  },
};
</script>

const FUNDINGS_STAGE = [
  { value: 'ANGEL',    label: 'Angel' },
  { value: 'PRE_IPO',  label: 'Pre_IPO' },
  { value: 'SEED',     label: 'Seed' },
  { value: 'SERIES_A', label: 'Series_A' },
  { value: 'SERIES_B', label: 'Series_B' },
  { value: 'SERIES_C', label: 'Series_C' },
  { value: 'SERIES_D', label: 'Series_D' },
  { value: 'PRIVATE',  label: 'Private' },
  { value: 'N/A',      label: 'NA' },
];

const Placement = {
  ADVISE: 'ADVISE',
  UNDER: 'UNDER',
  FUND: 'FUND',
};
const PLACEMENT_TYPE_IB = [
  { value: Placement.ADVISE, label: '주선' },
  { value: Placement.UNDER, label: '인수' },
];
const PLACEMENT_TYPE_AM = [
  { value: Placement.FUND, label: '프로젝트조합' },
];
const PLACEMENT_TYPE = [
  ...PLACEMENT_TYPE_IB,
  ...PLACEMENT_TYPE_AM,
];

const FundWrok = {
  FALSE: false,
  TRUE: true,
};
const FUND_WORK_TYPE = [
  { value: FundWrok.FALSE, label: '신규 추진' },
  { value: FundWrok.TRUE, label: '완료 건 등록' },
];

const StockType = {
  K_CS: 'K_CS',
  K_BOND: 'K_BOND',
  K_PFBOND: 'K_PFBOND',
  K_PS: 'K_PS',
  K_CPS: 'K_CPS',
  K_RPS: 'K_RPS',
  K_RCPS: 'K_RCPS',
  K_CB: 'K_CB',
  K_BW: 'K_BW',
};
const STOCK_TYPE = [
  { value: StockType.K_CS,     label: '보통주' },
  { value: StockType.K_BOND,   label: '일반사채' },
  { value: StockType.K_PFBOND, label: 'PF대출 등' },
  { value: StockType.K_PS,     label: '우선주' },
  { value: StockType.K_CPS,    label: '전환우선주' },
  { value: StockType.K_RPS,    label: '상환우선주' },
  { value: StockType.K_RCPS,   label: '상환전환우선주' },
  { value: StockType.K_CB,     label: '전환사채' },
  { value: StockType.K_BW,     label: '신주인수권부사채' },
];

const Primary = {
  NO: false,
  YES: true,
};
const PRIMARY_TYPE = [
  { value: Primary.NO, label: '기발행증권' },
  { value: Primary.YES, label: '신규발행증권' },
];

const Open = {
  SPEC: 'SPEC',
  MY: 'MY',
  ALL: 'ALL',
};
const OPEN_TYPE = [
  { value: Open.SPEC, label: '지정회원 공개' },
  { value: Open.MY, label: '나의고객 전체' },
  { value: Open.ALL, label: '회원 전체' },
];

const DisplayStatus = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
};
const DISPLAY_TYPE = [
  { value: DisplayStatus.OPEN, label: '게시함(진행)' },
  { value: DisplayStatus.CLOSE, label: '게시중지(미진행)' },
];

const DealIntent = {
  CREATE: 'CREATE',
  CONTRACT: 'CONTRACT',
  BROKEN: 'BROKEN',
};
const DEAL_INTENT_TYPE = [
  { value: DealIntent.CREATE, label: '생성' },
  { value: DealIntent.CONTRACT, label: '체결' },
  { value: DealIntent.BROKEN, label: '취소' },
];

export {
  FUNDINGS_STAGE,
  PLACEMENT_TYPE_IB,
  PLACEMENT_TYPE_AM,
  PLACEMENT_TYPE,
  FUND_WORK_TYPE,
  STOCK_TYPE,
  PRIMARY_TYPE,
  OPEN_TYPE,
  DISPLAY_TYPE,
  DEAL_INTENT_TYPE,
};

<template>
  <table class="table table-bordered table-sm font-weight-light text-nowrap">
    <thead class="table-light">
      <tr>
        <td>피투자회사</td>
        <td>증권종류</td>
        <td class="text-right">보유수량</td>
        <td class="text-right">투자단가</td>
        <td class="text-right">투자금액</td>
      </tr>
    </thead>
    <tbody>
      <template v-if="list.length">
        <tr v-for="(el, index) in list" :key="index">
          <td>{{ el.companyName }}</td>
          <td v-if="el.stockType">{{ el.stockType }}</td>
          <td v-else>{{ el.stockTypeCode | stock_type }}</td>
          <td class="text-right">{{ el.quantity | currency }}</td>
          <td class="text-right">{{ el.price | currency }}</td>
          <td class="text-right">{{ el.amount | currency }}</td>
        </tr>
      </template>
      <tr v-else>
        <td colspan="6">데이터가 없습니다.</td>
      </tr>
    </tbody>
    <caption class="text-right"><small>* 투자(취득)원가 기준임</small></caption>
  </table>
</template>

<script>
export default {
  name: 'PortfoliosTable',
  props: {
    list: {
      type: Array,
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

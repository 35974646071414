<template>
  <div class="card rounded-0" :class="{'border-warning': deleteMode}">
    <div class="row no-gutters">
      <div class="col-4 card-image">
        <ImageAndIcons
          :src="`${NegoStockApi}/public/deals/charge/${charge.dealChargeKey}`"
          object="cover"
          icon="person"
        />
      </div>
      <div class="col-8 pl-2">
        <div class="card-body p-2">
          <h5 class="card-title">
            {{charge.name}} <small>{{charge.duty}}</small>
            <template v-if="isStaff">
              <button
                class="btn btn-link btn-sm ml-1"
                @click="$emit('edit-item', charge)"
              >
                <IconPencil/>
              </button>
              <button
                class="btn btn-link btn-sm ml-1"
                @click="toggleDeleteMode"
              >
                <IconTrash />
              </button>
            </template>
          </h5>
          <template v-if="!deleteMode">
            <p class="card-text mb-0">{{ cardText }}</p>
            <p class="card-text mb-0">
              <a :href="`mailto:${charge.email}`"><small>{{ charge.email }}</small></a>
            </p>
            <p class="card-text mb-0">
              <a :href="`tel:${charge.contact}`"><small>{{ charge.contact | tel }}</small></a>
            </p>
          </template>
          <template v-else>
            <p class="card-text-delete mb-2"> 삭제 하시겠습니까?</p>
            <div>
              <button
                class="btn btn-dark btn-sm rounded-0 mr-2"
                @click="$emit('remove-item', charge)"
              >Delete</button>
              <button
                class="btn btn-outline-dark btn-sm rounded-0 ml-2"
                @click="toggleDeleteMode"
              >Cancel</button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageAndIcons from '@/components/icons/ImageAndIcons';
import IconPencil from '@/components/icons/IconPencil';
import IconTrash from '@/components/icons/IconTrash';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'ChargesCard',
  components: {
    ImageAndIcons,
    IconPencil,
    IconTrash,
  },
  data() {
    return {
      NegoStockApi,
      deleteMode: false,
    };
  },
  props: {
    charge: {
      type: Object,
      required: true,
    },
    isStaff: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardText() {
      return `${this.charge.dept}`;
    },
  },
  methods: {
    toggleDeleteMode() {
      this.deleteMode = !this.deleteMode;
    },
  },
};
</script>

<style scoped>
.card {
  height: 8.5rem;
}
.card-image {
  height: 136px;
}
.img-fit {
  height: 8.5rem;
  object-fit: cover;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.card-text-delete {
  line-height: 1.1em;
}
</style>

<template>
  <div class="mystock-container bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 bg-white pt-3">
          <router-view></router-view>
          <div class="d-flex justify-content-end py-2">
            <button class="btn btn-light btn-sm text-white px-4" @click="onBack">뒤로</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransactionsContainer',
  methods: {
    onBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.mystock-container {
  min-height: 100vh;
}
</style>

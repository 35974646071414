<template>
  <div>
    <FormHead
      title="이메일로 회원가입"
      text="약관에 동의하셔야 합니다."
    />
    <form @submit.prevent="submitForm">
      <InputEmail
        autocomplete="username"
        placeholder="이메일"
        id="userEmail"
        :value="form['userEmail']"
        :error="error['userEmail']"
        @input-form="(value) => inputForm({ key: 'userEmail', value })"
      />
      <InputPassword
        autocomplete="new-password"
        placeholder="비밀번호 (영문, 숫자, 특수문자 혼합 8자리 이상)"
        id="userPasswd"
        :value="form['userPasswd']"
        :error="error['userPasswd']"
        @input-form="(value) => inputForm({ key: 'userPasswd', value })"
      />
      <InputPassword
        autocomplete="new-password"
        placeholder="재입력 (영문, 숫자, 특수문자 혼합 8자리 이상)"
        id="confirmPasswd"
        :value="form['confirmPasswd']"
        :error="error['confirmPasswd']"
        @input-form="(value) => inputForm({ key: 'confirmPasswd', value })"
      />
      <InputCheckbox
        label="이용약관 동의"
        id="agreement"
        :value="form['agreement']"
        :error="error['agreement']"
        @input-form="(value) => inputForm({ key: 'agreement', value })"
      />
      <InputCheckbox
        label="개인정보이용방침 동의"
        id="privacy"
        :value="form['privacy']"
        :error="error['privacy']"
        @input-form="(value) => inputForm({ key: 'privacy', value })"
      />
      <SubmitButton
        label="완료"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <AppModalSimple
      v-if="showModal"
      title="네고스탁"
      content="회원가입이 완료되었습니다. 로그인 후 휴대전화 인증을 진행해 주세요."
    >
      <button
        class="btn btn-primary btn-block"
        @click="closeModal"
      >확인</button>
    </AppModalSimple>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__SIGN_UP, CLOSE_MODAL__SIGN_UP } from '@/store/mutation-types';
import FormHead from '@/components/auth/form/FormHead';
import InputEmail from '@/components/auth/form/InputEmail.vue';
import InputPassword from '@/components/auth/form/InputPassword.vue';
import InputCheckbox from '@/components/auth/form/InputCheckbox.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'SignUpView',
  components: {
    FormHead,
    InputEmail,
    InputPassword,
    InputCheckbox,
    SubmitButton,
    AppModalSimple,
  },
  computed: {
    ...mapState('authSignUp', [
      'form',
      'error',
      'showModal',
    ]),
    ...mapGetters('authSignUp', [
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('authSignUp', {
      'inputForm': INPUT_FORM__SIGN_UP,
      'closeModal': CLOSE_MODAL__SIGN_UP,
    }),
    ...mapActions('authSignUp', [
      'submitForm',
    ]),
  },
  beforeMount() {
    const { email } = this.$route.query;
    if (email) {
      this.inputForm({ key: 'userEmail', value: email });
    }
  },
};
</script>

<template>
  <div>
    <template v-if="searchType === 'intents_search'">
      <h4 class="title mb-3">지인거래 종목검색</h4>
      <p class="text">지인 등과 매매하기로 합의한 종목을 검색 및 선택해 주세요.</p>
    </template>
    <template v-if="searchType === 'mystocks_search'">
      <h4 class="title mb-3">종목검색 <small>(일반게시 종목으로 등록)</small></h4>
      <p class="text">비상장주식사이트등에 게시하여 네고요청을 받으실 수 있습니다.</p>
    </template>
    <template v-if="searchType === 'markets_search'">
      <h4 class="title mb-3">종목검색</h4>
      <p class="text">네고 또는 거래 요청할 매물을 바로 검색할 수 있습니다.</p>
    </template>
    <template v-if="searchType === 'sell_assigns_search'">
      <h4 class="title mb-3">종목검색</h4>
      <p class="text">확인매물 등록요청</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NegoSearchHeader',
  computed: {
    searchType() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-title;
}
.text {
  @include nego-text;
}
</style>

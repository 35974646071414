<template>
  <div class="border mb-4">
    <div class="d-flex justify-content-between bg-primary text-white py-2 px-3"
    >{{ deal.dealName }}
      <small v-if="deal.taxCredit" class="bg-danger p-1">소득공제</small>
    </div>
    <div class="p-3">
      <div class="row no-gutters">
        <div class="col-3">
          <ImageOrIcons
            :src="deal.companyLogo"
            icon="building"
          />
        </div>
        <div class="col-9 pl-3">
          <h5 class="mb-0">{{ deal.companyName }}</h5>
          <p class="mb-0">{{ deal.stockTypeCode | stock_type }}</p>
          <p class="mb-2">설정규모 {{ deal.expectedAmount | won3 }} 백만원</p>
          <div class="row no-gutters">
            <div class="col pr-2">
              <router-link
                class="btn btn-outline-primary btn-sm w-100"
                :to="{
                  name: '__COMPANY_VIEW__',
                  params: { companyCode: deal.companyCode },
                }"
              >기업정보</router-link>
            </div>
            <div class="col pl-2">
              <router-link
                class="btn btn-outline-primary btn-sm w-100"
                :to="{
                  name: '__FUNDING_LIBRARY__',
                  query: { dealKey: deal.dealKey, dealName: deal.dealName, beforeReserveTime: deal.beforeReserveTime }
                }"
              >조합자료실</router-link>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!-- A: 입금 전 -->
      <template v-if="deal.status === Status.ACCEPT">
        <div class="mb-3">
          <div class="d-flex justify-content-between">
            <p class="mb-0">조합 참여액 (입금하실 금액)</p>
            <span>{{ deal.offerPrice | currency }} 원</span>
          </div>
        </div>
        <div class="bg-gray border rounded p-2 mb-3">
          <p>
            입금안내
            <router-link
              v-if="modeTest"
              class="btn btn-outline-primary btn-sm ml-3"
              :to="{
                name: 'transactions_pay_in',
                params: { transactionKey: deal.transactionKey },
              }"
            >입금통보</router-link>
          </p>
          <ul class="pl-4 mb-0">
            <li>
              <div class="d-flex justify-content-between">
                입금계좌:
                <span>농협 {{ deal.virtualAccount }}<br>({{ deal.virtualAccountOwner }})</span>
              </div>
            </li>
            <li>
              <div class="d-flex justify-content-between">
                입금시간:
                <span>{{ deal.expiration | date }} 까지</span>
              </div>
            </li>
          </ul>
        </div>
        <button
          class="btn btn-block btn-light"
          disabled
        >조합가입 전자서명(입금 후 진행 가능)</button>
      </template>
      <!-- B: 입금 후 -->
      <template v-else-if="deal.status === Status.PAY_IN">
        <div class="mb-3">
          <div class="d-flex justify-content-between">
            <p class="mb-0">조합 참여액 (입금완료)</p>
            <span>{{ deal.offerPrice | currency }} 원</span>
          </div>
        </div>
        <div class="bg-gray border rounded p-2 mb-3">
          <p>전자서명(카카오페이) 안내</p>
          <ul class="pl-4 mb-0">
            <li>
              <div class="">
                제한시간까지 전자서명을 완료하시지 않을 경우, 조합가입신청이 취소됩니다(입금액은 반환).
              </div>
            </li>
            <li>
              <div class="d-flex justify-content-between">
                서명 제한시간:
                <span>{{ deal.expiration | date }} 까지</span>
              </div>
            </li>
          </ul>
        </div>
        <button
          class="btn btn-block btn-secondary"
          @click="$emit('sign-item')"
        >
          조합가입 전자서명
          <span v-if="
            deal.subscriptionStatus === SubscriptionStatus.SIGN_ASK
            || deal.subscriptionStatus === SubscriptionStatus.SIGN_EXPIRE"
          >(진행중)</span>
        </button>
      </template>
      <!-- C: 서명완료 -->
      <template v-else-if="deal.status === Status.SHARE_OUT || deal.status === Status.COMPLETE_OUT">
        <div class="mb-3">
          <div class="d-flex justify-content-between">
            <p class="mb-0">조합 참여액 (서명완료)</p>
            <span>{{ deal.offerPrice | currency }} 원</span>
          </div>
        </div>
        <div class="bg-gray border rounded p-2 mb-3">
          <p>전자서명 완료 안내</p>
          <ul class="pl-4 mb-0">
            <li>
              <div class="">
                조합 참여에 감사 드립니다.
              </div>
            </li>
            <li>
              <div class="">
                조합설립 절차가 완료되면, MY페이지의 "투자조합 현황"에서 조합 운용내역을 확인하실 수 있습니다.
              </div>
            </li>
          </ul>
        </div>
        <button
          class="btn btn-block btn-light"
          disabled
        >{{ (deal.subscriptionStatus === SubscriptionStatus.SIGN_NOTI
          || deal.subscriptionStatus === SubscriptionStatus.SIGN_ASK
          || deal.subscriptionStatus === SubscriptionStatus.SIGN_EXPIRE)
          ? '재서명중(별도안내)' : '가입 절차 완료'}}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { AppTarget } from '@/utils/constants';
import ImageOrIcons from '@/components/icons/ImageOrIcons';
import { Status, SubscriptionStatus } from '@/filters/filtersSubscriptions';

export default {
  name: 'LPJoinCard',
  components: {
    ImageOrIcons,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Status,
      SubscriptionStatus,
      AppTarget,
    };
  },
  computed: {
    modeTest() {
      return AppTarget === 'dev' || AppTarget === 'alpha';
    },
  },
};
</script>

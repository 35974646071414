const STATUS = {
  // 매수자
  BUYER__BROKEN__ACCEPT: '',
  BUYER__BROKEN__REFUND_WAIT: '거래가 취소됨<br>매매대금은 회원님 은행계좌로 반환',
  BUYER__BROKEN__REFUND_REQUEST: '거래가 취소됨<br>매매대금은 회원님 은행계좌로 반환',
  BUYER__BROKEN__REFUND: '거래가 취소됨<br>매매대금은 회원님 은행계좌로 반환해 드렸습니다.',
  // 매도자
  SELLER__BROKEN__ACCEPT: '',
  SELLER__BROKEN__WAITING_RETURN: '거래가 취소됨<br>이체한 주식은 회원님 증권 계좌로 반환',
  SELLER__BROKEN__RETURN_OUT: '거래가 취소됨<br>이체한 주식은 회원님 증권 계좌로 반환해 드렸습니다.',
};

const PAY_STATUS = {
  ACCEPT: '거래취소',
  REFUND_WAIT: '반환대기',
  REFUND_REQUEST: '반환대기',
  REFUND: '반환완료',
};

const SHARE_STATUS = {
  ACCEPT: '거래취소',
  WAITING_RETURN: '반환대기',
  RETURN_OUT: '반환완료',
};

export {
  STATUS,
  PAY_STATUS,
  SHARE_STATUS,
};

import _ from 'underscore';
import {
  INPUT_FORM__MARKETING_REJECT,
  CHECK_FORM__MARKETING_REJECT,
  POST_FORM__MARKETING_REJECT,
  DONE_FORM__MARKETING_REJECT,
  FAIL_FORM__MARKETING_REJECT,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  form: {
    allowMarketing: false,
    userKey: '',
  },
  error: {},
  status: 'INITIAL',
};

const getters = {
  invalidForm: state => _.keys(state.error).length > 0,
  loadingForm: state => state.status === 'POST',
  successForm: state => state.status === 'DONE',
};

const mutations = {
  [INPUT_FORM__MARKETING_REJECT] (state, { key, value }) {
    state.form[key] = value;
  },
  [CHECK_FORM__MARKETING_REJECT] (state) {
    const { userKey } = state.form;
    const invalid = {};

    if (!userKey) {
      invalid.userKey = 'userKey을 확인해주세요.';
    }

    state.error = { ...invalid };
  },
  [POST_FORM__MARKETING_REJECT] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__MARKETING_REJECT] (state) {
    state.status = 'DONE';
    state.error = {};
  },
  [FAIL_FORM__MARKETING_REJECT] (state, error) {
    state.status = 'FAIL';
    state.error = { ...error };
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__MARKETING_REJECT);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
  },
  submitForm({ commit, state }) {
    commit(POST_FORM__MARKETING_REJECT);
    const data = _.pick(state.form, 'allowMarketing', 'userKey');

    api.post('/public/users/allowmarketing', data).then(
      reponse => commit(DONE_FORM__MARKETING_REJECT, reponse.data),
      error => commit(FAIL_FORM__MARKETING_REJECT, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import {
  REQUEST_LIST__SELL_ASSIGNS,
  RECEIVE_LIST__SELL_ASSIGNS,
  FAILURE_LIST__SELL_ASSIGNS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST_LIST__SELL_ASSIGNS] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__SELL_ASSIGNS] (state, data) {
    state.list = [...data];
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__SELL_ASSIGNS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  reqeustList({ commit }) {
    commit(REQUEST_LIST__SELL_ASSIGNS);

    api.get('/sellassigns').then(
      reponse => commit(RECEIVE_LIST__SELL_ASSIGNS, reponse.data),
      error => commit(FAILURE_LIST__SELL_ASSIGNS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

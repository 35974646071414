<template>
  <div class="form-label-group mb-3">
    <input
      placeholder=""
      type="tel"
      class="form-control"
      :id="id"
      :class="{ 'is-invalid': error }"
      :disabled="disabled"
      :value="value"
      @input="$emit('input-form', $event.target.value)"
    >
    <label :for="id" class="col-form-label">{{ label }}</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputContact',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

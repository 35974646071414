<template>
  <div class="gradient">
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-12 mb-4 mb-lg-0">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              src="https://www.youtube-nocookie.com/embed/VDW-JnOVq_o?rel=0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SellAssignsVideo',
};
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient($gray, #ffffff);
}
</style>

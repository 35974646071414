<template>
  <div class="border mb-4">
    <div class="p-3">
      <div class="row no-gutters">
        <div class="col-3">
          <ImageOrIcons
            :src="deal.companyLogo"
            icon="building"
          />
        </div>
        <div class="col-9 pl-3">
          <h5 class="mb-0">{{ deal.companyName }}</h5>
          <p class="mb-0">{{ deal.primary ? '신규투자유치' : '매각' }}({{ deal.stockTypeCode | stock_type }})</p>
          <p class="mb-2">규모 {{ deal.expectedAmount | won3 }} 백만원</p>
          <div class="row no-gutters">
            <div class="col pr-2">
              <router-link
                class="btn btn-outline-primary btn-sm w-100"
                :to="{
                  name: '__COMPANY_VIEW__',
                  params: { companyCode: deal.companyCode },
                }"
              >기업정보</router-link>
            </div>
            <div class="col pl-2">
              <router-link
                class="btn btn-outline-primary btn-sm w-100"
                :to="{
                  name: '__PLACEMENTS_LIBRARY__',
                  query: { dealKey: deal.dealKey, dealName: deal.name }
                }"
              >IR자료실</router-link>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <CardListVideosItem
        :video="{
          videoId: deal.videoId,
          thumbnail: deal.videoThumbnail,
        }"
        :currentVideo="currentVideo"
        @select-item="(videoId) => $emit('select-item', videoId)"
      />
    </div>
  </div>
</template>

<script>
import ImageOrIcons from '@/components/icons/ImageOrIcons';
import CardListVideosItem from './CardListVideosItem.vue';

export default {
  name: 'DealCard',
  components: {
    ImageOrIcons,
    CardListVideosItem,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
    currentVideo: {
      type: String,
      required: true,
    },
  },
};
</script>

import api from '@/api/axios';
import {
  REQUEST__DEALS_CONTENTS_PUBLIC,
  RECEIVE__DEALS_CONTENTS_PUBLIC,
  FAILURE__DEALS_CONTENTS_PUBLIC,
} from '@/store/mutation-types';

const state = {
  dealKey: '',
  list: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__DEALS_CONTENTS_PUBLIC] (state, { dealKey }) {
    state.dealKey = dealKey;
    state.status = 'REQUEST';
  },
  [RECEIVE__DEALS_CONTENTS_PUBLIC] (state, data) {
    state.list = data;
    state.status = 'RECEIVE';
  },
  [FAILURE__DEALS_CONTENTS_PUBLIC] (state, error) {
    state.error = error;
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, { dealKey }) {
    commit(REQUEST__DEALS_CONTENTS_PUBLIC, { dealKey });

    api.get(`/public/deals/contents/${dealKey}`).then(
      response => commit(RECEIVE__DEALS_CONTENTS_PUBLIC, response.data),
      error => commit(FAILURE__DEALS_CONTENTS_PUBLIC, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

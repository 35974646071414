<template>
  <div>
    <FormHead
      :title="stock.stockName"
      :text="stock.stockInfoUid"
    />
    <div class="px-3">
      <template v-for="(transaction, index) in list">
        <TransactionsLinksCard
          :key="index"
          :transaction="transaction"
          @routing-confirm="routingConfirm"
        />
      </template>
      <div v-if="!list.length" class="alert alert-info" role="alert">
        연동가능한 매수거래 건이 존재하지 않습니다.
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { SET_STOCK__TRANSACTIONS_LINKS } from '@/store/mutation-types';
import FormHead from '@/components/intents/form/FormHead.vue';
import TransactionsLinksCard from '@/components/transactions/TransactionsLinksCard.vue';

export default {
  name: 'TransactionsLinksView',
  components: {
    FormHead,
    TransactionsLinksCard,
  },
  computed: {
    ...mapState('transactionsLinks', [
      'stock',
      'list',
    ]),
  },
  methods: {
    ...mapMutations('transactionsLinks', {
      'setStock': SET_STOCK__TRANSACTIONS_LINKS,
    }),
    ...mapActions('transactionsLinks', [
      'requestList',
    ]),
    routingConfirm(targetKey) {
      const { transactionKey } = this.$route.params;

      this.$router.push({
        name: 'transactions_links_confirm',
        params: { transactionKey, targetKey },
      });
    }
  },
  mounted() {
    const { stockInfoUid, stockName } = this.$route.query;
    const { transactionKey } = this.$route.params;

    this.setStock({ stockInfoUid, stockName });
    this.requestList(transactionKey);
  },
};
</script>

<template>
  <div class="py-4">
    <div class="border-gray py-2 text-center bg-level-0">
      <p class="mb-0">비상장주식의 투자위험등급 :1등급<span class="d-none d-md-inline">(매우 높은 위험)</span></p>
      <p class="mb-0 text-danger font-weight-bold">비상장주식은 매우 높은 위험의 금융상품으로 <br class="d-md-none"/>공격투자형 고객에게 적합한 상품입니다.</p>
    </div>
    <div class="row mx-0">
      <div
        v-for="(el, index) in list" :key="index"
        class="col border-gray px-0"
        :class="{'border-bold': index === 0}"
      >
        <p
          class="text-center text-white py-1 mb-0"
          :class="`bg-level-${el.level}`"
        >{{ el.level }}</p>
        <div class="d-flex justify-content-center align-items-center box">
          <p class="my-2 line" v-html="el.desc"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const LEVEL_MAP = [
  { level: 1, name: '매우 높은 위험' },
  { level: 2, name: '높은 위험' },
  { level: 3, name: '다소 높은 위험' },
  { level: 4, name: '보통 위험' },
  { level: 5, name: '낮은 위험' },
  { level: 6, name: '매우 낮은 위험' },
];

export default {
  name: 'HelpIntentWarning',
  data() {
    return {
      list: LEVEL_MAP.map(el => ({
        ...el,
        desc: el.name.replaceAll(/ /ig, ' <br class="d-md-none"/>'),
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-level-0 {
  background-color: #FFFF00;
}
.bg-level-1 {
  background-color: #C82E35;
}
.bg-level-2 {
  background-color: #EFBC21;
}
.bg-level-3 {
  background-color: #94C520;
}
.bg-level-4 {
  background-color: #34B0CD;
}
.bg-level-5 {
  background-color: #205699;
}
.bg-level-6 {
  background-color: #46338E;
}
.border-gray {
  border: 1px solid #7F7F7F !important;
}
.border-bold {
  border: 0.25rem solid #41719C !important;
}
.box {
  height: 5rem;
  @media (min-width: 992px) {
    height: unset;
  }
}
.line {
  line-height: 1.2;
}
</style>

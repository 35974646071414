<template>
  <div>
    <div class="modal fade show d-block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-dark">
            <h5 class="modal-title">{{ title }}</h5>
          </div>
          <div class="modal-body text-dark">
            <span v-html="content"></span>
          </div>
          <div class="modal-footer">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: 'AppModalSimple',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  created() {
    document.getElementsByTagName('body')[0].classList.add('modal-open');
  },
  destroyed() {
    document.getElementsByTagName('body')[0].classList.remove('modal-open');
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @media (min-width: 992px) {
    padding-right: 1rem;
  }
}
</style>


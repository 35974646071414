import _ from 'underscore';
import {
  INPUT_FORM__RESET_EMAIL,
  POST_FORM__RESET_EMAIL,
  DONE_FORM__RESET_EMAIL,
  FAIL_FORM__RESET_EMAIL,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    userEmail: '',
    currentPasswd: '',
  },
  error: {},
  status: 'INIT',
};

const getters = {
  enabledForm: state => _.every(state.form),
  loadingForm: state => state.status === 'POST',
};

const mutations = {
  [INPUT_FORM__RESET_EMAIL] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__RESET_EMAIL] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__RESET_EMAIL] (state) {
    state.form.userEmail = '';
    state.form.currentPasswd = '';
    state.status = 'DONE';

    router.push({ name: 'sign_in' });
  },
  [FAIL_FORM__RESET_EMAIL] (state, error) {
    state.error = { ...error },
    state.status = 'FAIL';
  },
};

const actions = {
  submitForm({ dispatch, commit, state }) {
    commit(POST_FORM__RESET_EMAIL);

    const { userEmail, currentPasswd } = state.form;
    api.post('/users/email', { userEmail, currentPasswd }).then(
      response => {
        commit(DONE_FORM__RESET_EMAIL, response.data);
        dispatch('userInfomation/requestUser', null, { root: true });
      },
      error => commit(FAIL_FORM__RESET_EMAIL, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

const STATUS = {
  // 매수자
  BUYER__ACCEPT:      '매수대금을 아래 가상계좌로 입금해 주세요.',
  BUYER__SHARE_IN:    '주식결제일에 고객님 증권계좌로 주식을 이체해 드릴 예정입니다.',
  BUYER__PAY_IN:      '주식결제일에 고객님 증권계좌로 주식을 이체해 드릴 예정입니다.',
  BUYER__COMPLETE_IN: '주식결제일에 고객님 증권계좌로 주식을 이체해 드릴 예정입니다.',
  BUYER__SHARE_OUT:   '주식결제일에 고객님 증권계좌로 주식을 이체해 드릴 예정입니다.',
};

const PAY_STATUS = {
  ACCEPT: '입금대기',
  PAY_IN: '입금완료',
  PAY_WAIT: '입금대기',
  PAY_REQUEST: '입금완료',
  PAY_OUT: '입금완료',
};

const SHARE_STATUS = {
  ACCEPT: '입고대기',
  WAITING_IN: '입고대기',
  SHARE_IN: '입고완료',
  WAITING_OUT: '입고완료',
  SHARE_OUT: '이체완료',
};

export {
  STATUS,
  PAY_STATUS,
  SHARE_STATUS,
};

<template>
  <div>
    <FormHead
      v-if="!isSocialUser"
      title="연락 받으실 휴대전화 문자인증"
      text="휴대전화로 인증번호를 받고 인증해주세요."
    />
    <FormHead
      v-else
      title="카카오로 회원가입"
      text="휴대전화번호 문자인증"
    />
    <form @submit.prevent="postCode">
      <InputGroupButton
        id="mobile"
        inputLabel="휴대전화 ('-' 없이 입력)"
        buttonLabel="요청"
        :value="mobile['value']"
        :status="mobile['status']"
        :feedback="mobile['feed']"
        :enabled="enabledMobile"
        @input-form="(value) => inputForm({ key: 'mobile', value })"
        @post-form="checkMobile"
      />
      <InputGroup
        id="code"
        inputLabel="인증번호 (6자리 숫자)"
        :value="code['value']"
        :status="code['status']"
        :feedback="code['feed']"
        :enabled="enabledCode"
        @input-form="(value) => inputForm({ key: 'code', value })"
      />
      <template v-if="isSocialUser">
        <InputCheckboxSquare
          label="전체동의"
          id="checkAll"
          :value="form['checkAll']"
          @input-form="(value) => checkForm({ key: 'checkAll', value })"
        />
        <InputCheckbox
          label="이용약관"
          id="agreement"
          to="terms"
          :value="form['agreement']"
          @input-form="(value) => checkForm({ key: 'agreement', value })"
        />
        <InputCheckbox
          label="개인정보이용방침"
          id="privacy"
          to="terms"
          :value="form['privacy']"
          @input-form="(value) => checkForm({ key: 'privacy', value })"
        />
      </template>
      <InputCheckbox
        label="투자정보(마케팅) 수신동의"
        requireLabel="선택"
        id="marketing"
        to="terms"
        :value="form['marketing']"
        @input-form="(value) => checkForm({ key: 'marketing', value })"
      />
      <SubmitButton
        label="완료"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
    <div class="border p-3 mb-3" role="alert">
      <h6 class="alert-heading">문자인증을 요청하는 이유</h6>
      <p class="mb-0">해당 휴대전화번호로 SMS 또는 카카오 알림톡을 통해 거래요청, 매매거래 등의 진행상태 등을 알려드리기 위해서입니다.</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/auth/form/FormHead';
import InputGroupButton from '@/components/user/form/InputGroupButton.vue';
import InputGroup from '@/components/user/form/InputGroup.vue';
import InputCheckboxSquare from '@/components/auth/form/InputCheckboxSquare.vue';
import InputCheckbox from '@/components/auth/form/InputCheckbox.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';
import {
  INIT_FORM__USER_MOBILE,
  INPUT_FORM__USER_MOBILE,
  CHECK_FORM__USER_MOBILE,
} from '@/store/mutation-types';

export default {
  name: 'MobileView',
  components: {
    FormHead,
    InputGroupButton,
    InputGroup,
    InputCheckboxSquare,
    InputCheckbox,
    SubmitButton,
  },
  computed: {
    ...mapState('userMobile', [
      'mobile',
      'code',
      'form',
    ]),
    ...mapGetters('userMobile', [
      'enabledMobile',
      'enabledCode',
      'enabledForm',
      'loadingForm',
    ]),
    ...mapGetters('userInfomation', [
      'isSocialUser',
    ]),
  },
  methods: {
    ...mapMutations('userMobile', {
      'initForm': INIT_FORM__USER_MOBILE,
      'inputForm': INPUT_FORM__USER_MOBILE,
      'checkForm': CHECK_FORM__USER_MOBILE,
    }),
    ...mapActions('userMobile', [
      'checkMobile',
      'postCode',
    ]),
  },
  mounted() {
    if (!this.isSocialUser) {
      this.checkForm({ key: 'agreement', value: true });
      this.checkForm({ key: 'privacy', value: true });
    }
  },
};
</script>

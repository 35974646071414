<template>
  <form @submit.prevent="handleSearch">
    <div class="form-label-group input-group text-left">
      <input
        id="input-markets-search"
        type="search"
        class="form-control"
        :placeholder="label"
        v-model="query"
      >
      <label for="input-markets-search">{{ label }}</label>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="submit" aria-label="검색 버튼">
          <IconSearch class="icon-sm" />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import IconSearch from '@/components/icons/IconSearch.vue';

export default {
  name: 'MarketsSearch',
  components: {
    IconSearch,
  },
  data() {
    return {
      query: '',
    };
  },
  props: {
    label: {
      type: String,
      default: '검색어를 입력해주세요.',
    },
  },
  methods: {
    handleSearch() {
      if (this.query) {
        this.$router.push({
          name: 'markets_search',
          query: { 'query': this.query },
        });
      }
    },
    setQuery() {
      const { stockName, query } = this.$route.query;
      if (stockName) {
        this.query = stockName;
      } else if (query) {
        this.query = query;
      } else {
        this.query = '';
      }
    },
  },
  mounted() {
    this.setQuery();
  },
};
</script>

<template>
  <div class="form-group mb-3">
    <div class="custom-control custom-checkbox pl-0">
      <input
        type="checkbox"
        class="custom-control-input d-none"
        :class="{ 'is-invalid': error }"
        :id="id"
        :checked="value"
        @change="$emit('input-form', $event.target.checked)"
      >
      <label class="h5" :for="id">{{ label }}</label>
      <IconCheckSquare
        v-if="!value"
        class="icon-md ml-3 text-light"
        @click="toggleValue"
      />
      <IconCheckSquareFill
        v-if="value"
        class="icon-md ml-3 text-secondary"
        @click="toggleValue"
      />
      <div class="invalid-feedback">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import IconCheckSquare from '@/components/icons/IconCheckSquare.vue';
import IconCheckSquareFill from '@/components/icons/IconCheckSquareFill.vue';

export default {
  name: 'InputCheckboxSquare',
  components: {
    IconCheckSquare,
    IconCheckSquareFill,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  methods: {
    toggleValue() {
      this.$emit('input-form', !this.value);
    },
  },
};
</script>

<template>
  <div>
    <NegoLinksTop
      :groups="LinkGroups"
      :group="group"
    />
    <NegoLinksBottom
      :groups="LinkGroups"
    />
  </div>
</template>

<script>
import NegoLinksTop from './NegoLinksTop.vue';
import NegoLinksBottom from './NegoLinksBottom.vue';
import { LinkGroups } from './negoLinks.js';

export default {
  name: 'NegoLinks',
  components: {
    NegoLinksTop,
    NegoLinksBottom,
  },
  props: {
    group: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      LinkGroups,
    };
  },
};
</script>

<template>
  <img
    v-if="isLoadingSuccess"
    class="image rounded-0 h-100 w-100 border"
    :class="`image-${object}`"
    :src="src"
    @error="handleError"
  />
  <div v-else class="d-flex justify-content-center align-items-center h-100 w-100 border bg-white">
    <p class="text-reset text-truncate px-1 mb-0">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'ImageOrTitle',
  props: {
    src: {
      type: String,
      required: false,
    },
    object: {
      type: String,
      default: 'contain',
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingSuccess: true,
    };
  },
  methods: {
    handleError() {
      this.isLoadingSuccess = false;
    },
  },
  beforeMount() {
    if (!this.src) {
      this.handleError();
    }
  },
  watch: {
    src(val) {
      this.isLoadingSuccess = val ? true : false;
    },
  },
};
</script>

<style scoped>
.image {
  background-color: #fff;
}
.image-contain {
  object-fit: contain;
}
.image-cover {
  object-fit: cover;
}
p {
  font-size: .75rem;
}
</style>

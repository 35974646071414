import {
  REQUEST_LIST__TRANSACTIONS_PROGRESS,
  RECEIVE_LIST__TRANSACTIONS_PROGRESS,
  FAILURE_LIST__TRANSACTIONS_PROGRESS,
  POST_NOTICE__TRANSACTIONS_PROGRESS,
  DONE_NOTICE__TRANSACTIONS_PROGRESS,
  FAIL_NOTICE__TRANSACTIONS_PROGRESS,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';
import { FintechCommApi } from '@/utils/constants';

const state = {
  transactions: {
    list: [],
    params: {},
    error: {},
    status: 'INITIAL',
  },
  notice: {
    error: {},
    status: 'INITIAL',
  },
};

const getters = {
  loadingList: (state) => state.transactions.status === 'REQUEST',
  loadingNotice: (state) => state.notice.status === 'POST',
};

const mutations = {
  [REQUEST_LIST__TRANSACTIONS_PROGRESS] (state) {
    state.transactions.error = {};
    state.transactions.status = 'REQUEST';
  },
  [RECEIVE_LIST__TRANSACTIONS_PROGRESS] (state, { contents, ...params }) {
    state.transactions.list = [...contents];
    state.transactions.params = { ...params };
    state.transactions.error = {};
    state.transactions.status = 'RECEIVE';
  },
  [FAILURE_LIST__TRANSACTIONS_PROGRESS] (state, error) {
    state.transactions.error = { ...error };
    state.transactions.status = 'FAILURE';
  },
  [POST_NOTICE__TRANSACTIONS_PROGRESS] (state) {
    state.notice.error = {};
    state.notice.status = 'POST';
  },
  [DONE_NOTICE__TRANSACTIONS_PROGRESS] (state) {
    state.notice.status = 'DONE';

    router.replace({ name: 'transactions_progress' });
  },
  [FAIL_NOTICE__TRANSACTIONS_PROGRESS] (state) {
    state.notice.error = { auth: '처리 실패. 잠시 후 다시 실행하시거나 관리자에게 문의 부탁드립니다.' };
    state.notice.status = 'FAIL';
  },
};

const actions = {
  requestList({ commit }, { page = 1, scale = 6 }) {
    commit(REQUEST_LIST__TRANSACTIONS_PROGRESS);

    api.get('/tx/ing', { params: { page, scale }}).then(
      response => commit(RECEIVE_LIST__TRANSACTIONS_PROGRESS, response.data),
      error => commit(FAILURE_LIST__TRANSACTIONS_PROGRESS, { error }),
    );
  },
  noticeShareIn({ commit }, { transactionKey }) {
    commit(POST_NOTICE__TRANSACTIONS_PROGRESS);

    api.get(`${FintechCommApi}/public/share/in/${transactionKey}`).then(
      () => setTimeout(() => commit(DONE_NOTICE__TRANSACTIONS_PROGRESS), 2000),
      error => commit(FAIL_NOTICE__TRANSACTIONS_PROGRESS, error.response.data),
    );
  },
  noticePayIn({ commit }, { transactionKey }) {
    commit(POST_NOTICE__TRANSACTIONS_PROGRESS);

    api.get(`${FintechCommApi}/public/pay/in/${transactionKey}`).then(
      () => setTimeout(() => commit(DONE_NOTICE__TRANSACTIONS_PROGRESS), 2000),
      error => commit(FAIL_NOTICE__TRANSACTIONS_PROGRESS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

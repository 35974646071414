<template>
  <div>
    <button
      v-for="([key, value], index) in list"
      :key="index"
      class="btn btn-sm rounded-pill stretched-link position-relative mr-1 mb-1"
      :class="hasSelected(key) ? 'btn-primary' : 'btn-snow'"
      @click="handleToggle(key)"
    >#{{ value }}</button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  TOGGLE__KEYWORDS,
} from '@/store/mutation-types';

export default {
  name: 'KeywordButtons',
  props: {
    // [[key, value]]
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('keywords', [
      'hasSelected',
    ]),
  },
  methods: {
    ...mapMutations('keywords', {
      toggleItem: TOGGLE__KEYWORDS,
    }),
    handleToggle(key) {
      this.toggleItem(key);
      this.$emit('toggle-item');
    },
  }
};
</script>


<style scoped>
.btn-sm {
  font-size: .75rem;
  line-height: 1.25;
}
</style>

<template>
  <div class="form-label-group mb-3">
    <input
      type="number"
      class="form-control"
      :class="{ 'is-invalid': error }"
      :id="id"
      :value="value"
      :placeholder="label"
      @input="$emit('input-form', $event.target.value)"
      :disabled="disabled"
    >
    <label :for="id">{{ label }}</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputNumber',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <AppModalSimple
    title="네고스타 차단목록 해제"
    content="차단해제 하시겠습니까?"
  >
    <div class="d-flex w-100">
      <button class="btn btn-outline-dark flex-grow-1 mr-3" @click="onClose">취소</button>
      <button class="btn btn-outline-dark flex-grow-1" @click="onDelete">해제</button>
    </div>
  </AppModalSimple>
</template>

<script>
import { mapActions } from 'vuex';
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'IntentsBlocksDeleteView',
  components: {
    AppModalSimple,
  },
  methods: {
    ...mapActions('intentsBlocks', [
      'deleteItem',
    ]),
    onClose() {
      this.$router.replace({ name: 'intents_blocks' });
    },
    onDelete() {
      const { intentBlockKey } = this.$route.params;
      this.deleteItem({ intentBlockKey });
    },
  },
};
</script>

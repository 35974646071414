<template>
  <div class="bottom d-block d-md-none fixed-bottom border-top bg-white">
    <ul class="nav nav-justified">
      <li class="nav-item" v-for="({ children, label, icon }, idx) in groups" :key="idx">
        <router-link
          class="nav-link d-inline-block"
          :class="{'active': children.some(el => el.name === $route.name) }"
          :to="{ name: children[0].name }"
        >
          <img :src="icon" class="icon" /><p class="mb-0 font-weight-bold">{{ label }}</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NegoLinksBottom',
  props: {
    groups: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 2rem;
}
.bottom {
  z-index: 1029;
  .nav-link {
    color: #000000;
    font-size: 0.75rem;
  }
  .nav-link.active p {
    background-color: #ffc107;
  }
}
</style>

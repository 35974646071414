<template>
  <div class="custom-file mb-3">
    <input
      type="file"
      class="custom-file-input"
      :class="{ 'is-invalid': error }"
      :id="id"
      @change="handleFile"
    >
    <label
      class="custom-file-label"
      :for="id"
    >{{ filename || label }}</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputFile',
  data() {
    return {
      filename: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },

    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '파일을 첨부해주세요',
    },
  },
  methods: {
    handleFile(e) {
      const file = e.target.files[0];
      this.$emit('input-form', file);
      this.filename = file.name;
    },
  },
};
</script>

<style scoped>
.custom-file,
.custom-file-input,
.custom-file-label {
  height: calc(3em + 2px);
}
.custom-file-label,
.custom-file-label::after {
  line-height: 2.3;
}
.custom-file-label::after {
  height: calc(3em);
  content: "파일첨부";
}
</style>

<template>
  <div class="chat-content">
    <!-- Header -->
    <div class="chat-header d-flex justify-content-between border-bottom p-2">
      <div class="flex-shrink-0 align-self-center mr-2">
        <div class="spinner-border" role="status" v-if="loadingStatus">
          <span class="sr-only">Loading...</span>
        </div>
        <button class="btn btn-link btn-sm text-reset" v-else @click="onClose">
          <IconArrowLeftShort class="icon-sm"/>
        </button>
      </div>
      <div class="flex-grow-1 flex-shrink-1 text-truncate">
        <h6 class="text-truncate mb-1">{{ room.stock || '&nbsp;' }}</h6>
        <p class="mb-0 font-12">{{ roomInfo.hopeQuantity }}주 / {{ roomInfo.hopePrice }}원 / {{ roomInfo.expiration | date_middle }}</p>
      </div>
    </div>
    <!-- Body -->
    <div class="chat-body px-2" ref="chatBody">
      <template v-for="({ text, pid, time}, idx) in room.messages">
        <div class="media mb-2" :key="idx">
          <IconPeopleCircle class="icon-sm align-self-center mr-1" v-if="pid !== roomInfo.publicId"/>
          <div class="media-body">
            <p class="mb-0" v-if="pid !== roomInfo.publicId">{{ roomInfo.otherName }}</p>
            <div class="d-flex" :class="{'flex-row-reverse': pid === roomInfo.publicId}">
              <div class="message flex-shrink-1 text-break rounded p-1" :class="{'message-me': pid === roomInfo.publicId}">{{ text }}</div>
              <small class="flex-shrink-0 align-self-end px-1 font-10">{{ time | time }}</small>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- Footer -->
    <div class="fixed-bottom bg-light" v-if="!disableChat">
      <form @submit.prevent="onSubmit" class="w-100">
        <fieldset>
          <div class="input-group">
            <textarea
              ref="textMessage"
              type="text"
              class="form-control shadow-none rounded-0 border-0"
              placeholder="type message..."
              aria-describedby="button-send"
              rows="1"
              cols="23"
              @focus="scrollTop"
            />
            <div class="input-group-append">
              <button class="btn btn-secondary rounded-0" type="submit" id="button-add">보내기</button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import IconArrowLeftShort from '@/components/icons/IconArrowLeftShort.vue';
import IconPeopleCircle from '@/components/icons/IconPeopleCircle.vue';

export default {
  name: 'IntentsChatContent',
  components: {
    IconArrowLeftShort,
    IconPeopleCircle,
  },
  props: {
    roomInfo: {
      type: Object,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
    loadingStatus: {
      type: Boolean,
      default: false,
    },
    disableChat: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      window.close();
    },
    onSubmit() {
      const $message = this.$refs.textMessage;
      $message.focus();
      const text = $message.value.trim();
      if (text) {
        this.$emit('send-message', text);
        $message.value = '';
      }
    },
    scrollTop() {
      this.$refs.chatBody.scrollTop = this.$refs.chatBody.scrollHeight;
    },
  },
  watch: {
    room() {
      this.$nextTick(this.scrollTop);
    },
  },
};
</script>

<style lang="scss" scoped>
.font-10 {
  font-size: 0.625rem;
}
.font-12 {
  font-size: 0.75rem;
}
.message {
  font-size: 0.875rem;
  background-color: #ffffff;
}
.message-me {
  background-color: #ffeb00;
}
.chat-header {
  height: 3.5rem;
  background-color: #a0c0d7;
}
.chat-body {
  overflow-y: auto;
  padding-top: .5rem;
  height: calc(100vh - 5.875rem);
  padding-bottom: .5rem;
  background-color: #a0c0d7;
}
.form-control:focus {
  border-color: unset;
}
</style>

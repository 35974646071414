<template>
  <div>
    <FormHead
      title="비밀번호 초기화"
      text="새로운 비밀번호로 초기화합니다."
    />
    <form @submit.prevent="submitForm">
      <InputPassword
        id="newPasswd"
        :value="form['newPasswd']"
        :error="error['newPasswd']"
        @input-form="(value) => inputForm({ key: 'newPasswd', value })"
      />
      <InputPassword
        label="비밀번호 확인"
        id="confirmPasswd"
        :value="form['confirmPasswd']"
        :error="error['confirmPasswd']"
        @input-form="(value) => inputForm({ key: 'confirmPasswd', value })"
      />
      <SubmitButton
        label="초기화"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { INPUT_FORM__RESET_PASSWORD } from '@/store/mutation-types';
import FormHead from '@/components/auth/form/FormHead';
import InputPassword from '@/components/auth/form/InputPassword.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';

export default {
  name: 'ResetPasswordView',
  components: {
    FormHead,
    InputPassword,
    SubmitButton,
  },
  computed: {
    ...mapState('authResetPassword', [
      'form',
      'error',
    ]),
    ...mapGetters('authResetPassword', [
      'enabledForm',
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('authResetPassword', {
      'inputForm': INPUT_FORM__RESET_PASSWORD,
    }),
    ...mapActions('authResetPassword', [
      'submitForm',
    ]),
  },
  mounted() {
    const { code } = this.$route.params;
    if (code) {
      this.inputForm({ key: 'code', value: code });
    } else {
      this.$router.push({ name: 'find_password' });
    }
  },
};
</script>

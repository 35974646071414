<template>
  <AppModalSimple
    title="네고스탁 차단목록"
    content="차단 하시겠습니까?"
  >
    <div class="d-flex w-100">
      <button class="btn btn-outline-dark flex-grow-1 mr-3" @click="onClose">취소</button>
      <button class="btn btn-outline-dark flex-grow-1" @click="onBlock">차단</button>
    </div>
  </AppModalSimple>
</template>

<script>
import { mapActions } from 'vuex';
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'IntentsRecentBlockView',
  components: {
    AppModalSimple,
  },
  methods: {
    ...mapActions('intentsRecent', [
      'blockItem',
    ]),
    onClose() {
      this.$router.replace({ name: 'intents_recent' });
    },
    onBlock() {
      const { intentKey } = this.$route.params;
      this.blockItem({ intentKey });
    },
  },
};
</script>

<template>
  <div>
    <FormHead title="네고스탁 주식이체 완료"/>
    <p>주식이체 완료 통보 하시겠습니까?</p>
    <form @submit.prevent="submitForm">
      <SubmitButton
        label="확인"
        :enabledForm="true"
        :loadingStatus="loadingNotice"
      />
      <p class="text-danger" v-if="error.auth">{{ error.auth }}</p>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormHead from '@/components/auth/form/FormHead.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';

export default {
  name: 'TransactionsShareInView',
  components: {
    FormHead,
    SubmitButton,
  },
  computed: {
    ...mapState('transactionsProgress', {
      error: state => state.notice.error,
      status: state => state.notice.status,
    }),
    ...mapGetters('transactionsProgress', [
      'loadingNotice',
    ]),
  },
  methods: {
    ...mapActions('transactionsProgress', [
      'noticeShareIn',
    ]),
    submitForm() {
      const { transactionKey } = this.$route.params;
      this.noticeShareIn({ transactionKey });
    },
  },
};
</script>

<template>
  <div>
    <FormHead
      :title="form.stockName"
      :text="form.stockInfoUid"
    />
    <form @submit.prevent="handleSubmit">
      <InputPrice
        label="주당가격(원)"
        :disabled="true"
        id="price"
        :value="form['price']"
        :error="error['price']"
      />
      <InputQuantity
        id="quantity"
        :value="form['quantity']"
        :error="error['quantity']"
        @input-form="(value) => inputForm({ key: 'quantity', value })"
        :label="`매수수량 (최소 ${form.minimumOrderQuantity} 주 이상)`"
      />
      <InputTotalDisable
        id="totalPrice"
        :value="form['price'] * form['quantity']"
        label="거래금액(원)"
      />
      <h6>유의사항</h6>
      <ol class="pl-4">
        <li>바로 매수 신청 후, 1시간 이내 지정한 가상계좌로 매수대금을 입금하시지 않을 경우, 신청 취소됩니다.</li>
        <li>입금 확인 후 30분 이내에 미리 등록해 두신 계좌로 매수한 주식을 입고 받으실 수 있습니다.</li>
        <li>본 매수 신청이 실제 주식 매수를 100% 보장하지는 않으며, 매수가 이루어지지 않을 경우, 입금 후 최대 2시간 이내에 대금을 반환해 드립니다.</li>
      </ol>
      <InputCheckbox
        id="agreeForm"
        :value="form['agreeForm']"
        :error="error['agreeForm']"
        @input-form="(value) => inputForm({ key: 'agreeForm', value })"
        label="유의사항 확인함"
      />
      <div class="alert alert-danger" role="alert" v-if="error['orderable']">
        {{ error['orderable'] }}
      </div>
      <SubmitButton
        label="바로매수 신청 (유저)"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <NegoModalAccount ref="negoModalAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputQuantity from '@/components/intents/form/InputQuantity.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import InputTotalDisable from '@/components/intents/form/InputTotalDisable.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import NegoModalAccount from '@/components/nego/NegoModalAccount.vue';
import {
  INIT_FORM__SELL_ASSIGNS_ORDER,
  INPUT_FORM__SELL_ASSIGNS_ORDER,
} from '@/store/mutation-types';
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'SellAssignsOrderUserView',
  components: {
    FormHead,
    InputQuantity,
    InputPrice,
    InputTotalDisable,
    SubmitButton,
    InputCheckbox,
    NegoModalAccount,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('sellAssignsOrder', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('sellAssignsOrder', [
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('sellAssignsOrder', {
      'initForm': INIT_FORM__SELL_ASSIGNS_ORDER,
      'inputForm': INPUT_FORM__SELL_ASSIGNS_ORDER,
    }),
    ...mapActions('sellAssignsOrder', [
      'checkOrder',
      'checkForm',
      'submitForm',
    ]),
    handleSubmit() {
      const {
        userKey,
        preUser,
        kassetAccountStatus,
        brokerAccountStatus,
        bankAccountStatus,
      } = this.user;

      // 로그인 X
      if (!userKey) {
        const { path, query } = this.$route;
        this.$router.push({
          name: 'sign_in',
          query: {
            ...query,
            redirect_path: path,
          },
        });
        return;
      }

      // 휴대전화인증 X
      if (preUser) {
        this.$router.push({
          name: 'user_mobile',
        });
        return;
      }

      // 비대면계좌 처리대기 중
      if (kassetAccountStatus === 'READY') {
        this.$refs.negoModalAccount.openModal();
        return;
      }

      // 증권,은행계좌 미등록
      if (
        kassetAccountStatus === 'NONE'
        && brokerAccountStatus === 'NONE'
        && bankAccountStatus === 'NONE'
      ) {
        const {
          stockInfoUid, stockName,
          price, quantity, minimumOrderQuantity,
          sellAssignKey,
        } = this.$route.query;
        const PATH = [
          `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
          `?path=/users/account`,
          `&redirect_path=${NegoStockWeb}/sell-assigns/order-user`,
          `&stockInfoUid=${stockInfoUid}`,
          `&stockName=${encodeURIComponent(stockName)}`,
          `&price=${price}`,
          `&quantity=${quantity}`,
          `&minimumOrderQuantity=${minimumOrderQuantity}`,
          `&sellAssignKey=${sellAssignKey}`,
        ].join('');
        window.location = PATH;
        return;
      }
      this.checkForm();
    },
  },
  mounted() {
    const {
      stockInfoUid, stockName, sellAssignKey, price, minimumOrderQuantity,
    } = this.$route.query;
    this.inputForm({ key: 'stockInfoUid', value: stockInfoUid });
    this.inputForm({ key: 'stockName', value: stockName });
    this.inputForm({ key: 'sellAssignKey', value: sellAssignKey });
    this.inputForm({ key: 'price', value: parseInt(price, 10) });
    this.inputForm({ key: 'quantity', value: parseInt(minimumOrderQuantity, 10) });
    this.inputForm({ key: 'minimumOrderQuantity', value: parseInt(minimumOrderQuantity, 10) });

    const { userKey, preUser } = this.user;
    if (userKey && !preUser) {
      this.checkOrder();
    }
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

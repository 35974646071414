const FormStatus = {
  INIT: 'INIT',
  POST: 'POST',
  DONE: 'DONE',
  FAIL: 'FAIL',
};

export {
  FormStatus,
};

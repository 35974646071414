<template>
  <div class="list-group">
    <template v-for="({ aIndex, label }, index) in question.options">
      <button
        :key="index"
        type="button"
        class="list-group-item list-group-item-action"
        :class="{ 'active' : isActive(aIndex) }"
        @click.prevent="$emit('input-form', { qIndex: question.qIndex, aIndex })"
      >{{ label }}</button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SelectButtonGroup',
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isActive(aIndex) {
      return this.question.selected.includes(aIndex);
    },
  },
};
</script>

<template>
    <div class="card">
      <template v-if="video.videoId !== currentVideo">
        <div class="card-img-top"
          @click="$emit('select-item', video.videoId)"
        >
          <img :src="video.thumbnail" alt="" class="img-fluid w-100">
        </div>
      </template>
      <template v-if="video.videoId === currentVideo">
        <CardListVideosItemPlayer :videoId="video.videoId" />
      </template>
      <div class="card-body p-2">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h6 class="card-title mb-0">{{ video.title }}</h6>
            <div class="d-flex justify-content-between">
              <p class="card-text text-truncate mb-0">{{ video.channelName }}</p>
              <router-link
                v-if="video.companyName"
                class="btn btn-link btn-sm flex-shrink-0 p-0"
                :to="{
                  name: '__COMPANY_VIEW__',
                  params: { companyCode: video.companyCode }
                }"
              >#{{ video.companyName }}</router-link>
            </div>
          </div>
          <div class="flex-shrink-0" v-if="enableDelete">
            <Dropdowns
              btnClass="btn-outline-primary btn-sm"
              :id="video.videoId"
              @remove-item="$emit('remove-item', video.companyVideoUid)"
            />
          </div>
          <div class="flex-shrink-0" v-if="enableCreate">
            <button
              class="btn btn-outline-primary btn-sm ml-4"
              @click="$emit('create-item', video)"
            >복사추가</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Dropdowns from '@/components/deals/Dropdowns.vue';
import CardListVideosItemPlayer from './CardListVideosItemPlayer.vue';

export default {
  name: 'CardListVideosItem',
  components: {
    Dropdowns,
    CardListVideosItemPlayer,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    currentVideo: {
      type: String,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      default: false,
    },
    enableCreate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.card-img-top {
  cursor: pointer;
}
.card-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

const Status = {
  CREATE: 'CREATE',
  CONTRACT: 'CONTRACT',
  BROKEN: 'BROKEN',
  ACCEPT: 'ACCEPT',
  PAY_IN: 'PAY_IN',
  COMPLETE_IN: 'COMPLETE_IN',
  SHARE_OUT: 'SHARE_OUT',
  COMPLETE_OUT: 'COMPLETE_OUT',
};
const STATUS__TYPE = [
  { value: Status.CREATE,       label: '생성' },
  { value: Status.CONTRACT,     label: '가체결' },
  { value: Status.BROKEN,       label: '취소' },
  { value: Status.ACCEPT,       label: '미입금' },
  { value: Status.PAY_IN,       label: '입금완료' },
  { value: Status.COMPLETE_IN,  label: '결제처리중' },
  { value: Status.SHARE_OUT,    label: '결제처리중' },
  { value: Status.COMPLETE_OUT, label: '결제처리완료' },
];

const SubscriptionStatus = {
  COMMIT: 'COMMIT',
  SIGN_NOTI: 'SIGN_NOTI',
  SIGN_ASK: 'SIGN_ASK',
  SIGN_FIN: 'SIGN_FIN',
  SIGN_EXPIRE: 'SIGN_EXPIRE',
  FILE_UP: 'FILE_UP',
  FILE_EXPIRE: 'FILE_EXPIRE',
  BROKEN: 'BROKEN',
};
const SUBSCRIPTION_STATUS__TYPE = [
  { value: SubscriptionStatus.COMMIT,      label: '미진행' },
  { value: SubscriptionStatus.SIGN_NOTI,   label: '서명요청' },
  { value: SubscriptionStatus.SIGN_ASK,    label: '서명진행중' },
  { value: SubscriptionStatus.SIGN_FIN,    label: '서명완료' },
  { value: SubscriptionStatus.SIGN_EXPIRE, label: '기간만료' },
  { value: SubscriptionStatus.FILE_UP,     label: '파일갱신' },
  { value: SubscriptionStatus.FILE_EXPIRE, label: '청약변경필요' },
  { value: SubscriptionStatus.BROKEN,      label: '청약취소' },
];

const placement_file = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
};
const PLACEMENT_FILE__KIND = [
  { value: placement_file.A, label: '결성총회안내문' },
  { value: placement_file.B, label: '결성총회조합원 서류(개인)' },
  { value: placement_file.C, label: '결성총회조합원 서류(법인)' },
  { value: placement_file.D, label: '상품설명서' },
  { value: placement_file.E, label: '조합규약' },
  { value: placement_file.F, label: '결성총회 의안 설명서' },
];
const PLACEMENT_FILE_ADDITIONAL__KIND = [
  { value: placement_file.A, label: '추가출자 안내문' },
  { value: placement_file.B, label: '추가출자 서류(개인)' },
  { value: placement_file.C, label: '추가출자 서류(법인)' },
  { value: placement_file.D, label: '상품설명서' },
  { value: placement_file.E, label: '조합규약' },
  { value: placement_file.F, label: '동의사항 등 설명서' },
];

export {
  Status,
  STATUS__TYPE,
  SubscriptionStatus,
  SUBSCRIPTION_STATUS__TYPE,
  placement_file,
  PLACEMENT_FILE__KIND,
  PLACEMENT_FILE_ADDITIONAL__KIND,
};

<template>
  <div class="bg-snow p-2 p-md-3 mb-3" v-if="files.length">
    <ul class="list-unstyled mb-0">
      <template v-for="(file, index) in files" >
        <li
          v-if="!file.name.startsWith('editor::')"
          :key="index"
          class="text-break"
        >
          <a
            v-if="mode === 'DOWN'"
            :href="`${NegoStockApi}/public/newsletter/file/${file.marketingAdFileKey}`"
            target="_blank"
            class="btn btn-link align-baseline"
            download
          ><IconDownload class="icon-xs" /></a>
          <button
            v-if="mode === 'FORM'"
            class="btn btn-link align-baseline"
            @click.prevent="$emit('delete-file', file.marketingAdFileKey)"
          ><IconXSquare class="icon-xs" /></button>
          <span>{{ file.name }}</span>
          <small class="ml-3">{{ file.size | file_size }}</small>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload.vue';
import IconXSquare from '@/components/icons/IconXSquare.vue';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'NewsletterFiles',
  components: {
    IconDownload,
    IconXSquare,
  },
  data() {
    return {
      NegoStockApi,
    };
  },
  props: {
    mode: {
      type: String,
      default: 'DOWN', // DOWN | FORM
    },
    files: {
      type: Array,
      required: true,
    },
  },
};
</script>


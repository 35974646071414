<template>
  <div class="form-label-group input-group mb-3">
    <input
      :placeholder="label"
      type="number"
      class="form-control"
      min="0"
      :class="{ 'is-invalid': error }"
      :id="id"
      :disabled="disabled"
      :value="value"
      @input="$emit('input-form', parseInt($event.target.value, 10))"
    >
    <label :for="id" class="col-form-label">{{ label }}</label>
    <div class="input-group-append">
      <span class="input-group-text">주</span>
    </div>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'InputQuantity',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '수량 (주)',
    },
  },
};
</script>

<template>
  <div class="d-flex justify-content-between">
    <div class="flex-grow-1 flex-shrink-1 text-truncate text-left mr-1">
      <h4 class="title text-truncate mb-0" :title="stockName">{{ stockName }}</h4>
      <p class="sub-left mb-0">({{ stockCode }})</p>
    </div>
    <div class="flex-shrink-0 text-center d-lg-none">
      <button class="btn btn-outline-light text-white" @click="$emit('click-button')">회원가입</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntentsCardHeader',
  props: {
    stockName: {
      type: String,
      required: true,
    },
    stockCode: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-font(1.5rem, 700, 1, -1.7px, inherit);
}
.sub-left {
  @include nego-font(1rem, 300, 1.5, 0.8px, inherit);
}
.sub-right {
  @include nego-font(1rem, 300, 1.5, 3.2px, inherit);
}
</style>

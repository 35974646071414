<template>
  <div class="custom-control custom-checkbox mb-3 pl-0">
    <IconCheckCircleFill
      class="icon-sm mr-1"
      :class="[value ? 'text-secondary' : 'text-light']"
      @click="toggleValue"
    />
    <input
      type="checkbox"
      class="custom-control-input d-none"
      :class="{ 'is-invalid': error }"
      :id="id"
      :checked="value"
      @change="$emit('input-form', $event.target.checked)"
    >
    <label class="" :for="id">{{ label }}</label>
    <div class="invalid-feedback">{{ error }}</div>
  </div>
</template>

<script>
import IconCheckCircleFill from '@/components/icons/IconCheckCircleFill.vue';

export default {
  name: 'InputCheckbox',
  components: {
    IconCheckCircleFill,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '확인',
    },
  },
  methods: {
    toggleValue() {
      this.$emit('input-form', !this.value);
    },
  },
};
</script>

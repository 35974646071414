const STATUS = {
  // 매수자
  BUYER__ACCEPT: '구매대금을 아래 가상계좌로<br> 입금해주세요.',
  BUYER__SHARE_IN: '구매대금을 아래 가상계좌로<br> 입금해주세요.',
  BUYER__PAY_IN: '조합원명부 수정을 기다리는 중',
  BUYER__COMPLETE_IN: '조합원명부 수정을 기다리는 중',
  BUYER__SHARE_OUT: '조합원명부 등록 완료',
  // // 매도자
  SELLER__ACCEPT: '구매자의 대금입금을 기다리는 중',
  SELLER__SHARE_IN: '구매자의 대금입금을 기다리는 중',
  SELLER__PAY_IN: '조합원명부 수정을 기다리는 중',
  SELLER__COMPLETE_IN: '조합원명부 수정을 기다리는 중',
  SELLER__SHARE_OUT: '회원님 계좌로 판매 대금이 곧 입금 될 예정',
};

const PAY_STATUS = {
  ACCEPT: '입금대기',
  PAY_IN: '입금완료',
  PAY_WAIT: '입금대기',
  PAY_REQUEST: '입금완료',
  PAY_OUT: '지급대기',
};

const SHARE_STATUS = {
  // ACCEPT: '입고대기',
  // WAITING_IN: '입고대기',
  // SHARE_IN: '입고완료',
  WAITING_OUT: '명부수정중',
  SHARE_OUT: '명부수정완료',
};

export {
  STATUS,
  PAY_STATUS,
  SHARE_STATUS,
};

import _ from 'underscore';
import api from '@/api/axios';
import {
  REQUEST_KEY__CONSULT_CREATE,
  RECEIVE_KEY__CONSULT_CREATE,
  FAILURE_KEY__CONSULT_CREATE,
  CHECK_FORM__CONSULT_CREATE,
  INPUT_FORM__CONSULT_CREATE,
  POST_FORM__CONSULT_CREATE,
  DONE_FORM__CONSULT_CREATE,
  FAIL_FORM__CONSULT_CREATE,
} from '@/store/mutation-types';
import router from '@/router';

const INIT_FORM = {
  consultKey: '',
  title: '',
  contents: '',
  contentsMime: 'text/plain', // or 'text/html'
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  enabledForm: state => _.every(state.form),
  loadingForm: state => state.status === 'REQUEST' || state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [REQUEST_KEY__CONSULT_CREATE] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_KEY__CONSULT_CREATE] (state, data) {
    const { consultKey } = data;
    state.form.consultKey = consultKey;
    state.status = 'RECEIVE';
  },
  [FAILURE_KEY__CONSULT_CREATE] (state, error) {
    state.error = error;
    state.status = 'FAILURE';
  },
  [CHECK_FORM__CONSULT_CREATE] (state) {
    const { title, contents } = state.form;
    const error = {};

    if (!title) {
      error.title = '제목을 입력해주세요';
    }
    if (!contents) {
      error.contents = '내용을 입력해주세요.';
    }

    state.error = { ...error };
  },
  [INPUT_FORM__CONSULT_CREATE] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__CONSULT_CREATE] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__CONSULT_CREATE] (state) {
    state.status = 'DONE';

    router.replace({
      name: '__CONSULT_LIST__',
    });
  },
  [FAIL_FORM__CONSULT_CREATE] (state) {
    state.status = 'FAIL';
  },

};

const actions = {
  requestKey({ commit }) {
    commit(REQUEST_KEY__CONSULT_CREATE);

    api.get('/consult/key').then(
      response => commit(RECEIVE_KEY__CONSULT_CREATE, response.data),
      error => commit(FAILURE_KEY__CONSULT_CREATE, error.response.data),
    );
  },
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__CONSULT_CREATE);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
 },
  submitForm({ commit, state }) {
    commit(POST_FORM__CONSULT_CREATE);
    const { consultKey, title, contents, contentsMime } = state.form;
    const data = {
      consultKey,
      title,
      contents,
      contentsMime,
    };

    api.post('/consult', data).then(
      response => commit(DONE_FORM__CONSULT_CREATE, response.data),
      error => commit(FAIL_FORM__CONSULT_CREATE, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

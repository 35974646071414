<template>
  <div class="intents-blocks-container bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col col-md-8 col-lg-7 col-xl-6 bg-white pt-3">
          <div class="row mb-4">
            <div class="col">
              <router-link
                class="btn btn-lg btn-block rounded-0"
                :class="[$route.name === 'intents_recent' ? 'btn-secondary' : 'btn-outline-secondary']"
                :to="{name:'intents_recent'}"
              >최근수령 LOIs</router-link>
            </div>
            <div class="col">
              <router-link
                class="btn btn-lg btn-block rounded-0"
                :class="[$route.name === 'intents_blocks' ? 'btn-secondary' : 'btn-outline-secondary']"
                :to="{name:'intents_blocks'}"
              >차단목록</router-link>
            </div>
          </div>
          <router-view></router-view>
          <div class="d-flex justify-content-end bg-white py-2">
            <router-link class="btn btn-light btn-sm text-white px-4" :to="{name: 'user_infomation'}">닫기</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntentsBlocksContainer',
};
</script>

<style scoped>
.intents-blocks-container {
  min-height: 100vh;
}
</style>

import {
  SET__CONSULT_DETAIL,
} from '@/store/mutation-types';

const state = {
  question: {},
  answer: {},
};

const getters = {
};

const mutations = {
  [SET__CONSULT_DETAIL] (state, data) {
    const { answer, ...question } = data;
    state.question = question;
    state.answer = answer;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import {
  REQUEST__ANGEL_TRANSACTIONS_FINISHED,
  RECEIVE__ANGEL_TRANSACTIONS_FINISHED,
  FAILURE__ANGEL_TRANSACTIONS_FINISHED,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  params: {},
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: (state) => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST__ANGEL_TRANSACTIONS_FINISHED] (state) {
    state.error = {};
    state.status = 'REQUEST';
  },
  [RECEIVE__ANGEL_TRANSACTIONS_FINISHED] (state, { contents, ...params }) {
    state.list = [...contents];
    state.params = { ...params };
    state.error = {};
    state.status = 'RECEIVE';
  },
  [FAILURE__ANGEL_TRANSACTIONS_FINISHED] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }, { page = 1, scale = 6 }) {
    commit(REQUEST__ANGEL_TRANSACTIONS_FINISHED);

    api.get('/angel/fin', { params: { page, scale }}).then(
      response => commit(RECEIVE__ANGEL_TRANSACTIONS_FINISHED, response.data),
      error => commit(FAILURE__ANGEL_TRANSACTIONS_FINISHED, { error }),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

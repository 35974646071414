import _ from 'underscore';
import {
  SET__INVESTOR_SUGGESTING,
  INPUT__INVESTOR_SUGGESTING,
  CHECK__INVESTOR_SUGGESTING,
  POST__INVESTOR_SUGGESTING,
  DONE__INVESTOR_SUGGESTING,
  FAIL__INVESTOR_SUGGESTING,
  INIT__INVESTOR_SUGGESTING,
  REQUEST__INVESTOR_SUGGESTING,
  RECEIVE__INVESTOR_SUGGESTING,
  FAILURE__INVESTOR_SUGGESTING,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  acceptRecommend: false, // undefined | false | true
  acceptProvide: false, // undefined | false | true
};
const INIT_DATA = {
  score: 0,
  answer: '',
  status: '',
};

const state = {
  formInit: { ...INIT_FORM },
  form: { ...INIT_FORM },
  formError: {},
  formStatus: 'INIT',
  data: {
    last: { ...INIT_DATA },
    signFin: { ...INIT_DATA, signFinTime: 0 },
  },
  dataError: {},
  dataStatus: 'INIT',
};

const getters = {
  invalidForm: state => _.keys(state.formError).length > 0,
  loadingForm: state => state.formStatus === 'POST',
  showModalASK: state => state.data.last.status === 'SIGN_ASK',
  userScore: state => state.data.signFin.score,
  userSignFinTime: state => {
    const date = new Date(state.data.signFin.signFinTime);
    date.setFullYear(date.getFullYear() + 2);
    return date.valueOf();
  },
};

const mutations = {
  [CHECK__INVESTOR_SUGGESTING] (state) {
    const { acceptRecommend: recommend, acceptProvide: provide } = state.formInit;
    const { acceptRecommend, acceptProvide } = state.form;
    const invalid = {};

    if (acceptRecommend === recommend && acceptProvide === provide ) {
      invalid.acceptRecommend = '변경사항이 없습니다.';
      invalid.acceptProvide = '변경사항이 없습니다.';
    }

    if (acceptRecommend && !acceptProvide) {
      invalid.acceptRecommend = '투자자정보를 제공해야 희망할 수 있습니다.';
    }

    state.formError = { ...invalid };
  },
  [SET__INVESTOR_SUGGESTING] (state, { key, value }) {
    state.formInit[key] = value;
    state.form[key] = value ?? false;
  },
  [INPUT__INVESTOR_SUGGESTING] (state, { key, value }) {
    state.form[key] = value;

    state.formError = _.omit(state.formError, key);
  },
  [POST__INVESTOR_SUGGESTING] (state) {
    state.formStatus = 'POST';
  },
  [DONE__INVESTOR_SUGGESTING] (state) {
    state.formStatus = 'DONE';

    const { form, formInit } = state;
    if (!formInit.acceptProvide && form.acceptProvide) {
      router.push({
        name: 'INVESTOR_SCORING',
      });
    } else {
      router.push({
        name: 'user_infomation',
      });
    }
  },
  [FAIL__INVESTOR_SUGGESTING] (state, error) {
    state.formStatus = 'FAIL';
    state.formError = { ...error };
  },
  [INIT__INVESTOR_SUGGESTING] (state) {
    state.formStatus = 'INIT';
  },
  [REQUEST__INVESTOR_SUGGESTING] (state) {
    state.dataStatus = 'REQUEST';
  },
  [RECEIVE__INVESTOR_SUGGESTING] (state, data) {
    state.dataStatus = 'RECEIVE';
    state.data = data;
  },
  [FAILURE__INVESTOR_SUGGESTING] (state, error) {
    state.dataStatus = 'FAILURE';
    state.dataError = { ...error };
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK__INVESTOR_SUGGESTING);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
  },
  submitForm({ state, commit }) {
    commit(POST__INVESTOR_SUGGESTING);
    const { acceptRecommend, acceptProvide } = state.form;
    const data = { acceptRecommend, acceptProvide };

    api.post('/users/investor/provide', data).then(
      () => commit(DONE__INVESTOR_SUGGESTING),
      error => commit(FAIL__INVESTOR_SUGGESTING, error.response.data),
    );
  },
  requstData({ commit }) {
    commit(REQUEST__INVESTOR_SUGGESTING);

    api.get('/users/investor/scoring').then(
      response => commit(RECEIVE__INVESTOR_SUGGESTING, response.data),
      error => commit(FAILURE__INVESTOR_SUGGESTING, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

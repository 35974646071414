<template>
  <div class="pt-3">
    <h5 class="text-center my-4">비상장주식 1:1 문의 답변</h5>
    <div class="d-flex">
      <h6 class="mr-3 font-weight-bold">Q</h6>
      <div class="flex-grow-1">
        <mark class="h6">{{ question['title'] }}</mark>
        <p class="text-light mt-3">{{ question['contents'] }}</p>
        <div class="text-right">
          <small class="text-light">{{ question['registTime'] |date_3 }}</small>
        </div>
      </div>
    </div>
    <hr>
    <div class="d-flex" v-if="answer">
      <h6 class="mr-3 font-weight-bold">A</h6>
      <div class="flex-grow-1">
        <mark class="h6">{{ answer['title'] }}</mark>
        <p class="mt-3" v-html="answer['contents']"></p>
        <div class="text-right">
          <small class="text-light">{{ answer['registTime'] |date_3 }}</small>
        </div>
      </div>
    </div>
    <div class="my-4 text-right">
      <router-link
        class="btn btn-outline-primary btn-sm rounded-0 py-0 font-sm"
        :to="{ name: '__CONSULT_LIST__' }"
      >목록보기</router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ConsultDetail',
  computed: {
    ...mapState('consultDetail', [
      'question',
      'answer',
    ]),
  },
  mounted() {
    if (!this.question.consultKey) {
      this.$router.replace({
        name: '__CONSULT_LIST__',
      });
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="spinner-border" role="status" v-if="loadingList">
        <span class="sr-only">Loading...</span>
      </div>
      <div
        v-else-if="list.length === 0"
        class="alert alert-light text-center"
      >투자한 신기술조합 관련 새로운 소식이 없습니다.</div>
      <ContentsList
        v-else
        :contents="list"
        @click-deals="clickDeals"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ContentsList from '@/components/investPlacements/ContentsList';

export default {
  name: 'InvestPlacementsContents',
  components: {
    ContentsList,
  },
  computed: {
    ...mapState('investPlacementsContents', [
      'list',
    ]),
    ...mapGetters('investPlacementsContents', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapActions('investPlacementsContents', [
      'requestList',
    ]),
    clickDeals({ dealKey, dealName }) {
      this.$router.push({
        name: '__DEALS_LIBRARY__',
        query: { dealKey, dealName },
      });
    },
  },
  mounted() {
    this.requestList();
  },
};
</script>

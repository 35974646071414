<template>
  <div class="card border-secondary text-primary">
    <div class="card-header bg-secondary text-white pt-3 pb-2">
      <IntentsSampleHeader
        :stockName="intent.stockName"
        :stockCode="intent.stockCode"
        @click-button="routingAuth"
      />
    </div>
    <div class="card-body">
      <IntentsCardTotalPrice
        class="text-dark"
        :price="intent.hopePrice"
        :quantity="intent.hopeQuantity"
      />
      <IntentsCardMessage
        :enabledChat="true"
        :hasNewMessage="true"
        :message="message"
        :expiration="intent.expiration"
        @click-button="routingAuth"
      />
      <IntentsCardButtons
        :buttons="buttons"
        @click-button="routingAuth"
      />
    </div>
  </div>
</template>

<script>
import IntentsSampleHeader from './IntentsSampleHeader.vue';
import IntentsCardTotalPrice from './IntentsCardTotalPrice.vue';
import IntentsCardMessage from './IntentsCardMessage.vue';
import IntentsCardButtons from './IntentsCardButtons.vue';

export default {
  name: 'IntentsSample',
  components: {
    IntentsSampleHeader,
    IntentsCardTotalPrice,
    IntentsCardMessage,
    IntentsCardButtons,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buttons: [
        { label: '거래요청(중개요청)', transType: 'offers', toStatus: 'S04' },
        { label: '거절', transType: 'refuse_receiver', toStatus: 'S09' },
      ],
      message: '협의 가능',
    };
  },
  methods: {
    routingAuth() {
      this.$router.push({ name: 'auth' });
    },
  },
};
</script>

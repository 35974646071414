var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex justify-content-between bg-secondary text-white px-3"},[_c('div',[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.transaction.stockName))]),_c('p',{staticClass:"mb-0 stock-code"},[_vm._v(_vm._s(_vm.transaction.stockCode))])]),_c('h6',[_vm._v("매수")])]),_c('div',{staticClass:"card-body price p-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"mb-1"},[_vm._v("가격")]),_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.offerPrice))+" "),_c('small',[_vm._v("원")])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"h6 mb-1"},[_vm._v("수량")]),_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.offerQuantity))+" "),_c('small',[_vm._v("주")])])]),_c('div',{staticClass:"d-flex justify-content-between border-top pt-2"},[_c('div',{staticClass:"mb-0 h6"},[_vm._v(" 대금 "),_c('span',{staticClass:"badge badge-pill badge-primary font-weight-lighter"},[_vm._v(_vm._s(_vm.payMessage))]),(_vm.enableDeposit && _vm.modeTest)?_c('router-link',{staticClass:"btn btn-outline-primary btn-sm ml-3",attrs:{"to":{
            name: 'transactions_pay_in',
            params: { transactionKey: _vm.transaction.transactionKey }
          }}},[_vm._v("입금통보")]):_vm._e()],1),_c('h6',[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.offerPrice * _vm.transaction.offerQuantity))+" "),_c('small',[_vm._v("원")])])]),_c('ul',{staticClass:"my-3 description pl-3"},[(_vm.transaction.stockCode.length === 6 || _vm.status === 'BUYER__ACCEPT')?_c('li',{domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e(),(_vm.transaction.sign_status === 'SIGN_FIN')?_c('li',[_vm._v("명의개서 진행에 필요한 서류를 안내에 따라 제출해 주세요.")]):_c('li',[_vm._v("매수약정 전자서명을 진행해 주세요.")])]),(_vm.transaction.stockCode.length === 6)?_c('p',{staticClass:"expiration mt-1 mb-2 text-right"},[_vm._v("주식결제일: "+_vm._s(_vm._f("date_short")(_vm.transaction.expiration))+" 오후 3시 예정")]):_c('p',{staticClass:"expiration mt-1 mb-2 text-right"},[_vm._v("주식결제: 명의개서 방식")]),(_vm.showBankInfo)?_c('div',{staticClass:"alert alert-primary text-center p-2 mb-3"},[_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.transaction.virtualAccount ? '농협은행' : ''))]),_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.transaction.virtualAccount))]),_c('h6',{staticClass:"mb-0"},[_vm._v("(계좌주: "+_vm._s(_vm.transaction.virtualAccountOwner)+")")])]):_vm._e(),_c('router-link',{staticClass:"btn btn-kakao btn-block",attrs:{"to":{
        name: '__BUY_SIGN__',
        params: { helperStockTxKey: _vm.transaction.helperStockTxKey }
      }}},[_c('IconChatFill',{staticClass:"btn-kakao-symbol mr-1 mb-1"}),_c('span',{staticClass:"btn-kakao-label"},[_vm._v(" 매수약정 전자서명하기 "),(_vm.transaction.signStatus === 'SIGN_FIN')?[_vm._v("(완료)")]:_vm._e()],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <table class="table table-bordered table-sm font-weight-light text-nowrap">
    <thead>
      <tr class="bg-gray">
        <td>구분</td>
        <td class="text-center">약정금액</td>
        <td class="text-center">출자잔액</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>전체</td>
        <td class="text-right">{{ info.totalCommitAmount | currency }}</td>
        <td class="text-right">{{ info.totalInvestAmount | currency }}</td>
      </tr>
      <tr>
        <td>나</td>
        <td class="text-right">{{ info.commitAmount | currency }}</td>
        <td class="text-right">{{ info.investAmount | currency }}</td>
      </tr>
      <tr>
        <td>비율</td>
        <td class="text-right">{{ info.commitAmount / info.totalCommitAmount | percent }} %</td>
        <td class="text-right">{{ info.investAmount / info.totalInvestAmount | percent }} %</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'HistoryTableMy',
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
};
</script>

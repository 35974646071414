<template>
  <div>
    <FormHead
      title="로그인"
      text="카카오계정 또는 이메일로 시작하실 수 있습니다."
    />
    <KakaoButton />
    <p class="text-center my-4">or</p>
    <form @submit.prevent="submitForm">
      <InputEmail
        autocomplete="username"
        placeholder="이메일"
        id="j_username"
        :value="form['j_username']"
        :error="error['j_username']"
        @input-form="(value) => inputForm({ key: 'j_username', value })"
      />
      <InputPassword
        autocomplete="current-password"
        placeholder="비밀번호"
        id="j_password"
        :value="form['j_password']"
        :error="error['j_password']"
        @input-form="(value) => inputForm({ key: 'j_password', value })"
      />
      <SubmitButton
        label="로그인"
        :loadingStatus="loadingForm"
        :enabledForm="enabledForm"
      />
      <div class="alert alert-danger" v-if="error['fail'] >= 5">
        비밀번호 오류 횟수가 허용한도를 초과하였습니다.<br> 아래 "비밀번호찾기"를 실행해주세요.
      </div>
      <div class="d-flex justify-content-between">
        <InputCheckbox
          id="autoSave"
          :value="form['autoSave']"
          :error="error['autoSave']"
          @input-form="(value) => inputForm({ key: 'autoSave', value })"
          label="이메일 저장"
        />
        <div>
          <router-link class="" :to="{ name: 'find_email' }">이메일찾기</router-link>
          <span class="text-primary mx-2">|</span>
          <router-link class="" :to="{ name: 'find_password' }">비밀번호찾기</router-link>
        </div>
      </div>
    </form>

    <NegoLinksHome />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  INPUT_FORM__SIGN_IN,
  GET_AUTO__SIGN_IN,
  SET_REDIRECT__NEGO_REDIRECT,
} from '@/store/mutation-types';
import FormHead from '@/components/auth/form/FormHead.vue';
import KakaoButton from '@/components/auth/form/KakaoButton.vue';
import InputEmail from '@/components/auth/form/InputEmail.vue';
import InputPassword from '@/components/auth/form/InputPassword.vue';
import SubmitButton from '@/components/auth/form/SubmitButton.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import NegoLinksHome from '@/components/nego/NegoLinksHome.vue';

export default {
  name: 'SignInView',
  components: {
    FormHead,
    KakaoButton,
    InputEmail,
    InputPassword,
    SubmitButton,
    InputCheckbox,
    NegoLinksHome,
  },
  computed: {
    ...mapState('authSignIn', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('authSignIn', [
      'loadingForm',
      'enabledForm',
    ]),
  },
  methods: {
    ...mapMutations('authSignIn', {
      'inputForm': INPUT_FORM__SIGN_IN,
      'getAuto': GET_AUTO__SIGN_IN,
    }),
    ...mapActions('authSignIn', [
      'submitForm',
    ]),
    ...mapMutations('negoRedirect', {
      'setRedirect': SET_REDIRECT__NEGO_REDIRECT,
    }),
  },
  mounted() {
    this.getAuto();

    const { j_username, j_password, redirect_path, ...query } = this.$route.query;
    if (j_username && j_password) {
      this.inputForm({ key: 'j_username', value: j_username });
      this.inputForm({ key: 'j_password', value: j_password });
    }
    if (redirect_path) {
      this.setRedirect({ redirect_path, query });
    }
  },
};
</script>

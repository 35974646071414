<template>
  <table class="table table-sm">
    <tbody>
      <tr>
        <td>기업명</td>
        <td>{{ data.name }}</td>
      </tr>
      <tr>
        <td>사업자번호</td>
        <td>{{ data.bizNo }}</td>
      </tr>
      <tr>
        <td>대표</td>
        <td>{{ data.ceo }}</td>
      </tr>
      <tr v-if="data.companyCode < 1000000000">
        <td>총발행주식</td>
        <td>{{ data.totalStockCount | currency }}</td>
      </tr>
      <tr>
        <td>종업원수</td>
        <td>{{ data.employeeCount || '-' | currency }}</td>
      </tr>
      <tr>
        <td>업종</td>
        <td>{{ data.exactTerms }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'CompanyTableInfos',
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

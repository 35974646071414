import _ from 'underscore';
import {
  REQUEST_LIST__HOME_FIRST,
  RECEIVE_LIST__HOME_FIRST,
  FAILURE_LIST__HOME_FIRST,
  SELECT_LIST__HOME_FIRST,
  SHUFFLE_HOT__HOME_FIRST,
} from '@/store/mutation-types';
import api from '@/api/axios';
import { replaceCompanyName } from '@/utils/expressions';

const state = {
  deals: [],
  companyHot: [],      // HOT비상장기업
  companyHotShuffled: [],
  companyReported: [], // 리포트
  news: [],            // 비상장기업 소식
  videos: [],          // 비상장기업 영상
  errorList: {},
  statusList: 'INITIAL',
  currentLIST: 'NEWS', // NEWS VIDEOS
};

const getters = {
  loadingList: state => state.statusList === 'REQUEST',
};

const mutations = {
  [REQUEST_LIST__HOME_FIRST] (state) {
    state.statusList = 'REQUEST';
  },
  [RECEIVE_LIST__HOME_FIRST] (state, data) {
    const { deals, companyHot, companyReported, news, videos } = data;
    
    state.deals = _.map(deals, (deal) => ({
      ...deal,
      companyName: replaceCompanyName(deal.companyName),
    })).sort((ex, deal) => (deal.type === "PLACEMENT" && deal.retailManagingStatus === "DONE") ? -1 : 1);

    state.companyHot = _.map(companyHot, (company) => ({
      ...company,
      name: replaceCompanyName(company.name),
    }));
    state.companyReported = _.map(companyReported, (company) => ({
      ...company,
      name: replaceCompanyName(company.name),
    }));
    state.news = _.map(news, (item) => ({
      ...item,
      companyName: replaceCompanyName(item.companyName),
    }));
    state.videos = _.map(videos, (item) => ({
      ...item,
      companyName: replaceCompanyName(item.companyName),
    }));
    state.statusList = 'RECEIVE';
  },
  [SHUFFLE_HOT__HOME_FIRST] (state) {
    const { companyHot } = state;
    state.companyHotShuffled = _.shuffle(companyHot).slice(0, 8);
  },
  [FAILURE_LIST__HOME_FIRST] (state, error) {
    state.statusList = 'FAILURE';
    state.errorList = error;
  },
  [SELECT_LIST__HOME_FIRST] (state, current) {
    state.currentLIST = current;
  },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST_LIST__HOME_FIRST);

    api.get('/public/display/main').then(
      response => {
        commit(RECEIVE_LIST__HOME_FIRST, response.data);
        commit(SHUFFLE_HOT__HOME_FIRST);
      },
      error => commit(FAILURE_LIST__HOME_FIRST, error),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="container py-5 d-none d-md-block">
    <div class="row row-cols-1 row-cols-lg-5">
      <div class="col text-center my-3" v-for="({ title, texts, img, imgP }, idx) in cards" :key="idx">
        <picture class="">
          <source :srcset="imgP" type="image/webp">
          <img :src="img" :alt="title" class="icon">
        </picture>
        <p class="title mt-3 mb-1">{{ title }}</p>
        <p class="text mb-0" v-for="(text, idx) in texts" :key="idx">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import img01 from './img/process1.png';
import img01P from './img/process1.webp';
import img02 from './img/process2.png';
import img02P from './img/process2.webp';
import img03 from './img/process3.png';
import img03P from './img/process3.webp';
import img04 from './img/process4.png';
import img04P from './img/process4.webp';
import img05 from './img/process5.png';
import img05P from './img/process5.webp';

const cards = [
  {
    title: '매매(중개)요청',
    texts: ['매도인, 매수인이 협의 후', '각각 당사에 거래 요청'],
    img: img01,
    imgP: img01P,
  },
  {
    title: '결제이행',
    texts: ['안전하게 당사 계좌로', '주식 및 매수대금 이체'],
    img: img02,
    imgP: img02P,
  },
  {
    title: '실명정보 확인',
    texts: ['첫 거래인 경우', '당사에서 실명 확인'],
    img: img03,
    imgP: img03P,
  },
  {
    title: '거래종결',
    texts: ['주식 및 매도대금 이체', '(거래세 등 공제)'],
    img: img04,
    imgP: img04P,
  },
  {
    title: '증빙문서 다운로드',
    texts: ['양도세 신고용', '매매내역서 다운로드'],
    img: img05,
    imgP: img05P,
  },
];

export default {
  name: 'HomeProcess',
  data() {
    return {
      cards,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 6.8rem;
  height: 6rem;
}
.title {
  @include nego-font(1.125rem, 700, 1, -1.3px, $primary);
}
.text {
  @include nego-font(1.125rem, 300, 1.33, -1.3px);
}
</style>

<template>
  <table class="table table-bordered table-sm text-nowrap font-weight-light">
    <template v-for="({ companyName, stockName, histories, abnormal }, index) in list">
      <thead :key="`th-${index}`">
        <tr class="table-light">
          <td colspan="8">{{ companyName }}<span v-if="abnormal">: {{ stockName }}</span></td>
        </tr>
        <tr>
          <td>일자</td>
          <td>구분</td>
          <td class="text-right">변동원금</td>
          <td class="text-right">변동수량</td>
          <td class="text-right">잔여수량</td>
          <td class="text-right">잔여투자금액</td>
          <td class="text-right">평균단가</td>
          <td class="text-right">회수손익</td>
        </tr>
      </thead>
      <tbody :key="`tb-${index}`">
        <tr v-for="(el, inIndex) in histories" :key="`tr-${inIndex}`">
          <td>{{ el.issuedDate | date_short_1 }}</td>
          <td>{{ el.type | placement_portfolio_type(el.description) }}</td>
          <td class="text-right">{{ el.amount | currency }}</td>
          <td class="text-right">{{ el.quantity | currency }}</td>
          <td class="text-right">{{ el.remainQuantity | currency }}</td>
          <td class="text-right">{{ el.remainAmount | currency }}</td>
          <td class="text-right">{{ el.price | currency }}</td>
          <td class="text-right">{{ el.profitLoss | currency }}</td>
        </tr>
      </tbody>
    </template>
  </table>
</template>

<script>
export default {
  name: 'PortfoliosHistoryTable',
  props: {
    list: {
      type: Array,
      require: true,
    },
  },
};
</script>

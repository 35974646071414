<template>
  <div class="">
    <button
      class="btn btn-outline-light btn-block text-left"
      v-if="!show"
      @click="handleShow"
    >{{ label }}</button>
    <div v-else>
      <form>
        <div class="form-label">
          <textarea
            rows="3"
            class="form-control"
            placeholder="입력한 댓글은 다른 회원에게 공개되지 않으며, 관리자만 확인합니다"
            v-model="comment"
          ></textarea>
        </div>
        <div class="input-group">
          <input type="text" class="form-control form-control-sm" v-model="count" disabled>
          <div class="input-group-append">
            <button class="btn btn-primary btn-sm" type="button"
              :disabled="!comment || comment.length > 250"
              @click="submitForm"
            >등록</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    label: {
      type: String,
      default: '댓글 입력',
    },
    parentKey: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      show: false,
      comment: '',
    };
  },
  computed: {
    count() {
      return `${this.comment.length}/250`;
    },
  },
  methods: {
    handleShow() {
      this.show = true;
    },
    submitForm() {
      const comment = this.comment.trim();
      if (!comment) {
        return;
      }
      this.$emit('submit-form', {
        comment,
        parentKey: this.parentKey,
      });
      this.comment = '';
      this.show = false;
    },
  },
};
</script>

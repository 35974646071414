<template>
  <div v-if="!loading">
    <div class="row no-gutters mb-5">
      <div class="col-3 col-md-1">
        <ImageAndIcons
          :src="data.logo"
          icon="building"
        />
      </div>
      <div class="col-9 col-md-11 pl-2">
        <div class="d-flex flex-column justify-content-end h-100">
          <h5 class="mb-1 text-truncate">{{ data.name }}</h5>
          <h6 v-if="data.companyCode < 1000000000">
            {{ data.stockCode }}
          </h6>
          <h6 v-else>
            통일주권 미발행
          </h6>
          <p class="mb-0">
            <a :href="data.homepage | company_link" target="_blank" class="btn-link">{{ data.homepage | company_link }}</a>
            <span v-if="data.foundDate"> | {{ data.foundDate | date_str }} 설립</span>
            <span v-if="data.parValue && data.companyCode < 1000000000"> | 액면가 {{ parseInt(data.parValue) | currency }}원</span>
          </p>
        </div>
      </div>
    </div>
    <p class="comment mb-5">{{ data.outline }}</p>
  </div>
</template>

<script>
import ImageAndIcons from '@/components/icons/ImageAndIcons';

export default {
  name: 'CompanyHeader',
  components: {
    ImageAndIcons,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.icon {
  width: 100px;
}
.comment {
  white-space: pre-line;
}
</style>
 
<template>
  <form @submit.prevent="$emit('submit-form')">
    <div class="form-label-group input-group">
      <input
        id="input-nego-search"
        type="search"
        class="form-control"
        :placeholder="labelInput"
        minlength="2"
        :value="value"
        @change="$emit('input-form', $event.target.value.trim())"
        aria-describedby="button-search"
      >
      <label for="input-nego-search">{{ labelInput }}</label>
      <div class="input-group-append">
        <button class="btn btn-primary" type="submit" id="button-search">
          {{ labelButton }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'InputButton',
  props: {
    value: {
      type: String,
      required: true,
    },
    labelInput: {
      type: String,
      default: '검색어를 입력해주세요.'
    },
    labelButton: {
      type: String,
      default: '검색'
    },
  },
};
</script>

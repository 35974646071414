<template>
  <div class="out-container bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm col-md-8 col-lg-6 col-xl-5 bg-white in-container">
          <router-view></router-view>
          <AuthLinks/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthLinks from '@/components/auth/AuthLinks.vue';

export default {
  name: 'AuthContainer',
  components: {
    AuthLinks,
  },
};
</script>

<style lang="scss" scoped>
.out-container {
  @media (min-width: 768px) {
    min-height: 100vh;
  }
}

.in-container {
  min-height: 100vh;

  @media (min-width: 768px) {
    min-height: unset;
  }
}
</style>

<template>
    <div class="top container pt-2 pb-2 py-lg-4 mb-4">
      <ul class="nav border-bottom">
        <li class="nav-item" v-for="({ name, sub, label }, idx) in links" :key="idx">
          <router-link
            class="nav-link d-inline-block"
            :class="{'active': $route.name === name || (sub && sub.includes($route.name))}"
            :to="{ name }"
          >{{ label }}</router-link>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'NegoLinksTop',
  props: {
    groups: {
      type: Object,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
  },
  computed: {
    links() {
      return this.groups[this.group].children;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  .nav .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .nav-link {
    color: black;
    font-size: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.25rem;
  }
  .nav-link.active {
    font-weight: 600;
    color: $secondary;
    border-bottom: 4px solid $secondary;
    margin-bottom: -4px;
  }
}
</style>

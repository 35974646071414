import _ from 'underscore';
import {
  REQUEST_INFO__SELL_ASSIGNS_ORDER_EVENT,
  RECEIVE_INFO__SELL_ASSIGNS_ORDER_EVENT,
  FAILURE_INFO__SELL_ASSIGNS_ORDER_EVENT,
  CHECK_FORM__SELL_ASSIGNS_ORDER_EVENT,
  INIT_FORM__SELL_ASSIGNS_ORDER_EVENT,
  INPUT_FORM__SELL_ASSIGNS_ORDER_EVENT,
  POST_FORM__SELL_ASSIGNS_ORDER_EVENT,
  DONE_FORM__SELL_ASSIGNS_ORDER_EVENT,
  FAIL_FORM__SELL_ASSIGNS_ORDER_EVENT,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  stockInfoUid: '',
  stockName: '',
  sellAssignKey: '',
  price: 0,
  quantity: 0,
  orderable: false,
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [REQUEST_INFO__SELL_ASSIGNS_ORDER_EVENT] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_INFO__SELL_ASSIGNS_ORDER_EVENT] (state, data) {
    state.status = 'RECEIVE';
    state.form.stockInfoUid = data.stockInfoUid;
    state.form.stockName = data.stockName;
    state.form.sellAssignKey = data.sellAssignKey;
    state.form.price = data.sellPrice;
    state.form.quantity = data.minimumOrderQuantity;
  },
  [FAILURE_INFO__SELL_ASSIGNS_ORDER_EVENT] (state, error) {
    state.status = 'FAILURE';
    state.error = error;
  },
  [CHECK_FORM__SELL_ASSIGNS_ORDER_EVENT] (state) {
    const { orderable } = state.form;
    const error = {};

    if (!orderable) {
      error.orderable = '바로매수 이용시간이 아닙니다. (영업일: 오전 7:30 ~ 오후 2:00)';
    }

    state.error = { ...error };
  },
  [INIT_FORM__SELL_ASSIGNS_ORDER_EVENT] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__SELL_ASSIGNS_ORDER_EVENT] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__SELL_ASSIGNS_ORDER_EVENT] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__SELL_ASSIGNS_ORDER_EVENT] (state) {
    state.status = 'DONE';

    router.replace({ name: 'transactions_progress' });
  },
  [FAIL_FORM__SELL_ASSIGNS_ORDER_EVENT] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  requestInfo({ dispatch, commit }, sellAssignKey) {
    commit(REQUEST_INFO__SELL_ASSIGNS_ORDER_EVENT);

    api.get(`/sellassigns/info/${sellAssignKey}`).then(
      response => {
        commit(RECEIVE_INFO__SELL_ASSIGNS_ORDER_EVENT, response.data);
        dispatch('checkOrder');
      },
      error => commit(FAILURE_INFO__SELL_ASSIGNS_ORDER_EVENT, error.response.data),
    );
  },
  checkOrder({ commit }) {
    api.get('/sellassigns/order').then(
      response => commit(INPUT_FORM__SELL_ASSIGNS_ORDER_EVENT, { key: 'orderable', value: response.data }),
      error => commit(FAIL_FORM__SELL_ASSIGNS_ORDER_EVENT, error.response.data),
    );
  },
  checkForm({ commit, dispatch, getters }) {
     commit(CHECK_FORM__SELL_ASSIGNS_ORDER_EVENT);

     if (!getters.invalidForm) {
       dispatch('submitForm');
     }
  },
  submitForm({ state, commit }) {
    commit(POST_FORM__SELL_ASSIGNS_ORDER_EVENT);

    const { sellAssignKey, price, quantity } = state.form;
    api.post('/sellassigns/order', { sellAssignKey, price, quantity }).then(
      () => setTimeout(() => commit(DONE_FORM__SELL_ASSIGNS_ORDER_EVENT), 2000),
      error => commit(FAIL_FORM__SELL_ASSIGNS_ORDER_EVENT, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

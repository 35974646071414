<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between bg-primary text-white">
      <div>
        <h5 class="mb-0">{{ transaction.stockName }}</h5>
        <p class="mb-0">{{ transaction.stockInfoUid }}</p>
      </div>
      <h5>{{ transaction.seller ? '판매' : '구매' }}</h5>
    </div>
    <div class="card-body price">
      <div class="d-flex justify-content-between">
        <div class="mb-2">
          조합지분
          <span class="badge badge-pill badge-primary font-weight-lighter">{{ shareMessage }}</span>
        </div>
        <h5>{{ transaction.investedAmount | currency }} <small>좌</small></h5>
      </div>
      <div class="d-flex justify-content-between">
        <p class="mb-0">매매금액</p>
        <h5>{{ transaction.sellAmount | currency }} <small>원</small></h5>
      </div>
      <div class="d-flex justify-content-between border-top pt-2" v-if="transaction.seller">
        <div class="mb-2">
          순지급액
          <span class="badge badge-pill badge-primary font-weight-lighter">{{ shareMessage }}</span>
        </div>
        <h5 >{{ transaction.sellerNetPayment | currency }} <small>원</small></h5>
      </div>
      <div class="d-flex justify-content-between border-top pt-2" v-if="transaction.buyer">
        <div class="mb-2">
          총입금액
          <span class="badge badge-pill badge-primary font-weight-lighter">{{ payMessage }}</span>
        </div>
        <h5>{{ transaction.buyerClaim | currency }} <small>원</small></h5>
      </div>
      <div class="my-3 text-center">
        <span class="description mb-0" v-html="message"></span>
      </div>
      <div class="">
        <a
          v-if="transaction.seller"
          class="btn btn-outline-primary btn-block mt-2 mt-lg-0"
          :href="`${FintechCommApi}/public/pdf/report/${transaction.sellerKey}/${transaction.transactionKey}/매도내역서`"
          download="매도내역서.pdf"
        >매도내역서</a>
        <a
          v-else
          class="btn btn-outline-primary btn-block mt-2 mt-lg-0"
          :href="`${FintechCommApi}/public/pdf/report/${transaction.buyerKey}/${transaction.transactionKey}/매수내역서`"
          download="매수내역서.pdf"
        >매수내역서</a>
      </div>
    </div>
  </div>
</template>

<script>
import { STATUS, PAY_STATUS, SHARE_STATUS } from './transactionsProgressCardStatus';
import { FintechCommApi } from '@/utils/constants';

export default {
  name: 'TransactionsFinishedCard',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      FintechCommApi,
    };
  },
  computed: {
    enableShareIn() {
      const { seller, shareStatus, sellAssignKey } = this.transaction;
      return seller && shareStatus === 'ACCEPT' && !sellAssignKey;
    },
    status() {
      const { buyer, status } = this.transaction;
      return `${buyer ? 'BUYER' : 'SELLER' }__${status}`;
    },
    payMessage() {
      return PAY_STATUS[this.transaction.payStatus];
    },
    shareMessage() {
      return SHARE_STATUS[this.transaction.shareStatus];
    },
    message() {
      return STATUS[this.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  @include nego-font(1.125rem, 300, 1.25, 0.2px, $dark);
}
.expiration {
  @include nego-font(1rem, 300, 1.25, 0.2px, $dark);
}
.description {
  @include nego-font(1rem, 400, 1.22, 0.2px, $dark);
}
</style>

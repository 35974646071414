<template>
  <AppModal title="예약구매" v-if="charger">
    <div class="">
      <h5 class="text-center mb-4">[담당자문의 관련 안내]</h5>
      <p class="text-danger font-weight-bold">비상장주식은 초고위험상품으로 전문투자자 또는 투자성향이 공격투자자로 확인된 고객에 대해서만 투자권유가 가능한 상품입니다.</p>
      <p class="">이에 따라 전화 문의 시, <mark class="bg-warning font-weight-bold">담당자는 주식매수 위한 회원가입 절차 등에 관한 안내만 가능</mark>하며 회사와 관련한 장래전망 등에 대한 문의에는 일체 답변을 드리지 않습니다.</p>
      <p class="mb-0">담당자: {{ charger.dealCharger }} ({{ charger.dealChargeDept }})</p>
      <p class="mb-0 ">연락처: {{ charger.dealChargerContact | tel }}</p>
    </div>
    <template v-slot:footer>
      <button
        class="btn btn-outline-dark btn-sm"
        @click="() => $emit('hide-charger')"
      >닫기</button>
    </template>
  </AppModal>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue';

export default {
  name: 'DealChargerModal',
  components: {
    AppModal,
  },
  props: {
    charger: {
      type: Object,
      default: null,
    },
  },
};
</script>

<template>
  <div class="text-center my-4">
    <h4 class="title">{{ title }} <span v-if="intentType" class="text-danger">({{ intentType }})</span></h4>
    <p class="text">({{ text }})</p>
  </div>
</template>

<script>
export default {
  name: 'FormHead',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    intentType: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include nego-title;
}
.text {
  @include nego-text;
}
</style>

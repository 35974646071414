import Vue from 'vue';
import {
  FUNDINGS_STAGE,
  PLACEMENT_TYPE,
  STOCK_TYPE,
  PRIMARY_TYPE,
  OPEN_TYPE,
  DEAL_INTENT_TYPE,
} from '@/filters/filtersFunding';
import {
  PLACEMENT_PORTFOLIO__TYPE,
  PLACEMENT_CASH__TYPE,
} from '@/filters/filtersPlacementsPortfolios';
import {
  INVESTOR__TYPE,
} from '@/filters/filtersInvestor';
import {
  PLACEMENT_FILE__KIND,
  PLACEMENT_FILE_ADDITIONAL__KIND,
} from '@/filters/filtersSubscriptions';
import {
  REPORTS__TYPE,
} from '@/filters/filtersReports';
import {
  RETAIL_MANAGING_STATUS
} from '@/filters/filtersRetail';
import {
  USER__ALLOW_MARKETING,
  USER__ACCEPT_RECOMMEND,
  USER__ACCEPT_PROVIDE,
  // USER__ACCEPT_PROVIDE_SCORE,
} from '@/filters/filtersUser';


Vue.filter('percent', value => {
  if (isNaN(value)) {
    return 0;
  }
  return (value * 100).toFixed(2);
});

// 반올림
Vue.filter('round', (value) => {
  return value && Math.round(value);
});

// 올림
Vue.filter('ceil', (value) => {
  return value && Math.ceil(value);
});

// 내림
Vue.filter('floor', (value) => {
  return value && Math.floor(value);
});

// 
Vue.filter('phone', (value) => {
  if (!value) {
    return '-';
  }
  const first = value.slice(0, 3);
  const second = value.slice(3, 7);
  const third = value.slice(7);
  return `${first}-${second}-${third}`;
});

Vue.filter('currency', (value) => {
  return value && value.toLocaleString('kr-KO');
});

Vue.filter('won', (value) => {
  const str = value.toLocaleString('kr-KO');
  if (str.length > 8) {
    return str.slice(0, -8);
  }
  return str;
});

Vue.filter('tel', (value) => {
  if (!value) {
    return 'Office미등록';
  }

  const length = value.length;
  const twoDigit = value.slice(0, 2);
  const thrDigit = value.slice(0, 3);

  if (twoDigit === '02') {
    if (length === 9) {
      return `${twoDigit}-${value.slice(2, 5)}-${value.slice(5)}`;
    } else if (length === 10) {
      return `${twoDigit}-${value.slice(2, 6)}-${value.slice(6)}`;
    } else {
      return value;
    }
  } else if (thrDigit === '010') {
    if (length === 11) {
      return `${thrDigit}-${value.slice(3, 7)}-${value.slice(7)}`;
    } else {
      return value;
    }
  } else {
    if (length === 10) {
      return `${thrDigit}-${value.slice(3, 6)}-${value.slice(6)}`;
    } else if (length === 11) {
      return `${thrDigit}-${value.slice(3, 7)}-${value.slice(7)}`;
    } else {
      return value;
    }
  }
});

// 억원
const billion = 100000000;
const million = 1000000;
const tenThousand = 10000;
Vue.filter('won2', (value) => {
  if (!value) {
    return '-';
  }
  if (value >= billion) {
    return (value / billion).toFixed(1) + ' 억';
  }
  if (value >= million) {
    return (value / million) + ' 백만';
  }
  if (value >= tenThousand) {
    return (value / tenThousand) + ' 만';
  }
  return value + ' 원';
});

Vue.filter('won3', (value) => {
  if (value === 0) {
    return 0;
  }
  if (!value) {
    return '-'
  }
  if (value < million) {
    return (value / million).toFixed(2);
  }

  const division = (value / million);
  const result = parseInt(division.toFixed(0));
  return result.toLocaleString('kr-KO');
});

const KB = 1024;
const MB = 1048576;
const GB = 1073741824;
Vue.filter('file_size', value => {
  if (value < KB) {
    return `${value}B`;
  } if (value < MB) {
    return `${(value / KB).toFixed(1)}KB`;
  } if (value < GB) {
    return `${(value / MB).toFixed(2)}MB`;
  } else {
    return `${(value / GB).toFixed(2)}GB`;
  }
});

// 1시간 이내 결제이행
// 2019. 10. 7. (오후 03시 까지 결제이행)
// 2019. 10. 8. (오전 10시 까지 결제이행)
// 2019. 10. 8. (오후 03시 까지 결제이행)
Vue.filter('bizdays', value => {
  const now = Date.now();
  const ONE_HOUR = 60 * 60 * 1000;
  if (value - now < ONE_HOUR) {
    return '1시간 이내 결제이행';
  } else {
    const time = new Date(value);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};
    const str = time.toLocaleString('ko-KR', options);
    return `${str.slice(0, -8)} (${str.slice(-8, -3)}시 까지 결제이행)`;
  }
});

// 2019. 5. 5. 오후 12:00
Vue.filter('date', value => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};
  const time = new Date(value);
  return time.toLocaleString('ko-KR', options);
});

// 19. 5. 5. 오후 12:00
Vue.filter('date_middle', value => {
  const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};
  const time = new Date(value);
  return time.toLocaleString('ko-KR', options);
});

// 5. 5. 오후 12:00
Vue.filter('date_middle_2', value => {
  const options = { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};
  const time = new Date(value);
  return time.toLocaleString('ko-KR', options);
});

// 19. 4. 5.
Vue.filter('date_short_0', value => {
  const options = { year: '2-digit', month: '2-digit', day: '2-digit'};
  const time = new Date(value);
  return time.toLocaleString('ko-KR', options);
});

// 2019. 4. 5.
Vue.filter('date_short', value => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
  const time = new Date(value);
  return time.toLocaleString('ko-KR', options);
});

// 2019.04.05.
Vue.filter('date_short_1', value => {
  if (!value) {
    return '-';
  }
  const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
  const time = new Date(value);
  return time.toLocaleString('ko-KR', options).replaceAll(' ', '');
});

// 2021년 02월 18일 => 2021. 2. 18.
Vue.filter('date_short_2', value => {
  if (!value) {
    return 'invalid';
  }
  const [year, month, date] = value.split(' ');
  return `${year.slice(0, -1)}. ${month.slice(0, -1)}. ${date.slice(0, -1)}`
});

// 2021년 02월 18일 => 2023년 5월 31일 오후 4:13
Vue.filter('date_3', value => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};
  const date = new Date(value);

  // '2023. 7. 3. 오후 03:25'
  const str = date.toLocaleDateString('ko-KR', options);
  const [year, month, day, ampm, time] = str.split(' ');
  return `${year.slice(0, -1)}년 ${month.slice(0, -1)}월 ${day.slice(0, -1)}일 ${ampm} ${time}`;
});

// 2019. 5. 5. 12:00
Vue.filter('date_4', value => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'};

  const str = new Date(value).toLocaleString('ko-KR', options);
  const [year, month, day, , time] = str.split(' ');
  return `${year.slice(0, -1)}. ${month.slice(0, -1)}. ${day.slice(0, -1)}. ${time}`;
});

// 2019. 4. 5. | 종료
Vue.filter('date_expiration', (value, count) => {
  const now = Date.now();
  if (count >= 3 || value < now) {
    return '종료';
  }
  const options = { year: 'numeric', month: 'numeric', day: 'numeric'};
  const time = new Date(value);
  return time.toLocaleString('ko-KR', options);
});

// 2019.04.05.
Vue.filter('date_str', (value) => {
  if (value) {
    const str = value.toString();
    return [str.substring(0,4),str.substring(4,6),str.substring(6)].join('.');
  } else {
    return 'Unknown';
  }
});

// 22.04.05.
Vue.filter('date_str_1', (value) => {
  if (value) {
    const str = value.toString();
    return [str.substring(2,4),str.substring(4,6),str.substring(6)].join('.');
  } else {
    return 'Unknown';
  }
});

// 22.04.05.
Vue.filter('date_long', (value) => {
  if (!value) {
    return '-'
  }
  const time = new Date(value);
  const year = time.getFullYear();
  const month = time.getMonth() + 1;
  const date = time.getDate();
  return `${year}년 ${month}월 ${date}일`
});

Vue.filter('time', value => {
  const date = new Date(value);
  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return formatter.format(date);
});

Vue.filter('club_open_type', value => {
  const map = {
    CLUB: '지정회원만',
    ALL: '전체',
  };
  return map[value];
});

Vue.filter('buyer_type', value => {
  const map = {
    NORMAL: '일반투자자',
    PRIVATE: '전문투자자',
  };
  return map[value];
});

Vue.filter('fundings_stage', value => {
  const item = FUNDINGS_STAGE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('placement_type', value => {
  const item = PLACEMENT_TYPE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('stock_type', value => {
  const item = STOCK_TYPE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('primary_type', value => {
  const item = PRIMARY_TYPE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('open_type', value => {
  const item = OPEN_TYPE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('deal_intent_type', value => {
  const item = DEAL_INTENT_TYPE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('placement_portfolio_type', (value, desc) => {
  const item = PLACEMENT_PORTFOLIO__TYPE.find(el => el.value === value);
  if (item) {
    if (desc) {
      return `${item.label.slice(0, -3)}: ${desc}`;
    }
    return item.label.slice(0, -3);
  }
  return 'Unknown';
});

Vue.filter('placement_cash_type', value => {
  const item = PLACEMENT_CASH__TYPE.find(el => el.value === value);
  if (item) {
    return item.label.slice(0, -3);
  }
  return 'Unknown';
});

Vue.filter('placement_file__kind', value => {
  const item = PLACEMENT_FILE__KIND.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return '';
});

Vue.filter('placement_file_additional__kind', value => {
  const item = PLACEMENT_FILE_ADDITIONAL__KIND.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return '';
});

Vue.filter('investor_type', (value) => {
  const item = INVESTOR__TYPE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('reports_type', (value) => {
  const item = REPORTS__TYPE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('retail_managing_status', (value) => {
  const item = RETAIL_MANAGING_STATUS.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return 'Unknown';
});

Vue.filter('user_allow_marketing', (value) => {
  const item = USER__ALLOW_MARKETING.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return '-';
});

Vue.filter('user_accept_recommend', (value) => {
  if (value === undefined) {
    return '희망하지 않음';
  }
  const item = USER__ACCEPT_RECOMMEND.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return '-';
});

Vue.filter('user_accept_provide', (value) => {
  if (value === undefined) {
    return '미제공';
  }
  const item = USER__ACCEPT_PROVIDE.find(el => el.value === value);
  if (item) {
    return item.label;
  }
  return '-';
});

Vue.filter('company_link', (value) => {
  if (!value || typeof value !== 'string') {
    return '';
  }

  if (value.startsWith('http://') || value.startsWith('https://')) {
    return value;
  } else {
    return 'http://' + value;
  }
});

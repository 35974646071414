<template>
  <div class="nego-search">
    <NegoSearchHeader/>
    <div class="form-label-group input-group">
      <input
        id="input-nego-search"
        type="search"
        class="form-control"
        placeholder="검색어를 입력해주세요."
        minlength="2"
        :value="keyword"
        @change="inputKeyword($event.target.value.trim())"
        aria-describedby="button-search"
      >
      <label for="input-nego-search">검색어를 입력해주세요.</label>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" id="button-search">
          <IconSearch class="icon-sm" />
        </button>
      </div>
    </div>
    <div class="my-3">
      <div class="spinner-border" role="status" v-if="loadingStatus">
        <span class="sr-only">Loading...</span>
      </div>
      <template v-else>
        <p class="text-muted" v-if="keyword.length !== 0 && filteredStocks.length === 0">검색 결과가 없습니다.</p>
        <div class="row">
          <template v-for="(stock, idx) in filteredStocks">
            <div class="col-lg-6 mb-3" :key="idx">
              <button
                class="btn btn-outline-primary btn-block"
                @click="selectStock({ stock })"
              >
                <h6 class="mb-0 font-weight-bold">{{ stock.name }}</h6>
                <p class="mb-0">{{ stock.code }}</p>
              </button>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="my-3" v-if="filteredStocks.length === 0">
      <div class="d-flex justify-content-between">
        <h6 class="title-result text-primary">최근 검색 종목</h6>
        <button class="btn btn-link" @click="deleteAll">삭제</button>
      </div>
      <p v-if="list.length === 0" class="text">최근 검색 종목이 없습니다.</p>
      <template v-for="(stock, idx) in list">
        <button
          :key="idx"
          class="btn btn-outline-dark btn-sm rounded-0 mr-2 mb-2"
          @click="selectStock({ stock })"
        >{{ stock.name }}</button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  GET_KEYWORD__NEGO_KEYWORDS,
  ADD_KEYWORD__NEGO_KEYWORDS,
  DELETE_ALL__NEGO_KEYWORDS,
  INPUT_KEYWORD__NEGO_STOCKS,
  RESET_KEYWORD__NEGO_STOCKS,
} from '@/store/mutation-types';
import NegoSearchHeader from '@/components/nego/NegoSearchHeader.vue';
import IconSearch from '@/components/icons/IconSearch.vue';

export default {
  name: 'NegoSearchView',
  components: {
    NegoSearchHeader,
    IconSearch,
  },
  computed: {
    ...mapState('negoKeywords', [
      'list',
    ]),
    ...mapState('negoStocks', [
      'stocks',
      'keyword',
      'status',
    ]),
    ...mapGetters('negoStocks', [
      'loadingStatus',
      'filteredStocks',
    ]),
  },
  methods: {
    ...mapMutations('negoKeywords', {
      'getKeyword': GET_KEYWORD__NEGO_KEYWORDS,
      'addKeyword': ADD_KEYWORD__NEGO_KEYWORDS,
      'deleteAll': DELETE_ALL__NEGO_KEYWORDS,
    }),
    ...mapMutations('negoStocks', {
      'inputKeyword': INPUT_KEYWORD__NEGO_STOCKS,
      'resetKeyword': RESET_KEYWORD__NEGO_STOCKS,
    }),
    ...mapActions('negoStocks', [
      'fetchStocks',
    ]),
    selectStock({ stock }) {
      this.addKeyword({ stock });
      const { name: currentName, query } = this.$route;
      const { stockInfoUid, name: stockName } = stock;
      let targetName;
      let referSite;
      let type;
      if (currentName === 'intents_search') {
        targetName = 'intents_maker';
        referSite = 'direct';
      } else if (currentName === 'mystocks_search') {
        targetName = 'mystocks_maker';
        type = query.type;
      } else if (currentName === 'markets_search') {
        targetName = 'markets_board';
      } else if (currentName === 'sell_assigns_search') {
        targetName = 'sell_assigns_check';
      } else {
        targetName = 'not_found';
      }
      this.$router.push({
        name: targetName,
        query: { stockInfoUid, stockName, referSite, type },
      });
    },
  },
  mounted() {
    this.getKeyword();
    this.fetchStocks();

    const { query } = this.$route.query;
    if (query) {
      this.inputKeyword(query);
    }
  },
  beforeDestroy() {
    this.resetKeyword();
  },
};
</script>

<style lang="scss" scoped>
.nego-search {
  min-height: 95vh;
}
</style>

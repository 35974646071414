function checkPcBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  let isPcBrowser = true;

  if (userAgent.indexOf('mobile') > -1) {
    isPcBrowser = false;
  }
  // if (userAgent.indexOf('edge') > -1) {
  //   isPcBrowser = true; // '익스플로러 엣지';
  // } else if (userAgent.indexOf('whale') > -1) {
  //   isPcBrowser = true; // '네이버 웨일';
  // } else if (userAgent.indexOf('chrome') > -1) {
  //   isPcBrowser = true; // '크롬';
  // } else if (userAgent.indexOf('firefox') > -1) {
  //   isPcBrowser = true; // '파이어폭스';
  // } else {
  //   isPcBrowser = true; // '익스플로러';
  // }

  return isPcBrowser;
}

function checkOldBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  let browser = false;

  if (userAgent.indexOf('trident') > -1) {
    browser = true; // '익스플로러';
  }

  return browser;
}

export {
  checkPcBrowser,
  checkOldBrowser,
};

<template>
  <div class="form-group mb-3">
    <div class="custom-control custom-checkbox pl-0">
      <IconCheckCircleFill
        class="icon-sm mr-1"
        :class="[value ? 'text-secondary' : 'text-light']"
        @click="toggleValue"
      />
      <input
        type="checkbox"
        class="custom-control-input d-none"
        :class="{ 'is-invalid': error }"
        :id="id"
        :checked="value"
        @change="($event) => $emit('input-form', $event.target.checked)"
      >
      <label class="" :for="id">{{ label }} </label>
      <a
        :href="linkFile"
        target="_blank"
        class="float-right btn btn-link p-0"
        :download="linkName"
      >[보기]</a>
      <div class="invalid-feedback">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import IconCheckCircleFill from '@/components/icons/IconCheckCircleFill.vue';

export default {
  name: 'InputCheckbox',
  components: {
    IconCheckCircleFill,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
    },
    error: {
      type: String,
      default: null,
    },
    linkFile: {
      type: String,
      required: true,
    },
    linkName: {
      type: String,
      default: '',
    },
  },
  methods: {
    toggleValue() {
      this.$emit('input-form', !this.value);
    },
  },
};
</script>

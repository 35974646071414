<template>
  <AppModalSimple
    v-if="showModal"
    title="지인과 거래하기"
    content="“지인과 거래하기”는 알고 계신 거래상대방과 이미 논의하신 거래 건에 대해 당사에 중개요청하기 위한 용도로 사용하셔야 합니다.
      <br>무작위 발송 등으로 수신자에 의해 “수신거부” 처리될 경우 서비스약관 제11조에 따라 서비스이용이 제한될 수 있습니다.
      <br>“지인과 거래하기”를 진행하시겠어요?"
  >
    <div class="d-flex justify-content-around w-100">
      <router-link
        class="btn btn-outline-dark btn-block mr-2"
        :to="{ name: 'intents_search' }"
      >네</router-link>
      <button
        class="btn btn-outline-dark btn-block ml-2 mt-0"
        @click="closeModal"
      >아니오</button>
    </div>
  </AppModalSimple>
</template>

<script>
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'NegoModal',
  components: {
    AppModalSimple,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  }
};
</script>

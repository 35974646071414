<template>
  <div>
    <FormHead
      :title="form['stockName']"
      :text="checkStockInfoUid ? form['stockInfoUid'] : '통일주권미발행'"
    />
    <template v-if="isPriceZero">
      <HelpIntentMakerNoticeNonPro v-if="isNonProBuyable" />
      <HelpIntentMakerNoticeOnlyPro v-else />
    </template>
    <form class="mt-5" @submit.prevent="checkForm">
      <InputNumberToString
        disabled
        id="price"
        :label="`주당가격 (원)${isPriceZero ? labelPrice : ''}`"
        :value="form['price']"
      />
      <InputNumberToString
        id="quantity"
        :label="`매수수량 (최소 ${form.minimumOrderQuantity} 주 이상)`"
        append="주"
        :value="form['quantity']"
        @input-form="(value) => inputForm({ key: 'quantity', value })"
        :error="error['quantity']"
      />
      <InputNumberToString
        disabled
        id="totalPrice"
        label="거래금액 (원)"
        :value="form['price'] * form['quantity']"
      />
      <h6>유의사항</h6>
      <ol class="pl-4" v-if="checkStockInfoUid">
        <li>본 건은 당사(코리아에셋투자증권)가 매수예정인 주식 등에 대해 구매예약을 받는 것입니다.</li>
        <li>예약구매를 신청하시면 당사에서 신청하신 조건으로 거래가 가능한지를 검토한 후 알려드립니다.<br/> 예약구매 신청이 거래불가로 통보된 경우 또는 신청을 취소하신 경우에는 24시간 경과 후에만 동일 건에 대해 예약구매를 다시 신청 하실 수 있습니다.</li>
        <li class="font-weight-bold">본 예약구매 신청 및 당사의 거래가능 여부 통보, 고객님의 매매대금입금이 실제 주식 매수를 100% 보장하지는 않습니다.</li>
        <li>본 거래의 <span class="font-weight-bold">주식 교부일은 {{ form['retailBatchOutDate'] | date_long }}</span>입니다.<br/> 예약구매 신청 총수량이 당사의 매수예정 수량을 초과할 경우 입금과 매수약정(전자서명)을 먼저 마치신 고객님께 우선하여 주식을 보내드립니다. 주식을 보내드리지 못할 경우, 입금하신 대금은 즉시 반환해 드립니다.</li>
        <li>당사의 담당자로부터 본건 신청과 관련하여 연락을 받으실 수 있습니다.</li>
      </ol>
      <ol class="pl-4" v-else>
        <li>본 건은 당사(코리아에셋투자증권)가 매수예정인 주식 등에 대해 구매예약을 받는 것입니다.</li>
        <li>예약구매를 신청하시면 당사에서 신청하신 조건으로 거래가 가능한지를 검토한 후 알려드립니다.<br/> 예약구매 신청이 거래불가로 통보된 경우 또는 신청을 취소하신 경우에는 24시간 경과 후에만 동일 건에 대해 예약구매를 다시 신청 하실 수 있습니다.</li>
        <li class="font-weight-bold">본 예약구매 신청 및 당사의 거래가능 여부 통보, 고객님의 매매대금입금이 실제 주식 매수를 100% 보장하지는 않습니다.</li>
        <li>본 거래의 대상인 주식은 통일주권이 발행되지 않아, 발행회사에 명의개서가 필요합니다. 따라서, 매수약정(전자서명)을 마치신 후 별도의 안내에 따라 아래의 서류를 제출해 주셔야 합니다.<br/> (제출서류 : 인감날인한 매매계약서2부, 인감날인한 주식양도통지 및 명의개서요청서 2부, 인감증명서 2부)</li>
        <li>당사의 담당자로부터 본건 신청과 관련하여 연락을 받으실 수 있습니다.</li>
      </ol>
      <InputCheckbox
        id="agreeForm"
        :value="form['agreeForm']"
        :error="error['agreeForm']"
        @input-form="(value) => inputForm({ key: 'agreeForm', value })"
        label="유의사항 확인함"
      />
      <div
        v-if="error['dealKey']"
        class="alert alert-danger"
      >{{ error['dealKey'] }}</div>
      <button
        v-if="isPriceZero"
        type="button"
        class="btn btn-secondary btn-block"
        @click="showModalNotice"
      >예약구매 신청</button>
      <SubmitButton
        v-else
        label="예약구매 신청"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <HelpIntentMakerNoticeModal ref="modalNotice">
      <HelpIntentMakerNoticeNonPro v-if="isNonProBuyable" />
      <HelpIntentMakerNoticeOnlyPro v-else />
    </HelpIntentMakerNoticeModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputNumberToString from '@/components/form/InputNumberToString.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import HelpIntentMakerNoticeModal from '@/components/helpIntent/HelpIntentMakerNoticeModal.vue';
import HelpIntentMakerNoticeNonPro from '@/components/helpIntent/HelpIntentMakerNoticeNonPro.vue';
import HelpIntentMakerNoticeOnlyPro from '@/components/helpIntent/HelpIntentMakerNoticeOnlyPro.vue';
import {
  INIT_FORM__HELP_INTENT_MAKER,
  INPUT_FORM__HELP_INTENT_MAKER,
} from '@/store/mutation-types';

export default {
  name: 'HelpIntentMaker',
  components: {
    FormHead,
    InputNumberToString,
    SubmitButton,
    InputCheckbox,
    HelpIntentMakerNoticeModal,
    HelpIntentMakerNoticeNonPro,
    HelpIntentMakerNoticeOnlyPro,
  },
  computed: {
    ...mapState('helpIntentMaker', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('helpIntentMaker', [
      'loadingForm',
    ]),
    checkStockInfoUid() {
      return this.form['stockInfoUid'].length === 6;
    },
    isPriceZero() {
      return this.form['price'] === 0;
    },
    isNonProBuyable() {
      return this.form['retailNonProBuyable'];
    },
    labelPrice() {
      const { retailNonProBuyable } = this.form;
      if (retailNonProBuyable) {
        return ': 투자적합고객에게만 공개';
      } else {
        return ': 전문투자자에게만 공개';
      }
    },
  },
  methods: {
    ...mapMutations('helpIntentMaker', {
      'initForm': INIT_FORM__HELP_INTENT_MAKER,
      'inputForm': INPUT_FORM__HELP_INTENT_MAKER,
    }),
    ...mapActions('helpIntentMaker', [
      'checkForm',
    ]),
    showModalNotice() {
      this.$refs.modalNotice.openModal();
    },
  },
  mounted() {
    const { stockInfoUid, dealKey } = this.form;
    if (!stockInfoUid || !dealKey) {
      this.$router.replace({
        name: '__HELP_INTENT__',
      });
    }
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

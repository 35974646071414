<template>
  <AppModalSimple
    title="네고스탁"
    content="로그아웃 하시겠습니까?"
  >
    <div class="d-flex justify-content-around ">
      <button class="btn btn-outline-dark flex-fill mr-1" @click="onBack">뒤로</button>
      <button class="btn btn-outline-dark flex-fill ml-1" @click="signOut">로그아웃</button>
    </div>
  </AppModalSimple>
</template>

<script>
import { mapActions } from 'vuex';
import AppModalSimple from '@/components/app/AppModalSimple.vue';

export default {
  name: 'SignOutView',
  components: {
    AppModalSimple,
  },
  methods: {
    ...mapActions('userInfomation', [
      'signOut',
    ]),
    onBack() {
      this.$router.back();
    },
  },
};
</script>

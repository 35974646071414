<template>
  <div class="mt-4">
    <h6 class="my-3">코리아에셋투자증권 광고성 정보 수신을 거부하시겠습니까?</h6>
    <div class="alert alert-primary">
      수신거부 후, 다시 수신하고 싶으실 때는 네고스탁({{ NegoStockWeb }}) 사이트에 접속하여 로그인하신 후 "나의 정보"페이지에 마케팅 정보 수신동의를 해주시기 바랍니다.
    </div>
    <div class="row" v-if="!successForm">
      <div class="col-6">
        <button
          class="btn btn-primary btn-block rounded-0"
          @click="handleYes"
        >예</button>
      </div>
      <div class="col-6">
        <button
          class="btn btn-primary btn-block rounded-0"
          @click="handleNo"
        >아니오</button>
      </div>
    </div>
    <div class="alert alert-success" v-else>
      거부 처리가 완료되었습니다.
    </div>
    <div class="alert alert-danger" v-if="error['userKey']">
      {{ error['userKey'] }}
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { NegoStockWeb } from '@/utils/constants';
import { INPUT_FORM__MARKETING_REJECT } from '@/store/mutation-types';

export default {
  name: 'MarketingReject',
  data() {
    return {
      NegoStockWeb,
    };
  },
  computed: {
    ...mapState('marketingReject', [
      'form',
      'error',
    ]),
    ...mapGetters('marketingReject', [
      'successForm',
    ]),
  },
  methods: {
    ...mapMutations('marketingReject', {
      'inputForm': INPUT_FORM__MARKETING_REJECT,
    }),
    ...mapActions('marketingReject', [
      'checkForm',
      'submitForm',
    ]),
    handleYes() {
      this.checkForm();
    },
    handleNo() {
      this.$router.replace({
        name: 'home',
      });
    },
  },
  mounted() {
    const { userKey } = this.$route.params;
    if (userKey) {
      this.inputForm({ key: 'userKey', value: userKey });
    }
  },
};
</script>

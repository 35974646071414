import _ from 'underscore';
import {
  CHECK_FORM__SELL_ASSINGS_INTENT,
  INIT_FORM__SELL_ASSINGS_INTENT,
  INPUT_FORM__SELL_ASSIGNS_INTENT,
  POST_FORM__SELL_ASSIGNS_INTENT,
  DONE_FORM__SELL_ASSIGNS_INTENT,
  FAIL_FORM__SELL_ASSIGNS_INTENT,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const INIT_FORM = {
  stockInfoUid: '',
  stockName: '',
  sellAssignKey: '',
  price: 0,
  quantity: 0,
  minimumOrderQuantity: 0,
  agreeForm: false,
  orderable: false,
};

const state = {
  form: { ...INIT_FORM },
  error: {},
  status: 'INIT',
};

const getters = {
  loadingForm: state => state.status === 'POST',
  invalidForm: state => _.keys(state.error).length > 0,
};

const mutations = {
  [CHECK_FORM__SELL_ASSINGS_INTENT] (state) {
    const { price, quantity, minimumOrderQuantity, agreeForm, orderable } = state.form;
    const error = {};
    
    if (!price) {
      error.price = '가격을 입력해 주세요.';
    }
    if (!quantity) {
      error.quantity = '수량을 입력해 주세요.';
    }
    if (quantity < minimumOrderQuantity) {
      error.quantity = '매도자가 설정한 최소 거래수량보다 작습니다.';
    }
    if (!agreeForm) {
      error.agreeForm = '유의사항을 확인해야합니다.';
    }
    if (!orderable) {
      error.orderable = '의향제출 가능시간이 아닙니다. (영업일: 오전 8:00 ~ 오후 2:20)';
    }

    state.error = { ...error };
  },
  [INIT_FORM__SELL_ASSINGS_INTENT] (state) {
    state.form = { ...INIT_FORM };
    state.error = {};
    state.status = 'INIT';
  },
  [INPUT_FORM__SELL_ASSIGNS_INTENT] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__SELL_ASSIGNS_INTENT] (state) {
    state.status = 'POST';
  },
  [DONE_FORM__SELL_ASSIGNS_INTENT] (state) {
    state.status = 'DONE';

    router.replace({ name: 'intents' });
  },
  [FAIL_FORM__SELL_ASSIGNS_INTENT] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  checkForm({ commit, dispatch, getters }) {
    commit(CHECK_FORM__SELL_ASSINGS_INTENT);

    if (!getters.invalidForm) {
      dispatch('submitForm');
    }
 },
 submitForm({ state, commit }) {
    commit(POST_FORM__SELL_ASSIGNS_INTENT);

    const { stockInfoUid, stockName, sellAssignKey, price, quantity } = state.form;
    api.post('/sellassigns/intent', { sellAssignKey, price, quantity }).then(
      reponse => setTimeout(() => commit(DONE_FORM__SELL_ASSIGNS_INTENT, { data: reponse.data, stockInfoUid, stockName }), 2000),
      error => commit(FAIL_FORM__SELL_ASSIGNS_INTENT, error.response.data),
    );
  },
  checkOrder({ commit }) {
    api.get('/sellassigns/intent').then(
      response => commit(INPUT_FORM__SELL_ASSIGNS_INTENT, { key: 'orderable', value: response.data }),
      error => commit(FAIL_FORM__SELL_ASSIGNS_INTENT, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default [
  {
    header: '네고스탁 비상장증권 중개에 관한 약관',
  },
  {
    title: '제 1 조 (목적)',
    text: '이 약관은 네고스탁을 통한 비상장증권중개서비스(이하 “서비스”라 한다)를 제공하는 코리아에셋투자증권주식회사(이하‚ “회사”라 한다.)와 동 서비스를 이용하는 고객 간의 서비스 이용에 관한 권리와 의무를 포함한 제반사항을 정함을 목적으로 한다.',
  },
  {
    title: '제 2 조 (용어의 정의)',
    list: [
      '①	이 약관에서 사용하는 ‘비상장증권 중개’라 함은 회사가 비상장증권 매수자와 매도자의 개별상대매매를 중개하는 업무를 말한다.',
      '②	이 약관에서 ‘거래자’라 함은 비상장증권을 매수하거나 매도하는 자를 말한다.',
      '③	이 약관에서 ‘매수자’라 함은 일정한 대가를 지불하고 비상장증권을 매도자로부터 매수하는 거래자를 말하고, ‘매도자’라 함은 일정한 대가를 수취하고 비상장증권을 매수자에게 매도하는 거래자를 말한다.',
      '④	이 약관에서 ‘네고스탁’이라 함은 거래자가 거래상대방을 탐색하고, 잠재적인 거래상대방과의 의사소통을 용이하게 하며, 회사에 비상장증권 중개요청 등을 편리하게 할 수 있도록 하기 위해 회사가 운영하는 웹사이트(도메인명 www.negostock.co.kr, fintech.kasset.co.kr) 및 모바일어플리케이션을 말한다.',
    ],
  },
  {
    title: '제 3 조 (중개 대상 비상장증권)',
    list: [
      '①	아래 각호의 증권 중에 회사가 지정한 증권으로 한정한다.',
      [
        '1.	한국예탁결제원에 예탁된 통일주권 주식 또는 전자등록된 내국법인의 주식 중 한국 거래소, 코스닥 및 코넥스에 상장되지 않은 주식',
        '2.	한국예탁결제원에 예탁된 증권(주식 제외) 중 한국 거래소, 코스닥 및 코넥스에 상장되지 않은 증권',
        '3.	1호 및 2호 이외에 회사가 별도로 지정한 증권',
      ],
      '②	회사는 중개 가능한 증권을 홈페이지 또는 네고스탁에 게시하여 거래자가 확인할 수 있도록 한다.',
    ],
  },
  {
    title: '제 4 조 (비상장증권 거래의 중지)',
    list: [
      '①	회사는 다음 각 호의 어느 하나에 해당하는 경우 비상장증권 중개의 전부 또는 일부를 정지할 수 있다. 이 경우, 회사는 그 사실을 회사의 웹사이트에 지체 없이 게시한다.',
      [
        '1.	네고스탁 전산시스템의 개선 및 오류 수정',
        '2.	법정공휴일, 임시공휴일',
        '3.	천재지변, 전시/사변 그 밖에 이에 준하는 사유가 발생한 경우',
        '4.	그 밖의 회사가 거래자에게 사전 고지하는 사유가 발생한 경우',
      ],
      '②	제1항에 의하여 비상장증권 중개가 정지된 후 정지사유가 해소된 때에는 회사는 그 사실을 회사의 웹사이트에 지체 없이 게시하고 비상장증권 중개를 재개할 수 있다.',
    ],
  },
  {
    title: '제 5 조 (비상장증권 중개 이용 약정)',
    list: [
      '①	고객은 회사의 영업점 또는 네고스탁에서 본 약관에 동의한 후 비상장주식 중개 이용신청을 하여야 한다.',
      '②	고객이 제6조 제1항에 따라 비상장증권을 매도신청하고자 할 경우에는 회사에 개설된 위탁계좌를 보유하고 있어야 한다. 이를 제외한 중개서비스는 회사에 위탁계좌를 개설하지 않고도 이용할 수 있다.',
    ],
  },
  {
    title: '제 6 조 (매도신청)',
    list: [
      '①	매도자가 매수할 상대방을 지정하지 않고, 비상장증권의 매도를 신청하고자 하는 경우 네고스탁 또는 회사가 사전 고지한 시간에 문서, 전화, 영업점 내점 및 그 밖의 방법에 의하여 이를 신청 할 수 있으며, 이 경우, 매도자는 대상 종목, 가격, 수량 등을 명시하여 신청하여야 한다. 회사는 매도자가 회사에 개설한 위탁계좌의 보유 잔고 여부를 확인한 후에 매도중개 업무를 개시한다.',
      '②	매도자가 네고스탁을 통해 매수할 거래상대방과 거래수량, 가격 및 거래일시 등을 지정하여 매도신청을 하는 경우에는 지정한 거래일시 이내 회사가 요청하는 시점에 회사가 지정하는 회사의 계좌로 매도할 주식을 이체하여야 한다.',
      '③	제2항의 매도신청의 경우 회사가 지정한 시점까지 회사가 지정하는 계좌로 매도할 주식을 이체하지 않을 경우 매도신청이 취소된다.',
      '④	제2항에 따라 매도할 주식을 이체하였더라도 매도신청 시 지정한 거래상대방이 매수대금을 입금하지 않을 경우에는 매도신청이 취소되고 이체한 주식은 매도신청 고객이 사전에 등록한 고객 명의의 증권계좌로 즉시 반환된다.',
      '⑤	제3항 및 제4항에 따라 매도신청이 취소된 경우 거래당사자 간의 개별 매매계약은 합의해제된 것으로 본다.',
    ],
  },
  {
    title: '제 7 조 (매수신청)',
    list: [
      '①	매수자가 네고스탁을 통해 매도할 거래상대방과 거래수량, 가격 및 거래일시 등을 지정하여 매수신청을 하는 경우에는 매수자가 지정한 거래일시 이내에서 회사가 지정하는 시간까지 회사가 지정하는 가상계좌로 매수금액을 입금하여야 한다.',
      '②	제1항의 매수신청의 경우 회사가 지정한 시간까지 회사가 지정한 가상계좌로 매수대금을 입금하지 않을 경우 매수신청이 취소된다. ',
      '③	제1항에 따라 매수대금을 입금하였더라도 매수신청 시 지정한 거래상대방이 매도할 주식을 회사가 지정한 증권계좌로 입고하지 않을 경우에는 매수신청이 취소되고 입금한 매수대금은 매수신청자 고객이 사전에 등록한 고객 명의의 은행계좌로 즉시 반환된다. ',
      '④	제2항 및 제3항에 따라 매수 신청이 취소된 경우 거래당사자 간의 개별 매매계약은 합의해제된 것으로 본다.',
    ],
  },
  {
    title: '제 8 조 (비상장주식 중개 신청 유효기간)',
    list: [
      '①	제6조 1항에 의한 비상장증권 매도신청의 유효기간은 신청자가 별도로 지정한 기간이 있는 경우는 그 기간으로 하고, 신청자의 별도 요청이 없는 없을 경우에는 신청일로부터 기산하여 30일 동안 유효하며, 그 이후에는 자동 취소 된다.',
      '②	제6조 2항 및 제7조 1항에 의한 비상장증권 중개 신청의 유효기간은 신청일 2영업일까지 유효하다. 다만, 중개 신청 시 신청자가 다르게 정한 경우에는 그 때까지로 한다.',
    ],
  },
  {
    title: '제 9 조 (신청수량단위 및 최소 신청수량)',
    list: [
      '①	비상장주식 중개의 신청수량단위 및 최소신청수량은 1주 단위로 한다. 주식을 제외한 비상장증권의 매도 신청 수량 및 최소신청수량은 종목별로 회사가 별도로 정한 단위로 한다.',
      '②	제1항의 규정에도 불구하고 회사는 최소매수 또는 매도금액을 설정할 수 있다.',
    ],
  },
  {
    title: '제 10 조 (비상장증권 중개 신청의 취소)',
    list: [
      '①	제6조 1항에 의한 비상장증권 중개 신청의 경우에는 거래가 체결되기 전 까지는 매도신청을 철회하거나 가격 및 수량 등을 변경할 수 있으며, 체결이 완료된 후에는 철회가 불가하다.',
      '②	제6조 2항 및 제7조 2항에 의한 비상장증권 중개 신청의 경우에는 매도신청 또는 매수신청 철회만 가능하며, 체결이 완료된 후에는 철회가 불가하다.',
    ],
  },
  {
    title: '제 11 조 (비상장증권 거래 체결방법)',
    list: [
      '①	비상장증권 거래의 체결은 매도자와 매수자가 각각 접촉하여 협상한 후 협상된 가격, 수량으로 체결한다. 이 때, 거래는 매수자와 매도자 1:1의 방식으로 진행한다. 다만, 고객이 네고스탁을 이용하여 익명의 상태로 각각 접촉하여 협상한 후 협상된 가격과 수량으로 회사에 중개신청을 하는 경우에는 회사는 동 가격, 수량으로 체결되도록 노력한다.',
      '②	비상장증권 거래자는 체결 전 협상된 종목의 가격, 수량 등을 확인하고, 거래자 상호간의 정보제공에 관한 동의를 하여야 한다.',
      '③	거래자는 제2항의 정보제공의 동의 후 네고스탁에 등록 또는 기타의 방법으로 본인의 인적 사항(실명, 주민등록번호 및 주소 등) 정보를 거래 각 상대방에게 제공한다.',
    ],
  },
  {
    title: '제 12 조 (비상장증권 거래 체결내역 통지)',
    list: [
      '①	회사는 제11조에 의하여 비상장증권 거래가 체결된 때에는 거래자에게 그 체결내역을 지체 없이 통지한다.',
      '②	통지를 받은 매도자 및 매수자는 통지 내용에 이의가 있는 경우 즉시 회사에 이의를 제기하여야 한다.',
      '③	제1항에 따라 회사가 비상장증권 거래 체결내역을 거래당사자에게 통지한 이후 비상장증권 거래자의 이의 제기가 없는 경우 매도자 및 매수자가 당해 비상장증권 거래 내역을 확인하고 승인한 것으로 본다.',
      '④	월간매매내역의 경우에는 매매가 체결된 날의 다음달 20일까지 이메일로 통지하는 것으로 한다.',
    ],
  },
  {
    title: '제 13 조 (매도 제한)',
    text: '비상장증권의 매도 시 「자본시장과 금융투자업에 관한 법률」(이하 “자본시장법” 이라 함) ‘매출’에 해당될 경우에는 회사는 매도에 대한 중개서비스를 제공하지 아니할 수 있다. 이 경우 회사는 거래자에게 지체 없이 통보 하여야 한다.',
  },
  {
    title: '제 14 조 (수수료)',
    list: [
      '①	제6조 1항에 따른 매도위탁 신청에 따른 중개의 경우 비상장증권 매도거래전용 계좌설정약관에 따른다.',
      '②	제6조 2항 및 제7조 1항에 따른 매도자 또는 매수자를 지정하여 중개 신청하는 경우에는 매도거래자로부터 <별첨>에서 정하는 수수료를 징수하며, 해당 금액과 증권거래세 등을 차감하고 매도금액을 입금한다.',
    ],
  },
  {
    title: '제 15 조 (수수료 등의 변경)',
    text: '회사는 영업점, 인터넷 홈페이지, 네고스탁 등에 중개수수료율을 게시하며, 필요한 경우 중개 수수료율을 변경할 수 있으며, 중개수수료율 변경 시 제16조를 준용한다.',
  },
  {
    title: '제 16 조 (약관 등의 변경)',
    list: [
      '①	회사는 계약의 중요내용 또는 약관 등을 변경하고자 하는 경우 회사의 영업점과 인터넷 홈페이지, 네고스탁 화면, 그 밖에 이와 유사한 전자 통신 매체에 변경내용을 변경 전 1개월간 비치 또는 게시하여야 한다.',
      '②	제1항의 변경내용이 거래자에게 불리한 경우 회사는 이를 서면 등 거래자와 사전에 합의한 방법으로 변경 전 1개월 전까지 통지하여야 한다. 다만, 기존 거래자에게 변경 전 기준 등이 그대로 적용되는 경우 또는 거래자가 변경내용에 대한 통지를 받지 아니하겠다는 의사를 명시적으로 표시한 경우에는 그러하지 아니한다.',
      '③	회사는 제2항의 통지를 할 경우‚ 고객이 변경에 동의하지 아니하는 경우에는 통지를 받은 날로부터 1개월 이내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 본다.‛ 라는 취지의 내용을 통지하여야 한다. 또한, 거래자가 제2항의 통지를 받은 날로부터 1개월이내에 계약해지의 의사표시를 하지 아니하는 경우에는 변경에 동의한 것으로 본다.',
      '④	회사는 약관을 영업점에 비치 또는 게시하여 거래자가 요구할 경우 이를 교부하여야 하며, 인터넷 홈페이지, 네고스탁 화면 기타 이와 유사한 전자통신매체에 게시하여 거래자가 약관을 조회하고 다운로드(화면출력 포함)받을 수 있도록 하여야 한다.',
    ],
  },
  {
    title: '제 17 조 (면책)',
    text: '회사는 천재지변, 전시/사변 기타 이에 준하는 사유로 인하여 비상장증권 거래자에게 손해가 발생한 경우 책임을 지지 아니한다.',
  },
  {
    title: '제 18 조 (주소변경 등의 신고)',
    text: '비상장증권 거래자는 주소, 사무소 기타 연락처가 변경된 때에는 지체 없이 회사에 신고하여야 한다. 통지의 불이행으로 발생하는 불이익에 대해서는 회사는 회사의 귀책사유가 없는 한 책임을 지지 아니한다. 다만 이 때에도 회사는 선량한 관리자로서의 주의의무를 다하여야 한다.',
  },
  {
    title: '제 19 조 (분쟁조정 및 관할법원)',
    list: [
      '①	비상장증권 거래자는 회사와 분쟁이 발생하는 경우 회사의 민원처리기구에 그 해결을 요구하거나 분쟁조정기관(금융감독원, 한국금융투자협회 등)에 분쟁조정을 신청할 수 있다.',
      '②	이 약관에 의한 거래와 관련하여 발생된 분쟁에 대하여 회사와 거래자 사이에 소송의 필요가 생긴 경우에는 그 관할법원은 민사소송법이 정한 바에 따른다.',
    ],
  },
  {
    title: '제 20 조 (기타)',
    list: [
      '①	이 약관에서 정하지 아니한 사항은 관계 법규 및 규정에서 정하는 바에 따르며, 관계 법규 및 규정에도 정함이 없는 경우에는 일반적인 상관례에 따른다.',
      '②	이 약관에 의한 비상장증권 중개 중 전자금융거래에 관하여는 전자금융거래법령이 우선 적용된다.',
    ],
  },
  {
    title: '별첨',
    list: [
      '1. 제14조 제2항의 “<별첨>에서 정하는” 수수료는 다음과 같다.',
    ],
    table: {
      head: [
        '구분',
        '부과기준',
        '수수료율',
      ],
      body: [
        [
          '비상장 주식',
          '매도거래금액',
          '0.2%',
        ],
        [
          '주식외 비상장증권',
          '매도거래금액',
          '0.1%',
        ],
      ],
    },
  },
  {
    title: '<부칙>',
    text: '본 약관은 2020년 5월 18일부터 시행합니다.',
  },
];

<template>
  <div class="my-3">
    <div class="mb-4">
      <div class="d-flex justify-content-between title-box mb-2">
        <h6 class="title text-primary mb-0 py-1">계좌정보</h6>
        <div>
          <button
            v-if="!registedAccount"
            class="btn btn-link btn-sm"
            @click="openRegister('account')"
          >등록하기</button>
          <button
            v-else
            disabled
            class="btn btn-link btn-sm"
          >등록완료</button>
          <button
            v-if="registedAccountEdit"
            class="btn btn-link btn-sm"
            @click="openRegister('account-edit')"
          >수정하기</button>
          </div>
      </div>
      <p class="content">증권계좌 정보는 매수 시 매수한 주식, 은행 등 금융계좌정보 매도 시 매매대금을 수령하기 위해 필요합니다.</p>
      <template v-if="registedAccount">
        <p
          v-if="user.brokerAccountInfo"
          class="mb-0"
        >(주식수령) {{ user.brokerAccountInfo }} (계좌주: {{ user.nameInfo }})</p>
        <p
          v-if="user.bankAccountInfo"
        >(현금수령) {{ user.bankAccountInfo }} (계좌주: {{ user.nameInfo }})</p>
      </template>
    </div>
    <div class="mb-4">
      <div class="d-flex justify-content-between title-box mb-2">
        <h6 class="title text-primary mb-0 py-1">실명정보</h6>
        <button
          v-if="user.nameStatus === 'NONE' || user.nameStatus === 'REQUEST'"
          class="btn btn-link btn-sm"
          @click="checkStatusRealname"
        >등록하기</button>
        <button
          v-else-if="user.nameStatus === 'READY'"
          disabled
          class="btn btn-link btn-sm"
        >확인중</button>
        <button
          v-else-if="user.nameStatus === 'CONFIRM'"
          disabled
          class="btn btn-link btn-sm"
        >등록완료</button>
      </div>
      <p class="content">금융실명법에 따라 본 정보는 실명확인 및 증권거래세 신고 등에 사용됩니다.</p>
      <p v-if="user.birthInfo">{{ user.nameInfo }} {{ user.birthInfo }}</p>
    </div>
    <div class="mb-4">
      <div class="d-flex justify-content-between title-box mb-2">
        <h6 class="title text-primary mb-0 py-1">투자권유 희망여부 등</h6>
        <router-link
          v-if="user.clientKey"
          class="btn btn-link btn-sm"
          :to="{
            name: 'INVESTOR_SUGGESTING',
            query: {
              acceptRecommend: user.acceptRecommend,
              acceptProvide: user.acceptProvide,
            },
          }"
        >수정하기</router-link>
        <button
          v-else
          class="btn btn-link btn-sm"
          @click="checkStatusInvestor"
        >수정하기</button>
      </div>
      <p class="content">투자권유를 희망하지 않거나, 투자자정보를 제공하지 않는 경우에는 회사가 투자권유를 할 수 없습니다.</p>
      <div v-if="user.clientKey">
        <p class="mb-0">투자권유: {{ user.acceptRecommend | user_accept_recommend }}</p>
        <template v-if="!user.acceptProvide">
          <p class="mb-0" >투자자정보: {{ user.acceptProvide | user_accept_provide }}<span v-if="user.acceptProvideTemp">(제공필요)</span></p>
        </template>
        <template v-else-if="isOverUserTime(user.scoreSignFinTime)">
          <p class="mb-0" >투자자정보: 유효기간 경과</p>
        </template>
        <template v-else>
          <p class="mb-0" >투자자성향평가결과: {{ userScoreLabel(user.score) }} ({{ user.scoreSignFinTime | date_short_0 }})</p>
        </template>
        <div class="alert alert-warning" v-if="user.acceptProvideTemp">
          <ul class="pl-2 font-sm mb-0">
            <li>제공하신 투자자정보와 관련하여 전자서명을 하셨다면, 새로고침을 눌러주세요.</li>
            <li>새로고침 하신 후에도 "미제공(제공필요)"로 표시된다면, 잠시 후 다시 시도해 주세요(반영에는 최대 1분 소요).</li>
            <li>일정시간 경과 후에도 "미제공(제공필요)"로 표시될 경우에는 수정하기를 눌러 다시 진행해 주세요.</li>
          </ul>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm font-sm"
              @click="reload"
            >새로고침</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="d-flex justify-content-between title-box mb-2">
        <h6 class="title text-primary mb-0 py-1">전문투자자 등록</h6>
        <button
          v-if="!user.proInvestor"
          class="btn btn-link btn-sm"
          @click="checkStatusProfessional"
        >등록하기</button>
        <button
          v-else
          disabled
          class="btn btn-link btn-sm"
        >등록완료</button>
      </div>
      <p class="content">타 금융회사에 전문투자자로 등록이 되어 있으신 경우 전문투자자 확인증 제출을 통해 등록이 가능합니다.</p>
    </div>
    <AppModal v-if="showModal" title="알림">
      <template v-if="modalStatus === 'REAL_NO_ACCOUNT'">
        <p>계좌정보를 먼저 등록 하신 후,<br> 실명정보를 등록하실 수 있습니다.</p>
        <button
          class="btn btn-dark btn-sm"
          @click="openRegister('account')"
        >계좌정보 등록하기</button>
      </template>
      <template v-if="modalStatus === 'PRO_NO_ACCOUNT'">
        <p>계좌 및 실명정보를 먼저 등록 하신 후에,<br> 전문투자자 등록을 하실 수 있습니다..</p>
        <button
          class="btn btn-dark btn-sm"
          @click="openRegister('account')"
        >계좌정보 등록하기</button>
      </template>
      <template v-if="modalStatus === 'PRO_NO_REAL'">
        <p>실명정보를 먼저 등록 하신 후에,<br> 전문투자자 등록을 하실 수 있습니다.</p>
        <button
          class="btn btn-dark btn-sm"
          @click="registerRealname"
        >실명정보 등록하기</button>
      </template>

      <template v-if="modalStatus === 'INVESTOR_NO_ACCOUNT'">
        <p>계좌 및 실명정보를 먼저 등록 하신 후에,<br> 투자권유 희망여부를 수정 하실 수 있습니다.</p>
        <button
          class="btn btn-dark btn-sm"
          @click="openRegister('account')"
        >계좌정보 등록하기</button>
      </template>
      <template v-if="modalStatus === 'INVESTOR_NO_REAL'">
        <p>실명정보를 먼저 등록 하신 후에,<br> 투자권유 희망여부를 수정 하실 수 있습니다.</p>
        <button
          class="btn btn-dark btn-sm"
          @click="registerRealname"
        >실명정보 등록하기</button>
      </template>
      <template v-if="modalStatus === 'INVESTOR_NO_READY'">
        <p class="mb-0">실명정보를 확인 중입니다.</p>
      </template>
      <template v-slot:footer>
        <button class="btn btn-outline-dark btn-sm" @click="closeModal">닫기</button>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';
import { isOverUserTime, userScoreLabel } from '@/utils/checkUser';
import AppModal from '@/components/app/AppModal.vue';

export default {
  name: 'InfomationRegist',
  components: {
    AppModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      modalStatus: 'NONE',
      isOverUserTime,
      userScoreLabel,
    };
  },
  computed: {
    registedAccount() {
      const { bankAccountStatus, brokerAccountStatus } = this.user;
      return bankAccountStatus === 'CONFIRM' && (brokerAccountStatus === 'CONFIRM' || brokerAccountStatus === 'READY');
    },
    registedAccountEdit() {
      const { bankAccountStatus, nameStatus } = this.user;
      return bankAccountStatus === 'CONFIRM' && nameStatus === 'CONFIRM';
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    checkStatusRealname() {
      if (!this.registedAccount) {
        this.modalStatus = 'REAL_NO_ACCOUNT';
        this.openModal();
      } else {
        this.registerRealname();
      }
    },
    checkStatusProfessional() {
      const { nameStatus } = this.user;
      if (!this.registedAccount) {
        this.modalStatus = 'PRO_NO_ACCOUNT';
        this.openModal();
      } else if (nameStatus === 'NONE' || nameStatus === 'REQUEST') {
        this.modalStatus = 'PRO_NO_REAL';
        this.openModal();
      } else {
        this.registerProfessional();
      }
    },
    registerRealname() {
      const { nameStatus, corp } = this.user;
      if (nameStatus === 'NONE' && !corp) {
        this.openRegister('realname');
      } else if (nameStatus === 'NONE' && corp) {
        this.openRegister('corp');
      } else if (nameStatus === 'REQUEST') {
        this.openRegister('file-id');
      }
    },
    registerProfessional() {
      this.openRegister('professional');
    },
    openRegister(link) {
      const type = (link === 'account' || link === 'account-edit') ? '0' : '1';
      const PATH = [
        `${NegoStockApi}/sessions/${type}/${FintechCommPath}/auth`,
        `?path=/users/${link}`,
        `&redirect_path=${NegoStockWeb}/user/infomation`,
      ].join('');
      window.location = PATH;
    },
    checkStatusInvestor() {
      const { nameStatus } = this.user;
      if (!this.registedAccount) {
        this.modalStatus = 'INVESTOR_NO_ACCOUNT';
        this.openModal();
      } else if (nameStatus === 'NONE' || nameStatus === 'REQUEST') {
        this.modalStatus = 'INVESTOR_NO_REAL';
        this.openModal();
      } else if (nameStatus === 'READY') {
        this.modalStatus = 'INVESTOR_NO_READY';
        this.openModal();
      }
    },
    reload() {
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-box {
  border-bottom: 5px solid #7399c6;
}
.title {
  @include nego-font(1.125rem, 700, 1.03, -0.5px);
}
</style>

import {
  REQUEST__KEYWORDS,
  RECEIVE__KEYWORDS,
  FAILURE__KEYWORDS,
  TOGGLE__KEYWORDS,
  DESELECT__KEYWORDS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [
    // [keywordUid, keyword]
  ],
  error: {},
  status: 'INITIAL',
  selectedKeywords: [], // keywordUid
};

const getters = {
  hasSelected: state => uid => state.selectedKeywords.includes(uid),
  selectedList: state => state.selectedKeywords,
};

const mutations = {
  [TOGGLE__KEYWORDS] (state, keywordUid) {
    const { selectedKeywords } = state;
    const index = selectedKeywords.indexOf(keywordUid);
    if (index < 0) {
      selectedKeywords.push(keywordUid);
    } else {
      state.selectedKeywords = [
        ...selectedKeywords.slice(0, index),
        ...selectedKeywords.slice(index + 1),
      ];
    }
  },
  [DESELECT__KEYWORDS] (state) { 
    state.selectedKeywords = [];
  },
  [REQUEST__KEYWORDS] (state) {
    state.error= {};
    state.status = 'REQUEST';
  },
  [RECEIVE__KEYWORDS] (state, list) {
    state.list = list.map(({ keywordUid, keyword }) => [keywordUid, keyword]);
    state.status = 'RECEIVE';
  },
  [FAILURE__KEYWORDS] (state, error) {
    state.error= { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  fetchKeywords({ commit }) {
    commit(REQUEST__KEYWORDS);
    api.get('/public/cover/keywords').then(
      response => commit(RECEIVE__KEYWORDS, response.data),
      error => commit(FAILURE__KEYWORDS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

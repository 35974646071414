<template>
<div class="agent bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 bg-white">
        <template v-if="target === 'comm' || target === 'commalpha'">
          <h3 class="pt-3">네고스탁 - 거래 실명정보 입력</h3>
          <p>네고스탁 거래 실명정보 입력은 모바일기기에서만 가능합니다. 고객님의 휴대전화번호로 거래 실명정보를 등록할 수 있는 링크를 발송해드렸습니다.</p>
        </template>
        <template v-if="target === 'openaccount' || target === 'openaccountalpha'">
          <h3 class="pt-3">네고스탁 - 비대면 계좌개설</h3>
          <p>비대면 계좌개설은 모바일 기기에서만 신청가능합니다. 고객님의 휴대전화번호로 계좌개설을 진행하실 수 있는 링크를 발송해드렸습니다.</p>
        </template>
        <div class="my-3">
          <router-link
             class="btn btn-primary btn-block"
            :to="{name: 'home'}"
          >홈으로 이동</router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AgentView',
  computed: {
    target() {
      const { target } = this.$route.params;
      return target;
    },
  },
};
</script>

<style scoped>
.agent {
  min-height: 55vh;
}
</style>

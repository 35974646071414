import {
  REQUEST_LIST__LP_FUNDING,
  RECEIVE_LIST__LP_FUNDING,
  FAILURE_LIST__LP_FUNDING,
  SELECT__LP_FUNDING,
  CLEAR__LP_FUNDING,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  error: {},
  status: 'INITIAL',
  item: null,
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [REQUEST_LIST__LP_FUNDING] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__LP_FUNDING] (state, data) {
    state.list = [...data]
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__LP_FUNDING] (state) {
    state.status = 'FAILURE';
  },
  [SELECT__LP_FUNDING] (state, item) {
    state.item = { ...item };
  },
  [CLEAR__LP_FUNDING] (state) {
    state.item = null;
  },
};

const actions = {
  requestListPublic({ commit }) {
    commit(REQUEST_LIST__LP_FUNDING);

    api.get('/public/deals/pro/placements').then(
      response => commit(RECEIVE_LIST__LP_FUNDING, response.data),
      error => commit(FAILURE_LIST__LP_FUNDING, error),
    );
  },
  requestList({ commit }) {
    commit(REQUEST_LIST__LP_FUNDING);

    api.get('/deals/pro/placements').then(
      response => commit(RECEIVE_LIST__LP_FUNDING, response.data),
      error => commit(FAILURE_LIST__LP_FUNDING, error),
    );
  },
  registNotice({ dispatch, commit, state }) {
    const { companyCode, dealKey } = state.item;

    api.post('/cover/notice', { companyCode, dealKey }).then(
      () => {
        commit(CLEAR__LP_FUNDING);
        dispatch('requestList');
      },
      error => commit(FAILURE_LIST__LP_FUNDING, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import axios from 'axios';
import {
  NegoStockApi,
  xsrfCookieName,
  xsrfHeaderName,
} from '@/utils/constants';

const instance = axios.create({
  baseURL: NegoStockApi,
  withCredentials: true,
  xsrfCookieName,
  xsrfHeaderName,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 412) {
      document.location.replace('/');
    }
    return Promise.reject(error);
  }
);

export default instance;

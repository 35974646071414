<template>
  <div class="d-flex justify-content-center align-items-center bg-gray image-box">
    <img
      v-if="isLoadingSuccess"
      class="w-100"
      :src="src"
      @error="handleError"
    />
    <IconBuilding
      v-else
      :class="iconClass"
    />
  </div>
</template>

<script>
import IconBuilding from '@/components/icons/IconBuilding';

export default {
  name: 'ImageOrIcons',
  components: {
    IconBuilding,
  },
  props: {
    src: {
      type: String,
      required: false,
    },
    iconClass: {
      type: String,
      default: 'icon-xl text-light',
    },
  },
  data() {
    return {
      isLoadingSuccess: true,
    };
  },
  methods: {
    handleError() {
      this.isLoadingSuccess = false;
    },
  },
  beforeMount() {
    if (!this.src) {
      this.handleError();
    }
  },
};
</script>

<style scoped>
.image-box {
  height: 84px;
}
.image {
  object-fit: contain;
}
</style>

<template>
  <div class="">
    <div class="text-center border-bottom my-4 py-2 h5">
      {{ isNotClosing ? '결성총회 안내' : '신규 및 추가 출자약정 안내' }}
    </div>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td class="align-middle">조합명</td>
          <td class="align-middle">{{ data.dealName }}</td>
        </tr>
        <tr>
          <td class="align-middle">조합원</td>
          <td class="align-middle">
            성명: {{ data.nameInfo }}<br/>
            생년월일: <samp>{{ data.birthInfo }}</samp>
          </td>
        </tr>
        <tr>
          <td class="align-middle" v-if="isNotClosing">투자약정금액</td>
          <td class="align-middle" v-else>신규 및 추가<br/>출자약정금액</td>
          <td class="align-middle">{{ data.commitAmount | currency }} 원</td>
        </tr>
      </tbody>
    </table>
    <div class="mb-4">
      <h6>1. {{ isNotClosing ? '결성총회' : '신규 및 추가 출자약정' }} 안내문 및 서면제출서류 양식</h6>
      <div class="alert alert-success mb-0 px-2">
        <div
          v-for="(file, index) in files"
          :key="index"
        >
          <FileDownload
            v-if="file.name && file.placementFileKey"
            :fileName="file.name"
            :fileKind="fileKindName(file.kind)"
            :fileKey="file.placementFileKey"
            :fileSize="file.size"
            :filePath="`${NegoStockApi}/public/placements/file`"
          />
        </div>
      </div>
    </div>
    <div class="mb-4">
      <h6>2. 제출서류 전자서명</h6>
      <div class="alert alert-primary mb-4 px-2">
        <p>아래 전자서명 버튼을 누르시면 "카카오페이 전자서명" 카카오톡 메시지가 수신됩니다.</p>
        <p class="mb-0">창을 닫으신 후 해당 메시지에서 전자서명을 진행해 주세요.</p>
      </div>
    </div>
    <template v-if="files.length">
      <template v-if="form['tempUser']">
        <InputCheckbox
          id="form_D"
          label="상품설명서 관련하여 다음을 확인함"
          :value="form.D"
          :error="valid.D"
          @input-form="(value) => inputForm({ key: 'D', value })"
          :linkFile="`${NegoStockApi}/public/placements/file/${files[2].placementFileKey}`"
          linkName="상품설명서.pdf"
        />
        <div class="border p-3 mb-3">
          <template v-if="data.salesCompanyName && data.salesPersonName">
            <ul class="pl-3">
              <li>본인은 아래의 직원으로부터 적합성(적정성)원칙 적용 별도 요청 사전고지, 신기술조합투자의 내용, 주요 투자위험(신용위험, 시장위험, 환위험등), 원금손실가능성(예금자 보호법상 보호대상 아님*), 수수료, 조기청산조건, 탈퇴‧해산 및 제명, 지위 양도‧승계‧대리 등에 대하여 설명을 듣고 이해하였음</li>
              <li>이와 함께, 동 조합의 투자위험은 5등급 중 ( 1 )등급으로 최대 원금전액 또는 일부 손실이 가능하며 조기상환, 환매 불가 여부 등 해당 조합의 특성을 충분히 이해하였음</li>
            </ul>
            <div>
              <p>판매(설명)직원</p>
              <table class="table table-sm table-borderless">
                <thead>
                  <tr>
                    <td><small>회사명</small></td>
                    <td><small>성명</small></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ data.salesCompanyName }}</td>
                    <td>{{ data.salesPersonName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-else>
            <ul class="pl-3 mb-0">
              <li>신기술조합투자의 내용, 주요 투자위험(신용위험, 시장위험, 환위험등), 원금손실가능성(예금자 보호법상 보호대상 아님*), 수수료, 조기청산조건, 탈퇴‧해산 및 제명, 지위 양도‧승계‧대리 등에 대하여 이해하였음</li>
              <li>이와 함께, 동 조합의 투자위험은 5등급 중 ( 1 )등급으로 최대 원금전액 또는 일부 손실이 가능하며 조기상환, 환매 불가 여부 등 해당 조합의 특성을 충분히 이해하였음</li>
            </ul>
          </template>
        </div>
      </template>
      <InputCheckbox
        id="form_E"
        label="규약과 사업계획의 내용을 숙지하고 동의함"
        :value="form.E"
        :error="valid.E"
        @input-form="(value) => inputForm({ key: 'E', value })"
        :linkFile="`${NegoStockApi}/public/placements/file/${files[3].placementFileKey}`"
        linkName="조합규약.pdf"
      />
      <InputCheckbox
        id="form_F"
        :label="isNotClosing ? '결성총회 의안에 대해 전부 찬성함' : '동의사항 설명 및 보고사항 내용 확인함'"
        :value="form.F"
        :error="valid.F"
        @input-form="(value) => inputForm({ key: 'F', value })"
        :linkFile="`${NegoStockApi}/public/placements/file/${files[4].placementFileKey}`"
        :linkName="isNotClosing ? '결성총회 의안 설명서.pdf' : '동의사항 등 설명서.pdf'"
      />
      <div class="border p-3 mb-3" v-if="isNotClosing">
        <ul class="pl-3 mb-0">
          <li>제1호 의안 : 조합규약 승인의 건</li>
          <li>제2호 의안 : 사업계획 승인의 건</li>
          <li>제3호 의안 : 회계감사인 선임의 건</li>
        </ul>
      </div>
    </template>

    <template v-if="loadingForm">
      <div class="spinner-border my-3" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </template>
    <template v-else>
      <div v-if="error['subscriptionKey'] || error['kakao'] || error['mobileForSign']" class="my-4">
        <div class="alert alert-warning">
          {{ error.subscriptionKey || error.kakao }}
        </div>
      </div>
      <div v-if="data.offline && (data.status === 'SIGN_EXPIRE' || error['kakao'] || error['mobileForSign'])" class="my-4">
        <InputNumber
          label="조합원 명의 휴대전화번호"
          id="mobileForSign"
          :value="form['mobileForSign']"
          :error="valid['mobileForSign']"
          @input-form="(value) => inputForm({ key: 'mobileForSign', value })"
        />
      </div>
    </template>

    <div class="mb-4">
      <div
        v-if=" showedAlert"
        class="alert alert-warning"
      >{{ !data.offline && data.status === 'SIGN_EXPIRE' ? messageStatusOnline[data.status] : messageStatus[data.status] }}</div>
      <button
        class="btn btn-kakao btn-lg btn-block"
        @click="checkForm"
        v-if="enabledForm"
      >
        <IconChatFill class="btn-kakao-symbol mr-1 mb-1" />
        <span class="btn-kakao-label"> 카카오페이 전자서명</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FileDownload from '@/components/form/FileDownload.vue';
import InputCheckbox from '@/components/form/InputCheckbox.vue';
import InputNumber from '@/components/form/InputNumber.vue';
import IconChatFill from '@/components/icons/IconChatFill.vue';
import { INPUT_FORM__AGREE_FILE } from '@/store/mutation-types';
import { NegoStockApi } from '@/utils/constants';
import {
  PLACEMENT_FILE__KIND,
  PLACEMENT_FILE_ADDITIONAL__KIND,
} from '@/filters/filtersSubscriptions';

export default {
  name: 'AgreeFileView',
  components: {
    FileDownload,
    InputCheckbox,
    InputNumber,
    IconChatFill,
  },
  data() {
    return {
      NegoStockApi,
      messageStatus: {
        SIGN_ASK: '카카오톡 카카오페이 인증요청을 확인해주세요.',
        SIGN_FIN: '전자서명을 완료하셨습니다.',
        SIGN_EXPIRE: '카카오페이 인증 유효기간이 경과되었습니다. 조합원 명의의 휴대전화번호를 입력하신 후 다시 진행해주세요.',
        FILE_EXPIRE: '전자서명 서류가 변경되었습니다. 첨부서류 확인 하신 후 다시 진행해주세요.',
        BROKEN: '조합가입 신청이 취소되었습니다.',
      },
      messageStatusOnline: {
        SIGN_EXPIRE: '카카오페이 인증 유효기간이 경과되었습니다.',
      },
    };
  },
  computed: {
    ...mapState('agreeFile', [
      'data',
      'files',
      'form',
      'valid',
      'error',
    ]),
    ...mapGetters('agreeFile', [
      'showedAlert',
      'enabledForm',
      'loadingForm',
    ]),
    isNotClosing() {
      return !this.data['additionalClosing'];
    },
  },
  methods: {
    ...mapMutations('agreeFile', {
      'inputForm': INPUT_FORM__AGREE_FILE,
    }),
    ...mapActions('agreeFile', [
      'requestData',
      'postForm',
      'checkForm',
    ]),
    fileKindName(kind) {
      const map = this.isNotClosing ? PLACEMENT_FILE__KIND : PLACEMENT_FILE_ADDITIONAL__KIND;
      const item = map.find(el => el.value === kind);
      return item?.label || '-';
    },
  },
  mounted() {
    const tempUser = this.$route.query.proInvestor ? false : true;
    this.inputForm({ key: 'tempUser', value: tempUser });

    const { subscriptionKey } = this.$route.params;
    this.requestData({ subscriptionKey });
  },
};
</script>

<style lang="scss" scoped>
.btn-kakao {
  background-color: #FEE500;
}
.btn-kakao-symbol,
.btn-kakao-label {
  color: #000000;
}
.btn-kakao-label {
  opacity: 0.85;
}
</style>

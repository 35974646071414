<template>
  <div>
    <LoadingSpinner v-if="loading" />
    <div v-else-if="list.length === 0" class="alert alert-light">
      등록된 영상이 없습니다.
    </div>
    <div v-else class="row">
      <template v-for="(video, index) in list">
        <div :key="index" class="col-12 col-md-6 mb-3">
          <CardListVideosItem
            :video="video"
            :currentVideo="selectedVideo"
            :enableDelete="enableDelete"
            :enableCreate="enableCreate"
            @remove-item="(companyVideoUid) =>$emit('remove-item', companyVideoUid)"
            @select-item="(videoId) => selectVideo(videoId)"
            @create-item="(video) => $emit('create-item', {video, index})"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/form/LoadingSpinner.vue';
import CardListVideosItem from './CardListVideosItem.vue';

export default {
  name: 'CardListVideos',
  components: {
    LoadingSpinner,
    CardListVideosItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      default: false,
    },
    enableCreate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selectedVideo: '',
    };
  },
  methods: {
    selectVideo(videoId) {
      this.selectedVideo = videoId;
    },
  },
};
</script>

import icon1 from './img/searchIcon.png';
import icon2 from './img/progIcon.png';
import icon3 from './img/regIcon.png';
import icon4 from './img/compIcon.png';

const LinkGroups = {
  group3: {
    label: '기업정보',
    icon: icon3,
    children: [
      { name: '__OTC_HOT__', label: 'HOT비상장' },
      { name: '__OTC_REPORTS__', label: '비상장리포트' },
      // { name: '__PLACEMENTS_ADVISE__', label: '딜Asked' },
      // { name: '__CONSULT_LIST__', label: '1:1문의(Beta)' },
    ],
  },
  group1: {
    label: '투자기회',
    icon: icon1,
    children: [
      { name: '__LP_FUNDING__', label: '조합원모집' },
      { name: '__HELP_INTENT__', label: 'Pro스토어' },
      { name: 'markets_board', label: '회원간매매' },
    ],
  },
  group2: {
    label: '투자진행',
    icon: icon2,
    children: [
      { name: '__LP_JOIN__', label: '조합가입' },
      { name: 'intents', label: '협의진행' },
      { name: 'transactions_progress', label: '매매진행' },
    ],
  },
  group4: {
    label: 'MY',
    icon: icon4,
    children: [
      { name: '__INVEST_PLACEMENTS__', label: 'MY조합' },
      { name: 'mystocks', label: 'MY매물', sub: ['mystocks', 'sell_assigns'] },
      { name: 'transactions_list', label: '거래내역' },
      { name: 'user_infomation', label: '회원정보' },
    ],
  },
};

export {
  LinkGroups,
};

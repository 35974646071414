<template>
  <button
    class="btn btn-block my-3"
    :class="[enabledChat? 'btn-outline-secondary' : 'btn-outline-light' ]"
    :disabled="!enabledChat"
    @click="$emit('click-button')"
  >
    <div class="d-flex text-left">
      <img src="./img/nego.png" alt="" class="nego-icon">
      <div class="flex-grow-1 ml-2">
        <h6 class="mb-0">{{ message }} <small class="float-right" v-if="hasNewMessage">새 메시지</small></h6>
        <small class="mb-0">유효기간: {{ expiration | date }}</small>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'IntentsCardMessage',
  props: {
    enabledChat: {
      type: Boolean,
      required: true,
    },
    hasNewMessage: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: null,
    },
    expiration: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nego-icon {
  height: 2.5rem;
}
.message {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
}
</style>

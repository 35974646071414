<template>
  <div>
    <FormHead
      :title="form.stockName"
      :text="form.stockInfoUid"
    />
    <template v-if="isPriceZero">
      <SellAssignsOrderNoticeNonPro v-if="isNonProBuyable" />
      <SellAssignsOrderNoticeOnlyPro v-else />
    </template>
    <form class="mt-5" @submit.prevent="checkForm">
      <InputNumberToString
        disabled
        id="price"
        :label="`주당가격 (원)${isPriceZero ? labelPrice : ''}`"
        :value="form['price']"
      />
      <InputNumberToString
        id="quantity"
        :label="`매수수량 (최소 ${form.minimumOrderQuantity} 주 이상)`"
        append="주"
        :value="form['quantity']"
        @input-form="(value) => inputForm({ key: 'quantity', value })"
        :error="error['quantity']"
      />
      <InputNumberToString
        disabled
        id="totalPrice"
        label="거래금액 (원)"
        :value="form['price'] * form['quantity']"
      />
      <h6>유의사항</h6>
      <ol class="pl-4">
        <li>바로 매수 신청 후, 1시간 이내 지정한 가상계좌로 매수대금을 입금하시지 않을 경우, 신청 취소됩니다.</li>
        <li>입금 확인 후 30분 이내에 미리 등록해 두신 계좌로 매수한 주식을 입고 받으실 수 있습니다.</li>
        <li>본 매수 신청이 실제 주식 매수를 100% 보장하지는 않으며, 매수가 이루어지지 않을 경우, 입금 후 최대 2시간 이내에 대금을 반환해 드립니다.</li>
      </ol>
      <InputCheckbox
        id="agreeForm"
        :value="form['agreeForm']"
        :error="error['agreeForm']"
        @input-form="(value) => inputForm({ key: 'agreeForm', value })"
        label="유의사항 확인함"
      />
      <div class="alert alert-danger" role="alert" v-if="error['orderable']">
        {{ error['orderable'] }}
      </div>
      <button
        v-if="isPriceZero"
        type="button"
        class="btn btn-secondary btn-block"
        @click="showModalNotice"
      >바로매수 신청</button>
      <SubmitButton
        v-else
        label="바로매수 신청"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <SellAssignsOrderNoticeModal ref="modalNotice">
      <SellAssignsOrderNoticeNonPro v-if="isNonProBuyable" />
      <SellAssignsOrderNoticeOnlyPro v-else />
    </SellAssignsOrderNoticeModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputNumberToString from '@/components/form/InputNumberToString.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import SellAssignsOrderNoticeModal from '@/components/sellAssigns/SellAssignsOrderNoticeModal.vue';
import SellAssignsOrderNoticeNonPro from '@/components/sellAssigns/SellAssignsOrderNoticeNonPro.vue';
import SellAssignsOrderNoticeOnlyPro from '@/components/sellAssigns/SellAssignsOrderNoticeOnlyPro.vue';
import {
  INIT_FORM__SELL_ASSIGNS_ORDER,
  INPUT_FORM__SELL_ASSIGNS_ORDER,
} from '@/store/mutation-types';

export default {
  name: 'SellAssignsOrderView',
  components: {
    FormHead,
    InputNumberToString,
    SubmitButton,
    InputCheckbox,
    SellAssignsOrderNoticeModal,
    SellAssignsOrderNoticeNonPro,
    SellAssignsOrderNoticeOnlyPro,
  },
  computed: {
    ...mapState('sellAssignsOrder', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('sellAssignsOrder', [
      'loadingForm',
    ]),
    isPriceZero() {
      return this.form['price'] === 0;
    },
    isNonProBuyable() {
      return this.form['nonProBuyable'];
    },
    labelPrice() {
      const { nonProBuyable } = this.form;
      if (nonProBuyable) {
        return ': 투자적합고객에게만 공개';
      } else {
        return ': 전문투자자에게만 공개';
      }
    },
  },
  methods: {
    ...mapMutations('sellAssignsOrder', {
      'initForm': INIT_FORM__SELL_ASSIGNS_ORDER,
      'inputForm': INPUT_FORM__SELL_ASSIGNS_ORDER,
    }),
    ...mapActions('sellAssignsOrder', [
      'requestInfo',
      'checkForm',
    ]),
    showModalNotice() {
      this.$refs.modalNotice.openModal();
    },
  },
  mounted() {
    const { sellAssignKey } = this.$route.query;
    this.requestInfo(sellAssignKey);
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

<template>
    <div class="card">
      <template v-if="video.videoId !== currentVideo">
        <div class="card-img-top"
          @click="$emit('select-item', video.videoId)"
        >
          <img :src="video.thumbnail" alt="" class="img-fluid w-100">
        </div>
      </template>
      <template v-if="video.videoId === currentVideo">
        <CardListVideosItemPlayer :videoId="video.videoId" />
      </template>
    </div>
</template>

<script>
import CardListVideosItemPlayer from './CardListVideosItemPlayer.vue';

export default {
  name: 'CardListVideosItem',
  components: {
    CardListVideosItemPlayer,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    currentVideo: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.card-img-top {
  cursor: pointer;
}
</style>

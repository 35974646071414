import {
  INPUT_STOCK__MARKETS_BOARD,
  REQUEST_LIST__MARKETS_BOARD,
  RECEIVE_LIST__MARKETS_BOARD,
  FAILURE_LIST__MARKETS_BOARD,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  stock: {
    stockInfoUid: '', // '259960' | 'all'
    stockName: '',
    type: 'SELL',
  },
  markets: {
    SELL: [],
    BUY: [],
  },
  error: {},
  status: 'INITIAL',
};

const getters = {
  isTotalList: state => state.stock.stockInfoUid === 'all',
  currentList: state => state.markets[state.stock.type],
  countSell: state => state.markets.SELL.length,
  countBuy: state => state.markets.BUY.length,
  loadingList: state => state.status === 'REQUEST',
};

const mutations = {
  [INPUT_STOCK__MARKETS_BOARD] (state, { key, value }) {
    state.stock[key] = value;
  },
  [REQUEST_LIST__MARKETS_BOARD] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_LIST__MARKETS_BOARD] (state, contents) {
    state.markets = {
      SELL: [...contents.sell],
      BUY: [...contents.buy],
    };
    state.status = 'RECEIVE';
  },
  [FAILURE_LIST__MARKETS_BOARD] (state) {
    state.status = 'FAILURE';
  },
};

const actions = {
  requestListPublic({ state, commit }) {
    commit(REQUEST_LIST__MARKETS_BOARD);

    const { stockInfoUid } = state.stock;
    api.get(`/public/userstocks/${stockInfoUid}`).then(
      response => commit(RECEIVE_LIST__MARKETS_BOARD, response.data),
      error => commit(FAILURE_LIST__MARKETS_BOARD, error),
    );
  },
  requestList({ state, commit }) {
    commit(REQUEST_LIST__MARKETS_BOARD);

    const { stockInfoUid } = state.stock;
    api.get(`/userstocks/${stockInfoUid}`).then(
      response => commit(RECEIVE_LIST__MARKETS_BOARD, response.data),
      error => commit(FAILURE_LIST__MARKETS_BOARD, error),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="">
    <div class="text-center border-bottom my-4 py-2 h5">
      결성총회 안내
    </div>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>조합명</td>
          <td>{{ data.dealName }}</td>
        </tr>
        <tr>
          <td>조합원</td>
          <td>
            <p class="mb-0">성명: {{ data.nameInfo }}</p>
            <p class="mb-0">생년월일: {{ data.birthInfo }}</p>
          </td>
        </tr>
        <tr>
          <td>투자약정금액</td>
          <td>{{ data.commitAmount | currency }} 원</td>
        </tr>
      </tbody>
    </table>
    <div class="my-4">
      <h6>결성총회 안내문 및 서면제출서류 양식</h6>
      <div class="alert alert-success mb-0">
        <div
          v-for="(file, index) in files"
          :key="index"
        >
          <FileDownload
            v-if="file.name && file.placementFileKey"
            :fileName="file.name"
            :fileKind="file.kind | placement_file__kind"
            :fileKey="file.placementFileKey"
            :fileSize="file.size"
            :filePath="`${NegoStockApi}/public/placements/file`"
          />
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="alert alert-primary mb-4">
        <p class="mb-0">제출서류 파일을 다운로드 받으신 후 안내에 따라, 필요서류를 서면 제출해주시기 바랍니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FileDownload from '@/components/form/FileDownload.vue';
import { NegoStockApi } from '@/utils/constants';

export default {
  name: 'ConfirmFileView',
  components: {
    FileDownload,
  },
  data() {
    return {
      NegoStockApi,
    };
  },
  computed: {
    ...mapState('agreeFile', [
      'data',
      'files',
    ]),
  },
  methods: {
    ...mapActions('agreeFile', [
      'requestData',
    ]),
  },
  mounted() {
    const { subscriptionKey } = this.$route.params;
    this.requestData({ subscriptionKey });
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex justify-content-between bg-primary text-white px-3"},[_c('div',[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.transaction.stockName))]),_c('p',{staticClass:"mb-0 stock-code"},[_vm._v(_vm._s(_vm.transaction.stockCode))])]),_c('h6',[_vm._v(_vm._s(_vm.transaction.seller ? '매도' : '매수'))])]),_c('div',{staticClass:"card-body price p-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"mb-1"},[_vm._v("가격")]),_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.offerPrice))+" "),_c('small',[_vm._v("원")])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"h6 mb-1"},[_vm._v(" 수량 "),_c('span',{staticClass:"badge badge-pill badge-primary font-weight-lighter"},[_vm._v(_vm._s(_vm.shareMessage))]),(_vm.enableShareIn && !_vm.isLinking)?_c('router-link',{staticClass:"btn btn-outline-primary btn-sm ml-3 py-0",attrs:{"to":{
            name: 'transactions_share_in',
            params: { transactionKey: _vm.transaction.transactionKey }
          }}},[_vm._v("이체통보")]):_vm._e()],1),_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.offerQuantity))+" "),_c('small',[_vm._v("주")])])]),_c('div',{staticClass:"d-flex justify-content-between border-top pt-2"},[_c('div',{staticClass:"mb-0 h6"},[_vm._v(" 대금 "),_c('span',{staticClass:"badge badge-pill badge-primary font-weight-lighter"},[_vm._v(_vm._s(_vm.payMessage))]),(_vm.enableDeposit && !_vm.isLinked && _vm.modeTest)?_c('router-link',{staticClass:"btn btn-outline-primary btn-sm ml-3",attrs:{"to":{
            name: 'transactions_pay_in',
            params: { transactionKey: _vm.transaction.transactionKey }
          }}},[_vm._v("입금통보")]):_vm._e()],1),_c('h6',[_vm._v(_vm._s(_vm._f("currency")(_vm.transaction.offerPrice * _vm.transaction.offerQuantity))+" "),_c('small',[_vm._v("원")])])]),_c('ul',{staticClass:"my-3 description pl-3"},[_c('li',{domProps:{"innerHTML":_vm._s(_vm.message)}})]),_c('p',{staticClass:"expiration mt-1 mb-2 text-right"},[_vm._v("결제이행: "+_vm._s(_vm._f("date")(_vm.transaction.expiration))+" 까지")]),(_vm.showBankInfo && !_vm.isLinked)?_c('div',{staticClass:"alert alert-primary text-center p-2 mb-0"},[_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.transaction.virtualAccount ? '농협은행' : ''))]),_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.transaction.virtualAccount))]),_c('h6',{staticClass:"mb-0"},[_vm._v("(계좌주: "+_vm._s(_vm.transaction.virtualAccountOwner)+")")])]):_vm._e(),(_vm.showBrokerInfo && !_vm.isLinking && !_vm.isActorChanged)?_c('div',{staticClass:"alert alert-primary text-center p-2 mb-0"},[_c('h6',{staticClass:"mb-1"},[_vm._v("코리아에셋투자증권")]),_c('h6',{staticClass:"mb-1"},[_vm._v("001-01-707546")]),_c('h6',{staticClass:"mb-0"},[_vm._v("(계좌주: 코리아에셋투자증권)")])]):_vm._e(),(_vm.enableLink)?_c('div',[_vm._m(0),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col mr-2"},[(_vm.transaction.sellAssignAvailableKey)?_c('button',{staticClass:"btn btn-outline-secondary btn-block btn-sm",on:{"click":function($event){return _vm.onLink(_vm.transaction)}}},[_vm._v("계좌 보유 주식으로")]):_vm._e()]),_c('div',{staticClass:"col ml-2"},[_c('router-link',{staticClass:"btn btn-outline-secondary btn-block btn-sm",attrs:{"to":{
              name: 'transactions_links',
              params: { transactionKey: _vm.transaction.transactionKey },
              query: { stockInfoUid: _vm.transaction.stockInfoUid, stockName: _vm.transaction.stockName }
            }}},[_vm._v("매수 중인 주식으로")])],1)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('h6',{staticClass:"text-secondary font-weight-bold"},[_vm._v("다른 결제 방법")]),_c('small',[_vm._v("더 알아보기")])])
}]

export { render, staticRenderFns }
<template>
<div class="d-flex justify-content-between">
  <p>{{ label }}</p>
  <div class="custom-control custom-switch">
    <input
      type="checkbox"
      class="custom-control-input"
      :id="id"
      :checked="value"
      @change="$emit('check-form', { value: $event.target.checked })"
    >
    <label class="custom-control-label" :for="id">{{ value ? '켬' : '끔' }}</label>
  </div>
</div>
</template>

<script>
export default {
  name: 'InputCheckbox',
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <div class="btn-group btn-group-sm">
    <button
      type="button"
      class="btn rounded-0"
      :disabled="disabled || value === left.value"
      :class="[value === left.value ? 'btn-secondary' : 'btn-outline-secondary']"
      @click="$emit('input-form', left.value)"
    >{{ left.label }}</button>
    <button
      type="button"
      class="btn rounded-0"
      :disabled="disabled || value === right.value"
      :class="[value === right.value ? 'btn-secondary' : 'btn-outline-secondary']"
      @click="$emit('input-form', right.value)"
    >{{ right.label }}</button>
  </div>
</template>

<script>
export default {
  name: 'SelectButtons',
  props: {
    value: {
      type: String,
      required: true,
    },
    left: {
      type: Object,
      required: true,
    },
    right: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.btn-secondary:disabled {
  opacity: 1;
}
</style>

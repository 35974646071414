<template>
  <div>
    <FormHead
      :title="form.stockName"
      :text="form.stockInfoUid"
    />
    <form @submit.prevent="handleSubmit">
      <SelectType
        id="type"
        leftLabel="매수관심등록"
        rightLabel="매도관심등록"
        :value="form['type']"
        :error="error['type']"
        @input-form="(value) => inputForm({ key: 'type', value })"
      />
      <InputQuantity
        id="quantity"
        :value="form['quantity']"
        :error="error['quantity']"
        @input-form="(value) => inputForm({ key: 'quantity', value })"
      />
      <InputPrice
        id="price"
        :value="form['price']"
        :error="error['price']"
        @input-form="(value) => inputForm({ key: 'price', value })"
      />
      <SelectNegoLimit
        id="negoLimit"
        :value="form['negoLimit']"
        @input-form="(value) => inputForm({ key: 'negoLimit', value })"
      />
      <InputTotalDisable
        id="totalPrice"
        :value="form['totalPrice']"
        :error="error['totalPrice']"
      />
      <!-- <InputCheckbox
        v-if="enablePosting(form['type'])"
        id="export38"
        :value="form['export38']"
        :error="error['export38']"
        @input-form="(value) => inputForm({ key: 'export38', value })"
        label="외부사이트(38.co.kr)에 게시"
      /> -->
      <SubmitButton
        label="종목 등록하기"
        :loadingStatus="loadingForm"
        :enabledForm="enableEdit(form['type'])"
      />
    </form>
    <div
      v-if="!enableEdit(form['type'])"
      class="alert alert-danger"
      role="alert"
    >{{ `게시가능한 종목(${form['type'] === 'BUY' ? '매수' : '매도' } 3개)를 초과하였습니다. 게시기한이 종료되지 않은 관심 목록 건을 삭제하신 후에 추가하실 수 있습니다.` }}</div>
    <div
      v-if="error.type || error.count || error.result === 'FORBIDDEN'"
      class="alert alert-danger"
      role="alert"
    >{{ error.type || error.count || '휴대전화인증이 필요합니다.' }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import SelectType from '@/components/intents/form/SelectType.vue';
import InputQuantity from '@/components/intents/form/InputQuantity.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import InputTotalDisable from '@/components/intents/form/InputTotalDisable.vue';
import SelectNegoLimit from '@/components/intents/form/SelectNegoLimit.vue';
// import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import {
  INIT_FORM__MYSTOCKS_MAKER,
  INPUT_FORM__MYSTOCKS_MAKER,
} from '@/store/mutation-types';

export default {
  name: 'MyStocksMakerView',
  components: {
    FormHead,
    SelectType,
    InputQuantity,
    InputPrice,
    InputTotalDisable,
    SelectNegoLimit,
    // InputCheckbox,
    SubmitButton,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('myStocksMaker', [
      'form',
      'error',
    ]),
    ...mapGetters('myStocksMaker', [
      'loadingForm',
    ]),
    ...mapGetters('myStocksExpires', [
      'enablePosting',
      'enableEdit',
    ]),
  },
  methods: {
    ...mapMutations('myStocksMaker', {
      'initForm': INIT_FORM__MYSTOCKS_MAKER,
      'inputForm': INPUT_FORM__MYSTOCKS_MAKER,
    }),
    ...mapActions('myStocksMaker', [
      'submitForm',
    ]),
    ...mapActions('myStocksExpires', [
      'getExpires',
    ]),
    handleSubmit() {
      if (this.user.userKey) {
        this.submitForm();
      } else {
        const { path, query } = this.$route;
        this.$router.push({
          name: 'sign_in',
          query: {
            ...query,
            redirect_path: path,
          },
        });
      }
    },
  },
  mounted() {
    const { stockInfoUid, stockName, type } = this.$route.query;
    if (stockInfoUid && stockName) {
      this.inputForm({ key: 'stockInfoUid', value: stockInfoUid });
      this.inputForm({ key: 'stockName', value: stockName });
      this.inputForm({ key: 'type', value: type || 'BUY' });
      this.getExpires();
    } else {
      this.$router.push({ name: 'mystocks_search' });
    }
  },
  destroyed() {
    this.initForm();
  },
};
</script>

<template>
  <div class="mt-4 mb-3">
    <div v-if="status !== 'DONE'">
      <h4 class="text-center">투자자정보 관련 확인사항</h4>
      <p class="mt-4">본인은 귀사에 제공한 투자자정보와 관련하여 다음과 같은 사항을 확인합니다.</p>
      <div class="border p-3 mb-4">
        <ol class="mb-0 pl-3">
          <li>귀사에 제공한 정보는 본인의 투자목적, 재산상황 및 투자경험 등의 정보를 정확히 알려드린 것입니다.</li>
          <li>향후 24개월 동안에는 귀사가 본인의 투자자정보가 변경되지 않는 것으로 귀사가 간주한다는 것을 설명받았습니다.</li>
          <li>본인의 투자자정보에 변경사항이 발생한 경우와 이메일 등의 연락처가 변경될 시에는 이를 귀사에 통지하여야 귀사가 본인에게 적합한 투자권유를 할 수 있음을 설명받았습니다.</li>
        </ol>
      </div>
      <div>
        <h6>고객님의 투자성향: <span class="font-weight-bold">{{ userScoreLabel(score) }}</span></h6>
        <div class="alert alert-warning">
          {{ userScoreDesc(score) }}
        </div>
      </div>
      <div v-if="showMobileForSign">
        <div class="alert alert-warning">{{ error.kakao }}</div>
        <InputNumber
          label="본인명의 휴대전화번호"
          id="mobileForSign"
          :value="form['mobileForSign']"
          @input-form="(value) => inputForm({ key: 'mobileForSign', value })"
        />
      </div>
      <button
        :disabled="showMobileForSign && !form['mobileForSign']"
        type="button"
        class="btn btn-primary btn-block"
        @click="submitForm"
      >카카오페이 인증하기</button>
    </div>
    <div v-else>
      <div class="alert alert-warning my-4">
        <h4 class="mb-0">인증하기 요청 성공</h4>
        <p class="mb-0">제출하신 투자자정보에 대해 카카오페이 알림톡으로 수신한 전자서명 요청을 완료해주세요.</p>
      </div>
      <router-link
        class="btn btn-primary btn-block"
        :to="{ name: 'user_infomation' }"
      >돌아가기</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import InputNumber from '@/components/form/InputNumber.vue';
import { INPUT__INVESTOR_SCORING } from '@/store/mutation-types';
import { userScoreLabel, userScoreDesc } from '@/utils/checkUser';

export default {
  name: 'InvestorResult',
  components: {
    InputNumber,
  },
  data() {
    return {
      userScoreLabel,
      userScoreDesc,
    };
  },
  computed: {
    ...mapState('investorStatus', [
      'last',
    ]),
    ...mapState('investorScoring', [
      'form',
      'status',
      'score',
      'error',
    ]),
    ...mapGetters('investorScoring', [
      // 'currentType',
      'showMobileForSign',
    ]),
  },
  methods: {
    ...mapMutations('investorScoring', {
      'inputForm': INPUT__INVESTOR_SCORING,
    }),
    ...mapActions('investorScoring', [
      'submitForm',
    ]),
  },
};
</script>

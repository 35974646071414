<template>
  <div class="container">
    <h6>Kakao Redirect...</h6>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { REMOVE_REDIRECT__NEGO_REDIRECT } from '@/store/mutation-types';

export default {
  name: 'KakaoView',
  computed: {
    ...mapState('negoRedirect', [
      'query',
    ]),
  },
  methods: {
    ...mapMutations('negoRedirect', {
      'removeRedirect': REMOVE_REDIRECT__NEGO_REDIRECT,
    }),
  },
  mounted() {
    if (this.query) {
      const { redirect_path, ...query } = this.query;
      this.removeRedirect();
      this.$router.replace({ path: redirect_path, ...query});
    } else {
      this.$router.replace({ name: 'home' });
    }
  },
};
</script>

<template>
  <div>
    <FormHead
      :title="form.stockName"
      :text="form.stockInfoUid"
    />
    <form @submit.prevent="checkForm">
      <SelectType
        id="type"
        rightLabel="매도관심등록"
        leftLabel="매수관심등록"
        :disabled="true"
        :value="form['type']"
        :error="error['type']"
        @input-form="(value) => inputForm({ key: 'type', value })"
      />
      <InputQuantity
        id="quantity"
        :value="form['quantity']"
        :error="error['quantity']"
        @input-form="(value) => inputForm({ key: 'quantity', value })"
      />
      <InputPrice
        id="price"
        :value="form['price']"
        :error="error['price']"
        @input-form="(value) => inputForm({ key: 'price', value })"
      />
      <SelectNegoLimit
        id="negoLimit"
        :value="form['negoLimit']"
        @input-form="(value) => inputForm({ key: 'negoLimit', value })"
      />
      <InputTotalDisable
        id="totalPrice"
        :value="form['totalPrice']"
        :error="error['totalPrice']"
      />
      <!-- <InputCheckbox
        v-if="enablePosting(form['type'])"
        id="export38"
        :value="form['export38']"
        :error="error['export38']"
        @input-form="(value) => inputForm({ key: 'export38', value })"
        label="외부사이트(38.co.kr)에 게시"
      /> -->
      <SubmitButton
        label="수정내용 저장"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <div
      v-if="error.type || error.count"
      class="alert alert-danger"
      role="alert"
    >{{ error.type || error.count }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import SelectType from '@/components/intents/form/SelectType.vue';
import InputQuantity from '@/components/intents/form/InputQuantity.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import InputTotalDisable from '@/components/intents/form/InputTotalDisable.vue';
import SelectNegoLimit from '@/components/intents/form/SelectNegoLimit.vue';
// import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import {
  INIT_FORM__MYSTOCKS_UPDATE,
  INPUT_FORM__MYSTOCKS_UPDATE,
} from '@/store/mutation-types';

export default {
  name: 'MyStocksUpdateView',
  components: {
    FormHead,
    SelectType,
    InputQuantity,
    InputPrice,
    InputTotalDisable,
    SelectNegoLimit,
    // InputCheckbox,
    SubmitButton,
  },
  computed: {
    ...mapState('myStocksUpdate', [
      'form',
      'error',
    ]),
    ...mapGetters('myStocksUpdate', [
      'loadingForm',
    ]),
    ...mapGetters('myStocksExpires', [
      'enablePosting',
    ]),
  },
  methods: {
    ...mapMutations('myStocksUpdate', {
      'initForm': INIT_FORM__MYSTOCKS_UPDATE,
      'inputForm': INPUT_FORM__MYSTOCKS_UPDATE,
    }),
    ...mapActions('myStocksUpdate', [
      'checkForm',
      'submitForm',
    ]),
    ...mapActions('myStocksExpires', [
      'getExpires',
    ]),
  },
  mounted() {
    const { myStockKey } = this.$route.params;
    const { stockInfoUid, stockName, type, quantity, price, negoLimit } = this.$route.query;
    if (myStockKey && stockInfoUid && stockName && type && quantity && price && negoLimit) {
      this.inputForm({ key: 'myStockKey', value: myStockKey });
      this.inputForm({ key: 'stockInfoUid', value: stockInfoUid });
      this.inputForm({ key: 'stockName', value: stockName });
      this.inputForm({ key: 'type', value: type });
      this.inputForm({ key: 'quantity', value: parseInt(quantity, 10) });
      this.inputForm({ key: 'price', value: parseInt(price, 10) });
      this.inputForm({ key: 'negoLimit', value: negoLimit.toString() });
      this.getExpires();
    } else {
      this.$router.push({ name: 'mystocks_search' });
    }
  },
  destroyed() {
    this.initForm();
  },
};
</script>

<template>
  <div class="guides-container bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8 bg-white">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuidesContainer',
};
</script>

import {
  REQUEST_USER__USER_INFOMATION,
  RECEIVE_USER__USER_INFOMATION,
  FAILURE_USER__USER_INFOMATION,
  SIGN_OUT__USER_INFOMATION,
  REROLE_USER__USER_INFOMATION,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';
import { DealBoardWeb } from '@/utils/constants';

const state = {
  user: {
    clientKey: '',
    userKey: '',
    username: '',
    nickname: '',
    publicId: '', // 공개ID
    mobile: {
      number: '', // 인증된휴대전화정보
      allowSms: false, // SMS 수신여부
    },
    socialUser: false, // 카카오사용자여부
    allowBrokerageContract: false, // 중개약관동의여부
    registContractInfo: false, // 사용자계약정보등록여부
    registBankAccount: false, // 사용자은행계좌등록여부
    registBrokerAccount: false, // 사용자증권계좌등록여부
    nameStatus: 'NONE', // 실명인증상태 (NONE:미등록, READY:확인대기, CONFIRM:확인, REQUEST:재요청)
    preUser: false, // 사용자등급상태
    proInvestor: false, // 전문투자자여부
    niceInfo: false,
    configuredRecvSellIntent: false, // 매도의향서수령지정여부
    configuredRecvBuyIntent: false, // 매수의향서수령지정여부
    myStockSellCount: 0, // 나의종목 매도 등록수
    myStockBuyCount: 0, // 나의종목 매수 등록수
    nameInfo: '', // 매매계약정보이름
    birthInfo: '', // 매매계약정보생년월일
    brokerAccountInfo: '', // 매매계약정보증권계좌
    bankAccountInfo: '', // 매매계약정보은행계좌
    corp: false,

    allowMarketing: false, // 광고성 투자정보 수신
    acceptRecommend: undefined,
    acceptProvide: undefined,
    score: 0,
    scoreSignFinTime: 0,
  },
  status: 'INITIAL',
};

const getters = {
  loadingData: state => state.status === 'REQUEST',
  isAuthenticated: state => !!state.user.userKey,
  isClubMember: state => !!state.user.staff || !!state.user.cluber,
  isSocialUser: state => state.user.socialUser,
  isProInvestor: state => state.user.proInvestor,
  isPreUser: state => state.user.preUser,
};

const mutations = {
  [REQUEST_USER__USER_INFOMATION] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_USER__USER_INFOMATION] (state, user) {
    state.status = 'RECEIVE';
    state.user = {
      ...user,
      acceptRecommend: user.acceptRecommend ?? undefined,
      acceptProvide: user.acceptProvide ?? undefined,
    };

    const { name, query, meta } = router.history.current;
    const { redirect_path, ...otherQuery } = query;

    if (user.helper || user.cluber) {
      return;
    }

    if (user.preUser && meta.noPreUser) {
      router.replace({
        name: meta.preName
      });
      return;
    }

    if (user.preUser) {
      router.replace({
        name: 'user_mobile'
      });
      return;
    }

    if (
      redirect_path
      && redirect_path !== '/auth/sign-in'
      && name !== 'agent'
    ) {
      router.replace({ path: redirect_path, query: otherQuery });
      return;
    }

    if (
      name === 'auth'
      || name === 'sign_in'
      || name === 'sign_up'
      || name === 'request_password'
      || name === 'reset_password'
    ) {
      router.replace({ name: 'home' });
      return;
    }
  },
  [FAILURE_USER__USER_INFOMATION] (state) {
    state.status = 'FAILURE';
    state.user = {};

    const { current } = router.history;
    if (current.matched.some(record => record.meta.requiresAuth)) {
      router.replace({
        name: current.meta.authName || 'sign_in',
        query: { 'redirect_path': current.path, ...current.query }
      });
    }
  },
  [SIGN_OUT__USER_INFOMATION] (state) {
    state.status = 'INITIAL';
    state.user = {};

    router.replace({ name: 'home' });
  },
  [REROLE_USER__USER_INFOMATION] () {
    router.replace({ name: '__INVEST_PLACEMENTS__' });
  },
};

const actions = {
  requestUser({ commit }) {
    commit(REQUEST_USER__USER_INFOMATION);

    api.get('/user', { cache: false }).then(
      response => commit(RECEIVE_USER__USER_INFOMATION, response.data),
      error => commit(FAILURE_USER__USER_INFOMATION, error),
    );
  },
  postConfigure({ commit }, { value }) {
    commit(REQUEST_USER__USER_INFOMATION);

    api.post('/users/configure', { 'recvSellIntent': value, 'recvBuyIntent': value }).then(
      response => commit(RECEIVE_USER__USER_INFOMATION, response.data),
      error => commit(FAILURE_USER__USER_INFOMATION, error),
    );
  },
  signOut({ commit, getters }) {
    const { isClubMember } = getters;
    api.post('/auth/signout').then(
      commit(SIGN_OUT__USER_INFOMATION),
    ).then(() => {
      if (isClubMember) {
        window.location = DealBoardWeb;
      } else {
        api.get('/welcome');
      }
    });
  },
  reloadUser({ commit }) {
    api.put('/user/rerole').then(
      () => setTimeout(() => {
        commit(REROLE_USER__USER_INFOMATION);
      }, 1000),
    );
  },
  connectClient({ dispatch }) {
    api.post('/users/tryclient').then(
      () => dispatch('requestUser')
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import _ from 'underscore';
import {
  INPUT_FORM__USER_MARKETING,
  POST_FORM__USER_MARKETING,
  DONE_FORM__USER_MARKETING,
  FAIL_FORM__USER_MARKETING,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';

const state = {
  form: {
    allowMarketing: '',
  },
  error: {},
  status: 'INIT',
};

const getters = {
  enabledForm: state => _.every(state.form),
  loadingForm: state => state.status === 'POST',
};

const mutations = {
  [INPUT_FORM__USER_MARKETING] (state, { key, value }) {
    state.form[key] = value;
  },
  [POST_FORM__USER_MARKETING] (state) {
    state.error = {};
    state.status = 'POST';
  },
  [DONE_FORM__USER_MARKETING] (state) {
    state.form.allowMarketing = '';
    state.status = 'INIT';

    router.push({ name: 'user_infomation' });
  },
  [FAIL_FORM__USER_MARKETING] (state, error) {
    state.error = { ...error };
    state.status = 'FAIL';
  },
};

const actions = {
  submitForm({ state, commit }) {
    commit(POST_FORM__USER_MARKETING);

    const { allowMarketing } = state.form;
    api.post('/users/allowmarketing', { allowMarketing }).then(
      response => setTimeout(() => commit(DONE_FORM__USER_MARKETING, response.data), 1000),
      error => commit(FAIL_FORM__USER_MARKETING, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="sample-jumbotron d-flex align-items-center">
    <div class="container text-center text-lg-left">
      <div class="d-flex flex-column flex-lg-row justify-content-between">
        <div class="">
          <h2 class="title mb-0">네고스탁 {{ intent.creatorNickname }} 회원님이</h2>
          <h2 class="title font-weight-bold mb-3">{{ intent.receiverNumber }} 님{{ intent.type === 'BUY' ? '에게' : '으로부터' }}</h2>
          <p class="text">{{ intent.stockName }}를 {{ intent.type === 'BUY' ? '매도' : '매수' }}할 의향이 있으십니다.<br class="d-block d-lg-none"> 회원가입 하시면 협의 후 거래를 진행하실 수 있습니다.</p>
          <div class="my-4 d-none d-lg-block">
            <div class="mb-3">
              <router-link
                class="btn btn-outline-secondary btn-lg px-5 font-weight-bold"
                :to="{name: 'auth'}"
              >회원가입</router-link>
            </div>
            <p class="text">네고스탁 어떻게 이용하나요?
              <router-link class="font-weight-bold text-reset ml-1" :to="{name: 'guides_videos'}"><br class="d-block d-lg-none"><u>1분 영상가이드 보러가기</u></router-link>
            </p>
          </div>
        </div>
        <div class="">
          <IntentsSample :intent="intent"/>
        </div>
        <div class="d-block d-lg-none">
          <p class="text mb-0">스크롤해서
            <router-link class="font-weight-bold text-reset ml-1" :to="{name: 'guides_videos'}"><u>1분 영상가이드 보기</u></router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IntentsSample from '@/components/intents/IntentsSample.vue'

export default {
  name: 'SampleJumbotron',
  components: {
    IntentsSample,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
    loadingStatus: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sample-jumbotron {
  min-height: 37rem;
  height: 80vh;
  background-image: url('./img/jumbo_mobile.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 992px) {
    min-height: 30rem;
    height: 30vh;
    background-image: url('./img/jumbo.jpg');
  }
}
.title {
  @include nego-font2(300, 1.2, -2.1px, $dark);
}
.text {
  @include nego-font2(300, 1.67, -1.3px);
}
</style>

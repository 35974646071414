import {
  REQUEST_EXPIRES__MYSTOCKS_EXPIRES,
  RECEIVE_EXPIRES__MYSTOCKS_EXPIRES,
  FAILURE_EXPIRES__MYSTOCKS_EXPIRES,
} from '@/store/mutation-types';
import api from '@/api/axios';

const INIT_EXPIRES = {
  buyPostingExpiration: 0,
  sellPostingExpiration: 0,
  buyCount: 0,
  sellCount: 0,
};

const state = {
  expires: { ...INIT_EXPIRES },
  error: {},
  status: 'INITIAL',
};

const getters = {
  enableBuyPosting: state => {
    const now = Date.now();
    const { buyPostingExpiration } = state.expires;
    if (buyPostingExpiration === 0 || buyPostingExpiration > now) {
      return true;
    }
    return false;
  },
  enableSellPosting: (state) => {
    const now = Date.now();
    const { sellPostingExpiration } = state.expires;
    if (sellPostingExpiration === 0 || sellPostingExpiration > now) {
      return true;
    }
    return false;
  },
  enablePosting: (state, getters) => (type) => {
    if (type === 'BUY' && getters.enableBuyPosting) {
      return true;
    }
    if (type === 'SELL' && getters.enableSellPosting) {
      return true;
    }
    return false;
  },
  enableBuyEdit: (state) => {
    const { buyCount } = state.expires;
    if (buyCount < 3) {
      return true;
    }
    return false;
  },
  enableSellEdit: (state) => {
    const { sellCount } = state.expires;
    if (sellCount < 3) {
      return true;
    }
    return false;
  },
  enableEdit: (state, getters) => (type) => {
    if (type === 'BUY' && getters.enableBuyEdit) {
      return true;
    }
    if (type === 'SELL' && getters.enableSellEdit) {
      return true;
    }
    return false;
  },
};

const mutations = {
  [REQUEST_EXPIRES__MYSTOCKS_EXPIRES] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE_EXPIRES__MYSTOCKS_EXPIRES] (state, data) {
    state.expires = { ...data };
    state.status = 'RECEIVE';
  },
  [FAILURE_EXPIRES__MYSTOCKS_EXPIRES] (state) {
    state.status = 'FAILURE';
  },
};

const actions = {
  getExpires({ commit }) {
    commit(REQUEST_EXPIRES__MYSTOCKS_EXPIRES);

    api.get('/mystocks/expires').then(
      response => commit(RECEIVE_EXPIRES__MYSTOCKS_EXPIRES, response.data),
      error => commit(FAILURE_EXPIRES__MYSTOCKS_EXPIRES, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <nav class="navbar navbar-expand-md fixed-top navbar-light bg-white shadow-sm">
    <div class="container-lg">
      <div
        class="navbar-brand text-primary font-weight-bold p-0"
        aria-label="Negostock Home"
      >
        <img src="./img/logo.svg" alt="" class="logo">
        <img src="./img/brand.svg" alt="" class="brand">
        <img src="./img/tudalLogo.svg" alt="" class="brand">
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TudalNavbar',
};
</script>

<style lang="scss" scoped>
.logo {
  width: 2.5rem;
  height: 2.5rem;
}
.brand {
  width: 10rem;
  height: 2rem;
}
</style>

<template>
  <div class="">
    <div class="text-center border-bottom my-4 py-2 h5">
      서류 제출 안내
    </div>
    <div class="mt-5">
      <p>■ 매수약정 내용</p>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>매수고객</td>
            <td>
              <p class="mb-0">성명: {{ data.nameInfo }}</p>
              <p class="mb-0">{{ checkBirthInfo ? '생년월일' : '사업자등록번호' }}: <samp>{{ data.birthInfo }}</samp></p>
              <p class="mb-0">휴대전화: <samp>{{ data.mobileForSign | phone }}</samp></p>
            </td>
          </tr>
          <tr>
            <td>종목<template v-if="checkStockInfoUid">(종목코드)</template></td>
            <td>{{ data.stockName }} <template v-if="checkStockInfoUid">(<samp>{{ data.stockInfoUid }}</samp>)</template></td>
          </tr>
          <tr>
            <td>수량 및 단가</td>
            <td>{{ data.offerQuantity | currency }} 주 | {{ data.offerPrice | currency }} 원</td>
          </tr>
          <tr>
            <td>매매금액</td>
            <td>{{ data.offerQuantity * data.offerPrice | currency }} 원</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-5">
      <p>■ 제출 서류 안내 및 날인 전 계약서</p>
      <p>"제출서류 안내"를 다운로드 받아, 필요한 제출서류를 확인하여 아래 제출처로 원본을 송부해 주시기를 부탁드립니다.</p>
      <template v-if="checkBirthInfo && !checkStockInfoUid">
        <a
          class="btn btn-primary w-100 mb-3"
          download="1 (개인)장외거래 제출서류 안내_명의개서.pdf"
          href="/files/helperstock_a.pdf"
        >제출서류 안내 다운로드(명의개서)</a>
      </template>
      <template v-if="!checkBirthInfo && !checkStockInfoUid">
        <a
          class="btn btn-primary w-100 mb-3"
          download="2 (법인)장외거래 제출서류 안내_명의개서.pdf"
          href="/files/helperstock_b.pdf"
        >제출서류 안내 다운로드(명의개서)</a>
      </template>
      <template v-if="checkBirthInfo && checkStockInfoUid">
        <a
          class="btn btn-primary w-100 mb-3"
          download="3 (개인)장외거래 제출서류 안내_통일주권.pdf"
          href="/files/helperstock_c.pdf"
        >제출서류 안내 다운로드(통일주권)</a>
      </template>
      <template v-if="!checkBirthInfo && checkStockInfoUid">
        <a
          class="btn btn-primary w-100 mb-3"
          download="4 (법인)장외거래 제출서류 안내_통일주권.pdf"
          href="/files/helperstock_d.pdf"
        >제출서류 안내 다운로드(통일주권)</a>
      </template>
      <a
        class="btn btn-primary w-100 mb-3"
        :href="`${FintechDocuWeb}/view/${data.contractDocuCode}`"
      >날인 전 매매계약서 다운로드</a>

      <p class="mb-0">제출처</p>
      <div class="alert alert-light">
        <ul class="list-unstyled pl-0 mb-0">
          <li>서울시 영등포구 여의나루로59</li>
          <li>코리아에셋투자증권 법인금융센터</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { FintechDocuApi, FintechDocuWeb } from '@/utils/constants';

export default {
  name: 'PaymentDocsView',
  data() {
    return {
      FintechDocuApi,
      FintechDocuWeb,
    };
  },
  computed: {
    ...mapState('paymentDocs', [
      'data',
      'dataError',
    ]),
    ...mapGetters('paymentDocs', [
      'loadingData',
    ]),
    // 개인 | 법인
    checkBirthInfo() {
      return this.data['birthInfo'].length === 14;
    },
    // 통일주권 | 명의개서
    checkStockInfoUid() {
      return this.data['stockInfoUid'].length === 6;
    },
  },
  methods: {
    ...mapActions('paymentDocs', [
      'requestData',
    ]),
  },
  mounted() {
    const { helperStockTxKey } = this.$route.params;
    this.requestData({ helperStockTxKey });
  },
};
</script>

import {
  INIT_FORM__FIND_PASSWORD,
  INPUT_FORM__FIND_PASSWORD,
  POST_EMAIL__FIND_PASSWORD,
  DONE_EMAIL__FIND_PASSWORD,
  FAIL_EMAIL__FIND_PASSWORD,
  POST_NUMBER__FIND_PASSWORD,
  DONE_NUMBER__FIND_PASSWORD,
  FAIL_NUMBER__FIND_PASSWORD,
  POST_CODE__FIND_PASSWORD,
  DONE_CODE__FIND_PASSWORD,
  FAIL_CODE__FIND_PASSWORD,
} from '@/store/mutation-types';
import api from '@/api/axios';
import router from '@/router';
import { FormStatus } from '@/utils/formStatus';

const INIT_FORM = {
  value: '',
  status: FormStatus.INIT,
  feedback: '',
};

const state = {
  email: { ...INIT_FORM },
  number: { ...INIT_FORM },
  code: { ...INIT_FORM },
  hasMobile: false,
  sendEmail: false,
};

const getters = {
  enabledEmail: state => !!state.email.value,
  enabledNumber: state => {
    return state.email.status === FormStatus.DONE && state.number.value.length >= 11;
  },
  enabledCode: state => {
    return state.number.status === FormStatus.DONE && state.code.value.length === 6;
  },
};

const mutations = {
  [INPUT_FORM__FIND_PASSWORD] (state, { key, value }) {
    state[key].value = value;
  },
  [INIT_FORM__FIND_PASSWORD] (state) {
    state.email = { ...INIT_FORM };
    state.number = { ...INIT_FORM };
    state.code = { ...INIT_FORM };
    state.hasMobile = false;
    state.sendEmail = false;
  },
  [POST_EMAIL__FIND_PASSWORD] (state) {
    state.email.status = FormStatus.POST;
  },
  [DONE_EMAIL__FIND_PASSWORD] (state, data) {
    if (!data.valid) {
      state.email.status = FormStatus.FAIL;
      state.email.feedback = '회원정보가 없습니다.';
      return;
    }

    if (data.socialUser) {
      router.push({
        name: 'find_result',
        params: { status: 'KAKAO' },
      });
      return;
    }

    if (!data.hasMobile) {
      state.email.status = FormStatus.FAIL;
      state.email.feedback = '이메일로 비밀번호를 변경하실 수 있는 링크를 보내드렸습니다.';
      state.sendEmail = true;
      return;
    }

    state.hasMobile = data.hasMobile;
    state.email.status = FormStatus.DONE;
    state.email.feedback = '이메일이 확인되었습니다.';
  },
  [FAIL_EMAIL__FIND_PASSWORD] (state) {
    state.email.status = FormStatus.FAIL;
    state.email.feedback = '처리가 완료되지 못했습니다. 잠시 후 다시 시도해주세요.';
  },
  [POST_NUMBER__FIND_PASSWORD] (state) {
    state.number.status = FormStatus.POST;
  },
  [DONE_NUMBER__FIND_PASSWORD] (state, data) {
    if (data.valid && !data.socialUser) {
      state.number.status = FormStatus.DONE;
      state.number.feedback = '인증번호를 발송하였습니다.';
    } else {
      state.number.status = FormStatus.FAIL;
      state.number.feedback = '휴전전화 번호를 확인하세요.';
    }
  },
  [FAIL_NUMBER__FIND_PASSWORD] (state) {
    state.number.status = FormStatus.FAIL;
    state.number.feedback = '처리가 완료되지 못했습니다. 잠시 후 다시 시도해주세요.';
  },

  [POST_CODE__FIND_PASSWORD] (state) {
    state.code.status = FormStatus.POST;
  },
  [DONE_CODE__FIND_PASSWORD] (state, data) {
    if (!data.valid) {
      state.code.status = FormStatus.DONE;
      state.code.feedback = '인증번호를 확인하세요.';
    } else {
      const code = state.code.value;
      router.push({
        name: 'reset_password',
        params: { code },
      });
    }
  },
  [FAIL_CODE__FIND_PASSWORD] (state) {
    state.code.status = FormStatus.FAIL;
    state.code.feedback = '처리가 완료되지 못했습니다. 잠시 후 다시 시도해주세요.';
  },
};

const actions = {
  checkEmail({ state, commit }) {
    commit(POST_EMAIL__FIND_PASSWORD);

    const email = state.email.value;
    api.post('/find/byemail', { email }).then(
      response => commit(DONE_EMAIL__FIND_PASSWORD, response.data),
      () => commit(FAIL_EMAIL__FIND_PASSWORD),
    );
  },
  checkNumber({ state, commit }) {
    commit(POST_NUMBER__FIND_PASSWORD);

    const email = state.email.value;
    const number = state.number.value;
    api.post('/find/code', { email, number }).then(
      response => commit(DONE_NUMBER__FIND_PASSWORD, response.data),
      () => commit(FAIL_NUMBER__FIND_PASSWORD),
    );
  },
  checkCode({ state, commit }) {
    commit(POST_CODE__FIND_PASSWORD);

    const number = state.number.value;
    const code = state.code.value;
    api.post('/find/verify', { number, code }).then(
      response => commit(DONE_CODE__FIND_PASSWORD, response.data),
      () => commit(FAIL_CODE__FIND_PASSWORD),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="lp-funding">
    <NegoLinks group="group1"/>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>개인전문투자자는 당사가 단독/공동으로 설정하는 <br class="d-md-none">신기술투자조합에 온라인으로 가입하실 수 있습니다.</p>
          <p class="text-right">
            <a href="#help-intent-notice"><small>개인 전문투자자 안내 보기 &gt;</small></a>
          </p>
        </div>
        <div class="col-lg-6 col-xl-4" v-for="(deal, index) in list" :key="index">
          <LPFundingCard
            :deal="deal"
            @regist-item="registItem"
            @reserve-item="reserveItem"
            @subscript-item="subscriptItem"
          />
        </div>
      </div>
      <LPNotice />
      <HelpIntentNotice id="help-intent-notice"/>
    </div>
    <template v-if="item && !item.registedOpenNotice">
      <AppModalSimple
        title="사전예약 시작 알림"
        :content="`<mark>'${item.dealName}'</mark> 신기술투자조합의 사전예약이 시작될 경우에 알림을 보내드릴까요?`"
      >
        <div class="row no-gutters w-100">
          <div class="col pr-1">
            <button class="btn btn-outline-dark btn-block" @click="registNotice">예</button>
          </div>
          <div class="col pl-1">
            <button class="btn btn-outline-dark btn-block" @click="clearItem">아니오</button>
          </div>
        </div>
      </AppModalSimple>
    </template>
    <AppModal v-if="showModal" title="알림">
      <template>
        <p>개인 전문투자자만 신청 가능합니다. <br>회원정보 페이지에서 개인전문투자자 등록을 진행해주세요.</p>
        <router-link
          class="btn btn-dark btn-sm"
          :to="{
            name: 'user_infomation',
            hash: '#infomation-regist',
          }"
        >회원정보페이지로 이동</router-link>
      </template>
      <template v-slot:footer>
        <button class="btn btn-outline-dark btn-sm" @click="closeModal">닫기</button>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import LPNotice from '@/components/limitedPartner/LPNotice.vue';
import HelpIntentNotice from '@/components/helpIntent/HelpIntentNotice.vue';
import LPFundingCard from '@/components/limitedPartner/LPFundingCard.vue';
import AppModalSimple from '@/components/app/AppModalSimple.vue';
import AppModal from '@/components/app/AppModal.vue';
import {
  SELECT__LP_FUNDING,
  CLEAR__LP_FUNDING,
} from '@/store/mutation-types';

export default {
  name: 'LPFunding',
  components: {
    NegoLinks,
    LPNotice,
    HelpIntentNotice,
    LPFundingCard,
    AppModalSimple,
    AppModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters('userInfomation', [
      'isAuthenticated',
      'isProInvestor',
    ]),
    ...mapState('lpFunding', [
      'list',
      'item',
    ]),
    ...mapGetters('lpFunding', [
      'loadingList',
    ]),
  },
  methods: {
    ...mapMutations('lpFunding', {
      selectItem: SELECT__LP_FUNDING,
      clearItem: CLEAR__LP_FUNDING,
    }),
    ...mapActions('lpFunding', [
      'requestListPublic',
      'requestList',
      'registNotice',
    ]),
    registItem(deal) {
      if (!this.isAuthenticated) {
        this.routeSignIn();
        return;
      }
      this.selectItem(deal);
    },
    reserveItem(deal) {
      if (!this.isAuthenticated) {
        this.routeSignIn();
        return;
      }
      if (!this.isProInvestor) {
        this.openModal();
        return;
      }
      const { dealKey, dealName, retailMinAmount } = deal;
      this.$router.push({
        name: '__LP_FUNDING_RESERVE__',
        query: { dealKey, dealName, retailMinAmount },
      });
    },
    subscriptItem(deal) {
      if (!this.isAuthenticated) {
        this.routeSignIn();
        return;
      }
      if (!this.isProInvestor) {
        this.openModal();
        return;
      }
      const { dealKey, dealName, retailMinAmount } = deal;
      this.$router.push({
        name: '__LP_FUNDING_SUBSCRIPT__',
        query: { dealKey, dealName, retailMinAmount },
      });
    },
    routeSignIn() {
      this.$router.push({
        name: 'sign_in',
        query: { redirect_path: '/lp/funding' },
      });
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.requestList();
    } else {
      this.requestListPublic();
    }
  },
};
</script>

<style lang="scss" scoped>
.lp-funding {
  min-height: 95vh;
}
</style>

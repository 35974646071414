<template>
  <div>
    <HomeCards id="home-cards"/>
    <HomeVideo id="home-video"/>
    <HomeProcess />
    <HomeRules />
    <h4 class="text-center text-primary mb-0"><strong>매매 협의과정 안내</strong></h4>
    <IntentsNotice />
    <h4 class="text-center text-primary mb-0"><strong>매매 진행과정 안내</strong></h4>
    <ProgressNotice />
    <HomeNotices />
  </div>
</template>

<script>
import HomeCards from '@/components/homeService/HomeCards.vue';
import HomeVideo from '@/components/homeService/HomeVideo.vue';
import HomeProcess from '@/components/homeService/HomeProcess.vue';
import HomeRules from '@/components/homeService/HomeRules.vue';
import HomeNotices from '@/components/homeService/HomeNotices.vue';
import IntentsNotice from '@/components/intents/IntentsNotice.vue';
import ProgressNotice from '@/components/transactions/ProgressNotice.vue';

export default {
  name: 'HomeService',
  components: {
    HomeCards,
    HomeVideo,
    HomeProcess,
    HomeRules,
    HomeNotices,
    IntentsNotice,
    ProgressNotice,
  },
};
</script>

<template>
  <div class="mt-4">
    <h6>조합투자현황</h6>
    <p><LoadingSpinner class="icon-sm mr-3" /> 잠시만 기다려주세요.</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingSpinner from '@/components/form/LoadingSpinner.vue';

export default {
  name: 'InvestNice',
  components: {
    LoadingSpinner,
  },
  methods: {
    ...mapActions('userInfomation', [
      'reloadUser',
    ]),
  },
  mounted() {
    setTimeout(() => {
      this.reloadUser();
      // this.$router.push({ name: '__INVEST_PLACEMENTS__' });
    }, 3000);
  },
};
</script>

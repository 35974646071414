<template>
  <div class="btn-group dropdown dropup-center">
    <button
      class="btn btn-link dropdown-toggle pl-0"
      type="button"
      id="dropdown-keyword"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleMenu"
      @blur="closeMenu"
    >#키워드</button>
    <div
      class="dropdown-menu dropdown-menu-left dropdown-menu-lg-right"
      :class="{ 'show': dropped }"
      aria-labelledby="dropdown-keyword"
    >
      <div class="d-flex justify-content-between align-items-center px-2">
        <p class="mb-0">#키워드를 선택해주세요.</p>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="handleDeselect"
        >해제</button>
      </div>
      <div class="dropdown-divider"></div>
      <div class="px-2">
        <KeywordButtons
          :list="list"
          @toggle-item="handleToggle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import KeywordButtons from '@/views/otc/KeywordButtons.vue';
import { DESELECT__KEYWORDS } from '@/store/mutation-types';

export default {
  name: 'DropdownsKeyword',
  components: {
    KeywordButtons,
  },
  data() {
    return {
      dropped: false,
    };
  },
  computed: {
    ...mapState('keywords', [
      'list',
    ]),
  },
  methods: {
    ...mapMutations('keywords', {
      deselectItem: DESELECT__KEYWORDS,
    }),
    ...mapActions('keywords', [
      'fetchKeywords',
    ]),
    toggleMenu() {
      this.dropped = !this.dropped;
    },
    closeMenu() {
      setTimeout(() => {
        this.dropped = false;
      }, 200);
    },
    handleToggle() {
      this.toggleMenu();
      this.$emit('toggle-item');
    },
    handleDeselect() {
      this.deselectItem();
      this.toggleMenu();
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  min-width: 18rem;
}
.btn-link {
  font-size: .875rem;
}
.btn-sm {
  font-size: .75rem;
  line-height: 1.25;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border mb-4"},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-3"},[_c('ImageOrIcons',{attrs:{"src":_vm.deal.companyLogo,"icon":"building"}})],1),_c('div',{staticClass:"col-9 pl-3"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.deal.companyName))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.deal.primary ? '신규투자유치' : '매각')+"("+_vm._s(_vm._f("stock_type")(_vm.deal.stockTypeCode))+")")]),_c('p',{staticClass:"mb-2"},[_vm._v("규모 "+_vm._s(_vm._f("won3")(_vm.deal.expectedAmount))+" 백만원")]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col pr-2"},[_c('router-link',{staticClass:"btn btn-outline-primary btn-sm w-100",attrs:{"to":{
                name: '__COMPANY_VIEW__',
                params: { companyCode: _vm.deal.companyCode },
              }}},[_vm._v("기업정보")])],1),_c('div',{staticClass:"col pl-2"},[_c('router-link',{staticClass:"btn btn-outline-primary btn-sm w-100",attrs:{"to":{
                name: '__PLACEMENTS_LIBRARY__',
                query: { dealKey: _vm.deal.dealKey, dealName: _vm.deal.name }
              }}},[_vm._v("IR자료실")])],1)])])]),_c('hr'),_c('CardListVideosItem',{attrs:{"video":{
        videoId: _vm.deal.videoId,
        thumbnail: _vm.deal.videoThumbnail,
      },"currentVideo":_vm.currentVideo},on:{"select-item":(videoId) => _vm.$emit('select-item', videoId)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import _ from 'underscore';
import api from '@/api/axios';
import {
  REQUEST__DEALS_CONTENTS,
  RECEIVE__DEALS_CONTENTS,
  FAILURE__DEALS_CONTENTS,
  SELECT__DEALS_CONTENTS,
  CLEAR__DEALS_CONTENTS,
} from '@/store/mutation-types';

const state = {
  dealKey: '',
  list: [],
  error: {},
  status: 'INITIAL',
  currentDealContentsKey: '',
  selectedItem: {},
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
  selectedItem: state => _.find(state.list, content => content.dealContentsKey === state.currentDealContentsKey),
};

const mutations = {
  [REQUEST__DEALS_CONTENTS] (state, { dealKey }) {
    state.dealKey = dealKey;
    state.status = 'REQUEST';
  },
  [RECEIVE__DEALS_CONTENTS] (state, data) {
    state.list = data;
    state.status = 'RECEIVE';
  },
  [FAILURE__DEALS_CONTENTS] (state, error) {
    state.error = error;
    state.status = 'FAILURE';
  },
  [SELECT__DEALS_CONTENTS] (state, dealContentsKey) {
    state.currentDealContentsKey = dealContentsKey;
  },
  [CLEAR__DEALS_CONTENTS] (state) {
    state.currentDealContentsKey = '';
  },
};

const actions = {
  requestList({ commit }, { dealKey }) {
    commit(REQUEST__DEALS_CONTENTS, { dealKey });

    api.get(`/deals/contents/${dealKey}`).then(
      response => commit(RECEIVE__DEALS_CONTENTS, response.data),
      error => commit(FAILURE__DEALS_CONTENTS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

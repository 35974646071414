<template>
  <div>
    <FormHead
      :title="form.dealName"
      text="투자사전예약"
    />
    <form @submit.prevent="checkForm">
      <InputPrice
        label="사전 예약 금액"
        id="reserveAmount"
        :value="form['reserveAmount']"
        :error="error['reserveAmount']"
        @input-form="(value) => inputForm({ key: 'reserveAmount', value })"
      />
      <h6 class="mt-5">유의사항</h6>
      <ol class="pl-4">
        <li>조합원 수 제한(49인)으로 인해 <mark class="bg-warning">최소참여금액이 {{ form['retailMinAmount'] | currency }} 원</mark> 입니다.</li>
        <li>일반청약일에 앞서 사전예약고객님에게 사전예약금액 만큼 먼저 조합가입 신청하실 수 있도록 알려드립니다.</li>
        <li>사전 예약금액을 수정하기 원하실 경우, 사전예약 신청을 다시 해주시면 최종 신청한 금액으로 수정됩니다.</li>
      </ol>
      <InputCheckbox
        id="agreeForm"
        :value="form['agreeForm']"
        :error="error['agreeForm']"
        @input-form="(value) => inputForm({ key: 'agreeForm', value })"
        label="유의사항 확인함"
      />
      <SubmitButton
        label="투자 사전예약 신청"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <div class="border p-2 mt-5">
      <h6 class="font-weight-bold text-center py-3">투자 사전예약 신청 후 절차 안내</h6>
      <ol class="pl-4">
        <li>사전예약자 청약일 오전 9시에 참여금액을 입금하실 가상계좌를 안내해 드립니다.</li>
        <li>안내 받으신 가상계좌로 참여금액 입금해 주시면 "조합규약 등에의 전자서명" 진행에 대해 안내해 드립니다.</li>
        <li>"조합규약 등에의 전자서명"을 완료하시면 조합가입이 완료됩니다.</li>
        <li>"조합규약 등에의 전자서명"을 완료하신 경우라도 모집액 초과 등의 이유로 참여가 취소될 수 있습니다.</li>
        <li>조합가입이 미완료 또는 취소될 경우 입금하신 참여금액은 고객님이 등록하신 계좌로 반환됩니다.</li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import {
  INIT_FORM__LP_FUNDING_RESERVE,
  INPUT_FORM__LP_FUNDING_RESERVE,
} from '@/store/mutation-types';

export default {
  name: 'LPFundingReserve',
  components: {
    FormHead,
    InputPrice,
    SubmitButton,
    InputCheckbox,
  },
  computed: {
    ...mapState('lpFundingReserve', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('lpFundingReserve', [
      'loadingForm',
    ]),
  },
  methods: {
    ...mapMutations('lpFundingReserve', {
      'initForm': INIT_FORM__LP_FUNDING_RESERVE,
      'inputForm': INPUT_FORM__LP_FUNDING_RESERVE,
    }),
    ...mapActions('lpFundingReserve', [
      'checkForm',
      'submitForm',
    ]),
  },
  mounted() {
    const { dealName, dealKey, retailMinAmount } = this.$route.query;
    this.inputForm({ key: 'dealName', value: dealName });
    this.inputForm({ key: 'dealKey', value: dealKey });
    this.inputForm({ key: 'retailMinAmount', value: retailMinAmount });
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

function validEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validPassword(password) {
  const re = /^(?=.*[0-9])(?=.*[~'!@#$%^&*()-])(?=.*[a-zA-Z])(?=.{8,20})/;
  return re.test(password);
}

function replaceCompanyName(name) {
  return name.replace(/\(주\)|㈜|（주）|주식회사|\s/g, '');
}

export {
  validEmail,
  validPassword,
  replaceCompanyName,
};

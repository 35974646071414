// homeFirst
export const REQUEST_LIST__HOME_FIRST = 'REQUEST_LIST__HOME_FIRST';
export const RECEIVE_LIST__HOME_FIRST = 'RECEIVE_LIST__HOME_FIRST';
export const FAILURE_LIST__HOME_FIRST = 'FAILURE_LIST__HOME_FIRST';
export const SELECT_LIST__HOME_FIRST = 'SELECT_LIST__HOME_FIRST';
export const SHUFFLE_HOT__HOME_FIRST = 'SHUFFLE_HOT__HOME_FIRST';

// authSignUp
export const CHECK_FORM__SIGN_UP = 'CHECK_FORM__SIGN_UP';
export const INPUT_FORM__SIGN_UP = 'INPUT_FORM__SIGN_UP';
export const POST_FORM__SIGN_UP = 'POST_FORM__SIGN_UP';
export const DONE_FORM__SIGN_UP = 'DONE_FORM__SIGN_UP';
export const FAIL_FORM__SIGN_UP = 'FAIL_FORM__SIGN_UP';
export const CLOSE_MODAL__SIGN_UP = 'CLOSE_MODAL__SIGN_UP';

// authSignIn
export const INPUT_FORM__SIGN_IN = 'INPUT_FORM__SIGN_IN';
export const POST_FORM__SIGN_IN = 'POST_FORM__SIGN_IN';
export const DONE_FORM__SIGN_IN = 'DONE_FORM__SIGN_IN';
export const FAIL_FORM__SIGN_IN = 'FAIL_FORM__SIGN_IN';
export const GET_AUTO__SIGN_IN = 'GET_AUTO__SIGN_IN';
export const SET_AUTO__SIGN_IN = 'SET_AUTO__SIGN_IN';

// authFindEmail
export const INPUT_FORM__FIND_EMAIL = 'INPUT_FORM__FIND_EMAIL';
export const INIT_FORM__FIND_EMAIL = 'INIT_FORM__FIND_EMAIL';
export const POST_FORM__FIND_EMAIL = 'POST_FORM__FIND_EMAIL';
export const DONE_FORM__FIND_EMAIL = 'DONE_FORM__FIND_EMAIL';
export const FAIL_FORM__FIND_EMAIL = 'FAIL_FORM__FIND_EMAIL';

// authFindPassword
export const INIT_FORM__FIND_PASSWORD = 'INIT_FORM__FIND_PASSWORD';
export const INPUT_FORM__FIND_PASSWORD = 'INPUT_FORM__FIND_PASSWORD';
export const POST_EMAIL__FIND_PASSWORD = 'POST_EMAIL__FIND_PASSWORD';
export const DONE_EMAIL__FIND_PASSWORD = 'DONE_EMAIL__FIND_PASSWORD';
export const FAIL_EMAIL__FIND_PASSWORD = 'FAIL_EMAIL__FIND_PASSWORD';
export const POST_NUMBER__FIND_PASSWORD = 'POST_NUMBER__FIND_PASSWORD';
export const DONE_NUMBER__FIND_PASSWORD = 'DONE_NUMBER__FIND_PASSWORD';
export const FAIL_NUMBER__FIND_PASSWORD = 'FAIL_NUMBER__FIND_PASSWORD';
export const POST_CODE__FIND_PASSWORD = 'POST_CODE__FIND_PASSWORD';
export const DONE_CODE__FIND_PASSWORD = 'DONE_CODE__FIND_PASSWORD';
export const FAIL_CODE__FIND_PASSWORD = 'FAIL_CODE__FIND_PASSWORD';

// authResetPassword
export const INPUT_FORM__RESET_PASSWORD = 'INPUT_FORM__RESET_PASSWORD';
export const POST_FORM__RESET_PASSWORD = 'POST_FORM__RESET_PASSWORD';
export const DONE_FORM__RESET_PASSWORD = 'DONE_FORM__RESET_PASSWORD';
export const FAIL_FORM__RESET_PASSWORD = 'FAIL_FORM__RESET_PASSWORD';

// authResetEmail
export const INPUT_FORM__RESET_EMAIL = 'INPUT_FORM__RESET_EMAIL';
export const POST_FORM__RESET_EMAIL = 'POST_FORM__RESET_EMAIL';
export const DONE_FORM__RESET_EMAIL = 'DONE_FORM__RESET_EMAIL';
export const FAIL_FORM__RESET_EMAIL = 'FAIL_FORM__RESET_EMAIL';

// authRevoke
export const INPUT_FORM__AUTH_REVOKE = 'INPUT_FORM__AUTH_REVOKE';
export const POST_FORM__AUTH_REVOKE = 'POST_FORM__AUTH_REVOKE';
export const DONE_FORM__AUTH_REVOKE = 'DONE_FORM__AUTH_REVOKE';
export const FAIL_FORM__AUTH_REVOKE = 'FAIL_FORM__AUTH_REVOKE';

// userInfomation
export const REQUEST_USER__USER_INFOMATION = 'REQUEST_USER__USER_INFOMATION';
export const RECEIVE_USER__USER_INFOMATION = 'RECEIVE_USER__USER_INFOMATION';
export const FAILURE_USER__USER_INFOMATION = 'FAILURE_USER__USER_INFOMATION';
export const SIGN_OUT__USER_INFOMATION = 'SIGN_OUT__USER_INFOMATION';
export const REROLE_USER__USER_INFOMATION = 'REROLE_USER__USER_INFOMATION';

// userNickName
export const INPUT_FORM__USER_NICKNAME = 'INPUT_FORM__USER_NICKNAME';
export const POST_FORM__USER_NICKNAME = 'POST_FORM__USER_NICKNAME';
export const DONE_FORM__USER_NICKNAME = 'DONE_FORM__USER_NICKNAME';
export const FAIL_FORM__USER_NICKNAME = 'FAIL_FORM__USER_NICKNAME';

// userPassword
export const INPUT_FORM__USER_PASSWORD = 'INPUT_FORM__USER_PASSWORD';
export const POST_FORM__USER_PASSWORD = 'POST_FORM__USER_PASSWORD';
export const DONE_FORM__USER_PASSWORD = 'DONE_FORM__USER_PASSWORD';
export const FAIL_FORM__USER_PASSWORD = 'FAIL_FORM__USER_PASSWORD';

// userMobile
export const UNCHECK_LOCK__USER_MOBILE = 'UNCHECK_LOCK__USER_MOBILE';
export const INPUT_FORM__USER_MOBILE = 'INPUT_FORM__USER_MOBILE';
export const CHECK_FORM__USER_MOBILE = 'CHECK_FORM__USER_MOBILE';
export const INIT_FORM__USER_MOBILE = 'INIT_FORM__USER_MOBILE';
export const POST_MOBILE__USER_MOBILE = 'POST_MOBILE__USER_MOBILE';
export const DONE_MOBILE__USER_MOBILE = 'DONE_MOBILE__USER_MOBILE';
export const FAIL_MOBILE__USER_MOBILE = 'FAIL_MOBILE__USER_MOBILE';
export const POST_CODE__USER_MOBILE = 'POST_CODE__USER_MOBILE';
export const DONE_CODE__USER_MOBILE = 'DONE_CODE__USER_MOBILE';
export const FAIL_CODE__USER_MOBILE = 'FAIL_CODE__USER_MOBILE';

// userMarketing
export const INPUT_FORM__USER_MARKETING = 'INPUT_FORM__USER_MARKETING';
export const POST_FORM__USER_MARKETING = 'POST_FORM__USER_MARKETING';
export const DONE_FORM__USER_MARKETING = 'DONE_FORM__USER_MARKETING';
export const FAIL_FORM__USER_MARKETING = 'FAIL_FORM__USER_MARKETING';

// negoRedirect
export const SET_REDIRECT__NEGO_REDIRECT = 'SET_REDIRECT__NEGO_REDIRECT';
export const REMOVE_REDIRECT__NEGO_REDIRECT = 'REMOVE_REDIRECT__NEGO_REDIRECT';

// negoStocks
export const INPUT_KEYWORD__NEGO_STOCKS = 'INPUT_KEYWORD__NEGO_STOCKS';
export const RESET_KEYWORD__NEGO_STOCKS = 'RESET_KEYWORD__NEGO_STOCKS';
export const REQUEST_LIST__NEGO_STOCKS = 'REQUEST_LIST__NEGO_STOCKS';
export const RECEIVE_LIST__NEGO_STOCKS = 'RECEIVE_LIST__NEGO_STOCKS';
export const FAILURE_LIST__NEGO_STOCKS = 'FAILURE_LIST__NEGO_STOCKS';

// negoKeywords
export const GET_KEYWORD__NEGO_KEYWORDS = 'GET_KEYWORD__NEGO_KEYWORDS';
export const ADD_KEYWORD__NEGO_KEYWORDS = 'ADD_KEYWORD__NEGO_KEYWORDS';
export const DELETE_ALL__NEGO_KEYWORDS = 'DELETE_ALL__NEGO_KEYWORDS';

// intents
export const INITIAL_LIST__INTENTS = 'INITIAL_LIST__INTENTS';
export const REQUEST_LIST__INTENTS = 'REQUEST_LIST__INTENTS';
export const RECEIVE_LIST__INTENTS = 'RECEIVE_LIST__INTENTS';
export const FAILURE_LIST__INTENTS = 'FAILURE_LIST__INTENTS';

// intentsChat
export const ENTER_ROOM__INTENTS_CHAT = 'ENTER_ROOM__INTENTS_CHAT';
export const FAILURE_ROOM__INTENTS_CHAT = 'FAILURE_ROOM__INTENTS_CHAT';
export const SETTING_ROOM__INTENTS_CHAT = 'SETTING_ROOM__INTENTS_CHAT';
export const LISTEN_STATUS__INTENTS_CHAT = 'LISTEN_STATUS__INTENTS_CHAT';
export const LISTEN_ROOM__INTENTS_CHAT = 'LISTEN_ROOM__INTENTS_CHAT';
export const DESTROY_ROOM__INTENTS_CHAT = 'DESTROY_ROOM__INTENTS_CHAT';

// intentsMessages
export const REQUEST_ROOM__INTENTS_MESSAGES = 'REQUEST_ROOM__INTENTS_MESSAGES';
export const RECEIVE_ROOM__INTENTS_MESSAGES = 'RECEIVE_ROOM__INTENTS_MESSAGES';
export const FAILURE_ROOM__INTENTS_MESSAGES = 'FAILURE_ROOM__INTENTS_MESSAGES';

// intentsMaker
export const CHECK_FORM__INTENTS_MAKER = 'CHECK_FORM__INTENTS_MAKER';
export const INIT_FORM__INTENTS_MAKER = 'INIT_FORM__INTENTS_MAKER';
export const INPUT_FORM__INTENTS_MAKER = 'INPUT_FORM__INTENTS_MAKER';
export const POST_FORM__INTENTS_MAKER = 'POST_FORM__INTENTS_MAKER';
export const DONE_FORM__INTENTS_MAKER = 'DONE_FORM__INTENTS_MAKER';
export const FAIL_FORM__INTENTS_MAKER = 'FAIL_FORM__INTENTS_MAKER';
// export const REQUEST_REFERS__INTENTS_MAKER = 'REQUEST_REFERS__INTENTS_MAKER';
// export const RECEIVE_REFERS__INTENTS_MAKER = 'RECEIVE_REFERS__INTENTS_MAKER';
// export const FAILURE_REFERS__INTENTS_MAKER = 'FAILURE_REFERS__INTENTS_MAKER';

// intentsTrans
export const REQUEST_INTENT__INTENTS_TRNAS = 'REQUEST_INTENT__INTENTS_TRNAS';
export const RECEIVE_INTENT__INTENTS_TRNAS = 'RECEIVE_INTENT__INTENTS_TRNAS';
export const FAILURE_INTENT__INTENTS_TRNAS = 'FAILURE_INTENT__INTENTS_TRNAS';
export const CHECK_FORM__INTENTS_TRANS = 'CHECK_FORM__INTENTS_TRANS';
export const INPUT_FORM__INTENTS_TRANS = 'INPUT_FORM__INTENTS_TRANS';
export const TRANS_FORM__INTENTS_TRANS = 'TRANS_FORM__INTENTS_TRANS';
export const DONE_FORM__INTENTS_TRANS = 'DONE_FORM__INTENTS_TRANS';
export const FAIL_FORM__INTENTS_TRANS = 'FAIL_FORM__INTENTS_TRANS';
export const REQUEST_BIZDAYS__INTENTS_TRANS = 'REQUEST_BIZDAYS__INTENTS_TRANS';
export const REVEIVE_BIZDAYS__INTENTS_TRANS = 'REVEIVE_BIZDAYS__INTENTS_TRANS';
export const FAILURE_BIZDAYS__INTENTS_TRANS = 'FAILURE_BIZDAYS__INTENTS_TRANS';

// intentsRecent
export const REQUEST_LIST__INTENTS_RECENT = 'REQUEST_LIST__INTENTS_RECENT';
export const RECEIVE_LIST__INTENTS_RECENT = 'RECEIVE_LIST__INTENTS_RECENT';
export const FAILURE_LIST__INTENTS_RECENT = 'FAILURE_LIST__INTENTS_RECENT';
export const SELECT_ITEM__INTENTS_RECENT = 'SELECT_ITEM__INTENTS_RECENT';

// intentsBlocks
export const REQUEST_LIST__INTENTS_BLOCKS = 'REQUEST_LIST__INTENTS_BLOCKS';
export const RECEIVE_LIST__INTENTS_BLOCKS = 'RECEIVE_LIST__INTENTS_BLOCKS';
export const FAILURE_LIST__INTENTS_BLOCKS = 'FAILURE_LIST__INTENTS_BLOCKS';
export const SELECT_ITEM__INTENTS_BLOCKS = 'SELECT_ITEM__INTENTS_BLOCKS';

// myStocks
export const REQUEST_LIST__MYSTOCKS = 'REQUEST_LIST__MYSTOCKS';
export const RECEIVE_LIST__MYSTOCKS = 'RECEIVE_LIST__MYSTOCKS';
export const FAILURE_LIST__MYSTOCKS = 'FAILURE_LIST__MYSTOCKS';

// myStocksExpires
export const REQUEST_EXPIRES__MYSTOCKS_EXPIRES = 'REQUEST_EXPIRES__MYSTOCKS_EXPIRES';
export const RECEIVE_EXPIRES__MYSTOCKS_EXPIRES = 'RECEIVE_EXPIRES__MYSTOCKS_EXPIRES';
export const FAILURE_EXPIRES__MYSTOCKS_EXPIRES = 'FAILURE_EXPIRES__MYSTOCKS_EXPIRES';

// myStocksMaker
export const CHECK_FORM__MYSTOCKS_MAKER = 'CHECK_FORM__MYSTOCKS_MAKER';
export const INIT_FORM__MYSTOCKS_MAKER = 'INIT_FORM__MYSTOCKS_MAKER';
export const INPUT_FORM__MYSTOCKS_MAKER = 'INPUT_FORM__MYSTOCKS_MAKER';
export const POST_FORM__MYSTOCKS_MAKER = 'POST_FORM__MYSTOCKS_MAKER';
export const DONE_FORM__MYSTOCKS_MAKER = 'DONE_FORM__MYSTOCKS_MAKER';
export const FAIL_FORM__MYSTOCKS_MAKER = 'FAIL_FORM__MYSTOCKS_MAKER';

// myStocksUpdate
export const CHECK_FORM__MYSTOCKS_UPDATE = 'CHECK_FORM__MYSTOCKS_UPDATE';
export const INIT_FORM__MYSTOCKS_UPDATE = 'INIT_FORM__MYSTOCKS_UPDATE';
export const INPUT_FORM__MYSTOCKS_UPDATE = 'INPUT_FORM__MYSTOCKS_UPDATE';
export const POST_FORM__MYSTOCKS_UPDATE = 'POST_FORM__MYSTOCKS_UPDATE';
export const DONE_FORM__MYSTOCKS_UPDATE = 'DONE_FORM__MYSTOCKS_UPDATE';
export const FAIL_FORM__MYSTOCKS_UPDATE = 'FAIL_FORM__MYSTOCKS_UPDATE';

// myStocksTrans
export const INPUT_FORM__MYSTOCKS_TRANS = 'INPUT_FORM__MYSTOCKS_TRANS';
export const DEL_FORM__MYSTOCKS_TRANS = 'DEL_FORM__MYSTOCKS_TRANS';
export const DONE_FORM__MYSTOCKS_TRANS = 'DONE_FORM__MYSTOCKS_TRANS';
export const FAIL_FORM__MYSTOCKS_TRANS = 'FAIL_FORM__MYSTOCKS_TRANS';

// transactionsProgress
export const REQUEST_LIST__TRANSACTIONS_PROGRESS = 'REQUEST_LIST__TRANSACTIONS_PROGRESS';
export const RECEIVE_LIST__TRANSACTIONS_PROGRESS = 'RECEIVE_LIST__TRANSACTIONS_PROGRESS';
export const FAILURE_LIST__TRANSACTIONS_PROGRESS = 'FAILURE_LIST__TRANSACTIONS_PROGRESS';
export const POST_NOTICE__TRANSACTIONS_PROGRESS = 'POST_NOTICE__TRANSACTIONS_PROGRESS';
export const DONE_NOTICE__TRANSACTIONS_PROGRESS = 'DONE_NOTICE__TRANSACTIONS_PROGRESS';
export const FAIL_NOTICE__TRANSACTIONS_PROGRESS = 'FAIL_NOTICE__TRANSACTIONS_PROGRESS';

// transactionsFinished
export const INITIAL_LIST__TRANSACTIONS_FINISHED = 'INITIAL_LIST__TRANSACTIONS_FINISHED';
export const REQUEST_LIST__TRANSACTIONS_FINISHED = 'REQUEST_LIST__TRANSACTIONS_FINISHED';
export const RECEIVE_LIST__TRANSACTIONS_FINISHED = 'RECEIVE_LIST__TRANSACTIONS_FINISHED';
export const FAILURE_LIST__TRANSACTIONS_FINISHED = 'FAILURE_LIST__TRANSACTIONS_FINISHED';

// transactionsCancled
export const INITIAL_LIST__TRANSACTIONS_CANCELED = 'INITIAL_LIST__TRANSACTIONS_CANCELED';
export const REQUEST_LIST__TRANSACTIONS_CANCELED = 'REQUEST_LIST__TRANSACTIONS_CANCELED';
export const RECEIVE_LIST__TRANSACTIONS_CANCELED = 'RECEIVE_LIST__TRANSACTIONS_CANCELED';
export const FAILURE_LIST__TRANSACTIONS_CANCELED = 'FAILURE_LIST__TRANSACTIONS_CANCELED';

// transactionsLinks
export const SET_STOCK__TRANSACTIONS_LINKS = 'SET_STOCK__TRANSACTIONS_LINKS';
export const REQUEST_LIST__TRANSACTIONS_LINKS = 'REQUEST_LIST__TRANSACTIONS_LINKS';
export const RECEIVE_LIST__TRANSACTIONS_LINKS = 'RECEIVE_LIST__TRANSACTIONS_LINKS';
export const FAILURE_LIST__TRANSACTIONS_LINKS = 'FAILURE_LIST__TRANSACTIONS_LINKS';

// negoSample
export const REQUEST_INTENT__NEGO_SAMPLE = 'REQUEST_INTENT__NEGO_SAMPLE';
export const RECEIVE_INTENT__NEGO_SAMPLE = 'RECEIVE_INTENT__NEGO_SAMPLE';
export const FAILURE_INTENT__NEGO_SAMPLE = 'FAILURE_INTENT__NEGO_SAMPLE';

// marketsBoard
export const INPUT_STOCK__MARKETS_BOARD = 'INPUT_STOCK__MARKETS_BOARD';
export const REQUEST_LIST__MARKETS_BOARD = 'REQUEST_LIST__MARKETS_BOARD';
export const RECEIVE_LIST__MARKETS_BOARD = 'RECEIVE_LIST__MARKETS_BOARD';
export const FAILURE_LIST__MARKETS_BOARD = 'FAILURE_LIST__MARKETS_BOARD';

// sellAssigns
export const REQUEST_LIST__SELL_ASSIGNS = 'REQUEST_LIST__SELL_ASSIGNS';
export const RECEIVE_LIST__SELL_ASSIGNS = 'RECEIVE_LIST__SELL_ASSIGNS';
export const FAILURE_LIST__SELL_ASSIGNS = 'FAILURE_LIST__SELL_ASSIGNS';

// sellAssignsCheck
export const REQUEST_DATA__SELL_ASSIGNS_CHECK = 'REQUEST_DATA__SELL_ASSIGNS_CHECK';
export const RECEIVE_DATA__SELL_ASSIGNS_CHECK = 'RECEIVE_DATA__SELL_ASSIGNS_CHECK';
export const FAILURE_DATA__SELL_ASSIGNS_CHECK = 'FAILURE_DATA__SELL_ASSIGNS_CHECK';

// sellAssignsOrder
export const REQUEST_INFO__SELL_ASSIGNS_ORDER = 'REQUEST_INFO__SELL_ASSIGNS_ORDER';
export const RECEIVE_INFO__SELL_ASSIGNS_ORDER = 'RECEIVE_INFO__SELL_ASSIGNS_ORDER';
export const FAILURE_INFO__SELL_ASSIGNS_ORDER = 'FAILURE_INFO__SELL_ASSIGNS_ORDER';
export const CHECK_FORM__SELL_ASSIGNS_ORDER = 'CHECK_FORM__SELL_ASSIGNS_ORDER';
export const INIT_FORM__SELL_ASSIGNS_ORDER = 'INIT_FORM__SELL_ASSIGNS_ORDER';
export const INPUT_FORM__SELL_ASSIGNS_ORDER = 'INPUT_FORM__SELL_ASSIGNS_ORDER';
export const POST_FORM__SELL_ASSIGNS_ORDER = 'POST_FORM__SELL_ASSIGNS_ORDER';
export const DONE_FORM__SELL_ASSIGNS_ORDER = 'DONE_FORM__SELL_ASSIGNS_ORDER';
export const FAIL_FORM__SELL_ASSIGNS_ORDER = 'FAIL_FORM__SELL_ASSIGNS_ORDER';

// sellAssignsOrderEvent
export const REQUEST_INFO__SELL_ASSIGNS_ORDER_EVENT = 'REQUEST_INFO__SELL_ASSIGNS_ORDER_EVENT';
export const RECEIVE_INFO__SELL_ASSIGNS_ORDER_EVENT = 'RECEIVE_INFO__SELL_ASSIGNS_ORDER_EVENT';
export const FAILURE_INFO__SELL_ASSIGNS_ORDER_EVENT = 'FAILURE_INFO__SELL_ASSIGNS_ORDER_EVENT';
export const CHECK_FORM__SELL_ASSIGNS_ORDER_EVENT = 'CHECK_FORM__SELL_ASSIGNS_ORDER_EVENT';
export const INIT_FORM__SELL_ASSIGNS_ORDER_EVENT = 'INIT_FORM__SELL_ASSIGNS_ORDER_EVENT';
export const INPUT_FORM__SELL_ASSIGNS_ORDER_EVENT = 'INPUT_FORM__SELL_ASSIGNS_ORDER_EVENT';
export const POST_FORM__SELL_ASSIGNS_ORDER_EVENT = 'POST_FORM__SELL_ASSIGNS_ORDER_EVENT';
export const DONE_FORM__SELL_ASSIGNS_ORDER_EVENT = 'DONE_FORM__SELL_ASSIGNS_ORDER_EVENT';
export const FAIL_FORM__SELL_ASSIGNS_ORDER_EVENT = 'FAIL_FORM__SELL_ASSIGNS_ORDER_EVENT';

// sellAssignsIntent
export const CHECK_FORM__SELL_ASSINGS_INTENT = 'CHECK_FORM__SELL_ASSINGS_INTENT';
export const INIT_FORM__SELL_ASSINGS_INTENT = 'INIT_FORM__SELL_ASSINGS_INTENT';
export const INPUT_FORM__SELL_ASSIGNS_INTENT = 'INPUT_FORM__SELL_ASSIGNS_INTENT';
export const POST_FORM__SELL_ASSIGNS_INTENT = 'POST_FORM__SELL_ASSIGNS_INTENT';
export const DONE_FORM__SELL_ASSIGNS_INTENT = 'DONE_FORM__SELL_ASSIGNS_INTENT';
export const FAIL_FORM__SELL_ASSIGNS_INTENT = 'FAIL_FORM__SELL_ASSIGNS_INTENT';

// investCheck
export const INPUT_FORM__INVEST_CHECK = 'INPUT_FORM__INVEST_CHECK';
export const POST_FORM__INVEST_CHECK = 'POST_FORM__INVEST_CHECK';
export const DONE_FORM__INVEST_CHECK = 'DONE_FORM__INVEST_CHECK';
export const FAIL_FORM__INVEST_CHECK = 'FAIL_FORM__INVEST_CHECK';

// investPlacements
export const REQUEST__INVEST_PLACEMENTS = 'REQUEST__INVEST_PLACEMENTS';
export const RECEIVE__INVEST_PLACEMENTS = 'RECEIVE__INVEST_PLACEMENTS';
export const FAILURE__INVEST_PLACEMENTS = 'FAILURE__INVEST_PLACEMENTS';
export const CURRENT__INVEST_PLACEMENTS = 'CURRENT__INVEST_PLACEMENTS';

// investPlacementsContents
export const REQUEST__INVEST_PLACEMENTS_CONTENTS = 'REQUEST__INVEST_PLACEMENTS_CONTENTS';
export const RECEIVE__INVEST_PLACEMENTS_CONTENTS = 'RECEIVE__INVEST_PLACEMENTS_CONTENTS';
export const FAILURE__INVEST_PLACEMENTS_CONTENTS = 'FAILURE__INVEST_PLACEMENTS_CONTENTS';

// investPlacementsHistory
export const REQUEST__INVEST_PLACEMENTS_HISTORY = 'REQUEST__INVEST_PLACEMENTS_HISTORY';
export const RECEIVE__INVEST_PLACEMENTS_HISTORY = 'RECEIVE__INVEST_PLACEMENTS_HISTORY';
export const FAILURE__INVEST_PLACEMENTS_HISTORY = 'FAILURE__INVEST_PLACEMENTS_HISTORY';

// investPlacementsDocuments
export const REQUEST__INVEST_PLACEMENTS_DOCUMENTS = 'REQUEST__INVEST_PLACEMENTS_DOCUMENTS';
export const RECEIVE__INVEST_PLACEMENTS_DOCUMENTS = 'RECEIVE__INVEST_PLACEMENTS_DOCUMENTS';
export const FAILURE__INVEST_PLACEMENTS_DOCUMENTS = 'FAILURE__INVEST_PLACEMENTS_DOCUMENTS';

// investPlacementsMedia
export const SELECT__INVEST_PLACEMENTS_MEDIA = 'SELECT__INVEST_PLACEMENTS_MEDIA';
export const REQUEST__INVEST_PLACEMENTS_MEDIA = 'REQUEST__INVEST_PLACEMENTS_MEDIA';
export const RECEIVE__INVEST_PLACEMENTS_MEDIA = 'RECEIVE__INVEST_PLACEMENTS_MEDIA';
export const FAILURE__INVEST_PLACEMENTS_MEDIA = 'FAILURE__INVEST_PLACEMENTS_MEDIA';

// placementsPortfolios
export const REQUEST__PLACEMENTS_PORTFOLIS = 'REQUEST__PLACEMENTS_PORTFOLIS';
export const RECEIVE__PLACEMENTS_PORTFOLIS = 'RECEIVE__PLACEMENTS_PORTFOLIS';
export const FAILURE__PLACEMENTS_PORTFOLIS = 'FAILURE__PLACEMENTS_PORTFOLIS';

// placementsPortfoliosHistory
export const REQUEST__PLACEMENTS_PORTFOLIS_HISTORY = 'REQUEST__PLACEMENTS_PORTFOLIS_HISTORY';
export const RECEIVE__PLACEMENTS_PORTFOLIS_HISTORY = 'RECEIVE__PLACEMENTS_PORTFOLIS_HISTORY';
export const FAILURE__PLACEMENTS_PORTFOLIS_HISTORY = 'FAILURE__PLACEMENTS_PORTFOLIS_HISTORY';

// placementsCash
export const REQUEST__PLACEMENTS_CASH = 'REQUEST__PLACEMENTS_CASH';
export const RECEIVE__PLACEMENTS_CASH = 'RECEIVE__PLACEMENTS_CASH';
export const FAILURE__PLACEMENTS_CASH = 'FAILURE__PLACEMENTS_CASH';

// placementsCashHistory
export const REQUEST__PLACEMENTS_CASH_HISTORY = 'REQUEST__PLACEMENTS_CASH_HISTORY';
export const RECEIVE__PLACEMENTS_CASH_HISTORY = 'RECEIVE__PLACEMENTS_CASH_HISTORY';
export const FAILURE__PLACEMENTS_CASH_HISTORY = 'FAILURE__PLACEMENTS_CASH_HISTORY';

// placementsAdvise
export const REQUEST__PLACEMENTS_ADVISE = 'REQUEST__PLACEMENTS_ADVISE';
export const RECEIVE__PLACEMENTS_ADVISE = 'RECEIVE__PLACEMENTS_ADVISE';
export const FAILURE__PLACEMENTS_ADVISE = 'FAILURE__PLACEMENTS_ADVISE';

// consultList
export const FILTER__CONSULT_LIST = 'FILTER__CONSULT_LIST';
export const REQUEST__CONSULT_LIST = 'REQUEST__CONSULT_LIST';
export const RECEIVE__CONSULT_LIST = 'RECEIVE__CONSULT_LIST';
export const FAILURE__CONSULT_LIST = 'FAILURE__CONSULT_LIST';

// consultCreate
export const REQUEST_KEY__CONSULT_CREATE = 'REQUEST_KEY__CONSULT_CREATE';
export const RECEIVE_KEY__CONSULT_CREATE = 'RECEIVE_KEY__CONSULT_CREATE';
export const FAILURE_KEY__CONSULT_CREATE = 'FAILURE_KEY__CONSULT_CREATE';
export const CHECK_FORM__CONSULT_CREATE = 'CHECK_FORM__CONSULT_CREATE';
export const INPUT_FORM__CONSULT_CREATE = 'INPUT_FORM__CONSULT_CREATE';
export const POST_FORM__CONSULT_CREATE = 'POST_FORM__CONSULT_CREATE';
export const DONE_FORM__CONSULT_CREATE = 'DONE_FORM__CONSULT_CREATE';
export const FAIL_FORM__CONSULT_CREATE = 'FAIL_FORM__CONSULT_CREATE';

// consultDetail
export const SET__CONSULT_DETAIL = 'SET__CONSULT_DETAIL';

// dealsContents
export const REQUEST__DEALS_CONTENTS = 'REQUEST__DEALS_CONTENTS';
export const RECEIVE__DEALS_CONTENTS = 'RECEIVE__DEALS_CONTENTS';
export const FAILURE__DEALS_CONTENTS = 'FAILURE__DEALS_CONTENTS';
export const SELECT__DEALS_CONTENTS = 'SELECT__DEALS_CONTENTS';
export const CLEAR__DEALS_CONTENTS = 'CLEAR__DEALS_CONTENTS';

// dealsContentsPublic
export const REQUEST__DEALS_CONTENTS_PUBLIC = 'REQUEST__DEALS_CONTENTS_PUBLIC';
export const RECEIVE__DEALS_CONTENTS_PUBLIC = 'RECEIVE__DEALS_CONTENTS_PUBLIC';
export const FAILURE__DEALS_CONTENTS_PUBLIC = 'FAILURE__DEALS_CONTENTS_PUBLIC';

// dealsContentsComments
export const INITIAL__DEALS_CONTENTS_COMMENTS = 'INITIAL__DEALS_CONTENTS_COMMENTS';
export const REQUEST__DEALS_CONTENTS_COMMENTS = 'REQUEST__DEALS_CONTENTS_COMMENTS';
export const RECEIVE__DEALS_CONTENTS_COMMENTS = 'RECEIVE__DEALS_CONTENTS_COMMENTS';
export const FAILURE__DEALS_CONTENTS_COMMENTS = 'FAILURE__DEALS_CONTENTS_COMMENTS';
export const INPUT__DEALS_CONTENTS_COMMENTS = 'INPUT__DEALS_CONTENTS_COMMENTS';
export const POST__DEALS_CONTENTS_COMMENTS = 'POST__DEALS_CONTENTS_COMMENTS';
export const DONE__DEALS_CONTENTS_COMMENTS = 'DONE__DEALS_CONTENTS_COMMENTS';
export const FAIL__DEALS_CONTENTS_COMMENTS = 'FAIL__DEALS_CONTENTS_COMMENTS';

// dealsCharges
export const REQUEST__DEALS_CHARGES = 'REQUEST__DEALS_CHARGES';
export const RECEIVE__DEALS_CHARGES = 'RECEIVE__DEALS_CHARGES';
export const FAILURE__DEALS_CHARGES = 'FAILURE__DEALS_CHARGES';
export const REMOVE__DEALS_CHARGES = 'REMOVE__DEALS_CHARGES';

// dealsQna
export const CHECK__DEALS_QNA = 'CHECK__DEALS_QNA';
export const INPUT__DEALS_QNA = 'INPUT__DEALS_QNA';
export const POST__DEALS_QNA = 'POST__DEALS_QNA';
export const DONE__DEALS_QNA = 'DONE__DEALS_QNA';
export const FAIL__DEALS_QNA = 'FAIL__DEALS_QNA';

// helpIntent
export const INPUT__HELP_INTENT = 'INPUT__HELP_INTENT';
export const REQUEST__HELP_INTENT = 'REQUEST__HELP_INTENT';
export const RECEIVE__HELP_INTENT = 'RECEIVE__HELP_INTENT';
export const FAILURE__HELP_INTENT = 'FAILURE__HELP_INTENT';
export const SELECT__HELP_INTENT = 'SELECT__HELP_INTENT';
export const DESELECT__HELP_INTENT = 'DESELECT__HELP_INTENT';
export const SHOW_CHARGER__HELP_INTENT = 'SHOW_CHARGER__HELP_INTENT';
export const HIDE_CHARGER__HELP_INTENT = 'HIDE_CHARGER__HELP_INTENT';
export const SHOW_CORPORATION__HELP_INTENT = 'SHOW_CORPORATION__HELP_INTENT';
export const HIDE_CORPORATION__HELP_INTENT = 'HIDE_CORPORATION__HELP_INTENT';

// helpIntentMaker
export const SET_FORM__HELP_INTENT_MAKER = 'SET_FORM__HELP_INTENT_MAKER';
export const CHECK_FORM__HELP_INTENT_MAKER = 'CHECK_FORM__HELP_INTENT_MAKER';
export const INIT_FORM__HELP_INTENT_MAKER = 'INIT_FORM__HELP_INTENT_MAKER';
export const INPUT_FORM__HELP_INTENT_MAKER = 'INPUT_FORM__HELP_INTENT_MAKER';
export const POST_FORM__HELP_INTENT_MAKER = 'POST_FORM__HELP_INTENT_MAKER';
export const DONE_FORM__HELP_INTENT_MAKER = 'DONE_FORM__HELP_INTENT_MAKER';
export const FAIL_FORM__HELP_INTENT_MAKER = 'FAIL_FORM__HELP_INTENT_MAKER';

// otcReports
export const REQUEST__OTC_REPORTS = 'REQUEST__OTC_REPORTS';
export const RECEIVE__OTC_REPORTS = 'RECEIVE__OTC_REPORTS';
export const FAILURE__OTC_REPORTS = 'FAILURE__OTC_REPORTS';

// otcHot
export const FILTER__OTC_HOT = 'FILTER__OTC_HOT';
export const SORT__OTC_HOT = 'SORT__OTC_HOT';
export const REQUEST__OTC_HOT = 'REQUEST__OTC_HOT';
export const RECEIVE__OTC_HOT = 'RECEIVE__OTC_HOT';
export const FAILURE__OTC_HOT = 'FAILURE__OTC_HOT';
export const INITIAL__OTC_HOT = 'INITIAL__OTC_HOT';
export const SELECT__OTC_HOT = 'SELECT__OTC_HOT';
export const CLEAR__OTC_HOT = 'CLEAR__OTC_HOT';
export const LIMITED__OTC_HOT = 'LIMITED__OTC_HOT';

// keywords
export const REQUEST__KEYWORDS = 'REQUEST__KEYWORDS';
export const RECEIVE__KEYWORDS = 'RECEIVE__KEYWORDS';
export const FAILURE__KEYWORDS = 'FAILURE__KEYWORDS';
export const TOGGLE__KEYWORDS = 'TOGGLE__KEYWORDS';
export const DESELECT__KEYWORDS = 'DESELECT__KEYWORDS';

// lpFunding
export const REQUEST_LIST__LP_FUNDING = 'REQUEST_LIST__LP_FUNDING';
export const RECEIVE_LIST__LP_FUNDING = 'RECEIVE_LIST__LP_FUNDING';
export const FAILURE_LIST__LP_FUNDING = 'FAILURE_LIST__LP_FUNDING';
export const SELECT__LP_FUNDING = 'SELECT__LP_FUNDING';
export const CLEAR__LP_FUNDING = 'CLEAR__LP_FUNDING';

// lpFundingReserve
export const CHECK_FORM__LP_FUNDING_RESERVE = 'CHECK_FORM__LP_FUNDING_RESERVE';
export const INIT_FORM__LP_FUNDING_RESERVE = 'INIT_FORM__LP_FUNDING_RESERVE';
export const INPUT_FORM__LP_FUNDING_RESERVE = 'INPUT_FORM__LP_FUNDING_RESERVE';
export const POST_FORM__LP_FUNDING_RESERVE = 'POST_FORM__LP_FUNDING_RESERVE';
export const DONE_FORM__LP_FUNDING_RESERVE = 'DONE_FORM__LP_FUNDING_RESERVE';
export const FAIL_FORM__LP_FUNDING_RESERVE = 'FAIL_FORM__LP_FUNDING_RESERVE';

// lpFundingSubscript
export const CHECK_FORM__LP_FUNDING_SUBSCRIPT = 'CHECK_FORM__LP_FUNDING_SUBSCRIPT';
export const INIT_FORM__LP_FUNDING_SUBSCRIPT = 'INIT_FORM__LP_FUNDING_SUBSCRIPT';
export const INPUT_FORM__LP_FUNDING_SUBSCRIPT = 'INPUT_FORM__LP_FUNDING_SUBSCRIPT';
export const POST_FORM__LP_FUNDING_SUBSCRIPT = 'POST_FORM__LP_FUNDING_SUBSCRIPT';
export const DONE_FORM__LP_FUNDING_SUBSCRIPT = 'DONE_FORM__LP_FUNDING_SUBSCRIPT';
export const FAIL_FORM__LP_FUNDING_SUBSCRIPT = 'FAIL_FORM__LP_FUNDING_SUBSCRIPT';

// lpJoin
export const REQUEST_LIST__LP_JOIN = 'REQUEST_LIST__LP_JOIN';
export const RECEIVE_LIST__LP_JOIN = 'RECEIVE_LIST__LP_JOIN';
export const FAILURE_LIST__LP_JOIN = 'FAILURE_LIST__LP_JOIN';

// companyBrief
export const REQUEST__COMPANY_BRIEF = 'REQUEST__COMPANY_BRIEF';
export const RECEIVE__COMPANY_BRIEF = 'RECEIVE__COMPANY_BRIEF';
export const FAILURE__COMPANY_BRIEF = 'FAILURE__COMPANY_BRIEF';

// companySuggest
export const SET__COMPANY_SUGGEST = 'SET__COMPANY_SUGGEST';
export const INPUT__COMPANY_SUGGEST = 'INPUT__COMPANY_SUGGEST';
export const POST__COMPANY_SUGGEST = 'POST__COMPANY_SUGGEST';
export const DONE__COMPANY_SUGGEST = 'DONE__COMPANY_SUGGEST';
export const FAIL__COMPANY_SUGGEST = 'FAIL__COMPANY_SUGGEST';
export const INIT__COMPANY_SUGGEST = 'INIT__COMPANY_SUGGEST';

// companyRegister
export const CHECK_FORM__COMPANY_REGISTER = 'CHECK_FORM__COMPANY_REGISTER';
export const INIT_FORM__COMPANY_REGISTER = 'INIT_FORM__COMPANY_REGISTER';
export const INPUT_FORM__COMPANY_REGISTER = 'INPUT_FORM__COMPANY_REGISTER';
export const POST_FORM__COMPANY_REGISTER = 'POST_FORM__COMPANY_REGISTER';
export const DONE_FORM__COMPANY_REGISTER = 'DONE_FORM__COMPANY_REGISTER';
export const FAIL_FORM__COMPANY_REGISTER = 'FAIL_FORM__COMPANY_REGISTER';

// companyUpdate
export const CHECK_FORM__COMPANY_UPDATE = 'CHECK_FORM__COMPANY_UPDATE';
export const INIT_FORM__COMPANY_UPDATE = 'INIT_FORM__COMPANY_UPDATE';
export const INPUT_FORM__COMPANY_UPDATE = 'INPUT_FORM__COMPANY_UPDATE';
export const POST_FORM__COMPANY_UPDATE = 'POST_FORM__COMPANY_UPDATE';
export const DONE_FORM__COMPANY_UPDATE = 'DONE_FORM__COMPANY_UPDATE';
export const FAIL_FORM__COMPANY_UPDATE = 'FAIL_FORM__COMPANY_UPDATE';

// angelKakao
// export const POST__ANGEL_KAKAO = 'POST__ANGEL_KAKAO';
// export const DONE__ANGEL_KAKAO = 'DONE__ANGEL_KAKAO';
// export const FAIL__ANGEL_KAKAO = 'FAIL__ANGEL_KAKAO';

// angelItemAdd
// export const CHECK__ANGEL_ITEM_ADD = 'CHECK__ANGEL_ITEM_ADD';
// export const INPUT__ANGEL_ITEM_ADD = 'INPUT__ANGEL_ITEM_ADD';
// export const POST__ANGEL_ITEM_ADD = 'POST__ANGEL_ITEM_ADD';
// export const DONE__ANGEL_ITEM_ADD = 'DONE__ANGEL_ITEM_ADD';
// export const FAIL__ANGEL_ITEM_ADD = 'FAIL__ANGEL_ITEM_ADD';

// angelItemMod
// export const INPUT__ANGEL_ITEM_MOD = 'INPUT__ANGEL_ITEM_MOD';
// export const POST__ANGEL_ITEM_MOD = 'POST__ANGEL_ITEM_MOD';
// export const DONE__ANGEL_ITEM_MOD = 'DONE__ANGEL_ITEM_MOD';
// export const FAIL__ANGEL_ITEM_MOD = 'FAIL__ANGEL_ITEM_MOD';

// angelBuyOffer
// export const CHECK__ANGEL_BUY_OFFER = 'CHECK__ANGEL_BUY_OFFER';
// export const INPUT__ANGEL_BUY_OFFER = 'INPUT__ANGEL_BUY_OFFER';
// export const POST__ANGEL_BUY_OFFER = 'POST__ANGEL_BUY_OFFER';
// export const DONE__ANGEL_BUY_OFFER = 'DONE__ANGEL_BUY_OFFER';
// export const FAIL__ANGEL_BUY_OFFER = 'FAIL__ANGEL_BUY_OFFER';

// angelTransactionsProgress
export const REQUEST__ANGEL_TRANSACTIONS_PROGRESS = 'REQUEST__ANGEL_TRANSACTIONS_PROGRESS';
export const RECEIVE__ANGEL_TRANSACTIONS_PROGRESS = 'RECEIVE__ANGEL_TRANSACTIONS_PROGRESS';
export const FAILURE__ANGEL_TRANSACTIONS_PROGRESS = 'FAILURE__ANGEL_TRANSACTIONS_PROGRESS';

// angelTransactionsProgressPayIn
// export const POST__ANGEL_TRANSACTIONS_PROGRESS_PAY_IN = 'POST__ANGEL_TRANSACTIONS_PROGRESS_PAY_IN';
// export const DONE__ANGEL_TRANSACTIONS_PROGRESS_PAY_IN = 'DONE__ANGEL_TRANSACTIONS_PROGRESS_PAY_IN';
// export const FAIL__ANGEL_TRANSACTIONS_PROGRESS_PAY_IN = 'FAIL__ANGEL_TRANSACTIONS_PROGRESS_PAY_IN';

// angelTransactionsFinished
export const REQUEST__ANGEL_TRANSACTIONS_FINISHED = 'REQUEST__ANGEL_TRANSACTIONS_FINISHED';
export const RECEIVE__ANGEL_TRANSACTIONS_FINISHED = 'RECEIVE__ANGEL_TRANSACTIONS_FINISHED';
export const FAILURE__ANGEL_TRANSACTIONS_FINISHED = 'FAILURE__ANGEL_TRANSACTIONS_FINISHED';

// tudalKakao
// export const POST__TUDAL_KAKAO = 'POST__TUDAL_KAKAO';
// export const DONE__TUDAL_KAKAO = 'DONE__TUDAL_KAKAO';
// export const FAIL__TUDAL_KAKAO = 'FAIL__TUDAL_KAKAO';

// todalOffer
// export const REQUEST_BIZDAYS__TUDAL_OFFER = 'REQUEST_BIZDAYS__TUDAL_OFFER';
// export const REVEIVE_BIZDAYS__TUDAL_OFFER = 'REVEIVE_BIZDAYS__TUDAL_OFFER';
// export const FAILURE_BIZDAYS__TUDAL_OFFER = 'FAILURE_BIZDAYS__TUDAL_OFFER';
// export const CHECK_FORM__TUDAL_OFFER = 'CHECK_FORM__TUDAL_OFFER';
// export const INIT_FORM__TUDAL_OFFER = 'INIT_FORM__TUDAL_OFFER';
// export const INPUT_FORM__TUDAL_OFFER = 'INPUT_FORM__TUDAL_OFFER';
// export const POST_FORM__TUDAL_OFFER = 'POST_FORM__TUDAL_OFFER';
// export const DONE_FORM__TUDAL_OFFER = 'DONE_FORM__TUDAL_OFFER';
// export const FAIL_FORM__TUDAL_OFFER = 'FAIL_FORM__TUDAL_OFFER';

// todalConfirm
// export const REQUEST_INTENT__TUDAL_CONFIRM = 'REQUEST_INTENT__TUDAL_CONFIRM';
// export const RECEIVE_INTENT__TUDAL_CONFIRM = 'RECEIVE_INTENT__TUDAL_CONFIRM';
// export const FAILURE_INTENT__TUDAL_CONFIRM = 'FAILURE_INTENT__TUDAL_CONFIRM';
// export const INPUT_FORM__TUDAL_CONFIRM = 'INPUT_FORM__TUDAL_CONFIRM';
// export const POST_FORM__TUDAL_CONFIRM = 'POST_FORM__TUDAL_CONFIRM';
// export const DONE_FORM__TUDAL_CONFIRM = 'DONE_FORM__TUDAL_CONFIRM';
// export const FAIL_FORM__TUDAL_CONFIRM = 'FAIL_FORM__TUDAL_CONFIRM';

// investMeeting
export const REQUEST_DATA__INVEST_MEETING = 'REQUEST_DATA__INVEST_MEETING';
export const RECEIVE_DATA__INVEST_MEETING = 'RECEIVE_DATA__INVEST_MEETING';
export const FAILURE_DATA__INVEST_MEETING = 'FAILURE_DATA__INVEST_MEETING';
export const INPUT_FORM__INVEST_MEETING = 'INPUT_FORM__INVEST_MEETING';
export const SELECT_FORM__INVEST_MEETING = 'SELECT_FORM__INVEST_MEETING';
export const CHECK_FORM__INVEST_MEETING = 'CHECK_FORM__INVEST_MEETING';
export const POST_FORM__INVEST_MEETING = 'POST_FORM__INVEST_MEETING';
export const DONE_FORM__INVEST_MEETING = 'DONE_FORM__INVEST_MEETING';
export const FAIL_FORM__INVEST_MEETING = 'FAIL_FORM__INVEST_MEETING';

// agreeFile
export const REQUEST_DATA__AGREE_FILE = 'REQUEST_DATA__AGREE_FILE';
export const RECEIVE_DATA__AGREE_FILE = 'RECEIVE_DATA__AGREE_FILE';
export const FAILURE_DATA__AGREE_FILE = 'FAILURE_DATA__AGREE_FILE';
export const INPUT_FORM__AGREE_FILE = 'INPUT_FORM__AGREE_FILE';
export const CHECK_FORM__AGREE_FILE = 'CHECK_FORM__AGREE_FILE';
export const POST_FORM__AGREE_FILE = 'POST_FORM__AGREE_FILE';
export const DONE_FORM__AGREE_FILE = 'DONE_FORM__AGREE_FILE';
export const FAIL_FORM__AGREE_FILE = 'FAIL_FORM__AGREE_FILE';

// buySign
export const REQUEST_DATA__BUY_SIGN = 'REQUEST_DATA__BUY_SIGN';
export const RECEIVE_DATA__BUY_SIGN = 'RECEIVE_DATA__BUY_SIGN';
export const FAILURE_DATA__BUY_SIGN = 'FAILURE_DATA__BUY_SIGN';
export const INPUT_FORM__BUY_SIGN = 'INPUT_FORM__BUY_SIGN';
export const CHECK_FORM__BUY_SIGN = 'CHECK_FORM__BUY_SIGN';
export const POST_FORM__BUY_SIGN = 'POST_FORM__BUY_SIGN';
export const DONE_FORM__BUY_SIGN = 'DONE_FORM__BUY_SIGN';
export const FAIL_FORM__BUY_SIGN = 'FAIL_FORM__BUY_SIGN';

// paymentDocs
export const REQUEST_DATA__PAYMENT_DOCS = 'REQUEST_DATA__PAYMENT_DOCS';
export const RECEIVE_DATA__PAYMENT_DOCS = 'RECEIVE_DATA__PAYMENT_DOCS';
export const FAILURE_DATA__PAYMENT_DOCS = 'FAILURE_DATA__PAYMENT_DOCS';

// investorSuggesting
export const SET__INVESTOR_SUGGESTING = 'SET__INVESTOR_SUGGESTING';
export const INPUT__INVESTOR_SUGGESTING = 'INPUT__INVESTOR_SUGGESTING';
export const CHECK__INVESTOR_SUGGESTING = 'CHECK__INVESTOR_SUGGESTING';
export const POST__INVESTOR_SUGGESTING = 'POST__INVESTOR_SUGGESTING';
export const DONE__INVESTOR_SUGGESTING = 'DONE__INVESTOR_SUGGESTING';
export const FAIL__INVESTOR_SUGGESTING = 'FAIL__INVESTOR_SUGGESTING';
export const INIT__INVESTOR_SUGGESTING = 'INIT__INVESTOR_SUGGESTING';
export const REQUEST__INVESTOR_SUGGESTING = 'REQUEST__INVESTOR_SUGGESTING';
export const RECEIVE__INVESTOR_SUGGESTING = 'RECEIVE__INVESTOR_SUGGESTING';
export const FAILURE__INVESTOR_SUGGESTING = 'FAILURE__INVESTOR_SUGGESTING';

// investorScoring
export const SELECT__INVESTOR_SCORING = 'SELECT__INVESTOR_SCORING';
export const CHECK__INVESTOR_SCORING = 'CHECK__INVESTOR_SCORING';
export const SCORE__INVESTOR_SCORING = 'SCORE__INVESTOR_SCORING';
export const ANSWER__INVESTOR_SCORING = 'ANSWER__INVESTOR_SCORING';
export const INIT__INVESTOR_SCORING = 'INIT__INVESTOR_SCORING';
export const INPUT__INVESTOR_SCORING = 'INPUT__INVESTOR_SCORING';
export const POST__INVESTOR_SCORING = 'POST__INVESTOR_SCORING';
export const DONE__INVESTOR_SCORING = 'DONE__INVESTOR_SCORING';
export const FAIL__INVESTOR_SCORING = 'FAIL__INVESTOR_SCORING';

// publicIntent
export const REQUEST_DATA__PUBLIC_INTENT = 'REQUEST_DATA__PUBLIC_INTENT';
export const RECEIVE_DATA__PUBLIC_INTENT = 'RECEIVE_DATA__PUBLIC_INTENT';
export const FAILURE_DATA__PUBLIC_INTENT = 'FAILURE_DATA__PUBLIC_INTENT';
export const INPUT_FORM__PUBLIC_INTENT = 'INPUT_FORM__PUBLIC_INTENT';
export const CHECK_FORM__PUBLIC_INTENT = 'CHECK_FORM__PUBLIC_INTENT';
export const POST_FORM__PUBLIC_INTENT = 'POST_FORM__PUBLIC_INTENT';
export const DONE_FORM__PUBLIC_INTENT = 'DONE_FORM__PUBLIC_INTENT';
export const FAIL_FORM__PUBLIC_INTENT = 'FAIL_FORM__PUBLIC_INTENT';

// marketingReject
export const INPUT_FORM__MARKETING_REJECT = 'INPUT_FORM__MARKETING_REJECT';
export const CHECK_FORM__MARKETING_REJECT = 'CHECK_FORM__MARKETING_REJECT';
export const POST_FORM__MARKETING_REJECT = 'POST_FORM__MARKETING_REJECT';
export const DONE_FORM__MARKETING_REJECT = 'DONE_FORM__MARKETING_REJECT';
export const FAIL_FORM__MARKETING_REJECT = 'FAIL_FORM__MARKETING_REJECT';

// newsletter
export const REQUEST__NEWS_LETTER = 'REQUEST__NEWS_LETTER';
export const RECEIVE__NEWS_LETTER = 'RECEIVE__NEWS_LETTER';
export const FAILURE__NEWS_LETTER = 'FAILURE__NEWS_LETTER';

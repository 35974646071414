const RetailManagingStatus = {
  READY: 'READY',
  PRE: 'PRE',
  PROCEED: 'PROCEED',
  CANCEL: 'CANCEL',
  DONE: 'DONE',
};
const RETAIL_MANAGING_STATUS = [
  { value: RetailManagingStatus.READY,   label: '' },
  { value: RetailManagingStatus.PRE,     label: '신기술투자조합 모집준비중' },
  { value: RetailManagingStatus.PROCEED, label: '신기술투자조합 모집중' },
  { value: RetailManagingStatus.CANCEL,  label: '' },
  { value: RetailManagingStatus.DONE,    label: '신기술투자조합 모집종료' },
];

export {
  RETAIL_MANAGING_STATUS,
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deals-library"},[_c('InvestLinks'),_c('DealsCharges'),_c('div',{staticClass:"d-flex justify-content-end mb-3"},[_c('router-link',{staticClass:"btn btn-primary btn-sm rounded-0 ml-2",attrs:{"to":{
        name: '__DEALS_QNA__',
        query: _vm.$route.query,
      }}},[_vm._v("담당자에게 문의하기")])],1),(!_vm.isProInvestor)?[_c('div',{staticClass:"alert bg-warning"},[_vm._v(" 상품설명서 등의 자료는 전문투자자만 열람가능 합니다. 회원정보 페이지에서 전문투자자 등록이 가능합니다. "),_c('br'),_c('p',{staticClass:"mb-0 text-right"},[_c('router-link',{staticClass:"btn btn-link btn-sm pr-0 text-dark",attrs:{"to":{
          name: 'user_infomation',
          hash: '#infomation-regist',
        }}},[_vm._v("회원정보페이지로 가기 >")])],1)]),_c('HelpIntentNotice')]:(_vm.$route.query.beforeReserveTime)?_c('div',{staticClass:"alert bg-warning"},[_vm._v(" 투자안내서(IM), 조합규약(안) 등의 자료는 사전예약 접수일에 공개됩니다. ")]):[_c('DealsContents')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
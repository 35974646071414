<template>
  <div class="consult-list">
    <NegoLinks group="group3"/>
    <div class="container">
      <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-4">
        <p class="mb-3 mb-md-0">비상장기업에 대해 무엇이든 물어보세요.<br/>당사의 리서치센터에서 성의껏 답변을 드립니다.</p>
        <router-link
          class="btn btn-outline-primary btn-md-block"
          :to="{ name: '__CONSULT_CREATE__' }"
        >온라인 1:1 문의 작성하기 <IconChevronRight /> </router-link>
      </div>
    </div>
    <div class="container-md px-0 px-md-3 mb-5">
      <div>
        <a
          class="btn btn-link"
          :class="filter === 'NONE' ? 'text-dark': 'text-light'"
          @click="handleFilter('NONE')"
        >전체</a>
        <span class="text-light">|</span>
        <a
          class="btn btn-link"
          :class="filter === 'MINE' ? 'text-dark': 'text-light'"
          @click="handleFilter('MINE')"
        >내Q&A</a>
      </div>
      <div class="accordion" id="accordionExample">
        <template v-for="(el, index) in filteredList">
          <ConsultListItem
            :key="index"
            :item="{ index, ...el }"
            @select-item="() => handleLink(el)"
           />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import NegoLinks from '@/components/nego/NegoLinks.vue';
import IconChevronRight from '@/components/icons/IconChevronRight.vue';
import ConsultListItem from '@/components/consult/ConsultListItem.vue';
import {
  FILTER__CONSULT_LIST,
  SET__CONSULT_DETAIL,
} from '@/store/mutation-types';

export default {
  name: 'ConsultList',
  components: {
    NegoLinks,
    IconChevronRight,
    ConsultListItem,
  },
  computed: {
    ...mapState('consultList', [
      'list',
      'filter',
    ]),
    ...mapGetters('consultList', [
      'loadingList',
      'filteredList',
    ]),
    ...mapGetters('userInfomation', [
      'isAuthenticated',
    ]),
  },
  methods: {
    ...mapActions('consultList', [
      'requestList',
      'requestListPublic',
    ]),
    ...mapMutations('consultList', {
      inputFilter: FILTER__CONSULT_LIST,
    }),
    ...mapMutations('consultDetail', {
      setDetail: SET__CONSULT_DETAIL,
    }),
    handleLink(el) {
      this.setDetail(el);
      this.$router.push({
        name: '__CONSULT_DETAIL__',
        params: { consultKey: el.consultKey },
      });
    },
    handleFilter(filter) {
      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'sign_in',
          query: { redirect_path: '/consult/list' },
        });
      } else {
        this.inputFilter(filter);
      }
    },
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.requestListPublic();
    } else {
      this.requestList();
    }
  },
};
</script>

<style lang="scss" scoped>
.consult-list {
  min-height: 95vh;
}
</style>

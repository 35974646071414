<template>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <transition-group
      name="carousel-item"
      tag="div"
      class="carousel-inner"
      :enter-class="`carousel-item-${direction === 'left' ? 'right' : 'left'}`"
      :leave-to-class="`carousel-item-${direction}`"
    >
      <template v-for="(charge, index) in list">
        <div class="carousel-item active px-3" v-if="index === cardNumber" :key="index">
          <ChargesCard
            :charge="charge"
          />
        </div>
      </template>
    </transition-group>
    <a
      class="carousel-control-prev"
      role="button"
      data-slide="prev"
      @click="handlePrev"
    >
      <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
      <IconCaretLeftFill class="text-secondary icon-xl" />
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      role="button"
      data-slide="next"
      @click="handleNext"
    >
      <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
      <IconCaretRightFill class="text-secondary icon-xl" />
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import ChargesCard from '@/components/deals/ChargesCard';
import IconCaretLeftFill from '@/components/icons/IconCaretLeftFill';
import IconCaretRightFill from '@/components/icons/IconCaretRightFill';

export default {
  name: 'ChargesCarousel',
  components: {
    ChargesCard,
    IconCaretLeftFill,
    IconCaretRightFill,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      cardNumber: 0,
      direction: 'left',
    };
  },
  methods: {
    handleNext() {
      this.cardNumber += 1;
      if (this.cardNumber === this.list.length) {
        this.cardNumber = 0;
      }
      this.direction = 'left';
    },
    handlePrev() {
      this.cardNumber -= 1;
      if (this.cardNumber < 0) {
        this.cardNumber = this.list.length - 1;
      }
      this.direction = 'right';
    },
  },
};
</script>

<style scoped>
.carousel-control-prev,
.carousel-control-next {
  width: 8%;
}
/* .carousel-item-enter-left {
  transform: translateX(100%);
}
.carousel-item-enter-right {
  transform: translateX(-100%);
}

.carousel-item-leave-to-left {
  transform: translateX(-100%);
}
.carousel-item-leave-to-right {
  transform: translateX(100%);
} */
</style>

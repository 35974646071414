<template>
  <div class="bg-gray py-5">
    <div class="container">
      <h5>비상장주식 투자자 유의사항</h5>
      <ul class="text-">
        <li><p>{{ notices[0] }}</p></li>
        <li><p>{{ notices[1] }}</p></li>
        <li><p>{{ notices[2] }}</p></li>
        <li>
          <p>
            {{ notices[3] }}
            (
              <a href="/files/hometax_guide.pdf" download="국세청홈택스 신고방법 안내.pdf" class="text-dark">
                <IconFileEarmarkText class="icon-sm"/> 국세청홈택스 신고방법 안내.pdf
              </a>
            )
          </p>
        </li>
        <li><p>{{ notices[4] }}</p></li>
      </ul>
      <h5>중개수수료</h5>
      <div class="row">
        <div class="col-12 col-md-7 col-lg-5">
          <table class="table table-bordered table-sm">
            <thead>
              <tr class="text-center table-light">
                <th rowspan="2" class="align-middle">매수</th>
                <th colspan="2">매도</th>
              </tr>
              <tr class="text-center table-light">
                <th>일반중개</th>
                <th>확인매물</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td>거래대금의 <br>0.0%</td>
                <td>거래대금의 <br>0.2%</td>
                <td>거래대금의 <br>0.5%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="text-right">
        <h6>준법감시인 심사필 제20-**호 (20**.**.**~20**.**.**)</h6>
      </div> -->
    </div>
  </div>
</template>

<script>
import IconFileEarmarkText from '@/components/icons/IconFileEarmarkText';

const notices = [
  '비상장주식은 상장주식에 비해 기업공시나 기업분석보고서 등 투자참조자료가 부족하여 투자정보를 적시에 충분히 획득하기 어렵고, 유동성이 낮아 투자자가 원하는 시점에 주식을 취득하거나 처분하기 어려우니 투자위험을 충분히 고려하여 투자판단 하시기 바랍니다.',
  '코리아에셋투자증권은 회원간의 협상 과정에 일체 참여하지 않습니다. 또한 코리아에셋투자증권은 네고스탁 회원 당사자 간에 합의된 매매를 중개할 뿐이며, 매수자에 대하여 일체의 비상장 주식 투자 권유 행위를 하지 않습니다.',
  '코리아에셋투자증권은 네고스탁 채팅을 통해 합의한 거래요청에 대해 거래상대방의 결제 이행을 보장하지 않습니다. 다만, 지정된 시간 이내에 거래상대방이 결제를 이행하지 않을 경우 코리아에셋투자증권으로 이체한 주식 또는 매매대금은 반환 받게 됩니다.',
  '비상장주식 매도 시, 증권거래세 및 양도소득세 납부의무가 발생합니다. 증권거래서는 매도 시에 원천징수가 이루어지며, 양도소득세는 투자자가 직접 세무서에 신고, 납부하셔야 합니다.',
  '단일 매도자를 기준으로 동일 종목을 6개월간 50인 이상에게 매도하거나 동일 종목을 1년간 10억원 이상 매도하는 경우 매매중개가 제한될 수 있습니다.',
];

export default {
  name: 'HomeNotices',
  components: {
    IconFileEarmarkText,
  },
  data() {
    return {
      notices,
    };
  },
};
</script>

<template>
  <div>
    <div class="text-center">
      <div class="header d-flex justify-content-between">
        <div class="p-3 d-flex flex-column justify-content-center">
          <h4 class="sub mb-0">네고스탁</h4>
          <h2 class="title">FAQ</h2>
        </div>
        <img src="./img/header01.svg" alt="" class="w-50 h-100"/>
      </div>
      <div class="btn-group my-3 w-100" role="group">
        <router-link
          v-for="({ label, id }, index) in faqMap"
          :key="index"
          class="btn btn-secondary px-0"
          :to="{ path: `/guides/faq/#${id}` }"
        >{{ label }}</router-link>
      </div>
    </div>
    <div class="my-4" v-for="({ label, id, content }, index) in faqMap" :key="index">
      <h3 :id="id">{{ label }}</h3>
      <GuidesFaqCollapse v-for="(qna, idx) in content" v-bind="qna" :key="idx" />
    </div>
    <div class="position-sticky text-right">
      <button class="btn btn-primary btn-sm" @click="scrollTop">위로</button>
    </div>
  </div>
</template>

<script>
import GuidesFaqCollapse from '@/components/guides/GuidesFaqCollapse.vue';
import FaqContents from './faqContents';

export default {
  name: 'GuidesFaq',
  components: {
    GuidesFaqCollapse,
  },
  data() {
    return {
      faqMap: [
        { label: '네고요청', id: 'nego', content: FaqContents.nego },
        { label: '매매진행', id: 'trade', content: FaqContents.trade },
        { label: '바로매수', id: 'sell', content: FaqContents.sell },
        { label: '계정관련', id: 'auth', content: FaqContents.auth },
      ],
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 7rem;
  background-color: $primary;
}
.sub {
  @include nego-font2(300, 1.1, -1.2px, #ffffff);
}
.title {
  @include nego-font2(700, 1.1, -1.8px, #ffffff);
}
h3 {
  margin-top: -3rem;
  padding-top: 4.5rem;
}
.position-sticky {
  right: 2rem;
  bottom: 2rem;
}
</style>

<template>
  <div>
    <FormHead
      :title="form.stockName"
      :text="form.stockInfoUid"
    />
    <form @submit.prevent="handleSubmit">
      <InputQuantity
        id="quantity"
        :value="form['quantity']"
        :error="error['quantity']"
        @input-form="(value) => inputForm({ key: 'quantity', value })"
        :label="`수량 (최소 ${form.minimumOrderQuantity} 주 매수 가능)`"
      />
      <InputPrice
        id="price"
        :value="form['price']"
        :error="error['price']"
        @input-form="(value) => inputForm({ key: 'price', value })"
      />
      <InputTotalDisable
        id="totalPrice"
        :value="form['price'] * form['quantity']"
        :label="`거래금액`"
      />
      <h6>유의사항</h6>
      <ol class="pl-4">
        <li>본 건은 코리아에셋투자증권에서 직접 또는 매도인을 대리하여 진행하는 매도 건으로 고객의 요청등에 의해 매도수량과 매도가격을 비공개로 진행합니다.</li>
        <li>매수의향을 제출하시면 당사 또는 매도인이 사전에 요청한 기준에 따라 즉시 거래거절 또는 거래요청을 보내드리며 “네고진행” 화면에서 이를 확인하실 수 있습니다. </li>
        <li>거래요청이 이루어진 경우 10분 이내에 거래를 승인하셔야 하며, 승인 후 30분 이내에 입금을 완료하시면 됩니다.</li>
        <li>본 매수 의향제출과 향후 회원님이 수령하시는 거래요청이 실제 주식 매수를 100% 보장하지는 않습니다.</li>
      </ol>
      <InputCheckbox
        id="agreeForm"
        :value="form['agreeForm']"
        :error="error['agreeForm']"
        @input-form="(value) => inputForm({ key: 'agreeForm', value })"
        label="유의사항 확인함"
      />
      <div class="alert alert-danger" role="alert" v-if="error['sellAssignKey']">
        {{ error['sellAssignKey'] }}
      </div>
      <div class="alert alert-danger" role="alert" v-if="error['orderable']">
        {{ error['orderable'] }}
      </div>
      <SubmitButton
        label="매수의향 제출"
        :loadingStatus="loadingForm"
        :enabledForm="true"
      />
    </form>
    <NegoModalAccount ref="negoModalAccount"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import FormHead from '@/components/intents/form/FormHead.vue';
import InputQuantity from '@/components/intents/form/InputQuantity.vue';
import InputPrice from '@/components/intents/form/InputPrice.vue';
import InputTotalDisable from '@/components/intents/form/InputTotalDisable.vue';
import SubmitButton from '@/components/intents/form/SubmitButton.vue';
import InputCheckbox from '@/components/intents/form/InputCheckbox.vue';
import NegoModalAccount from '@/components/nego/NegoModalAccount.vue';
import {
  INIT_FORM__SELL_ASSINGS_INTENT,
  INPUT_FORM__SELL_ASSIGNS_INTENT,
} from '@/store/mutation-types';
import { NegoStockWeb, NegoStockApi, FintechCommPath } from '@/utils/constants';

export default {
  name: 'SellAssignsIntentView',
  components: {
    FormHead,
    InputQuantity,
    InputPrice,
    InputTotalDisable,
    SubmitButton,
    InputCheckbox,
    NegoModalAccount,
  },
  computed: {
    ...mapState('userInfomation', [
      'user',
    ]),
    ...mapState('sellAssignsIntent', [
      'form',
      'error',
      'status',
    ]),
    ...mapGetters('sellAssignsIntent', [
      'loadingForm',
    ])
  },
  methods: {
    ...mapMutations('sellAssignsIntent', {
      'initForm': INIT_FORM__SELL_ASSINGS_INTENT,
      'inputForm': INPUT_FORM__SELL_ASSIGNS_INTENT,
    }),
    ...mapActions('sellAssignsIntent', [
      'checkForm',
      'submitForm',
      'checkOrder',
    ]),
    handleSubmit() {
      const {
        userKey,
        preUser,
        kassetAccountStatus,
        brokerAccountStatus,
        bankAccountStatus,
      } = this.user;

      // 로그인 X
      if (!userKey) {
        const { path, query } = this.$route;
        this.$router.push({
          name: 'sign_in',
          query: {
            ...query,
            redirect_path: path,
          },
        });
        return;
      }

      // 휴대전화인증 X
      if (preUser) {
        this.$router.push({
          name: 'user_mobile',
        });
        return;
      }

      // 비대면계좌 처리대기 중
      if (kassetAccountStatus === 'READY') {
        this.$refs.negoModalAccount.openModal();
        return;
      }

      // 증권,은행계좌 미등록
      if (kassetAccountStatus === 'NONE' && brokerAccountStatus === 'NONE' && bankAccountStatus === 'NONE') {
        const {
          stockInfoUid, stockName, price, quantity, minimumOrderQuantity, sellAssignKey,
        } = this.$route.query;
        const PATH = [
          `${NegoStockApi}/sessions/0/${FintechCommPath}/auth`,
          `?path=/users/account`,
          `&redirect_path=${NegoStockWeb}/sell-assigns/intent`,
          `&stockInfoUid=${stockInfoUid}`,
          `&stockName=${encodeURIComponent(stockName)}`,
          `&price=${price}`,
          `&quantity=${quantity}`,
          `&minimumOrderQuantity=${minimumOrderQuantity}`,
          `&sellAssignKey=${sellAssignKey}`,
        ].join('');
        window.location = PATH;
        return;
      }

      this.checkForm();
    },
  },
  mounted() {
    const {
      stockInfoUid, stockName, sellAssignKey, price, minimumOrderQuantity,
    } = this.$route.query;
    this.inputForm({ key: 'stockInfoUid', value: stockInfoUid });
    this.inputForm({ key: 'stockName', value: stockName });
    this.inputForm({ key: 'sellAssignKey', value: sellAssignKey });
    this.inputForm({ key: 'price', value: parseInt(price, 10) });
    this.inputForm({ key: 'quantity', value: parseInt(minimumOrderQuantity, 10) });
    this.inputForm({ key: 'minimumOrderQuantity', value: parseInt(minimumOrderQuantity, 10) });

    const { userKey, preUser } = this.user;
    if (userKey && !preUser) {
      this.checkOrder();
    }
  },
  beforeDestroy() {
    this.initForm();
  },
};
</script>

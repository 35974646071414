import _ from 'underscore';
import {
  REQUEST__INVEST_PLACEMENTS_DOCUMENTS,
  RECEIVE__INVEST_PLACEMENTS_DOCUMENTS,
  FAILURE__INVEST_PLACEMENTS_DOCUMENTS,
} from '@/store/mutation-types';
import api from '@/api/axios';

const state = {
  list: [],
  gains: [],
  error: {},
  status: 'INITIAL',
};

const getters = {
  loadingList: state => state.status === 'REQUEST',
  sortedList: state => _.sortBy(state.list, 'dateIdx').reverse(),
};

const mutations = {
  [REQUEST__INVEST_PLACEMENTS_DOCUMENTS] (state) {
    state.status = 'REQUEST';
  },
  [RECEIVE__INVEST_PLACEMENTS_DOCUMENTS] (state, data) {
    const { letters, receipts, changes, gains } = data;
    state.list = [
      ...(letters ?? []),
      ...(receipts ?? []),
      ...(changes ?? []),
    ];
    state.gains = gains ?? [];
    state.status = 'RECEIVE';
  },
  [FAILURE__INVEST_PLACEMENTS_DOCUMENTS] (state, error) {
    state.error = { ...error };
    state.status = 'FAILURE';
  },
};

const actions = {
  requestList({ commit }) {
    commit(REQUEST__INVEST_PLACEMENTS_DOCUMENTS);

    api.get('/invest/placement/docs').then(
      response => commit(RECEIVE__INVEST_PLACEMENTS_DOCUMENTS, response.data),
      error => commit(FAILURE__INVEST_PLACEMENTS_DOCUMENTS, error.response.data),
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
